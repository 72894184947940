import { Box, Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import React, { FunctionComponent, useEffect } from 'react';
import PostSecurityProfileDataModel from '../../../data-models/admin/access-control/PostSecurityProfile.DataModel';
import PostRuleDataModel from '../../../data-models/admin/access-control/PostSecurityProfileRule.DataModel';
import SecurityProfileDataModel from '../../../data-models/admin/access-control/SecurityProfile.DataModel';
import SecurityRuleDataModel from '../../../data-models/admin/access-control/SecurityRule.DataModel';
import {
    editSecurityProfile,
    getSecurityProfileById,
    getNewSecurityProfile,
    addSecurityProfile,
} from '../../../services/SecurityProfiles.Service';
import { Loader } from '../../generic-components/loader/Loader.Component';
import Portal from '../../generic-components/portal/Portal.Component';
import { AccessControlSecurityRuleAccordion } from '../access-control-security-rule-accordion/AccessControlSecurityRuleAccordion.Component';
import styles from './AccessControlSecurityProfilesAddEdit.Styles';

interface AccessControlSecurityProfilesAddEditProps {
    isEditMode: boolean;
    securityProfileId: number;
    goBack: () => void;
    reloadSecurityProfiles: () => void;
}

interface IErrors {
    name?: string;
}

export const permissions = ['Read', 'Create', 'Update', 'Delete'];

const createPostRule = (rule: SecurityRuleDataModel): PostRuleDataModel => {
    const ruleToSend = new PostRuleDataModel();

    ruleToSend.securityRuleId = rule.securityRuleId;
    ruleToSend.create = rule.create;
    ruleToSend.update = rule.update;
    ruleToSend.delete = rule.delete;
    ruleToSend.read = rule.read;

    return ruleToSend;
};

export const AccessControlSecurityProfilesAddEdit: FunctionComponent<AccessControlSecurityProfilesAddEditProps> = ({
    isEditMode,
    securityProfileId,
    reloadSecurityProfiles,
    goBack,
}) => {
    const classes = styles();
    const abortController = new AbortController();
    const [securityProfile, setSecurityProfile] = React.useState<SecurityProfileDataModel>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<IErrors>(null);

    useEffect(() => {
        if (securityProfileId !== null) {
            handleGetSecurityProfileId(securityProfileId);
        } else {
            handleGetNewSecurityProfile();
        }
    }, [securityProfileId]);

    const handleGetSecurityProfileId = async (securityProfileId: number) => {
        setIsLoading(true);
        await getSecurityProfileById(securityProfileId, abortController.signal)
            .then((res) => {
                setSecurityProfile(res);
            })
            .catch((e) => setIsLoading(false));

        setIsLoading(false);
    };

    const handleGetNewSecurityProfile = async () => {
        setIsLoading(true);
        await getNewSecurityProfile(abortController.signal)
            .then((res) => {
                setSecurityProfile(res);
            })
            .catch((e) => setIsLoading(false));

        setIsLoading(false);
    };

    const findByParentId = (updatedSecurityRules: SecurityRuleDataModel[], parentId: number) => {
        for (let rule of updatedSecurityRules) {
            if (rule.securityRuleId === parentId) {
                return rule;
            }
            if (rule.children != null) {
                let found = findByParentId(rule.children, parentId);
                if (found) return found;
            }
        }
        return false;
    };

    const updateParentRule = (updatedSecurityRules: SecurityRuleDataModel[], rule: SecurityRuleDataModel) => {
        for (let i = 0; i < permissions.length; i++) {
            const lowerCaseP = permissions[i].toLowerCase();
            const isFullAccess = rule.children.every((r) => r[lowerCaseP]);
            if (isFullAccess) {
                rule[lowerCaseP] = true;
                continue;
            }

            const isNoneAccess = rule.children.every((r) => r[lowerCaseP] === false);
            if (isNoneAccess) {
                rule[lowerCaseP] = false;
                continue;
            }

            rule[lowerCaseP] = null;
        }
        if (rule.parentId) {
            let found = findByParentId(updatedSecurityRules, rule.parentId);
            updateParentRule(updatedSecurityRules, found);
        }
    };

    const handleChangeRulePermission = (updatedSecurityRule: SecurityRuleDataModel, parentId: number) => {
        const updatedSecurityProfileRules = [...securityProfile.rules];

        let parentRule: SecurityRuleDataModel = null;

        if (parentId !== null) {
            parentRule = findByParentId(updatedSecurityProfileRules, parentId);
        }

        if (parentRule !== null) {
            const childrenIdx = parentRule.children.findIndex(
                (r) => r.securityRuleId === updatedSecurityRule.securityRuleId,
            );
            parentRule.children[childrenIdx] = updatedSecurityRule;

            updateParentRule(updatedSecurityProfileRules, parentRule);
        } else {
            const idx = updatedSecurityProfileRules.findIndex(
                (pr) => pr.securityRuleId === updatedSecurityRule.securityRuleId,
            );
            updatedSecurityProfileRules[idx] = updatedSecurityRule;
        }

        setSecurityProfile({ ...securityProfile, rules: [...updatedSecurityProfileRules] });
    };

    const createChildrenPostRule = (
        children: SecurityRuleDataModel[],
        securityProfileToSend: PostSecurityProfileDataModel,
    ) => {
        for (let rule of children) {
            securityProfileToSend.rules.push(createPostRule(rule));
            rule.children.forEach((childRule) => {
                securityProfileToSend.rules.push(createPostRule(childRule));
                createChildrenPostRule(childRule.children, securityProfileToSend);
            });
        }
    };

    const handleCreateSecurityProfile = () => {
        if (!validate()) return;

        setIsLoading(true);
        const securityProfileToSend = new PostSecurityProfileDataModel();
        securityProfileToSend.name = securityProfile.name;
        securityProfileToSend.description = securityProfile.description;
        securityProfileToSend.rules = [];

        securityProfile.rules.forEach((rule) => {
            securityProfileToSend.rules.push(createPostRule(rule));

            if (rule.children.length > 0) {
                createChildrenPostRule(rule.children, securityProfileToSend);
            }
        });

        if (isEditMode) {
            editSecurityProfile(securityProfileId, securityProfileToSend, abortController.signal)
                .then(() => {
                    setIsLoading(false);
                    setSecurityProfile(null);
                    reloadSecurityProfiles();
                    goBack();
                })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            addSecurityProfile(securityProfileToSend, abortController.signal)
                .then(() => {
                    setIsLoading(false);
                    setSecurityProfile(null);
                    reloadSecurityProfiles();
                    goBack();
                })
                .catch(() => {
                    setIsLoading(false);
                });
        }
    };

    const handleCancel = () => {
        setSecurityProfile(null);
        goBack();
    };

    const validate = () => {
        let errors: IErrors = {};
        errors.name = !!securityProfile.name ? '' : 'Required';

        setErrors(errors);

        return Object.values(errors).every((x) => x == '');
    };

    return isLoading ? (
        <Loader isSmall />
    ) : (
        <>
            <Paper className={classes.paper}>
                <Typography>SECURITY PROFILE & PERMISSIONS</Typography>
                <Grid container spacing={3} xs={12} className={classes.textfieldsContainer}>
                    <Grid item xs={6}>
                        <TextField
                            label="Security Profile Name"
                            value={securityProfile?.name}
                            onChange={(e) => {
                                setSecurityProfile({ ...securityProfile, name: e.target.value });
                                validate();
                            }}
                            required
                            error={!!errors?.name}
                            helperText={errors?.name}
                            disabled={isEditMode}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label="Security Profile Description"
                            value={securityProfile?.description}
                            onChange={(e) => setSecurityProfile({ ...securityProfile, description: e.target.value })}
                        />
                    </Grid>
                </Grid>
                <Grid container xs={12}>
                    <Grid item xs={3}>
                        Permission Group
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.accessGridTitle}>Access</Typography>
                    </Grid>
                    {permissions.map((p) => (
                        <Grid item xs={1} key={p} className={classes.permissionTitle}>
                            {p}
                        </Grid>
                    ))}
                    <Grid item container xs={12}>
                        {securityProfile?.rules.map((rule) => (
                            <AccessControlSecurityRuleAccordion
                                key={rule.securityRuleId}
                                rule={rule}
                                handleParentChangeRulePermission={handleChangeRulePermission}
                            />
                        ))}
                    </Grid>
                </Grid>
            </Paper>
            <Portal elementId="router-footer">
                <Box className={classes.buttonsContainer}>
                    <Button className={classes.cancelButton} onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button className={classes.button} onClick={handleCreateSecurityProfile}>
                        {isEditMode ? 'Save' : 'Add'} Security Profile
                    </Button>
                </Box>
            </Portal>
        </>
    );
};
