import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    clientListPaper: {
        padding: '10px 25px 100px',
        margin: '25px',
        '& th': {
            background: '#F0F0F0',
        },
        '& a': {
            color: '#1599BC',
            textDecoration: 'none',
        },
    },
    sidebarTitle: {
        color: '#428302',
    },
    gridContainer: {
        width: '20vw',
        minHeight: '280px',
    },
    blackFont: {
        color: '#000000',
    },
    redFont: {
        color: '#e74c3c',
    },
    dataLabel: {
        color: '#898F91',
        fontSize: '12px',
    },
    paper: {
        paddingLeft: '25px',
        paddingRight: '25px',
        paddingBottom: '20px',
    },
    paperHeader: {
        padding: '0',
        marginLeft: '15px',
        flexDirection: 'row-reverse',
    },
    paperHeaderIcon: {
        color: '#428302',
    },
    textAreaGrid: {
        paddingLeft: '35px!important',
    },
    fullWidthInput: {
        width: '100%',
    },
    selectInput: {
        width: '100%',
    },
    listHeaderSubTitle: {
        fontSize: '12px',
        paddingTop: '10px',
        color: '#898F91',
    },
    headerContainer: {
        display: 'grid',
    },
    blueFont: {
        color: '#1599BC',
        marginBottom: '5px',
    },
    textArea: {
        overflow: 'hidden !important',
    },
    errorMessageContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
}));
