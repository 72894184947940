import { Button, Paper } from '@material-ui/core';
import { MaterialTableProps } from 'material-table';
import React, { FunctionComponent, useEffect } from 'react';
import UserAdminTableDataModel from '../../../data-models/UserAdminTableDataModel';
import { deleteUser, getUsers } from '../../../services/Users.Service';
import styles from './AccessControlUsers.Styles';
import MaterialTable from '../../generic-components/material-table/MaterialTable.Component';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link, Route, useHistory } from 'react-router-dom';
import { AccessControlUserProfile } from '../access-control-user-profile/AccessControlUserProfile.Component';
import { Confirm } from '../../generic-components/confirm/Confirm.Component';
import { AccessControlUserNew } from '../access-control-user-new/AccessControlUserNew.Component';
import { toast } from 'react-toastify';


export const AccessControlUsers: FunctionComponent<any> = () => {
    const classes = styles();
    const abortController = new AbortController();

    const [filter, setFilter] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [users, setUsers] = React.useState<Array<UserAdminTableDataModel>>([]);
    const [selectedUser, setSelectedUser] = React.useState<number>(null);
    const [showDeleteUserDialog, setShowDeleteUserDialog] = React.useState<boolean>(false);

    useEffect(() => {
        handleInitialLoad();
    }, []);

    const history = useHistory();

    const handleInitialLoad = async () => {
        setIsLoading(true);
        handleGetUsers();
    };

    const handleGetUsers = async () => {
        if (!isLoading) {
            setIsLoading(true);
        }

        const usersResult = await getUsers(abortController.signal);
        setUsers(usersResult);
    };

    const handleDeleteUser = () => {
        deleteUser(selectedUser, abortController.signal).then(() => {
            toast.success('User deleted successfully');
            handleGetUsers();
        });
    };

    const handleNewReferralClick = () => {
        history.push('/admin/access-control/users/user/new');
    };

    const usersTableProps: MaterialTableProps<UserAdminTableDataModel> = {
        columns: [
            {
                title: 'Name',
                render: (rowData) => (
                    <Link to={`/admin/access-control/users/${rowData.userId}`}>
                        {rowData.firstName + ' ' + rowData.lastName}
                    </Link>
                ),
            },
            {
                title: 'Email Address',
                field: 'email',
            },
            {
                title: 'Mobile Contact Phone',
                field: 'mobilePhone',
            },
            {
                title: 'Job Title',
                field: 'jobTitle',
            },
            {
                title: 'Tenant',
                field: 'tenantName',
            },
            {
                title: 'vendor',
                field: 'vendorName',
            },
        ],
        data: users ?? [],
        options: {
            filtering: filter,
            showTitle: true,
            paging: users ? users.length > 10 : false,
            toolbar: true,
            actionsColumnIndex: -1,
            exportButton: true,
            exportFileName: 'TerraClaimConnect_Admin_UsersList',
            pageSizeOptions: [10, 25, 50],
            pageSize: 50,
            paginationPosition: 'top',
            emptyRowsWhenPaging: false,
        },
        style: {
            paddingLeft: '35px',
            paddingRight: '35px',
        },
        actions: [
            {
                icon: 'tune',
                tooltip: 'Filter',
                isFreeAction: true,
                onClick: () => setFilter(!filter),
            },
            {
                icon: (props) => (
                    <Button {...props} className={classes.button}>
                        Add User
                    </Button>
                ),
                tooltip: 'Add User',
                position: 'toolbar',
                onClick: () => handleNewReferralClick(),
                /*onClick: (event, rowData) => {
                    // setShowAddUser(true);
                    // setIsEditMode(false);
                    // props.setCurrentSections([
                    //     {
                    //         name: ' Users',
                    //         goBack: false,
                    //         onClick: () => handleCancel()
                    //     },
                    //     {
                    //         name: 'Add User',
                    //         goBack: false,
                    //         onClick: null
                    //     }
                    // ]);
                },*/
            },
            {
                icon: () => <DeleteIcon style={{ fill: 'rgba(0,0,0,0.54)' }} />,
                tooltip: 'delete user',
                position: 'row',
                onClick: (event, rowData) => {
                    if (Array.isArray(rowData)) return;
                    setSelectedUser(rowData.userId);
                    setShowDeleteUserDialog(true);
                    // props.handleDeleteUser(rowData.userId);
                },
            },
        ],
        title: 'User List',
    };

    return (
        <>
            <Route path="/admin/access-control/users" exact>
                <>
                    <Paper className={classes.paper}>
                        <MaterialTable tableProps={usersTableProps} />
                    </Paper>
                    <Confirm
                        onAction={() => {
                            handleDeleteUser();
                            setShowDeleteUserDialog(false);
                        }}
                        title="Delete User"
                        onToggleConfirm={() => {
                            setShowDeleteUserDialog(false);
                        }}
                        openDialog={showDeleteUserDialog}
                        okText="Delete"
                        noText="Cancel"
                        messageText="Are you sure you want to delete this user?"
                    />
                </>
            </Route>
            <Route path="/admin/access-control/users/user/new" exact>
                <AccessControlUserNew />
            </Route>
            <Route path="/admin/access-control/users/:id" exact>
                <AccessControlUserProfile />
            </Route>
        </>
    );
};
