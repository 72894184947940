import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 100;

export default makeStyles((theme: Theme) => ({
    formContainer:{
        paddingRight:'25px',
        paddingLeft:'25px',
        paddingTop:'20px',
        paddingBottom:'20px',
    },
    button:{
        color:'white',
        backgroundColor:'#107A98',
        marginLeft:'20px',
        '&:hover': {
            backgroundColor: '#004763!important',
        },
    },
    cancelButton:{
        color:'black',
        backgroundColor:'#e5e5e5',
        marginLeft:'40px',
        '&:hover': {
            backgroundColor: '#f0f0f0 !important',
        },
    },
    buttonsContainer:{
        display:'flex',
        alignItems:'center',
        marginTop:'40px',
        justifyContent:'flex-end'
    },
    selectInput: {
      width:'100%',
    },
    gridContainer:{
        paddingRight:'20px',
        paddingLeft:'40px'
    },
    paperTitle: {
        color: '#428302',
        marginBottom:'15px',
    },
}));