import { apiDelete, apiGet, apiPost, apiPut } from '../support/helpers/ApiConnection.Helper';
import UserEntity from '../entities/User.Entity';
import UserAdminTableDataModel from '../data-models/UserAdminTableDataModel';
import UserWithRulesDataModel from '../data-models/UserWithRules.DataModel';

export default function getProfile(abortSignal: AbortSignal): Promise<UserEntity> {
    return apiGet<UserEntity>('/users', abortSignal);
}

export function getUsers(abortSignal: AbortSignal): Promise<Array<UserAdminTableDataModel>> {
    return apiGet<Array<UserAdminTableDataModel>>('/users/users-admin-table', abortSignal);
}

export function deleteUser(userId: number, abortSignal: AbortSignal): Promise<UserEntity> {
    return apiDelete<UserEntity>('/users', userId, abortSignal);
}

export function createUser(newUser: UserEntity, abortSignal: AbortSignal): Promise<UserEntity> {
    return apiPost<UserEntity>('/users', newUser, abortSignal);
}

export function editUser(newUser: UserEntity, abortSignal: AbortSignal): Promise<UserEntity> {
    return apiPut<UserEntity>('/users', newUser.userId, newUser, abortSignal);
}

export function getUserProfile(userId: string, abortSignal: AbortSignal): Promise<UserWithRulesDataModel> {
    return apiGet<UserWithRulesDataModel>('/users/profile/' + userId, abortSignal);
}

export function userAssignSecurityProfile(
    userId: number,
    newSecurityProfilesIds: number[],
    removedSecurityProfileIds: number[],
    abortSignal: AbortSignal,
): Promise<boolean> {
    return apiPost<boolean>(
        '/users/profile/update-security-profiles/' + userId,
        { newSecurityProfilesIds, removedSecurityProfileIds },
        abortSignal,
    );
}
