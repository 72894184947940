import React, { Fragment, FunctionComponent } from 'react';
import { List, ListItem, ListItemText, FormControlLabel, Radio, Typography } from '@material-ui/core';
import styles from "./ReferralService.Styles";
import VendorEntity from '../../../../entities/Vendor.Entity';
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import { CustomMessageBox } from '../../../generic-components/custom-message-box/CustomMessageBox.Component';

interface IMedicalServiceListProps {
    vendors: Array<VendorEntity>;
}

export const ReferralVendorList: FunctionComponent<IMedicalServiceListProps> = ({ vendors }: IMedicalServiceListProps) => {    
    let messageToDisplay = '';
    if(vendors.length > 0 && vendors[0].vendorId == 0)
    {
        messageToDisplay = vendors[0].vendorName;
    }
    const classes = styles();
    return (
        <Fragment>
            {
                vendors.length > 0 && vendors[0].vendorId > 0 ?
                    <Fragment>
                        {
                            vendors.filter(x => x.isPreferred).length > 0 ?
                                <Fragment>
                                    <Typography variant="caption" className={classes.headerTitleBold}>Client Preferred Vendors</Typography>
                                    <List component="div" disablePadding>
                                        {
                                            vendors.map((vendor, sindex) => {
                                                if (vendor.isPreferred) {
                                                    return (
                                                        <ListItem key={vendor.vendorId} button className={classes.nested}>
                                                            <ListItemText  >
                                                                <FormControlLabel
                                                                    value={vendor.vendorId}
                                                                    control={<Radio className={classes.radioButton} />}
                                                                    label={<span className={classes.radioButtonLabel}>
                                                                        {vendor.vendorName}
                                                                    </span>}
                                                                />
                                                            </ListItemText>
                                                        </ListItem>
                                                    )
                                                } else {
                                                    return ''
                                                }
                                            })
                                        }
                                    </List>
                                </Fragment> : ''
                        }
                        {
                            vendors.filter(x => !x.isPreferred).length > 0 ?
                                <Fragment>
                                    <Typography variant="caption" className={classes.listHeaderSubTitle}>CCMSI Vendor Partner</Typography>
                                    <List component="div" disablePadding>
                                        {
                                            vendors.map((vendor, sindex) => {
                                                if (!vendor.isPreferred) {
                                                    return (
                                                        <ListItem key={vendor.vendorId} button className={classes.nested}>
                                                            <ListItemText  >
                                                                <FormControlLabel
                                                                    value={vendor.vendorId}
                                                                    control={<Radio className={classes.radioButton} />}
                                                                    label={<span className={classes.radioButtonLabel}>
                                                                        {vendor.vendorName}
                                                                    </span>}
                                                                />
                                                            </ListItemText>
                                                        </ListItem>
                                                    )
                                                } else {
                                                    return ''
                                                }
                                            })
                                        }
                                    </List>
                                </Fragment> : ''
                        }
                    </Fragment> : <div className={classes.customMessage}> <CustomMessageBox label={messageToDisplay}></CustomMessageBox></div>
            }
        </Fragment>
    );
}
