import React, { FunctionComponent, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import TextfieldMask from '../../../generic-components/texfield-mask/TextfieldMask.Component';
import styles from "./ReferralClient.Styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StateEntity from '../../../../entities/State.Entity';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import { ClearSelect } from "../../../generic-components/clear-select/ClearSelect.Component";
import { ServicesEnum } from '../../../../support/enums/Services.Enum';

interface IReferralClientProps {
    referralId: number,
    states: Array<StateEntity>,
    referralClient: ReferralContactEntity,
    handleClientEdit: any,
    serviceId: number;
    handleUpdateClient: () => void;
    openCloseStatus: Object;
    handleOpenCloseStatus: any;
}

export const ReferralClient: FunctionComponent<IReferralClientProps> = (props: IReferralClientProps) => {
    const classes = styles();
    const abortController = new AbortController();

    const [referralClientState, setreferralClientState] = React.useState(props.referralClient);
    //functional component states

    //componentDidMount
    useEffect(() => {
        if ( !props.openCloseStatus.hasOwnProperty("Client_box" ) )  props.handleOpenCloseStatus("Client_box", true);
    },[]);
    
    useEffect(() => {
        setreferralClientState(props.referralClient);
        return function cleanup() {
            abortController.abort();
        }
    }, [props]);

    const handleOpenCloseStatus = ( event, boxName ) => {
        event.persist();
        props.handleOpenCloseStatus( boxName, !(props.openCloseStatus?.[boxName]??true) );
    };
    
    const handleTextFieldChangeClient = (event) => {
        event.persist();
        let newClient: ReferralContactEntity = referralClientState;
        newClient[event.target.id ? event.target.id : event.target.name] = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value;
        setreferralClientState(newClient);
        props.handleClientEdit(newClient);
    };

    const handleClearSelectFieldChangeClient = (selectId: string) => {
        let newClient: ReferralContactEntity = referralClientState;
        newClient[selectId] = null;
        setreferralClientState(newClient);
        props.handleClientEdit(newClient);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Accordion className={classes.paper} expanded={props.openCloseStatus["Client_box"]??true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                        aria-controls="panel1a-content"
                        id="client-accordion"
                        className={classes.paperHeader}
                        onClick={(event) => handleOpenCloseStatus(event, "Client_box")}
                    >
                        <Typography variant="subtitle2" className={classes.sidebarTitle}>Client</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            referralClientState ?
                                <Grid container spacing={3} className={classes.gridContainer}>
                                    <Grid item xs={3}>
                                        <TextField label="Client Name" id="Name" disabled={true} onChange={(event) => handleTextFieldChangeClient(event)} value={referralClientState?.name} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField label="Address Line 1" id="AddressLine1" disabled={true} onChange={(event) => handleTextFieldChangeClient(event)} value={referralClientState?.addressLine1} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask 
                                            mask="(999)-9999999"
                                            disabled={true}   
                                            onChange={(event) => handleTextFieldChangeClient(event)} 
                                            value={referralClientState?.primaryPhoneNumber}    
                                            label="Primary Phone" 
                                            id="primaryPhoneNumber"    
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField label="Ext." id="PrimaryPhoneExtension" disabled={true} onChange={(event) => handleTextFieldChangeClient(event)} value={referralClientState?.primaryPhoneExtension} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField label="Client Number" id="number" disabled={true} onChange={(event) => handleTextFieldChangeClient(event)} value={referralClientState?.number} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField label="Address Line 2" id="AddressLine2" disabled={true} onChange={(event) => handleTextFieldChangeClient(event)} value={referralClientState?.addressLine2} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask 
                                            mask="(999)-9999999"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClient(event)} 
                                            value={referralClientState?.secondaryPhoneNumber}    
                                            label="Secondary Phone" 
                                            id="secondaryPhoneNumber" 
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField label="Ext." id="SecondaryPhoneExtension" disabled={true} onChange={(event) => handleTextFieldChangeClient(event)} value={referralClientState?.secondaryPhoneExtension} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField label="Contact First Name" id="firstName" disabled={true} onChange={(event) => handleTextFieldChangeClient(event)} value={referralClientState?.firstName} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField label="City" id="CityName" disabled={true} onChange={(event) => handleTextFieldChangeClient(event)} value={referralClientState?.cityName} />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask 
                                            mask="(999)-9999999"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClient(event)} 
                                            value={referralClientState?.fax} 
                                            label="Fax" 
                                            id="fax" 
                                        />                                       
                                    </Grid>
                                    <Grid item xs={3}>
                                        {/* Empty space */}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField label="Contact Last Name" id="lastName" disabled={true} onChange={(event) => handleTextFieldChangeClient(event)} value={referralClientState?.lastName} />
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Grid item xs={4}>
                                            <FormControl className={classes.selectInput}>
                                                <InputLabel id="demo-simple-select-label">State</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="stateId"
                                                    name="stateId"
                                                    key={referralClientState?.stateId}
                                                    value={referralClientState?.stateId}
                                                    disabled={true}
                                                    onChange={(event) => handleTextFieldChangeClient(event)}
                                                    endAdornment={
                                                        <ClearSelect
                                                            shouldRender={false}
                                                            onClick={() => handleClearSelectFieldChangeClient('stateId')}
                                                        />
                                                    }
                                                >
                                                    {
                                                        props.states.map((state, index) => {
                                                            return (
                                                                <MenuItem key={state.stateId} value={state.stateId}>{state.stateName}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField label="Email" id="Email" disabled={true} onChange={(event) => handleTextFieldChangeClient(event)} value={referralClientState?.email} />
                                    </Grid>
                                    {
                                        props.serviceId === ServicesEnum.Diagnostic ?
                                        <Grid container xs={3} spacing={1} style={{ marginTop:'9px', marginRight: '4px'}}>
                                            <Grid item xs={3} style={{ marginLeft: '15px', marginRight: '-8px' }}>
                                                <TextField
                                                    label="Zip Code" 
                                                    id="ZipCode" 
                                                    disabled={true} 
                                                    onChange={(event) => handleTextFieldChangeClient(event)} 
                                                    value={referralClientState?.zipCode}
                                                    style={{ width: '110px'}}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Grid item xs={3} style={{ marginTop:'17px',marginLeft: '15px', marginRight: '-8%' }}>
                                                    _
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3} style={{marginRight: '15px'}}>
                                                <TextField
                                                    disabled={true}
                                                    label="Zip Code Ext."
                                                    id="zipCodeExtension"
                                                    onChange={(event) => handleTextFieldChangeClient(event)}
                                                    value={referralClientState.zipCodeExtension}
                                                    style={{ width: '100px' }}
                                                    inputProps={{ maxLength: 4 }}
                                                />
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid item xs={3}>
                                            <TextField label="Zip Code" id="ZipCode" disabled={true} onChange={(event) => handleTextFieldChangeClient(event)} value={referralClientState?.zipCode} />
                                        </Grid>
                                    }
                                </Grid>
                                : <EmptyDataLabel></EmptyDataLabel>
                        }
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}