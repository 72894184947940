import React, { FunctionComponent, useEffect } from 'react';
import styles from "./DxHeaderSummary.Styles";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { MaterialTableProps } from 'material-table';
import MaterialTable from "../../../../../../components/generic-components/material-table/MaterialTable.Component";
import DiagnosticProcedureTableDataModel from '../../../../../../data-models/DiagnosticProcedureTable.DataModel';
import ServiceTypeRequestDxEntity from '../../../../../../entities/ServiceTypeRequestDx.Entity';
import LanguageEntity from '../../../../../../entities/Language.Entity';

interface IDxHeaderSummaryProps {
    proceduresTable: Array<DiagnosticProcedureTableDataModel>;
    serviceTypeRequestDx: ServiceTypeRequestDxEntity;
    languages: Array<LanguageEntity>;
}

export const DxHeaderSummary: FunctionComponent<IDxHeaderSummaryProps> = (props: IDxHeaderSummaryProps) => {
    const classes = styles();
    const abortController = new AbortController();

    const [rowsUpdated, setRowsUpdated] = React.useState<Array<DiagnosticProcedureTableDataModel>>(null);

    useEffect(() => {
        if(!!props.proceduresTable){
            setRowsUpdated(props.proceduresTable);
        }

        return function cleanup() {
            abortController.abort();
        }
    }, [props.proceduresTable]);

    const clientsTableProps: MaterialTableProps<DiagnosticProcedureTableDataModel> = {
        columns: [
            {
                title: 'Service Type',
                field: 'serviceTypeName',
                render: rowData => rowData?.serviceTypeName
            },
            {
                title: 'Specific Body Part',
                field: 'specificBodyPartName',
                render: rowData => rowData?.specificBodyPartName || '-'
            },
            {
                title: 'Body Side',
                field: 'bodySideName',
                render: rowData => rowData?.bodySideName || '-'
            },
            {
                title: 'Diagnosis',
                field: 'diagnosisName',
                render: rowData => rowData?.diagnosisName
            },
            {
                title: 'Procedure Details',
                field: 'procedureDetailsName',
                render: rowData => rowData?.procedureDetailsName || '-'
            },
            {
                title: 'Age Of Injury',
                field: 'ageOfInjuryReview',
                render: rowData => rowData?.ageOfInjuryReview == true ? "Yes" : "No"
            },
        ],
        data: rowsUpdated ? rowsUpdated : [],
        options: {
            filtering: false,
            showTitle: true,
            paging: false,
            toolbar: false,
            actionsColumnIndex: -1,
        },
        style:{
            paddingLeft: '35px',
            paddingRight: '35px',
        },
    }


    return (
        <Accordion defaultExpanded={true} className={classes.paper}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                {<Typography variant="subtitle2" className={classes.sidebarTitle}>Diagnostic</Typography>}
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12}>
                        <MaterialTable tableProps={clientsTableProps} />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="subtitle1" className={classes.blackFont}>Claimant Relevant Information:</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography variant="body2" className={classes.dataLabel}>Height</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>{`${props?.serviceTypeRequestDx?.heightFt??""} Feet ${props?.serviceTypeRequestDx?.heightInches??""} Inches`}</Typography>
                    </Grid> 
                    <Grid item xs={2}>
                        <Typography variant="body2" className={classes.dataLabel}>Weight</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>{`${props?.serviceTypeRequestDx?.weightLbs??""} Lbs`}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        {/* EMPTY SPACE */}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>Rush Request</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>{props?.serviceTypeRequestDx?.rushRequest ? "Yes" : "No"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>Transportation</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>{props?.serviceTypeRequestDx?.transportation ? "Yes" : "No"}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        {/* EMPTY SPACE */}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>Patient Language</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>{props?.serviceTypeRequestDx?.patientLanguage}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>Translation</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>{props?.serviceTypeRequestDx?.translation ? "Yes" : "No"}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>Language</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>{props.serviceTypeRequestDx?.languageId !== 0 ? props.languages?.filter(x => props.serviceTypeRequestDx && props.serviceTypeRequestDx?.languageId && x.languageId == props.serviceTypeRequestDx?.languageId)[0]?.languageName : 'Other'}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>Other Language</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>{props?.serviceTypeRequestDx?.otherLanguage}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" className={classes.dataLabel}>Additional Comments</Typography>
                        {props?.serviceTypeRequestDx?.otherComments?.split('\n').map((sentence, key) => (
                            <Typography key={key} variant="subtitle2" className={classes.blackFont}>
                                {sentence}
                            </Typography>
                        ))}
                    </Grid>
                </Grid>
            </AccordionDetails>

        </Accordion>

    );
}