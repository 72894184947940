import { createStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 320;

export default (theme: Theme) =>
    createStyles({
        content: {
            [theme.breakpoints.up('sm')]: {
                paddingRight: drawerWidth,
            },
        },
        contentWithoutSideBar: {
            [theme.breakpoints.up('sm')]: {
                paddingRight: 0,
            },
        },
        tabs: {
            paddingLeft: '25px',
            paddingRight: '41px',
            paddingTop: '15px',
            marginRight: '8px',
        },
    });
