import React, { Fragment, FunctionComponent } from 'react';
import styles from "./WizardBar.Styles";
import { Typography, Stepper, Step, StepLabel } from '@material-ui/core';

type WizardBarProps = {
    title: string;
    showsteps: string;
    setStepIndex?: ((index: number) => void);
    activeIndex?: number;
    steps?: Array<string>;
}

export const WizardBar: FunctionComponent<WizardBarProps> = (props: WizardBarProps) => {
    const classes = styles();

    return (
        <Fragment>
            <Typography variant="h6" className={[classes.navBarTitle, classes.titleWidth].join(' ')}>{props.title}</Typography>
            {
                (props.showsteps === "1") ? (
                    <div className={classes.rootHeader}>
                        <Stepper activeStep={props.activeIndex} alternativeLabel className={classes.stepper}>
                            {props.steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                ) : (
                        ''
                    )
            }
        </Fragment>
    );
}