import React, { FunctionComponent, useEffect } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core';
import TextfieldMask from '../../../generic-components/texfield-mask/TextfieldMask.Component';
import styles from './ReferralEmployerAttorney.Styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StateEntity from '../../../../entities/State.Entity';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';
import { useValidationDialog } from '../../../../support/custom-hooks/useValidationDialog';
import { ClearSelect } from '../../../generic-components/clear-select/ClearSelect.Component';

interface IReferralEmployerAttorneyProps {
    referralId: number;
    states: Array<StateEntity>;    
    referralEmployer: ReferralContactEntity;
    referralEmployerAttorney: ReferralContactEntity;    
    handleEmployerAttorneyEdit: any;    
    serviceId: number;    
    handleUpdateEmployerAttorney: () => void;
    openCloseStatus: Object;
    handleOpenCloseStatus: (boxName: string, isOpened: boolean) => void;
}

interface IErrors {
    name?: string;
    addressLine1?: string;
    addressLine2?: string;
    zipCode?: string;
    cityName?: string;
    state?: string;
    email?: string;
    primaryPhoneNumber?: string;
    fax?: string;
    attorneyFax?: string;
}

export const ReferralEmployerAttorney: FunctionComponent<IReferralEmployerAttorneyProps> = (props: IReferralEmployerAttorneyProps) => {
    const classes = styles();
    

    const [referralEmployerAttorneyState, setReferralEmployerAttorneyState] = React.useState(
        props.referralEmployerAttorney,
    );
    const [errors, setErrors] = React.useState<IErrors>(null);
    const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState<boolean>(false);    
    const { Dialog, checkForErrors } = useValidationDialog('Validation', 'Some required fields are missing.', 'Ok');

    //componentDidMount
    useEffect(() => {
        
        if ( !props.openCloseStatus.hasOwnProperty("EmployerAdjuster_box" ) ) props.handleOpenCloseStatus("EmployerAdjuster_box", true);
    },[]);
    
    useEffect(() => {
        
        if (props.referralEmployerAttorney?.primaryPhoneNumber?.length == 0) {
            setReferralEmployerAttorneyState({ ...props.referralEmployerAttorney, primaryPhoneNumber: null });
        } else {
            setReferralEmployerAttorneyState(props.referralEmployerAttorney);
        }

    }, [props]);

    const handleOpenCloseStatus = ( event, boxName ) => {
        event.persist();
        props.handleOpenCloseStatus( boxName, !(props.openCloseStatus?.[boxName]??true) );
    };
        
    const handleTextFieldChangeEmployerAttorney = (event) => {
        event.persist();
        let newEmployerAttorney: ReferralContactEntity = referralEmployerAttorneyState;
        newEmployerAttorney[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;
        setReferralEmployerAttorneyState(newEmployerAttorney);
        props.handleEmployerAttorneyEdit(newEmployerAttorney);
        if (hasBeenSubmitted) validate(true);
    };

    const handleClearSelectFieldChangeEmployerAttorney = (selectId: string) => {
        let newEmployerAttorney: ReferralContactEntity = referralEmployerAttorneyState;
        newEmployerAttorney[selectId] = null;
        setReferralEmployerAttorneyState(newEmployerAttorney);
        props.handleEmployerAttorneyEdit(newEmployerAttorney);
        if (hasBeenSubmitted) validate(true);
    };

    const validate = (isChange?: boolean) => {
        let errors: IErrors = {};
        errors = getErrorsListEmployer()
        setErrors(errors);
        return checkForErrors(errors, isChange);
    };

    const isValidEmployer = () => { //returns true/false to know if the form has passed the validation OK (true) or not (false)
        let errors: IErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsListEmployer();
        setErrors(errors);
        isValidResult = (Object.keys(errors).length == 0);
        return isValidResult;
    }

    const getErrorsListEmployer = () => {//returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: IErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }

        if ([
            ServicesEnum.PT,
            ServicesEnum.UR,
            ServicesEnum.TT,
            ServicesEnum.DME]
        .includes( props.serviceId ))
        {                
            if (!props.referralEmployer?.name) errors.name = 'Required';
            if (!props.referralEmployer?.addressLine1) errors.addressLine1 = 'Required';
            if (!props.referralEmployer?.zipCode) errors.zipCode = 'Required';
            if (!props.referralEmployer?.cityName) errors.cityName = 'Required';
            if (!props.referralEmployer?.stateId) errors.state = 'Required';
        }

        return errors;
    }
        
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Accordion className={classes.paper} expanded={props.openCloseStatus["EmployerAdjuster_box"]??true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                            aria-controls="panel1a-content"
                            id="adjuster-accordion"
                            className={classes.paperHeader}
                            onClick={(event) => handleOpenCloseStatus(event, "EmployerAdjuster_box")}
                        >
                            <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                Employer Attorney
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {referralEmployerAttorneyState ? (
                                <Grid container spacing={3} className={classes.gridContainer}>
                                    <Grid item xs={12}>
                                        <FormControl
                                            component="fieldset"
                                            disabled={true}
                                        >
                                            <FormLabel component="legend">Attorney Represented </FormLabel>
                                            <RadioGroup
                                                row
                                                aria-label="position"
                                                name="isRepresented"
                                                onChange={(event) => {
                                                    handleTextFieldChangeEmployerAttorney(event);
                                                    validate();
                                                }}
                                                value={referralEmployerAttorneyState?.isRepresented?.toString()}
                                            >
                                                <FormControlLabel
                                                    value="true"
                                                    control={<Radio color="primary" />}
                                                    label="Yes"
                                                />
                                                <FormControlLabel
                                                    value="false"
                                                    control={<Radio color="primary" />}
                                                    label="No"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Firm"
                                            id="firm"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                            value={referralEmployerAttorneyState?.firm}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Address Line 1"
                                            id="addressLine1"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                            value={referralEmployerAttorneyState?.addressLine1}
                                        />
                                    </Grid>
                                    {props.serviceId === ServicesEnum.Diagnostic ? (
                                        <Grid
                                            container
                                            xs={3}
                                            spacing={1}
                                            style={{ marginTop: '9px', marginRight: '4px' }}
                                        >
                                            <Grid item xs={3} style={{ marginLeft: '15px', marginRight: '-8px' }}>
                                                <TextField
                                                    label="Zip Code"
                                                    id="zipCode"
                                                    disabled={true}
                                                    onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                                    value={referralEmployerAttorneyState?.zipCode}
                                                    style={{ width: '110px' }}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                        marginTop: '17px',
                                                        marginLeft: '15px',
                                                        marginRight: '-8%',
                                                    }}
                                                >
                                                    _
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3} style={{ marginRight: '15px' }}>
                                                <TextField
                                                    disabled={true}
                                                    label="Zip Code Ext."
                                                    id="zipCodeExtension"
                                                    onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                                    value={referralEmployerAttorneyState.zipCodeExtension}
                                                    style={{ width: '100px' }}
                                                    inputProps={{ maxLength: 4 }}
                                                />
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={3}>
                                            <TextField
                                                label="Zip Code"
                                                id="zipCode"
                                                disabled={true}
                                                onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                                value={referralEmployerAttorneyState?.zipCode}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Attorney Full Name"
                                            id="name"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                            value={referralEmployerAttorneyState?.name}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Attorney First Name"
                                            id="firstName"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                            value={referralEmployerAttorneyState?.firstName}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Address Line 2"
                                            id="addressLine2"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                            value={referralEmployerAttorneyState?.addressLine2}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask
                                            mask="(999)-9999999"
                                            label="Primary Phone"
                                            id="primaryPhoneNumber"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                            value={referralEmployerAttorneyState?.primaryPhoneNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Ext."
                                            id="primaryPhoneExtension"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                            value={referralEmployerAttorneyState?.primaryPhoneExtension}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Attorney Last Name"
                                            id="lastName"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                            value={referralEmployerAttorneyState?.lastName}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="City"
                                            id="cityName"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                            value={referralEmployerAttorneyState?.cityName}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask
                                            mask="(999)-9999999"
                                            label="Secondary Phone"
                                            id="secondaryPhoneNumber"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                            value={referralEmployerAttorneyState?.secondaryPhoneNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Ext."
                                            id="secondaryPhoneExtension"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                            value={referralEmployerAttorneyState?.secondaryPhoneExtension}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Email"
                                            id="email"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                            value={referralEmployerAttorneyState?.email}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl className={classes.selectInput}>
                                            <InputLabel id="demo-simple-select-label">State</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="stateId"
                                                name="stateId"
                                                key={referralEmployerAttorneyState?.stateId}
                                                value={referralEmployerAttorneyState?.stateId}
                                                disabled={true}
                                                onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                                endAdornment={
                                                    <ClearSelect
                                                        shouldRender={false}
                                                        onClick={() =>
                                                            handleClearSelectFieldChangeEmployerAttorney('stateId')
                                                        }
                                                    />
                                                }
                                            >
                                                {props.states.map((state, index) => {
                                                    return (
                                                        <MenuItem key={state.stateId} value={state.stateId}>
                                                            {state.stateName}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask
                                            mask="(999)-9999999"
                                            label="Fax"
                                            id="fax"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                            value={referralEmployerAttorneyState?.fax}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        {/* Empty space */}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.selectInput}>
                                            <TextField
                                                multiline={true}
                                                label="Additional Comments"
                                                id="comments"                                                
                                                onChange={(event) => handleTextFieldChangeEmployerAttorney(event)}
                                                value={referralEmployerAttorneyState?.comments}
                                                inputProps={{ maxLength: 250 }}
                                                helperText={`${
                                                    referralEmployerAttorneyState?.comments
                                                        ? referralEmployerAttorneyState?.comments.length
                                                        : 0
                                                }/${250}`}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            ) : (
                                <EmptyDataLabel></EmptyDataLabel>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            {Dialog}
        </>
    );
};
