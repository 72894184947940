import { makeStyles } from "@material-ui/core";

interface IProps{
    ellipsis:boolean,
    shrink: boolean
}

export default makeStyles((theme) => ({
    label:{
        whiteSpace: 'nowrap',
        textOverflow: (props: IProps) => props.ellipsis ? 'ellipsis' : '',
        overflow: 'hidden',
    },
    container:{
        width: '100%',
        overflow:'hidden',
    },
    tooltip:{
        // 1% extra so tooltip still displays
        maxWidth: (props: IProps) => props.ellipsis ? props.shrink ? '126%' : '101%' : 'none',
        overflow:'hidden',
    },
    arrow:{
        left:"15px !important",
    },
    labelTypography:{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    typography:{
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        fontWeight: 500,
    },
    input:{
        textOverflow: 'ellipsis',
    },
    disabledInput:{
        color: 'black',
        opacity:1,
        fontWeight: 500,
    },
    disableCalendarIcon:{
        '&::-webkit-calendar-picker-indicator':{
            display:'none'
        },
    },
    icon:{
        backgroundColor:'white',
    }
}));