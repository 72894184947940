import { Paper, Button } from '@material-ui/core';
import { MaterialTableProps } from 'material-table';
import MaterialTable from '../../generic-components/material-table/MaterialTable.Component';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Route, useRouteMatch, Link } from 'react-router-dom';
import styles from './Vendors.Styles';
import ServiceDataModel from '../../../data-models/Service.DataModel';
import VendorAdminTableDataModel from '../../../data-models/VendorAdminTable.DataModel';
import VendorContactDataModel from '../../../data-models/VendorContact.DataModel';
import VendorAuthCredentialDataModel from '../../../data-models/VendorAuthCredential.DataModel';
import VendorAuthenticationTypeEntity from '../../../entities/AuthenticationType.Entity';
import VendorProfileDataModel from '../../../data-models/VendorProfile.DataModel';
import StateEntity from '../../../entities/State.Entity';
import UserEntity from '../../../entities/User.Entity';
import VendorEntity from '../../../entities/Vendor.Entity';
import VendorContactEntity from '../../../entities/VendorContact.Entity';
import VendorAuthCredentialEntity from '../../../entities/VendorAuthCredential.Entity';
import { getServices } from '../../../services/Services.Service';
import getStates from '../../../services/States.Service';
import getProfile from '../../../services/Users.Service';
import getAuthenticationTypes from '../../../services/AuthenticationTypes.Service';
import { createVendorContact, getVendorContacts } from '../../../services/VendorContacts.Service';
import { createVendorAuthCredential, getVendorAuthCredentials } from '../../../services/VendorAuthCredentials.Service';
import { createVendor, getVendorAdminTable, editVendor, getVendorProfile } from '../../../services/Vendors.Service';
import { UserRolesEnum } from '../../../support/enums/UserRoles.Enum';
import { Loader } from '../../generic-components/loader/Loader.Component';
import { AddVendor } from '../add-vendor/AddVendor.Component';
import { adminNavigationCards } from '../admin-navigation/NavigationCards';
import { VendorProfileController } from '../vendor-profile-controller/VendorProfileController.Component';
import { BreadCrumb } from '../../../support/custom-hooks/useBreadCrum';

interface NewVendorsState {
    loading: boolean;
    currentRoute: string;
    selectedRouteInfo: {
        section: string;
        subRoutes: { label: string; link: string }[];
        subRoutesRoles: UserRolesEnum[][];
    };
    vendors: Array<VendorAdminTableDataModel>;
    states: Array<StateEntity>;
    newVendor: VendorEntity;
    currentSubroutIndex: string;
    vendorProfile: VendorProfileDataModel;
    //Used for saving original state when editing
    originalVendorProfile: VendorProfileDataModel;
    vendorContacts: Array<VendorContactDataModel>;
    vendorAuthCredentials: Array<VendorAuthCredentialDataModel>;
    authenticationTypes: Array<VendorAuthenticationTypeEntity>;
    newVendorContact: VendorContactEntity;
    newVendorAuthCredential: VendorAuthCredentialEntity;
    services: Array<ServiceDataModel>;
    userProfile: UserEntity;
    profileId: number;
}

type PathParamsType = {
    handleId: (id: number) => void;
    // claimId: string;
};

export const NewVendors: FunctionComponent<PathParamsType> = (props) => {
    const [state, setState] = useState<NewVendorsState>({
        loading: false,
        currentRoute: null,
        selectedRouteInfo: null,
        vendors: null,
        states: null,
        newVendor: null,
        currentSubroutIndex: null,
        vendorProfile: null,
        originalVendorProfile: null,
        vendorContacts: null,
        vendorAuthCredentials: null,
        authenticationTypes: null,
        newVendorContact: null,
        newVendorAuthCredential: null,
        services: null,
        userProfile: null,
        profileId: null,
    });

    const [filter, setFilter] = React.useState<boolean>(false);
    const [showAddVendor, setShowAddVendor] = React.useState<boolean>(false);

    const { path } = useRouteMatch();
    const classes = styles();
    const abortController = new AbortController();

    useEffect(() => {
        getUserProfile();
        setState({
            ...state,
            loading: true,
        });
    }, []);

    const getUserProfile = () => {
        setState({
            ...state,
            loading: true,
        });
        Promise.all([
            getStates(abortController.signal),
            getVendorAdminTable(abortController.signal),
            getServices(abortController.signal),
            getProfile(abortController.signal),
            getAuthenticationTypes(abortController.signal),
        ]).then((result) => {
            setState(() => {
                return {
                    ...state,
                    states: result[0],
                    vendors: result[1],
                    services: result[2],
                    userProfile: result[3],
                    authenticationTypes: result[4],
                    currentRoute: 'vendors',
                    loading: false,
                };
            });
        });
    };

    const handleEditNewVendorContact = (editedVendorContact: VendorContactEntity) => {
        setState({
            ...state,
            newVendorContact: editedVendorContact,
        });
    };

    const handleEditNewVendorAuthCredential = (editedVendorAuthCredential: VendorAuthCredentialEntity) => {
        setState({
            ...state,
            newVendorAuthCredential: editedVendorAuthCredential,
        });
    };

    const handleCreateVendorContact = () => {
        setState({
            ...state,
            loading: true,
        });
        createVendorContact(state.newVendorContact, abortController.signal)
            .then(() => {
                getVendorContacts(state.vendorProfile.vendorId, abortController.signal).then((vendorContacts) => {
                    setState(() => {
                        return {
                            ...state,
                            loading: false,
                            newVendorContact: null,
                            vendorContacts,
                        };
                    });
                });
            })
            .catch(() => {
                setState({
                    ...state,
                    loading: false,
                });
            });
    };

    
    const handleCreateVendorAuthCredential = () => {
        setState({
            ...state,
            loading: true,
        });
        createVendorAuthCredential(state.newVendorAuthCredential, abortController.signal)
            .then(() => {
                getVendorAuthCredentials(state.vendorProfile.vendorId, abortController.signal).then((vendorAuthCredentials) => {
                    setState(() => {
                        return {
                            ...state,
                            loading: false,
                            newVendorAuthCredential: null,
                            vendorAuthCredentials,
                        };
                    });
                });
            })
            .catch(() => {
                setState({
                    ...state,
                    loading: false,
                });
            });
    };

    const handleEditNewVendor = (editedVendor: VendorEntity) => {
        setState({
            ...state,
            newVendor: editedVendor,
        });
    };

    const handleCreateVendor = () => {
        setState({
            ...state,
            loading: true,
        });
        createVendor(state.newVendor, abortController.signal)
            .then(() => {
                const selectedCard = adminNavigationCards.find((c) => c.link === '/vendors');
                getVendorAdminTable(abortController.signal).then((vendors) => {
                    setState(() => {
                        return {
                            ...state,
                            states: state.states,
                            vendors,
                            currentRoute: 'vendors',
                            selectedRouteInfo: {
                                section: selectedCard.section,
                                subRoutes: selectedCard.subRoutes,
                                subRoutesRoles: selectedCard.subRoutesRoles ?? null,
                            },
                            newVendor: null,
                            loading: false,
                        };
                    });
                });
            })
            .catch(() => {
                setState({
                    ...state,
                    loading: false,
                });
            });
    };

    const handleStartVendorProfileEdit = () => {
        setState({
            ...state,
            originalVendorProfile: { ...state.vendorProfile },
        });
    };

    const handleCancelVendorProfileEdit = () => {
        setState({
            ...state,
            vendorProfile: { ...state.originalVendorProfile },
        });
    };

    const handleSaveEditedVendorProfile = () => {
        setState({
            ...state,
            loading: true,
        });
        editVendor(state.vendorProfile, state.vendorProfile.vendorId, abortController.signal)
            .then(() => {
                setState({
                    ...state,
                    loading: false,
                });
            })
            .catch(() => {
                setState({
                    ...state,
                    loading: false,
                });
            });
    };

    const handleNavigateToProfile = (id: number, section: string) => {
        if (isNaN(id)) {
            return;
        }

        props.handleId(id);

        setState({
            ...state,
            loading: true,
        });

        Promise.all([getVendorProfile(id, abortController.signal), getVendorContacts(id, abortController.signal), getVendorAuthCredentials(id,abortController.signal)])
            .then((res) => {
                setState({
                    ...state,
                    vendorProfile: res[0],
                    vendorContacts: res[1],
                    vendorAuthCredentials: res[2],
                    loading: false,
                    profileId: id,
                });
            })
            .catch(() => {
                setState({
                    ...state,
                    loading: false,
                });
            });
    };

    const handleVendorProfileEdit = (editedVendorProfile: VendorProfileDataModel) => {
        setState({
            ...state,
            vendorProfile: editedVendorProfile,
        });
    };

    const setColumns = () => {
        return [
            {
                title: 'Number',
                field: 'vendorNumber',
                render: (rowData) => (rowData.vendorNumber === 0 ? '' : rowData.vendorNumber),
            },
            {
                title: 'Name',
                field: 'vendorName',
                render: (rowData) => (
                    <Link
                        to={`${path}/${rowData.vendorId}/profile`}
                        onClick={() => handleNavigateToProfile(rowData.vendorId, 'Vendor')}
                    >
                        {rowData.vendorName}
                    </Link>
                ),
            },
            {
                title: 'Address',
                field: 'address',
            },
            {
                title: 'City',
                field: 'city',
            },
            {
                title: 'State',
                field: 'stateName',
            },
        ];
    };

    const clientsTableProps: MaterialTableProps<VendorAdminTableDataModel> = {
        columns: setColumns(),
        data: state.vendors ? state.vendors : [],
        options: {
            filtering: filter,
            showTitle: true,
            paging: state.vendors ? state.vendors.length > 10 : false,
            toolbar: true,
            exportButton: true,
            exportFileName: 'TerraClaimConnect_Admin_VendorsList',
            pageSizeOptions: [10, 25, 50],
            pageSize: 50,
            paginationPosition: 'top',
            emptyRowsWhenPaging: false,
        },
        style: {
            paddingLeft: '35px',
            paddingRight: '35px',
        },
        actions: [
            {
                icon: 'tune',
                tooltip: 'Filter',
                isFreeAction: true,
                onClick: () => setFilter(!filter),
            },
            {
                icon: (props) => (
                    <Button {...props} className={classes.button}>
                        Add Vendor
                    </Button>
                ),
                tooltip: 'Add Vendor',
                position: 'toolbar',
                onClick: (event, rowData) => {
                    setShowAddVendor(true);
                },
            },
        ],
        title: 'Vendor List',
    };

    const vendorsBreadCrumb = {
        name: 'Vendors',
        goBack: false,
        link: '/vendors',
        path: path,
    };

    return state?.loading ? (
        <Loader isSmall />
    ) : (
        <>
            <Route path={'/vendors/:id'}>
                <VendorProfileController
                    profile={state.vendorProfile}
                    currentTabIndex={state.currentSubroutIndex}
                    states={state.states}
                    authenticationTypes={state.authenticationTypes}
                    handleVendorEdit={handleVendorProfileEdit}
                    handleStartVendorProfileEdit={handleStartVendorProfileEdit}
                    handleCancelVendorProfileEdit={handleCancelVendorProfileEdit}
                    handleSaveEditedVendorProfile={handleSaveEditedVendorProfile}
                    vendorContacts={state.vendorContacts}
                    vendorAuthCredentials={state.vendorAuthCredentials}
                    newVendorContact={state.newVendorContact}
                    handleVendorContactEdit={handleEditNewVendorContact}
                    createVendorContact={handleCreateVendorContact}
                    newVendorAuthCredential={state.newVendorAuthCredential}
                    handleVendorAuthCredentialEdit={handleEditNewVendorAuthCredential}
                    createVendorAuthCredential={handleCreateVendorAuthCredential}
                    services={state.services}
                    handleNavigateToProfile={handleNavigateToProfile}
                />
            </Route>
            <Route path="/vendors" exact>
                <BreadCrumb sections={[vendorsBreadCrumb]} />
                <Paper className={classes.paper}>
                    {showAddVendor ? (
                        <AddVendor
                            newVendor={state.newVendor}
                            handleVendorEdit={handleEditNewVendor}
                            createVendor={handleCreateVendor}
                            states={state.states}
                            cancel={() => setShowAddVendor(false)}
                        />
                    ) : (
                        <MaterialTable tableProps={clientsTableProps} />
                    )}
                </Paper>
            </Route>
        </>
    );
};
