import { Button, Paper, Typography } from "@material-ui/core";
import { MaterialTableProps } from "material-table";
import React, { FunctionComponent } from "react";
import styles from './VendorContacts.Styles';
import MaterialTable from "../../generic-components/material-table/MaterialTable.Component";
import StateEntity from "../../../entities/State.Entity";
import VendorContactDataModel from "../../../data-models/VendorContact.DataModel";
import VendorContactEntity from "../../../entities/VendorContact.Entity";
import { AddVendorContact } from "../add-vendor-contact/AddVendorContact.Component";

interface VendorContactsProps{
    vendorContacts: Array<VendorContactDataModel>;
    newVendorContact: VendorContactEntity,
    handleVendorContactEdit: (newVendor: VendorContactEntity) => void;
    createVendorContact: () => void;
    states: Array<StateEntity>;
    vendorId: number;
}

export const VendorContacts: FunctionComponent<VendorContactsProps> = (props) => {

    const classes = styles();
    
    const [filter, setFilter] = React.useState<boolean>(false);
    const [showAddVendorContact, setShowAddVendorContact] = React.useState<boolean>(false);

    const clientsTableProps: MaterialTableProps<VendorContactDataModel> = {
        columns: [
            {
                title: 'First Name',
                field: 'contactFirstName',
            },
            {
                title: 'Last Name',
                field: 'contactLastName',
            },
            {
                title: 'Email',
                field: 'contactEmail'
            },
            {
                title: 'Work Phone',
                field: 'primaryPhoneNumber'
            },
            {
                title: 'Job Title',
                field: 'jobTitle',
            },
            {
                title: 'Location',
                field: 'location',
            }
        ],
        data: props.vendorContacts ? props.vendorContacts : [],
        options: {
            filtering: filter,
            showTitle: false,
            paging: props.vendorContacts ? props.vendorContacts.length > 10 : false,
            toolbar: true,
        },
        style:{
            paddingLeft: '35px',
            paddingRight: '35px',
        },
        actions:[
            {
              icon:'Add Vendor',
              tooltip: 'Add Vendor',
              position: 'toolbar',
              onClick: (event, rowData) => {
                  //setShowAddVendorContact(true);
              }
            }
          ],
        components:{
            Action: props => 
                <Button {...props}
                    className={classes.button}
                    onClick={() => setShowAddVendorContact(true)} 
                >
                    Add Contact
                </Button>
        },
        title: 'Vendor Contact List'
    }

    return (
        <Paper className={classes.paper}>
                {showAddVendorContact ? 
                <AddVendorContact
                    newVendorContact={props.newVendorContact}
                    handleVendorContactEdit={props.handleVendorContactEdit}
                    createVendorContact={props.createVendorContact}
                    states={props.states}
                    cancel={() => setShowAddVendorContact(false)}
                    vendorId={props.vendorId}
                />
                    : 
                        <MaterialTable tableProps={clientsTableProps} /> 
                }
        </Paper>
    )        
}