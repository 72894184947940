import { FunctionComponent, Fragment, ReactNode } from 'react';
import React from 'react';
import { useSelector } from 'react-redux';
import { UsersState } from '../../../store/reducers/usersReducer';
import { IAdminNavigationCard } from '../../tc-admin/admin-navigation/NavigationCards';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    renderNoAccess: {
        marginLeft: '-60px',
    },
}));

type AccessControlProps = {
    children: ReactNode;
    allowedPermissions: string[];
    sendBack: boolean;
    renderNoAccess?: ReactNode;
};

export function checkPermissions(userPermissions: string[], allowedPermissions: string[], sendBack: boolean) {
    if (
        allowedPermissions?.length > 0 &&
        userPermissions?.some((permission) => allowedPermissions?.includes(permission))
    ) {
        return true;
    } else if (sendBack) {
        window.history.back();
    }
    return false;
}

export function checkAnyPermissions(userPermissions: string[], cards: IAdminNavigationCard[], sendBack: boolean) {
    let allAllowedPermissions = [];
    cards.forEach((c) => {
        allAllowedPermissions = [...allAllowedPermissions, ...c.allowedPermissions];
    });
    if (
        allAllowedPermissions?.length > 0 &&
        userPermissions?.some((permission) => allAllowedPermissions?.includes(permission))
    ) {
        return true;
    } else if (sendBack) {
        window.history.back();
    }
    return false;
}

const AccessControl: FunctionComponent<AccessControlProps> = ({
    children,
    allowedPermissions,
    renderNoAccess,
    sendBack,
}: AccessControlProps) => {
    const userDecurityRules = useSelector<UsersState, UsersState['securityRules']>((state) => state.securityRules);
    const classes = useStyles();

    if (checkPermissions(userDecurityRules, allowedPermissions, sendBack)) {
        return <Fragment>{children}</Fragment>;
    } else if (renderNoAccess == null) {
        return null;
    } else if (renderNoAccess !== null && userDecurityRules.length > 0) {
        return <div className={classes.renderNoAccess}>{renderNoAccess}</div>;
    }
    return null;
};

export default AccessControl;
