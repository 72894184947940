import React, { useState, useEffect, FunctionComponent } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { CustomModal } from "../custom-modal/CustomModal.Component" 
import StateEntity from "../../../entities/State.Entity";
import styles from "./LocationModal.Styles";
import { array, isNullOrEmpty } from "../../../support/common";
import AddressAutocompleteEntity from "../../../entities/ReferralAddress.Entity";

interface ILocationAddress {
  address1: string;
  address2: string;
  city: string;
  stateId?: number;
  stateName: string;
  stateCode: string;
  zipCode: string;
  countryCode: string;
}

interface ILocationModalProps {
    openDialog: boolean;
    onToggleModal: any;
    onAddNewLocation: any;
    states: Array<StateEntity>;
}

interface ISchemaAddress {
  address1: string;  
  city: string;
  stateId?: number;
  zipCode: string;
}

export const LocationModal: FunctionComponent<ILocationModalProps> = ({ openDialog, onToggleModal, onAddNewLocation, states } : ILocationModalProps) => {
  const classes = styles();
  const [location, setLocation] = useState<ILocationAddress>({
    address1: "",
    address2: "",
    city: "",
    stateId: null,
    stateName: "",
    stateCode: "",
    zipCode: "",
    countryCode: "",
  });
  const [validationMessages, setValidationMessages] = useState<ISchemaAddress>({
    address1: "",  
    city: "",
    stateId: null,
    zipCode: "",
  });  
  
  useEffect(() => {
      if (openDialog) {
        setLocation({
          address1: "",
          address2: "",
          city: "",
          stateId: null,
          stateName: "",
          stateCode: "",
          zipCode: "",
          countryCode: "",
        });
        setValidationMessages({
          address1: "",  
          city: "",
          stateId: null,
          zipCode: "",
        });
      }
  }, [openDialog]);

  const hasValueSchemaHandler = (obj: any) => Object.values(obj).some(v => v !== null && typeof v !== "undefined" && v !== "")

  const validationMessagesHandler = (n: string) => { 
    if(n === "address2") return !1;
    return hasValueSchemaHandler(validationMessages) && isNullOrEmpty(validationMessages[n]);
  }

  const validateSchemaHandler = () => {
    return {
      isValid: !Object.values(validationMessages).some(v => isNullOrEmpty(v))
    }
  }

  const inputProps = (name: string, label: string) => ({
      name,
      label,
      value: location[name],
      error: validationMessagesHandler(name),
      onChange: ({ target: { value } }) =>        
        setLocation((ps) => {
          const ns = { ...ps, [name]: value };
          if (name === "stateId") {
              const s = array(states).find((state) => state.stateId === value);
              ns.stateName = s?.stateName;
              ns.stateCode = s?.stateCode
          }
          setValidationMessages(pse => ({ ...pse, [name]: value }));
          return ns;
        })
  });

  const getfullAddress = () => {    
    const mainAddress = `${location.address1 || ""}${location.address1 && location.address2 ? ", " : ""}${location.address2 || ""}`.trim();
    const complementAddress = `${location.city || ""}${location.city && (location.stateCode || location.zipCode) ? ", " : ""}${location.stateCode || ""}${location.stateCode ? " " : ""}${location.zipCode || ""} ${location.zipCode && location.countryCode ? ", " : ""} ${location.countryCode || ""}`.trim();
    return (`${mainAddress}${mainAddress && complementAddress ? " " : ""}${complementAddress}`);
  };

  const acceptHandler = () => {
    
    if(validateSchemaHandler().isValid) {
      onToggleModal();
      const address = new AddressAutocompleteEntity();
      address.id = "";
      address.stateName = location.stateName;
      address.stateCode = location.stateCode;
      address.zipCode = location.zipCode;
      address.streetNumber=  "";
      address.streetName = location.address1;
      address.address2 = location.address2;
      address.city = location.city;
      address.fullAddress = getfullAddress();
      address.name = getfullAddress();
      address.title = getfullAddress();
      onAddNewLocation(address);
    }
  }  

  return (    
    <CustomModal
        closeHandler={() => { onToggleModal(); }} 
        acceptHandler={() => { acceptHandler(); }}
        open={openDialog} 
        title={"Add new location"}
        acceptButtonText={"Add"}
        cancelButtonText={"Cancel"}
        fullWidth={true}
        maxWidth={"xs"}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField fullWidth {...inputProps("address1", "Address 1 *")} className={classes.mb2}/>
          <TextField fullWidth {...inputProps("address2", "Address 2")}  className={classes.mb2}/>
          <TextField fullWidth {...inputProps("city", "City *")}  className={classes.mb2}/>          
          <FormControl fullWidth className={classes.mb2} error={validationMessagesHandler("stateId")}>
            <InputLabel id="demo-simple-select-label">State</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              {...inputProps("stateId", "State *")}
            >
              {array(states).map((state, index) => {
                return (
                  <MenuItem key={state.stateId} value={state.stateId}>
                      {state.stateName}
                  </MenuItem>
                );
              })}
            </Select>
        </FormControl>
          <TextField fullWidth {...inputProps("zipCode", "Zip Code *")} className={classes.mb2}/>
        </Grid>
        <Grid item xs={12}>
          {getfullAddress()}
      </Grid>        
      </Grid>            
    </CustomModal>
  );
}

