import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary } from '@material-ui/core';
import styles from './DmeResponseSummary.Styles';
import Moment from 'moment';
import DmeResponseDataModel from '../../../../data-models/dme/DmeResponse.DataModel';
import { MaterialTableProps } from 'material-table';
import MaterialTable from '../../../generic-components/material-table/MaterialTable.Component';
import ProductInformationEntity from '../../../../entities/dme/ProductInformation.Entity';

interface IDmeResponseSummaryProps {
    dmeResponse: DmeResponseDataModel;
}

export const DmeResponseSummary: FunctionComponent<IDmeResponseSummaryProps> = ({
    dmeResponse,
}: IDmeResponseSummaryProps) => {
    const classes = styles();

    const productProps: MaterialTableProps<ProductInformationEntity> = {
        columns: [
            {
                title: 'Product Category',
                field: 'productCategoryName',
            },
            {
                title: 'Sent Date',
                render: (rowData) => {
                    return rowData.sentDate ? Moment(rowData.sentDate).format('M/D/YYYY') : '';
                },
            },
            {
                title: 'Delivery Scheduled',
                render: (rowData) => {
                    return rowData.deliverySchedule ? Moment(rowData.deliverySchedule).format('M/D/YYYY') : '';
                },
            },
            {
                title: 'Delivered Date',
                render: (rowData) => {
                    return rowData.deliveredDate ? Moment(rowData.deliveredDate).format('M/D/YYYY') : '';
                },
            },
            {
                title: 'Tracking Number',
                field: 'trackingNumber',
            },
            {
                title: 'Rental/Purchase',
                field: 'rentalPurchase',
            },
            {
                title: 'Duration',
                field: 'duration',
            },
            {
                title: 'Returned Date',
                render: (rowData) => {
                    return rowData.returnedDate ? Moment(rowData.returnedDate).format('M/D/YYYY') : '';
                },
            },         
            {
                title: 'Notes',
                field: 'note',
            },
        ],
        data: dmeResponse?.products ?? [],
        options: {
            selection: false,
            paging: false,
            toolbar: false,
        },
    };

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary aria-controls="panel1a-content" id="cliam-accordion" className={classes.paperHeader}>
                <Typography variant="subtitle2" className={classes.paperTitle}>
                    Durable Medical Equipment
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12}>
                        <MaterialTable tableProps={productProps} />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
