import React, { Fragment, FunctionComponent } from 'react';
import styles from './ReferralRequest.Styles';
import { WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReferralOverviewDataModel } from '../../../../data-models/ReferralOverview.DataModel';
import ImeEntity from '../../../../entities/Ime.Entity';
import SpecialtyEntity from '../../../../entities/Specialty.Entity';
import LanguageEntity from '../../../../entities/Language.Entity';
import StateEntity from '../../../../entities/State.Entity';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import SpecificBodyPartEntity from '../../../../entities/SpecificBodyPart.Entity';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';
import { MsReferralRequest } from './ms-referral-request/MsReferralRequest.Component';
import { ImeReferralRequest } from './ime-referral-request/ImeReferralRequest.Component';
import BodySideEntity from '../../../../entities/BodySide.Entity';
import InjuryTypeEntity from '../../../../entities/pt/InjuryType.Entity';
import IMECoverLetterEntity from '../../../../entities/IMECoverLetter.Entity';
import MedicalServiceDataModel from '../../../../data-models/MedicalService.DataModel';
import DiagnosticServiceDataModel from '../../../../data-models/DiagnosticService.DataModel';
import { DxReferralRequest } from './ms-referral-request/DxReferralRequest.Component';
import DiagnosticProcedureDetailDataModel from '../../../../data-models/DiagnosticProcedureDetail.DataModel';
import UtilizationReviewServiceDataModel from '../../../../data-models/UtilizationReviewService.DataModel';
import { UrReferralRequest } from './ms-referral-request/URReferralRequest.Component';
import TransportationDataModel from '../../../../data-models/Transportation.DataModel';
import TranslationDataModel from '../../../../data-models/Translation.DataModel';
import AppointmentTypeEntity from '../../../../entities/AppointmentType.Entity';
import DestinationEntity from '../../../../entities/Destination.Entity';
import InterpretationTypeEntity from '../../../../entities/InterpretationType.Entity';
import OriginationEntity from '../../../../entities/Origination.Entity';
import TransportationRequiredEntity from '../../../../entities/TransportationRequired.Entity';
import ServiceTypeEntity from '../../../../entities/ServiceType.Entity';
import { TtReferralRequest } from './ms-referral-request/TtReferralRequest.Component';
import { WheelchairOrStrecherDetailEntity } from '../../../../entities/WheelchairOrStrecherDetail.Entity';
import { DmeReferralRequest } from './ms-referral-request/DmeReferralRequest.Component';
import DmeServiceDataModel from '../../../../data-models/dme/DmeService.DataModel';

type PathParamsType = {
    id: string;
    claimId: string;
};

type ReferralRequestProps = {
    utilizationReviewService: UtilizationReviewServiceDataModel;
    diagnosticService: DiagnosticServiceDataModel;
    medicalService: MedicalServiceDataModel;
    ime: ImeEntity;
    referralOverviewDataModel: ReferralOverviewDataModel;
    specialties: Array<SpecialtyEntity>;
    languages: Array<LanguageEntity>;
    states: Array<StateEntity>;
    treatingPhysician: ReferralContactEntity;
    recommendedPhysician: ReferralContactEntity;
    specificBodyParts: Array<SpecificBodyPartEntity>;
    bodySides: Array<BodySideEntity>;
    injuryTypes: Array<InjuryTypeEntity>;
    imeCoverLetter: IMECoverLetterEntity;
    serviceId: number;
    procedureDetails: Array<DiagnosticProcedureDetailDataModel>;
    transportationService: TransportationDataModel;
    translationService: TranslationDataModel;
    appointmentTypes: Array<AppointmentTypeEntity>;
    destinations: Array<DestinationEntity>;
    interpretationTypes: Array<InterpretationTypeEntity>;
    originations: Array<OriginationEntity>;
    transportationRequireds: Array<TransportationRequiredEntity>;
    serviceType: Array<ServiceTypeEntity>;
    wheelchairOrStretcherDetails: Array<WheelchairOrStrecherDetailEntity>;

    //dme
    dmeService: DmeServiceDataModel;
} & WithStyles<typeof styles> &
    RouteComponentProps<PathParamsType>;

const ReferralRequestComponent: FunctionComponent<ReferralRequestProps> = (props: ReferralRequestProps) => {
    const { classes } = props;

    return (
        <Fragment>
            {
                <div className={classes.content}>
                    {props.referralOverviewDataModel &&
                        props.referralOverviewDataModel.referralServiceType[0].serviceId == ServicesEnum.IME && (
                            <ImeReferralRequest
                                imeCoverLetter={props.imeCoverLetter}
                                specificBodyParts={props.specificBodyParts}
                                referralOverviewDataModel={props.referralOverviewDataModel}
                                ime={props.ime}
                                languages={props.languages}
                                specialties={props.specialties}
                                states={props.states}
                                treatingPhysician={props.treatingPhysician}
                                recommendedPhysician={props.recommendedPhysician}
                            />
                        )}
                    {props.serviceId == ServicesEnum.PT && (
                        <MsReferralRequest
                            languages={props.languages}
                            serviceType={props.medicalService?.serviceType}
                            referralId={props.referralOverviewDataModel?.referral?.referralId}
                            specificBodyParts={props.specificBodyParts}
                            bodySides={props.bodySides}
                            injuryTypes={props.injuryTypes}
                            states={props.states}
                            medicalService={props.medicalService}
                            serviceId={props.serviceId}
                        />
                    )}
                    {props.serviceId == ServicesEnum.Diagnostic && (
                        <DxReferralRequest
                            serviceType={props.medicalService?.serviceType}
                            procedures={props.diagnosticService?.procedures}
                            referralId={props.referralOverviewDataModel?.referral?.referralId}
                            specificBodyParts={props.specificBodyParts}
                            bodySides={props.bodySides}
                            injuryTypes={props.injuryTypes}
                            states={props.states}
                            serviceId={props.serviceId}
                            diagnosticService={props.diagnosticService}
                            procedureDetails={props.procedureDetails}
                            languages={props.languages}
                        />
                    )}
                    {props.serviceId == ServicesEnum.UR && (
                        <UrReferralRequest
                            injuryTypes={props.injuryTypes}
                            bodySides={props.bodySides}
                            utilizationReviewService={props.utilizationReviewService}
                            states={props.states}
                            referralId={props.referralOverviewDataModel?.referral?.referralId}
                            serviceId={props.serviceId}
                        />
                    )}
                    {props.serviceId === ServicesEnum.TT && (
                        <TtReferralRequest
                            transportationServiceType={props.transportationService}
                            appointmentTypes={props.appointmentTypes}
                            destinations={props.destinations}
                            originations={props.originations}
                            transportationRequireds={props.transportationRequireds}
                            translationServiceType={props.translationService}
                            interpretationTypes={props.interpretationTypes}
                            languages={props.languages}
                            wheelchairOrStretcherDetails={props.wheelchairOrStretcherDetails}
                            states={props.states}
                        />
                    )}
                    {props.serviceId === ServicesEnum.DME && (
                        <DmeReferralRequest states={props.states} dmeService={props.dmeService} />
                    )}
                </div>
            }
        </Fragment>
    );
};

export const ReferralRequest = withRouter(withStyles(styles)(ReferralRequestComponent));
