import { Button, Paper } from '@material-ui/core';
import { MaterialTableProps } from 'material-table';
import React, { FunctionComponent, useEffect } from 'react';
import SecurityProfileList from '../../../data-models/admin/access-control/SecurityProfileList.DataModel';
import MaterialTable from '../../generic-components/material-table/MaterialTable.Component';
import styles from './AccessControlSecurityProfiles.Styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { AccessControlSecurityProfilesAddEdit } from '../access-control-security-profiles-add-edit/AccessControlSecurityProfilesAddEdit.Component';
import { deleteSecurityProfile, getAllSecurityProfiles } from '../../../services/SecurityProfiles.Service';
import { Loader } from '../../generic-components/loader/Loader.Component';
import { DialogCustom } from '../../generic-components/dialog-custom/DialogCustom.Component';
import AccessControl from '../../generic-components/access-control/AccessControl.Component';
import {
    adminRules,
    accessControlRules,
    securityProfilesRules,
} from '../../../support/constants/SecurityRules.Constants';
import { Redirect } from 'react-router-dom';

interface AccessControlSecurityProfilesProps {}

export const AccessControlSecurityProfiles: FunctionComponent<AccessControlSecurityProfilesProps> = (props) => {
    const classes = styles();
    const abortController = new AbortController();

    const [showAddEdit, setShowAddEdit] = React.useState<boolean>(false);
    const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
    const [selectedSecurityProfileId, setSelectedSecurityProfileId] = React.useState<number>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [securityProfiles, setSecurityProfiles] = React.useState<SecurityProfileList[]>([]);
    const [showCantDeleteModal, setShowCantDeleteModal] = React.useState<boolean>(false);
    const [showConfirmDelete, setShowConfirmDelete] = React.useState<boolean>(false);
    const [selectedToDelete, setSelectedToDelete] = React.useState<number>(null);

    const handleStartEdit = (securityProfileId: number) => {
        setSelectedSecurityProfileId(securityProfileId);
        setIsEditMode(true);
        setShowAddEdit(true);
    };

    useEffect(() => {
        handleGetSecurityProfiles();
    }, []);

    const handleGetSecurityProfiles = async () => {
        setIsLoading(true);
        getAllSecurityProfiles(abortController.signal)
            .then((res) => {
                setSecurityProfiles(res);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const securityProfileTableProps: MaterialTableProps<SecurityProfileList> = {
        columns: [
            {
                title: 'Name',
                render: (rowData) => (
                    <div className={classes.link} onClick={() => handleStartEdit(rowData.securityProfileId)}>
                        {' '}
                        {rowData.name}{' '}
                    </div>
                ),
            },
            {
                title: 'Description',
                field: 'description',
            },
            {
                title: 'Users',
                field: 'userCount',
            },
        ],
        data: securityProfiles ?? [],
        options: {
            showTitle: true,
            paging: securityProfiles ? securityProfiles.length > 10 : false,
            toolbar: true,
            actionsColumnIndex: -1,
            exportButton: true,
            exportFileName: 'TerraClaimConnect_Admin_ServiceByVendor',
            pageSizeOptions: [10, 25, 50],
            pageSize: 50,
            paginationPosition: 'top',
            emptyRowsWhenPaging: false,
        },
        style: {
            paddingLeft: '35px',
            paddingRight: '35px',
        },
        actions: [
            {
                icon: (props) => (
                    <Button {...props} className={classes.button}>
                        Add new Profile
                    </Button>
                ),
                tooltip: 'Add Security Profile',
                position: 'toolbar',
                onClick: (event, rowData) => {
                    setShowAddEdit(true);
                    setIsEditMode(false);
                },
            },
            {
                icon: () => <DeleteIcon style={{ fill: 'rgba(0,0,0,0.54)' }} />,
                tooltip: 'Delete Security Profile',
                position: 'row',
                onClick: (event, rowData) => {
                    if (Array.isArray(rowData)) return;
                    handleDelete(rowData.securityProfileId, rowData.userCount);
                },
            },
        ],
        title: 'Security Profile List',
    };

    const handleDelete = async (id: number, userCount: number) => {
        if (userCount > 0) {
            setShowCantDeleteModal(true);
            return;
        }
        setSelectedToDelete(id);
        setShowConfirmDelete(true);
    };

    const handleConfirmDelete = () => {
        setShowConfirmDelete(false);
        setIsLoading(true);
        deleteSecurityProfile(selectedToDelete, abortController.signal)
            .then(() => {
                handleGetSecurityProfiles();
            })
            .catch(() => setIsLoading(false));
    };

    // const accessControlSecurityProfilesAddEditPermissions = [
    //     ...adminRules.create,
    //     ...adminRules.update,
    //     ...adminRules.delete,
    //     ...accessControlRules.create,
    //     ...accessControlRules.update,
    //     ...accessControlRules.delete,
    //     ...securityProfilesRules.create,
    //     ...securityProfilesRules.update,
    //     ...securityProfilesRules.delete,
    // ];
    const accessControlSecurityProfilesAddEditPermissions = [
        ...adminRules.read,
        ...accessControlRules.read,
        ...securityProfilesRules.read,
    ];

    return (
        <Paper className={classes.paper}>
            {showAddEdit ? (
                <AccessControl
                    allowedPermissions={accessControlSecurityProfilesAddEditPermissions}
                    sendBack={false}
                    renderNoAccess={<Redirect to="/not-found" />}
                >
                    <AccessControlSecurityProfilesAddEdit
                        isEditMode={isEditMode}
                        securityProfileId={selectedSecurityProfileId}
                        goBack={() => setShowAddEdit(false)}
                        reloadSecurityProfiles={handleGetSecurityProfiles}
                    />
                </AccessControl>
            ) : isLoading ? (
                <Loader isSmall />
            ) : (
                <MaterialTable tableProps={securityProfileTableProps} />
            )}
            <DialogCustom
                closeHandler={() => setShowCantDeleteModal(false)}
                open={showCantDeleteModal}
                subtitle="Cannot delete security profile because it is assigned to another user"
                acceptHandler={() => setShowCantDeleteModal(false)}
                acceptButtonText="Ok"
            />

            <DialogCustom
                closeHandler={() => setShowConfirmDelete(false)}
                open={showConfirmDelete}
                subtitle="Are you sure you want to delete this service profile?"
                acceptHandler={handleConfirmDelete}
                cancelButtonText="Cancel"
                acceptButtonText="Delete"
            />
        </Paper>
    );
};
