import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary } from '@material-ui/core';
import styles from "./NurseCaseManagerSummary.Styles";
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import StateEntity from '../../../../entities/State.Entity';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';
  
interface INurseCaseManagerSummary {
    referralNurseCaseManager: ReferralContactEntity;
    states: Array<StateEntity>;
    serviceId: number;
}

export const NurseCaseManagerSummary: FunctionComponent<INurseCaseManagerSummary> = (props: INurseCaseManagerSummary) => {
    const classes = styles(); 

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>Nurse Case Manager</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.referralNurseCaseManager ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Case Management Company</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralNurseCaseManager.name}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Address Line 1</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralNurseCaseManager.addressLine1}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>State</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.states?.filter(x => !!props.referralNurseCaseManager && !!props.referralNurseCaseManager.stateId && x.stateId == props.referralNurseCaseManager.stateId)[0]?.stateName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Primary Phone</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralNurseCaseManager.primaryPhoneNumber}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Nurse First Name</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralNurseCaseManager.firstName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Address Line 2</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralNurseCaseManager.addressLine2}</Typography>
                            </Grid>
                            {
                                props.serviceId === ServicesEnum.Diagnostic ?
                                <Grid container xs={3} spacing={1} style={{ marginTop:'9px', marginRight: '4px'}}>
                                    <Grid item xs={3} style={{ marginLeft: '12px', marginRight: '-15%' }}>
                                        <Typography variant="body2" className={classes.dataLabel}>Zip Code</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralNurseCaseManager.zipCode}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Grid item xs={3} style={{ marginTop:'12px',marginLeft: '15px', marginRight: '-8%' }}>
                                            _
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3} style={{marginRight: '15px'}}>
                                        <Typography variant="body2" className={classes.dataLabel}>Zip Code Ext.</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralNurseCaseManager.zipCodeExtension}</Typography>
                                    </Grid>
                                </Grid>
                                :
                                <Grid item xs={3}>
                                    <Typography variant="body2" className={classes.dataLabel}>Zip Code</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.referralNurseCaseManager.zipCode}</Typography>
                                </Grid>

                            }
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Fax</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralNurseCaseManager.fax}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Nurse Last Name</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralNurseCaseManager.lastName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>City</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralNurseCaseManager.cityName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Email</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralNurseCaseManager.email}</Typography>
                            </Grid>
                        </Grid>
                        :
                        <EmptyDataLabel></EmptyDataLabel>
                }
            </AccordionDetails>
        </Accordion>
    );
}