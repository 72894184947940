import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    clientListPaper: {
        padding: '10px 25px 100px',
        marginTop: '20px',
        marginBottom: '20px',
        '& th': {
            background: '#F0F0F0',
        },
        '& a': {
            color: '#1599BC',
            textDecoration: 'none',
        },
    },
    content: {
        // [theme.breakpoints.up('sm')]: {
        //     paddingRight: drawerWidth,
        // },
    },
}));
