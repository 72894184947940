import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@material-ui/core';
import styles from './ReferralSubmit.Styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import ReferralClaimEntity from '../../../../entities/ReferralClaim.Entity';
import ReferralEntity from '../../../../entities/Referral.Entity';
import VendorResponseDataModel from '../../../../data-models/VendorResponse.DataModel';
import ComunicationErrorEntity from '../../../../entities/CommunicationError.Entity';
import ReferralDocumentEntity from '../../../../entities/ReferralDocument.Entity';
import { TooltipTextField } from '../../../generic-components/tooltip-textfield/TooltipTextField.Component';
import { ReferralOverviewDataModel } from '../../../../data-models/ReferralOverview.DataModel';
import ImeEntity from '../../../../entities/Ime.Entity';
import LanguageEntity from '../../../../entities/Language.Entity';
import IMECoverLetterEntity from '../../../../entities/IMECoverLetter.Entity';
import SpecialtyEntity from '../../../../entities/Specialty.Entity';
import SpecificBodyPartEntity from '../../../../entities/SpecificBodyPart.Entity';
import StateEntity from '../../../../entities/State.Entity';
import { ImeReferralRequest } from '../../referral-detail/referral-request/ime-referral-request/ImeReferralRequest.Component';
import { MsReferralRequest } from '../../referral-detail/referral-request/ms-referral-request/MsReferralRequest.Component';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';
import MedicalServiceDataModel from '../../../../data-models/MedicalService.DataModel';
import BodySideEntity from '../../../../entities/BodySide.Entity';
import InjuryTypeEntity from '../../../../entities/pt/InjuryType.Entity';
import DiagnosticServiceDataModel from '../../../../data-models/DiagnosticService.DataModel';
import { DxReferralRequest } from '../../referral-detail/referral-request/ms-referral-request/DxReferralRequest.Component';
import DiagnosticProcedureDetailDataModel from '../../../../data-models/DiagnosticProcedureDetail.DataModel';
import UtilizationReviewServiceDataModel from '../../../../data-models/UtilizationReviewService.DataModel';
import TranslationDataModel from '../../../../data-models/Translation.DataModel';
import TransportationDataModel from '../../../../data-models/Transportation.DataModel';
import AppointmentTypeEntity from '../../../../entities/AppointmentType.Entity';
import DestinationEntity from '../../../../entities/Destination.Entity';
import InterpretationTypeEntity from '../../../../entities/InterpretationType.Entity';
import OriginationEntity from '../../../../entities/Origination.Entity';
import TransportationRequiredEntity from '../../../../entities/TransportationRequired.Entity';
import { UrReferralRequest } from '../../referral-detail/referral-request/ms-referral-request/URReferralRequest.Component';
import { TtReferralRequest } from '../../referral-detail/referral-request/ms-referral-request/TtReferralRequest.Component';
import { WheelchairOrStrecherDetailEntity } from '../../../../entities/WheelchairOrStrecherDetail.Entity';
import { DmeReferralRequest } from '../../referral-detail/referral-request/ms-referral-request/DmeReferralRequest.Component';
import DmeServiceDataModel from '../../../../data-models/dme/DmeService.DataModel';
import ProductCategoryEntity from '../../../../entities/dme/ProductCategory.Entity';

interface IReferralSubmitProps {
    referralId: number;
    referral: ReferralEntity;
    referralClaimant: ReferralContactEntity;
    referralClient: ReferralContactEntity;
    referralClaim: ReferralClaimEntity;
    referralDocuments: Array<ReferralDocumentEntity>;
    vendorName: string;
    selectedServiceNames: string[];
    vendorResponse: VendorResponseDataModel;
    comunnicationError: ComunicationErrorEntity;
    exceptionMessage: string;
    showInfo: boolean;
    redAlert: boolean;
    validationError: boolean;
    ime: ImeEntity;
    referralOverviewDataModel: ReferralOverviewDataModel;
    specialties: Array<SpecialtyEntity>;
    languages: Array<LanguageEntity>;
    states: Array<StateEntity>;
    treatingPhysician: ReferralContactEntity;
    recommendedPhysician: ReferralContactEntity;
    specificBodyParts: Array<number>;
    imeCoverLetter: IMECoverLetterEntity;
    serviceId?: number;

    medicalService: MedicalServiceDataModel;
    diagnosticService: DiagnosticServiceDataModel;
    bodySides: Array<BodySideEntity>;
    injuryTypes: Array<InjuryTypeEntity>;
    procedureDetails: Array<DiagnosticProcedureDetailDataModel>;
    utilizationReviewService: UtilizationReviewServiceDataModel;
    bodyParts: Array<SpecificBodyPartEntity>;

    transportationService: TransportationDataModel;
    translationService: TranslationDataModel;
    appointmentTypes: Array<AppointmentTypeEntity>;
    destinations: Array<DestinationEntity>;
    interpretationTypes: Array<InterpretationTypeEntity>;
    originations: Array<OriginationEntity>;
    transportationRequireds: Array<TransportationRequiredEntity>;
    serviceTypeAbbreviations: Array<string>;
    wheelchairOrStretcherDetails: Array<WheelchairOrStrecherDetailEntity>;

    //DME
    dmeService: DmeServiceDataModel;
}

export const ReferralSubmit: FunctionComponent<IReferralSubmitProps> = (props: IReferralSubmitProps) => {
    {
        const classes = styles();
        const abortController = new AbortController();

        const [referralClaimantState, ] = React.useState(props.referralClaimant);
        //const [, setReferralState] = React.useState(null);
        const [referralClientState, ] = React.useState(props.referralClient);
        const [referralClaimState, ] = React.useState(props.referralClaim);
        const [vendorResponse, ] = React.useState(props.vendorResponse);
        const [comunnicationError, ] = React.useState(props.comunnicationError);
        const [exceptionMessage, ] = React.useState(props.exceptionMessage);
        const [redAlertState, ] = React.useState(props.redAlert);
        const [validationErrorState, ] = React.useState(props.validationError);
        
        //functional component states

        //componentDidMount
        useEffect(() => {
            window.scrollTo({top: 0,
                left: 0,
                behavior: "auto" /*"smooth"*/});
            /*setreferralClaimantState(props.referralClaimant);
            setReferralState(props.referral);
            setReferralClientState(props.referralClient);
            setReferralClaimState(props.referralClaim);
            setVendorResponse(props.vendorResponse);

            setComunnicationError(props.comunnicationError);
            setExceptionMessage(props.exceptionMessage);
            setRedAlertState(props.redAlert);
            setValidationErrorState(props.validationError);*/
            return function cleanup() {
                abortController.abort();
            };
        }, []);

        const communicationErrorTemplate = () => {
            return comunnicationError ? (
                <Grid item xs={12}>
                    <Accordion defaultExpanded={true} className={classes.paper}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                            aria-controls="panel1a-content"
                            id="adjuster-accordion"
                            className={classes.paperHeader}
                        >
                            <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                Communication Errors
                            </Typography>
                        </AccordionSummary>
                        {exceptionMessageTemplate()}
                        <AccordionDetails>
                            <Grid container spacing={3} className={classes.gridContainer}>
                                <Grid xs={12} item container>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" className={classes.dataLabel}>
                                            Status Code
                                        </Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>
                                            {comunnicationError.errorCode}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography variant="body2" className={classes.dataLabel}>
                                            Error Description
                                        </Typography>
                                        <Typography variant="subtitle2" className={classes.redFont}>
                                            {comunnicationError.errorDescription}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            ) : (
                <Fragment></Fragment>
            );
        };

        const exceptionMessageTemplate = () => {
            return exceptionMessage ? (
                <AccordionDetails>
                    {redAlertState ? (
                        <Typography variant="subtitle2" className={classes.redFont}>
                            {exceptionMessage}
                        </Typography>
                    ) : (
                        <Typography variant="caption" className={classes.listHeaderSubTitle}>
                            {exceptionMessage}
                        </Typography>
                    )}
                </AccordionDetails>
            ) : (
                <Fragment></Fragment>
            );
        };

        const validationErrorsTemplate = () => {
            return vendorResponse || exceptionMessage ? (
                <Grid item xs={12}>
                    <Accordion defaultExpanded={true} className={classes.paper}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                            aria-controls="panel1a-content"
                            id="adjuster-accordion"
                            className={classes.paperHeader}
                        >
                            <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                {props.showInfo && !validationErrorState
                                    ? 'Server Information'
                                    : "Vendor's validation Errors"}
                            </Typography>
                        </AccordionSummary>
                        {exceptionMessageTemplate()}
                        <AccordionDetails>
                            <Grid container spacing={3} className={classes.gridContainer}>
                                {vendorResponse
                                    ? vendorResponse.vendorResponse?.map((vendorError) => {
                                          return (
                                              <Grid xs={12} item container>
                                                  <Grid item xs={4}>
                                                      <Typography variant="body2" className={classes.dataLabel}>
                                                          {props.showInfo && !validationErrorState
                                                              ? 'About'
                                                              : 'Field Name'}
                                                      </Typography>
                                                      <Typography variant="subtitle2" className={classes.blackFont}>
                                                          {vendorError.fieldName}
                                                      </Typography>
                                                  </Grid>
                                                  <Grid item xs={8}>
                                                      <Typography variant="body2" className={classes.dataLabel}>
                                                          {props.showInfo && !validationErrorState
                                                              ? 'Description'
                                                              : 'Error Description'}
                                                      </Typography>
                                                      {redAlertState && !validationErrorState ? (
                                                          <Typography variant="subtitle2" className={classes.redFont}>
                                                              {vendorError.errorDescription}
                                                          </Typography>
                                                      ) : (
                                                          <Typography variant="subtitle2" className={classes.blackFont}>
                                                              {vendorError.errorDescription}
                                                          </Typography>
                                                      )}
                                                  </Grid>
                                              </Grid>
                                          );
                                      })
                                    : ''}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            ) : (
                <Fragment></Fragment>
            );
        };

        return (
            <Grid container spacing={3}>
                {validationErrorsTemplate()}
                <Grid item xs={12}>
                    <div>
                        <Accordion defaultExpanded={true} className={classes.paper}>
                            <AccordionSummary
                                aria-controls="panel1a-content"
                                id="cliam-accordion"
                                className={classes.paperHeader}
                            >
                                <div className={classes.headerContainer}>
                                    <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                        New Referral
                                    </Typography>
                                    <Typography variant="caption" className={classes.listHeaderSubTitle}>
                                        {' '}
                                        The following Referral is going to be submitted
                                    </Typography>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={3} className={classes.gridContainer}>
                                    <Grid container spacing={3} item xs={12}>
                                        <Grid item container xs spacing={3} alignContent={'flex-start'}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                                    Claimant
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                {referralClaimantState ? (
                                                    <TooltipTextField
                                                        key="FirstName"
                                                        label="First Name"
                                                        id="firstName"
                                                        disabled={true}
                                                        value={referralClaimantState.firstName}
                                                    />
                                                ) : (
                                                    <EmptyDataLabel></EmptyDataLabel>
                                                )}
                                            </Grid>
                                            <Grid item>
                                                {referralClaimantState ? (
                                                    <TooltipTextField
                                                        key="LasttName"
                                                        label="Last Name"
                                                        id="lastName"
                                                        disabled={true}
                                                        value={referralClaimantState.lastName}
                                                    />
                                                ) : (
                                                    <EmptyDataLabel></EmptyDataLabel>
                                                )}
                                            </Grid>
                                            <Grid item>
                                                {referralClaimantState ? (
                                                    <TooltipTextField
                                                        key="LastFourSSN"
                                                        label="Last Four SSN"
                                                        id="lastFourSSN"
                                                        disabled={true}
                                                        value={referralClaimantState.lastFourSSN}
                                                    />
                                                ) : (
                                                    <EmptyDataLabel></EmptyDataLabel>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs container spacing={3} alignContent={'flex-start'}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                                    Referral
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TooltipTextField
                                                    key="ServiceType"
                                                    label="Service Type"
                                                    id="serviceType"
                                                    disabled={true}
                                                    value={props.selectedServiceNames?.join(', ')}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TooltipTextField
                                                    key="Vendor"
                                                    label="Vendor"
                                                    id="vendor"
                                                    disabled={true}
                                                    value={props.vendorName}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs container spacing={3} alignContent={'flex-start'}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                                    Client
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {referralClientState ? (
                                                    <TooltipTextField
                                                        key="ClientName"
                                                        label="Client Name"
                                                        id="clientName"
                                                        disabled={true}
                                                        value={referralClientState.name}
                                                    />
                                                ) : (
                                                    <EmptyDataLabel></EmptyDataLabel>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={2} container spacing={3} alignContent={'flex-start'}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                                    Claim
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {referralClaimState ? (
                                                    <TooltipTextField
                                                        key="ClaimNumber"
                                                        label="Claim Number"
                                                        id="claimNumber"
                                                        disabled={true}
                                                        value={referralClaimState.claimNumber}
                                                    />
                                                ) : (
                                                    <EmptyDataLabel></EmptyDataLabel>
                                                )}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs container spacing={3} alignContent={'flex-start'}>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                                    Documents attached
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {props.referralDocuments?.length > 0 ? (
                                                    <TooltipTextField
                                                        key="DocumentLength"
                                                        label="Number of documents"
                                                        id="documentLength"
                                                        disabled={true}
                                                        value={props.referralDocuments?.length}
                                                    />
                                                ) : (
                                                    <Typography variant="subtitle2" className={classes.blueFont}>
                                                        {'-'}
                                                    </Typography>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        {props.serviceId === ServicesEnum.IME && (
                            <ImeReferralRequest
                                ime={props.ime}
                                referralOverviewDataModel={props.referralOverviewDataModel}
                                specialties={props.specialties}
                                languages={props.languages}
                                states={props.states}
                                treatingPhysician={props.treatingPhysician}
                                recommendedPhysician={props.recommendedPhysician}
                                specificBodyParts={props.bodyParts.filter((bp) =>
                                    props.specificBodyParts?.includes(bp.specificBodyPartId),
                                )}
                                imeCoverLetter={props.imeCoverLetter}
                            />
                        )}
                        {props.serviceId === ServicesEnum.PT && (
                            <MsReferralRequest
                                serviceType={props.medicalService?.serviceType}
                                injuryTypes={props.injuryTypes}
                                bodySides={props.bodySides}
                                medicalService={props.medicalService}
                                states={props.states}
                                referralId={props.referralId}
                                specificBodyParts={props.bodyParts}
                                serviceId={props.serviceId}
                                languages={props.languages}
                            />
                        )}
                        {props.serviceId === ServicesEnum.Diagnostic && (
                            <DxReferralRequest
                                procedures={props.diagnosticService?.procedures}
                                serviceType={props.medicalService?.serviceType}
                                injuryTypes={props.injuryTypes}
                                bodySides={props.bodySides}
                                diagnosticService={props.diagnosticService}
                                states={props.states}
                                referralId={props.referralId}
                                specificBodyParts={props.bodyParts}
                                serviceId={props.serviceId}
                                procedureDetails={props.procedureDetails}
                                languages={props.languages}
                            />
                        )}
                        {props.serviceId === ServicesEnum.TT && (
                            <TtReferralRequest
                                transportationServiceType={props.transportationService}
                                appointmentTypes={props.appointmentTypes}
                                destinations={props.destinations}
                                originations={props.originations}
                                transportationRequireds={props.transportationRequireds}
                                translationServiceType={props.translationService}
                                interpretationTypes={props.interpretationTypes}
                                languages={props.languages}
                                wheelchairOrStretcherDetails={props.wheelchairOrStretcherDetails}
                                states={props.states}
                            />
                        )}
                        {props.serviceId === ServicesEnum.UR && (
                            <UrReferralRequest
                                injuryTypes={props.injuryTypes}
                                bodySides={props.bodySides}
                                utilizationReviewService={props.utilizationReviewService}
                                states={props.states}
                                referralId={props.referralOverviewDataModel?.referral?.referralId}
                                serviceId={props.serviceId}
                            />
                        )}
                        {props.serviceId === ServicesEnum.DME && (
                            <DmeReferralRequest dmeService={props.dmeService} states={props.states} />
                        )}
                    </div>
                </Grid>
                {communicationErrorTemplate()}
            </Grid>
        );
    }
};
