
const isArray = (arr : any) => Array.isArray(arr);

const array = (arr : any) => (isArray(arr) ? arr : []);

const isNullOrEmpty = (v: any) => v === undefined || v === null || v === "";   

export {
    isArray,
    array,
    isNullOrEmpty
}