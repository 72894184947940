import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 100;
const tablePadding = 35;

export default makeStyles((theme: Theme) => ({
    paper: {
        marginTop: '20px',
        marginBottom: '20px',
        // marginBottom:'20px',
        // width: `calc(100vw - ${drawerWidth + (tablePadding * 2)}px) !important`
        //padding:'10px',
        //paddingTop: '15px',
        //paddingLeft: '35px',
        //paddingRight: '35px',
        //paddingBottom: '25px',
    },
    button: {
        color: 'white',
        backgroundColor: '#107A98',
        marginLeft: '40px',
        '&:hover': {
            backgroundColor: '#004763!important',
        },
        position: 'absolute',
        bottom: '0px',
        right: '40px',
    },
    linksContainer: {
        position: 'relative',
        marginBottom: '20px',
        paddingTop: '20px',
        paddingLeft: '30px',
        // paddingRight: '30px',
        display: 'flex',
        flexDirection: 'column',
    },
    link: {
        marginBottom: '20px',
        fontSize: '18px',
    },
    selectInput: {
        width: '100%',
    },
    breadCrumContainer: {
        paddingTop: '15px',
    },
}));
