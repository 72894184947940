import React, { FunctionComponent } from 'react';
import styles from './TranslationHeaderSummary.Styles';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TooltipTextField } from '../../../../../generic-components/tooltip-textfield/TooltipTextField.Component';
import ServiceTypeRequestTranslationEntity from '../../../../../../entities/ServiceTypeRequestTranslation.Entity';
import AppointmentTypeEntity from '../../../../../../entities/AppointmentType.Entity';
import Moment from 'moment';
import InterpretationTypeEntity from '../../../../../../entities/InterpretationType.Entity';
import LanguageEntity from '../../../../../../entities/Language.Entity';

interface ITranslationHeaderSummaryProps {
    serviceTypeRequestTranslation: ServiceTypeRequestTranslationEntity;
    appointmentTypes: Array<AppointmentTypeEntity>;
    interpretationTypes: Array<InterpretationTypeEntity>;
    languages: Array<LanguageEntity>;
}

export const TranslationHeaderSummary: FunctionComponent<ITranslationHeaderSummaryProps> = (
    props: ITranslationHeaderSummaryProps,
) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                {
                    <Typography variant="subtitle2" className={classes.sidebarTitle}>
                        Translation Service
                    </Typography>
                }
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3} className={classes.gridContainer}>
                    {/* row 1 */}
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Appointment Type"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTranslation?.appointmentTypeId !== 0
                                    ? props.appointmentTypes?.filter(
                                          (x) =>
                                              props.serviceTypeRequestTranslation &&
                                              props.serviceTypeRequestTranslation.appointmentTypeId &&
                                              x.appointmentTypeId ===
                                                  props.serviceTypeRequestTranslation.appointmentTypeId,
                                      )[0]?.appointmentTypeName
                                    : 'Other'
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Other Appointment Type"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTranslation?.appointmentTypeId === 0
                                    ? props.serviceTypeRequestTranslation?.otherAppointmentType
                                    : '-'
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Appointment Date"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={Moment(props.serviceTypeRequestTranslation?.appointmentDate).format('MM/DD/YYYY')}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {/* EMPTY SPACE */}
                    </Grid>
                    {/*row 2 */}
                    <Grid item xs={3}>
                        <TooltipTextField
                            label="Appointment Time"
                            id="appointmentTime"
                            type="typography"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                typeof props.serviceTypeRequestTranslation?.appointmentTime === 'string'
                                    ? props.serviceTypeRequestTranslation?.appointmentTime
                                          ?.split('T')[1]
                                          .substring(0, 5)
                                    : props.serviceTypeRequestTranslation?.appointmentTime
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Appointment Length (Min)"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={props.serviceTypeRequestTranslation?.appointmentLength}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            Rush Request
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {props.serviceTypeRequestTranslation?.rushRequest ? 'Yes' : 'No'}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {/* EMPTY SPACE */}
                    </Grid>
                    {/*row 3 */}
                    <Grid item xs={6}>
                        <TooltipTextField
                            type="typography"
                            label="Appointment Address"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTranslation?.appointmentAddress
                                    ? props.serviceTypeRequestTranslation?.appointmentAddress
                                    : '-'
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Appointment Contact Number"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTranslation?.appointmentContactNumber
                                    ? props.serviceTypeRequestTranslation?.appointmentContactNumber
                                    : '-'
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {/* EMPTY SPACE */}
                    </Grid>
                    {/*row 4 */}
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Language"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTranslation?.languageId !== 0
                                    ? props.languages?.filter(
                                          (x) =>
                                              props.serviceTypeRequestTranslation &&
                                              props.serviceTypeRequestTranslation.languageId &&
                                              x.languageId === props.serviceTypeRequestTranslation.languageId,
                                      )[0]?.languageName
                                    : 'Other'
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Other language"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTranslation?.languageId === 0
                                    ? props.serviceTypeRequestTranslation?.otherLanguage
                                    : '-'
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Interpretation Type"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                !!props.serviceTypeRequestTranslation?.interpretationTypeId
                                    ? props.interpretationTypes?.filter(
                                          (x) =>
                                              props.serviceTypeRequestTranslation &&
                                              props.serviceTypeRequestTranslation.interpretationTypeId &&
                                              x.interpretationTypeId ===
                                                  props.serviceTypeRequestTranslation.interpretationTypeId,
                                      )[0]?.interpretationTypeName
                                    : '-'
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        {/* EMPTY SPACE */}
                    </Grid>
                    {/*row 5 */}
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Translation Type"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTranslation?.translationType
                                    ? props.serviceTypeRequestTranslation?.translationType
                                    : '-'
                            }
                        />
                    </Grid>
                    {/* row 6 */}
                    <Grid item xs={12}>
                        <TooltipTextField
                            type="typography"
                            label="Special instructions"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={
                                props.serviceTypeRequestTranslation?.specialInstructions
                                    ? props.serviceTypeRequestTranslation?.specialInstructions
                                    : '-'
                            }
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
