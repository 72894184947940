import { createTheme , makeStyles } from "@material-ui/core";
import { Theme } from '@material-ui/core/styles';
const drawerWidth = 320;

export default makeStyles((theme: Theme) => ({
  content: {
      [theme.breakpoints.up('sm')]: {
          paddingRight: drawerWidth,
      },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  paperTitle: {
    color: '#428302',
  },
  listHeader: {
    padding: 0,
    lineHeight: 0,
    marginBottom: '16px',
    height: '30px'
  },
  serviceTitle: {
    backgroundColor: "#F3F3F3",
    fontSize: 16,
  },
  listItemTitle: {
    fontWeight: 500
  },
  headerTitleBold: {
    fontWeight: 'bold',
    color :'#000000'
  },
  listHeaderSubTitle: {
    fontSize: '12px',
    color: '#898F91'
  },
  paper: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    display: 'contents'
  },
  nested: {
    paddingLeft: theme.spacing(6),
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  radioButton: {
    padding: theme.spacing(0),
  },
  checkBox: {
    padding: theme.spacing(0),
    // backgroundColor: '#107A98',
    // color: '#107A98'
  },
  radioButtonLabel: {
    fontSize: '0.875rem',
    color: '#000000'
  },
  vendorsGroup: {
    paddingTop: '8px',
  },
  subtitleDiagnostic: {
    display: 'block',
    fontSize: '14px',
  },
  customMessage:{
    textAlign:'center',
    width:'100%',
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

export const medicalServiceTheme = createTheme ({
  overrides: {
    MuiListItem: {
      root: {
        color: '#004763',
      },
    },
    MuiTypography:{
      body1:{
        fontSize:'0.875rem',
        fontWeight: 500
      }
    },
    MuiIconButton:{
      label:{
        
      }
    },
    MuiSvgIcon:{
      root:{
        marginRight: '16px!important'
      }
    },
    MuiRadio:{
      root:{
        color: '#898F91'
      },
      colorSecondary:{
        '&$checked':{
          color: '#107A98'
        },
        color: '#898F91'
      } 
    },
    MuiCheckbox:{
      root:{
        color: '#898F91'
      },
      colorSecondary:{
        '&$checked':{
          color: '#107A98'
        },
        color: '#898F91'
      } 
    }
  },
});
