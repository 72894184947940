import React, { Fragment, Component, useState } from 'react';
import styles, { newTheme }  from "./ReferralActivityList.Styles";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Link, List, ListItem, ListItemText, ThemeProvider, Typography, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReferralOverviewDataModel } from '../../../../data-models/ReferralOverview.DataModel';
import ActivityEntity from '../../../../entities/Activity.Entity';
import MaterialTable from "../../../generic-components/material-table/MaterialTable.Component";
import { MaterialTableProps } from 'material-table';
import Moment from "moment";
import ActivityTypeEntity from '../../../../entities/ActivityType.Entity';

type PathParamsType = {
    id: string;
    claimId: string;
}

type ReferralActivityListProps = {
    referralActivities: Array<ActivityEntity>;
} & WithStyles<typeof styles> & RouteComponentProps<PathParamsType>

type ReferralActivitiesState = {
    filter: boolean;
}

class ReferralActivityListComponent extends Component<ReferralActivityListProps, ReferralActivitiesState>  {
    constructor(props: ReferralActivityListProps) {
        super(props);

        this.state = {
            filter: false,
        };
    }

    render() {
        const { classes } = this.props;
        return (
            <ThemeProvider theme={newTheme}>
                <Accordion defaultExpanded={true} className={classes.paper} square>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="widget-activity-list-accordion"
                        className={classes.paperHeader}
                    >
                        <Typography variant="subtitle2" className={classes.paperTitle}>Activity</Typography>
                        
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Typography variant="caption" className={classes.paperCaption}>Latest Activity</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    <div className={classes.content} >
                                        <List>
                                            {this.props.referralActivities?.map((activity, index) => (
                                                (index<6)?
                                                <ListItem className={classes.listItem}>
                                                    <ListItemText
                                                        primary={Moment(activity.date).format('MM/DD/YYYY')+" "+activity.activityType}
                                                    />
                                                </ListItem>:""
                                            ))}
                                        </List>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </ThemeProvider>
        );
    }
}

export const ReferralActivityList = withRouter(withStyles(styles)(ReferralActivityListComponent))
