import { makeStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

const drawerWidth = 80;

export default makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            height: '100%',
        },
        drawerContainer: { height: '100%' },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: '#003144',
            background:
                'transparent linear-gradient(90deg, #A7CE05 0%, #1087A9 46%, #004763 100%) 0% 0% no-repeat padding-box;',
            backgroundPosition: 'center bottom',
            backgroundSize: '100% 4px',
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        //toolbar: theme.mixins.toolbar,
        logo: {
            flexGrow: 0,

        },
        drawerPaper: {
            width: drawerWidth,
            backgroundColor: '#FFFFFF',
            color: '#004763',
        },
        content: {
            flexGrow: 1,
            paddingTop: theme.mixins.toolbar.minHeight,
            '@media (min-width:0px) and (orientation: landscape)': {
                paddingTop: 48,
            },
            [theme.breakpoints.up('sm')]: {
                paddingTop: (theme.mixins.toolbar[theme.breakpoints.up("sm")] as CSSProperties).minHeight
            },
        },
        toolbar:{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight:'0px',
        },
        clientNameContainer:{
            height:'64px',
            backgroundColor: '#024f6c',
            display: 'flex',
            alignItems: 'center',
            paddingRight: '15px',
            paddingLeft: '15px',
            cursor:'pointer',
            '&:hover':{
                backgroundColor:'#004763'
            },
            textTransform: "none"
        },
        clientName:{
            color: '#a1c111',
        },
        menu:{
            marginLeft:'15px'
        }
    };
});
