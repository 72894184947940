import { Grid } from "@material-ui/core";
import Moment from 'moment';
import React, { Fragment, FunctionComponent, useEffect } from 'react';
import AdditionalInformationDataModel from "../../../../../../data-models/pt/AdditionalInformation.DataModel";
import { EmptyDataLabel } from "../../../../../generic-components/empty-data-label/EmptyDataLabel.Component";
import { TooltipTextField } from "../../../../../generic-components/tooltip-textfield/TooltipTextField.Component";
import styles from './AdditionalInformation.Styles';

interface IAdditionalInformationProps{
    referralId: number,
    handleLocalServiceEdit: any,
    additionalInformation: AdditionalInformationDataModel,
}

export const AdditionalInformation: FunctionComponent<IAdditionalInformationProps> = (props: IAdditionalInformationProps) => {
    const classes = styles();
    const abortController = new AbortController();

    const [additionalInformationState, setAdditionalInformation] = React.useState<AdditionalInformationDataModel>(props.additionalInformation);
    const [counterState, setCounterState] = React.useState(props.additionalInformation ? props.additionalInformation : null);

    useEffect(() => {
        if (!props.additionalInformation) {
            let newAI = { referralId: props.referralId } as AdditionalInformationDataModel;
            newAI.referralId = props.referralId;
            setAdditionalInformation(newAI);
            setCounterState(newAI)
        } else {
            setAdditionalInformation(props.additionalInformation);
            setCounterState(props.additionalInformation);
        }

        return function cleanup() {
            abortController.abort();
        }
    }, [props]);

    const handleTextFieldChangeAdditionalInformation = (event) => {
        event.persist();
        let newAI: AdditionalInformationDataModel = additionalInformationState;
        if (!newAI.referralId) {
            newAI.referralId = props.referralId;
        }

        if (event.target.type == 'checkbox') {
            newAI[event.target.id ? event.target.id : event.target.name] = event.target.checked;
        }
        else {
            setCounterState({...counterState,[event.target.id ? event.target.id : event.target.name] : event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value.toString() == "true" ? true : event.target.value.toString() == "false" ? false : event.target.value});
            newAI[event.target.id ? event.target.id : event.target.name] = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value.toString() == "true" ? true : event.target.value.toString() == "false" ? false : event.target.value;
        }   

        setAdditionalInformation(newAI);
        props.handleLocalServiceEdit(additionalInformationState);
    };

    return (
        <Fragment>
            {
                additionalInformationState ?
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={3}>
                            <TooltipTextField 
                                label='Date of hire'
                                id="dateOfHire" 
                                onChange={(event) => handleTextFieldChangeAdditionalInformation(event)} 
                                defaultValue={
                                    (additionalInformationState.dateOfHire as string)?.split('T')[0]
                                }
                                type="date" 
                                InputLabelProps={{ shrink: true }} 
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField 
                                label='Job Title'
                                id="jobTitle" 
                                onChange={(event) => handleTextFieldChangeAdditionalInformation(event)} 
                                defaultValue={additionalInformationState.jobTitle ? additionalInformationState.jobTitle : ''}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField 
                                label='Date the Employee will Return to Work' 
                                id="dateTheEmployeeWillReturnToWork" 
                                onChange={(event) => handleTextFieldChangeAdditionalInformation(event)} 
                                defaultValue={
                                    (additionalInformationState.dateTheEmployeeWillReturnToWork as string)?.split('T')[0]
                                }
                                type="date" 
                                InputLabelProps={{ shrink: true }} 
                            />
                        </Grid>
                        <Grid item xs={3}>
                            {/* EMPTY SPACE */}
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField 
                                label='ICD Medical Diagnosis Code 1' 
                                id="icdMedicalDiagnosisCode1" 
                                onChange={(event) => handleTextFieldChangeAdditionalInformation(event)} 
                                defaultValue={additionalInformationState.icdMedicalDiagnosisCode1 ? additionalInformationState.icdMedicalDiagnosisCode1 : ''}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField 
                                label='ICD Medical Diagnosis Code 2' 
                                id="icdMedicalDiagnosisCode2" 
                                onChange={(event) => handleTextFieldChangeAdditionalInformation(event)} 
                                defaultValue={additionalInformationState.icdMedicalDiagnosisCode2 ? additionalInformationState.icdMedicalDiagnosisCode2 : ''}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField 
                                label='ICD Medical Diagnosis Code 3'
                                id="icdMedicalDiagnosisCode3" 
                                onChange={(event) => handleTextFieldChangeAdditionalInformation(event)} 
                                defaultValue={additionalInformationState.icdMedicalDiagnosisCode3 ? additionalInformationState.icdMedicalDiagnosisCode3 : ''}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            {/* EMPTY SPACE */}
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField 
                                label='Did the Back Injury Cause the Leg Pain?' 
                                id="didTheBackInjuryCauseTheLegPain" 
                                onChange={(event) => handleTextFieldChangeAdditionalInformation(event)} 
                                defaultValue={additionalInformationState.didTheBackInjuryCauseTheLegPain ? additionalInformationState.didTheBackInjuryCauseTheLegPain : ''}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField 
                                label='Have You Had Surgeries to this Body Part in the Past?' 
                                id="haveYouHadSurgeriesToThisBodyPartInThePast" 
                                onChange={(event) => handleTextFieldChangeAdditionalInformation(event)} 
                                defaultValue={additionalInformationState.haveYouHadSurgeriesToThisBodyPartInThePast ? additionalInformationState.haveYouHadSurgeriesToThisBodyPartInThePast : ''}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            {/* EMPTY SPACE */}
                        </Grid>
                        <Grid item xs={3}>
                            {/* EMPTY SPACE */}
                        </Grid>
                    </Grid>
                    : <EmptyDataLabel></EmptyDataLabel>
            }
        </Fragment>
    );
}