import {Box, Grid, Paper, Typography} from "@material-ui/core";
import { MaterialTableProps } from "material-table";
import React, { FunctionComponent, useEffect, useState } from "react";
import MaterialTable from "../../generic-components/material-table/MaterialTable.Component";
import styles from './ReferralsByDateAndService.Styles';
import VendorErrorEntity from "../../../entities/VendorError.Entity";
import VendorResponseDataModel from "../../../data-models/VendorResponse.DataModel";
import { getReferralsByDateAndService } from "../../../services/Referrals.Service";
import AdminReferralByDateAndServiceDataModel from "../../../data-models/AdminReferralByDateAndService.DataModel";

interface ReferralsByDateAndServiceProps{
    serviceId: number;
    dateRangeFrom: string;
    dateRangeTo: string;
}

export const ReferralsByDateAndService: FunctionComponent<ReferralsByDateAndServiceProps> = (props) => {

    const classes = styles();
    const abortController = new AbortController();
    const [referralsByDateAndService, setReferralsByDateAndService] = useState<AdminReferralByDateAndServiceDataModel[]>(null);

    useEffect(() => {

        getReferralsByDateAndService(props.serviceId,props.dateRangeFrom, props.dateRangeTo, abortController.signal).then(res => {
            setReferralsByDateAndService(res);
        });
    }, [props.serviceId]);

    const referralsByDateAndServiceTableProps: MaterialTableProps<AdminReferralByDateAndServiceDataModel> = {
        columns:[
            {
                title: 'Referral Id',
                field: 'referralId',
            },
            {
                title: 'Claim Id',
                field: 'claimId',
            },
            {
                title: 'Claim Number',
                field: 'claimNumber',
            },
            {
                title: 'Vendor Name',
                field: 'vendorName',
            },
            {
                title: 'Submitted Date',
                field: 'submittedDate',
            }
        ],
        options:{
            showTitle:true,
            toolbar: true,
            search: false,
            paging: false,
        },
        data: referralsByDateAndService ?? [],
        title: 'Referral List',
    }

    return (
        <Paper className={classes.tableContainer}>
            <MaterialTable tableProps={referralsByDateAndServiceTableProps}/>
        </Paper>
    )        
}