import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 320;

export default makeStyles((theme: Theme) => ({
  content: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: drawerWidth,
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  sidebarTitle: {
    color: '#428302',
  },
  gridContainer: {
    width: '100%'
  },
  blackFont: {
    color: '#000000'
  },
  redFont: {
    color: '#e74c3c'
  },
  dataLabel: {
    color: '#898F91',
    fontSize: '12px'
  },
  paper: {
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingBottom: '20px'
  },
  paperHeader: {
    padding: '0',
    marginLeft: '15px',
    flexDirection: 'row-reverse'
  },
  paperHeaderIcon: {
    color: '#428302'
  },
  textAreaGrid: {
    paddingLeft: '35px!important',
  },
  fullWidthInput: {
    width: '100%'
  },
  selectInput: {
    width: '100%',
  },
  listHeaderSubTitle: {
    fontSize: '12px',
    paddingTop: '10px',
    color: '#898F91'
  },
  headerContainer: {
    display: 'grid'
  },
  blueFont:{
    color: '#1599BC',
    marginBottom: '5px'
  },
  textArea:{
    overflow:'hidden !important',
  }
}));