import MSCoverLetterEntity from '../entities/pt/PTCoverLetter.Entity';
import ReferralVisitEntity from '../entities/pt/ReferralVisit.Entity';
import ServiceTypeResponseEntity from '../entities/pt/ServiceTypeResponse.Entity';
import ServiceTypeScorecardResponseEntity from '../entities/pt/ServiceTypeScorecardResponse.Entity';
import ReferralScheduleEntity from '../entities/ReferralSchedule.Entity';
import ScorecardResponsesChartContentDataModel from './pt/ScorecardResponsesChartContentDataModel';
import ServiceTypeRequestTransportationEntity from '../entities/ServiceTypeRequestTransportation.Entity';

export default class TransportationDataModel {
    serviceTypeRequestTransportationId: number;
    referralId: number;
    serviceId: number;
    serviceTypeRequestTransportation: ServiceTypeRequestTransportationEntity;
    coverLetter: MSCoverLetterEntity;
    lastVisit: ReferralVisitEntity;
    progressSummary: string;
    response: ServiceTypeResponseEntity;
    visits: Array<ReferralVisitEntity>;
    notes: string;
    singleSchedule?: ReferralScheduleEntity;
    scorecardResponse: Array<ServiceTypeScorecardResponseEntity>;
    scorecardResponsesChartContent: Array<ScorecardResponsesChartContentDataModel>;
}
