import { makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 100;

export default makeStyles((theme: Theme) => ({
    root: { 
        display: "flex", 
        flexDirection:'column',
        width: `${drawerWidth}px`,
        height: 'calc(100vh - 64px)',
        alignItems: "center",
        backgroundColor:'white',
    },
    menuList:{
        paddingTop:'10px',
        backgroundColor:'white',
        //height: 'calc(100vh - 64px)',
        position: 'fixed',
        left:0,
        bottom:0,
        top:'64px',
    },
    menuItem:{
        flexDirection: "column",
        paddingTop: '15px !important',
        paddingBottom: '10px !important',
        borderLeft: 'solid 4px transparent !important',
        paddingLeft:'8px !important',
        paddingRight: '8px !important',
    },
    hover: { "&:hover": { 
            borderLeftColor: "#004763 !important",
        }
    },
    active: {
        borderLeftColor: "#004763 !important",
    }
}));