import React, { FunctionComponent } from 'react';
import styles from './MsReferringPhysician.Styles';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, TextField } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { EmptyDataLabel } from '../../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import StateEntity from '../../../../../../entities/State.Entity';
import { TooltipTextField } from '../../../../../generic-components/tooltip-textfield/TooltipTextField.Component';
import MedicalServiceDataModel from '../../../../../../data-models/MedicalService.DataModel';
import DiagnosticServiceDataModel from '../../../../../../data-models/DiagnosticService.DataModel';
import UtilizationReviewServiceDataModel from '../../../../../../data-models/UtilizationReviewService.DataModel';
import { ServicesEnum } from '../../../../../../support/enums/Services.Enum';
import DmeServiceDataModel from '../../../../../../data-models/dme/DmeService.DataModel';

interface IMsReferringPhysician {
    medicalService:
        | MedicalServiceDataModel
        | DiagnosticServiceDataModel
        | UtilizationReviewServiceDataModel
        | DmeServiceDataModel;
    states: Array<StateEntity>;
    serviceId?: number;
}

export const MsReferringPhysician: FunctionComponent<IMsReferringPhysician> = (props: IMsReferringPhysician) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.sidebarTitle}>
                    Referring Physician
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {props.medicalService && props.medicalService?.referringPhysician ? (
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="First Name"
                                disabled={true}
                                value={
                                    !!props.medicalService.referringPhysician.firstName
                                        ? props.medicalService.referringPhysician.firstName
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="Address Line 1"
                                disabled={true}
                                value={
                                    !!props.medicalService.referringPhysician.addressLine1
                                        ? props.medicalService.referringPhysician.addressLine1
                                        : ''
                                }
                            />
                        </Grid>
                        {props.serviceId === ServicesEnum.Diagnostic ? (
                            <Grid container xs={3} spacing={1} style={{ marginTop: '9px', marginRight: '4px' }}>
                                <Grid item xs={3} style={{ marginLeft: '15px', marginRight: '-15%' }}>
                                    <TooltipTextField
                                        label="Zip Code"
                                        disabled={true}
                                        value={
                                            !!props.medicalService.referringPhysician.zipCode
                                                ? props.medicalService.referringPhysician.zipCode
                                                : ''
                                        }
                                        style={{ width: '110px' }}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <Grid
                                        item
                                        xs={3}
                                        style={{ marginTop: '15px', marginLeft: '15px', marginRight: '-8%' }}
                                    >
                                        _
                                    </Grid>
                                </Grid>
                                <Grid item xs={3} style={{ marginRight: '15px' }}>
                                    <TooltipTextField
                                        disabled={true}
                                        label="Zip Code Ext."
                                        id="zipCodeExtension"
                                        value={
                                            !!props.medicalService.referringPhysician.zipCodeExtension
                                                ? props.medicalService.referringPhysician.zipCodeExtension
                                                : ''
                                        }
                                        style={{ width: '100px' }}
                                        inputProps={{ maxLength: 4 }}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid item xs={3}>
                                <TooltipTextField
                                    label="Zip Code"
                                    disabled={true}
                                    value={
                                        !!props.medicalService.referringPhysician.zipCode
                                            ? props.medicalService.referringPhysician.zipCode
                                            : ''
                                    }
                                />
                            </Grid>
                        )}
                        <Grid item xs={3}>
                            {/* EMPTY SPACE */}
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="Last Name"
                                disabled={true}
                                value={
                                    !!props.medicalService.referringPhysician.lastName
                                        ? props.medicalService.referringPhysician.lastName
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="Address Line 2"
                                disabled={true}
                                value={
                                    !!props.medicalService.referringPhysician.addressLine2
                                        ? props.medicalService.referringPhysician.addressLine2
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="Primary Phone"
                                disabled={true}
                                value={
                                    !!props.medicalService.referringPhysician.primaryPhoneNumber
                                        ? props.medicalService.referringPhysician.primaryPhoneNumber
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="Ext"
                                disabled={true}
                                value={
                                    !!props.medicalService.referringPhysician.primaryPhoneExtension
                                        ? props.medicalService.referringPhysician.primaryPhoneExtension
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="Middle initial"
                                disabled={true}
                                value={
                                    !!props.medicalService.referringPhysician.middleInitial
                                        ? props.medicalService.referringPhysician.middleInitial
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="City"
                                disabled={true}
                                value={
                                    !!props.medicalService.referringPhysician.cityName
                                        ? props.medicalService.referringPhysician.cityName
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="Secondary Phone"
                                disabled={true}
                                value={
                                    !!props.medicalService.referringPhysician.secondaryPhoneNumber
                                        ? props.medicalService.referringPhysician.secondaryPhoneNumber
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="Ext"
                                disabled={true}
                                value={
                                    !!props.medicalService.referringPhysician.secondaryPhoneExtension
                                        ? props.medicalService.referringPhysician.secondaryPhoneExtension
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="Physician NPI"
                                disabled={true}
                                value={
                                    !!props.medicalService.referringPhysician.treatingPhysicianNPI
                                        ? props.medicalService.referringPhysician.treatingPhysicianNPI
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="State"
                                disabled={true}
                                value={
                                    props.states?.find(
                                        (x) => x.stateId == props.medicalService?.referringPhysician?.stateId,
                                    )?.stateName
                                }
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="Fax"
                                disabled={true}
                                value={
                                    !!props.medicalService.referringPhysician.fax
                                        ? props.medicalService.referringPhysician.fax
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={3}>
                            {/* EMPTY SPACE */}
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="Email"
                                disabled={true}
                                value={
                                    !!props.medicalService.referringPhysician.email
                                        ? props.medicalService.referringPhysician.email
                                        : ''
                                }
                            />
                        </Grid>
                        <Grid item xs={3}>
                            {/* EMPTY SPACE */}
                        </Grid>
                        <Grid item xs={3}>
                            {/* EMPTY SPACE */}
                        </Grid>
                        <Grid item xs={3}>
                            {/* EMPTY SPACE */}
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                maxRows={6}
                                multiline
                                className={classes.input}
                                disabled
                                label="Additional Comments"
                                value={
                                    !!props.medicalService.referringPhysician.comments
                                        ? props.medicalService.referringPhysician.comments
                                        : ''
                                }
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <EmptyDataLabel></EmptyDataLabel>
                )}
            </AccordionDetails>
        </Accordion>
    );
};
