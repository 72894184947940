import React, { Fragment, Component } from 'react';
import styles from './ReferralDetail.Styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/core';
import { Loader } from '../../components/generic-components/loader/Loader.Component';
import { Sidebar } from '../../components/generic-components/sidebar/Sidebar.Component';
import { NavigationBar } from '../../components/generic-components/navigation-bar/NavigationBar.Component';
import { TabsBar } from '../../components/generic-components/tabs-bar/TabsBar.Component';
import { ReferralOverview } from '../../components/referral-components/referral-detail/referral-overview/ReferralOverview.Component';
import { ReferralOverviewDataModel } from '../../data-models/ReferralOverview.DataModel';
import {
    getDiagnosticProcedureResponseScorecard,
    getImeCoverLetter,
    getImeScorecardResponse,
    getOverview,
    getPermission,
    getReferralClaim,
    getReferralContact,
    getReferralDiagnostic,
    getReferralDme,
    getReferralDmeResponse,
    getReferralDocument,
    getReferralIme,
    getReferralPhysicalTherapy,
    getReferralResponse,
    getReferralTranslation,
    getReferralTransportation,
    getReferralUr,
    getServiceTypeResponse,
} from '../../services/Referrals.Service';
import { sendSubmissionRequest } from '../../services/Referrals.Service';
import { ReferralResponse } from '../../components/referral-components/referral-detail/referral-response/ReferralResponse.Component';
import ReferralScheduleEntity from '../../entities/ReferralSchedule.Entity';
import ImeResponseEntity from '../../entities/ImeResponse.Entity';
import getReferralScheduleStatusTypes from '../../services/ReferralScheduleStatusTypes.Service';
import ReferralScheduleStatusTypeEntity from '../../entities/ReferralScheduleStatusType.Entity';
import ReferralContactEntity from '../../entities/ReferralContact.Entity';
import StateEntity from '../../entities/State.Entity';
import getStates from '../../services/States.Service';
import ReferralDocumentEntity from '../../entities/ReferralDocument.Entity';
import { ReferralRequest } from '../../components/referral-components/referral-detail/referral-request/ReferralRequest.Component';
import ImeEntity from '../../entities/Ime.Entity';
import getSpecialties from '../../services/Specialties.Service';
import getLanguages from '../../services/Languages.Service';
import SpecialtyEntity from '../../entities/Specialty.Entity';
import LanguageEntity from '../../entities/Language.Entity';
import { ReferralContactTypesEnum } from '../../support/enums/ReferralContactTypesEnum';
import { ReferralScorecard } from '../../components/referral-components/referral-detail/referral-scorecard/ReferralScorecard.Component';
import { getOverviewIme } from '../../services/Referrals.Service';
import { ReferralOverviewImeDataModel } from '../../data-models/ReferralOverviewIme.DataModel';
import { ServicesEnum } from '../../support/enums/Services.Enum';
import ImeScorecardResponseEntity from '../../entities/ImeScorecardResponse.Entity';
import ImeScorecardSectionDataModel from '../../data-models/ImeScorecardSection.DataModel';
import getImeScorecardSections from '../../services/ImeScorecardSections.Service';
import { ReferralClaimInfo } from '../../components/referral-components/referral-detail/referral-claim-info/ReferralClaimInfo.Component';
import getCoverageTypes from '../../services/CoverageTypes.Service';
import CoverageTypeEntity from '../../entities/CoverageType.Entity';
import ReferralClaimEntity from '../../entities/ReferralClaim.Entity';
import { ReferralActivities } from '../../components/referral-components/referral-detail/referral-activities/ReferralActivities.Component';
import ActivityTypeEntity from '../../entities/ActivityType.Entity';
import getActivityTypes from '../../services/ActivityTypes.Service';
import getReferralActivities from '../../services/Activities.Service';
import { ReferralDocuments } from '../../components/referral-components/referral-detail/referral-documents/ReferralDocuments.Component';
import { ClaimSidebar } from '../../components/claim-components/claim-sidebar/ClaimSidebar.Component';
import ClaimEntity from '../../entities/Claim.Entity';
import ClaimDataModel from '../../data-models/Claim.DataModel';
import UserEntity from '../../entities/User.Entity';
import ClientEntity from '../../entities/Client.Entity';
import ReferralStatusEntity from '../../entities/ReferralStatus.Entity';
import getReferralStatuses from '../../services/ReferralStatuses.Service';
import SpecificBodyPartEntity from '../../entities/SpecificBodyPart.Entity';
import getBodySides from '../../services/BodySides.Service';
import getSpecificBodyParts, { getSpecificBodyPartsInjuryTypes } from '../../services/SpecificBodyParts.Service';
import InjuryTypeEntity from '../../entities/pt/InjuryType.Entity';
import BodySideEntity from '../../entities/BodySide.Entity';
import ScorecardSectionDataModel from '../../data-models/pt/ScorecardSection.DataModel';
import { getScorecardSections, getServices } from '../../services/Services.Service';
import getReferralNotificationTracker from '../../services/ReferralTracker.Service';
import ReferralNotificationTrackerDataModel from '../../data-models/ReferralNotificationTracker.DataModel';
import IMECoverLetterEntity from '../../entities/IMECoverLetter.Entity';
import MedicalServiceDataModel from '../../data-models/MedicalService.DataModel';
import DiagnosticServiceDataModel from '../../data-models/DiagnosticService.DataModel';
import { getDiagnosisById } from '../../services/Diagnosis.Service';
import { getAllDiagnosticProcedureDetail } from '../../services/DiagnosticProcedureDetail.Service';
import DiagnosticProcedureDetailDataModel from '../../data-models/DiagnosticProcedureDetail.DataModel';
import DiagnosticProcedureResponseDataModel from '../../data-models/DiagnosticProcedureResponse.DataModel';
import ServiceDataModel from '../../data-models/Service.DataModel';
import DiagnosticProcedureResponseScorecardTabDataModel from '../../data-models/DiagnosticProcedureResponseScorecardTabDataModel';
import UtilizationReviewServiceDataModel from '../../data-models/UtilizationReviewService.DataModel';
import DiagnosticProcedureResponseTabDataModel from '../../data-models/DiagnosticProcedureResponseTab.DataModel';
import TranslationDataModel from '../../data-models/Translation.DataModel';
import TransportationDataModel from '../../data-models/Transportation.DataModel';
import AppointmentTypeEntity from '../../entities/AppointmentType.Entity';
import DestinationEntity from '../../entities/Destination.Entity';
import InterpretationTypeEntity from '../../entities/InterpretationType.Entity';
import OriginationEntity from '../../entities/Origination.Entity';
import TransportationRequiredEntity from '../../entities/TransportationRequired.Entity';
import getAppointmentTypes from '../../services/AppointmentType.Service';
import getDestinations from '../../services/Destination.Service';
import getInterpretationTypes from '../../services/InterpretationType.Service';
import getOriginations from '../../services/Origination.Service';
import getTransportationRequireds from '../../services/TransportationRequired.Service';
import ServiceTypeEntity from '../../entities/ServiceType.Entity';
import TransportationTranslationResponse from '../../data-models/TransportationTranslationResponse.DataModel';
import UtilizationReviewResponseDataModel from '../../data-models/UtilizationReviewResponse.DataModel';
import ActivityDataModel from '../../data-models/Activity.DataModel';
import { BreadCrumb, Section } from '../../support/custom-hooks/useBreadCrum';
import getAllTtSchedules from '../../services/ReferralTtSchedule.Service';
import { WheelchairOrStrecherDetailEntity } from '../../entities/WheelchairOrStrecherDetail.Entity';
import getWheelchairOrStrecherDetails from '../../services/WheelchairOrStrecherDetail.Service';
import DmeServiceDataModel from '../../data-models/dme/DmeService.DataModel';
import DmeResponseDataModel from '../../data-models/dme/DmeResponse.DataModel';
import { checkPermissions } from '../../components/generic-components/access-control/AccessControl.Component';
import { vendorPortalRules, vendorReferralList } from '../../support/constants/SecurityRules.Constants';
import getReferralVisitStatuses from '../../services/ReferralVisitStatuses.Service';
import ReferralVisitStatusEntity from '../../entities/pt/ReferralVisitStatus.Entity';
import { translationTypes } from '../../support/constants/TranslationTypes.Constants';

type ReferralDetailState = {
    loading: boolean;
    sidebar: boolean;
    tabIndex: number;
    referralOverviewDataModel: ReferralOverviewDataModel;
    referralSchedule: ReferralScheduleEntity;
    imeResponse: ImeResponseEntity;
    referralScheduleStatusTypes: Array<ReferralScheduleStatusTypeEntity>;
    responsePhysician: ReferralContactEntity;
    states: Array<StateEntity>;
    responseDocuments: Array<ReferralDocumentEntity>;
    imeRequest: ImeEntity;
    specialties: Array<SpecialtyEntity>;
    languages: Array<LanguageEntity>;
    treatingPhysician: ReferralContactEntity;
    recommendedPhysician: ReferralContactEntity;
    referralOverviewImeDataModel: ReferralOverviewImeDataModel;
    imeScorecardResponses: Array<ImeScorecardResponseEntity>;
    imeScorecardSections: Array<ImeScorecardSectionDataModel>;
    referralClient: ReferralContactEntity;
    coverageTypes: Array<CoverageTypeEntity>;
    referralClaim: ReferralClaimEntity;
    referralAdjuster: ReferralContactEntity;
    referralClaimant: ReferralContactEntity;
    referralClaimantAttorney: ReferralContactEntity;
    referralEmployer: ReferralContactEntity;
    referralEmployerAttorney: ReferralContactEntity;
    referralNurseCaseManager: ReferralContactEntity;
    referralActivities: Array<ActivityDataModel>;
    activityTypes: Array<ActivityTypeEntity>;
    referralDocuments: Array<ReferralDocumentEntity>;
    referralStatuses: Array<ReferralStatusEntity>;
    specificBodyParts: Array<SpecificBodyPartEntity>;
    injuryTypes: Array<InjuryTypeEntity>;
    bodySides: Array<BodySideEntity>;
    medicalService: MedicalServiceDataModel;
    scorecardSections: Array<ScorecardSectionDataModel>;
    imeCoverLetter: IMECoverLetterEntity;
    referralNotificationTracker: ReferralNotificationTrackerDataModel;
    serviceId: number;
    diagnosticService: DiagnosticServiceDataModel;
    procedureDetails: Array<DiagnosticProcedureDetailDataModel>;
    diagnosticProcedureResponse: Array<DiagnosticProcedureResponseDataModel>;
    services: Array<ServiceDataModel>;
    diagnosticProcedureScorecardResponse: Array<DiagnosticProcedureResponseScorecardTabDataModel>;
    utilizationReviewService: UtilizationReviewServiceDataModel;
    serviceTypeResponseUr: UtilizationReviewResponseDataModel;
    transportationService: TransportationDataModel;
    translationService: TranslationDataModel;
    appointmentTypes: Array<AppointmentTypeEntity>;
    destinations: Array<DestinationEntity>;
    interpretationTypes: Array<InterpretationTypeEntity>;
    originations: Array<OriginationEntity>;
    transportationRequireds: Array<TransportationRequiredEntity>;
    serviceType: Array<ServiceTypeEntity>;
    transportationTranslationResponses: Array<TransportationTranslationResponse>;
    wheelchairOrStretcherDetails: Array<WheelchairOrStrecherDetailEntity>;
    ReferralVisitStatuses : Array<ReferralVisitStatusEntity>;
    //dme
    dmeService: DmeServiceDataModel;
    dmeResponse: DmeResponseDataModel;
    referralDetailBreadCrumb: Section[];
};

type PathParamsType = {
    id: string;
    claimId: string;
};

type ComponentProps = {
    userSecurityRules: string[];
};

type ReferralDetailProps = WithStyles<typeof styles> & RouteComponentProps<PathParamsType> & ComponentProps;
function getClaimDataModel(
    claimE: ClaimEntity,
    coverageType: CoverageTypeEntity,
    client: ClientEntity,
    stateOfJurisdiction: StateEntity,
    user: UserEntity,
) {
    const claimDM: ClaimDataModel = new ClaimDataModel();
    claimDM.claim = claimE;
    claimDM.coverageType = coverageType;
    claimDM.client = client;
    claimDM.stateOfJurisdiction = stateOfJurisdiction;
    claimDM.user = user;
    return claimDM;
}
function getReferralClient(referralClient: ReferralContactEntity) {
    const client: ClientEntity = new ClientEntity();

    client.clientId = referralClient?.contactId;
    client.clientName = referralClient?.name;
    client.clientNumber = referralClient?.number;

    return client;
}

class ReferralDetailComponent extends Component<ReferralDetailProps, ReferralDetailState> {
    constructor(props: ReferralDetailProps) {
        super(props);

        this.state = {
            loading: false,
            sidebar: false,
            tabIndex: 0,
            referralOverviewDataModel: null,
            referralSchedule: null,
            imeResponse: null,
            referralScheduleStatusTypes: null,
            responsePhysician: null,
            states: null,
            responseDocuments: null,
            imeRequest: null,
            specialties: null,
            languages: null,
            treatingPhysician: null,
            recommendedPhysician: null,
            referralOverviewImeDataModel: null,
            imeScorecardResponses: null,
            imeScorecardSections: null,
            referralClient: null,
            coverageTypes: null,
            referralClaim: null,
            referralAdjuster: null,
            referralClaimant: null,
            referralClaimantAttorney: null,
            referralEmployer: null,
            referralEmployerAttorney: null,
            referralNurseCaseManager: null,
            referralActivities: null,
            activityTypes: null,
            referralDocuments: null,
            referralStatuses: null,
            specificBodyParts: null,
            bodySides: null,
            injuryTypes: null,
            medicalService: null,
            scorecardSections: null,
            imeCoverLetter: null,
            referralNotificationTracker: null,
            serviceId: null,
            diagnosticService: null,
            procedureDetails: null,
            diagnosticProcedureResponse: null,
            services: null,
            diagnosticProcedureScorecardResponse: null,
            utilizationReviewService: null,
            serviceTypeResponseUr: null,
            transportationService: null,
            translationService: null,
            appointmentTypes: null,
            destinations: null,
            interpretationTypes: null,
            originations: null,
            transportationRequireds: null,
            serviceType: null,
            transportationTranslationResponses: null,
            wheelchairOrStretcherDetails: null,
            ReferralVisitStatuses : null,

            //dme
            dmeService: null,
            dmeResponse: null,
            referralDetailBreadCrumb: [],
        };
    }

    abortController = new AbortController();

    async componentDidMount() {
        this.setState({
            loading: true,
        });

        const initialData = await Promise.all([
            getReferralScheduleStatusTypes(this.abortController.signal),
            getStates(this.abortController.signal),
            getSpecialties(this.abortController.signal),
            getLanguages(this.abortController.signal),
            getCoverageTypes(this.abortController.signal),
            getActivityTypes(this.abortController.signal),
            getReferralStatuses(this.abortController.signal),
            getBodySides(this.abortController.signal),
            getReferralVisitStatuses(this.abortController.signal),
        ]).catch((_err) => {
            this.setState({
                loading: false,
            });
        });

        if (initialData) {
            this.setState({
                referralScheduleStatusTypes: initialData[0],
                states: initialData[1],
                specialties: initialData[2],
                languages: initialData[3],
                coverageTypes: initialData[4],
                activityTypes: initialData[5],
                referralStatuses: initialData[6],
                bodySides: initialData[7],
                ReferralVisitStatuses: initialData[8],
            });
            const permission = await getPermission(
                +this.props.match.params.id,
                +this.props.match.params.claimId,
                this.abortController.signal,
            ).catch(() => {
                this.props.history.push('/not-found');
            });
            if (permission) {
                const referralAndRelatedResult = await Promise.all([
                    getOverview(+this.props.match.params.id, this.abortController.signal),
                    getReferralContact(
                        +this.props.match.params.id,
                        ReferralContactTypesEnum.TreatingPhysician,
                        this.abortController.signal,
                    ),
                    getReferralContact(
                        +this.props.match.params.id,
                        ReferralContactTypesEnum.OptionalPhysician,
                        this.abortController.signal,
                    ),
                    getReferralContact(
                        +this.props.match.params.id,
                        ReferralContactTypesEnum.Client,
                        this.abortController.signal,
                    ),
                    getReferralClaim(+this.props.match.params.id, this.abortController.signal),
                    getReferralContact(
                        +this.props.match.params.id,
                        ReferralContactTypesEnum.Adjuster,
                        this.abortController.signal,
                    ),
                    getReferralContact(
                        +this.props.match.params.id,
                        ReferralContactTypesEnum.Claimant,
                        this.abortController.signal,
                    ),
                    getReferralContact(
                        +this.props.match.params.id,
                        ReferralContactTypesEnum.ClaimantAttorney,
                        this.abortController.signal,
                    ),
                    getReferralContact(
                        +this.props.match.params.id,
                        ReferralContactTypesEnum.Employer,
                        this.abortController.signal,
                    ),
                    getReferralContact(
                        +this.props.match.params.id,
                        ReferralContactTypesEnum.EmployerAttorney,
                        this.abortController.signal,
                    ),
                ]).catch((_err) => {
                    this.setState({
                        loading: false,
                    });
                });

                if (referralAndRelatedResult) {
                    this.setState({
                        referralOverviewDataModel: referralAndRelatedResult[0],
                        treatingPhysician: referralAndRelatedResult[1],
                        recommendedPhysician: referralAndRelatedResult[2],
                        referralClient: referralAndRelatedResult[3],
                        referralClaim: referralAndRelatedResult[4],
                        referralAdjuster: referralAndRelatedResult[5],
                        referralClaimant: referralAndRelatedResult[6],
                        referralClaimantAttorney: referralAndRelatedResult[7],
                        referralEmployer: referralAndRelatedResult[8],
                        referralEmployerAttorney: referralAndRelatedResult[9],
                    });

                    this.setState({
                        serviceId: referralAndRelatedResult[0]?.referralServiceType[0]?.serviceId,
                        serviceType: referralAndRelatedResult[0]?.referralServiceType,
                        referralDetailBreadCrumb: checkPermissions(
                            this.props.userSecurityRules,
                            [...vendorPortalRules.read, ...vendorReferralList.read],
                            false,
                        )
                            ? [
                                  {
                                      name: 'Referral list',
                                      goBack: false,
                                      link: '/referrals',
                                      path: this.props.location.pathname,
                                  },
                                  {
                                      name: `Referral: ${referralAndRelatedResult[0]?.referral?.referralId}`,
                                      goBack: false,
                                      link: `/claims/${referralAndRelatedResult[0]?.claim?.claimId}/referrals/${referralAndRelatedResult[0]?.referral?.referralId}`,
                                      path: this.props.location.pathname,
                                  },
                              ]
                            : [
                                  {
                                      name: 'Claims',
                                      goBack: false,
                                      link: '/claims',
                                      path: this.props.location.pathname,
                                  },
                                  {
                                      name: `${referralAndRelatedResult[0]?.claim?.claimNumber}: Referral List`,
                                      goBack: false,
                                      link: `/claims/${referralAndRelatedResult[0]?.claim?.claimId}`,
                                      path: this.props.location.pathname,
                                  },
                                  {
                                      name: `Referral: ${referralAndRelatedResult[0]?.referral?.referralId}`,
                                      goBack: false,
                                      link: `/claims/${referralAndRelatedResult[0]?.claim?.claimId}/referrals/${referralAndRelatedResult[0]?.referral?.referralId}`,
                                      path: this.props.location.pathname,
                                  },
                              ],
                        loading: false,
                    });

                    const secondaryReferralAndRelatedResult = await Promise.all([
                        getReferralActivities(+this.props.match.params.id, this.abortController.signal),
                        getSpecificBodyParts(
                            referralAndRelatedResult[0]?.referralServiceType[0]?.serviceId,
                            referralAndRelatedResult[0]?.referralServiceType[0]?.serviceTypeId,
                            this.abortController.signal,
                        ),
                        getReferralContact(
                            +this.props.match.params.id,
                            ReferralContactTypesEnum.PhyisicianResponse,
                            this.abortController.signal,
                        ),
                        getScorecardSections(
                            referralAndRelatedResult[0]?.referralServiceType[0]?.serviceId,
                            this.abortController.signal,
                        ),
                        getReferralNotificationTracker(+this.props.match.params.id, this.abortController.signal),
                    ]).catch((_err) => {
                        this.setState({
                            loading: false,
                        });
                    });

                    if (secondaryReferralAndRelatedResult) {
                        this.setState({
                            referralActivities: secondaryReferralAndRelatedResult[0],
                            specificBodyParts: secondaryReferralAndRelatedResult[1],
                            responsePhysician: secondaryReferralAndRelatedResult[2],
                            scorecardSections: secondaryReferralAndRelatedResult[3],
                            referralNotificationTracker: secondaryReferralAndRelatedResult[4],
                        });

                        const requestsList = [];
                        if (referralAndRelatedResult[0].referralServiceType[0].serviceId === ServicesEnum.IME) {
                            requestsList.push(
                                getReferralResponse(
                                    +this.props.match.params.id,
                                    this.abortController.signal,
                                    referralAndRelatedResult[0].referralServiceType[0].serviceId,
                                ),
                                getOverviewIme(+this.props.match.params.id, this.abortController.signal),
                                getReferralIme(+this.props.match.params.id, this.abortController.signal),
                                getImeScorecardResponse(+this.props.match.params.id, this.abortController.signal),
                                getImeCoverLetter(+this.props.match.params.id, this.abortController.signal),
                            );
                            const imeAndRelatedResult = await Promise.all(requestsList).catch((_err) => {
                                this.setState({
                                    loading: false,
                                });
                            });

                            if (imeAndRelatedResult) {
                                const scorecardSectionRequests = [];
                                imeAndRelatedResult[3]?.map((imeScorecarResponse) => {
                                    const scorecardSection = getImeScorecardSections(
                                        imeScorecarResponse.imeScorecardSectionId,
                                        this.abortController.signal,
                                    );
                                    scorecardSectionRequests.push(scorecardSection);
                                });

                                const imeSectionsResult = await Promise.all<ImeScorecardSectionDataModel>(
                                    scorecardSectionRequests,
                                ).catch((_err) => {
                                    this.setState({
                                        loading: false,
                                    });
                                });

                                if (imeSectionsResult) {
                                    this.setState({
                                        imeScorecardSections: imeSectionsResult as Array<ImeScorecardSectionDataModel>,
                                        referralSchedule: imeAndRelatedResult[0].schedule,
                                        imeResponse: imeAndRelatedResult[0].response,
                                        responsePhysician: imeAndRelatedResult[0].physician,
                                        responseDocuments: imeAndRelatedResult[0].documents,
                                        referralOverviewImeDataModel: imeAndRelatedResult[1],
                                        imeRequest: imeAndRelatedResult[2].ime,
                                        imeScorecardResponses: imeAndRelatedResult[3],
                                        specificBodyParts: imeAndRelatedResult[2].specificBodyParts,
                                        imeCoverLetter: imeAndRelatedResult[4],
                                        loading: false,
                                    });
                                }
                            }
                        } else if (referralAndRelatedResult[0].referralServiceType[0].serviceId === ServicesEnum.PT) {
                            requestsList.push(
                                getReferralPhysicalTherapy(+this.props.match.params.id, this.abortController.signal),
                                getReferralResponse(
                                    +this.props.match.params.id,
                                    this.abortController.signal,
                                    referralAndRelatedResult[0].referralServiceType[0].serviceId,
                                ),
                                getReferralContact(
                                    +this.props.match.params.id,
                                    ReferralContactTypesEnum.NurseCaseManager,
                                    this.abortController.signal,
                                ),
                            );

                            const ptAndRelatedResult = await Promise.all(requestsList).catch((_err) => {
                                this.setState({
                                    loading: false,
                                });
                            });

                            if (ptAndRelatedResult) {
                                this.setState({
                                    medicalService: ptAndRelatedResult[0],
                                    responseDocuments: ptAndRelatedResult[1].documents,
                                    referralNurseCaseManager: ptAndRelatedResult[2],
                                });

                                if (ptAndRelatedResult[0]?.serviceType.specificBodyPartId) {
                                    const injuryTypes = await getSpecificBodyPartsInjuryTypes(
                                        ptAndRelatedResult[0]?.serviceType.specificBodyPartId,
                                        this.abortController.signal,
                                    );

                                    this.setState({
                                        injuryTypes: injuryTypes,
                                        loading: false,
                                    });
                                } else {
                                    this.setState({
                                        loading: false,
                                    });
                                }
                            }
                        } else if (
                            referralAndRelatedResult[0].referralServiceType[0].serviceId === ServicesEnum.Diagnostic
                        ) {
                            requestsList.push(
                                getReferralDiagnostic(+this.props.match.params.id, this.abortController.signal),
                                getReferralContact(
                                    +this.props.match.params.id,
                                    ReferralContactTypesEnum.NurseCaseManager,
                                    this.abortController.signal,
                                ),
                                getServiceTypeResponse<DiagnosticProcedureResponseTabDataModel>(
                                    +this.props.match.params.id,
                                    this.abortController.signal,
                                    referralAndRelatedResult[0].referralServiceType[0].serviceId,
                                ),
                                getServices(this.abortController.signal),
                                getDiagnosticProcedureResponseScorecard(
                                    +this.props.match.params.id,
                                    this.abortController.signal,
                                ),
                            );

                            const dxAndRelatedResult = await Promise.all(requestsList).catch((_err) => {
                                this.setState({
                                    loading: false,
                                });
                            });

                            if (dxAndRelatedResult) {
                                this.setState({
                                    diagnosticService: dxAndRelatedResult[0],
                                    responseDocuments: dxAndRelatedResult[2]?.documents,
                                    referralNurseCaseManager: dxAndRelatedResult[1],
                                    diagnosticProcedureResponse: dxAndRelatedResult[2].procedures,
                                    services: dxAndRelatedResult[3],
                                    diagnosticProcedureScorecardResponse: dxAndRelatedResult[4],
                                });
                            }

                            getAllDiagnosticProcedureDetail(this.abortController.signal).then((result) => {
                                this.setState({
                                    procedureDetails: result,
                                });
                            });
                        } else if (referralAndRelatedResult[0].referralServiceType[0].serviceId === ServicesEnum.UR) {
                            requestsList.push(
                                getReferralUr(+this.props.match.params.id, this.abortController.signal),
                                getServices(this.abortController.signal),
                                getServiceTypeResponse<UtilizationReviewResponseDataModel>(
                                    +this.props.match.params.id,
                                    this.abortController.signal,
                                    referralAndRelatedResult[0].referralServiceType[0].serviceId,
                                ),
                            );

                            const urAndRelatedResult = await Promise.all(requestsList).catch((_err) => {
                                this.setState({
                                    loading: false,
                                });
                            });

                            if (urAndRelatedResult) {
                                this.setState({
                                    utilizationReviewService: urAndRelatedResult[0],
                                    services: urAndRelatedResult[1],
                                    serviceTypeResponseUr: urAndRelatedResult[2],
                                    responseDocuments: urAndRelatedResult[2]?.documents,
                                });
                            }
                        } else if (referralAndRelatedResult[0].referralServiceType[0].serviceId === ServicesEnum.TT) {
                            if (
                                referralAndRelatedResult[0].referralServiceType.findIndex(
                                    (st) => st.serviceTypeAbbreviation === 'TP',
                                ) !== -1
                            ) {
                                requestsList.push(
                                    getReferralTransportation(+this.props.match.params.id, this.abortController.signal),
                                    getServices(this.abortController.signal),
                                    getAppointmentTypes(this.abortController.signal),
                                    getTransportationRequireds(this.abortController.signal),
                                    getOriginations(this.abortController.signal),
                                    getDestinations(this.abortController.signal),
                                    getWheelchairOrStrecherDetails(this.abortController.signal),
                                    getAllTtSchedules(+this.props.match.params.id, this.abortController.signal),
                                );

                                const tpAndRelatedResult = await Promise.all(requestsList).catch((_err) => {
                                    this.setState({
                                        loading: false,
                                    });
                                });
                                if (tpAndRelatedResult) {
                                    this.setState({
                                        transportationService: tpAndRelatedResult[0],
                                        services: tpAndRelatedResult[1],
                                        appointmentTypes: tpAndRelatedResult[2],
                                        transportationRequireds: tpAndRelatedResult[3],
                                        originations: tpAndRelatedResult[4],
                                        destinations: tpAndRelatedResult[5],
                                        wheelchairOrStretcherDetails: tpAndRelatedResult[6],
                                        transportationTranslationResponses: tpAndRelatedResult[7],
                                    });
                                }
                            }

                            if (
                                referralAndRelatedResult[0].referralServiceType.some((s) => translationTypes.includes(s.serviceTypeAbbreviation))
                            ) {
                                const tlRequestList = [];
                                tlRequestList.push(
                                    getReferralTranslation(+this.props.match.params.id, this.abortController.signal),
                                    getServices(this.abortController.signal),
                                    getAppointmentTypes(this.abortController.signal),
                                    getInterpretationTypes(this.abortController.signal),
                                    getAllTtSchedules(+this.props.match.params.id, this.abortController.signal),
                                );

                                const tlAndRelatedResult = await Promise.all(tlRequestList).catch((_err) => {
                                    this.setState({
                                        loading: false,
                                    });
                                });
                                if (tlAndRelatedResult) {
                                    this.setState({
                                        translationService: tlAndRelatedResult[0],
                                        services: tlAndRelatedResult[1],
                                        appointmentTypes: tlAndRelatedResult[2],
                                        interpretationTypes: tlAndRelatedResult[3],
                                        transportationTranslationResponses: tlAndRelatedResult[4],
                                    });
                                }
                            }
                        } else if (referralAndRelatedResult[0].referralServiceType[0].serviceId === ServicesEnum.DME) {
                            Promise.all([
                                getReferralDme(+this.props.match.params.id, this.abortController.signal),
                                getReferralDmeResponse(+this.props.match.params.id, this.abortController.signal),
                            ]).then((dmeResult) => {
                                this.setState(() => {
                                    return {
                                        dmeService: dmeResult[0],
                                        dmeResponse: dmeResult[1],
                                    };
                                });
                            });
                        }
                    }

                    const referralsDocumentsResult = await getReferralDocument(
                        +this.props.match.params.id,
                        this.abortController.signal,
                    ).catch((_err) => {
                        this.setState({
                            loading: false,
                        });
                    });
                    if (referralsDocumentsResult) {
                        this.setState({
                            referralDocuments: referralsDocumentsResult,
                            loading: false,
                        });
                    }
                }
            }
        }
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    tabChangeHandler = (index: number) => {
        console.log("tabChangeHandler",index);
        if (index === 0) {
            this.setState({
                sidebar: false,
                tabIndex: index,
            });
        } else {
            this.setState({
                sidebar: true,
                tabIndex: index,
            });
        }
    };

    sendSubmission(referralId, submission) {
        sendSubmissionRequest(referralId, submission, this.abortController?.signal)
            .then(() => {
                window.location.reload();
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getDiagnosisById(diagnosisId) {
        let diagnosis;
        getDiagnosisById(diagnosisId, this.abortController?.signal).then((result) => {
            diagnosis = result;
        });
        return diagnosis;
    }

    getTabs() {
        switch (this.state.referralOverviewDataModel?.referralServiceType[0].serviceId) {
            case ServicesEnum.IME:
                return [
                    'Info',
                    'Claim Info',
                    'Referral Request',
                    'Referral Response',
                    'Scorecard',
                    'Documents',
                    'Activity',
                ];
            case ServicesEnum.Diagnostic:
            case ServicesEnum.PT:
                return [
                    'Info',
                    'Claim Info',
                    'Referral Request',
                    'Referral Response',
                    'Scorecard',
                    'Documents',
                    'Activity',
                ];
            case ServicesEnum.UR:
            case ServicesEnum.TT:
            case ServicesEnum.DME:
                return ['Info', 'Claim Info', 'Referral Request', 'Referral Response', 'Documents', 'Activity'];
            default:
                return [
                    'Info',
                    'Claim Info',
                    'Referral Request',
                    'Referral Response',
                    'Scorecard',
                    'Documents',
                    'Activity',
                ];
        }
    }

    isScorecardTabActive() {
        return (
            this.state.referralOverviewDataModel?.referralServiceType[0].serviceId !== ServicesEnum.UR &&
            this.state.referralOverviewDataModel?.referralServiceType[0].serviceId !== ServicesEnum.TT &&
            this.state.referralOverviewDataModel?.referralServiceType[0].serviceId !== ServicesEnum.DME
        );
    }

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                {this.state.loading ? (
                    <Loader />
                ) : (
                    <Fragment>
                        <BreadCrumb sections={this.state.referralDetailBreadCrumb} />
                        <Fragment>
                            {this.state.sidebar ? (
                                <Sidebar>
                                    <ClaimSidebar
                                        claimDataModel={getClaimDataModel(
                                            this.state.referralOverviewDataModel?.claim,
                                            this.state.referralOverviewDataModel?.claimCoverageType,
                                            getReferralClient(this.state.referralOverviewDataModel?.client),
                                            this.state.referralOverviewDataModel?.claimState,
                                            null,
                                        )}
                                        referral={this.state.referralOverviewDataModel?.referral}
                                        employerName={this.state.referralEmployer?.name}
                                        vendorName={this.state.referralOverviewDataModel?.referralVendor?.vendorName}
                                        serviceTypeName={this.state.referralOverviewDataModel?.referralServiceType.map(
                                            (st) => st.serviceTypeName,
                                        )}
                                        referralStatus={this.state.referralStatuses}
                                        side={true}
                                    />
                                </Sidebar>
                            ) : (
                                ''
                            )}
                            <div className={this.state.sidebar ? classes.content : classes.contentWithoutSideBar}>
                                <Fragment>
                                    <div className={classes.tabs}>
                                        <NavigationBar>
                                            <TabsBar
                                                tabs={this.getTabs()}
                                                tabChangeHandler={this.tabChangeHandler}
                                                default={this.state.tabIndex}
                                            />
                                        </NavigationBar>
                                    </div>
                                    <div role="tabpanel" hidden={this.state.tabIndex !== 0}>
                                        <ReferralOverview
                                            diagnosticService={this.state.diagnosticService}
                                            utilizationReviewService={this.state.utilizationReviewService}
                                            imeCoverLetter={this.state.imeCoverLetter}
                                            services={this.state.services}
                                            diagnosticProcedureResponse={this.state.diagnosticProcedureResponse}
                                            referralNotificationTracker={this.state.referralNotificationTracker}
                                            scorecardSections={this.state.scorecardSections}
                                            specificBodyParts={this.state.specificBodyParts}
                                            medicalService={this.state.medicalService}
                                            scheduleStatusTypes={this.state.referralScheduleStatusTypes}
                                            referralActivities={this.state.referralActivities}
                                            referralOverviewDataModel={this.state.referralOverviewDataModel}
                                            referralOverviewImeDataModel={this.state.referralOverviewImeDataModel}
                                            imeScorecardSections={this.state.imeScorecardSections}
                                            sendSubmission={this.sendSubmission}
                                            transportationService={this.state.transportationService}
                                            translationService={this.state.translationService}
                                            appointmentTypes={this.state.appointmentTypes}
                                            destinations={this.state.destinations}
                                            interpretationTypes={this.state.interpretationTypes}
                                            originations={this.state.originations}
                                            transportationRequireds={this.state.transportationRequireds}
                                            languages={this.state.languages}
                                            dmeResponse={this.state.dmeResponse}
                                        />
                                    </div>
                                    <div role="tabpanel" hidden={this.state.tabIndex !== 1}>
                                        <ReferralClaimInfo
                                            referralOverviewDataModel={this.state.referralOverviewDataModel}
                                            states={this.state.states}
                                            referralClient={this.state.referralClient}
                                            coverageTypes={this.state.coverageTypes}
                                            referralClaim={this.state.referralClaim}
                                            referralAdjuster={this.state.referralAdjuster}
                                            referralClaimant={this.state.referralClaimant}
                                            referralClaimantAttorney={this.state.referralClaimantAttorney}
                                            referralEmployer={this.state.referralEmployer}
                                            referralEmployerAttorney={this.state.referralEmployerAttorney}
                                            referralNurseCaseManager={this.state.referralNurseCaseManager}
                                        />
                                    </div>
                                    <div role="tabpanel" hidden={this.state.tabIndex !== 2}>
                                        <ReferralRequest
                                            utilizationReviewService={this.state.utilizationReviewService}
                                            diagnosticService={this.state.diagnosticService}
                                            imeCoverLetter={this.state.imeCoverLetter}
                                            injuryTypes={this.state.injuryTypes}
                                            bodySides={this.state.bodySides}
                                            specificBodyParts={this.state.specificBodyParts}
                                            referralOverviewDataModel={this.state.referralOverviewDataModel}
                                            ime={this.state.imeRequest}
                                            languages={this.state.languages}
                                            specialties={this.state.specialties}
                                            states={this.state.states}
                                            treatingPhysician={this.state.treatingPhysician}
                                            recommendedPhysician={this.state.recommendedPhysician}
                                            medicalService={this.state.medicalService}
                                            serviceId={this.state.serviceId}
                                            procedureDetails={this.state.procedureDetails}
                                            transportationService={this.state.transportationService}
                                            translationService={this.state.translationService}
                                            appointmentTypes={this.state.appointmentTypes}
                                            destinations={this.state.destinations}
                                            interpretationTypes={this.state.interpretationTypes}
                                            originations={this.state.originations}
                                            transportationRequireds={this.state.transportationRequireds}
                                            serviceType={this.state.serviceType}
                                            wheelchairOrStretcherDetails={this.state.wheelchairOrStretcherDetails}
                                            dmeService={this.state.dmeService}
                                        />
                                    </div>
                                    <div role="tabpanel" hidden={this.state.tabIndex !== 3}>
                                        <ReferralResponse
                                            proceduresTable={this.state.diagnosticService?.proceduresTable}
                                            serviceTypeResponseUr={this.state.serviceTypeResponseUr}
                                            services={this.state.services}
                                            diagnosticProcedureResponse={this.state.diagnosticProcedureResponse}
                                            serviceId={this.state.serviceId}
                                            physicalTherapyDataModel={this.state.medicalService}
                                            referralOverviewDataModel={this.state.referralOverviewDataModel}
                                            referralOverviewImeDataModel={this.state.referralOverviewImeDataModel}
                                            imeResponse={this.state.imeResponse}
                                            referralScheduleStatusTypes={this.state.referralScheduleStatusTypes}
                                            physician={this.state.responsePhysician}
                                            states={this.state.states}
                                            responseDocuments={this.state.responseDocuments}
                                            serviceType={this.state.serviceType ? this.state.serviceType[0] : null}
                                            ReferralVisitStatuses ={this.state.ReferralVisitStatuses}
                                            transportationTranslationResponses={
                                                this.state.transportationTranslationResponses
                                            }
                                            dmeResponse={this.state.dmeResponse}
                                        />
                                    </div>
                                    {this.isScorecardTabActive() && (
                                        <div role="tabpanel" hidden={this.state.tabIndex !== 4}>
                                            <ReferralScorecard
                                                diagnosticProcedureScorecardResponse={
                                                    this.state.diagnosticProcedureScorecardResponse
                                                }
                                                medicalService={this.state.medicalService}
                                                scorecardSections={this.state.scorecardSections}
                                                referralOverviewDataModel={this.state.referralOverviewDataModel}
                                                imeScorecardResponses={this.state.imeScorecardResponses}
                                                imeScorecardSections={this.state.imeScorecardSections}
                                            />
                                        </div>
                                    )}
                                    <div
                                        role="tabpanel"
                                        hidden={
                                            this.isScorecardTabActive()
                                                ? this.state.tabIndex !== 5
                                                : this.state.tabIndex !== 4
                                        }
                                    >
                                        <ReferralDocuments
                                            referralOverviewDataModel={this.state.referralOverviewDataModel}
                                            referralDocuments={this.state.referralDocuments}
                                        />
                                    </div>
                                    <div
                                        role="tabpanel"
                                        hidden={
                                            this.isScorecardTabActive()
                                                ? this.state.tabIndex !== 6
                                                : this.state.tabIndex !== 5
                                        }
                                    >
                                        <ReferralActivities
                                            referralOverviewDataModel={this.state.referralOverviewDataModel}
                                            referralActivities={this.state.referralActivities}
                                            activityTypes={this.state.activityTypes}
                                        />
                                    </div>
                                </Fragment>
                            </div>
                        </Fragment>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export const ReferralDetail = withRouter(withStyles(styles)(ReferralDetailComponent));
