import ProductInformationEntity from './ProductInformation.Entity';

export default class ServiceTypeRequestDmeEntity {
    referralId: number;
    products: ProductInformationEntity[];
    rushRequest: boolean;
    prescription: boolean;
    deliveryAddress: string;
    address: string;
    contactName: string;
    contactPhoneNumber: string;
    requestDeliveryDate: Date;
    additionalComments: string;
    stateId: number;
    cityName: string;
    zipCode: string;
    duration: number;
}
