import { apiPost, apiPut } from '../support/helpers/ApiConnection.Helper';
import ReferralContactEntity from '../entities/ReferralContact.Entity';

export function sendReferralContact(referralContact: ReferralContactEntity, abortSignal: AbortSignal, referralContactId?: number): Promise<ReferralContactEntity> {
    if (referralContactId){
        return apiPut<ReferralContactEntity>('/referral-contacts', referralContactId, referralContact, abortSignal);
    }
    else{
        return apiPost<ReferralContactEntity>('/referral-contacts', referralContact, abortSignal);
    }
}
