import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { TooltipTextField } from '../../../../../generic-components/tooltip-textfield/TooltipTextField.Component';
import styles from './DmeHeaderSummary.Styles';
import Moment from 'moment';
import ServiceTypeRequestDmeEntity from '../../../../../../entities/dme/ServiceTypeRequestDme.Entity';
import { MaterialTableProps } from 'material-table';
import MaterialTable from '../../../../../generic-components/material-table/MaterialTable.Component';
import ProductInformationEntity from '../../../../../../entities/dme/ProductInformation.Entity';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StateEntity from '../../../../../../entities/State.Entity';

export interface IDmeHeaderSummary {
    serviceTypeRequestDme: ServiceTypeRequestDmeEntity;
    states: Array<StateEntity>;
}

const MakeFullAddress = (dmeRow : ProductInformationEntity, states:StateEntity[]) =>
    {
        const stateName = states?.find(element => element.stateId==dmeRow.stateId)?.stateName;
        let addressString = dmeRow.address;
        if (dmeRow.cityName) {
            addressString += ", " + dmeRow.cityName;
        }
        if (stateName) {
            addressString += " " + stateName;
        }
        if (dmeRow.zipCode) {
            addressString += " " + dmeRow.zipCode;
        }

        return addressString;
}

export const DmeHeaderSummary: FunctionComponent<IDmeHeaderSummary> = ({ serviceTypeRequestDme, states }) => {
    const classes = styles();

    const productTableProps: MaterialTableProps<ProductInformationEntity> = {
        columns: [
            {
                title: 'Product Category',
                field: 'productCategoryName',
            },
            {
                title: 'Product',
                field: 'productName',
            },
            {
                title: 'Quantity',
                field: 'quantity',
            },
            {
                title: 'Rental/Purchase',
                field: 'rentalPurchase',
            },
            {
                title: 'Durations (days)',
                field: 'duration',
            },
            {
                title: 'Delivery Address',
                field:'fullAddress',
                render: (rowData) =>
                    MakeFullAddress(rowData,states) ,
            },
            {
                title: 'Location',
                field: 'deliveryLocation',
            },
            {
                title: 'Requested Delivery Date',
                field: 'requestDeliveryDate',
                render: (rowData) => {
                    const formattedDate = rowData.requestDeliveryDate 
                        ? Moment(rowData?.requestDeliveryDate)?.format('YYYY-MM-DD')
                        : '';
                    return formattedDate;
                },
            },
        ],
        data: serviceTypeRequestDme?.products ?? [],
        options: {
            selection: false,
            paging: false,
            toolbar: false,
            actionsColumnIndex: -1,
        },
    };

    return (
        <Accordion defaultExpanded={true} className={classes.paper}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                {
                    <Typography variant="subtitle2" className={classes.sidebarTitle}>
                        Durable Medical Equipment
                    </Typography>
                }
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <MaterialTable tableProps={productTableProps} />
                    </Grid>
                    {/*row 3*/}

                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            label="Rush Request"
                            value={serviceTypeRequestDme?.rushRequest ? 'Yes' : 'No'}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            label="Prescription"
                            value={serviceTypeRequestDme?.prescription ? 'Yes' : 'No'}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {/*Empty space */}
                    </Grid>
                    {/*row 4*/}
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            label="Contact Name"
                            value={serviceTypeRequestDme?.contactName}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            label="Contact Phone Number"
                            value={serviceTypeRequestDme?.contactPhoneNumber}
                        />
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>

                    {/*row 6*/}

                    <Grid item xs={12}>
                        <TooltipTextField
                            type="typography"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            label="Additional Comments"
                            value={serviceTypeRequestDme?.additionalComments}
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
