import ErrorLogEntity from '../../entities/error-log/ErrorLog.Entity';
import sendErrorLog from '../../services/ErrorLog.Service';
import { ApiMethodsEnum } from '../enums/ApiMethods.Enum';
import { toast } from 'react-toastify';

export function apiGet<T>(
    url: string,
    abortSignal: AbortSignal,
    queryString?: string,
    showDefaultMsg?: boolean,
): Promise<T> {
    return processRequest(url, ApiMethodsEnum.Get, abortSignal, null, queryString, showDefaultMsg);
}

export function apiPost<T>(
    url: string,
    request: T | any,
    abortSignal: AbortSignal,
    showDefaultMsg?: boolean,
    throwError: boolean = true,
): Promise<T> {
    return processRequest(
        url,
        ApiMethodsEnum.Post,
        abortSignal,
        JSON.stringify(request),
        null,
        showDefaultMsg,
        throwError,
    );
}

export function apiPut<T>(
    url: string,
    id: number,
    request: T,
    abortSignal: AbortSignal,
    showDefaultMsg?: boolean,
): Promise<T> {
    return processRequest(
        url + '/' + id,
        ApiMethodsEnum.Put,
        abortSignal,
        JSON.stringify(request),
        null,
        showDefaultMsg,
    );
}

export function apiDelete<bool>(
    url: string,
    id: number,
    abortSignal: AbortSignal,
    showDefaultMsg: boolean = null,
): Promise<bool> {
    return processRequest(url + '/' + id, ApiMethodsEnum.Delete, abortSignal, null, null, showDefaultMsg);
}

function processRequest(
    url: string,
    method: ApiMethodsEnum,
    abortSignal: AbortSignal,
    jsonRequest?: string,
    queryString?: string,
    showDefaultMsg?: boolean,
    throwError: boolean = true,
) {
    const baseUrl = process.env.REACT_APP_API_ROUTE;

    //Filling request options
    const requestOptions = {
        signal: abortSignal,
        method: method,
        headers: {
            Authorization: window.sessionStorage.getItem('Authorization'),
            'Content-Type': 'application/json',
            'X-Tenant-Authorization': window.sessionStorage.getItem('X-Tenant-Authorization'),
        },
    } as RequestInit;
    if (method === ApiMethodsEnum.Post || method === ApiMethodsEnum.Put) {
        requestOptions.body = jsonRequest;
    }

    let requestUrl = queryString ? baseUrl + url + '?' + queryString : baseUrl + url;
    
    return fetch(requestUrl, requestOptions)
        .then((response) => {
            if (!response.ok) {
                throw response;
            }

            return response.json().catch(() => null);
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            let errorCloned = new Error(error.message);
            errorCloned.name = error.name;
            errorCloned.stack = error.stack;
            if (showDefaultMsg == null || showDefaultMsg == true) {
                if (error && error.bodyUsed !== null && !error.bodyUsed) {
                    if (throwError) {
                        processWarning(error);
                    }
                } else {
                    if (throwError) {
                        processServerCommunicationError(error);
                    }
                }
            }
            throw errorCloned;
        });
}

function processServerCommunicationError(err: any, message: string = 'Server communication failed') {
    toast.error('❌ ' + message);
    var abortSignal: AbortSignal;
    var errorLog = new ErrorLogEntity();
    errorLog.ErrorMessage = message;
    sendErrorLog(errorLog,abortSignal);
}

function processWarning(err: any, message: string = 'Warning') {
    if (err instanceof Response) {
        err.json()
            .then((serverMessage) => {
                let toastMessage: string = serverMessage || message;
                toast.warning('⚠️ ' + toastMessage);
            })
            .catch(() => processServerCommunicationError(err));
    } else {
        let toastMessage: string = message;
        toast.warning('⚠️ ' + toastMessage);
        processServerCommunicationError(err);
    }
}
