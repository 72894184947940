import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary, Divider, FormControl, FormLabel, List, ListItem, ListItemIcon, Checkbox, ListItemText, ThemeProvider } from '@material-ui/core';
import styles from "./ClaimantSummary.Styles";
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import StateEntity from '../../../../entities/State.Entity';
import Moment from 'moment';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';

interface IClaimantSummaryProps {
    referralClaimant: ReferralContactEntity;
    states: Array<StateEntity>;
    serviceId?: number;
}

export const ClaimantSummary: FunctionComponent<IClaimantSummaryProps> = (props: IClaimantSummaryProps) => {
    const classes = styles();
    const genders = [{ value: 'M', label: 'Male' }, { value: 'F', label: 'Female' }, { value: 'U', label: 'Unknown' }];

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>Claimant</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.referralClaimant ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>First Name</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimant.firstName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Date Of Hire</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{Moment(props.referralClaimant?.dateOfHire).format('MM/DD/YYYY')}</Typography>
                            </Grid>
                            {
                                props.serviceId === ServicesEnum.Diagnostic ?
                                <Grid container xs={3} spacing={1} style={{ marginTop:'9px', marginRight: '4px'}}>
                                    <Grid item xs={3} style={{ marginLeft: '12px', marginRight: '-15%' }}>
                                        <Typography variant="body2" className={classes.dataLabel}>Zip Code</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimant.zipCode}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Grid item xs={3} style={{ marginTop:'12px',marginLeft: '15px', marginRight: '-8%' }}>
                                            _
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3} style={{marginRight: '15px'}}>
                                        <Typography variant="body2" className={classes.dataLabel}>Zip Code Ext.</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimant.zipCodeExtension}</Typography>
                                    </Grid>
                                </Grid>
                                :
                                <Grid item xs={3}>
                                    <Typography variant="body2" className={classes.dataLabel}>Zip Code</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimant?.zipCode}</Typography>
                                </Grid>
                            }
                            <Grid item xs={3}>
                                {/* EMPTY SPACE */}
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Last Name</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimant?.lastName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Address Line 1</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimant?.addressLine1}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" className={classes.dataLabel}>Email</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimant?.email}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Claimant MI</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimant?.middleInitial}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Address Line 2</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimant?.addressLine2}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Mobile Phone</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimant?.primaryPhoneNumber}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                {/* EMPTY SPACE */}
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Last Four SSN</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimant?.lastFourSSN}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>City</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimant?.cityName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Secondary Phone</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimant?.secondaryPhoneNumber}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Ext.</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimant?.secondaryPhoneExtension}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Date Of Birth</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{Moment(props.referralClaimant?.dateOfBirth).format('MM/DD/YYYY')}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>State</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.states?.filter(x => !!props.referralClaimant && !!props.referralClaimant.stateId && x.stateId == props.referralClaimant.stateId)[0]?.stateName}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" className={classes.dataLabel}>Fax</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimant?.fax}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Gender</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{genders.filter(x => !!props.referralClaimant && !!props.referralClaimant.gender && x.value == props.referralClaimant.gender)[0]?.label}</Typography>
                            </Grid>
                        </Grid>
                        : <EmptyDataLabel></EmptyDataLabel>
                }
            </AccordionDetails>
        </Accordion>
    );
}