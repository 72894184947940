import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 100;

export default makeStyles((theme: Theme) => ({
    paper:{
        marginTop:'20px',
        marginBottom:'20px'
        //padding:'10px',
        //paddingTop: '15px',
        //paddingLeft: '35px',
        //paddingRight: '35px',
        //paddingBottom: '25px',
    },
    button:{
        color:'white',
        backgroundColor:'#107A98',
        '&:hover': {
            backgroundColor: '#004763!important',
        },
    },
}));