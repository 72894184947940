import { createTheme , createStyles, Theme } from '@material-ui/core/styles';

// const drawerWidth = 320;
const drawerWidth = 0;
const contentHorizontalPadding = 25;

export default (theme: Theme) => createStyles({
    content: {
        [theme.breakpoints.up('sm')]: {
            paddingRight: drawerWidth,
        },
        // position: "absolute",
        // width: `calc(100vw - ${drawerWidth}px - ${contentHorizontalPadding}px)`,
        // width: '100%'
    },
    saveAllButton: {
        marginRight: theme.spacing(1),
        float: "right",
        backgroundColor: '#107A98',
        '&:hover': {
            backgroundColor: '#004763!important',
        }
    },
    nextButton: {
        float: "right",
        backgroundColor: '#107A98',
        '&:hover': {
            backgroundColor: '#004763!important',
        }
    },
    backButton: {
        marginRight: theme.spacing(1),
        float: "right",
        backgroundColor: '#DAEAEF',
        color: '#107A98'
    },
    startoverButton: {
        marginRight: theme.spacing(1),
        float: "left",
        color: '#107A98',
        textDecoration: 'underline'
    },
    refreshButton: {
        marginRight: theme.spacing(1),        
        color: '#107A98',
        borderColor: "#107A98"
    },
    formContent: {
        marginLeft: 25,
        marginRight: 25,
        marginTop: 275,
        marginBottom: 60,
    },
    formFooterContainer: {
        backgroundColor: "#F8F8F8",
        borderTop: "1px solid #E7E7E7",
        position: "fixed",
        left: "0",
        bottom: "0",
        height: "60px",
        width: "100%",
        paddingRight: drawerWidth,
        zIndex: 70
    },
    formFooter: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '60px',
        width: '100%',
        paddingLeft: '60px',
        paddingRight: '60px'
    },
    firstBottomButton: {
        flex: 1
    },
    controlsDisabled: {
        pointerEvents: 'none'
    },
    wizardBar:{
        marginLeft: '25px',
        marginRight: '25px'
    },
    fixedHead:{
        position: 'fixed',
        marginTop: '0px',
        top: '64px',
        zIndex: 999,
        backgroundColor: '#E7EEF1',
        marginRight:'40px',
        width: '-webkit-fill-available' 
    },
    diffBrowser:{
        width: '-moz-available'
    }
});

export const newTheme = createTheme ({
    overrides: {
        MuiListItem: {
            root: {
                minHeight: '30px !important'
            }
        },
        MuiStepLabel: {
            alternativeLabel: {
                marginTop: "0px !important"
            }
        },
        MuiRadio: {
            colorPrimary: {
                '&$checked': {
                    color: '#107A98!important',
                },
            },
            root: {
                color: '#898F91!important'
            },
            colorSecondary: {
                '&$checked': {
                    color: '#107A98!important'
                },
                color: '#898F91!important'
            }
        },
        MuiCheckbox: {
            colorSecondary: {
                '&$checked': {
                    color: '#107A98!important',
                },
            },
            colorPrimary: {
                color: '#898F91!important',
            }
        },
        MuiInputBase: {
            root: {
                fontSize: '0.9rem'
            },
            input: {
                color: '#000'
            }
        },
        MuiInput: {
            underline: {
                "&$disabled": {
                    "&&&:before": {
                        borderBottom: "none"
                    },
                    "&&:after": {
                        borderBottom: "none"
                    }
                }

            }
        },
        MuiSelect: {
            icon: {
                "&$disabled": {
                    color: 'transparent'
                }
            }
        },
        MuiFormControl: {
            root: {
                width: '100%',
            }
        }
    }
});