import React, { FunctionComponent, useState, useEffect  } from 'react';
import { Typography, Grid, TextField, ThemeProvider, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import PrivateComponent from '../../../../generic-components/private-component/PrivateComponent.Component';
import { ReferralPtExtendStopVisits } from '../../../referral-pt-extend-stop-visits/ReferralPtExtendStopVisits.Component';
import { useFeatureFlag } from '../../../../generic-components/feature-flag-hook/useFeatureFlag';
import { UserRolesEnum } from '../../../../../support/enums/UserRoles.Enum';
import WarningIcon from '@material-ui/icons/Warning';
import styles, {referralPtSummaryTheme} from "./ReferralPtSummary.Styles";
import { EmptyDataLabel } from '../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import SpecificBodyPartEntity from '../../../../../entities/SpecificBodyPart.Entity';
import MedicalServiceDataModel from "../../../../../data-models/MedicalService.DataModel";
import SubmissionEntity from '../../../../../entities/Submission.Entity';
import Moment from 'moment';
import { MaterialTableProps } from "material-table";
import ServiceTypeRequestPtDetailDataModel from "../../../../../data-models/pt/ServiceTypeRequestPtDetail.DataModel";
import MaterialTable from "../../../../generic-components/material-table/MaterialTable.Component";

interface IReferralPtSummaryProps {
    medicalService: MedicalServiceDataModel;
    specificBodyParts: Array<SpecificBodyPartEntity>;
    sendSubmission: (referralId: number, add: SubmissionEntity) => void;
}

export const ReferralPtSummary: FunctionComponent<IReferralPtSummaryProps> = (props: IReferralPtSummaryProps) => {
    const classes = styles();

    // const { enabled: showComponent } = useFeatureFlag('ShowComponent');
    // const showVisitsButtons = showComponent;
    const showVisitsButtons = false;

    const [isStopped,setIsStopped] = useState(props.medicalService?.serviceType.stoppedVisitsDateOn != null ? true : false);
    const [visitsAttended,setVisitsAttended] = useState(props.medicalService?.visits?.filter(x => x.claimantAttendance == true));


    useEffect(()=>{
        if (props.medicalService?.serviceType.stoppedVisitsDateOn != null){
            setIsStopped(true);
        }
        setVisitsAttended(props.medicalService?.visits?.filter(x => x.claimantAttendance == true));
    },[props.medicalService])

    const serviceTypeRequestPtTableProps: MaterialTableProps<ServiceTypeRequestPtDetailDataModel> = {
        columns: [
            {
                title:'Specific Body Parts',
                field:'specificBodyPartName'
            },
            {
                title:'Body Side',
                field:'bodySideName'
            },
            {
                title:'Injury Type',
                field:'injuryTypeName'
            },
        ],
        data: props.medicalService?.serviceType?.serviceTypeRequestPtDetail ?? [],
        options: {
            filtering: false,
            showTitle: true,
            paging: false,
            toolbar: false,
            actionsColumnIndex: -1,
        },
        style:{
            paddingLeft: '35px',
            paddingRight: '35px',
        }
    }

    return (    
            <Accordion defaultExpanded={true} className={classes.paper} square>
                <AccordionSummary
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
                >
                    <Typography variant="subtitle2" className={classes.paperTitle}>PM</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {
                        props.medicalService ?
                            <Grid container spacing={3} className={classes.gridContainer}>
                                <Grid xs={12} item container >
                                    <Grid item xs={3}>
                                        <Typography variant="body2" className={classes.dataLabel}>Number of approved visits</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.medicalService.serviceType.numberOfApprovedVisits ? props.medicalService.serviceType.numberOfApprovedVisits : ''}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body2" className={classes.dataLabel}>Frequency & duration</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.medicalService.serviceType.frequencyAndDuration ? props.medicalService.serviceType.frequencyAndDuration : ''}</Typography>
                                    </Grid>
                                    {/*<Grid item xs={3}>
                                        <Typography variant="body2" className={classes.dataLabel}>Body Part</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.specificBodyParts?.filter(x => props.medicalService.serviceType.specificBodyPartId && x.specificBodyPartId == props.medicalService.serviceType.specificBodyPartId)[0]?.specificBodyPartName ? props.specificBodyParts?.filter(x => props.medicalService.serviceType.specificBodyPartId && x.specificBodyPartId == props.medicalService.serviceType.specificBodyPartId)[0]?.specificBodyPartName : ''}</Typography>
                                    </Grid>*/}
                                    <Grid item xs={3}>
                                        <Typography variant="body2" className={classes.dataLabel}>Latest schedule date & time</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.medicalService?.lastVisit?.scheduleDate ? Moment(props.medicalService.lastVisit.scheduleDate).format('MM/DD/YYYY') + ' at ' + Moment(props.medicalService.lastVisit.scheduleDate).format('HH:mm'): ''}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography variant="body2" className={classes.dataLabel}>Visit #</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.medicalService?.lastVisit?.visitNumber ? props.medicalService?.lastVisit?.visitNumber : ''}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        {
                                            showVisitsButtons &&
                                                <PrivateComponent authorizedRoles={[UserRolesEnum.Adjuster]} sendBack={true}>
                                                    <ReferralPtExtendStopVisits physicalTherapy={props.medicalService} sendSubmission={props.sendSubmission}/>
                                                </PrivateComponent>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid xs={12} item container >
                                    <Grid item xs={3}>
                                        {/* Empty space */}
                                    </Grid>
                                    <Grid item xs={3}>
                                        {
                                            isStopped &&
                                                <Grid item xs={3} container>
                                                    <Grid item xs={1} className={classes.gridInfoText}>
                                                        <WarningIcon style={{ color: 'red' }}/>
                                                    </Grid>
                                                    <Grid item xs={11} className={classes.gridInfoText}>
                                                        <Typography align="left" variant="subtitle2" className={classes.blackFont}>Visits from {visitsAttended.length + 1} to {props.medicalService.serviceType.numberOfApprovedVisits} were cancelled by the adjuster</Typography>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </Grid>
                                </Grid>
                                {
                                    props.medicalService?.serviceType.addedVisitsDateOn &&
                                        <Grid xs={12} item container >
                                            <Grid item xs={3}>
                                                <Typography variant="body2" className={classes.dataLabel}>Extended visits by Adjuster</Typography>
                                                {
                                                    props.medicalService?.response?.isAddedVisitsSuccessful != true ?
                                                        <Typography variant="subtitle2" className={classes.blackFont}>{"From " + (props.medicalService?.serviceType.numberOfApprovedVisits) + " to " + (props.medicalService?.serviceType.numberOfApprovedVisits + props.medicalService?.serviceType.numberOfAddedVisits)}</Typography>
                                                        :  <Typography variant="subtitle2" className={classes.blackFont}>{"From " + (props.medicalService?.serviceType.numberOfApprovedVisits-props.medicalService?.serviceType.numberOfAddedVisits) + " to " + props.medicalService?.serviceType.numberOfApprovedVisits}</Typography>
                                                }
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="body2" className={classes.dataLabel}>Date</Typography>
                                                <Typography variant="subtitle2" className={classes.blackFont}>{Moment(props.medicalService?.serviceType.addedVisitsDateOn).format('MM/DD/YYYY')}</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="body2" className={classes.dataLabel}>Vendor Confirmation</Typography>
                                                <Typography variant="subtitle2" className={classes.blackFont}>{props.medicalService?.response?.isAddedVisitsSuccessful ? "Confirmed" : "Pending"}</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                {/* Empty space */}
                                            </Grid>
                                        </Grid>
                                }
                                <Grid xs={12} item >
                                    <MaterialTable tableProps={serviceTypeRequestPtTableProps} />
                                </Grid>
                                <Grid xs={12} item container >
                                    <ThemeProvider theme={referralPtSummaryTheme}>
                                        <TextField className={classes.summaryTextArea} multiline disabled label="Progress Summary" id="progressSummary" defaultValue={props.medicalService?.progressSummary ? props.medicalService?.progressSummary : ''} />
                                    </ThemeProvider>
                                </Grid>
                                {
                                    props.medicalService.serviceType.stoppedVisitsDateOn &&
                                        <Grid xs={12} item container >
                                            <Grid item xs={6}>
                                                <ThemeProvider theme={referralPtSummaryTheme}>
                                                    <TextField className={classes.stoppedVisitsReason} multiline disabled label="Stopped visits - Reason" id="stoppedVisitsReason" defaultValue={props.medicalService?.serviceType.stoppedVisitsReason ? props.medicalService?.serviceType.stoppedVisitsReason : ''} />
                                                </ThemeProvider>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="body2" className={classes.dataLabel}>Date</Typography>
                                                <Typography variant="subtitle2" className={classes.blackFont}>{Moment(props.medicalService?.serviceType.stoppedVisitsDateOn).format('MM/DD/YYYY')}</Typography>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography variant="body2" className={classes.dataLabel}>Vendor Confirmation</Typography>
                                                <Typography variant="subtitle2" className={classes.blackFont}>{props.medicalService?.response?.isStoppedVisitsSuccessful ? "Confirmed" : "Pending"}</Typography>
                                            </Grid>
                                        </Grid>
                                }
                            </Grid>
                        :
                            <EmptyDataLabel></EmptyDataLabel>
                    }  
                </AccordionDetails>
            </Accordion>
    );
}