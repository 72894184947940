import ReferralContactEntity from "../entities/ReferralContact.Entity";
import ServiceTypeRequestUrEntity from "../entities/UtilizationReviewDetail.Entity";
import ServiceTypeScorecardResponseEntity from "../entities/pt/ServiceTypeScorecardResponse.Entity";
import ScorecardResponsesChartContentDataModel from "./pt/ScorecardResponsesChartContentDataModel";

export default class UtilizationReviewServiceDataModel {
    serviceTypeRequestUr: ServiceTypeRequestUrEntity;
    referringPhysician: ReferralContactEntity;
    scorecardResponse: Array<ServiceTypeScorecardResponseEntity>;
    scorecardResponsesChartContent: Array<ScorecardResponsesChartContentDataModel>;
}