import React, { FunctionComponent } from 'react';
import {
    Typography,
    Grid,
    TextField,
    ThemeProvider,
    Accordion,
    AccordionDetails,
    AccordionSummary,
} from '@material-ui/core';
import styles, { referralTranslationSummaryTheme } from './ReferralTranslationSummary.Styles';
import { EmptyDataLabel } from '../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import Moment from 'moment';
import AppointmentTypeEntity from '../../../../../entities/AppointmentType.Entity';
import InterpretationTypeEntity from '../../../../../entities/InterpretationType.Entity';
import TranslationDataModel from '../../../../../data-models/Translation.DataModel';
import LanguageEntity from '../../../../../entities/Language.Entity';

interface IReferralTranslationSummaryProps {
    translationService: TranslationDataModel;
    appointmentTypes: Array<AppointmentTypeEntity>;
    interpretationTypes: Array<InterpretationTypeEntity>;
    languages: Array<LanguageEntity>;
}

export const ReferralTranslationSummary: FunctionComponent<IReferralTranslationSummaryProps> = (
    props: IReferralTranslationSummaryProps,
) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary aria-controls="panel1a-content" id="cliam-accordion" className={classes.paperHeader}>
                <Typography variant="subtitle2" className={classes.paperTitle}>
                    Translation
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {props.translationService ? (
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid xs={12} item container>
                            <Grid item xs={2}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Appointment Type
                                </Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>
                                    {!!props.translationService.serviceTypeRequestTranslation?.appointmentTypeId
                                        ? props.appointmentTypes?.filter(
                                              (x) =>
                                                  props.translationService.serviceTypeRequestTranslation &&
                                                  props.translationService.serviceTypeRequestTranslation
                                                      .appointmentTypeId &&
                                                  x.appointmentTypeId ==
                                                      props.translationService.serviceTypeRequestTranslation
                                                          .appointmentTypeId,
                                          )[0]?.appointmentTypeName
                                        : '-'}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Other Appointment Type
                                </Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>
                                    {props.translationService.serviceTypeRequestTranslation?.otherAppointmentType
                                        ? props.translationService.serviceTypeRequestTranslation?.otherAppointmentType
                                        : '-'}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Appointment Date
                                </Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>
                                    {Moment(
                                        props.translationService.serviceTypeRequestTranslation?.appointmentDate,
                                    ).format('MM/DD/YYYY')}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Language
                                </Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>
                                    {props.translationService.serviceTypeRequestTranslation?.languageId !== 0
                                        ? props.languages?.filter(
                                              (x) =>
                                                  props.translationService.serviceTypeRequestTranslation &&
                                                  props.translationService.serviceTypeRequestTranslation.languageId &&
                                                  x.languageId ==
                                                      props.translationService.serviceTypeRequestTranslation.languageId,
                                          )[0]?.languageName
                                        : 'Other'}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Interpretation Type
                                </Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>
                                    {!!props.translationService.serviceTypeRequestTranslation?.interpretationTypeId
                                        ? props.interpretationTypes?.filter(
                                              (x) =>
                                                  props.translationService.serviceTypeRequestTranslation &&
                                                  props.translationService.serviceTypeRequestTranslation
                                                      .interpretationTypeId &&
                                                  x.interpretationTypeId ==
                                                      props.translationService.serviceTypeRequestTranslation
                                                          .interpretationTypeId,
                                          )[0]?.interpretationTypeName
                                        : '-'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid xs={12} item container>
                            <ThemeProvider theme={referralTranslationSummaryTheme}>
                                <TextField
                                    className={classes.summaryTextArea}
                                    multiline
                                    disabled
                                    label="Special Instructions"
                                    id="specialInstructions"
                                    defaultValue={
                                        props.translationService.serviceTypeRequestTranslation?.specialInstructions
                                            ? props.translationService.serviceTypeRequestTranslation
                                                  ?.specialInstructions
                                            : ''
                                    }
                                />
                            </ThemeProvider>
                        </Grid>
                    </Grid>
                ) : (
                    <EmptyDataLabel></EmptyDataLabel>
                )}
            </AccordionDetails>
        </Accordion>
    );
};
