import ClaimEntity from "../entities/Claim.Entity";
import ClientEntity from "../entities/Client.Entity";
import StateEntity from "../entities/State.Entity";
import UserEntity from "../entities/User.Entity";
import CoverageTypeEntity from "../entities/CoverageType.Entity";

export default class ClaimDataModel {
    claim: ClaimEntity;
    coverageType: CoverageTypeEntity;
    client: ClientEntity;
    stateOfJurisdiction: StateEntity;
    user: UserEntity;
}