import React, { FunctionComponent } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import styles from './Loader.Styles';

interface LoaderProps {
    isSmall?: boolean;
}

export const Loader: FunctionComponent<LoaderProps> = ({ isSmall = false }) => {
    const classes = styles();

    return (
        <div className={classes.root}>
            <LinearProgress
                style={isSmall ? { maxWidth: 'calc(100vw - 77px)', position: 'sticky', left: '77px' } : {}}
            />
        </div>
    );
};
