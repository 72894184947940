import { Tab, Tabs, Typography } from '@material-ui/core';
import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

type TabsBarLinkedProps = {
    handleTabChange?: (newValue: string) => void;
    linkedTabs?: { label: string; link: string }[];
    default?: number;
    title?: string;
    initialTabIndex?: number;
    profileId?: number;
};

export const TabsBarLinked: FunctionComponent<TabsBarLinkedProps> = (props: TabsBarLinkedProps) => {
    const { pathname } = useLocation();
    const [tabValue, setTabValue] = React.useState<string>();

    const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
        setTabValue(newValue);
        props.handleTabChange(newValue);
    };

    useEffect(() => {
        const currentTab = props.linkedTabs?.find((tab) => {
            if (tab.link.includes(':')) {
                const lastItemInTab = tab.link.split('/').pop();
                const lastItemInPath = pathname.split('/').pop();
                return lastItemInTab === lastItemInPath;
            } else {
                return pathname.includes(tab.link);
            }
        })?.label;

        setTabValue(currentTab);
    }, [props.linkedTabs]);

    return (
        <Fragment>
            {props.title && (
                <Typography
                    style={{
                        fontWeight: 500,
                    }}
                >
                    {props.title}
                </Typography>
            )}
            <Tabs
                value={tabValue}
                onChange={handleChange}
                TabIndicatorProps={{
                    style: {
                        height: '3px',
                        backgroundColor: '#004763',
                    },
                }}
            >
                {props.linkedTabs?.map(({ label, link }) => {
                    let finalLink: string;
                    finalLink = link.replace(':id', props.profileId?.toString());

                    return (
                        <Tab value={label} component={Link} to={finalLink} label={label} key={label} disableRipple />
                    );
                })}
            </Tabs>
        </Fragment>
    );
};
