import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Grid, AccordionSummary, Typography, AccordionDetails, Accordion } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './DmeReferralForm.Styles';
import ServiceTypeEntity from '../../../../../entities/ServiceType.Entity';
import DmeServiceDataModel from '../../../../../data-models/dme/DmeService.DataModel';
import ProductCategoryEntity from '../../../../../entities/dme/ProductCategory.Entity';
import ServiceTypeRequestDmeEntity from '../../../../../entities/dme/ServiceTypeRequestDme.Entity';
import { DmeHeader } from './dme-header/DmeHeader.Component';
import { useValidationDialog } from '../../../../../support/custom-hooks/useValidationDialog';
import ReferralContactEntity from '../../../../../entities/ReferralContact.Entity';
import StateEntity from '../../../../../entities/State.Entity';
import { ReferringPhysician } from './referring-physician/ReferringPhysician.Component';

interface IDmeReferralFormProps {
    referralId: number;
    serviceTypes: Array<ServiceTypeEntity>;
    dmeService: DmeServiceDataModel;
    productCategories: Array<ProductCategoryEntity>;
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean) => void;
    handleSetIsValidDmeFunction: (currentPageIsValidDmeFunction: () => boolean, serviceTypeString: string) => void;
    
    openCloseStatus: Object;
    handleOpenCloseStatus: (boxName: string, isOpened: boolean) => void;

    handleDmeServiceEdit: (serviceTypeRequestDme: DmeServiceDataModel) => void;
    states: Array<StateEntity>;
    serviceId: number;
}

export interface IDmeReferralErrors {
    referringPhysicianFirstName?: string;
    referringPhysicianLastName?: string;
    referringPhysicianTreatingNPI?: string;
    referringPhysicianAddressLine1?: string;
    referringPhysicianAddressLine2?: string;
    referringPhysicianZipCode?: string;
    referringPhysicianCityName?: string;
    referringPhysicianState?: string;
    referringPhysicianEmail?: string;
    referringPhysicianPrimaryPhoneNumber?: string;
    referringPhysicianFax?: string;
    productsErrors?: string;
}

export const DmeReferralForm: FunctionComponent<IDmeReferralFormProps> = (props) => {
    
    let isValidDmeProductCategoryValidationFunction = null;
    const classes = styles();
    const {
        referralId,
        serviceTypes,
        dmeService,
        productCategories,
        handleSetValidationFunction,
        handleDmeServiceEdit,
        states,
        serviceId,
    } = props;
    const [hasBeenSubmitted, setHasBeenSubmitted] = useState<boolean>(false);

    const [errors, setErrors] = React.useState<IDmeReferralErrors>(null);
    const { Dialog, checkForErrors } = useValidationDialog('Validation', 'Some required fields are missing.', 'Ok');
    const { Dialog: DialogProducts, checkForErrors: checkForErrorProducts } = useValidationDialog(
        'Validation',
        'At least one product must be added',
        'Ok',
    );

    useEffect(() => {
        //handleSetValidationFunction(validate);
        props.handleSetIsValidDmeFunction(isValidDme, "Dme");
        props.handleSetIsValidDmeFunction(isValidDmeProductCategoryValidationFunction, "DmeProductCategory");
    }, [props]);

    useEffect(() => {
        if ( !props.openCloseStatus.hasOwnProperty( "Dme_box" ) )  props.handleOpenCloseStatus( "Dme_box", true );
        if ( !props.openCloseStatus.hasOwnProperty( "DmeReferringPhysician_box" ) ) props.handleOpenCloseStatus( "DmeReferringPhysician_box", true );
        if (dmeService === null || dmeService === undefined) {
            const newDmeServiceDataModel = new DmeServiceDataModel();

            handleDmeServiceEdit(newDmeServiceDataModel);
        }
    }, []);

    const handleOpenCloseStatus = ( event, boxName ) => {
        event.persist();
        props.handleOpenCloseStatus( boxName, !(props.openCloseStatus?.[boxName]??true) );
    };

    const handleServiceTypeRequestDmeEdit = (serviceTypeRequestDme: ServiceTypeRequestDmeEntity) => {
        const newDmeService = { ...dmeService };

        newDmeService.serviceTypeRequestDme = serviceTypeRequestDme;
        handleDmeServiceEdit(newDmeService);
    };

    const handleReferringPhysicianEdit = (referringPhysician: ReferralContactEntity) => {
        const newDmeService = { ...dmeService };

        newDmeService.referringPhysician = referringPhysician;
        handleDmeServiceEdit(newDmeService);
    };

    const setIsValidDmeProductCategoryFunction = (isValidFunction) => {
        isValidDmeProductCategoryValidationFunction = isValidFunction;
    }

    const validateDme = (isChange?: boolean) => {
        let errors: IDmeReferralErrors = {};
        errors = getErrorsListDme()
        setErrors(errors);
        return checkForErrors(errors, isChange);
    };

    const isValidDme = () => { //returns true/false to know if the form has passed the validation OK (true) or not (false)
        let errors: IDmeReferralErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsListDme();
        setErrors(errors);
        isValidResult = (Object.keys(errors).length == 0);
        return isValidResult;
    }
    const serviceTypeRequestDmeNew = new ServiceTypeRequestDmeEntity();
    const getErrorsListDme = () => {//returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: IDmeReferralErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }

        
        if (
            (dmeService?.serviceTypeRequestDme?.products?.length === 0 ||
                dmeService?.serviceTypeRequestDme?.products === undefined ||
                dmeService?.serviceTypeRequestDme?.products === null) /* && isSubmission */
        ) {
            errors.productsErrors = 'Required';
        }

        return errors;
    }

    return (
        <Fragment>
            <Grid item xs={12}>
                <Accordion className={classes.paper} expanded={props.openCloseStatus["Dme_box"]??true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                        aria-controls="panel1a-content"
                        id="cliam-accordion"
                        className={classes.paperHeader}
                        onClick={(event) => handleOpenCloseStatus(event, "Dme_box")}
                    >
                        <Typography variant="subtitle2" className={classes.sidebarTitle}>
                            {serviceTypes[0]?.serviceTypeName + ' (' + serviceTypes[0]?.serviceTypeAbbreviation + ')'}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <DmeHeader
                            referralId={referralId}
                            serviceTypeRequestDme={dmeService?.serviceTypeRequestDme??serviceTypeRequestDmeNew}
                            productCategories={productCategories}
                            handleServiceTypeRequestDmeEdit={handleServiceTypeRequestDmeEdit}
                            validate={validateDme}
                            handleSetIsValidDmeProductCategoryFunction={setIsValidDmeProductCategoryFunction}
                            hasBeenSubmitted={hasBeenSubmitted}
                            states ={props.states}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>

            <Grid item xs={12}>
                <Accordion className={classes.paper} expanded={props.openCloseStatus["DmeReferringPhysician_box"]??true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                        aria-controls="panel1a-content"
                        id="adjuster-accordion"
                        className={classes.paperHeader}
                        onClick={(event) => handleOpenCloseStatus(event, "DmeReferringPhysician_box")}
                    >
                        <Typography variant="subtitle2" className={classes.sidebarTitle}>
                            Referring Physician
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ReferringPhysician
                            handleLocalServiceEdit={handleReferringPhysicianEdit}
                            referringPhysician={dmeService?.referringPhysician}
                            referralId={referralId}
                            states={states}
                            errors={errors}
                            validate={validateDme}
                            hasBeenSubmitted={hasBeenSubmitted}
                            serviceId={serviceId}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>
            {Dialog}
            {DialogProducts}
        </Fragment>
    );
};
