import React, { FunctionComponent } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ReferralDetail } from '../referral-detail/ReferralDetail.Component';
import { AddReferral } from '../add-referral/AddReferral.Container';
import { ClaimReferrals } from '../claim-referrals/ClaimReferrals.Container';
import { NewClients } from '../../components/tc-admin/new-clients/Clients.Component';
import { NewVendors } from '../../components/tc-admin/new-vendors/Vendors.Component';
import { NewAdminTab } from '../../components/tc-admin/new-admin-tab/AdminTab.Component';
import { ReportsMenu } from '../../components/tc-admin/new-referrals-menu/ReportsMenu.Component';
import AccessControl, {
    checkAnyPermissions,
} from '../../components/generic-components/access-control/AccessControl.Component';
import { ClaimList } from '../../components/claim-components/claim-list/ClaimList.Component';
import { UserRolesEnum } from '../../support/enums/UserRoles.Enum';
import styles from './SideBarMenu.Styles';
import { useSelector } from 'react-redux';
import { UsersState } from '../../store/reducers/usersReducer';
import { adminNavigationCards } from '../../components/tc-admin/admin-navigation/NavigationCards';
import {
    adminRules,
    claimsRules,
    clientsRules,
    referralListRules,
    reportRules,
    vendorPortalRules,
    vendorReferralList,
    vendorsRules,
} from '../../support/constants/SecurityRules.Constants';

//Add here new routes and related pages

type PathParamsType = {
    handleId?: (id: number) => void;
    currentTab: string;
    selectedRouteInfo: {
        section: string;
        subRoutes: { label: string; link: string; allowedPermissions?: string[] }[];
        subRoutesRoles: UserRolesEnum[][];
        showTabs: boolean;
    };
    handleTabChange: (newTabIndex: string) => void;
    initialTabIndex: number;
    profileId?: number;
    clickAdminNavigationItem: boolean;
};

export const SideBarMenu: FunctionComponent<PathParamsType> = (props) => {
    const classes = styles();
    const userSecurityRules = useSelector<UsersState, UsersState['securityRules']>((state) => state.securityRules);

    return (
        <div
            className={
                checkAnyPermissions(userSecurityRules, adminNavigationCards, false)
                    ? classes.tableContainer
                    : classes.tableContainerWithoutSideMenu
            }
        >
            <Switch>
                <Route path="/claims" exact>
                    <AccessControl
                        allowedPermissions={[...claimsRules.read, ...claimsRules.create, ...claimsRules.update]}
                        sendBack={false}
                        renderNoAccess={<Redirect to="/not-found" />}
                    >
                        <ClaimList handleId={props.handleId} />
                    </AccessControl>
                </Route>
                <Route path="/reports">
                    <AccessControl
                        allowedPermissions={reportRules.allFamilyRules}
                        sendBack={false}
                        renderNoAccess={<Redirect to="/not-found" />}
                    >
                        <ReportsMenu />
                    </AccessControl>
                </Route>
                <Route path="/clients">
                    <AccessControl
                        allowedPermissions={clientsRules.allFamilyRules}
                        sendBack={false}
                        renderNoAccess={<Redirect to="/not-found" />}
                    >
                        <NewClients handleId={props.handleId} />
                    </AccessControl>
                </Route>
                <Route path="/vendors">
                    <AccessControl
                        allowedPermissions={vendorsRules.allFamilyRules}
                        sendBack={false}
                        renderNoAccess={<Redirect to="/not-found" />}
                    >
                        <NewVendors handleId={props.handleId} />
                    </AccessControl>
                </Route>
                <Route path="/admin">
                    <AccessControl
                        allowedPermissions={adminRules.allFamilyRules}
                        sendBack={false}
                        renderNoAccess={<Redirect to="/not-found" />}
                    >
                        <NewAdminTab
                            subRoutes={props.selectedRouteInfo?.subRoutes}
                            initialTabIndex={props.initialTabIndex}
                            profileId={props.profileId}
                        />
                    </AccessControl>
                </Route>
                <Route path="/claims/:id" exact>
                    <AccessControl
                        allowedPermissions={[
                            ...claimsRules.read,
                            ...claimsRules.create,
                            ...claimsRules.update,
                            ...referralListRules.read,
                        ]}
                        sendBack={false}
                        renderNoAccess={<Redirect to="/not-found" />}
                    >
                        <ClaimReferrals handleId={props.handleId} />
                    </AccessControl>
                </Route>
                <Route path="/claims/:id/referrals/add">
                    <AccessControl
                        allowedPermissions={[...claimsRules.create, ...referralListRules.create]}
                        sendBack={false}
                        renderNoAccess={<Redirect to="/not-found" />}
                    >
                        <AddReferral />
                    </AccessControl>
                </Route>
                <Route path="/claims/:id/referrals/edit/:referralId">
                    <AccessControl
                        allowedPermissions={[...claimsRules.update, ...referralListRules.update]}
                        sendBack={false}
                        renderNoAccess={<Redirect to="/not-found" />}
                    >
                        <AddReferral />
                    </AccessControl>
                </Route>
                <Route path="/claims/:claimId/referrals/:id">
                    <AccessControl
                        allowedPermissions={[
                            ...claimsRules.read,
                            ...referralListRules.read,
                            ...vendorPortalRules.read,
                            ...vendorReferralList.read,
                        ]}
                        sendBack={false}
                        renderNoAccess={<Redirect to="/not-found" />}
                    >
                        <ReferralDetail userSecurityRules={userSecurityRules} />
                    </AccessControl>
                </Route>
            </Switch>
        </div>
    );
};

export default SideBarMenu;
