import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";

import React, { FunctionComponent } from "react";
import styles from './AddVendor.Styles';
import VendorEntity from "../../../entities/Vendor.Entity";
import StateEntity from "../../../entities/State.Entity";

interface AddVendorProps{
    newVendor: VendorEntity,
    handleVendorEdit: (newVendor: VendorEntity) => void;
    createVendor : () => void;
    states: Array<StateEntity>;
    cancel: () => void;
}

export const AddVendor: FunctionComponent<AddVendorProps> = (props) => {
    const classes = styles();
    const abortController = new AbortController();

    const [vendorState, setVendorState] = React.useState(props.newVendor);

    React.useEffect(() => {
        let newVendor = props.newVendor;
        if(!newVendor){
            newVendor = {} as VendorEntity;
            props.handleVendorEdit(newVendor);
        }

        setVendorState(newVendor);

        return function cleanup() {
            abortController.abort();
        }
    }, [props]);

    const handleTextFieldChange = (event: 
        React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        |
        React.ChangeEvent<{name?: string; value: unknown; id?:string;}>
    ) => {
        event.persist();
        let newVendor : VendorEntity = vendorState;
        newVendor[event.target.id ? event.target.id : event.target.name] = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value;
        setVendorState(newVendor);
        props.handleVendorEdit(newVendor);
    }
    
    const handleCancel = () => {
        let newVendor = {} as VendorEntity;
        props.handleVendorEdit(newVendor);
        props.cancel();
    }

    if(!vendorState) return null;

    return <form noValidate autoComplete="off" className={classes.formContainer} >
        
        <Typography variant="subtitle2" className={classes.paperTitle}>Basic information</Typography>
        <Grid container spacing={3} className={classes.gridContainer} >
            <Grid item xs={3}>
                <TextField 
                    label='Name' 
                    id='vendorName'
                    value={props.newVendor.vendorName}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Email' 
                    id='email'
                    value={props.newVendor.email}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Address 1' 
                    id='address1'
                    value={props.newVendor.address1}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <FormControl className={classes.selectInput}>
                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="stateId"
                        name="stateId"
                        value={props.newVendor.stateId}
                        onChange={(event) => handleTextFieldChange(event)}
                    >
                        {
                            props.states.map((state, index) => {
                                return (
                                    <MenuItem key={state.stateId} value={state.stateId}>{state.stateName}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Number' 
                    id='vendorNumber'
                    type='number'
                    value={props.newVendor.vendorNumber}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Phone 1' 
                    id='phone1'
                    value={props.newVendor.phone1}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Address 2' 
                    id='address2'
                    value={props.newVendor.address2}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    label='Zip' 
                    id='zip'
                    value={props.newVendor.zip}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='FEIN' 
                    id='FEIN'
                    value={props.newVendor.FEIN}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Phone 2' 
                    id='phone2'
                    value={props.newVendor.phone2}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='City' 
                    id='city'
                    value={props.newVendor.city}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <FormControl className={classes.selectInput}>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="isActive"
                        name="isActive"
                        value={props.newVendor.isActive}
                        onChange={(event) => handleTextFieldChange(event)}
                    >
                        <MenuItem value={1}>Active</MenuItem>
                        <MenuItem value={0}>Inactive</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='API' 
                    id='API'
                    value={props.newVendor.API}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Query Data' 
                    id='queryData'
                    value={props.newVendor.queryData}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
        </Grid>
        <div className={classes.buttonsContainer}>
            <Button 
                className={classes.cancelButton}
                onClick={handleCancel}
            >
                Cancel
            </Button>
            <Button className={classes.button} onClick={props.createVendor}>
                Add
            </Button>
        </div>
    </form>
}