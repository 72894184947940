import React, { FunctionComponent, useEffect } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import TextfieldMask from '../../../generic-components/texfield-mask/TextfieldMask.Component';
import styles from './ReferralClaimant.Styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StateEntity from '../../../../entities/State.Entity';
import Moment from 'moment';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';
import { useValidationDialog } from '../../../../support/custom-hooks/useValidationDialog';

interface IReferralClaimantProps {
    referralId: number;
    states: Array<StateEntity>;
    referralClaimant: ReferralContactEntity;    
    handleClaimantEdit: any;    
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean, referralContactType: string) => void;
    handleSetIsValidClaimantFunction: (currentFormIsValidClaimantFunction: () => boolean, referralContactType: string) => void;    
    openCloseStatus: Object;
    handleOpenCloseStatus: any;
    serviceId: number;
    handleUpdateClaimant: (currentPageValidationFunction: () => boolean) => void;    
}

interface IErrors {
    claimantFirstName?: string;
    claimantLastName?: string;
    claimantDateOfBirth?: string;
    claimantGender?: string;
    claimantAddressLine1?: string;
    claimantAddressLine2?: string;
    claimantZipCode?: string;
    claimantCityName?: string;
    claimantState?: string;
    claimantEmail?: string;
    claimantMobilePhoneNumber?: string;
    claimantSecondaryPhoneNumber?: string;
    claimantFax?: string;
    claimantDateOfHire?: string;
}

export const ReferralClaimant: FunctionComponent<IReferralClaimantProps> = (props: IReferralClaimantProps) => {

    const classes = styles();
    const abortController = new AbortController();
    const genders = [
        { value: 'M', label: 'Male' },
        { value: 'F', label: 'Female' },
        { value: 'U', label: 'Unknown' },
    ];

    const [referralClaimantState, setreferralClaimantState] = React.useState(null);    
    const [errors, setErrors] = React.useState<IErrors>(null);    
    const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState<boolean>(false);    
    const { Dialog, checkForErrors } = useValidationDialog('Validation', 'Some required fields are missing.', 'Ok');

    //componentDidMount
    useEffect(() => {
        window.scrollTo(0,0);
        if ( !props.openCloseStatus.hasOwnProperty("Claimant_box" ) )  props.handleOpenCloseStatus("Claimant_box", true);        
    },[]);

    useEffect(() => {
        if (props.referralClaimant?.primaryPhoneNumber?.length == 0) {
            setreferralClaimantState({ ...props.referralClaimant, primaryPhoneNumber: null });
        } else {
            setreferralClaimantState(props.referralClaimant);
        }

        props.handleSetIsValidClaimantFunction(isValidClaimant, "Claimant");

        return function cleanup() {
            abortController.abort();
        };
    }, [props]);

    const handleOpenCloseStatus = ( event, boxName ) => {
        event.persist();
        props.handleOpenCloseStatus( boxName, !(props.openCloseStatus?.[boxName]??true) );
    };
    
    const handleTextFieldChangeClaimant = (event) => {
        event.persist();
        let newClaimant: ReferralContactEntity = referralClaimantState;
        newClaimant[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;
        setreferralClaimantState(newClaimant);
        props.handleClaimantEdit(newClaimant);
        if (hasBeenSubmitted) validate(true);
    };

    const validate = (isChange?: boolean) => {

        
        let errors: IErrors = {};
        errors = getErrorsListClaimant()
        setErrors(errors);
        return checkForErrors(errors, isChange);
    };

        
    const isValidClaimant = () => {
        let errors: IErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsListClaimant();
        isValidResult = (Object.keys(errors).length == 0);
        setErrors(errors);
        return isValidResult;
    }

    const getErrorsListClaimant = () => {
        
        let errors: IErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }

        if (!props.referralClaimant?.firstName) errors.claimantFirstName = 'Required';
        if (!props.referralClaimant?.lastName) errors.claimantLastName = 'Required';
        
        if ([
            ServicesEnum.IME]
            .includes( props.serviceId )){
                if (!props.referralClaimant?.dateOfBirth) errors.claimantDateOfBirth = 'Required';
                if (!props.referralClaimant?.dateOfHire) errors.claimantDateOfHire = 'Required';
                if (!props.referralClaimant?.zipCode) errors.claimantZipCode = 'Required';
            }
            
        if ([
            ServicesEnum.PT,
            ServicesEnum.Diagnostic,
            ServicesEnum.UR,
            ServicesEnum.TT]
            .includes( props.serviceId )){                
                if (!props.referralClaimant?.dateOfBirth) errors.claimantDateOfBirth = 'Required';
                if (!props.referralClaimant?.gender) errors.claimantGender = 'Required';
                if (!props.referralClaimant?.addressLine1) errors.claimantAddressLine1 = 'Required';
                if (!props.referralClaimant?.zipCode) errors.claimantZipCode = 'Required';
                if (!props.referralClaimant?.cityName) errors.claimantCityName = 'Required';
                if (!props.referralClaimant?.stateId) errors.claimantState = 'Required';
            }

            return errors;
    };   
    
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Accordion className={classes.paper} expanded={props.openCloseStatus["Claimant_box"]??true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                            aria-controls="panel1a-content"
                            id="Claimant-accordion"
                            className={classes.paperHeader}
                            onClick={(event) => handleOpenCloseStatus(event, "Claimant_box")}
                        >
                            <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                Claimant
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {referralClaimantState ? (
                                <Grid container spacing={3} className={classes.gridContainer}>
                                    <Grid item xs={3}>
                                        <TextField
                                            required
                                            key="FirstName"
                                            label="First Name"
                                            id="firstName"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimant(event)}
                                            value={referralClaimantState.firstName}
                                            error={!!errors?.claimantFirstName}
                                            helperText={errors?.claimantFirstName}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Date Of Hire"
                                            id="dateOfHire"
                                            type="date"
                                            InputLabelProps={{ shrink: true }}
                                            value={
                                                (referralClaimantState?.dateOfHire as string)?.split('T')[0]
                                            }
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimant(event)}
                                            error={!!errors?.claimantDateOfHire}
                                            helperText={errors?.claimantDateOfHire}
                                            required={props.serviceId === ServicesEnum.IME}
                                        />
                                    </Grid>
                                    {props.serviceId === ServicesEnum.Diagnostic ? (
                                        <Grid
                                            container
                                            xs={3}
                                            spacing={1}
                                            style={{ marginTop: '9px', marginRight: '4px' }}
                                        >
                                            <Grid item xs={3} style={{ marginLeft: '15px', marginRight: '-8px' }}>
                                                <TextField
                                                    label="Zip Code"
                                                    id="zipCode"
                                                    disabled={true}
                                                    onChange={(event) => handleTextFieldChangeClaimant(event)}
                                                    value={referralClaimantState?.zipCode}
                                                    required
                                                    error={!!errors?.claimantZipCode}
                                                    helperText={errors?.claimantZipCode}
                                                    style={{ width: '110px' }}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                        marginTop: '17px',
                                                        marginLeft: '15px',
                                                        marginRight: '-8%',
                                                    }}
                                                >
                                                    _
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3} style={{ marginRight: '15px' }}>
                                                <TextField
                                                    disabled={true}
                                                    label="Zip Code Ext."
                                                    id="zipCodeExtension"
                                                    onChange={(event) => handleTextFieldChangeClaimant(event)}
                                                    value={referralClaimantState.zipCodeExtension}
                                                    style={{ width: '100px' }}
                                                    inputProps={{ maxLength: 4 }}
                                                />
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={3}>
                                            <TextField
                                                label="Zip Code"
                                                id="zipCode"
                                                disabled={true}
                                                onChange={(event) => handleTextFieldChangeClaimant(event)}
                                                value={referralClaimantState?.zipCode}
                                                required={
                                                    props.serviceId === ServicesEnum.PT ||
                                                    props.serviceId === ServicesEnum.Diagnostic ||
                                                    props.serviceId === ServicesEnum.UR ||
                                                    props.serviceId === ServicesEnum.TT ||
                                                    props.serviceId === ServicesEnum.IME ||
                                                    props.serviceId === ServicesEnum.DME
                                                }
                                                error={!!errors?.claimantZipCode}
                                                helperText={errors?.claimantZipCode}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={3}>
                                        {/* EMPTY SPACE */}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            required
                                            label="Last Name"
                                            id="lastName"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimant(event)}
                                            value={referralClaimantState?.lastName}
                                            error={!!errors?.claimantLastName}
                                            helperText={errors?.claimantLastName}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Address Line 1"
                                            id="addressLine1"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimant(event)}
                                            value={referralClaimantState?.addressLine1}
                                            required={
                                                props.serviceId === ServicesEnum.PT ||
                                                props.serviceId === ServicesEnum.Diagnostic ||
                                                props.serviceId === ServicesEnum.UR ||
                                                props.serviceId === ServicesEnum.TT ||
                                                props.serviceId === ServicesEnum.DME
                                            }
                                            error={!!errors?.claimantAddressLine1}
                                            helperText={errors?.claimantAddressLine1}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Claimant Email"
                                            id="email"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimant(event)}
                                            value={referralClaimantState?.email}
                                            error={!!errors?.claimantEmail}
                                            helperText={errors?.claimantEmail}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Claimant MI"
                                            id="middleInitial"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimant(event)}
                                            value={referralClaimantState?.middleInitial}
                                            inputProps={{ maxLength: 1 }}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Address Line 2"
                                            id="addressLine2"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimant(event)}
                                            value={referralClaimantState?.addressLine2}
                                            error={!!errors?.claimantAddressLine2}
                                            helperText={errors?.claimantAddressLine2}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask
                                            mask="(999)-9999999"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimant(event)}
                                            value={referralClaimantState?.primaryPhoneNumber}
                                            label="Mobile Phone"
                                            id="primaryPhoneNumber"
                                            error={!!errors?.claimantMobilePhoneNumber}
                                            helperText={errors?.claimantMobilePhoneNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        {/* Empty space */}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Last Four SSN"
                                            id="lastFourSSN"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimant(event)}
                                            value={referralClaimantState?.lastFourSSN}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="City"
                                            id="cityName"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimant(event)}
                                            value={referralClaimantState?.cityName}
                                            required={
                                                props.serviceId === ServicesEnum.PT ||
                                                props.serviceId === ServicesEnum.Diagnostic ||
                                                props.serviceId === ServicesEnum.UR ||
                                                props.serviceId === ServicesEnum.TT ||
                                                props.serviceId === ServicesEnum.DME
                                            }
                                            error={!!errors?.claimantCityName}
                                            helperText={errors?.claimantCityName}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask
                                            mask="(999)-9999999"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimant(event)}
                                            value={referralClaimantState?.secondaryPhoneNumber}
                                            label="Secondary Phone"
                                            id="secondaryPhoneNumber"
                                            error={!!errors?.claimantSecondaryPhoneNumber}
                                            helperText={errors?.claimantSecondaryPhoneNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Ext."
                                            id="secondaryPhoneExtension"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimant(event)}
                                            value={referralClaimantState?.secondaryPhoneExtension}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Date Of Birth"
                                            id="dateOfBirth"
                                            type={referralClaimantState?.lockedDateOfBirth ? 'text' : 'date'}
                                            InputLabelProps={{ shrink: true }}
                                            value={Moment(referralClaimantState?.dateOfBirth).format(
                                                'MM/DD/YYYY',
                                            )}
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimant(event)}                                            
                                            error={!!errors?.claimantDateOfBirth}
                                            helperText={errors?.claimantDateOfBirth}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl id="stateId" className={classes.selectInput}>
                                            <InputLabel
                                                id="demo-simple-select-label"                                                
                                            >
                                                State
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="stateId"
                                                name="stateId"
                                                key={referralClaimantState?.stateId}
                                                value={referralClaimantState?.stateId?? ''}
                                                disabled={true}
                                                onChange={(event) => handleTextFieldChangeClaimant(event)}                                                
                                                error={!!errors?.claimantState}
                                            >
                                                {props.states.map((state, index) => {
                                                    return (
                                                        <MenuItem
                                                            id="stateId"
                                                            key={state.stateId}
                                                            value={state.stateId}
                                                        >
                                                            {state.stateName}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask
                                            mask="(999)-9999999"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimant(event)}
                                            value={referralClaimantState?.fax}
                                            label="Fax"
                                            id="fax"
                                            error={!!errors?.claimantFax}
                                            helperText={errors?.claimantFax}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        {/* Empty space */}
                                    </Grid>
                                    <Grid item xs={3} container>
                                        <FormControl
                                            className={classes.selectInput}
                                            error={!!errors?.claimantGender}
                                            required={
                                                props.serviceId === ServicesEnum.PT ||
                                                props.serviceId === ServicesEnum.Diagnostic ||
                                                props.serviceId === ServicesEnum.UR ||
                                                props.serviceId === ServicesEnum.DME
                                            }
                                        >
                                            <InputLabel id="gender-select-label">Gender</InputLabel>
                                            <Select
                                                labelId="gender-select-label"
                                                id="gender"
                                                name="gender"
                                                key={referralClaimantState?.gender}
                                                value={referralClaimantState?.gender}
                                                disabled={true}
                                                onChange={(event) => handleTextFieldChangeClaimant(event)}                                                
                                                error={!!errors?.claimantGender}
                                            >
                                                {genders.map((gender, index) => {
                                                    return (
                                                        <MenuItem key={gender.value} value={gender.value}>
                                                            {gender.label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            ) : (
                                <EmptyDataLabel></EmptyDataLabel>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            {Dialog}
        </>
    );
};
