import {useState} from 'react';

const useArrayChanges = <T,>(itemsInitial: T[]) => {
    const [items, setItems] = useState(itemsInitial);
    
    const updateItems = (itemsFinal: T[]) => {  
        const addedItems = itemsFinal.filter(item => !items.includes(item));
        const removedItems = items.filter(item => !itemsFinal.includes(item));
        setItems(itemsFinal);

        return {addedItems, removedItems};
    }

    return { items, updateItems, setItems};
    
}

export default useArrayChanges