import React, { FunctionComponent } from 'react';
import styles, { navigationBarTheme } from "./NavigationBar.Styles";
import { Toolbar, ThemeProvider } from '@material-ui/core';

export const NavigationBar: FunctionComponent = ({ children }) => {
    const classes = styles();

    return (
        <ThemeProvider theme={navigationBarTheme}>
            <div className={classes.appNavBarHeader}>
                <Toolbar>
                    {children}
                </Toolbar>
            </div>
        </ThemeProvider>
    );
}