import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary, Divider, FormControl, FormLabel, List, ListItem, ListItemIcon, Checkbox, ListItemText, ThemeProvider } from '@material-ui/core';
import styles from "./ClientSummary.Styles";
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import StateEntity from '../../../../entities/State.Entity';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';

interface IClientSummaryProps {
    referralClient: ReferralContactEntity;
    states: Array<StateEntity>;
    serviceId?: number;
}

export const ClientSummary: FunctionComponent<IClientSummaryProps> = (props: IClientSummaryProps) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>Client</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.referralClient ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Name</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClient.name}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Address Line 1</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClient.addressLine1}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Primary Phone</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClient.primaryPhoneNumber}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Ext.</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClient.primaryPhoneExtension}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Client Number/Code</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClient.number}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Address Line 2</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClient.addressLine2}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Secondary Phone</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClient.secondaryPhoneNumber}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Ext.</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClient.secondaryPhoneExtension}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Contact First Name</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClient.firstName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>City</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClient.cityName}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" className={classes.dataLabel}>Fax</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClient.fax}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Contact Last Name</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClient.lastName}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Grid item xs={4}>
                                    <Typography variant="body2" className={classes.dataLabel}>State</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.states?.filter(x => !!props.referralClient && !!props.referralClient.stateId && x.stateId == props.referralClient.stateId)[0]?.stateName}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Email</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClient.email}</Typography>
                            </Grid>
                            {
                                props.serviceId === ServicesEnum.Diagnostic ?
                                <Grid container xs={3} spacing={1} style={{ marginTop:'9px', marginRight: '4px'}}>
                                    <Grid item xs={3} style={{ marginLeft: '15px', marginRight: '-15%' }}>
                                        <Typography variant="body2" className={classes.dataLabel}>Zip Code</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClient.zipCode}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Grid item xs={3} style={{ marginTop:'12px',marginLeft: '15px', marginRight: '-8%' }}>
                                            _
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3} style={{marginRight: '15px'}}>
                                        <Typography variant="body2" className={classes.dataLabel}>Zip Code Ext.</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClient.zipCodeExtension}</Typography>
                                    </Grid>
                                </Grid>
                                :
                                <Grid item xs={3}>
                                    <Typography variant="body2" className={classes.dataLabel}>Zip Code</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClient.zipCode}</Typography>
                                </Grid>
                            }
                        </Grid>
                        :
                        <EmptyDataLabel></EmptyDataLabel>
                }
            </AccordionDetails>
        </Accordion>
    );
}