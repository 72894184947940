import { Box, Paper } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Route, Link, useRouteMatch, Redirect } from 'react-router-dom';
import styles from './ReferralsMenu.Styles';
import AdminReferralSentCountByDateDataModel from '../../../data-models/AdminReferralSentCountByDate.DataModel';
import AdminReferralTableDataModel from '../../../data-models/AdminReferralTableDataModel';
import VendorAdminTableDataModel from '../../../data-models/VendorAdminTable.DataModel';
import TenantEntity from '../../../entities/Tenant.Entity';
import UserEntity from '../../../entities/User.Entity';
import { getReferralsTable, getReferralsCountByDateTable } from '../../../services/Referrals.Service';
import { getTenants } from '../../../services/Tenants.Services';
import getProfile, { getUsers } from '../../../services/Users.Service';
import { getVendorAdminTable } from '../../../services/Vendors.Service';
import { BreadCrumb } from '../../../support/custom-hooks/useBreadCrum';
import { ReferralsCountByDate } from '../referrals-count-by-date/ReferralsCountByDate.Component';
import { Referrals, ReferralSearchFilter } from '../referrals/Referrals.Component';
import { Loader } from '../../generic-components/loader/Loader.Component';
import { ReportFilters, Reports } from '../reports/Reports.Component';
import { BillingReportFilters, BillingReports } from '../billing-report/BillingReports.Component';
import { getBillingReport, getReport } from '../../../services/Report.Service';
import ReportDataModel from '../../../data-models/Report.DataModel';
import UserAdminTableDataModel from '../../../data-models/UserAdminTableDataModel';
import BillingReportCountDataModel from '../../../data-models/BillingReportCount.DataModel';
import BillingReportSummaryDataModel from '../../../data-models/BillingReportSummary.DataModel';
import AccessControl from '../../generic-components/access-control/AccessControl.Component';
import NotFound from '../../generic-components/not-found/NotFound.Component';
import {
    billingReportRules,
    generalReportRules,
    referralCountByDateRules,
    referralStatusRules,
    reportRules,
} from '../../../support/constants/SecurityRules.Constants';

interface ReportsMenuState {
    loading: boolean;
    currentRoute: string;
    vendors: Array<VendorAdminTableDataModel>;
    tenants: Array<TenantEntity>;
    userProfile: UserEntity;
    referralsTable: Array<AdminReferralTableDataModel>;
    isReferralsStatusTableLoading: boolean;
    referralsCountByDateTable: Array<AdminReferralSentCountByDateDataModel>;
    isReferralsCountByDateTableLoading: boolean;
    reports: Array<ReportDataModel>;
    users: Array<UserAdminTableDataModel>;
    billingReportResults: Array<BillingReportSummaryDataModel>;
    totalBillingReportCount: BillingReportCountDataModel;
}

interface ReportsProps {}

export const ReportsMenu: FunctionComponent<ReportsProps> = (props) => {
    const [state, setState] = useState<ReportsMenuState>({
        loading: false,
        currentRoute: null,
        vendors: null,
        tenants: null,
        userProfile: null,
        referralsTable: null,
        isReferralsStatusTableLoading: false,
        referralsCountByDateTable: null,
        isReferralsCountByDateTableLoading: false,
        reports: null,
        users: null,
        billingReportResults: null,
        totalBillingReportCount: null,
    });

    const classes = styles();
    const { path } = useRouteMatch();

    const abortController = new AbortController();

    useEffect(() => {
        getUserProfile();
        setState({
            ...state,
            loading: true,
        });
    }, []);

    const getUserProfile = () => {
        setState({
            ...state,
            loading: true,
        });
        Promise.all([
            getTenants(abortController.signal),
            getVendorAdminTable(abortController.signal),
            getProfile(abortController.signal),
            getUsers(abortController.signal),
        ])
            .then((referralsTableResult) => {
                setState({
                    ...state,
                    currentRoute: 'referrals-info',
                    tenants: referralsTableResult[0],
                    vendors: referralsTableResult[1],
                    userProfile: referralsTableResult[2],
                    users: referralsTableResult[3],
                    loading: false,
                });
            })
            .catch(() => {
                setState({ ...state, loading: false });
            });
    };

    const handleGetReferralsTable = (filters: ReferralSearchFilter) => {
        setState({ ...state, isReferralsStatusTableLoading: true });

        getReferralsTable(filters, abortController.signal)
            .then((res) => {
                setState({ ...state, referralsTable: res, isReferralsStatusTableLoading: false });
            })
            .catch(() => {
                setState({ ...state, isReferralsStatusTableLoading: false });
            });
    };

    const handleGetReferralsCountByDateTable = (filters: ReferralSearchFilter) => {
        setState({ ...state, isReferralsCountByDateTableLoading: true });

        getReferralsCountByDateTable(filters, abortController.signal)
            .then((res) => {
                setState({ ...state, referralsCountByDateTable: res, isReferralsCountByDateTableLoading: false });
            })
            .catch(() => {
                setState({ ...state, isReferralsCountByDateTableLoading: false });
            });
    };

    const handleGetReport = (filters: ReportFilters) => {
        getReport(filters, abortController.signal).then((reports) => {
            setState({ ...state, reports: reports });
        });
    };

    const handleGetBillingReport = async (filters: BillingReportFilters) => {
        return await Promise.all([getBillingReport(filters, abortController.signal)]).then((res) => {
            setState({ ...state, billingReportResults: res[0] });
        });
    };

    const referralsPermissions = [...reportRules.read, ...referralStatusRules.read];
    const referralsCountByDatePermissions = [...reportRules.read, ...referralCountByDateRules.read];
    const reportsPermissions = [...reportRules.read, ...generalReportRules.read];
    const billingReportsPermissions = [...reportRules.read, ...billingReportRules.read];

    const reportsBreadCrumb = {
        name: 'Reports',
        goBack: false,
        link: '/reports',
        path: path,
    };

    const referralStatusBreadCrumb = {
        name: 'Referral Status',
        goBack: false,
        link: '/reports/referral-status',
        path: path,
    };

    const referralCountByDateBreadCrumb = {
        name: 'Referral Count By Date',
        goBack: false,
        link: '/reports/referral-count-by-date',
        path: path,
    };

    const generalReportBreadCrumb = {
        name: 'General Report',
        goBack: false,
        link: '/reports/general-report',
        path: path,
    };

    const billingReportBreadCrumb = {
        name: 'Billing Report',
        goBack: false,
        link: '/reports/billing-report',
        path: path,
    };

    return state?.loading ? (
        <Loader isSmall />
    ) : (
        <>
            <Route path="/reports/referral-status" exact>
                <AccessControl
                    allowedPermissions={referralsPermissions}
                    sendBack={false}
                    renderNoAccess={<Redirect to="/not-found" />}
                >
                    <BreadCrumb sections={[reportsBreadCrumb, referralStatusBreadCrumb]} />
                    <Referrals
                        referrals={state.referralsTable}
                        tenants={state.tenants}
                        vendors={state.vendors}
                        handleGetReferralsTable={handleGetReferralsTable}
                        isLoading={state.isReferralsStatusTableLoading}
                    />
                </AccessControl>
            </Route>
            <Route path="/reports/referral-count-by-date" exact>
                <AccessControl
                    allowedPermissions={referralsCountByDatePermissions}
                    sendBack={false}
                    renderNoAccess={<Redirect to="/not-found" />}
                >
                    <BreadCrumb sections={[reportsBreadCrumb, referralCountByDateBreadCrumb]} />
                    <ReferralsCountByDate
                        referrals={state.referralsCountByDateTable}
                        tenants={state.tenants}
                        vendors={state.vendors}
                        handleGetReferralsTable={handleGetReferralsCountByDateTable}
                        isLoading={state.isReferralsCountByDateTableLoading}
                    />
                </AccessControl>
            </Route>
            <Route path="/reports/general-report" exact>
                <AccessControl
                    allowedPermissions={reportsPermissions}
                    sendBack={false}
                    renderNoAccess={<Redirect to="/not-found" />}
                >
                    <BreadCrumb sections={[reportsBreadCrumb, generalReportBreadCrumb]} />
                    <Reports
                        tenants={state.tenants}
                        vendors={state.vendors}
                        adjusters={state.users}
                        reportResults={state.reports}
                        handleGetReport={handleGetReport}
                        isLoading={state.isReferralsCountByDateTableLoading}
                    />
                </AccessControl>
            </Route>
            <Route path="/reports/billing-report" exact>
                <AccessControl
                    allowedPermissions={billingReportsPermissions}
                    sendBack={false}
                    renderNoAccess={<Redirect to="/not-found" />}
                >
                    <BreadCrumb sections={[reportsBreadCrumb, billingReportBreadCrumb]} />
                    <BillingReports
                        tenants={state.tenants}
                        vendors={state.vendors}
                        reportResults={state.billingReportResults}
                        totalReportCount={state.totalBillingReportCount}
                        handleGetReport={handleGetBillingReport}
                        isLoading={state.isReferralsCountByDateTableLoading}
                    />
                </AccessControl>
            </Route>
            <Route path="/reports" exact>
                <BreadCrumb sections={[reportsBreadCrumb]} />
                <Paper className={classes.paper}>
                    <Box className={classes.linksContainer}>
                        <AccessControl allowedPermissions={referralsPermissions} sendBack={false}>
                            <Link to={`${path}/referral-status`} className={classes.link}>
                                Referral Status
                            </Link>
                        </AccessControl>
                        <AccessControl allowedPermissions={referralsCountByDatePermissions} sendBack={false}>
                            <Link to={`${path}/referral-count-by-date`} className={classes.link}>
                                Referral Count by date
                            </Link>
                        </AccessControl>
                        <AccessControl allowedPermissions={reportsPermissions} sendBack={false}>
                            <Link to={`${path}/general-report`} className={classes.link}>
                                General Report
                            </Link>
                        </AccessControl>
                        <AccessControl allowedPermissions={billingReportsPermissions} sendBack={false}>
                            <Link to={`${path}/billing-report`} className={classes.link}>
                                Billing Report
                            </Link>
                        </AccessControl>
                    </Box>
                </Paper>
            </Route>
        </>
    );
};
