import { createTheme } from "@material-ui/core";

export default createTheme({
  overrides: {
    MuiTypography: {
      h6: {
        color: '#428302',
        fontSize: '16px'
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: '#898F91',
        '&$checked': {
          color: '#107A98',
        },
      },
    },
  },
});
