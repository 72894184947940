import React, { Fragment, FunctionComponent } from "react";
import styles from "./CustomMessageBox.Styles";
import { Typography } from "@material-ui/core";
import InfoIcon from '@material-ui/icons/Info';
import { EmptyDataLabel } from '../empty-data-label/EmptyDataLabel.Component';

type CustomMessageBoxProps = {
    label?: string;
}

export const CustomMessageBox: FunctionComponent<CustomMessageBoxProps> = ({label}: CustomMessageBoxProps) => {
    const classes = styles();

    return (
        label!=''?
        <div className={`${classes.root} flex flex-row`}>
            <InfoIcon className={classes.iconBG} />
            <div className={classes.verticalLine}></div>
            <Typography variant='body2' className={classes.label}>{label}</Typography>
        </div>
        :<EmptyDataLabel></EmptyDataLabel>

    );
}
