import React, { FunctionComponent, useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import SpecificBodyPartEntity from '../../../../../entities/SpecificBodyPart.Entity';
import { PtHeaderSummary } from './pt-header-summary/PtHeaderSummary.Component';
import { DxHeaderSummary } from './dx-header-summary/DxHeaderSummary.Component';
import { MsClaimantBackgroundInformation } from './ms-claimant-background-information/MsClaimantBackgroundInformation.Component';
import { MsAccidentInjuryDetails } from './ms-accident-injury-details/MsAccidentInjuryDetails.Component';
import { MsInjuryHistory } from './ms-injury-history/MsInjuryHistory.Component';
import { MsMedicalInformation } from './ms-medical-information/MsMedicalInformation.Component';
import { MsHealthHistoryLifestyleInformation } from './ms-health-history-lifestyle-information/MsHealthHistoryLifestyleInformation.Component';
import { PtEmployer } from './ms-employer/MsEmployer.Component';
import { MsEmployerAccident } from './ms-employer-accident/MsEmployerAccident.Component';
import { MsCompensabilityInformation } from './ms-compensability-information/MsCompensabilityInformation.Component';
import { MsReferringPhysician } from './ms-referring-physician/MsReferringPhysician.Component';
import InjuryTypeEntity from '../../../../../entities/pt/InjuryType.Entity';
import BodySideEntity from '../../../../../entities/BodySide.Entity';
import StateEntity from '../../../../../entities/State.Entity';
import MSCoverLetterEntity from "../../../../../entities/pt/PTCoverLetter.Entity";
import { MSCoverLetterDetails } from "./ms-cover-letter/MsCoverLetter.Component";
import DiagnosticServiceDataModel from '../../../../../data-models/DiagnosticService.DataModel';
import DiagnosticProcedureEntity from '../../../../../entities/DiagnosticProcedure.Entity';
import ServiceTypePTEntity from '../../../../../entities/pt/ServiceTypePT.Entity';
import DiagnosticProcedureDetailDataModel from '../../../../../data-models/DiagnosticProcedureDetail.DataModel';
import DiagnosticProcedureTableDataModel from '../../../../../data-models/DiagnosticProcedureTable.DataModel';
import LanguageEntity from '../../../../../entities/Language.Entity';

type DxReferralRequestProps = {
    injuryTypes: Array<InjuryTypeEntity>;
    bodySides: Array<BodySideEntity>;
    diagnosticService: DiagnosticServiceDataModel;
    states: Array<StateEntity>;
    referralId: number;
    specificBodyParts: Array<SpecificBodyPartEntity>;
    serviceId: number;
    procedures: Array<DiagnosticProcedureEntity>;
    serviceType: ServiceTypePTEntity;
    procedureDetails: Array<DiagnosticProcedureDetailDataModel>;
    languages: Array<LanguageEntity>;
}

export const DxReferralRequest: FunctionComponent<DxReferralRequestProps> = (props: DxReferralRequestProps) => {
    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DxHeaderSummary proceduresTable={props?.diagnosticService?.proceduresTable} serviceTypeRequestDx={props?.diagnosticService?.serviceTypeRequestDx} languages={props.languages}/>
                </Grid>
                <Grid item xs={12}>
                    <MsReferringPhysician medicalService={props.diagnosticService} states={props.states} serviceId={props.serviceId}/>
                </Grid>
            </Grid>
        </form>
    );
}