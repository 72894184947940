import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";

import React, { FunctionComponent } from "react";
import styles from './AddVendorContact.Styles';
import StateEntity from "../../../entities/State.Entity";
import VendorContactEntity from "../../../entities/VendorContact.Entity";

interface AddVendorContactProps{
    newVendorContact: VendorContactEntity,
    handleVendorContactEdit: (newVendorContact: VendorContactEntity) => void;
    createVendorContact : () => void;
    states: Array<StateEntity>;
    cancel: () => void;
    vendorId: number;
}

export const AddVendorContact: FunctionComponent<AddVendorContactProps> = (props) => {
    const classes = styles();
    const abortController = new AbortController();

    const [vendorState, setVendorState] = React.useState(props.newVendorContact);

    React.useEffect(() => {
        let newVendorContact = props.newVendorContact;
        if(!newVendorContact){
            newVendorContact = { vendorId:props.vendorId } as VendorContactEntity;
            props.handleVendorContactEdit(newVendorContact);
        }

        setVendorState(newVendorContact);

        return function cleanup() {
            abortController.abort();
        }
    }, [props]);

    const handleTextFieldChange = (event: 
        React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        |
        React.ChangeEvent<{name?: string; value: unknown; id?:string;}>
    ) => {
        event.persist();
        let newVendorContact : VendorContactEntity = vendorState;
        newVendorContact[event.target.id ? event.target.id : event.target.name] = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value;
        setVendorState(newVendorContact);
        props.handleVendorContactEdit(newVendorContact);
    }
    
    const handleCancel = () => {
        let newVendorContact = {} as VendorContactEntity;
        props.handleVendorContactEdit(newVendorContact);
        props.cancel();
    }

    if(!vendorState) return null;

    return <form noValidate autoComplete="off" className={classes.formContainer} >
        
        <Typography variant="subtitle2" className={classes.paperTitle}>Basic information</Typography>
        <Grid container spacing={3} className={classes.gridContainer} >
            <Grid item xs={3}>
                <TextField 
                    label='First Name' 
                    id='contactFirstName'
                    value={props.newVendorContact.contactFirstName}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Contact Type' 
                    id='jobTitle'
                    value={props.newVendorContact.jobTitle}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Address 1' 
                    id='addressLine1'
                    value={props.newVendorContact.addressLine1}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <FormControl className={classes.selectInput}>
                    <InputLabel id="demo-simple-select-label">State</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="stateId"
                        name="stateId"
                        value={props.newVendorContact.stateId}
                        onChange={(event) => handleTextFieldChange(event)}
                    >
                        {
                            props.states.map((state, index) => {
                                return (
                                    <MenuItem key={state.stateId} value={state.stateId}>{state.stateName}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Last Name' 
                    id='contactLastName'
                    value={props.newVendorContact.contactLastName}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Work Phone' 
                    id='primaryPhoneNumber'
                    value={props.newVendorContact.primaryPhoneNumber}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Address 2' 
                    id='addressLine2'
                    value={props.newVendorContact.addressLine2}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Zip' 
                    id='zipCode'
                    value={props.newVendorContact.zipCode}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Email' 
                    id='contactEmail'
                    value={props.newVendorContact.contactEmail}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Cell Phone' 
                    id='secondaryPhoneNumber'
                    value={props.newVendorContact.secondaryPhoneNumber}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='City' 
                    id='cityName'
                    value={props.newVendorContact.cityName}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Location' 
                    id='location'
                    value={props.newVendorContact.location}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
        </Grid>
        <div className={classes.buttonsContainer}>
            <Button 
                className={classes.cancelButton}
                onClick={handleCancel}
            >
                Cancel
            </Button>
            <Button className={classes.button} onClick={props.createVendorContact}>
                Add
            </Button>
        </div>
    </form>
}