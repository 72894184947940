//TODO: That's our entity actions

import { Section } from '../../support/custom-hooks/useBreadCrum';

export type Action = {
    type:
        | 'MODIFY_USER_ROLES'
        | 'MODIFY_CLIENT_NAME'
        | 'MODIFY_USER_SECURITY_RULES'
        | 'MODIFY_USER_NAME'
        | 'MODIFY_BREADCRUMB';
    payload: any;
};

//Quick Action preprogrammed

export const modifyUserRoles = (userRoles: Array<number>): Action => ({
    type: 'MODIFY_USER_ROLES',
    payload: userRoles,
});

export const modifyClientName = (clientName: string): Action => ({
    type: 'MODIFY_CLIENT_NAME',
    payload: clientName,
});

export const modifyUserSecurityRules = (securityRules: string[]): Action => ({
    type: 'MODIFY_USER_SECURITY_RULES',
    payload: securityRules,
});

export const modifyUserName = (userName: string): Action => ({
    type: 'MODIFY_USER_NAME',
    payload: userName,
});

export const modifyBreadCrumb = (breadCrumb: Section[]): Action => ({
    type: 'MODIFY_BREADCRUMB',
    payload: breadCrumb,
});

// //TODO: Example of how to use store
// //TODO: Thats how we use a stored specific data field
// const userRoles = useSelector<UsersState, UsersState['roles']>((state) => state.roles);

// //TODO: That's how we dispatch an action to our store, to modify, add or delete data
// const dispatch = useDispatch();

// const changeUserRoles = (roles: Array<number>) => {
//     //Fetching an action
//     dispatch(modifyUserRoles(roles));
// };
