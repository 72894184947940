import React, { Fragment } from 'react';
import { render } from 'react-dom';
import './index.css';
import './App.Styles.ts';
import 'fontsource-roboto';
import { BrowserRouter as ReactRouter } from 'react-router-dom';
import './App.Styles.ts';
import { Provider } from 'react-redux';
import theme from './App.Styles';
import { store } from './store/store';
import { Layout } from './components/generic-components/layout/Layout.Component';
import { ThemeProvider } from '@material-ui/core';
import Router from './support/config/Router';
import { ToastContainer } from 'react-toastify';
import { AuthenticatedTemplate, useMsalAuthentication, useMsal, UnauthenticatedTemplate } from '@azure/msal-react';
import 'react-toastify/dist/ReactToastify.css';
import { InteractionType, RedirectRequest } from '@azure/msal-browser';
import { AuthenticationVerifier } from './components/generic-components/authentication-verifier/AuthenticationVerifier.Component';
import { resetPasswordUri, loginUri, scopes } from './b2cConfiguration';


const App = () => {
    
    const loginRequest: RedirectRequest = { scopes: scopes };
    const { result } = useMsalAuthentication(InteractionType.Redirect, loginRequest);
    const { instance, accounts } = useMsal();
    
    React.useEffect(() => {
        const callbackId = instance.addEventCallback((message) => {
            const { error } = message;
            if (error !== null && error.message.includes("AADB2C90118")) {
                instance.loginRedirect({
                    authority: resetPasswordUri,
                    scopes: scopes
                });
                return;
            }
            if (error !== null && error.message.includes("AADB2C90091")) {
                instance.loginRedirect({
                    authority: loginUri,
                    scopes: scopes
                });
                return;
            }            
        })
        return () => callbackId && instance.removeEventCallback(callbackId);
    }, [instance]);
    
    return (
        <Fragment>
            <AuthenticatedTemplate>
                {accounts ? (
                    <AuthenticationVerifier accounts={accounts} instance={instance}>
                        <ReactRouter>
                            <ThemeProvider theme={theme}>
                                <Layout>
                                    <Router></Router>
                                    <div id="router-footer" />
                                    <ToastContainer autoClose={3000} hideProgressBar></ToastContainer>
                                </Layout>
                            </ThemeProvider>
                        </ReactRouter>
                    </AuthenticationVerifier>
                ) : (
                    ''
                )}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate></UnauthenticatedTemplate>
        </Fragment>
    );
};

render(<App />, document.getElementById('root'));

export default App;
