import { BillingReportFilters } from "../components/tc-admin/billing-report/BillingReports.Component";
import { ReportFilters } from "../components/tc-admin/reports/Reports.Component";
import BillingReportDataModel from "../data-models/BillingReport.DataModel";
import BillingReportSummaryDataModel from "../data-models/BillingReportSummary.DataModel";
import ReportDataModel from "../data-models/Report.DataModel";
import { apiGet } from '../support/helpers/ApiConnection.Helper';

export function getReport(reportFilters: ReportFilters, abortSignal: AbortSignal): Promise<Array<ReportDataModel>> {   
    let querystring = '';
    if(reportFilters.tenantId){
        querystring += `TenantId=${reportFilters.tenantId}`;
    }
    if(reportFilters.adjusterId){
        if(querystring.length > 0){
            querystring += '&&';
        }
        querystring += `AdjusterId=${reportFilters.adjusterId}`;
    }
    if(reportFilters.vendorId){
        if(querystring.length > 0){
            querystring += '&&';
        }
        querystring += `VendorId=${reportFilters.vendorId}`;
    }
    if(reportFilters.dateRangeFrom){
        if(querystring.length > 0){
            querystring += '&&';
        }
        querystring += `DateRangeFrom=${reportFilters.dateRangeFrom}`;
    }
    if(reportFilters.dateRangeTo){
        if(querystring.length > 0){
            querystring += '&&';
        }
        querystring += `DateRangeTo=${reportFilters.dateRangeTo}`;
    }

    return apiGet<Array<ReportDataModel>>('/reports/filtered-reports',abortSignal, querystring);
}

export function getBillingReport(reportFilters: BillingReportFilters, abortSignal: AbortSignal): Promise<BillingReportSummaryDataModel[]> {   
    let querystring = '';
    if(reportFilters.vendorIds.length > 0){
        if(querystring.length > 0){
            querystring += '&&';
        }
        querystring += `VendorIds=${reportFilters.vendorIds.join(',')}`;
    }
    
    querystring = addTenantAndDateFiltersToQuery(querystring, reportFilters.tenantIds, reportFilters.dateRangeFrom, reportFilters.dateRangeTo);

    return apiGet<BillingReportSummaryDataModel[]>('/reports/billing/filtered-reports',abortSignal, querystring);
}

export function getBillingReportByVendorId(vendorId: number, reportFilters: BillingReportFilters, abortSignal: AbortSignal): Promise<BillingReportDataModel[]> {
    let querystring = '';
    querystring = addTenantAndDateFiltersToQuery(querystring, reportFilters.tenantIds, reportFilters.dateRangeFrom, reportFilters.dateRangeTo);

    return apiGet<BillingReportDataModel[]>('/reports/billing/vendor/' + vendorId,abortSignal, querystring);
}

const addTenantAndDateFiltersToQuery = (queryString: string, tenantIds: number[], dateRangeFrom: Date, dateRangeTo: Date) => {
    if(tenantIds.length > 0){
        if(queryString.length > 0){
            queryString += '&&';
        }
        queryString += `TenantIds=${tenantIds.join(',')}`;
    }

    if(dateRangeFrom){
        if(queryString.length > 0){
            queryString += '&&';
        }
        queryString += `DateRangeFrom=${dateRangeFrom}`;
    }

    if(dateRangeTo){
        if(queryString.length > 0){
            queryString += '&&';
        }
        queryString += `DateRangeTo=${dateRangeTo}`;
    }

    return queryString;
}
