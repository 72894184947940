import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";

import React, { FunctionComponent } from "react";
import styles from './AddVendorAuthCredential.Styles';
import StateEntity from "../../../entities/State.Entity";
import VendorAuthCredentialEntity from "../../../entities/VendorAuthCredential.Entity";
import AuthenticationTypeEntity from "../../../entities/AuthenticationType.Entity";

interface AddVendorAuthCredentialProps{
    newVendorAuthCredential: VendorAuthCredentialEntity,
    handleVendorAuthCredentialEdit: (newVendorAuthCredential: VendorAuthCredentialEntity) => void;
    createVendorAuthCredential : () => void;
    states: Array<StateEntity>;
    authenticationTypes: Array<AuthenticationTypeEntity>;
    cancel: () => void;
    vendorId: number;
}

export const AddVendorAuthCredential: FunctionComponent<AddVendorAuthCredentialProps> = (props) => {
    const classes = styles();
    const abortController = new AbortController();

    const communicationTypes = [{communicationTypeKey:'DataTransfer', communicationTypeName:'Data Transfer'}, {communicationTypeKey:'FileTransfer', communicationTypeName:'File Transfer'}];

    const [vendorState, setVendorState] = React.useState(props.newVendorAuthCredential);

    React.useEffect(() => {
        let newVendorAuthCredential = props.newVendorAuthCredential;
        if(!newVendorAuthCredential){
            newVendorAuthCredential = { vendorId:props.vendorId } as VendorAuthCredentialEntity;
            props.handleVendorAuthCredentialEdit(newVendorAuthCredential);
        }

        setVendorState(newVendorAuthCredential);

        return function cleanup() {
            abortController.abort();
        }
    }, [props]);

    const handleTextFieldChange = (event: 
        React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        |
        React.ChangeEvent<{name?: string; value: unknown; id?:string;}>
    ) => {
        event.persist();
        let newVendorAuthCredential : VendorAuthCredentialEntity = vendorState;
        newVendorAuthCredential[event.target.id ? event.target.id : event.target.name] = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value;
        setVendorState(newVendorAuthCredential);
        props.handleVendorAuthCredentialEdit(newVendorAuthCredential);
    }
    
    const handleCancel = () => {
        let newVendorAuthCredential = {} as VendorAuthCredentialEntity;
        props.handleVendorAuthCredentialEdit(newVendorAuthCredential);
        props.cancel();
    }

    if(!vendorState) return null;

    return <form noValidate autoComplete="off" className={classes.formContainer} >
        
        <Typography variant="subtitle2" className={classes.paperTitle}>Auth Credential Information</Typography>
        <Grid container spacing={3} className={classes.gridContainer} >
            <Grid item xs={3}>
                <FormControl className={classes.selectInput}>
                    <InputLabel id="demo-simple-select-label">Auth Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="authenticationTypeId"
                        name="authenticationTypeId"
                        value={props.newVendorAuthCredential.authenticationTypeId}
                        onChange={(event) => handleTextFieldChange(event)}
                    >
                        {
                            props.authenticationTypes.map((authenticationType, index) => {
                                return (
                                    <MenuItem key={authenticationType.authenticationTypeId} value={authenticationType.authenticationTypeId}>{authenticationType.authenticationTypeName}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Auth Key Type' 
                    id='authKeyType'
                    value={props.newVendorAuthCredential.authKeyType}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Auth Key' 
                    id='authKey'
                    value={props.newVendorAuthCredential.authKey}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Auth Value Secret ID' 
                    id='authValueSecretID'
                    value={props.newVendorAuthCredential.authValueSecretID}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Token EndPoint' 
                    id='tokenEndpoint'
                    value={props.newVendorAuthCredential.tokenEndpoint}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Scope' 
                    id='scope'
                    value={props.newVendorAuthCredential.scope}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                {/* <TextField 
                    label='CommunicationType' 
                    id='communicationType'
                    value={props.newVendorAuthCredential.communicationType}
                    onChange={(event) => handleTextFieldChange(event)}
                /> */}
                
                <FormControl className={classes.selectInput}>
                    <InputLabel id="demo-simple-select-label-communication-type">Communication Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label-communication-type"
                        id="communicationType"
                        name="communicationType"
                        value={props.newVendorAuthCredential.communicationType}
                        onChange={(event) => handleTextFieldChange(event)}
                    >
                        {
                            communicationTypes.map((communicationType, index) => {
                                return (
                                    <MenuItem key={communicationType.communicationTypeKey} value={communicationType.communicationTypeKey}>{communicationType.communicationTypeName}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
        <div className={classes.buttonsContainer}>
            <Button 
                className={classes.cancelButton}
                onClick={handleCancel}
            >
                Cancel
            </Button>
            <Button className={classes.button} onClick={props.createVendorAuthCredential}>
                Add
            </Button>
        </div>
    </form>
}