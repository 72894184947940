import { Box, FormControl, IconButton, TextField, Typography, InputLabel, Select, MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react';
import SecurityProfileDataModel from '../../../data-models/admin/access-control/SecurityProfile.DataModel';
import SecurityProfileList from '../../../data-models/admin/access-control/SecurityProfileList.DataModel';
import { getAllSecurityProfiles, getSecurityProfileById } from '../../../services/SecurityProfiles.Service';
import { Loader } from '../../generic-components/loader/Loader.Component';
import styles from './AccessControlSelectSecurityProfile.Styles';
import CloseIcon from '@material-ui/icons/Close';

interface AccessControlSelectSecurityProfileProps {
    assignedSecurityProfiles: SecurityProfileDataModel[];
    localAssignedSecurityProfiles: {
        securityProfileId: number;
        securityProfileName: string;
    }[];
    setLocalAssignedSecurityProfiles: React.Dispatch<
        React.SetStateAction<
            {
                securityProfileId: number;
                securityProfileName: string;
            }[]
        >
    >;
}

export const AccessControlSelectSecurityProfile: React.FunctionComponent<AccessControlSelectSecurityProfileProps> = ({
    assignedSecurityProfiles,
    localAssignedSecurityProfiles,
    setLocalAssignedSecurityProfiles,
}) => {
    const classes = styles();
    const abortController = new AbortController();

    const [securityProfileList, setSecurityProfileList] = React.useState<SecurityProfileList[]>([]);

    useEffect(() => {
        const newLocalAssignedSecurityProfiles = [...localAssignedSecurityProfiles];
        assignedSecurityProfiles.forEach((securityProfile) => {
            if (
                newLocalAssignedSecurityProfiles.findIndex(
                    (sp) => sp.securityProfileId === securityProfile.securityProfileId,
                ) === -1
            ) {
                newLocalAssignedSecurityProfiles.push({
                    securityProfileId: securityProfile.securityProfileId,
                    securityProfileName: securityProfile.name,
                });
            }
        });

        setLocalAssignedSecurityProfiles(newLocalAssignedSecurityProfiles);
    }, [assignedSecurityProfiles]);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    useEffect(() => {
        handleInitialLoad();
    }, []);

    const handleInitialLoad = async () => {
        setIsLoading(true);
        await handleGetSecurityProfiles();
        setIsLoading(false);
    };

    const handleGetSecurityProfiles = async () => {
        const securityProfileList = await getAllSecurityProfiles(abortController.signal);
        setSecurityProfileList(securityProfileList);
    };

    const handleTextFieldChange = (event: any) => {
        const { value } = event.target;

        const alreadyExists = localAssignedSecurityProfiles.find((sp) => sp.securityProfileId === value);

        if (alreadyExists) return;

        const securityProfile = securityProfileList.find((sp) => sp.securityProfileId === value);

        setLocalAssignedSecurityProfiles([
            ...localAssignedSecurityProfiles,
            { securityProfileName: securityProfile.name, securityProfileId: securityProfile.securityProfileId },
        ]);
    };

    const handleUnassignSecurityProfile = (securityProfileId: number) => {
        const newLocalAssignedSecurityProfiles = [...localAssignedSecurityProfiles];

        setLocalAssignedSecurityProfiles([
            ...newLocalAssignedSecurityProfiles.filter((p) => p.securityProfileId != securityProfileId),
        ]);
    };

    if (isLoading) {
        return <Loader isSmall />;
    }

    return (
        <Box>
            <FormControl className={classes.selectInput}>
                <InputLabel id="demo-simple-select-label">Security Profile</InputLabel>
                {
                    <Select
                        labelId="demo-simple-select-label"
                        id="securityProfileId"
                        name="securityProfileId"
                        value=""
                        onChange={(event) => handleTextFieldChange(event)}
                    >
                        {securityProfileList
                            .sort((a, b) => (a?.name < b?.name ? -1 : 1))
                            .map((securityProfile, index) => {
                                return (
                                    <MenuItem
                                        key={securityProfile.securityProfileId}
                                        value={securityProfile.securityProfileId}
                                    >
                                        {securityProfile.name}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                }
            </FormControl>
            <Box className={classes.selectedSecurityProfilesContainer}>
                {localAssignedSecurityProfiles.map((securityProfile, index) => (
                    <Box key={securityProfile.securityProfileId} className={classes.selectedSecurityProfileContainer}>
                        <Typography className={classes.selectedSecurityProfileTitle}>
                            {securityProfile.securityProfileName}
                        </Typography>
                        <IconButton
                            className={classes.selectedSecurityProfileIcon}
                            onClick={() => handleUnassignSecurityProfile(securityProfile.securityProfileId)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};
