import React, { FunctionComponent, useState } from 'react';
import { Paper, Typography } from '@material-ui/core';
import styles from './VendorsReferralList.Styles';
import MaterialTable from '../../../generic-components/material-table/MaterialTable.Component';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { MaterialTableProps } from 'material-table';
import ReferralStatusEntity from '../../../../entities/ReferralStatus.Entity';
import ReferralEntity from '../../../../entities/Referral.Entity';
import { ReferralStatusTypesEnum } from '../../../../support/enums/ReferralStatusTypesEnum';
import { useDateFilter } from '../../../../support/custom-hooks/useDateFilter';

interface IVendorsReferralListProps {
    referrals: Array<ReferralEntity>;
    referralStatus: Array<ReferralStatusEntity>;
}

export const VendorsReferralList: FunctionComponent<IVendorsReferralListProps> = ({
    referralStatus,
    referrals,
}: IVendorsReferralListProps) => {
    const [filter, setFilter] = useState(false);

    const classes = styles();

    const referralStatusOptions = {};
    referralStatus.forEach((referralStatus) => {
        const { referralStatusId, referralStatusLabel } = referralStatus;
        referralStatusOptions[referralStatusId] = referralStatusLabel;
    });

    const [localReferrals, setLocalReferrals] = useState<Array<ReferralEntity>>();
    const { InputFilters } = useDateFilter(referrals, localReferrals, setLocalReferrals);

    let buttonBackLink: string = '/referrals';

    const tableProps: MaterialTableProps<ReferralEntity> = {
        columns: [
            {
                title: 'Referral Number',
                render: (rowData) => {
                    return (
                        <Link to={`/claims/${rowData.claimId}/referrals/${rowData.referralId}`}>
                            {rowData.referralId}
                        </Link>
                    );
                },
                customSort: (a, b) => a.referralId - b.referralId,
                field: 'referralId',
            },
            {
                title: 'Claim Number',
                field: 'claimNumber',
            },
            {
                title: 'Claimant',
                render: (rowData) => {
                    return rowData.claimantFirstName && rowData.claimantLastName
                        ? rowData.claimantFirstName + ' ' + rowData.claimantLastName
                        : '';
                },
                customFilterAndSearch: (term: string, rowData) => {
                    if (
                        rowData.claimantFirstName?.toLocaleLowerCase().includes(term.toLocaleLowerCase()) ||
                        rowData.claimantLastName?.includes(term.toLocaleLowerCase()) ||
                        `${rowData.claimantFirstName?.toLocaleLowerCase()} ${rowData.claimantLastName?.toLocaleLowerCase()}`.includes(
                            term.toLocaleLowerCase(),
                        )
                    ) {
                        return true;
                    }

                    return false;
                },
            },
            {
                title: 'Client',
                field: 'referralClientName',
            },
            { title: 'Tenant', field: 'tenantName' },
            {
                title: 'Date Submitted',
                field: 'dateSubmitted',
                render: (rowData) => (rowData.dateSubmitted ? moment(rowData.dateSubmitted).format('M/D/YYYY') : ''),
                customSort: (a, b) => new Date(a.dateSubmitted)?.getTime() - new Date(b.dateSubmitted)?.getTime(),
                filterComponent: () => InputFilters,
            },
            {
                title: 'Referral Status',
                field: 'referralStatusId',
                lookup: referralStatusOptions,
            },
            { title: 'Service', field: 'serviceName' },
            {
                title: 'Service Type',
                field: 'joinedServiceTypeNames',
            },
        ],
        data: localReferrals ?? [],
        actions: [
            {
                icon: 'tune',
                tooltip: 'Filter',
                isFreeAction: true,
                onClick: () => setFilter(!filter),
            },
        ],
        options: {
            filtering: filter,
            showTitle: true,
            paging: referrals ? referrals.length >= 5 : false,
            toolbar: true,
            exportButton: true,
            exportAllData: true,
            rowStyle: (rowData) => ({
                backgroundColor:
                    rowData.referralStatusId == ReferralStatusTypesEnum.SubmissionFailed ||
                    rowData.referralStatusId == ReferralStatusTypesEnum.ValidationFailed
                        ? '#ff000042'
                        : 'inherit',
            }),
        },
        title: 'Referrals',
    };

    return (
        <Paper className={classes.clientListPaper}>
            <MaterialTable tableProps={tableProps} />
        </Paper>
    );
};
