import React, { FunctionComponent, useState } from 'react';
import styles from "./ResponseDocumentList.Styles";
import MaterialTable from "../../../generic-components/material-table/MaterialTable.Component";
import { MaterialTableProps } from 'material-table';
import ReferralDocumentEntity from '../../../../entities/ReferralDocument.Entity';
import Moment from 'moment';
import { Paper } from '@material-ui/core';

interface IResponseDocumentListProps {
    referralDocuments: Array<ReferralDocumentEntity>
}

export const ResponseDocumentList: FunctionComponent<IResponseDocumentListProps> = (props: IResponseDocumentListProps) => {
    const classes = styles();
    const [filter, setFilter] = useState(false);
    const filesTableProps: MaterialTableProps<ReferralDocumentEntity> = {
        columns: [
            {
                title: "Description",
                field: "fileDescription"
            },
            {
                title: "File Name",
                field: "fileName"
            },            
            {
                title: "Document Type",
                field: "documentType"
            },            
            {
                title: "Due Date",
                render: rowData => (rowData.dueDate ? Moment(rowData.dueDate).format("M/D/YYYY") : ""),
                customSort: (a,b) => new Date(a.dueDate)?.getTime() - new Date(b.dueDate)?.getTime() 
            },
            {
                title: "Uploaded by",
                field: "uploadedBy"
            }
        ],
        data: props.referralDocuments ? props.referralDocuments : [],
        options: {
            filtering: filter,
            showTitle: true,
            paging: props.referralDocuments ? props.referralDocuments.length >= 5 : false,
            toolbar: true,
        },
        title: "Documents"
    };

    return (
        <Paper className={classes.paper}>
            <MaterialTable tableProps={filesTableProps} />                     
        </Paper>
    );
}