import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    paper: {
        marginTop: '20px',
        //padding:'10px',
        //paddingTop: '15px',
        //paddingLeft: '35px',
        //paddingRight: '35px',
        //paddingBottom: '25px',
    },
    button: {
        color: 'white',
        backgroundColor: '#107A98',
        marginLeft: '40px',
        '&:hover': {
            backgroundColor: '#004763!important',
        },
    },
    container: {
        marginTop: '20px',
    },
    selectedComponentContainerWithTabs: {
        flex: 1,
        height: '100%',
        marginTop: '64px',
    },
    breadCrumb: {
        // marginTop: '10px',
        paddingTop: '10px',
    },
}));
