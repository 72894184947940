import React, { FunctionComponent } from 'react';
import { StandardTextFieldProps, TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

interface ITexfieldMaskProps extends StandardTextFieldProps{
    mask: string;
    disabled?: boolean;
    onChange?: any;
    value?: string;
    label?: string;
    id?: string;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    key?: string;
}

const TextfieldMask: FunctionComponent<ITexfieldMaskProps> = (props: ITexfieldMaskProps) => {
    return (
        !props.disabled ?
                <InputMask
                    mask={props.mask}
                    maskChar= {" "}
                    disabled={props.disabled ? props.disabled  : false}
                    onChange={(event) => props.onChange(event)}  
                    value={props.value ? props.value : ""}  
                >
                    {
                        inProps => 
                            <TextField 
                                {...inProps}
                                disabled={props.disabled ? props.disabled  : false}
                                required={props.required ? props.required : false}
                                label={props.label ? props.label : ""} 
                                id={props.id ? props.id : ""}
                                key={props.key ? props.key : ""}
                                error={!!props.error ? !!props.error : false} 
                                helperText={props.helperText ? props.helperText : false} 
                                inputProps={{
                                    autoComplete: 'none',
                                }}
                            /> 
                    }
                </InputMask> :
                <TextField 
                    disabled={props.disabled ? props.disabled  : false}
                    label={props.label ? props.label : ""} 
                    id={props.id ? props.id : ""} 
                    key={props.key ? props.key : ""}
                    onChange={(event) => props.onChange(event)}  
                    value={props.value ? props.value : ""}
                    required={props.required ? props.required : false} 
                    error={!!props.error ? !!props.error : false} 
                    helperText={props.helperText ? props.helperText : false}
                />
    )
};

export default TextfieldMask;
