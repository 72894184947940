import React, { Fragment, FunctionComponent } from 'react';
import styles from './ReferralMsResponse.Styles';
import { Grid, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReferralOverviewDataModel } from '../../../../../data-models/ReferralOverview.DataModel';
import { UsersState } from '../../../../../store/reducers/usersReducer';
import { UserRolesEnum } from '../../../../../support/enums/UserRoles.Enum';
import { MsResponseSummary } from '../../ms-response-summary/MsResponseSummary.Component';
import { PtVisitCalendar } from '../../pt-visit-calendar/PtVisitCalendar.Component';
import { PhysicianResponse } from '../../physician-response/PhysicianResponse.Component';
import ReferralContactEntity from '../../../../../entities/ReferralContact.Entity';
import StateEntity from '../../../../../entities/State.Entity';
import { ResponseDocuments } from '../../response-documents/ResponseDocuments.Component';
import ReferralDocumentEntity from '../../../../../entities/ReferralDocument.Entity';
import MedicalServiceDataModel from '../../../../../data-models/MedicalService.DataModel';
import { ServicesEnum } from '../../../../../support/enums/Services.Enum';
import { DxResponseSummary } from '../../dx-response-summary/DxResponseSummary.Component';
import ReferralScheduleStatusTypeEntity from '../../../../../entities/ReferralScheduleStatusType.Entity';
import DiagnosticProcedureResponseDataModel from '../../../../../data-models/DiagnosticProcedureResponse.DataModel';
import ServiceDataModel from '../../../../../data-models/Service.DataModel';
import { UrResponseSummary } from '../../ur-response-summary/UrResponseSummary.Component';
import DiagnosticProcedureTableDataModel from '../../../../../data-models/DiagnosticProcedureTable.DataModel';
import { TtResponseSummary } from '../../tt-response-summary/TtResponseSummary.Component';
import TransportationTranslationResponse from '../../../../../data-models/TransportationTranslationResponse.DataModel';
import ServiceTypeEntity from '../../../../../entities/ServiceType.Entity';
import UtilizationReviewResponseDataModel from '../../../../../data-models/UtilizationReviewResponse.DataModel';
import { DmeResponseSummary } from '../../dme-response-summary/DmeResponseSummary.Component';
import DmeResponseDataModel from '../../../../../data-models/dme/DmeResponse.DataModel';
import ReferralVisitStatusEntity from '../../../../../entities/pt/ReferralVisitStatus.Entity';

type PathParamsType = {
    id: string;
    claimId: string;
};

type ReferralMsResponseProps = {
    referralOverviewDataModel: ReferralOverviewDataModel;
    physicalTherapyDataModel: MedicalServiceDataModel;
    physician: ReferralContactEntity;
    states: Array<StateEntity>;
    responseDocuments: Array<ReferralDocumentEntity>;
    serviceId: number;
    referralScheduleStatusTypes: Array<ReferralScheduleStatusTypeEntity>;
    diagnosticProcedureResponse: Array<DiagnosticProcedureResponseDataModel>;
    services: Array<ServiceDataModel>;
    serviceTypeResponseUr: UtilizationReviewResponseDataModel;
    proceduresTable: Array<DiagnosticProcedureTableDataModel>;
    transportationTranslationResponses: Array<TransportationTranslationResponse>;
    serviceType: ServiceTypeEntity;
    dmeResponse: DmeResponseDataModel;
    ReferralVisitStatuses : Array<ReferralVisitStatusEntity>;
} & WithStyles<typeof styles> &
    RouteComponentProps<PathParamsType>;

const ReferralMsResponseComponent: FunctionComponent<ReferralMsResponseProps> = (props: ReferralMsResponseProps) => {
    const userRoles = useSelector<UsersState, UsersState['roles']>((state) => state.roles);
    let buttonBackLink: string;

    try {
        if (userRoles?.findIndex((x) => x == UserRolesEnum.Vendor || x == UserRolesEnum.VendorApi) != -1) {
            buttonBackLink = '/referrals';
        } else {
            buttonBackLink = `/claims/${props.match.params.claimId}`;
        }
    } catch {}

    const { classes } = props;

    return (
        <Fragment>
            {
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {props.serviceId === ServicesEnum.PT && (
                                <MsResponseSummary
                                    referralStatus={props.referralOverviewDataModel?.referralStatus}
                                    medicalServiceDataModel={props.physicalTherapyDataModel}
                                />
                            )}
                            {props.serviceId === ServicesEnum.Diagnostic &&
                                props.diagnosticProcedureResponse &&
                                props.diagnosticProcedureResponse.map((diagnosticProcedureResponse, index) => {
                                    const dxService = props.services.find(
                                        (service) => service.service.serviceId === ServicesEnum.Diagnostic,
                                    );
                                    const serviceType = dxService.serviceTypes.find(
                                        (st) => st.serviceTypeId === diagnosticProcedureResponse.serviceTypeId,
                                    );
                                    let prefix = `${serviceType.serviceTypeName}`;
                                    if (diagnosticProcedureResponse?.specificBodyPartName) {
                                        prefix += `: ${diagnosticProcedureResponse.specificBodyPartName}`;

                                        if (diagnosticProcedureResponse?.bodySideName) {
                                            prefix += ` - ${diagnosticProcedureResponse.bodySideName}`;
                                        }
                                    }

                                    return (
                                        !diagnosticProcedureResponse?.replacedByVendorOn && (
                                            <>
                                                <DxResponseSummary
                                                    referralStatus={props.referralOverviewDataModel?.referralStatus}
                                                    referralScheduleStatusTypes={props.referralScheduleStatusTypes}
                                                    diagnosticProcedureResponse={diagnosticProcedureResponse}
                                                    currentServiceType={serviceType.serviceTypeName}
                                                />
                                                <PhysicianResponse
                                                    prefix={prefix}
                                                    serviceId={props.serviceId}
                                                    physician={diagnosticProcedureResponse.physician}
                                                    states={props.states}
                                                />
                                            </>
                                        )
                                    );
                                })}
                        </Grid>
                        {props.serviceId === ServicesEnum.UR && (
                            <Grid item xs={12}>
                                <UrResponseSummary utilizationReviewResponse={props.serviceTypeResponseUr} />
                            </Grid>
                        )}
                        {props.serviceId === ServicesEnum.PT && (
                            <Grid item xs={12}>
                                <PtVisitCalendar physicalTherapyDataModel={props.physicalTherapyDataModel} referralVisitStatuses ={props.ReferralVisitStatuses}/>
                            </Grid>
                        )}
                        {(props.serviceId === ServicesEnum.UR || props.serviceId === ServicesEnum.PT) && (
                            <Grid item xs={12}>
                                <PhysicianResponse
                                    serviceId={props.serviceId}
                                    physician={props.physician}
                                    states={props.states}
                                />
                            </Grid>
                        )}
                        {props.serviceId === ServicesEnum.TT && (
                            <Grid item xs={12}>
                                <TtResponseSummary
                                    serviceTypes={props.referralOverviewDataModel?.referralServiceType}
                                    transportationTranslationResponses={props.transportationTranslationResponses}
                                />
                            </Grid>
                        )}
                        {props.serviceId === ServicesEnum.DME && (
                            <Grid item xs={12}>
                                <DmeResponseSummary dmeResponse={props.dmeResponse} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <ResponseDocuments vendorDocuments={props.responseDocuments} />
                        </Grid>
                    </Grid>
                </form>
            }
        </Fragment>
    );
};

export const ReferralMsResponse = withRouter(withStyles(styles)(ReferralMsResponseComponent));
