import clsx from 'clsx';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import styles from './AdminNavigation.Styles';
import { MenuItem, MenuList } from '@material-ui/core';
import { adminNavigationCards, IAdminNavigationCard } from './NavigationCards';
import UserEntity from '../../../entities/User.Entity';
import { checkPermissions } from '../../generic-components/access-control/AccessControl.Component';
import { useSelector } from 'react-redux';
import { UsersState } from '../../../store/reducers/usersReducer';
interface AdminNavigationProps {
    handleChangeRoute: (route: string, activeCard: IAdminNavigationCard) => void;
    userProfile: UserEntity;
    resetBreadcrumb: () => void;
}

export const AdminNavigation: FunctionComponent<AdminNavigationProps> = (props) => {
    const classes = styles();
    const { path } = useRouteMatch();

    const { pathname } = useLocation();
    const [activeCard, setActiveCard] = useState<IAdminNavigationCard>(null);
    const userDecurityRules = useSelector<UsersState, UsersState['securityRules']>((state) => state.securityRules);

    useEffect(() => {
        const pathArr = pathname.split('/');
        const currentPath = pathname.split('/')[1];

        const activeCard = adminNavigationCards.find((c) => c.link.includes(`/${currentPath}`));

        const newCard = { ...activeCard };
        if (pathArr.length > 3 && !pathArr.includes('admin')) {
            if (activeCard.profile?.subRoutes) {
                newCard.subRoutes = activeCard.profile?.subRoutes;
                newCard.section = null;
            }
            newCard['getProfile'] = true;
        } else {
            newCard['getProfile'] = false;
        }

        if (activeCard !== undefined && activeCard.subRoutesRoles != null) {
            newCard.subRoutesRoles = activeCard.subRoutesRoles;
        } else {
            newCard.subRoutesRoles = null;
        }

        setActiveCard(newCard);
        props.handleChangeRoute(currentPath, newCard);
    }, [path, pathname]);

    return (
        <MenuList disablePadding className={classes.menuList}>
            {adminNavigationCards.map(({ order, link, title, icon, hidden, allowedPermissions }) =>
                hidden
                    ? null
                    : checkPermissions(userDecurityRules, allowedPermissions, false) && (
                          <MenuItem
                              key={title}
                              className={clsx(
                                  classes.menuItem,
                                  classes.hover,
                                  activeCard?.order === order ? classes.active : '',
                              )}
                              component={Link}
                              selected={activeCard?.order === order}
                              to={`${link}`}
                              onClick={props.resetBreadcrumb}
                          >
                              {icon}
                              {title}
                          </MenuItem>
                      ),
            )}
        </MenuList>
    );
};
