export enum ReferralContactTypesEnum {
    Adjuster = 1,
    Claimant = 2,
    Client = 3,
    Employer = 4,
    TreatingPhysician = 5,
    OptionalPhysician = 6,
    ClaimantAttorney = 7,
    EmployerAttorney = 8,
    PhyisicianResponse = 9,
    NurseCaseManager = 10
}