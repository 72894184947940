import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
    root: {
        flexGrow: 1,
        display: 'table',
        height: '100%',
        margin: 'auto'
    },
    label: {
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'table-cell',
        color: 'rgba(0, 0, 0, 0.87);'
    }
});