import { Paper, Button } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from './Clients.Styles';
import { Route, Link, useRouteMatch, Redirect } from 'react-router-dom';
import Moment from 'moment';
import ClientPreferredVendorDataModel from '../../../data-models/ClientPreferredVendor.DataModel';
import EditedClientPreferredVendorDataModel from '../../../data-models/EditedPreferredVendor.DataModel';
import ServiceDataModel from '../../../data-models/Service.DataModel';
import VendorAdminTableDataModel from '../../../data-models/VendorAdminTable.DataModel';
import ClientEntity from '../../../entities/Client.Entity';
import StateEntity from '../../../entities/State.Entity';
import TenantEntity from '../../../entities/Tenant.Entity';
import UserEntity from '../../../entities/User.Entity';
import getClients, {
    createClient,
    editClient,
    getClientsByTenantId,
    getClientPreferredVendors,
    getClient,
    editClientPreferredVendor,
    createNewClientPreferredVneodrAndDeleteOldOne,
    createClientPreferredVendor,
    changeClientPreferredVendorStatus,
} from '../../../services/Client.Service';
import { getAllServices } from '../../../services/Services.Service';
import getStates from '../../../services/States.Service';
import { getTenants } from '../../../services/Tenants.Services';
import getProfile from '../../../services/Users.Service';
import { getVendorAdminTable } from '../../../services/Vendors.Service';
import { UserRolesEnum } from '../../../support/enums/UserRoles.Enum';
import { adminNavigationCards } from '../admin-navigation/NavigationCards';
import { MaterialTableProps } from 'material-table';
import MaterialTable from '../../generic-components/material-table/MaterialTable.Component';
import { AddClient } from '../add-client/AddClient.Component';
import { ClientProfileController } from '../client-profile-controller/ClientProfileController.Component';
import { Loader } from '../../generic-components/loader/Loader.Component';
import AccessControl, { checkPermissions } from '../../generic-components/access-control/AccessControl.Component';
import NotFound from '../../generic-components/not-found/NotFound.Component';
import { useSelector } from 'react-redux';
import { UsersState } from '../../../store/reducers/usersReducer';
import { BreadCrumb } from '../../../support/custom-hooks/useBreadCrum';
import {
    clientsListRules,
    clientsRules,
    preferredVendorRules,
    profileRules,
} from '../../../support/constants/SecurityRules.Constants';

interface NewCLientState {
    loading: boolean;
    clients: Array<ClientEntity>;
    currentRoute: string;
    selectedRouteInfo: {
        section: string;
        subRoutes: { label: string; link: string }[];
        subRoutesRoles: UserRolesEnum[][];
    };
    vendors: Array<VendorAdminTableDataModel>;
    states: Array<StateEntity>;
    currentSubroutIndex: string;
    newClient: ClientEntity;
    //Used for saving original state when editing
    tenants: Array<TenantEntity>;
    services: Array<ServiceDataModel>;
    clientProfile: ClientEntity;
    originalClientProfile: ClientEntity;
    newPreferredVendor: ClientPreferredVendorDataModel;
    clientPreferredVendors: Array<ClientPreferredVendorDataModel>;
    userProfile: UserEntity;
    filter: boolean;
    showAddClient: boolean;
    profileId: number;
}

type PathParamsType = {
    handleId?: (id: number) => void;
    // claimId: string;
};

export const NewClients: FunctionComponent<PathParamsType> = (props) => {
    const [state, setState] = useState<NewCLientState>({
        loading: false,
        clients: null,
        currentRoute: null,
        selectedRouteInfo: null,
        vendors: null,
        states: null,
        currentSubroutIndex: null,
        newClient: null,
        tenants: null,
        services: null,
        clientProfile: null,
        originalClientProfile: null,
        newPreferredVendor: null,
        clientPreferredVendors: null,
        userProfile: null,
        profileId: null,
        filter: false,
        showAddClient: false,
    });

    const { path } = useRouteMatch();
    const classes = styles();
    const abortController = new AbortController();

    var validationFunction: () => boolean = null;

    const setValidationFunction = (currentPageValidationFunction: () => boolean) => {
        validationFunction = currentPageValidationFunction;
    };

    useEffect(() => {
        getUserProfile();
        setState({
            ...state,
            loading: true,
        });
    }, []);

    const getUserProfile = () => {
        setState({
            ...state,
            loading: true,
        });
        getProfile(abortController.signal).then((profile) => {
            if (profile?.userRoles?.findIndex((x) => x == UserRolesEnum.TenantAdmin) != -1) {
                Promise.all([
                    getStates(abortController.signal),
                    getClientsByTenantId(profile?.tenantId, abortController.signal),
                    getTenants(abortController.signal),
                ]).then((results) => {
                    setState(() => {
                        return {
                            ...state,
                            states: results[0],
                            clients: results[1],
                            tenants: results[2],
                            currentRoute: 'clients',
                            userProfile: profile,
                            loading: false,
                        };
                    });
                });
            } else {
                Promise.all([
                    getStates(abortController.signal),
                    getClients(abortController.signal),
                    getTenants(abortController.signal),
                ]).then((results) => {
                    setState(() => {
                        return {
                            ...state,
                            states: results[0],
                            clients: results[1],
                            tenants: results[2],
                            currentRoute: 'clients',
                            userProfile: profile,
                            loading: false,
                        };
                    });
                });
            }
        });
    };

    const handleEditNewClient = (editedClient: ClientEntity) => {
        setState({
            ...state,
            newClient: editedClient,
        });
    };

    const handleCreateClient = () => {
        setState({
            ...state,
            loading: true,
        });
        createClient(state?.newClient, abortController.signal)
            .then(() => {
                const selectedCard = adminNavigationCards.find((c) => c.link === '/clients');
                getClients(abortController.signal).then((clients) => {
                    setState(() => {
                        return {
                            ...state,
                            states: state?.states,
                            clients,
                            currentRoute: 'clients',
                            selectedRouteInfo: {
                                section: selectedCard.section,
                                subRoutes: selectedCard.subRoutes,
                                subRoutesRoles: null,
                            },
                            newClient: null,
                            loading: false,
                        };
                    });
                });
            })
            .catch(() => {
                setState({
                    ...state,
                    loading: false,
                });
            });
    };

    const handleStartClientProfileEdit = () => {
        setState({ ...state, originalClientProfile: { ...state?.clientProfile } });
    };

    const handleCancelClientProfileEdit = () => {
        setState({ ...state, clientProfile: { ...state?.originalClientProfile } });
    };

    const handleSaveEditedClientProfile = () => {
        setState({ ...state, loading: true });

        editClient(state?.clientProfile.clientId, state?.clientProfile, abortController.signal)
            .then(() => {
                setState({ ...state, loading: false });
            })
            .catch(() => {
                setState({ ...state, loading: false });
            });
    };

    const handleClientProfileEdit = (editedClientProfile: ClientEntity) => {
        setState({
            ...state,
            clientProfile: editedClientProfile,
        });
    };

    const handleNewPreferredVendorEdit = (edited: ClientPreferredVendorDataModel) => {
        setState({
            ...state,
            newPreferredVendor: edited,
        });
    };

    const handleGetClientPreferredVendors = async (id: string) => {
        Promise.all([
            getVendorAdminTable(abortController.signal),
            getAllServices(abortController.signal),
            getClientPreferredVendors(parseInt(id), abortController.signal),
        ]).then((res) => {
            setState({
                ...state,
                vendors: res[0],
                services: res[1],
                clientPreferredVendors: res[2],
                loading: false,
                profileId: parseInt(id),
            });
        });
    };

    const handleNavigateToProfile = (id: number, section: string) => {
        if (isNaN(id)) {
            return;
        }

        props.handleId(id);

        setState({ ...state, loading: true });

        switch (section) {
            case 'Clients':
                Promise.all([getClient(id, abortController.signal), getTenants(abortController.signal)]).then((res) => {
                    setState({ ...state, clientProfile: res[0], tenants: res[1], loading: false, profileId: id });
                });
                break;
            default:
                setState({ ...state, loading: false });
                break;
        }
    };

    const handleStartEditPreferredVendor = (
        tenantId: number,
        vendorId: number,
        serviceId: number,
        states: number[],
        clientPreferredVendorIds: number[],
    ) => {
        setState({
            ...state,
            newPreferredVendor: {
                tenantId: tenantId,
                vendorId: vendorId,
                serviceId: serviceId,
                stateIds: states,
                isActive: true,
                clientPreferredVendorIds,
            },
        });
    };

    const handleConfirmEditPreferredVendor = (newStateIds: number[], removedStateIds: number[]) => {
        if (!(!validationFunction || validationFunction())) return;

        setState({ ...state, loading: true });
        const editedPreferredVendor: EditedClientPreferredVendorDataModel = {
            newStateIds: newStateIds,
            removedStateIds: removedStateIds,
            tenantId: state?.newPreferredVendor.tenantId,
            vendorId: state?.newPreferredVendor.vendorId,
            serviceId: state?.newPreferredVendor.serviceId,
        };

        editClientPreferredVendor(state?.clientProfile.clientId, editedPreferredVendor, abortController.signal).then(
            () => {
                getClientPreferredVendors(state?.clientProfile.clientId, abortController.signal).then(
                    (clientPreferredVendors) => {
                        setState({
                            ...state,
                            loading: false,
                            clientPreferredVendors: clientPreferredVendors,
                            newPreferredVendor: null,
                        });
                    },
                );
            },
        );
    };

    const handlConfirmUpdatePreferredVendor = (removedClientIds: number[]) => {
        if (!(!validationFunction || validationFunction())) return;

        setState({ ...state, loading: true });
        const editedPreferredVendor: EditedClientPreferredVendorDataModel = {
            newStateIds: state?.newPreferredVendor.stateIds,
            removedStateIds: [],
            tenantId: state?.newPreferredVendor.tenantId,
            vendorId: state?.newPreferredVendor.vendorId,
            serviceId: state?.newPreferredVendor.serviceId,
            removedClientIds,
        };

        createNewClientPreferredVneodrAndDeleteOldOne(
            state?.clientProfile.clientId,
            editedPreferredVendor,
            abortController.signal,
        ).then(() => {
            getClientPreferredVendors(state?.clientProfile.clientId, abortController.signal).then(
                (clientPreferredVendors) => {
                    setState({
                        ...state,
                        loading: false,
                        clientPreferredVendors: clientPreferredVendors,
                        newPreferredVendor: null,
                    });
                },
            );
        });
    };

    const handleCreateNewPreferredVendor = async () => {
        if (!(!validationFunction || validationFunction())) return;
        return await Promise.all([
            createClientPreferredVendor(
                state?.clientProfile.clientId,
                state?.newPreferredVendor,
                abortController.signal,
            ),
        ])
            .then(() => {
                getClientPreferredVendors(state?.clientProfile.clientId, abortController.signal).then((res) => {
                    setState({ ...state, clientPreferredVendors: res, newPreferredVendor: null });
                });
            })
            .catch((e) => {
                setState({ ...state, newPreferredVendor: null });
                throw e;
            });
    };

    const handleChangeClientPreferredVendorStatus = (ids: Array<number>) => {
        changeClientPreferredVendorStatus(ids, abortController.signal)
            .then(() => {
                getClientPreferredVendors(state?.clientProfile.clientId, abortController.signal).then((res) => {
                    setState({ ...state, loading: false, clientPreferredVendors: res });
                });
            })
            .catch((e) => {
                setState({ ...state, loading: false, newPreferredVendor: null });
            });
    };

    const handleClearNewPreferredVendor = () => {
        setState({ ...state, newPreferredVendor: null });
    };

    const handleNewPreferredVendorMultipleStateEdit = (edited: Array<number>, states: Array<StateEntity>) => {
        let selectedStates = edited.map((x) => states.find((y) => x === y.stateId).stateId);
        const currentPreferredVendor = { ...state?.newPreferredVendor };
        currentPreferredVendor.stateIds = selectedStates;
        setState({ ...state, newPreferredVendor: currentPreferredVendor });
    };

    const userSecurityRules = useSelector<UsersState, UsersState['securityRules']>((state) => state.securityRules);
    const setColumns = () => {
        if (state?.userProfile?.userRoles?.findIndex((x) => x == UserRolesEnum.TenantAdmin) != -1) {
            return [
                {
                    title: 'Number',
                    field: 'clientNumber',
                },
                {
                    title: 'Name',
                    field: 'clientName',
                    render: (rowData) => (
                        <Link
                            to={
                                checkPermissions(userSecurityRules, clientProfilePermissions, false)
                                    ? `${path}/${rowData.clientId}/profile`
                                    : checkPermissions(userSecurityRules, clientPreferredVendorsPermissions, false)
                                    ? `${path}/${rowData.clientId}/preferred-vendor`
                                    : path
                            }
                            onClick={() => handleNavigateToProfile(rowData.clientId, 'Clients')}
                        >
                            {rowData.clientName}
                        </Link>
                    ),
                },
                //Maybe used in the future
                //{
                //    title: 'Accout Manager',
                //    field: 'accoutManager'
                //},
                {
                    title: 'Status',
                    field: 'isActive',
                    render: (rowData) => (rowData.isActive ? 'Active' : 'Inactive'),
                    export: false,
                },
                {
                    title: 'Client Since Date',
                    field: 'createdDate',
                    render: (rowData) => (rowData.createdDate ? Moment(rowData.createdDate).format('M/D/YYYY') : ''),
                },
            ];
        } else {
            return [
                {
                    title: 'Number',
                    field: 'clientNumber',
                },
                {
                    title: 'Name',
                    field: 'clientName',
                    render: (rowData) => (
                        <Link
                            to={
                                checkPermissions(userSecurityRules, clientProfilePermissions, false)
                                    ? `${path}/${rowData.clientId}/profile`
                                    : checkPermissions(userSecurityRules, clientPreferredVendorsPermissions, false)
                                    ? `${path}/${rowData.clientId}/preferred-vendor`
                                    : path
                            }
                            onClick={() => handleNavigateToProfile(rowData.clientId, 'Clients')}
                        >
                            {rowData.clientName}
                        </Link>
                    ),
                },
                // {
                //     title: 'Tenant',
                //     field: 'tenantName',
                // },
                //Maybe used in the future
                //{
                //    title: 'Accout Manager',
                //    field: 'accoutManager'
                //},
                {
                    title: 'Status',
                    field: 'isActive',
                    render: (rowData) => (rowData.isActive ? 'Active' : 'Inactive'),
                    export: false,
                },
                {
                    title: 'Client Since Date',
                    field: 'createdDate',
                    render: (rowData) => (rowData.createdDate ? Moment(rowData.createdDate).format('M/D/YYYY') : ''),
                },
            ];
        }
    };

    const clientsTableProps: MaterialTableProps<ClientEntity> = {
        columns: setColumns(),
        data: state?.clients ? state?.clients : [],
        options: {
            filtering: state?.filter,
            showTitle: true,
            paging: state?.clients ? state?.clients.length > 10 : false,
            toolbar: true,
            exportButton: true,
            exportFileName: 'TerraClaimConnect_Admin_ClientsList',
            pageSizeOptions: [10, 25, 50],
            pageSize: 50,
            paginationPosition: 'top',
            emptyRowsWhenPaging: false,
        },
        style: {
            paddingLeft: '35px',
            paddingRight: '35px',
        },
        actions: [
            {
                icon: 'tune',
                tooltip: 'Filter',
                isFreeAction: true,
                onClick: () => setState({ ...state, filter: !state?.filter }),
            },
            {
                icon: (props) => (
                    <Button {...props} className={classes.button}>
                        Add Client
                    </Button>
                ),
                tooltip: 'Add Client',
                position: 'toolbar',
                onClick: (event, rowData) => {
                    setState({ ...state, showAddClient: true });
                },
            },
        ],
        title: 'Client List',
    };

    const clientProfileControllerPermissions = [
        ...clientsRules.read,
        ...profileRules.read,
        ...preferredVendorRules.read,
    ];

    const clientProfilePermissions = [...clientsRules.read, ...profileRules.read];
    const clientPreferredVendorsPermissions = [...clientsRules.read, ...preferredVendorRules.read];
    const addClientPermissions = [...clientsRules.read, ...clientsListRules.read];
    const clientsTablePermissions = [...clientsRules.read, ...clientsListRules.read, ...profileRules.read];

    const clientsBreadCrumb = {
        name: 'Clients',
        goBack: false,
        link: '/clients',
        path: path,
    };

    return state?.loading ? (
        <Loader isSmall />
    ) : (
        <>
            <Route path="/clients/:id">
                <AccessControl
                    allowedPermissions={clientProfileControllerPermissions}
                    sendBack={false}
                    renderNoAccess={<Redirect to="/not-found" />}
                >
                    <ClientProfileController
                        profile={state?.clientProfile}
                        states={state?.states}
                        handleClientEdit={handleClientProfileEdit}
                        handleStartClientProfileEdit={handleStartClientProfileEdit}
                        handleCancelClientProfileEdit={handleCancelClientProfileEdit}
                        handleSaveEditedClientProfile={handleSaveEditedClientProfile}
                        tenants={state?.tenants}
                        currentTabIndex={state?.currentSubroutIndex}
                        vendors={state?.vendors}
                        services={state?.services}
                        handleNewPreferredVendorEdit={handleNewPreferredVendorEdit}
                        newPreferredVendor={state?.newPreferredVendor}
                        handleNewPreferredVendorMultipleStateEdit={handleNewPreferredVendorMultipleStateEdit}
                        preferredVendors={state?.clientPreferredVendors}
                        handleCreateNewPreferredVendor={handleCreateNewPreferredVendor}
                        handleChangeClientPreferredVendorStatus={handleChangeClientPreferredVendorStatus}
                        handleStartEditPreferredVendor={handleStartEditPreferredVendor}
                        handleConfirmEditPreferredVendor={handleConfirmEditPreferredVendor}
                        handlConfirmUpdatePreferredVendor={handlConfirmUpdatePreferredVendor}
                        handleSetValidationFunction={setValidationFunction}
                        userProfile={state?.userProfile}
                        handleClearNewPreferredVendor={handleClearNewPreferredVendor}
                        handleGetClientPreferredVendors={handleGetClientPreferredVendors}
                        handleNavigateToProfile={handleNavigateToProfile}
                    />
                </AccessControl>
            </Route>
            <Route path="/clients" exact>
                <BreadCrumb sections={[clientsBreadCrumb]} />
                <Paper className={classes.paper}>
                    {state?.showAddClient ? (
                        <AccessControl allowedPermissions={addClientPermissions} sendBack={false}>
                            <AddClient
                                newClient={state?.newClient}
                                handleClientEdit={handleEditNewClient}
                                createClient={handleCreateClient}
                                states={state?.states}
                                cancel={() => setState({ ...state, showAddClient: true })}
                                tenants={state?.tenants}
                                userProfile={state?.userProfile}
                            />
                        </AccessControl>
                    ) : (
                        <AccessControl
                            allowedPermissions={clientsTablePermissions}
                            sendBack={false}
                            renderNoAccess={<Redirect to="/not-found" />}
                        >
                            <MaterialTable tableProps={clientsTableProps} />
                        </AccessControl>
                    )}
                </Paper>
            </Route>
        </>
    );
};
