import { createTheme , makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    gridContainer: {
        width: '100%',
    },
    paper: {
        paddingTop: '15px',
        paddingLeft: '35px',
        paddingRight: '35px',
        paddingBottom: '25px',
    },
    dataLabel: {
        color: '#898F91',
        fontSize: '12px'
    },
    iconTextRow: {
        display: 'inline-flex',
        flexDirection: 'row'
    },
    blackFont: {
        color: '#000000'
    },
    iconData: {
        color: '#000000',
        marginLeft: 10
    },
    paperHeader: {
        flexDirection: 'row-reverse'
    },
    paperTitle: {
        color: '#428302'
    },
    divider: {
        height: '1px',
        marginTop: 20,
        color: '#898F91'
    },
    paperHeaderIcon: {
        color: '#428302'
    },
    scoreRow: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center'
    },
    bolderFont: {
        color: '#000000',
        fontWeight: 'bolder'
    }
}));

export const imeScorecardTheme = createTheme ({
    overrides: {
      MuiRadio: {
        colorPrimary:{
          '&$disabled': {
            color: '#898F91'
          },
          '&$checked': {
            color: '#107A98!important'
          },
        },
      }
    },
  });