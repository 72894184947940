import React, { Fragment, FunctionComponent } from 'react';
import styles from './ClaimSidebar.Styles';
import { Typography, Accordion, AccordionDetails, Grid, Divider } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import CardIcon from '@material-ui/icons/ContactMail';
import LocationIcon from '@material-ui/icons/LocationOn';
import ClaimDataModel from '../../../data-models/Claim.DataModel';
import { EmptyDataLabel } from '../../generic-components/empty-data-label/EmptyDataLabel.Component';
import Moment from 'moment';
import ReferralEntity from '../../../entities/Referral.Entity';
import ReferralStatusEntity from '../../../entities/ReferralStatus.Entity';

type ClaimSidebarProps = {
    claimDataModel: ClaimDataModel;
    referral: ReferralEntity;
    referralStatus: Array<ReferralStatusEntity>;
    employerName: string;
    vendorName: string;
    serviceTypeName: Array<string>;
    side: boolean;
};
function getReferralStatus(referralStatus, StatusTypeId) {
    let referralStatusName = 'Open';
    referralStatus?.map((status: ReferralStatusEntity, index) => {
        if (status.referralStatusId == StatusTypeId) {
            referralStatusName = status.referralStatusLabel;
        }
    });
    return referralStatusName;
}

export const ClaimSidebar: FunctionComponent<ClaimSidebarProps> = ({
    claimDataModel = null,
    referral = null,
    employerName = null,
    vendorName = null,
    serviceTypeName = null,
    referralStatus = null,
    side,
}: ClaimSidebarProps) => {
    const classes = styles();

    return side ? ( /** bar located at right side (vertical mode) */
        <Fragment>
            {claimDataModel ? (
                <Fragment>
                    <div className={classes.claimDataContainer}>
                        {referral == null ? (
                            <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                Claim
                            </Typography>
                        ) : (
                            <div>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Referral
                                </Typography>
                                <Typography variant="h5" className={classes.referralHighlightedData}>
                                    {referral?.referralId}
                                </Typography>
                                <div className={classes.iconTextRow}>
                                    <Typography variant="subtitle1" className={classes.referralData}>
                                        {getReferralStatus(referralStatus, referral.referralStatusId)}{' '}
                                        {Moment(referral.referralStatusDate).format('MM/DD/YYYY')}
                                    </Typography>
                                </div>
                            </div>
                        )}
                        <div className={classes.dataContainer}>
                            <div className={classes.dataRow}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Claim
                                </Typography>
                                <Typography variant="subtitle1" className={classes.claimData}>
                                    {claimDataModel?.claim?.claimNumber}
                                </Typography>
                            </div>
                            <div className={classes.dataRow}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Claimant
                                </Typography>
                                <div className={classes.iconTextRow}>
                                    <Fragment>
                                        <PersonIcon className={classes.blackFont}></PersonIcon>{' '}
                                        <Typography variant="subtitle1" className={classes.claimData}>
                                            {claimDataModel.claim
                                                ? claimDataModel.claim.claimantFirstName +
                                                  ' ' +
                                                  claimDataModel.claim.claimantLastName
                                                : 'No claimant data'}
                                        </Typography>
                                    </Fragment>
                                </div>
                            </div>
                            <div className={classes.dataRow}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    DOL
                                </Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>
                                    {Moment(claimDataModel?.claim?.dateOfLoss).format('MM/DD/YYYY')}
                                </Typography>
                            </div>
                            <div className={classes.dataRow}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Coverage
                                </Typography>
                                <div className={classes.iconTextRow}>
                                    <CardIcon className={classes.blackFont}></CardIcon>{' '}
                                    <Typography variant="subtitle2" className={classes.iconData}>
                                        {claimDataModel?.coverageType?.coverageTypeCode +
                                            ' - ' +
                                            claimDataModel?.coverageType?.coverageTypeName}
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.dataRow}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    State of Jurisdiction
                                </Typography>
                                <div className={classes.iconTextRow}>
                                    <LocationIcon className={classes.blackFont}></LocationIcon>{' '}
                                    <Typography variant="subtitle2" className={classes.iconData}>
                                        {claimDataModel?.stateOfJurisdiction?.stateName}
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.dataRow}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Client
                                </Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>
                                    {claimDataModel?.client?.clientNumber + ' - ' + claimDataModel?.client?.clientName}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    {referral == null ? (
                        <Fragment></Fragment>
                    )
                    : (
                        <Fragment>
                            <Divider className={classes.divider}></Divider>
                            <div className={classes.bottomContainer}>
                                <div className={classes.claimDataContainer}>
                                    <div className={classes.dataRow}>
                                        <Typography variant="body2" className={classes.dataLabel}>
                                            ServiceType
                                        </Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>
                                            {serviceTypeName.join(', ')}
                                        </Typography>
                                    </div>
                                    <div className={classes.dataRow}>
                                        <Typography variant="body2" className={classes.dataLabel}>
                                            Vendor
                                        </Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>
                                            {vendorName}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            )
            : (
                <EmptyDataLabel></EmptyDataLabel>
            )}
        </Fragment>
    )
    : ( /** bar located at top side (horizontal mode) */
        <Accordion defaultExpanded={true} className={classes.paper}>
            <AccordionDetails>
                {claimDataModel ? (
                    <Grid container spacing={3} className={classes.gridContainer}>
                        {referral !== null && (
                            <Grid item xs={2}>
                                <Typography variant="subtitle2" className={classes.claimTitle}>
                                    Referral
                                </Typography>
                                <Typography variant="subtitle1" className={classes.referralData}>
                                    {referral?.referralId}
                                </Typography>
                                <div className={classes.iconTextRow}>
                                    <Typography variant="subtitle1" className={classes.referralData}>
                                        {getReferralStatus(referralStatus, referral?.referralStatusId)}{' '}
                                        {Moment(referral?.referralStatusDate).format('MM/DD/YYYY')}
                                    </Typography>
                                </div>
                            </Grid>
                        )}
                        <Grid item xs={2}>
                            <Typography variant="subtitle2" className={classes.claimTitle}>
                                Claim
                            </Typography>
                            <Typography variant="subtitle1" className={classes.claimData}>
                                {' '}
                                {claimDataModel?.claim?.claimNumber
                                    ? claimDataModel?.claim?.claimNumber
                                    : 'No claim data'}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.dataLabel}>
                                Claimant
                            </Typography>
                            <div className={classes.iconTextRow}>
                                <PersonIcon className={classes.blackFont}></PersonIcon>{' '}
                                <Typography variant="subtitle1" className={classes.claimData}>
                                    {claimDataModel.claim
                                        ? claimDataModel.claim.claimantFirstName +
                                          ' ' +
                                          claimDataModel.claim.claimantLastName
                                        : 'No claimant data'}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.dataLabel}>
                                DOL
                            </Typography>
                            <Typography variant="subtitle2" className={classes.blackFont}>
                                {Moment(claimDataModel?.claim?.dateOfLoss).format('MM/DD/YYYY')}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="body2" className={classes.dataLabel}>
                                Coverage
                            </Typography>
                            <div className={classes.iconTextRow}>
                                <Typography variant="subtitle2" className={classes.iconData}>
                                    {claimDataModel?.coverageType?.coverageTypeCode}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="body2" className={classes.dataLabel}>
                                State of Jurisdiction
                            </Typography>
                            <div className={classes.iconTextRow}>
                                <LocationIcon className={classes.blackFont}></LocationIcon>{' '}
                                <Typography variant="subtitle2" className={classes.iconData}>
                                    {claimDataModel?.stateOfJurisdiction?.stateCode}
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.dataLabel}>
                                Client
                            </Typography>
                            <Typography variant="subtitle2" className={classes.blackFont}>
                                {claimDataModel?.client?.clientNumber + ' - ' + claimDataModel?.client?.clientName}
                            </Typography>
                        </Grid>
                        {referral && ( /* editting a referral (or adding a new one after the fist step) */
                            <>
                                <Grid item xs={2}>
                                    <Typography variant="body2" className={classes.dataLabel}>
                                        Employer
                                    </Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>
                                        {employerName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body2" className={classes.dataLabel}>
                                        ServiceType
                                    </Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>
                                        {serviceTypeName.join(', ')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body2" className={classes.dataLabel}>
                                        Vendor
                                    </Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>
                                        {vendorName}
                                    </Typography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                ) : (
                    <EmptyDataLabel></EmptyDataLabel>
                )}
            </AccordionDetails>
        </Accordion>
    );
};
