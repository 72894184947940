import React, { FunctionComponent } from 'react';
import { Paper, Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, DialogProps } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { styled } from '@material-ui/core/styles';
import styles from "./CustomModal.Styles";


const PREFIX = "Confirm";

const classes = {
  content: `${PREFIX}-content`,
  close: `${PREFIX}-close`,
  actions: `${PREFIX}-actions`,
  header: `${PREFIX}-header`
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.content}`]: { color: "#000000", fontSize: 12, overflowY: "auto" },
  [`& .${classes.close}`]: { marginRight: 0 },
  [`& .${classes.header}`]: { backgroundColor: "#EEEFEF", height: 48, padding: "0 1.5rem", "& > div": { height: "100%", margin: 0 } },
  [`& .${classes.actions}`]: {
    padding: theme.spacing(1, 3, 1, 3),
    height: 56,
    backgroundColor: "#EEEFEF",
    "& .MuiButton-root": { lineHeight: 1.5 }
  }
}));


interface ICustomModalProps {
    closeHandler: (val: boolean) => void;
    acceptHandler: (val: boolean) => void;
    open: boolean;
    title?: string;
    subtitle?: string;
    acceptButtonText?: string;
    cancelButtonText?: string;
    children?: any;
    isFullScreen?: boolean;		
		fullWidth?: boolean;
		maxWidth?: DialogProps['maxWidth'];
}

export const CustomModal: FunctionComponent<ICustomModalProps> = (props: ICustomModalProps) => {
    const classes = styles();
		const { fullWidth, maxWidth } = props;
    const handleClose = () => {
        props.closeHandler(false);
    };
    const handleAccept = () => {
        props.acceptHandler(true);
    };    
    return (
      <StyledDialog open={props.open} onClose={handleClose} PaperComponent={Paper} {...props} fullWidth={fullWidth} maxWidth={maxWidth}>             
        {
          props.title && 
            <DialogTitle>
            <Grid container alignItems="center" spacing={2} wrap="nowrap">
              <Grid item xs className="tcc-p0">
                <Typography className="tcc-box-title">{props.title}</Typography>
              </Grid>          
              <Grid item className="tcc-p0">
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            </DialogTitle>
        }
        {
          (props.subtitle ||
          props.children) &&
              <DialogContent>
                  {props.subtitle && <Typography variant="subtitle1" className={classes.subtitle}>{props.subtitle}</Typography>}
                  {props.children && <>{props.children}</>}
              </DialogContent>
        }
        {
          (props.acceptButtonText || 
          props.cancelButtonText) &&
              <DialogActions>
                  {props.acceptButtonText && <Button onClick={handleAccept} color="primary" variant="contained" className={classes.acceptButton}>{props.acceptButtonText}</Button>}
                  {props.cancelButtonText && <Button onClick={handleClose} color="primary" variant="outlined" className={classes.cancelButton}>{props.cancelButtonText}</Button>}
              </DialogActions>
        }
      </StyledDialog>
        
    );
}