import React, { Fragment, FunctionComponent, useEffect } from 'react';
import styles from "./PtHeader.Styles";
import { Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, TextField, Typography } from '@material-ui/core';
import { EmptyDataLabel } from '../../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import SpecificBodyPartEntity from '../../../../../../entities/SpecificBodyPart.Entity';
import InjuryTypeEntity from '../../../../../../entities/pt/InjuryType.Entity';
import BodySideEntity from '../../../../../../entities/BodySide.Entity';
import { IMedicalServiceReferralFormErrors } from "../MedicalServiceReferralForm.Component";
import { TooltipTextField } from "../../../../../generic-components/tooltip-textfield/TooltipTextField.Component";
import ServiceTypePTEntity from '../../../../../../entities/pt/ServiceTypePT.Entity';
import LanguageEntity from '../../../../../../entities/Language.Entity';
import { MaterialTableProps } from "material-table";
import MaterialTable from "../../../../../generic-components/material-table/MaterialTable.Component";
import ServiceTypeRequestPtDetailDataModel from "../../../../../../data-models/pt/ServiceTypeRequestPtDetail.DataModel";
import ServiceTypeRequestPtDetailEntity from "../../../../../../entities/pt/ServiceTypeRequestPtDetail.Entity";
import { useValidationDialog } from '../../../../../../support/custom-hooks/useValidationDialog';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { toast } from 'react-toastify';

interface IPtHeaderProps {
    referralId: number,
    specificBodyParts: Array<SpecificBodyPartEntity>,
    injuryTypes: Array<InjuryTypeEntity>,
    bodySides: Array<BodySideEntity>,
    serviceTypePT: ServiceTypePTEntity,
    handleServiceTypePTEdit: any,
    errors: IMedicalServiceReferralFormErrors,
    validate: (isChange?: boolean, isSubmit?: boolean) => void,
    handleSetIsValidPtProcedureFunction: (currentPageIsValidPtProcedureFunction: () => boolean) => void;
    hasBeenSubmitted: boolean,
    handleSpecificBodyPart: (sbpId: number) => void,
    languages: Array<LanguageEntity>,
    handleServiceTypeRequestPtDetailEdit: (serviceTypeRequestPtDetail: Array<ServiceTypeRequestPtDetailDataModel>) => void,
    deleteServiceTypeRequestPtDetail: (serviceTypeRequestPtDetailId: number) => void,
    handleClearInjuryTypes: () => void,
}

export interface IPtHeaderErrors {
    specificBodyPartId?: string,
    injuryTypeId?: string
}

export const PtHeader: FunctionComponent<IPtHeaderProps> = (props: IPtHeaderProps) => {
    const classes = styles();
    const abortController = new AbortController();
    const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState<boolean>(false);

    const [languages, setLanguages] = React.useState<Array<LanguageEntity>>(null);
    const [referralServiceTypePTState, setReferralServiceTypePTState] = React.useState(props.serviceTypePT);
    const [counterState, setCounterState] = React.useState(props.serviceTypePT ? props.serviceTypePT : null);

    const [rowsUpdated, setRowsUpdated] = React.useState<Array<ServiceTypeRequestPtDetailDataModel>>(null);
    const [serviceTypeRequestPtDetailToAdd, setServiceTypeRequestPtDetailToAdd] = React.useState<ServiceTypeRequestPtDetailEntity>(null);

    const [specificBodyPartSelected, setSpecificBodyPartSelected] = React.useState<number>(null);
    const [injuryTypeSelected, setInjuryTypeSelected] = React.useState<InjuryTypeEntity>(null);
    const [keyInjuryType, setKeyInjuryType] = React.useState(0);

    const [errors, setErrors] = React.useState<IPtHeaderErrors>(null);
    const {Dialog, checkForErrors} = useValidationDialog("Validation","At least one specific body part must be added","Ok");
    
    //componentDidMount
    useEffect(() => {
        props.handleSetIsValidPtProcedureFunction(isValidPtProcedure);

        if(!serviceTypeRequestPtDetailToAdd)
        {
            let newP: ServiceTypeRequestPtDetailEntity = new ServiceTypeRequestPtDetailEntity();
            setServiceTypeRequestPtDetailToAdd(newP);
        }

        if(!!props.serviceTypePT?.serviceTypeRequestPtDetail)
        {
            setRowsUpdated(props.serviceTypePT?.serviceTypeRequestPtDetail);
        }

        let newSTPT: ServiceTypePTEntity = props.serviceTypePT;
        newSTPT.referralId =  props.referralId;
        setReferralServiceTypePTState(newSTPT);
        setCounterState(newSTPT)

        setKeyInjuryType(0);

        var sortedLanguages: Array<LanguageEntity> = [...props.languages, { languageId: 0, languageName: 'Other' }];
        sortedLanguages.sort((a, b) => a.languageName.localeCompare(b.languageName));
        sortedLanguages.unshift(sortedLanguages.splice(12, 1)[0]);
        setLanguages(sortedLanguages);

        return function cleanup() {
            abortController.abort();
        }
    }, [props]);

    const handleTextFieldChangePtHeader = (event) => {
        event.persist();

        let newSTPT : ServiceTypePTEntity = referralServiceTypePTState;

        if (!newSTPT.referralId) {
            newSTPT.referralId = props.referralId;
        }

        if (event.target.type == 'checkbox') {
            setCounterState({...counterState,[event.target.id ? event.target.id : event.target.name] : event.target.checked});
            newSTPT[event.target.id ? event.target.id : event.target.name] = event.target.checked;
        }
        else {
            setCounterState({...counterState,[event.target.id ? event.target.id : event.target.name] : event.target.value});
            newSTPT[event.target.id ? event.target.id : event.target.name] = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value?.toString() == "true" ? true : event.target.value?.toString() == "false" ? false : event.target.value;
        }
        
        if(newSTPT.languageId !== 0) {
            newSTPT.otherLanguage = null;
        }
        
        if (!newSTPT.translation) {
            newSTPT.otherLanguage = null;
            newSTPT.languageId = null;
        }
        
        setReferralServiceTypePTState(newSTPT);
        
        props.handleServiceTypePTEdit(referralServiceTypePTState);

        if(props.hasBeenSubmitted)
            props.validate(true, false);
    };

    const handleSpecificBodyPartId = (event) => {
        event.persist();

        let newP: ServiceTypeRequestPtDetailEntity;
        if(!serviceTypeRequestPtDetailToAdd){
            newP = new ServiceTypeRequestPtDetailEntity();
        } else {
            newP = serviceTypeRequestPtDetailToAdd;
        }

        setSpecificBodyPartSelected(event.target.value)

        newP.specificBodyPartId = event.target.value;
        
        setServiceTypeRequestPtDetailToAdd(newP);

        props.handleSpecificBodyPart(event.target.value);
    }

    const handleInjuryTypeId = (event, value) => {
        event.persist();

        let newP: ServiceTypeRequestPtDetailEntity;
        if(!serviceTypeRequestPtDetailToAdd){
            newP = new ServiceTypeRequestPtDetailEntity();
        } else {
            newP = serviceTypeRequestPtDetailToAdd;
        }

        setInjuryTypeSelected(value);

        newP.injuryTypeId = value?.injuryTypeId;
        setKeyInjuryType(keyInjuryType === 0 ? 1 : 0);
        
        setServiceTypeRequestPtDetailToAdd(newP);
    }

    const handleServiceTypePtDetailChange = (event) => {
        event.persist();

        if(event.target.id == "specificBodyPartId" || event.target.name == "specificBodyPartId"){
            props.handleSpecificBodyPart(event.target.value);
        }

        if(!serviceTypeRequestPtDetailToAdd.referralId){
            setServiceTypeRequestPtDetailToAdd({...serviceTypeRequestPtDetailToAdd,referralId: props.referralId});
        }

        setServiceTypeRequestPtDetailToAdd({...serviceTypeRequestPtDetailToAdd,[event.target.id ? event.target.id : event.target.name] : event.target.value});
    }

    const handleDeleteRow = (rowData: ServiceTypeRequestPtDetailDataModel) => {
        
        let newListOfProcedures: Array<ServiceTypeRequestPtDetailDataModel> = [...rowsUpdated];   
        const procedureIdxToDelete = newListOfProcedures.findIndex(p => p?.bodySideId === rowData.bodySideId &&
            p?.specificBodyPartId === rowData.specificBodyPartId &&
            p?.injuryTypeId === rowData.injuryTypeId
        );
        
        newListOfProcedures.splice(procedureIdxToDelete, 1);

        if(rowData.serviceTypeRequestPtDetailId !== null && rowData.serviceTypeRequestPtDetailId !== undefined){
            props.deleteServiceTypeRequestPtDetail(rowData.serviceTypeRequestPtDetailId);
        }

        setRowsUpdated(newListOfProcedures);
        props.handleServiceTypeRequestPtDetailEdit(newListOfProcedures);
    }

    const serviceTypeRequestPtTableProps: MaterialTableProps<ServiceTypeRequestPtDetailDataModel> = {
        columns: [
            {
                title:'Specific Body Parts',
                field:'specificBodyPartName'
            },
            {
                title:'Body Side',
                field:'bodySideName'
            },
            {
                title:'Injury Type',
                field:'injuryTypeName'
            },
        ],
        data: rowsUpdated ?? [],
        options: {
            filtering: false,
            showTitle: true,
            paging: false,
            toolbar: false,
            actionsColumnIndex: -1,
        },
        style:{
            paddingLeft: '35px',
            paddingRight: '35px',
        },
        actions:[
            {
                icon:'delete',
                tooltip:'delete detail',
                position:'row',
                onClick: (event, rowData) => {
                    if(Array.isArray(rowData)) return;
                    handleDeleteRow(rowData);
                }
            },
          ],
    }

    const formatRow = (newProcedure: ServiceTypeRequestPtDetailEntity):ServiceTypeRequestPtDetailDataModel => {

        let newRow: ServiceTypeRequestPtDetailDataModel = new ServiceTypeRequestPtDetailDataModel;

        newProcedure.referralId = props.referralId;

        for(let sbp of props.specificBodyParts){
            if(sbp.specificBodyPartId == newProcedure.specificBodyPartId){
                newRow.specificBodyPartId = sbp.specificBodyPartId;
                newRow.specificBodyPartName = sbp.specificBodyPartName;
                break;
            }
        }
        for(let bs of props.bodySides){
            if(bs.bodySideId == newProcedure.bodySideId){
                newRow.bodySideId = bs.bodySideId;
                newRow.bodySideName = bs.bodySideName;
                break;
            }
        }
        for(let it of props.injuryTypes){
            if(it.injuryTypeId == newProcedure.injuryTypeId){
                newRow.injuryTypeId = it.injuryTypeId;
                newRow.injuryTypeName = it.injuryTypeName;
                break;
            }
        }

        return newRow;
    };

    const validatePTHeader = (isChange?: boolean, isSubmit: boolean = true) => {
        let errors: IPtHeaderErrors = {};
        errors = getErrorsListPtProcedure()
        setErrors(errors);
        return checkForErrors(errors, isChange);
    };

    const isValidPtProcedure = () => { //returns true/false to know if the form has passed the validation OK (true) or not (false)
        let errors: IPtHeaderErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsListPtProcedure();
        setErrors(errors);
        isValidResult = (Object.keys(errors).length == 0);
        return isValidResult;
    }

    const getErrorsListPtProcedure = () => {//returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: IPtHeaderErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }

        if (!(rowsUpdated.length > 0 )) {
            errors.specificBodyPartId = 'Required';

            if (!serviceTypeRequestPtDetailToAdd?.specificBodyPartId)
                errors.specificBodyPartId = 'Required';
            else {
                if (!serviceTypeRequestPtDetailToAdd?.injuryTypeId)
                    errors.injuryTypeId = "Required";
            }
        }
        return errors;
    }

    const localValidate = (isChange?: boolean) => {
        if(!hasBeenSubmitted){
            setHasBeenSubmitted(true);
        }

        let errors: IPtHeaderErrors = {};

        errors.specificBodyPartId = serviceTypeRequestPtDetailToAdd && serviceTypeRequestPtDetailToAdd.specificBodyPartId > 0 ? "" : "Required";

        if(serviceTypeRequestPtDetailToAdd?.specificBodyPartId > 0)
        {
            errors.injuryTypeId = serviceTypeRequestPtDetailToAdd && serviceTypeRequestPtDetailToAdd.injuryTypeId > 0 ? "" : "Required";
        }

        setErrors(errors);
        return checkForErrors(errors, isChange);
    }

    const handleButtonAddProcedure = (event) => {
        event.persist();

        if ( localValidate(false) ) {
            let newRowsTable: Array<ServiceTypeRequestPtDetailDataModel>;
            if (!rowsUpdated) {
                newRowsTable = new Array<ServiceTypeRequestPtDetailDataModel>();
            } else {
                newRowsTable = rowsUpdated;
            }
    
            let newListServiceTypeRequests: Array<ServiceTypeRequestPtDetailDataModel> = [...rowsUpdated];
            let newServiceTypeRequest: ServiceTypeRequestPtDetailDataModel = new ServiceTypeRequestPtDetailDataModel();
    
            newServiceTypeRequest.specificBodyPartId = serviceTypeRequestPtDetailToAdd.specificBodyPartId;
            newServiceTypeRequest.injuryTypeId  = injuryTypeSelected.injuryTypeId;
            newServiceTypeRequest.bodySideId = serviceTypeRequestPtDetailToAdd.bodySideId;
            newServiceTypeRequest.serviceTypeRequestPtDetailId = serviceTypeRequestPtDetailToAdd.serviceTypeRequestPtDetailId;
            newServiceTypeRequest.serviceTypeRequestPtId = serviceTypeRequestPtDetailToAdd.serviceTypeRequestPtId;
    
            let newRow = formatRow(serviceTypeRequestPtDetailToAdd);
    
            newRowsTable.push(newRow);
    
            setRowsUpdated(newRowsTable);
            
            newListServiceTypeRequests.push(newRow);
    
            props.handleServiceTypeRequestPtDetailEdit(newRowsTable);
    
            cleanTextFields();
        }
    }

    const cleanTextFields = () => {
        let newP : ServiceTypeRequestPtDetailEntity = new ServiceTypeRequestPtDetailEntity();
        setServiceTypeRequestPtDetailToAdd(newP);

        setErrors(null);
        props.handleClearInjuryTypes();
    }

    const handlePaste = (event) => {
        event.clipboardData.items[0].getAsString(text => {
            console.log(text.length);
          if(text.length>1000)
          {
              toast.warning('⚠️ ' + 'You’ve reached the character limit, please shorten your message');
          }
        })
      }
    return (
        <Fragment>
            {
                referralServiceTypePTState ?
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="Number of approved visits" id="numberOfApprovedVisits"
                                type="number"
                                onChange={(event) => handleTextFieldChangePtHeader(event)}
                                defaultValue={referralServiceTypePTState.numberOfApprovedVisits ? referralServiceTypePTState.numberOfApprovedVisits : ''}
                                required 
                                error={!!props.errors?.ptNumberOfApprovedVisits} 
                                helperText={props.errors?.ptNumberOfApprovedVisits}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="Frequency & Duration" id="frequencyAndDuration"
                                onChange={(event) => handleTextFieldChangePtHeader(event)}
                                defaultValue={referralServiceTypePTState.frequencyAndDuration ? referralServiceTypePTState.frequencyAndDuration : ''}
                                required 
                                error={!!props.errors?.ptFrequencyAndDuration} 
                                helperText={props.errors?.ptFrequencyAndDuration}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField
                                label="Schedule special instructions" id="scheduleSpecialInstructions"
                                onChange={(event) => handleTextFieldChangePtHeader(event)}
                                defaultValue={referralServiceTypePTState.scheduleSpecialInstructions ? referralServiceTypePTState.scheduleSpecialInstructions : ''}
                            />
                        </Grid>
                        <Grid item xs={3} />
                        <Grid item xs={12} />

                        <Grid item xs={3}>
                            <TooltipTextField
                                type='select'
                                label='Specific Body Parts'
                                inputLabelId="demo-simple-select-label"
                                formControlClass={classes.selectInput}
                                id="specificBodyPartId"
                                name="specificBodyPartId"
                                key={serviceTypeRequestPtDetailToAdd?.specificBodyPartId}
                                defaultValue={serviceTypeRequestPtDetailToAdd?.specificBodyPartId}
                                onChange={(event) => handleSpecificBodyPartId(event)}
                                required 
                                error={!!errors?.specificBodyPartId} 
                            >
                                {
                                    props.specificBodyParts?.map((specificBodyPart, index) => {
                                        return (
                                            <MenuItem key={specificBodyPart.specificBodyPartId} value={specificBodyPart.specificBodyPartId}>{specificBodyPart.specificBodyPartName}</MenuItem>
                                        )
                                    })
                                }
                            </TooltipTextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField
                                type='select'
                                label='Body Side'
                                formControlClass={classes.selectInput}
                                inputLabelId="demo-simple-select-label"
                                id="bodySideId"
                                name="bodySideId"
                                key={serviceTypeRequestPtDetailToAdd?.bodySideId}
                                defaultValue={serviceTypeRequestPtDetailToAdd?.bodySideId}
                                onChange={(event) => handleServiceTypePtDetailChange(event)}
                            >
                                {
                                    props.bodySides?.map((bodySide, index) => {
                                        return (
                                            <MenuItem key={bodySide.bodySideId} value={bodySide.bodySideId}>{bodySide.bodySideName}</MenuItem>
                                        )
                                    })
                                }
                            </TooltipTextField>
                        </Grid>
                        <Grid item xs={3}>
                            <Autocomplete
                                id="injuryTypeId"
                                options={props.injuryTypes}
                                getOptionLabel={option => option.injuryTypeName}
                                //key={keyInjuryType}
                                key={props.injuryTypes?.length}
                                onChange={(event, value) => handleInjuryTypeId(event, value)}
                                disabled={!(props.injuryTypes && props.injuryTypes.length > 0)}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label="Injury Type"
                                        fullWidth
                                        required 
                                        error={!!errors?.injuryTypeId} 
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={3} alignItems='center'>
                            <div className={classes.addButtonContainer}>
                                <Button
                                    className={classes.button}
                                    onClick={handleButtonAddProcedure}
                                    disabled={false}
                                    variant="contained"
                                    color="primary"
                                >
                                    ADD
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <MaterialTable key={rowsUpdated?.length ?? 0} tableProps={serviceTypeRequestPtTableProps} />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="rushRequest"
                                        name="rushRequest"
                                        disableRipple
                                        checked={referralServiceTypePTState.rushRequest}
                                        onClick={(event) => handleTextFieldChangePtHeader(event)}
                                    />
                                }
                                label={<Typography variant="body2">Rush Request</Typography>}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Transportation</FormLabel>
                                <RadioGroup row aria-label="position" id="transportation" name="transportation" key={referralServiceTypePTState?.transportation ? "true" : "false"} onChange={(event) => handleTextFieldChangePtHeader(event)} value={referralServiceTypePTState?.transportation ? true : false}>
                                    <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            {/* EMPTY SPACE */}
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField 
                                label="Patient Language" 
                                id="patientLanguage" 
                                name="patientLanguage" 
                                onChange={(event) => handleTextFieldChangePtHeader(event)} 
                                defaultValue={referralServiceTypePTState.patientLanguage ? referralServiceTypePTState.patientLanguage : ''} 
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Translation</FormLabel>
                                <RadioGroup row aria-label="position" id="translation" name="translation" onChange={(event) => handleTextFieldChangePtHeader(event)} value={referralServiceTypePTState?.translation ? true : false}>
                                    <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
                                    <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3} container spacing={3}>
                            <Grid item xs={12}>
                                <TooltipTextField
                                    type='select'
                                    formControlClass={classes.selectInput}
                                    label='Language'
                                    inputLabelId="demo-simple-select-label"
                                    id="languageId"
                                    name="languageId"
                                    key={referralServiceTypePTState.languageId}
                                    value={referralServiceTypePTState.languageId || referralServiceTypePTState.languageId === 0 ? referralServiceTypePTState.languageId : ''}
                                    onChange={(event) => handleTextFieldChangePtHeader(event)}
                                    disabled={!referralServiceTypePTState.translation}
                                    zeroIsValid
                                >
                                    {
                                        languages?.map((language, index) => {
                                            return (
                                                <MenuItem key={language?.languageId} value={language?.languageId}>{language?.languageName}</MenuItem>
                                            )
                                        })
                                    }
                                </TooltipTextField>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <TooltipTextField 
                                label="Other Language" 
                                id="otherLanguage" 
                                disabled={!!referralServiceTypePTState.languageId || !referralServiceTypePTState.translation || referralServiceTypePTState.languageId !== 0} 
                                onChange={(event) => handleTextFieldChangePtHeader(event)} value={referralServiceTypePTState.otherLanguage ? referralServiceTypePTState.otherLanguage : ''} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl className={classes.selectInput}>
                                <TooltipTextField 
                                    maxRows={4} 
                                    multiline={true} 
                                    label="Additional Comments" 
                                    id="otherComments" 
                                    name="otherComments" 
                                    onChange={(event) => handleTextFieldChangePtHeader(event)} 
                                    defaultValue={referralServiceTypePTState.otherComments ? referralServiceTypePTState.otherComments : ''} 
                                    inputProps={{ maxLength: 1000 }} 
                                    helperText={`${counterState?.otherComments ? counterState?.otherComments.length : 0 }/${1000}`} 
                                    onPaste={handlePaste}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    : <EmptyDataLabel></EmptyDataLabel>
            }
            {Dialog}
        </Fragment>
    );
}