export default class ServiceTypeRequestDxEntity{
    referralId: number;
    serviceTypeRequestDxId: number;
    transportation: boolean;
    patientLanguage: string;
    translation: boolean;
    languageId: number;
    otherLanguage: string;
    otherComments: string;
    rushRequest: boolean;
    weightLbs: number;
    heightFt: number;
    heightInches: number;
    // public constructor (serviceTypeNumber: number,serviceTypeName: string){
    //     this.serviceTypeNumber = serviceTypeNumber;
    //     this.serviceTypeName = serviceTypeName;
    // }
}