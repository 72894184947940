import { Configuration } from "@azure/msal-browser";

export const resetPasswordUri: string = process.env.REACT_APP_B2C_RPURI;

export const loginUri: string = process.env.REACT_APP_B2C_SUSIURI;

export const scopes: Array<string> = process.env.REACT_APP_B2C_SCOPES.split(',');

export const msalInstance: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_B2C_CLIENTID,
        authority: loginUri,
        knownAuthorities: [process.env.REACT_APP_B2C_AUTHORITY],
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    }
};