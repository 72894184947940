import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    paper: {
        position: 'relative',
        //marginTop: '20px',
        padding: '40px',
        paddingBottom: '80px',
        //paddingTop: '15px',
        //paddingLeft: '35px',
        //paddingRight: '35px',
        //paddingBottom: '25px',
    },
    userInformationHeader: {
        marginBottom: '20px',
        paddingLeft: '35px',
        paddingRight: '0px',
    },
    container: {
        marginTop: '20px',
    },
    paperTitle: {
        color: '#428302',
        marginBottom: '15px',
    },
    formContainer: {
        paddingRight: '25px',
        paddingLeft: '25px',
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    button: {
        color: 'white',
        backgroundColor: '#107A98',
        marginLeft: '20px',
        '&:hover': {
            backgroundColor: '#004763!important',
        },
    },
    cancelButton: {
        color: 'black',
        backgroundColor: '#e5e5e5',
        marginLeft: '40px',
        '&:hover': {
            backgroundColor: '#f0f0f0 !important',
        },
    },
    buttonsContainer: {
        position: 'absolute',
        bottom: '20px',
        right: '40px',
        display: 'flex',
        alignItems: 'center',
        marginTop: '40px',
        justifyContent: 'flex-end',
    },
    selectInput: {
        width: '100%',
    },
    gridContainer: {
        paddingRight: '20px',
        paddingLeft: '40px',
    },
    securityProfilesPaper: {
        marginTop: '50px',
        marginBottom: '40px',
    },
    securityProfilesPaperTitleContainer: {
        padding: '20px',
    },
    sidebarTitle: {
        color: '#107A98',
        cursor: 'pointer',
    },
    breadCrumb: {
        // marginTop: '10px',
        paddingTop: '10px',
    },
}));
