import React, { Fragment, FunctionComponent } from 'react';
import styles from './ReferralActivities.Styles';
import { Accordion, AccordionDetails, Grid, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReferralOverviewDataModel } from '../../../../data-models/ReferralOverview.DataModel';
import MaterialTable from '../../../generic-components/material-table/MaterialTable.Component';
import { MaterialTableProps } from 'material-table';
import Moment from 'moment';
import ActivityTypeEntity from '../../../../entities/ActivityType.Entity';
import ActivityDataModel from '../../../../data-models/Activity.DataModel';

type PathParamsType = {
    id: string;
    claimId: string;
};

type ReferralActivitiesProps = {
    referralOverviewDataModel: ReferralOverviewDataModel;
    referralActivities: Array<ActivityDataModel>;
    activityTypes: Array<ActivityTypeEntity>;
} & WithStyles<typeof styles> &
    RouteComponentProps<PathParamsType>;

const ReferralActivitiesComponent: FunctionComponent<ReferralActivitiesProps> = (props: ReferralActivitiesProps) => {
    const [filterState, setFilterState] = React.useState(false);
    const activityTypesOptions = {};
    props.activityTypes?.map((activityType) => {
        const { activityTypeId, title } = activityType;
        activityTypesOptions[activityTypeId] = title;
    });

    const { classes } = props;

    const tableProps: MaterialTableProps<ActivityDataModel> = {
        columns: [
            {
                title: 'Date & Time',
                render: (rowData) => (rowData.date ? Moment(rowData.date).format('MM/DD/YYYY hh:mm') : ''),
            },
            {
                title: 'Activity Type',
                field: 'activityTypeId',
                lookup: activityTypesOptions,
            },
            {
                title: 'User',
                field: 'userName',
                render: (rowData) => (rowData.vendorName !== null ? rowData.vendorName : rowData.userName),
            },
            { title: 'Description', field: 'description' },
        ],
        data: props.referralActivities ? props.referralActivities : [],
        actions: [
            {
                icon: 'tune',
                tooltip: 'Filter',
                isFreeAction: true,
                onClick: () => setFilterState(!filterState),
            },
        ],
        options: {
            filtering: filterState,
            showTitle: true,
            paging: props.referralActivities ? props.referralActivities.length >= 20 : false,
            pageSize: 20,
            toolbar: true,
        },
        title: 'Activity',
    };

    return (
        <Fragment>
            {
                <div className={classes.content}>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Accordion defaultExpanded={true} className={classes.paper} square>
                                    <AccordionDetails className={classes.clientListPaper}>
                                        <Grid container spacing={3} className={classes.gridContainer}>
                                            <MaterialTable tableProps={tableProps} />
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            }
        </Fragment>
    );
};

export const ReferralActivities = withRouter(withStyles(styles)(ReferralActivitiesComponent));
