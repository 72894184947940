import { createStyles, Theme } from '@material-ui/core/styles';

// const drawerWidth = 320;
const drawerWidth = 0;

export default (theme: Theme) => createStyles({
    content: {
        [theme.breakpoints.up('sm')]: {
            paddingRight: drawerWidth,
        },
    },
});