export default class ServiceTypeRequestTranslationEntity {
    serviceTypeRequestTranslationId: number;
    referralId: number;
    appointmentTypeId: number;
    otherAppointmentType: string;
    appointmentDate: string | Date;
    appointmentAddress: string;
    appointmentContactNumber: string;
    languageId: number;
    otherLanguage: string;
    interpretationTypeId: number;
    translationType: string;
    rushRequest: boolean;
    specialInstructions: string;
    appointmentTime: Date | string;
    appointmentLength: string;
    stateId: number;
    city: string;
    zipCode: string;
    addressLine2: string;
    facilityName:string
}
