import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { MaterialTableProps } from 'material-table';
import React, { FunctionComponent, useEffect } from 'react';
import styles from './Reports.Styles';
import MaterialTable from '../../generic-components/material-table/MaterialTable.Component';
import VendorAdminTableDataModel from '../../../data-models/VendorAdminTable.DataModel';
import TenantEntity from '../../../entities/Tenant.Entity';
import UserAdminTableDataModel from '../../../data-models/UserAdminTableDataModel';
import Moment from 'moment';
import ReportDataModel from '../../../data-models/Report.DataModel';
import { Loader } from '../../generic-components/loader/Loader.Component';

export interface ReportFilters {
    tenantId: number;
    vendorId: number;
    adjusterId: number;
    dateRangeFrom: Date;
    dateRangeTo: Date;
}

interface ReportsProps {
    vendors: Array<VendorAdminTableDataModel>;
    tenants: Array<TenantEntity>;
    adjusters: Array<UserAdminTableDataModel>;
    reportResults: Array<ReportDataModel>;
    handleGetReport: (filters: ReportFilters) => void;
    isLoading: boolean;
}

export const Reports: FunctionComponent<ReportsProps> = (props) => {
    const classes = styles();
    const [filter, setFilter] = React.useState<boolean>(false);
    const [reportFilters, setReportFilters] = React.useState<ReportFilters>({
        tenantId: 0,
        vendorId: 0,
        adjusterId: 0,
        dateRangeFrom: null,
        dateRangeTo: null,
    });

    const clientsTableProps: MaterialTableProps<ReportDataModel> = {
        columns: [
            {
                title: 'Adjuster',
                field: 'adjuster',
            },
            {
                title: 'Referral Number',
                field: 'referralNumber',
            },
            {
                title: 'Referral Price',
                render: (rowData) => `$${rowData.referralPrice}`,
            },
            {
                title: 'Date Range',
                render: (rowData) =>
                    `${Moment(rowData.dateFrom).format('YYYY-MM-DD')} - ${Moment(rowData.dateTo).format('YYYY-MM-DD')}`,
            },
            {
                title: 'Service Type',
                field: 'serviceType',
            },
            {
                title: 'State of Jurisdiction',
                field: 'stateId',
            },
        ],
        data: props.reportResults ? props.reportResults : [],
        options: {
            filtering: filter,
            showTitle: true,
            paging: props.vendors ? props.vendors.length > 10 : false,
            toolbar: true,
        },
        style: {
            paddingLeft: '35px',
            paddingRight: '35px',
        },
        title: 'Report Results',
    };

    const handleTextFieldChange = (
        event:
            | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            | React.ChangeEvent<{ name?: string; value: unknown; id?: string }>,
    ) => {
        event.persist();
        let newFilter: ReportFilters = { ...reportFilters };
        newFilter[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;
        setReportFilters(newFilter);
    };

    return (
        <Paper className={classes.paper}>
            <Typography variant="subtitle2" className={classes.paperTitle}>
                Reports
            </Typography>
            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={3}>
                    <FormControl className={classes.selectInput}>
                        <InputLabel id="demo-simple-select-label">Tenant</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="tenantId"
                            name="tenantId"
                            value={reportFilters.tenantId}
                            onChange={(event) => handleTextFieldChange(event)}
                        >
                            {props.tenants?.map((tenant, index) => {
                                return (
                                    <MenuItem key={tenant.tenantId} value={tenant.tenantId}>
                                        {tenant.tenantName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className={classes.selectInput}>
                        <InputLabel id="demo-simple-select-label">Vendor</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="vendorId"
                            name="vendorId"
                            value={reportFilters.vendorId}
                            onChange={(event) => handleTextFieldChange(event)}
                        >
                            {props.vendors?.map((vendor, index) => {
                                return (
                                    <MenuItem key={vendor.vendorId} value={vendor.vendorId}>
                                        {vendor.vendorName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Date Range From"
                        id="dateRangeFrom"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => handleTextFieldChange(event)}
                        value={Moment(reportFilters.dateRangeFrom).format('YYYY-MM-DD')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Date Range To"
                        id="dateRangeTo"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => handleTextFieldChange(event)}
                        value={Moment(reportFilters.dateRangeTo).format('YYYY-MM-DD')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl className={classes.selectInput}>
                        <InputLabel id="demo-simple-select-label">Adjuster</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="adjusterId"
                            name="adjusterId"
                            value={reportFilters.adjusterId}
                            onChange={(event) => handleTextFieldChange(event)}
                        >
                            {props.adjusters?.map((adjuster, index) => {
                                return (
                                    <MenuItem
                                        key={adjuster.userId}
                                        value={adjuster.userId}
                                    >{`${adjuster.firstName} ${adjuster.lastName}`}</MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Button className={classes.button} onClick={() => props.handleGetReport(reportFilters)}>
                    Get Report
                </Button>
            </Grid>
            <div className={classes.tableContainer}>
                {props.isLoading ? <Loader isSmall /> : <MaterialTable tableProps={clientsTableProps} />}
                <Button className={classes.greenButton} onClick={() => props.handleGetReport(reportFilters)}>
                    Export report
                </Button>
            </div>
        </Paper>
    );
};
