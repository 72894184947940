import { useEffect, useState } from "react";
import ReferralEntity from "../../entities/Referral.Entity";
import moment from "moment";
import React from "react";
import { TextField } from "@material-ui/core";

const getDateFormatted = (date: string | Date) => {
    const dateObj = new Date(moment(date).format("MM/DD/YYYY"));
    const month = dateObj.getUTCMonth() + 1;
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();

    return new Date(`${month}/${day}/${year}`);
}

export const useDateFilter = (
        referrals: Array<ReferralEntity>,
        localReferrals: Array<ReferralEntity>,
        setLocalReferrals: (referrals: Array<ReferralEntity>) => void,
        filterProperty: 'dateSubmitted' | 'referralStatusDate' = 'dateSubmitted',
        isAnotherFilterActive: boolean = false
    ) => {
    const [dateFilters, setDateFilters] = useState<{initialDate: string, finalDate: string}>({initialDate: null, finalDate: null});
    const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
    useEffect(() => {
        setLocalReferrals(referrals);
    }, [referrals])
    
    useEffect(() => {
        triggerFilter();
    }, [isAnotherFilterActive])

    const filterReferrals = (referrals: Array<ReferralEntity>) => {
        return referrals.filter(referral => {
            let filterDate;

            if(filterProperty === 'dateSubmitted') {
                filterDate = referral.dateSubmitted
            } 
            else if( filterProperty === 'referralStatusDate') {
                filterDate = referral.referralStatusDate
            }

            if(filterDate == null){
                return null;
            }
            
            if(dateFilters?.initialDate && getDateFormatted(filterDate as string) < getDateFormatted(dateFilters.initialDate))
                return null;
            
            if(dateFilters?.finalDate && getDateFormatted(filterDate as string) > getDateFormatted(dateFilters.finalDate))
                return null;
            
            return referral;
        });

    }

    const triggerFilter = () => {
        if(dateFilters.initialDate === null && dateFilters.finalDate === null) {
            setIsFilterActive(false);
        }

        const filteredReferrals = isAnotherFilterActive ? filterReferrals(localReferrals) : filterReferrals(referrals);

        setIsFilterActive(filteredReferrals.length != referrals.length);

        setLocalReferrals(filteredReferrals);
    }

    const InputFilters = (
        <>
            <TextField
                placeholder="Date From"
                type='date'
                value={dateFilters?.initialDate}
                defaultValue={dateFilters?.initialDate ? moment(dateFilters?.initialDate).format('MM/DD/YYYY') : ''}
                onChange={
                (e) => {
                    const newDateFilters2 = dateFilters;
                    newDateFilters2.initialDate = e.target.value
                    setDateFilters(newDateFilters2);

                    const date = new Date(e.target.value);
                    if(date.getFullYear() > 2020)
                    {
                        triggerFilter();
                    }
                }}
                
                onKeyDown={(e) => {
                    if(e.key === 'Backspace')
                    {
                        const newDateFilters2 = dateFilters;
                        newDateFilters2.initialDate = null;
                        setDateFilters(newDateFilters2);

                        triggerFilter();

                        if(newDateFilters2.finalDate === null)
                        {
                            setIsFilterActive(false);
                        }
                    }
                }}
                onBlur={triggerFilter}
            />
            <TextField
                placeholder="Date To"
                type='date'
                value={dateFilters?.finalDate}
                defaultValue={dateFilters?.finalDate ? moment(dateFilters?.finalDate).format('MM/DD/YYYY') : ''}
                onChange={
                (e) => {
                    const newDateFilters2 = dateFilters;
                    newDateFilters2.finalDate = e.target.value;
                    setDateFilters(newDateFilters2);

                    const date = new Date(e.target.value);
                    if(date.getFullYear() > 2020)
                    {
                        triggerFilter();
                    }
                }}
                onKeyDown={(e) => {
                    if(e.key === 'Backspace')
                    {
                        const newDateFilters2 = dateFilters;
                        newDateFilters2.finalDate = null;
                        setDateFilters(newDateFilters2);

                        triggerFilter();
                        
                        if(newDateFilters2.initialDate === null)
                        {
                            setIsFilterActive(false);
                        }
                    }
                }}
                onBlur={triggerFilter}
            />
        </>
    )

    return {setDateFilters, dateFilters, triggerFilter, InputFilters, isFilterActive};
}