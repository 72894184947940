import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 100;

export default makeStyles((theme: Theme) => ({
    paper:{
        marginTop:'20px',
        //padding:'10px',
        paddingTop: '25px',
        paddingLeft: '35px',
        paddingRight: '35px',
        //paddingBottom: '25px',
    },
    button:{
        color:'white',
        backgroundColor:'#107A98',
        marginLeft:'40px',
        '&:hover': {
            backgroundColor: '#004763!important',
        },
        position:'absolute',
        bottom:'0px',
        right:'0px',
    },
    greenButton:{
        color:'white',
        backgroundColor:'#428302',
        marginLeft:'40px',
        '&:hover': {
            backgroundColor: '#428302!important',
        },
        position:'absolute',
        bottom:'20px',
        right:'80px',
    },
    gridContainer:{
        position:'relative',
        marginBottom:'20px'
    },
    tableContainer:{
        paddingBottom:'80px'
    },
    cancelButton:{
        color:'black',
        backgroundColor:'#e5e5e5',
        marginLeft:'40px',
        '&:hover': {
            backgroundColor: '#f0f0f0 !important',
        },
    },
    buttonsContainer:{
        display:'flex',
        alignItems:'center',
        marginTop:'40px',
        justifyContent:'flex-end'
    },
    selectInput: {
      width:'100%',
    },
    paperTitle: {
        color: '#428302',
        marginBottom:'25px',
        fontSize: '16px',
    },
}));