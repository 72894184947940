import ReferralContactEntity from '../entities/ReferralContact.Entity';

export default class AddressAutocompleteEntity {
    title: string;
    name: string;
    id:string; 
    stateName:string;
    stateCode:string;
    zipCode: string;
    streetNumber: string;
    streetName:string;
    city:string;
    address2: string
    fullAddress:string;
}

export class AddressEntity {
    country : string;
    countryCode : string;
    countryCodeISO3 : string;
    countrySecondarySubdivision : string;
    countrySubdivision : string;
    countrySubdivisionName : string;
    extendedPostalCode : string;
    freeformAddress : string;
    localName : string;
    municipality : string;
    municipalitySubdivision : string;
    postalCode : string;
    streetName : string;
    streetNumber : string;
}
class PositionEntity{
    lat:number;
    long:number;
}
class PoiEntity{
    name:string;
}

export  class AddressResultEntity {
    address : AddressEntity
    id: string;
    position: PositionEntity;
    poi: PoiEntity;
}

export const MakeFullAddress = (refPhysicianState : ReferralContactEntity,stateName:string) =>
`${ refPhysicianState.addressLine1?refPhysicianState.addressLine1+", ":"" } ${refPhysicianState.addressLine2 ? refPhysicianState.addressLine2 + ", " : ""}${refPhysicianState.cityName?refPhysicianState.cityName+", ":""} ${stateName? `${stateName} `:"" }${ refPhysicianState.zipCode? refPhysicianState.zipCode:""}`

