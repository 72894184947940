import React, { FunctionComponent } from 'react';
import { CssBaseline, AppBar, Toolbar, Typography, Box, Menu, MenuItem, Button } from '@material-ui/core';
import styles from './Layout.Styles';
import { ReactComponent as Logo } from "../../../assets/logos/connectLogo.svg";
import { useSelector } from "react-redux";
import { UsersState } from "../../../store/reducers/usersReducer";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";
import {ReactComponent as KeyboardArrowDownIcon} from '../../../assets/svgs/keyboardArrowDownIcon.svg';
import {ReactComponent as LogoutIcon} from '../../../assets/svgs/logoutIcon.svg';

// interface IProps {
//     container: any
// }

export const Layout: FunctionComponent<any> = ({ container, ...other }) => {
    const classes = styles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    //const [mobileOpen, setMobileOpen] = React.useState(false);

    /*const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };*/

    /*const drawer = (
        <div className={classes.drawerContainer}>
            <Hidden xsDown implementation="css">
                <div className={classes.toolbar} />
            </Hidden>
        </div>
    );*/
    const clientName = useSelector<UsersState, UsersState['clientName']>(state => state.clientName);
    const userName = useSelector<UsersState, UsersState['userName']>(state => state.userName);

    const {instance} = useMsal();

    return (
        <>
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar} >
                    {/* TODO: Implement Logo */}
                    <Logo className={classes.logo} />
                    {/* <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton> */}
                    <Button 
                        className={classes.clientNameContainer}
                        onClick={handleClick}
                        id="basic-button"
                        aria-controls="logout-menu"
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Typography className={classes.clientName} >{userName} - {clientName}</Typography>
                        <KeyboardArrowDownIcon />
                    </Button>
                    
                </Toolbar>
            </AppBar>
            {/* <nav className={classes.drawer}>
                //The implementation can be swapped with js to avoid SEO duplication of links.
                <Hidden smUp implementation="css">
                <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                            paper: classes.drawerPaper
                        }}
                        ModalProps={{
                            keepMounted: true // Better open performance on mobile.
                        }}
                        >
                        {drawer}
                        </Drawer>
                        </Hidden>
                        <Hidden xsDown implementation="css">
                        <Drawer
                        classes={{
                            paper: classes.drawerPaper
                        }}
                        variant="permanent"
                        open
                        >
                        {drawer}
                        </Drawer>
                        </Hidden>
                    </nav> */}
            <main className={classes.content} {...other} />
        </div>
            <Menu 
                id='logout-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                className={classes.menu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                //transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <MenuItem onClick={() => instance.logout()} >
                    <Typography style={{marginRight:'10px'}} >
                        Logout 
                    </Typography>

                    <LogoutIcon/>
                </MenuItem>
            </Menu>
        </>
    );
};
