export default class ReferralContactEntity {
    contactId: number;
    contactTypeId: number;
    tenantId: number;
    referralId: number;
    firstName: string;
    middleInitial: string;
    isMiddleInitial: boolean;
    lastName: string;
    email: string;
    addressLine1: string;
    addressLine2: string;
    cityName: string;
    stateId: number;
    zipCode: string;
    zipCodeExtension: string;
    primaryPhoneNumber: string;
    primaryPhoneExtension: string;
    secondaryPhoneNumber: string;
    secondaryPhoneExtension: string;
    fax: string;
    comments: string;
    name: string;
    number: string;
    fein: string;
    location: string;
    isRepresented?: boolean;
    dateOfBirth: Date | string;
    dateOfHire: Date | string;
    gender: string;
    lastFourSSN: string;
    treatingPhysicianNPI: string;
    optionalPhysicianNPI: string;
    firm: string;
    groupNumber: string;
    facilityName: string;
//    stateName:string    
    //locked fields 
    lockedAddressLine1: boolean;
    lockedAddressLine2: boolean;
    lockedCityName: boolean;
    lockedCodeExtension: boolean;
    lockedDateOfBirth: boolean;
    lockedDateOfHire: boolean;
    lockedEmail: boolean;
    lockedFEIN: boolean;
    lockedFax: boolean;
    lockedFirstName: boolean;
    lockedGender: boolean;
    lockedIsRepresented: boolean;
    lockedLastFourSSN: boolean;
    lockedLastName: boolean;
    lockedLocation: boolean;
    lockedMiddleInitial: boolean;
    lockedName: boolean;
    lockedNumber: boolean;
    lockedPrimaryPhoneExtension: boolean;
    lockedPrimaryPhoneNumber: boolean;
    lockedSecondaryPhoneExtension: boolean;
    lockedSecondaryPhoneNumber: boolean;
    lockedStateId: boolean;
    lockedZipCode: boolean;
    lockedFirm: boolean;
    lockedGroupNumber: boolean;
}