import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary, ThemeProvider, TextField } from '@material-ui/core';
import styles, { ptVisitCalendarTheme } from "./PtVisitCalendar.Styles";
import PhysicalTherapyDataModel from '../../../../data-models/PhysicalTherapy.DataModel';
import Moment from 'moment';
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import MedicalServiceDataModel from '../../../../data-models/MedicalService.DataModel';
import ReferralVisitStatusEntity from '../../../../entities/pt/ReferralVisitStatus.Entity';

interface IPtVisitCalendarProps {
    physicalTherapyDataModel: MedicalServiceDataModel,
    referralVisitStatuses : Array<ReferralVisitStatusEntity>;
}

export const PtVisitCalendar: FunctionComponent<IPtVisitCalendarProps> = (props: IPtVisitCalendarProps) => {
    const classes = styles();

    const [evaluationVisit, setEvaluationVisit] = React.useState(null);
    const [treatmentVisits, setTreatmentVisits] = React.useState(null);

    useEffect(()=>{
        setEvaluationVisit(props.physicalTherapyDataModel?.visits?.find(x => x.visitNumber == 0));
        setTreatmentVisits(props.physicalTherapyDataModel?.visits?.filter(x => x.visitNumber > 0))
    },[props])

    const GetVisitStatusName = function (referralVisitStatusId : number)
    {
        const visitStatusItem = props.referralVisitStatuses?.find(element => element.referralVisitStatusId==referralVisitStatusId);
     
        return visitStatusItem?.visitStatusName ?? "-";
    }

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>Visit Calendar</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3} className={classes.gridContainer}>
                    {
                        props.physicalTherapyDataModel?.visits ?
                            <Fragment>
                                <Grid item container xs={12}>
                                    <Grid item xs>
                                        <Typography variant="body2" className={classes.dataLabel}>Evaluation Visit</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{evaluationVisit ? "Yes" : "No"}</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="body2" className={classes.dataLabel}>Schedule date & time</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{evaluationVisit?.scheduleDate ? Moment(evaluationVisit?.scheduleDate).format('MM/DD/YYYY') + ' at ' + Moment(evaluationVisit?.scheduleDate).format('HH:mm'): '-'}</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="body2" className={classes.dataLabel}>Claimant Attendance</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{evaluationVisit?.claimantAttendance == null || evaluationVisit?.claimantAttendance == undefined ? '-' : evaluationVisit?.claimantAttendance ? 'Show' : 'No Show'}</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="body2" className={classes.dataLabel}>Status</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{GetVisitStatusName(evaluationVisit?.referralVisitStatusId??0)}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12}>
                                    <Grid item xs>
                                        <Typography variant="body2" className={classes.dataLabel}>Treatment Visits</Typography>
                                        {
                                            treatmentVisits?.map(x =>
                                                    <Typography variant="subtitle2" className={classes.blackFont}>{x.visitNumber}</Typography>)
                                        }
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="body2" className={classes.dataLabel}>Schedule date & time</Typography>
                                        {
                                            treatmentVisits?.map(x =>
                                                <Typography variant="subtitle2" className={classes.blackFont}>{x.scheduleDate ? Moment(x.scheduleDate).format('MM/DD/YYYY') + ' at ' + Moment(x.scheduleDate).format('HH:mm') : '-'}</Typography>)
                                        }
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="body2" className={classes.dataLabel}>Claimant Attendance</Typography>
                                        {
                                            treatmentVisits?.map(x =>
                                                <Typography variant="subtitle2" className={classes.blackFont}>{x.claimantAttendance == null || x.claimantAttendance == undefined ? '-' : x.claimantAttendance ? 'Show' : 'No Show'}</Typography>)
                                        }
                                    </Grid>
                                    <Grid item xs>
                                        <Typography variant="body2" className={classes.dataLabel}>Status</Typography>
                                        {
                                            treatmentVisits?.map(x =>
                                                <Typography variant="subtitle2" className={classes.blackFont}>{GetVisitStatusName(x.referralVisitStatusId??0)}</Typography>)
                                        }
                                    </Grid>
                                </Grid>
                                <Grid xs={12} item container >
                                    <ThemeProvider theme={ptVisitCalendarTheme}>
                                        <TextField className={classes.summaryTextArea} multiline disabled label="Progress Summary" id="progressSummary" defaultValue={props.physicalTherapyDataModel?.progressSummary ? props.physicalTherapyDataModel?.progressSummary : ''} />
                                    </ThemeProvider>
                                </Grid>
                                <Grid xs={12} item container >
                                    <ThemeProvider theme={ptVisitCalendarTheme}>
                                        <TextField className={classes.summaryTextArea} multiline disabled label="Notes" id="notes" defaultValue={props.physicalTherapyDataModel?.notes ? props.physicalTherapyDataModel?.notes : ''} />
                                    </ThemeProvider>
                                </Grid>
                                <Grid xs={12} item container >
                                    <ThemeProvider theme={ptVisitCalendarTheme}>
                                        <TextField className={classes.summaryTextArea} multiline disabled label="Discharge Notes" id="dischargeNotes" defaultValue={props.physicalTherapyDataModel?.response?.dischargeNote ? props.physicalTherapyDataModel?.response?.dischargeNote : ''} />
                                    </ThemeProvider>
                                </Grid>
                            </Fragment>
                            : <EmptyDataLabel />
                    }

                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}