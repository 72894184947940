import React, { Fragment } from 'react';
import { ClaimSidebar } from '../../components/claim-components/claim-sidebar/ClaimSidebar.Component';
import { NavigationBar } from '../../components/generic-components/navigation-bar/NavigationBar.Component';
import { getClaim, getClaimDocuments } from '../../services/Claim.Service';
import ClaimDataModel from '../../data-models/Claim.DataModel';
import { WithStyles, withStyles, Button, ThemeProvider, Grid } from '@material-ui/core';
import styles, { newTheme } from './AddReferral.Styles';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Loader } from '../../components/generic-components/loader/Loader.Component';
import ServiceDataModel from '../../data-models/Service.DataModel';
import { getServices } from '../../services/Services.Service';
import { ReferralService } from '../../components/referral-components/referral-add/referral-service/ReferralService.Component';
import { ReferralClaim } from '../../components/referral-components/referral-add/referral-claim/ReferralClaim.Component';
import { ReferralClaimant } from '../../components/referral-components/referral-add/referral-claimant/ReferralClaimant.Component';
import { ReferralClient } from '../../components/referral-components/referral-add/referral-client/ReferralClient.Component';
import getVendors from '../../services/Vendors.Service';
import sendErrorLog from '../../services/ErrorLog.Service';
import VendorEntity from '../../entities/Vendor.Entity';
import {
    getReferral,
    getReferralSubmission,
    getReferralClaim,
    getReferralContact,
    getReferralDocument,
    getReferralIme,
    getReferralPhysicalTherapy,
    sendReferralDocuments,
    sendReferralInitialData,
    sendReferralPT,
    sendReferralSubmit,
    getReferralDiagnostic,
    sendReferralDX,
    sendReferralUR,
    getReferralUr,
    getReferralTranslation,
    getReferralTransportation,
    sendReferralTt,
    sendReferralDme,
    getReferralDme,
    GetRefreshedReferralContacts,
} from '../../services/Referrals.Service';
import { toast } from 'react-toastify';
import getStates from '../../services/States.Service';
import getCoverageTypes from '../../services/CoverageTypes.Service';
import CoverageTypeEntity from '../../entities/CoverageType.Entity';
import StateEntity from '../../entities/State.Entity';
import ReferralClaimEntity from '../../entities/ReferralClaim.Entity';
import ReferralContactEntity from '../../entities/ReferralContact.Entity';
import ReferralEntity from '../../entities/Referral.Entity';
import { ReferralEmployer } from '../../components/referral-components/referral-add/referral-employer/ReferralEmployer.Component';
import { ReferralContactTypesEnum } from '../../support/enums/ReferralContactTypesEnum';
import { sendReferralContact } from '../../services/ReferralContacts.Service';
import { ReferralForm } from '../../components/referral-components/referral-add/referral-form/ReferralForm.Component';
import ImeDataModel from '../../data-models/Ime.DataModel';
import ImeEntity from '../../entities/Ime.Entity';
import SpecialtyEntity from '../../entities/Specialty.Entity';
import SpecificBodyPartEntity from '../../entities/SpecificBodyPart.Entity';
import LanguageEntity from '../../entities/Language.Entity';
import getLanguages from '../../services/Languages.Service';
import getSpecialties from '../../services/Specialties.Service';
import getSpecificBodyParts, { getSpecificBodyPartsInjuryTypes } from '../../services/SpecificBodyParts.Service';
import { sendReferralIme } from '../../services/Imes.Service';
import { ReferralDocument } from '../../components/referral-components/referral-add/referral-documents/ReferralDocument.Component';
import ReferralDocumentEntity from '../../entities/ReferralDocument.Entity';
import { ReferralSubmit } from '../../components/referral-components/referral-add/referral-submit/ReferralSubmit.Component';
import ReferralDocumentDataModel from '../../data-models/ReferralDocument.DataModel';
import { WizardBar } from '../../components/generic-components/wizard-bar/WizardBar.Component';
import getDocumentTypes from '../../services/DocumentTypes.Service';
import DocumentTypeEntity from '../../entities/DocumentType.Entity';
import VendorResponseDataModel from '../../data-models/VendorResponse.DataModel';
import VendorErrorEntity from '../../entities/VendorError.Entity';
import CommunicationErrorEntity from '../../entities/CommunicationError.Entity';
import ReferralStatusEntity from '../../entities/ReferralStatus.Entity';
import getReferralStatuses from '../../services/ReferralStatuses.Service';
import { ServicesEnum } from '../../support/enums/Services.Enum';
import ServiceTypeEntity from '../../entities/ServiceType.Entity';
import { ReferralSubmitInternalCodesEnum } from '../../support/enums/ReferralSubmitInternalCodes.Enum';
import { ReferralStatusTypesEnum } from '../../support/enums/ReferralStatusTypesEnum';
import { sendReferralClaim } from '../../services/ReferralClaims.Service';
import InjuryTypeEntity from '../../entities/pt/InjuryType.Entity';
import BodySideEntity from '../../entities/BodySide.Entity';
import getBodySides from '../../services/BodySides.Service';
import IMECoverLetterEntity from '../../entities/IMECoverLetter.Entity';
import MedicalServiceDataModel from '../../data-models/MedicalService.DataModel';
import DiagnosticServiceDataModel from '../../data-models/DiagnosticService.DataModel';
import DiagnosisDataModel from '../../data-models/Diagnosis.DataModel';
import { getDiagnosisByDescription, getDiagnosisById } from '../../services/Diagnosis.Service';
import DiagnosticProcedureDetailDataModel from '../../data-models/DiagnosticProcedureDetail.DataModel';
import getDiagnosticProcedureDetailByServiceTypeId from '../../services/DiagnosticProcedureDetail.Service';
import UtilizationReviewServiceDataModel from '../../data-models/UtilizationReviewService.DataModel';
import TransportationDataModel from '../../data-models/Transportation.DataModel';
import TranslationDataModel from '../../data-models/Translation.DataModel';
import getAppointmentTypes from '../../services/AppointmentType.Service';
import AppointmentTypeEntity from '../../entities/AppointmentType.Entity';
import DestinationEntity from '../../entities/Destination.Entity';
import InterpretationTypeEntity from '../../entities/InterpretationType.Entity';
import OriginationEntity from '../../entities/Origination.Entity';
import TransportationRequiredEntity from '../../entities/TransportationRequired.Entity';
import getDestinations from '../../services/Destination.Service';
import getInterpretationTypes from '../../services/InterpretationType.Service';
import getOriginations from '../../services/Origination.Service';
import getTransportationRequireds from '../../services/TransportationRequired.Service';
import { DocumentTypeIdsEnum } from '../../support/enums/DocumenTypeIds.Enum';
import { deleteServiceTypeRequestPtDetail } from '../../services/ServiceTypeRequestPtDetail.Service';
import { BreadCrumb, Section } from '../../support/custom-hooks/useBreadCrum';
import getWheelchairOrStrecherDetails from '../../services/WheelchairOrStrecherDetail.Service';
import { WheelchairOrStrecherDetailEntity } from '../../entities/WheelchairOrStrecherDetail.Entity';
import getProductCategories from '../../services/ProductCategories.Service';
import DmeServiceDataModel from '../../data-models/dme/DmeService.DataModel';
import ProductCategoryEntity from '../../entities/dme/ProductCategory.Entity';
import { DialogCustom } from "../../components/generic-components/dialog-custom/DialogCustom.Component";
import { Typography } from "@material-ui/core";
import { TtDataModel } from '../../data-models/Tt.DataModel';
import { ReferralClaimantAttorney } from '../../components/referral-components/referral-add/referral-claimant-attorney/ReferralClaimantAttorney.Component';
import { ReferralEmployerAttorney } from '../../components/referral-components/referral-add/referral-employer-attorney/ReferralEmployerAttorney.Component';
import ErrorLogEntity from '../../entities/error-log/ErrorLog.Entity';
import { translationTypes } from '../../support/constants/TranslationTypes.Constants';

interface StatusCardState {
    claim: ClaimDataModel;
    services: Array<ServiceDataModel>;
    vendors: Array<VendorEntity>;
    loading: boolean;
    stepIndex: number;
    selectedServiceTypeId: Array<number>;
    selectedVendorId: number;
    referralId: number;
    referral: ReferralEntity;
    nextStep: boolean;
    addReferralBreadCrumb: Section[];
    showErrorDialog: boolean;
    textErrorDialog: string;

    //Claim Section
    referralClaim: ReferralClaimEntity;
    referralAdjuster: ReferralContactEntity;
    referralClaimant: ReferralContactEntity;
    referralClaimantAttorney: ReferralContactEntity;
    referralClient: ReferralContactEntity;
    referralEmployer: ReferralContactEntity;
    referralEmployerAttorney: ReferralContactEntity;
    claimDocuments: Array<ReferralDocumentEntity>;
    coverageTypes: Array<CoverageTypeEntity>;
    states: Array<StateEntity>;
    specialties: Array<SpecialtyEntity>;
    bodyParts: Array<SpecificBodyPartEntity>;
    languages: Array<LanguageEntity>;
    ime: ImeEntity;
    imeCoverLetter: IMECoverLetterEntity;
    specificBodyParts: Array<number>;
    treatingPhysician: ReferralContactEntity;
    optionalIMEPhysician: ReferralContactEntity;
    currentSpecificBodyParts: Array<SpecificBodyPartEntity>;
    selectedDocuments: Array<ReferralDocumentEntity>;
    documentTypes: Array<DocumentTypeEntity>;
    vendorResponse: VendorResponseDataModel;
    vendorCommunicationError: CommunicationErrorEntity;

    //PT Section
    medicalService: MedicalServiceDataModel;
    referralStatus: Array<ReferralStatusEntity>;
    referralNurseCaseManager: ReferralContactEntity;
    exceptionMessage: string;
    pendingSubmission: boolean;
    submissionFailed: boolean;
    validationError: boolean;
    injuryTypes: Array<InjuryTypeEntity>;
    bodySides: Array<BodySideEntity>;

    //DX Section
    diagnosticService: DiagnosticServiceDataModel;
    diagnosis: DiagnosisDataModel;
    diagnosisFindedArray: Array<DiagnosisDataModel>;
    procedureDetails: Array<DiagnosticProcedureDetailDataModel>;
    loadingDiagnosis: boolean;

    //UR Section
    utilizationReviewService: UtilizationReviewServiceDataModel;

    //TT Section
    transportationService: TransportationDataModel;
    translationService: TranslationDataModel;
    appointmentTypes: Array<AppointmentTypeEntity>;
    destinations: Array<DestinationEntity>;
    interpretationTypes: Array<InterpretationTypeEntity>;
    originations: Array<OriginationEntity>;
    transportationRequireds: Array<TransportationRequiredEntity>;
    wheelchairOrStrecherDetail: Array<WheelchairOrStrecherDetailEntity>;

    //DME Section
    productCategories: Array<ProductCategoryEntity>;
    dmeService: DmeServiceDataModel;
    openCloseStatus: Object;
}

export enum FormTypes {
    'servicetype',
    'client',
    'claim',
    'claimant',
    'employer',
    'referral',
    'documents',
    'submit',
}

type PathParamsType = {
    id: string;
    referralId?: string;
};

type PropsType = {};

const allowedEditStatuses = [
    ReferralStatusTypesEnum.Open,
    ReferralStatusTypesEnum.ReadyForSubmission,
    ReferralStatusTypesEnum.ValidationFailed,
];

type AddReferralProps = WithStyles<typeof styles> & RouteComponentProps<PathParamsType> & PropsType;

function getServiceTypeName(services: ServiceDataModel[], selectedServicesIds: number[]) {
    const serviceNames: string[] = [];
    services?.map((service: ServiceDataModel, _index) => {
        service.serviceTypes?.map((serviceType: ServiceTypeEntity, _jindex) => {
            if (selectedServicesIds?.includes(serviceType.serviceTypeId)) {
                serviceNames.push(serviceType.serviceTypeName);
            }
        });
    });
    return serviceNames;
}
function getServiceTypeAbb(services: ServiceDataModel[], selectedServicesIds: number[]) {
    const serviceNames: string[] = [];
    services?.map((service: ServiceDataModel, _index) => {
        service.serviceTypes?.map((serviceType: ServiceTypeEntity, _jindex) => {
            if (selectedServicesIds?.includes(serviceType.serviceTypeId)) {
                serviceNames.push(serviceType.serviceTypeAbbreviation);
            }
        });
    });
    return serviceNames;
}

function isAbbreviationInArray(abbreviation: string[], array: string[]) {
    return abbreviation.some((element) => array.includes(element));
}

class AddReferralComponent extends React.Component<AddReferralProps, StatusCardState> {
    constructor(props: AddReferralProps) {
        super(props);

        this.state = {
            claim: null,
            loading: false,
            services: [],
            vendors: [],
            selectedServiceTypeId: [0],
            selectedVendorId: 0,
            referralClaim: null,
            referralAdjuster: null,
            referralClaimant: null,
            referralClaimantAttorney: null,
            referralClient: null,
            referralEmployer: null,
            referralEmployerAttorney: null,
            claimDocuments: null,
            coverageTypes: null,
            referral: null,
            states: null,
            referralId: 0,
            stepIndex: this.props.match.params.referralId ? 1 : 0,
            languages: [],
            specialties: [],
            bodyParts: [],
            selectedDocuments: null,
            ime: null,
            imeCoverLetter: null,
            specificBodyParts: [],
            treatingPhysician: null,
            optionalIMEPhysician: null,
            currentSpecificBodyParts: [],
            documentTypes: null,
            vendorResponse: null,
            vendorCommunicationError: null,
            referralStatus: null,
            medicalService: null,
            referralNurseCaseManager: null,
            exceptionMessage: null,
            pendingSubmission: false,
            submissionFailed: false,
            validationError: false,
            injuryTypes: null,
            bodySides: null,
            diagnosticService: null,
            diagnosis: null,
            procedureDetails: [],
            nextStep: false,
            diagnosisFindedArray: [],
            utilizationReviewService: null,
            transportationService: null,
            translationService: null,
            appointmentTypes: null,
            destinations: null,
            interpretationTypes: null,
            originations: null,
            transportationRequireds: null,
            loadingDiagnosis: false,
            wheelchairOrStrecherDetail: [],
            productCategories: [],
            dmeService: null,
            addReferralBreadCrumb: [],
            showErrorDialog: false,
            textErrorDialog: "",
            openCloseStatus: {}
        };
    }

    abortController = new AbortController();

    validationFunction: any = null;
    focusDocuments: any = null;

    componentDidMount() {
        this.setState({
            loading: true,
        });

        getClaim(+this.props.match.params.id, this.abortController.signal)
            .then((claimResult) => {
                this.setState({
                    claim: claimResult,
                });
            })
            .catch((_err) => {
                this.setState({
                    loading: false,
                });
            });

        if (this.state.stepIndex === 0) {
            getServices(this.abortController.signal, true)
                .then((servicesResult) => {
                    getClaim(+this.props.match.params.id, this.abortController.signal).then((claimResult) => {
                        this.setState(() => {
                            return {
                                services: servicesResult,
                                claim: claimResult,
                                addReferralBreadCrumb: [
                                    {
                                        name: 'Claims',
                                        goBack: false,
                                        link: '/claims',
                                        path: this.props.location.pathname,
                                    },
                                    {
                                        name: `${this.state.claim?.claim?.claimNumber}: Referral List`,
                                        goBack: false,
                                        link: `/claims/${this.state.claim?.claim?.claimId}`,
                                        path: this.props.location.pathname,
                                    },
                                    {
                                        name: `Add`,
                                        goBack: false,
                                        link: `/claims/${this.state.claim?.claim?.claimId}/referrals/add`,
                                        path: this.props.location.pathname,
                                    },
                                ],
                                loading: !!this.props.match.params.referralId,
                            };
                        });
                    });

                    if (+this.props.match.params.referralId) {
                        getReferral(+this.props.match.params.referralId, this.abortController.signal)
                            .then((referralResult) => {
                                this.handleRedirectIfNotAllowed(
                                    referralResult.referralStatusId,
                                    referralResult.referralId,
                                );

                                this.setState({
                                    referralId: referralResult.referralId,
                                    referral: referralResult,
                                });
                                //launch vendor search
                                this.handleGetVendors(referralResult.serviceTypeIds);
                                getReferralStatuses(this.abortController.signal)
                                    .then((referralStatusResult) => {
                                        this.setState({
                                            referralStatus: referralStatusResult,
                                            // loading: false,
                                        });
                                        //launch vendor search
                                        this.handleGetVendors(referralResult.serviceTypeIds);
                                    })
                                    .catch((_err) =>
                                        this.setState({
                                            loading: false,
                                        }),
                                    );
                            })
                            .catch((_err) =>
                                this.setState({
                                    loading: false,
                                }),
                            );
                    }
                })
                .catch((_err) =>
                    this.setState({
                        loading: false,
                    }),
                );
        } else if (this.state.stepIndex === 1 && +this.props.match.params.referralId) {
            this.setState((_prevState) => {
                return {
                    loading: true,
                };
            });

            getServices(this.abortController.signal)
                .then((servicesResult) => {
                    this.setState({
                        services: servicesResult,
                        loading: !!this.props.match.params.referralId,
                    });
                    if (+this.props.match.params.referralId) {
                        getReferral(+this.props.match.params.referralId, this.abortController.signal)
                            .then(async (referralResult) => {
                                this.handleRedirectIfNotAllowed(
                                    referralResult.referralStatusId,
                                    referralResult.referralId,
                                );
                                getClaim(+this.props.match.params.id, this.abortController.signal).then(
                                    (claimResult) => {
                                        this.setState(() => {
                                            return {
                                                referralId: referralResult.referralId,
                                                referral: referralResult,
                                                claim: claimResult,
                                                addReferralBreadCrumb: [
                                                    {
                                                        name: 'Claims',
                                                        goBack: false,
                                                        link: '/claims',
                                                        path: this.props.location.pathname,
                                                    },
                                                    {
                                                        name: `${this.state.claim?.claim?.claimNumber}: Referral List`,
                                                        goBack: false,
                                                        link: `/claims/${this.state.claim?.claim?.claimId}`,
                                                        path: this.props.location.pathname,
                                                    },
                                                    {
                                                        name: `${this.props.match.params.referralId}: Edit`,
                                                        goBack: false,
                                                        link: `/claims/${this.state.claim?.claim?.claimId}/referrals/edit/${this.props.match.params.referralId}`,
                                                        path: this.props.location.pathname,
                                                    },
                                                ],
                                                // loading: false,
                                            };
                                        });
                                    },
                                );
                                // this.setState({
                                //     referralId: referralResult.referralId,
                                //     referral: referralResult,
                                // });
                                //launch vendor search
                                this.handleGetVendors(referralResult.serviceTypeIds);
                                //await this.loadClaimAdjuster(false);
                                await this.loadFullPage(false);
                                if (
                                    referralResult.referralStatusId === ReferralStatusTypesEnum.SubmissionFailed ||
                                    referralResult.referralStatusId === ReferralStatusTypesEnum.ValidationFailed ||
                                    referralResult.referralStatusId === ReferralStatusTypesEnum.ReadyForSubmission
                                ) {
                                    await this.loadClaimant(false);
                                    await this.loadClient(false);
                                    await this.loadEmployer(false);
                                    await this.loadReferral(false);
                                    await this.loadDocuments(false);
                                    await this.loadReferralSubmission(false);
                                    this.setStepIndex(2);
                                }

                                this.setState((_prevState) => {
                                    return {
                                        loading: false,
                                    };
                                });
                            })
                            .catch((_err) =>
                                this.setState({
                                    loading: false,
                                }),
                            );
                    } else {
                        getClaim(+this.props.match.params.id, this.abortController.signal)
                            .then((claimResult) => {
                                this.setState(() => {
                                    return {
                                        claim: claimResult,
                                        addReferralBreadCrumb: [
                                            {
                                                name: 'Claims',
                                                goBack: false,
                                                link: '/claims',
                                                path: this.props.location.pathname,
                                            },
                                            {
                                                name: `${this.state.claim?.claim?.claimNumber}: Referral List`,
                                                goBack: false,
                                                link: `/claims/${this.state.claim?.claim?.claimId}`,
                                                path: this.props.location.pathname,
                                            },
                                            {
                                                name: `Add`,
                                                goBack: false,
                                                link: `/claims/${this.state.claim?.claim?.claimId}/referrals/add`,
                                                path: this.props.location.pathname,
                                            },
                                        ],
                                        loading: false,
                                    };
                                });
                            })
                            .catch((err) =>
                                this.setState({
                                    loading: false,
                                }),
                            );
                    }
                })
                .catch((_err) =>
                    this.setState({
                        loading: false,
                    }),
                );
        }
    }

    componentWillUnmount() {
        this.abortController.abort();
    }    

    handleRedirectIfNotAllowed = (referralStatus: ReferralStatusTypesEnum, referralId: number) => {
        if (!allowedEditStatuses.includes(referralStatus)) {
            this.abortController.abort();
            this.props.history.push(`/claims/${this.state?.claim?.claim?.claimId}/referrals/${referralId}`);
        }
    };

    handleGetVendors = async (selectedServiceTypeId: number[]) => {
        if (selectedServiceTypeId.length > 0) {
            await getVendors(this.state.claim?.claim?.claimId, selectedServiceTypeId[0], this.abortController.signal)
                .then((vendorsResult) => {
                    this.setState({
                        selectedServiceTypeId: selectedServiceTypeId,
                        vendors: vendorsResult,
                    });
                })
                .catch((_err) => this.setState({}));
        } else {
            this.setState({
                vendors: [],
                selectedVendorId: null,
            });
        }
    };

    handleSelectVendor = (selectedVendorId: number) => {
        this.setState({
            selectedVendorId: selectedVendorId,
        });
    };

    postReferralInitialData = (claimId: number, selectedServiceTypeIds: Array<number>, selectedVendorId: number) => {
        this.setState({
            loading: true,
        });
        sendReferralInitialData(claimId, selectedServiceTypeIds, selectedVendorId, this.abortController.signal)
            .then((referralResult) => {
                getReferral(referralResult.referralId, this.abortController.signal)
                    .then((newReferralResult) => {
                        this.handleRedirectIfNotAllowed(
                            newReferralResult.referralStatusId,
                            newReferralResult.referralId,
                        );

                        this.setState({
                            referralId: referralResult.referralId,
                            referral: newReferralResult,
                            loading: false,
                        });

                        //this.loadClaimAdjuster();
                        this.loadFullPage();
                        this.setStepIndex(this.state.stepIndex + 1);
                    })
                    .catch((_err) =>
                        this.setState({
                            loading: false,
                        }),
                    );
            })
            .catch((_err) =>
                this.setState({
                    loading: false,
                }),
            );
    };

    handleClaimEdit = (edited: ReferralClaimEntity) => {
        this.setState({
            referralClaim: edited,
        });
    };

    handleAdjusterEdit = (edited: ReferralContactEntity) => {
        this.setState({
            referralAdjuster: edited,
        });
    };

    handleNurseCaseManagerEdit = (edited: ReferralContactEntity) => {
        this.setState({
            referralNurseCaseManager: edited,
        });
    };

    handleClaimantEdit = (edited: ReferralContactEntity) => {
        this.setState({
            referralClaimant: edited,
        });
    };

    handleClaimantAttorneyEdit = (edited: ReferralContactEntity) => {
        this.setState({
            referralClaimantAttorney: edited,
        });
    };

    handleClientEdit = (edited: ReferralContactEntity) => {
        this.setState({
            referralClient: edited,
        });
    };

    handleEmployerEdit = (edited: ReferralContactEntity) => {
        this.setState({
            referralEmployer: edited,
        });
    };

    handleEmployerAttorneyEdit = (edited: ReferralContactEntity) => {
        this.setState({
            referralEmployerAttorney: edited,
        });
    };

    handleImeEdit = (edited: ImeEntity) => {
        this.setState({
            ime: edited,
        });
    };

    handleSpecificBodyPart = async (sbpId: number) => {
        if (sbpId) {
            const injuryTypesResult = await getSpecificBodyPartsInjuryTypes(sbpId, this.abortController.signal).catch();
            this.setState((_prevState) => {
                return {
                    injuryTypes: injuryTypesResult,
                };
            });
        } else {
            this.setState((_prevState) => {
                return {
                    injuryTypes: null,
                };
            });
        }
    };

    handleMedicalServiceEdit = async (edited: MedicalServiceDataModel) => {
        this.setState((_prevState) => {
            return {
                medicalService: edited,
            };
        });
    };

    handleDiagnosticServiceEdit = async (edited: DiagnosticServiceDataModel) => {
        this.setState((_prevState) => {
            return {
                diagnosticService: edited,
            };
        });
    };

    handleUtilizationReviewServiceEdit = async (edited: UtilizationReviewServiceDataModel) => {
        this.setState((_prevState) => {
            return {
                utilizationReviewService: edited,
            };
        });
    };

    handleTransportationServiceEdit = async (edited: TransportationDataModel) => {
        this.setState((_prevState) => {
            return {
                transportationService: edited,
            };
        });
    };

    handleTranslationServiceEdit = async (edited: TranslationDataModel) => {
        this.setState((_prevState) => {
            return {
                translationService: edited,
            };
        });
    };

    handleDmeServiceEdit = async (edited: DmeServiceDataModel) => {
        this.setState(() => {
            return {
                dmeService: edited,
            };
        });
    };

    handleSpecificBodyPartsEdit = (edited: Array<number>) => {
        const selectedBodyParts = edited.map((x) => this.state.bodyParts.find((y) => x === y.specificBodyPartId));
        this.setState({
            specificBodyParts: edited,
            currentSpecificBodyParts: selectedBodyParts,
        });
    };

    handleClearInjuryTypes = () => {
        this.setState({
            injuryTypes: [],
        });
    };

    handleOptionalImePhysicianEdit = (edited: ReferralContactEntity) => {
        this.setState({
            optionalIMEPhysician: edited,
        });
    };

    handleTreatingPhysicianEdit = (edited: ReferralContactEntity) => {
        this.setState({
            treatingPhysician: edited,
        });
    };

    handleImeCoverLetterEdit = (edited: IMECoverLetterEntity) => {
        this.setState({
            imeCoverLetter: edited,
        });
    };

    handleGetDocuments = (documentTypeNumbers: Array<string>, initialDate: Date, endDate: Date, referralId: number) => {
        this.setState({
            loading: true,
        });
        getClaimDocuments(
            +this.props.match.params.id,
            documentTypeNumbers,
            initialDate,
            endDate,
            referralId,
            this.abortController.signal,
        )
            .then((claimDocumentsResult) => {
                this.setState({
                    claimDocuments: claimDocumentsResult,
                    loading: false,
                });
                setTimeout(() => {
                    this.focusDocuments('searchButton');
                }, 400);            
            })
            .catch((_err) =>
                this.setState({
                    loading: false,
                }),
            );
    };

    handleSelectedDocumentsEdit = (edited: Array<ReferralDocumentEntity>) => {
        this.setState({
            selectedDocuments: edited,
        });
    };

    setStepIndex(newStepIndex: number) {
        this.setState((_prevState) => {
            this.validationFunction = null;
            return { stepIndex: newStepIndex };
        });
    }

    handleNextStep6 = () => {
        this.setState({
            nextStep: true,
        });
    };

    searchDiagnosisByCodeDescription = (description: string) => {
        if (description != '') {
            this.setState({
                loadingDiagnosis: true,
                loading: false,
            });
        }
        getDiagnosisByDescription(description, this.abortController.signal)
            .then(async (result) => {
                this.setState({
                    diagnosisFindedArray: result,
                    loading: false,
                    loadingDiagnosis: false,
                });
            })
            .catch((_err) =>
                this.setState({
                    loading: false,
                }),
            );
    };

    getDiagnosisById(diagnosisId) {
        let diagnosis;
        getDiagnosisById(diagnosisId, this.abortController?.signal).then((result) => {
            diagnosis = result;
        });
        return diagnosis;
    }

    getDxProcedureDetailsByServiceTypeId = (serviceTypeId: number) => {
        getDiagnosticProcedureDetailByServiceTypeId(serviceTypeId, this.abortController.signal)
            .then(async (procedureDetailsResult) => {
                this.setState((_prevState) => {
                    return {
                        procedureDetails: procedureDetailsResult,
                        loading: false,
                    };
                });
            })
            .catch((_err) => {
                this.setState((_prevState) => {
                    return { loading: false };
                });
            });
    };

    getStepContent(stepIndex: number): React.ReactNode {
        let serviceTypes = [];

        switch (stepIndex) {
            case 0:
                return this.state.referralId ? (
                    <div className={this.props.classes.controlsDisabled}>
                        <ReferralService
                            services={this.state.services}
                            vendors={this.state.vendors}
                            handleSelectServiceType={this.handleGetVendors}
                            handleSelectVendor={this.handleSelectVendor}
                            serviceTypeId={this.state.referral?.serviceTypeIds}
                            vendorId={this.state.referral?.vendorId}
                        />
                    </div>
                ) : (
                    <ReferralService
                        services={this.state.services}
                        vendors={this.state.vendors}
                        handleSelectServiceType={this.handleGetVendors}
                        handleSelectVendor={this.handleSelectVendor}
                        serviceTypeId={this.state.referral?.serviceTypeIds}
                        vendorId={this.state.referral?.vendorId}
                    />
                );
            case 1:
                serviceTypes = [];
                //let servicesFound
                this.state.services
                    .find((sId) => sId.service?.serviceId == this.state.referral?.serviceId)?.serviceTypes?.forEach((st) => {
                        const serviceType = this.state.selectedServiceTypeId.find((stId) => stId == st.serviceTypeId);
                        if (serviceType) {
                            serviceTypes?.push(st);
                        }
                    });

                return (
                    <>
                        <ReferralForm
                            serviceId={this.state.referral?.serviceId}
                            referralId={this.state.referralId}
                            states={this.state.states}
                            specialties={this.state.specialties}
                            languages={this.state.languages}
                            bodyParts={this.state.bodyParts}
                            ime={this.state.ime}
                            specificBodyParts={this.state.specificBodyParts}
                            treatingPhysician={this.state.treatingPhysician}
                            optionalImePhysician={this.state.optionalIMEPhysician}
                            handleImeBodyPartsEdit={this.handleSpecificBodyPartsEdit}
                            handleImeEdit={this.handleImeEdit}
                            handleOptionalImePhysicianEdit={this.handleOptionalImePhysicianEdit}
                            handleTreatingPhysicianEdit={this.handleTreatingPhysicianEdit}
                            medicalService={this.state.medicalService}
                            handleMedicalServiceEdit={this.handleMedicalServiceEdit}
                            bodySides={this.state.bodySides}
                            injuryTypes={this.state.injuryTypes}
                            handleSetValidationFunction={this.setValidationFunction.bind(this)}
                            coverLetterState={this.state.imeCoverLetter}
                            handleImeCoverLetterEdit={this.handleImeCoverLetterEdit}
                            serviceTypes={serviceTypes}
                            getMedicalService={this.getPhysicalTheraphy}
                            procedureDetails={this.state.procedureDetails}
                            diagnosticService={this.state.diagnosticService}
                            handleDiagnosticServiceEdit={this.handleDiagnosticServiceEdit}
                            nextStep={this.state.nextStep}
                            searchDiagnosisByCodeDescription={this.searchDiagnosisByCodeDescription}
                            diagnosisFindedArray={this.state.diagnosisFindedArray}
                            getDxSpecificBodyParts={this.getDxSpecificBodyParts}
                            handleSpecificBodyPart={this.handleSpecificBodyPart}
                            handleUtilizationReviewServiceEdit={this.handleUtilizationReviewServiceEdit}
                            utilizationReviewService={this.state.utilizationReviewService}
                            getDxProcedureDetailsByServiceTypeId={this.getDxProcedureDetailsByServiceTypeId}
                            transportationService={this.state.transportationService}
                            translationService={this.state.translationService}
                            handleTransportationServiceEdit={this.handleTransportationServiceEdit}
                            handleTranslationServiceEdit={this.handleTranslationServiceEdit}
                            appointmentTypes={this.state.appointmentTypes}
                            destinations={this.state.destinations}
                            interpretationTypes={this.state.interpretationTypes}
                            originations={this.state.originations}
                            transportationRequireds={this.state.transportationRequireds}
                            deleteServiceTypeRequestPtDetail={this.deleteServiceTypePtRequestDetail}
                            loadingDiagnosis={this.state.loadingDiagnosis}
                            handleClearInjuryTypes={this.handleClearInjuryTypes}
                            wheelchairOrStrecherDetail={this.state.wheelchairOrStrecherDetail}
                            dmeService={this.state.dmeService}
                            productCategories={this.state.productCategories}
                            handleDmeServiceEdit={this.handleDmeServiceEdit}
                            handleSetIsValidImeFunction={this.setIsValidFunction.bind(this)}
                            handleSetIsValidPtFunction={this.setIsValidFunction.bind(this)}
                            handleSetIsValidDxFunction={this.setIsValidFunction.bind(this)}
                            handleSetIsValidDmeFunction={this.setIsValidFunction.bind(this)}
                            handleSetIsValidUrFunction={this.setIsValidFunction.bind(this)}
                            handleSetIsValidTtFunction={this.setIsValidFunction.bind(this)}

                            openCloseStatus={this.state.openCloseStatus}
                            handleOpenCloseStatus={this.handleOpenCloseStatus.bind(this)}
                            
                            handleUpdateIme={this.handleUpdateIme}
                            handleUpdateTreatingPhysician={this.handleUpdateTreatingPhysician}
                            handleUpdateOptionalImePhysician={this.handleUpdateOptionalImePhysician}
                            handleUpdateImeCoverLetter={this.handleUpdateImeCoverLetter}
                        />
                        <ReferralDocument
                            referralId={this.state.referralId}
                            handleGetDocuments={this.handleGetDocuments}
                            documents={this.state.claimDocuments}
                            selectedDocuments={this.state.selectedDocuments}
                            handleSelectedDocumentsEdit={this.handleSelectedDocumentsEdit}
                            documentTypes={this.state.documentTypes}
                            serviceId={this.state.referral?.serviceId}
                            handleSetValidationFunction={this.setValidationFunction.bind(this)}
                            handleSetIsValidDocumentsFunction={this.setValidationFunction.bind(this)}
                            openCloseStatus={this.state.openCloseStatus}
                            handleOpenCloseStatus={this.handleOpenCloseStatus.bind(this)}
                            handleUpdateDocuments={this.handleUpdateDocuments}
                            handleSetFocusDocuments={this.setFocusDocumentsFunction.bind(this)}
                        />
                        <ReferralClaimantAttorney
                            referralId={this.state.referralId}
                            states={this.state.states}                            
                            referralClaimantAttorney={this.state.referralClaimantAttorney}                            
                            handleClaimantAttorneyEdit={this.handleClaimantAttorneyEdit}
                            handleSetIsValidClaimantAttorneyFunction={this.setIsValidFunction.bind(this)}
                            openCloseStatus={this.state.openCloseStatus}
                            handleOpenCloseStatus={this.handleOpenCloseStatus.bind(this)}
                            serviceId={this.state.referral?.serviceId}                            
                            handleUpdateClaimantAttorney={this.handleUpdateClaimantAttorney}
                        />
                        <ReferralEmployerAttorney
                           referralId={this.state.referralId}
                           states={this.state.states}
                           referralEmployer={this.state.referralEmployer}
                           referralEmployerAttorney={this.state.referralEmployerAttorney}
                           handleEmployerAttorneyEdit={this.handleEmployerAttorneyEdit}
                           openCloseStatus={this.state.openCloseStatus}
                           handleOpenCloseStatus={this.handleOpenCloseStatus.bind(this)}
                           serviceId={this.state.referral?.serviceId}
                           handleUpdateEmployerAttorney={this.handleUpdateEmployerAttorney}
                        />
                        <ReferralClaim
                            referralId={this.state.referralId}
                            states={this.state.states}
                            coverageTypes={this.state.coverageTypes}
                            referralClaim={this.state.referralClaim}
                            referralAdjuster={this.state.referralAdjuster}
                            referralNurseCaseManager={this.state.referralNurseCaseManager}
                            handleNurseCaseManagerEdit={this.handleNurseCaseManagerEdit}
                            handleClaimEdit={this.handleClaimEdit}
                            handleAdjusterEdit={this.handleAdjusterEdit}
                            handleSetValidationFunction={this.setValidationFunction.bind(this)}
                            handleSetIsValidFunction={this.setIsValidFunction.bind(this)}
                            handleSetIsValidClaimFunction={this.setIsValidFunction.bind(this)}
                            openCloseStatus={this.state.openCloseStatus}
                            handleOpenCloseStatus={this.handleOpenCloseStatus.bind(this)}
                            serviceId={this.state.referral?.serviceId}
                            handleUpdateAdjuster={this.handleUpdateAdjuster}
                            handleUpdateClaim={this.handleUpdateClaim}
                        />
                        <ReferralClaimant
                            referralId={this.state.referralId}
                            states={this.state.states}
                            referralClaimant={this.state.referralClaimant}                            
                            handleClaimantEdit={this.handleClaimantEdit}                            
                            handleSetValidationFunction={this.setValidationFunction.bind(this)}
                            handleSetIsValidClaimantFunction={this.setIsValidFunction.bind(this)}                            
                            openCloseStatus={this.state.openCloseStatus}
                            handleOpenCloseStatus={this.handleOpenCloseStatus.bind(this)}
                            serviceId={this.state.referral?.serviceId}
                            handleUpdateClaimant={this.handleUpdateClaimant}                            
                        />
                        
                        <ReferralClient
                            referralId={this.state.referralId}
                            states={this.state.states}
                            referralClient={this.state.referralClient}
                            handleClientEdit={this.handleClientEdit}
                            serviceId={this.state.referral?.serviceId}
                            handleUpdateClient={this.handleUpdateClient}
                            openCloseStatus={this.state.openCloseStatus}
                            handleOpenCloseStatus={this.handleOpenCloseStatus.bind(this)}
                        />
                        <ReferralEmployer
                            referralId={this.state.referralId}
                            states={this.state.states}
                            referralEmployer={this.state.referralEmployer}
                            handleEmployerEdit={this.handleEmployerEdit}                                                        
                            handleSetValidationFunction={this.setValidationFunction.bind(this)}
                            handleSetIsValidEmployerFunction={this.setIsValidFunction.bind(this)}
                            openCloseStatus={this.state.openCloseStatus}
                            handleOpenCloseStatus={this.handleOpenCloseStatus.bind(this)}
                            serviceId={this.state.referral?.serviceId}
                            handleUpdateEmployer={this.handleUpdateEmployer}                            
                        />
                        <Grid item xs={12}>
                            {/* Empty space */<div>&nbsp;</div>}                                
                        </Grid>
                    </>
                );
            case 2:
                const selectedServiceNames: string[] = [];
                this.state.services.forEach((service, _index) => {
                    service.serviceTypes.forEach((servicetype, _sindex) => {
                        if (this.state.selectedServiceTypeId.some((stId) => stId == servicetype.serviceTypeId)) {
                            selectedServiceNames.push(servicetype.serviceTypeName);
                        }
                    });
                });
                let vendorName;
                let vendor;
                this.state.vendors.forEach((vendor, _index) => {
                    if (vendor.vendorId == this.state.referral?.vendorId) {
                        vendorName = vendor.vendorName;
                        vendor = vendor;
                    }
                });

                serviceTypes = [];
                this.state.services
                    .find((sId) => sId.service?.serviceId == this.state.referral?.serviceId)
                    .serviceTypes.forEach((st) => {
                        const serviceType = this.state.selectedServiceTypeId.find((stId) => stId == st.serviceTypeId);
                        if (serviceType) {
                            serviceTypes.push(st);
                        }
                    });

                return (
                    <ReferralSubmit
                        referralId={this.state.referralId}
                        referral={this.state.referral}
                        referralClaimant={this.state.referralClaimant}
                        referralClient={this.state.referralClient}
                        referralClaim={this.state.referralClaim}
                        referralDocuments={this.state.selectedDocuments}
                        vendorName={vendorName}
                        selectedServiceNames={selectedServiceNames}
                        vendorResponse={this.state.vendorResponse}
                        comunnicationError={this.state.vendorCommunicationError}
                        exceptionMessage={this.state.exceptionMessage}
                        showInfo={this.state.pendingSubmission || this.state.submissionFailed}
                        redAlert={this.state.submissionFailed}
                        validationError={this.state.validationError}
                        ime={this.state.ime}
                        referralOverviewDataModel={{
                            claim: this.state.claim.claim,
                            claimCoverageType: this.state.claim.coverageType,
                            claimState: this.state.claim.stateOfJurisdiction,
                            claimClient: this.state.claim.client,
                            client: this.state.referralClient,
                            claimant: this.state.referralClaimant,
                            referral: this.state.referral,
                            referralServiceType: serviceTypes,
                            referralVendor: vendor, //new VendorEntity(),//vendors.find( v => v.vendorId = this.state.selectedVendorId ),
                            referralStatus: null,
                            referralSchedule: null,
                        }}
                        specialties={this.state.specialties}
                        languages={this.state.languages}
                        states={this.state.states}
                        treatingPhysician={this.state.treatingPhysician}
                        recommendedPhysician={this.state.optionalIMEPhysician}
                        specificBodyParts={this.state.specificBodyParts}
                        bodyParts={this.state.bodyParts}
                        imeCoverLetter={this.state.imeCoverLetter}
                        serviceId={this.state.referral?.serviceId}
                        medicalService={this.state.medicalService}
                        diagnosticService={this.state.diagnosticService}
                        bodySides={this.state.bodySides}
                        injuryTypes={this.state.injuryTypes}
                        procedureDetails={this.state.procedureDetails}
                        utilizationReviewService={this.state.utilizationReviewService}
                        transportationService={this.state.transportationService}
                        translationService={this.state.translationService}
                        appointmentTypes={this.state.appointmentTypes}
                        destinations={this.state.destinations}
                        interpretationTypes={this.state.interpretationTypes}
                        originations={this.state.originations}
                        transportationRequireds={this.state.transportationRequireds}
                        serviceTypeAbbreviations={getServiceTypeAbb(
                            this.state.services,
                            this.state.referral?.serviceTypeIds,
                        )}
                        wheelchairOrStretcherDetails={this.state.wheelchairOrStrecherDetail}
                        //DME
                        dmeService={this.state.dmeService}
                    />
                );
            default:
                return '';
        }
    }

    loadFullPage = async (isLoadingHandledInternally = true) => {        
        if (this.state.referralClaim)
            return;

        let statesResult = null;
        let coverageTypesResult = null;
        let claimResult = null;
        let adjusterResult = null;
        let claimantResult = null;
        let claimantAttorneyResult = null;
        let clientResult = null;
        let employerResult = null;
        let employerAttorneyResult = null;
        let nurseCaseManagerResult = null;

        let imeResult: ImeDataModel = null;
        let selectedBodyParts: Array<number> = null;
        let treatingPhysicianResult = null;
        let optionalPhysicianResult = null;
        let languagesResult = null;
        let specialtiesResult = null;
        let bodySidesResult = null;
        let specificBodyPartsResult = null;

        let ptResult = null;
        let dxResult = null;
        let urResult = null;

        let appointmentTypesResult = null; // TtResult
        let interpretationTypesResult = null;
        let translation = null;
        let transportationRequiredResult = null;
        let originationResult = null;
        let wheelchairOrStrecherDetailResult = null;
        let destinationResult = null;
        let transportation = null;

        let dmeResult = null;
        let productCategories = null;

        let documentTypesResult = null;
        let referralSelectedDocumentsResult = null;

        if (isLoadingHandledInternally)
            this.setState({ loading: true, });
        
        try {
            [statesResult, coverageTypesResult, claimResult, adjusterResult, claimantResult, claimantAttorneyResult, employerResult, employerAttorneyResult, clientResult] =
                await Promise.all([
                    await getStates( this.abortController.signal ),
                    await getCoverageTypes( this.abortController.signal ),
                    await getReferralClaim( this.state.referralId, this.abortController.signal ),            
                    await getReferralContact( this.state.referralId, ReferralContactTypesEnum.Adjuster, this.abortController.signal ),
                    await getReferralContact( this.state.referralId, ReferralContactTypesEnum.Claimant, this.abortController.signal ),
                    await getReferralContact( this.state.referralId, ReferralContactTypesEnum.ClaimantAttorney, this.abortController.signal ),
                    await getReferralContact( this.state.referralId, ReferralContactTypesEnum.Employer, this.abortController.signal ),
                    await getReferralContact( this.state.referralId, ReferralContactTypesEnum.EmployerAttorney, this.abortController.signal ),
                    await getReferralContact( this.state.referralId, ReferralContactTypesEnum.Client, this.abortController.signal ),
                ]);
            
            if (this.state.referral.serviceId === ServicesEnum.IME && !this.state.ime) {
                [imeResult, treatingPhysicianResult, optionalPhysicianResult, languagesResult, specialtiesResult, bodySidesResult, specificBodyPartsResult] =
                    await Promise.all([
                        await getReferralIme(this.state.referralId, this.abortController.signal),
                        await getReferralContact( this.state.referralId, ReferralContactTypesEnum.TreatingPhysician, this.abortController.signal ),
                        await getReferralContact( this.state.referralId, ReferralContactTypesEnum.OptionalPhysician, this.abortController.signal ),
                        await getLanguages(this.abortController.signal ),
                        await getSpecialties(this.abortController.signal ),
                        await getBodySides(this.abortController.signal ),
                        await getSpecificBodyParts( this.state.referral.serviceId, this.state.referral.serviceTypeIds[0], this.abortController.signal ),
                    ]);
                selectedBodyParts = imeResult.specificBodyParts?.map( (x: SpecificBodyPartEntity): number => x.specificBodyPartId );
            }
            
            if ( this.state.referral.serviceId === ServicesEnum.PT && !this.state.medicalService ) {
                [ptResult, specificBodyPartsResult, bodySidesResult, languagesResult, nurseCaseManagerResult] = 
                    await Promise.all([
                        await getReferralPhysicalTherapy(this.state.referralId, this.abortController.signal ),
                        await getSpecificBodyParts( this.state.referral.serviceId, this.state.referral.serviceTypeIds[0], this.abortController.signal ),
                        await getBodySides(this.abortController.signal ),
                        await getLanguages(this.abortController.signal ),
                        await getReferralContact( this.state.referralId, ReferralContactTypesEnum.NurseCaseManager, this.abortController.signal ),
                    ]);
            }

            if (this.state.referral.serviceId === ServicesEnum.Diagnostic && !this.state.diagnosticService) {
                [dxResult, specificBodyPartsResult, bodySidesResult, languagesResult, nurseCaseManagerResult] = 
                    await Promise.all([
                        await getReferralDiagnostic(this.state.referralId, this.abortController.signal),
                        await getSpecificBodyParts( this.state.referral.serviceId, this.state.referral.serviceTypeIds[0], this.abortController.signal ),
                        await getBodySides(this.abortController.signal ),
                        await getLanguages(this.abortController.signal ),
                        await getReferralContact( this.state.referralId, ReferralContactTypesEnum.NurseCaseManager, this.abortController.signal ),
                    ]);
            }

            if (this.state.referral.serviceId === ServicesEnum.UR && !this.state.utilizationReviewService) {
                [urResult, nurseCaseManagerResult] = 
                    await Promise.all([
                        await getReferralUr(this.state.referralId, this.abortController.signal),
                        await getReferralContact( this.state.referralId, ReferralContactTypesEnum.NurseCaseManager, this.abortController.signal ),
                    ]);
            }

            if (this.state.referral.serviceId === ServicesEnum.DME && !this.state.dmeService) {
                [dmeResult, productCategories, nurseCaseManagerResult] =
                    await Promise.all([
                        await getReferralDme(this.state.referralId, this.abortController.signal),
                        await getProductCategories(this.abortController.signal),
                        await getReferralContact( this.state.referralId, ReferralContactTypesEnum.NurseCaseManager, this.abortController.signal ),
                    ]);
            }
            
            if (this.state.referral.serviceId === ServicesEnum.TT) {
                const STAbb = getServiceTypeAbb(this.state.services, this.state.referral?.serviceTypeIds);
                
                if (isAbbreviationInArray(STAbb, translationTypes) && !this.state.translationService) {
                    [appointmentTypesResult, interpretationTypesResult, translation, languagesResult] =
                        await Promise.all([
                            await getAppointmentTypes(this.abortController.signal),
                            await getInterpretationTypes(this.abortController.signal),
                            await getReferralTranslation(this.state.referralId, this.abortController.signal),
                            await getLanguages(this.abortController.signal )
                        ]);
                }
                
                if ( STAbb.includes('TP') && !this.state.transportationService ) {
                    [transportationRequiredResult, originationResult, wheelchairOrStrecherDetailResult, destinationResult, transportation] =
                        await Promise.all([
                            await getTransportationRequireds(this.abortController.signal),
                            await getOriginations(this.abortController.signal),
                            await getWheelchairOrStrecherDetails(this.abortController.signal),
                            await getDestinations(this.abortController.signal),
                            await getReferralTransportation(this.state.referralId, this.abortController.signal),
                        ]);
                }
            }

            if (!this.state.selectedDocuments) {
                [documentTypesResult, referralSelectedDocumentsResult] = await Promise.all([
                    getDocumentTypes(this.state.referral.serviceId, this.abortController.signal),
                    getReferralDocument(this.state.referralId, this.abortController.signal),
                ])
            }
        }
        catch(error){
        };
        this.setState((_prevState) => {
            return {
                states: statesResult,
                coverageTypes: coverageTypesResult,
                referralClaim: claimResult,
                referralAdjuster: adjusterResult,
                referralClaimant: claimantResult,
                referralClaimantAttorney: claimantAttorneyResult,
                referralClient: clientResult,
                referralEmployer: employerResult,
                referralEmployerAttorney: employerAttorneyResult,
                referralNurseCaseManager: nurseCaseManagerResult,

                ime: { ...imeResult?.ime },
                imeCoverLetter: imeResult?.coverLetter,
                currentSpecificBodyParts: imeResult?.specificBodyParts,

                specificBodyParts: selectedBodyParts,
                treatingPhysician: treatingPhysicianResult,
                optionalIMEPhysician: optionalPhysicianResult,
                languages: languagesResult,
                specialties: specialtiesResult,
                bodySides: bodySidesResult,
                bodyParts: specificBodyPartsResult,
                
                medicalService: ptResult,
                diagnosticService: dxResult,
                utilizationReviewService: urResult,

                appointmentTypes: appointmentTypesResult,// TtResult
                interpretationTypes: interpretationTypesResult,
                translationService: translation,
                transportationRequireds: transportationRequiredResult,
                originations: originationResult,
                wheelchairOrStrecherDetail: wheelchairOrStrecherDetailResult,
                destinations: destinationResult,
                transportationService: transportation,                    
                
                dmeService: dmeResult,
                productCategories: productCategories,
                
                documentTypes: documentTypesResult,
                selectedDocuments: referralSelectedDocumentsResult,

                loading: isLoadingHandledInternally? !isLoadingHandledInternally : _prevState.loading,
            };
        });
    }

    loadClaimAdjuster = async (isLoadingHandledInternally = true) => {
        if (!this.state.referralClaim) {
            if (isLoadingHandledInternally) {
                this.setState({
                    loading: true,
                });
            }
            //States
            await getStates(this.abortController.signal)
                .then(async (statesResult) => {
                    //CoverageTypes
                    await getCoverageTypes(this.abortController.signal)
                        .then(async (coverageTypesResult) => {
                            //ReferralClaim
                            await getReferralClaim(this.state.referralId, this.abortController.signal)
                                .then(async (claimResult) => {
                                    await getReferralContact(
                                        this.state.referralId,
                                        ReferralContactTypesEnum.Adjuster,
                                        this.abortController.signal,
                                    )
                                        .then(async (adjusterResult) => {
                                            await getReferralContact(
                                                this.state.referralId,
                                                ReferralContactTypesEnum.Claimant,
                                                this.abortController.signal,
                                            )
                                            .then(async (claimantResult) => {
                                                if (
                                                    this.state.referral.serviceId == ServicesEnum.PT ||
                                                    this.state.referral.serviceId == ServicesEnum.Diagnostic ||
                                                    this.state.referral.serviceId == ServicesEnum.UR ||
                                                    this.state.referral.serviceId == ServicesEnum.DME
                                                ) {
                                                    await getReferralContact(
                                                        this.state.referralId,
                                                        ReferralContactTypesEnum.NurseCaseManager,
                                                        this.abortController.signal,
                                                    )
                                                        .then(async (nurseCaseManagerResult) => {
                                                            if (isLoadingHandledInternally) {
                                                                this.setState((_prevState) => {
                                                                    return {
                                                                        states: statesResult,
                                                                        coverageTypes: coverageTypesResult,
                                                                        referralClaim: claimResult,
                                                                        referralAdjuster: adjusterResult,
                                                                        referralClaimant: claimantResult,
                                                                        referralNurseCaseManager: nurseCaseManagerResult,
                                                                        loading: false,
                                                                    };
                                                                });
                                                            } else {
                                                                this.setState((_prevState) => {
                                                                    return {
                                                                        states: statesResult,
                                                                        coverageTypes: coverageTypesResult,
                                                                        referralClaim: claimResult,
                                                                        referralAdjuster: adjusterResult,
                                                                        referralNurseCaseManager: nurseCaseManagerResult,
                                                                    };
                                                                });
                                                            }
                                                        })
                                                        .catch((_err) => {
                                                            if (isLoadingHandledInternally) {
                                                                this.setState((_prevState) => {
                                                                    return { loading: false };
                                                                });
                                                            }
                                                        });
                                                    } else {
                                                        if (isLoadingHandledInternally) {
                                                            this.setState((_prevState) => {
                                                                return {
                                                                    states: statesResult,
                                                                    coverageTypes: coverageTypesResult,
                                                                    referralClaim: claimResult,
                                                                    referralAdjuster: adjusterResult,
                                                                    referralClaimant: claimantResult,
                                                                    loading: false,
                                                                };
                                                            });
                                                        } else {
                                                            this.setState((_prevState) => {
                                                                return {
                                                                    states: statesResult,
                                                                    coverageTypes: coverageTypesResult,
                                                                    referralClaim: claimResult,
                                                                    referralAdjuster: adjusterResult,
                                                                    referralClaimant: claimantResult,
                                                                };
                                                            });
                                                        }
                                                    }
                                                })
                                        })
                                        .catch((_err) => {
                                            if (isLoadingHandledInternally) {
                                                this.setState((_prevState) => {
                                                    return { loading: false };
                                                });
                                            }
                                        });
                                })
                                .catch((_err) => {
                                    if (isLoadingHandledInternally) {
                                        this.setState((_prevState) => {
                                            return { loading: false };
                                        });
                                    }
                                });
                        })
                        .catch((_err) => {
                            if (isLoadingHandledInternally) {
                                this.setState((_prevState) => {
                                    return { loading: false };
                                });
                            }
                        });
                })
                .catch((_err) => {
                    if (isLoadingHandledInternally) {
                        this.setState((_prevState) => {
                            return { loading: false };
                        });
                    }
                });
        }
    };

    loadClaimant = async (isLoadingHandledInternally = true) => {
        if (!this.state.referralClaimant) {
            if (isLoadingHandledInternally) {
                this.setState({
                    loading: true,
                });
            }
            await getReferralContact(
                this.state.referralId,
                ReferralContactTypesEnum.Claimant,
                this.abortController.signal,
            )
                .then(async (claimantResult) => {
                    await getReferralContact(
                        this.state.referralId,
                        ReferralContactTypesEnum.ClaimantAttorney,
                        this.abortController.signal,
                    )
                        .then(async (claimantAttorneyResult) => {
                            if (isLoadingHandledInternally) {
                                this.setState((_prevState) => {
                                    return {
                                        referralClaimant: claimantResult,
                                        referralClaimantAttorney: claimantAttorneyResult,
                                        loading: false,
                                    };
                                });
                            } else {
                                this.setState((_prevState) => {
                                    return {
                                        referralClaimant: claimantResult,
                                        referralClaimantAttorney: claimantAttorneyResult,
                                    };
                                });
                            }
                            if (!this.state.states || this.state.states.length == 0) {
                                if (isLoadingHandledInternally) {
                                    this.setState({
                                        loading: true,
                                    });
                                }

                                await getStates(this.abortController.signal)
                                    .then((statesResult) => {
                                        if (isLoadingHandledInternally) {
                                            this.setState({
                                                states: statesResult,
                                                loading: false,
                                            });
                                        } else {
                                            this.setState({
                                                states: statesResult,
                                            });
                                        }
                                    })
                                    .catch((_err) => {
                                        if (isLoadingHandledInternally) {
                                            this.setState((_prevState) => {
                                                return { loading: false };
                                            });
                                        }
                                    });
                            }
                        })
                        .catch((_err) => {
                            if (isLoadingHandledInternally) {
                                this.setState((_prevState) => {
                                    return { loading: false };
                                });
                            }
                        });
                })
                .catch((_err) => {
                    if (isLoadingHandledInternally) {
                        this.setState((_prevState) => {
                            return { loading: false };
                        });
                    }
                });
        }
    };

    loadClient = async (isLoadingHandledInternally = true) => {
        if (!this.state.referralClient) {
            if (isLoadingHandledInternally) {
                this.setState({
                    loading: true,
                });
            }
            await getReferralContact(
                this.state.referralId,
                ReferralContactTypesEnum.Client,
                this.abortController.signal,
            )
                .then(async (clientResult) => {
                    if (isLoadingHandledInternally) {
                        this.setState((_prevState) => {
                            return {
                                referralClient: clientResult,
                                loading: false,
                            };
                        });
                    } else {
                        this.setState((_prevState) => {
                            return {
                                referralClient: clientResult,
                            };
                        });
                    }

                    if (!this.state.states || this.state.states.length == 0) {
                        if (isLoadingHandledInternally) {
                            this.setState((_prevState) => {
                                return {
                                    loading: true,
                                };
                            });
                        }

                        await getStates(this.abortController.signal)
                            .then((statesResult) => {
                                if (isLoadingHandledInternally) {
                                    this.setState((_prevState) => {
                                        return {
                                            states: statesResult,
                                            loading: false,
                                        };
                                    });
                                } else {
                                    this.setState((_prevState) => {
                                        return {
                                            states: statesResult,
                                        };
                                    });
                                }
                            })
                            .catch((_err) => {
                                if (isLoadingHandledInternally) {
                                    this.setState((_prevState) => {
                                        return { loading: false };
                                    });
                                }
                            });
                    }
                })
                .catch((_err) => {
                    if (isLoadingHandledInternally) {
                        this.setState((_prevState) => {
                            return { loading: false };
                        });
                    }
                });
        }
    };

    loadEmployer = async (isLoadingHandledInternally = true) => {
        if (!this.state.referralEmployer) {
            if (isLoadingHandledInternally) {
                this.setState({
                    loading: true,
                });
            }
            await getReferralContact(
                this.state.referralId,
                ReferralContactTypesEnum.Employer,
                this.abortController.signal,
            )
                .then(async (employerResult) => {
                    await getReferralContact(
                        this.state.referralId,
                        ReferralContactTypesEnum.EmployerAttorney,
                        this.abortController.signal,
                    )
                        .then(async (employerAttorneyResult) => {
                            if (isLoadingHandledInternally) {
                                this.setState((_prevState) => {
                                    return {
                                        referralEmployer: employerResult,
                                        referralEmployerAttorney: employerAttorneyResult,
                                        loading: false,
                                    };
                                });
                            } else {
                                this.setState((_prevState) => {
                                    return {
                                        referralEmployer: employerResult,
                                        referralEmployerAttorney: employerAttorneyResult,
                                    };
                                });
                            }
                            if (!this.state.states || this.state.states.length == 0) {
                                if (isLoadingHandledInternally) {
                                    this.setState((_prevState) => {
                                        return {
                                            loading: true,
                                        };
                                    });
                                }

                                await getStates(this.abortController.signal)
                                    .then((statesResult) => {
                                        if (isLoadingHandledInternally) {
                                            this.setState((_prevState) => {
                                                return {
                                                    states: statesResult,
                                                    loading: false,
                                                };
                                            });
                                        } else {
                                            this.setState((_prevState) => {
                                                return {
                                                    states: statesResult,
                                                };
                                            });
                                        }
                                    })
                                    .catch((_err) => {
                                        if (isLoadingHandledInternally) {
                                            this.setState((_prevState) => {
                                                return { loading: false };
                                            });
                                        }
                                    });
                            }
                        })
                        .catch((_err) => {
                            if (isLoadingHandledInternally) {
                                this.setState((_prevState) => {
                                    return { loading: false };
                                });
                            }
                        });
                })
                .catch((_err) => {
                    if (isLoadingHandledInternally) {
                        this.setState((_prevState) => {
                            return { loading: false };
                        });
                    }
                });
        }
    };

    loadReferral = async (isLoadingHandledInternally = true) => {
        if (this.state.referral.serviceId === ServicesEnum.IME && !this.state.ime) {
            if (isLoadingHandledInternally) {
                this.setState({
                    loading: true,
                });
            }
            await getReferralIme(this.state.referralId, this.abortController.signal)
                .then((imeResult) => {
                    const selectedBodyParts = imeResult.specificBodyParts?.map((x) => x.specificBodyPartId);
                    this.setState((_prevState) => {
                        return {
                            ime: { ...imeResult.ime },
                            imeCoverLetter: imeResult.coverLetter,
                            currentSpecificBodyParts: imeResult.specificBodyParts,
                            specificBodyParts: selectedBodyParts,
                        };
                    });
                })
                .catch();

            await getReferralContact(
                this.state.referralId,
                ReferralContactTypesEnum.TreatingPhysician,
                this.abortController.signal,
            )
                .then((treatingPhysicianResult) => {
                    this.setState((_prevState) => {
                        return {
                            treatingPhysician: treatingPhysicianResult,
                        };
                    });
                })
                .catch();

            await getReferralContact(
                this.state.referralId,
                ReferralContactTypesEnum.OptionalPhysician,
                this.abortController.signal,
            )
                .then((optionalPhysicianResult) => {
                    this.setState((_prevState) => {
                        return {
                            optionalIMEPhysician: optionalPhysicianResult,
                        };
                    });
                })
                .catch();

            await getLanguages(this.abortController.signal)
                .then((languagesResult) => {
                    this.setState((_prevState) => {
                        return {
                            languages: languagesResult,
                        };
                    });
                })
                .catch();

            await getSpecialties(this.abortController.signal)
                .then((specialtiesResult) => {
                    this.setState((_prevState) => {
                        return {
                            specialties: specialtiesResult,
                        };
                    });
                })
                .catch();

            await getBodySides(this.abortController.signal)
                .then((bodySidesResult) => {
                    this.setState((_prevState) => {
                        return {
                            bodySides: bodySidesResult,
                        };
                    });
                })
                .catch();

            await getSpecificBodyParts(
                this.state.referral.serviceId,
                this.state.referral.serviceTypeIds[0],
                this.abortController.signal,
            )
                .then((specificBodyPartsResult) => {
                    if (isLoadingHandledInternally) {
                        this.setState((_prevState) => {
                            return {
                                bodyParts: specificBodyPartsResult,
                                loading: false,
                            };
                        });
                    } else {
                        this.setState((_prevState) => {
                            return {
                                bodyParts: specificBodyPartsResult,
                            };
                        });
                    }
                })
                .catch((_err) => {
                    if (isLoadingHandledInternally) {
                        this.setState((_prevState) => {
                            return { loading: false };
                        });
                    }
                });
        } else if (
            this.state.referral.serviceId === ServicesEnum.UR ||
            this.state.referral.serviceId === ServicesEnum.PT ||
            this.state.referral.serviceId === ServicesEnum.Diagnostic ||
            this.state.referral.serviceId === ServicesEnum.DME ||
            (this.state.referral.serviceId === ServicesEnum.TT && !this.state.medicalService)
        ) {
            if (isLoadingHandledInternally) {
                this.setState({
                    loading: true,
                });
            }
            if (this.state.referral.serviceId === ServicesEnum.PT) {
                await getReferralPhysicalTherapy(this.state.referralId, this.abortController.signal)
                    .then(async (ptResult) => {
                        this.setState((_prevState) => {
                            return {
                                medicalService: ptResult,
                            };
                        });
                    })
                    .catch();

                getSpecificBodyParts(
                    this.state.referral.serviceId,
                    this.state.referral.serviceTypeIds[0],
                    this.abortController.signal,
                )
                    .then((ptResults) => {
                        if (isLoadingHandledInternally) {
                            this.setState((_prevState) => {
                                return {
                                    bodyParts: ptResults,
                                    loading: false,
                                };
                            });
                        } else {
                            this.setState((_prevState) => {
                                return {
                                    bodyParts: ptResults,
                                };
                            });
                        }
                    })
                    .catch((_err) => {
                        if (isLoadingHandledInternally) {
                            this.setState((_prevState) => {
                                return { loading: false };
                            });
                        }
                    });
            } else if (this.state.referral.serviceId === ServicesEnum.Diagnostic) {
                await getReferralDiagnostic(this.state.referralId, this.abortController.signal)
                    .then(async (dxResult) => {
                        this.setState((_prevState) => {
                            return {
                                diagnosticService: dxResult,
                            };
                        });
                    })
                    .catch();
            } else if (this.state.referral.serviceId === ServicesEnum.UR) {
                await getReferralUr(this.state.referralId, this.abortController.signal)
                    .then(async (urResult) => {
                        this.setState(() => {
                            return {
                                utilizationReviewService: urResult,
                            };
                        });
                    })
                    .catch();

                getSpecificBodyParts(
                    this.state.referral.serviceId,
                    this.state.referral.serviceTypeIds[0],
                    this.abortController.signal,
                )
                    .then((ptResults) => {
                        if (isLoadingHandledInternally) {
                            this.setState((_prevState) => {
                                return {
                                    bodyParts: ptResults,
                                    loading: false,
                                };
                            });
                        } else {
                            this.setState((_prevState) => {
                                return {
                                    bodyParts: ptResults,
                                };
                            });
                        }
                    })
                    .catch((_err) => {
                        if (isLoadingHandledInternally) {
                            this.setState((_prevState) => {
                                return { loading: false };
                            });
                        }
                    });
            } else if (this.state.referral.serviceId === ServicesEnum.TT) {
                await getAppointmentTypes(this.abortController.signal)
                    .then((appointmentTypesResult) => {
                        this.setState((_prevState) => {
                            return {
                                appointmentTypes: appointmentTypesResult,
                            };
                        });
                    })
                    .catch();

                const STAbb = getServiceTypeAbb(this.state.services, this.state.referral?.serviceTypeIds);

                if (isAbbreviationInArray(STAbb, translationTypes)) {
                    await getInterpretationTypes(this.abortController.signal)
                        .then((it) => {
                            this.setState((_prevState) => {
                                return {
                                    interpretationTypes: it,
                                };
                            });
                        })
                        .catch();
                    await getReferralTranslation(this.state.referralId, this.abortController.signal)
                        .then(async (translation) => {
                            this.setState((_prevState) => {
                                return {
                                    translationService: translation,
                                };
                            });
                        })
                        .catch();
                }
                if (STAbb.includes('TP')) {
                    await getTransportationRequireds(this.abortController.signal)
                        .then((tr) => {
                            this.setState((_prevState) => {
                                return {
                                    transportationRequireds: tr,
                                };
                            });
                        })
                        .catch();

                    await getOriginations(this.abortController.signal)
                        .then((or) => {
                            this.setState((_prevState) => {
                                return {
                                    originations: or,
                                };
                            });
                        })
                        .catch();

                    await getWheelchairOrStrecherDetails(this.abortController.signal)
                        .then((wh) => {
                            this.setState(() => {
                                return {
                                    wheelchairOrStrecherDetail: wh,
                                };
                            });
                        })
                        .catch();

                    await getDestinations(this.abortController.signal)
                        .then((de) => {
                            this.setState((_prevState) => {
                                return {
                                    destinations: de,
                                };
                            });
                        })
                        .catch();
                    await getReferralTransportation(this.state.referralId, this.abortController.signal)
                        .then(async (transportation) => {
                            this.setState((_prevState) => {
                                return {
                                    transportationService: transportation,
                                };
                            });
                        })
                        .catch();
                }
            } else if (this.state.referral.serviceId === ServicesEnum.DME) {
                await getReferralDme(this.state.referralId, this.abortController.signal).then((dmeResult) => {
                    if (!dmeResult) return;
                    this.setState((_prevState) => {
                        return {
                            dmeService: dmeResult,
                        };
                    });
                });

                await getProductCategories(this.abortController.signal).then((productCategories) => {
                    this.setState(() => {
                        return {
                            productCategories: productCategories,
                        };
                    });
                });
            }

            await getBodySides(this.abortController.signal)
                .then((bodySidesResult) => {
                    if (isLoadingHandledInternally) {
                        this.setState((_prevState) => {
                            return {
                                bodySides: bodySidesResult,
                                loading: false,
                            };
                        });
                    } else {
                        this.setState((_prevState) => {
                            return {
                                bodySides: bodySidesResult,
                            };
                        });
                    }
                })
                .catch();
        }
        await getLanguages(this.abortController.signal)
            .then((languagesResult) => {
                if (isLoadingHandledInternally) {
                    this.setState((_prevState) => {
                        return {
                            languages: languagesResult,
                            loading: false,
                        };
                    });
                } else {
                    this.setState((_prevState) => {
                        return {
                            languages: languagesResult,
                        };
                    });
                }
            })
            .catch();
    };

    getDxSpecificBodyParts = (serviceTypeId: number) => {
        if (!serviceTypeId) {
            this.setState({
                bodyParts: [],
            });
        } else {
            getSpecificBodyParts(this.state.referral.serviceId, serviceTypeId, this.abortController.signal).then(
                (specificBodyPartsResult) => {
                    this.setState(() => {
                        return {
                            bodyParts: specificBodyPartsResult,
                        };
                    });
                },
            );
        }
    };

    loadDocuments = async (isLoadingHandledInternally = true) => {
        if (!this.state.selectedDocuments) {
            if (isLoadingHandledInternally) {
                this.setState({
                    loading: true,
                });
            }

            await Promise.all([
                getDocumentTypes(this.state.referral.serviceId, this.abortController.signal),
                getReferralDocument(this.state.referralId, this.abortController.signal),
            ])
                .then(([documentTypesResponse, referralDocumentsResponse]) => {
                    if (isLoadingHandledInternally) {
                        this.setState((_prevState) => {
                            return {
                                selectedDocuments: referralDocumentsResponse,
                                documentTypes: documentTypesResponse,
                                loading: false,
                            };
                        });
                    } else {
                        this.setState((_prevState) => {
                            return {
                                selectedDocuments: referralDocumentsResponse,
                                documentTypes: documentTypesResponse,
                            };
                        });
                    }
                })
                .catch((_err) => {
                    if (isLoadingHandledInternally) {
                        this.setState((_prevState) => {
                            return { loading: false };
                        });
                    }
                });
        }
    };

    loadReferralSubmission = async (isLoadingHandledInternally = true) => {
        if (isLoadingHandledInternally) {
            this.setState({
                loading: true,
            });
        }

        if (this.state.referral != null) {
            await getReferralSubmission(this.state.referralId, this.abortController.signal)
                .then((referralSubmissionResult) => {
                    if (this.state.referral.referralStatusId == ReferralStatusTypesEnum.SubmissionFailed) {
                        const vendorResponseErrorList = new Array<VendorErrorEntity>();
                        const vendorErrorEntity = new VendorErrorEntity(
                            'Referral',
                            referralSubmissionResult.compressedFileRequestIdFailed != null ||
                            referralSubmissionResult.compressedFileErrorReason != null
                                ? 'Submission error. Please Contact Support'
                                : "We couldn't access the vendor's API. Please resubmit",
                        );
                        vendorResponseErrorList.push(vendorErrorEntity);
                        const vendorResponseDataModel = new VendorResponseDataModel(vendorResponseErrorList);
                        this.setState({
                            vendorResponse: vendorResponseDataModel,
                            submissionFailed: true,
                        });
                    } else if (this.state.referral.referralStatusId == ReferralStatusTypesEnum.ValidationFailed) {
                        const vendorResponseErrorList = new Array<VendorErrorEntity>();
                        let foundValidationErrors = false;
                        if (referralSubmissionResult.vendorError) {
                            this.setState({
                                exceptionMessage: referralSubmissionResult.vendorError
                                    ? referralSubmissionResult.vendorError
                                    : null,
                                submissionFailed: true,
                                validationError: true,
                            });
                        } else {
                            const result = JSON.parse(referralSubmissionResult.vendorAnswerData);
                            const errorObject = JSON.parse(result.Message);
                            errorObject?.validationErrors?.map((vendorError, _index) => {
                                const vendorErrorEntity = new VendorErrorEntity(
                                    vendorError['fieldName'],
                                    vendorError['errorMessage'],
                                );
                                vendorResponseErrorList.push(vendorErrorEntity);
                                foundValidationErrors = true;
                            });
                            const vendorResponseDataModel = new VendorResponseDataModel(vendorResponseErrorList);

                            this.setState({
                                vendorResponse: vendorResponseDataModel,
                                exceptionMessage: errorObject?.message
                                    ? errorObject.message
                                    : 'No validation message sent by the vendor',
                                submissionFailed: true,
                                validationError: true,
                            });
                        }
                    } else if (this.state.referral.referralStatusId == ReferralStatusTypesEnum.ReadyForSubmission) {
                        this.setState({
                            submissionFailed: true,
                            validationError: false,
                        });
                    }

                    if (isLoadingHandledInternally) {
                        this.setState((_prevState) => {
                            return {
                                loading: false,
                            };
                        });
                    }
                })
                .catch((_err) => {
                    if (isLoadingHandledInternally) {
                        this.setState((_prevState) => {
                            return { loading: false };
                        });
                    }
                });
        }
    };

    getPhysicalTheraphy = async () => {
        await getReferralPhysicalTherapy(this.state.referralId, this.abortController.signal).then(async (ptResult) => {
            this.setState((_prevState) => {
                return {
                    medicalService: ptResult,
                };
            });
        });
    };

    getDiagnostic = async () => {
        await getReferralDiagnostic(this.state.referralId, this.abortController.signal).then(async (dxResult) => {
            this.setState((_prevState) => {
                return {
                    diagnosticService: dxResult,
                };
            });
        });
    };

    getUr = async () => {
        await getReferralUr(this.state.referralId, this.abortController.signal).then(async (urResult) => {
            this.setState(() => {
                return {
                    utilizationReviewService: urResult,
                };
            });
        });
    };

    getTransportation = async () => {
        await getReferralTransportation(this.state.referralId, this.abortController.signal).then(async (tpResult) => {
            this.setState(() => {
                return {
                    transportationService: tpResult,
                };
            });
        });
    };

    getTranslation = async () => {
        await getReferralTranslation(this.state.referralId, this.abortController.signal).then(async (tlResult) => {
            this.setState(() => {
                return {
                    translationService: tlResult,
                };
            });
        });
    };

    deleteServiceTypePtRequestDetail = async (serviceTypePtRequestDetailId: number) => {
        deleteServiceTypeRequestPtDetail(serviceTypePtRequestDetailId, this.abortController.signal);
    };

    submitReferral = () => {
        this.setState({
            loading: true,
        });
        sendReferralSubmit(
            this.state.referralId,
            this.abortController.signal,
            this.state.referral.serviceId === ServicesEnum.TT,
        )
            .then((submitReferralResponse) => {
                this.setState(() => {
                    return {
                        loading: false,
                    };
                });

                if (submitReferralResponse.internalCode == ReferralSubmitInternalCodesEnum.PendingSubmission) {
                    toast.success(submitReferralResponse.message);
                    const vendorResponseErrorList = new Array<VendorErrorEntity>();
                    const vendorErrorEntity = new VendorErrorEntity('Referral', submitReferralResponse.message);
                    vendorResponseErrorList.push(vendorErrorEntity);
                    const vendorResponseDataModel = new VendorResponseDataModel(vendorResponseErrorList);
                    this.setState({
                        vendorResponse: vendorResponseDataModel,
                        loading: false,
                        pendingSubmission: true,
                    });
                    this.props.history.push(
                        '/claims/' + this.props.match.params.id + '/referrals/' + this.state.referralId,
                    );
                } else {
                    this.props.history.push(
                        '/claims/' + this.props.match.params.id + '/referrals/' + this.state.referralId,
                    );
                    toast.success(submitReferralResponse.message, { autoClose: 5000 });
                }
            })
            .catch((err) => {
                if (err.status == '400') {
                    err.json().then((ValidationErrorsResponse) => {
                        const vendorResponseErrorList = new Array<VendorErrorEntity>();
                        let foundValidationErrors = false;
                        const errorObject = JSON.parse(ValidationErrorsResponse);
                        errorObject.ValidationErrors?.map((vendorError, _index) => {
                            const vendorErrorEntity = new VendorErrorEntity(
                                vendorError['FieldName'],
                                vendorError['ErrorMessage'],
                            );
                            vendorResponseErrorList.push(vendorErrorEntity);
                            foundValidationErrors = true;
                        });
                        const vendorResponseDataModel = new VendorResponseDataModel(vendorResponseErrorList);
                        if (foundValidationErrors) {
                            if (errorObject.IsSubmissionError) {
                                toast.error('❌ ' + 'Zip Files Request Process Failed');
                                const vendorResponseErrorList = new Array<VendorErrorEntity>();
                                const vendorErrorEntity = new VendorErrorEntity(
                                    'Referral',
                                    'Submission error. Please Contact Support',
                                );
                                vendorResponseErrorList.push(vendorErrorEntity);
                                const vendorResponseDataModel = new VendorResponseDataModel(vendorResponseErrorList);
                                this.setState({
                                    vendorResponse: vendorResponseDataModel,
                                    loading: false,
                                    submissionFailed: true,
                                    referral: {
                                        ...this.state.referral,
                                        referralStatusId: 8,
                                    },
                                });
                            } else {
                                toast.warning('⚠️ ' + 'Validation errors');

                                this.setState({
                                    vendorResponse: vendorResponseDataModel,
                                    exceptionMessage: ValidationErrorsResponse.Message
                                        ? ValidationErrorsResponse.Message
                                        : null,
                                    loading: false,
                                });
                            }
                        } else {
                            toast.warning('⚠️ ' + 'Communication errors');
                            const communicationErrorEntity = new CommunicationErrorEntity('Not Found', err.message);
                            this.setState({
                                vendorCommunicationError: communicationErrorEntity,
                                exceptionMessage: ValidationErrorsResponse.Message
                                    ? ValidationErrorsResponse.Message
                                    : null,
                                loading: false,
                            });
                        }
                    });
                } else {
                    toast.warning('⚠️ ' + 'Error submitting the Referral');
                    if (err.status == '404') {
                        const communicationErrorEntity = new CommunicationErrorEntity('Not Found', err.message);
                        err.json().then((validationError) => {
                            this.setState({
                                vendorCommunicationError: communicationErrorEntity,
                                exceptionMessage: validationError.Message ? validationError.Message : null,
                                loading: false,
                            });
                        });
                    } else {
                        err.json().then((serverMessage) => {
                            const communicationErrorEntity = new CommunicationErrorEntity(err.status, '');
                            this.setState({
                                vendorCommunicationError: communicationErrorEntity,
                                exceptionMessage: serverMessage.Message ? serverMessage.Message : null,
                                loading: false,
                            });
                        });
                    }
                }
            });
    };

    handleUpdateClaim = (validate: () => boolean) => {
        this.setValidationFunction(validate);
        if (!this.validationFunction || this.validationFunction()) {
            this.updateClaim();
        }
    }
    
    handleUpdateAdjuster = (validate: () => boolean) => {
        this.setValidationFunction(validate);
        if (!this.validationFunction || this.validationFunction()) {
            this.updateContact(this.state.referralAdjuster, 'Adjuster');
        }
    }
    
    handleUpdateClaimant = (validate: () => boolean) => {
        this.setValidationFunction(validate);
        if (!this.validationFunction || this.validationFunction()) {
            this.updateContact(this.state.referralClaimant, 'Claimant');
        }
    }

    handleUpdateClaimantAttorney = (validate: () => boolean) => {
        this.setValidationFunction(validate);
        if (!this.validationFunction || this.validationFunction()) {
            this.updateContact(this.state.referralClaimantAttorney, 'Claimant Attorney');
        }
    }

    handleUpdateClient = () => {
            this.updateContact(this.state.referralClient, 'Client');
    }

    handleUpdateEmployer = (validate: () => boolean) => {
        this.setValidationFunction(validate);
        if (!this.validationFunction || this.validationFunction()) {
            this.updateContact(this.state.referralEmployer, 'Employeer');
        }
    }

    handleUpdateEmployerAttorney = () => {
        this.updateContact(this.state.referralEmployerAttorney, 'Employer Attorney');
    }

    handleUpdateIme = (validate: () => boolean) => {
        this.setValidationFunction(validate);
        if (!this.validationFunction || this.validationFunction()) {
            this.updateIme();
        }
    }

    handleUpdateTreatingPhysician = (validate: () => boolean) => {
        this.setValidationFunction(validate);
        if (!this.validationFunction || this.validationFunction()) {
            this.updateContact(this.state.treatingPhysician, 'Treating Physician');
        }
    }
    
    handleUpdateOptionalImePhysician = (validate: () => boolean) => {
        this.setValidationFunction(validate);
        if (!this.validationFunction || this.validationFunction()) {
            this.updateContact(this.state.optionalIMEPhysician, 'Optional Ime Physician');
        }
    }
    
    handleUpdateImeCoverLetter = () => {
        this.updateIme();
    }

    handleUpdatePt = () => {
        this.updatePt();
    }
    
    handleUpdatePtReferringPhysician = () => {
        this.updatePt();
    }
    
    handleUpdatePtAdditionalInformation = () => {
        this.updatePt();
    }

    handleUpdateDocuments = (validate: () => boolean) => {
        this.setValidationFunction(validate);
        if (!this.validationFunction || this.validationFunction()) {
            this.updateDocuments();
        }
    }

    handleRefresh = async () => {
       this.loadPartialPageRefresh(false);
    };

    loadPartialPageRefresh = async (isLoadingHandledInternally = true) => {        
            let claimInfo = null; 

        try {

            [claimInfo] =

                await Promise.all([
                    await GetRefreshedReferralContacts( this.state.referralId, ReferralContactTypesEnum.Adjuster, this.abortController.signal ),
                ]);
        } catch(error){
            toast.warning('⚠️ ' + 'Error getting Referral contacts', { autoClose: 5000 });
            this.setState({ loading: false });

            return false;
        };
        claimInfo.referralAdjuster.contactId = this.state.referralAdjuster.contactId;
        claimInfo.referralClaimant.contactId = this.state.referralClaimant.contactId;
        claimInfo.referralClaimantAttorney.contactId = this.state.referralClaimantAttorney.contactId;
        claimInfo.referralClient.contactId = this.state.referralClient.contactId;
        claimInfo.referralEmployer.contactId = this.state.referralEmployer.contactId;
        claimInfo.referralEmployerAttorney.contactId = this.state.referralEmployerAttorney.contactId;

        this.setState({

                referralAdjuster: claimInfo.referralAdjuster,

                referralClaimant: claimInfo.referralClaimant,

                referralClaimantAttorney: claimInfo.referralClaimantAttorney,

                referralClient: claimInfo.referralClient,

                referralEmployer: claimInfo.referralEmployer,       
                
                referralEmployerAttorney : claimInfo.referralEmployerAttorney,

                loading:false

        });
    };

    handleNext = async () => {        
        if (this.state.stepIndex === 0) {
            if (!this.state.referralId) {
                if (this.state.selectedServiceTypeId && this.state.selectedVendorId) {
                    this.postReferralInitialData(
                        this.state.claim.claim.claimId,
                        this.state.selectedServiceTypeId,
                        this.state.selectedVendorId,
                    );
                } else {
                    toast.warning('⚠️ ' + 'Please select a Service Type and a Vendor.');
                }
            } else { //this case could happen.. just in case, we will move to step 1
                this.setStepIndex(1);
            }
        } else if (this.state.stepIndex === 1) {

            let isValidDocuments = this.validations["Documents"]();
    
            if ( !isValidDocuments ) {
                this.focusDocuments();
                let msgFinal = "Please select a Document Type. At least one document is required to attach.";
                this.setState({
                    textErrorDialog: msgFinal,
                    showErrorDialog: true
                });
                return false;
            }
            if (this.isValidAll())
            {
                this.setState({ loading: true });
                let isSavedOK = await this.saveAllData();
                if ( isSavedOK ) {
                    this.setStepIndex(2);
                }
            }
            /*if (this.isValidAll())
                this.setStepIndex(2);*/

        } else if (this.state.stepIndex === 2) {
            this.submitReferral();
        }
    };

    handleBack = () => {
        if (this.state.referral.serviceId == ServicesEnum.Diagnostic) {
            this.getDiagnostic();
            } else if (this.state.referral.serviceId == ServicesEnum.UR) {
                this.getUr();
            } else if (this.state.referral.serviceId === ServicesEnum.TT) {
                const STAbb = getServiceTypeAbb(this.state.services, this.state.referral?.serviceTypeIds);
                if ( STAbb.includes( 'TP' ) ) {
                    this.getTransportation();
                }
                if (isAbbreviationInArray(STAbb, translationTypes)) {
                    this.getTranslation();
                }
            }
        this.setStepIndex(1);
    };

    startOver = () => {
        this.props.history.push('/claims/' + this.props.match.params.id);
    };

    handleOpenCloseStatus = ( boxName: string, isOpened: boolean ) => {
        const newObjProperty = {};
        newObjProperty[boxName] = isOpened;
        this.setState((_prevState) => {return { openCloseStatus: {..._prevState.openCloseStatus,...newObjProperty }} } );
    }
    
    handleOpenCloseStatusAll = ( isOpened ) => {
        const newObjProperty = {...this.state.openCloseStatus};
        for ( const property in newObjProperty ){
            newObjProperty[ property ] = isOpened;
        }
        this.setState({openCloseStatus: newObjProperty } );
    }

    getVendorName() {
        let vendorName = '';
        this.state.vendors?.map((vendor: VendorEntity, _index) => {
            if (vendor.vendorId == this.state.referral?.vendorId) {
                vendorName = vendor.vendorName;
            }
        });
        return vendorName;
    }

    setFocusDocumentsFunction(currentPageSetFocusFunction: ()=>boolean){
        this.focusDocuments = currentPageSetFocusFunction;
    }

    validations = [];

    setValidationFunction(currentPageValidationFunction: () => boolean, referralContactType: string = "") {
        this.validationFunction = currentPageValidationFunction;
        if ( referralContactType != "" )
            this.validations[referralContactType] = currentPageValidationFunction; //when the component is load, set their validation function for its form
    }

    setIsValidFunction(currentFormIsValidFunction: () => boolean, referralContactType: string = "") {
        //this.validationFunction = currentFormIsValidFunction;
        if (referralContactType!="")
            this.validations[referralContactType] = currentFormIsValidFunction;
    }

    handleSaveAll = async () => {
        
            let isSavedOK = await this.saveAllData();
            if (isSavedOK) {
                toast.success('Referral saved', { autoClose: 3000 });}
    }
    
    saveAllData = async () => {
        try {
            this.setState({ loading: true });
            let selectedBodyParts = [];
            //let imeResult = new ImeDataModel();
            let diagnosticResult = new DiagnosticServiceDataModel();
            let utilizationReviewResult = new UtilizationReviewServiceDataModel();
            let transportationResult = null;
            let translationResult = null; //new TranslationDataModel();
            let dmeResult = new DmeServiceDataModel();
            let [ imeResult, treatingPhysicianResult, optionalPhysicianResult ] = [ new ImeDataModel(), new ReferralContactEntity(), new ReferralContactEntity() ];
            //let documentsResult = null;
            let isClaimantAttorney = false;
            let isEmployerAttorney = false;
            let isNurseCaseManager = false;
            if ( (this.state.referralClaimantAttorney?.isRepresented as any) == 'true' || this.state.referralClaimantAttorney?.isRepresented == true )
                isClaimantAttorney = true;
            if ( (this.state.referralEmployerAttorney?.isRepresented as any) == 'true' || this.state.referralEmployerAttorney?.isRepresented == true )
                isEmployerAttorney = true;
            if ( [ServicesEnum.PT, ServicesEnum.Diagnostic, ServicesEnum.UR, ServicesEnum.DME ].includes(this.state.referral.serviceId))
                isNurseCaseManager = true;
            const [referralClaimResult, referralAdjusterResult, referralClaimantResult, referralEmployerResult, referralClaimantAttorneyResult, referralEmployerAttorneyResult,
                referralNurseCaseManagerResult ] =
                await Promise.all([
                    await sendReferralClaim( this.state.referralClaim.referralClaimId, this.state.referralClaim, this.abortController.signal ),
                    await sendReferralContact( this.state.referralAdjuster, this.abortController.signal, this.state.referralAdjuster.contactId ),
                    await sendReferralContact( this.state.referralClaimant, this.abortController.signal, this.state.referralClaimant.contactId ),
                    await sendReferralContact( this.state.referralEmployer, this.abortController.signal, this.state.referralEmployer.contactId ),
                    isClaimantAttorney? await sendReferralContact( this.state.referralClaimantAttorney, this.abortController.signal, this.state.referralClaimantAttorney.contactId ): null,
                    isEmployerAttorney? await sendReferralContact( this.state.referralEmployerAttorney, this.abortController.signal, this.state.referralEmployerAttorney.contactId ): null,
                    isNurseCaseManager? await sendReferralContact( this.state.referralNurseCaseManager, this.abortController.signal, this.state.referralNurseCaseManager.contactId ): null
                ]);

            if (this.state.referral.serviceId == ServicesEnum.IME) {

                //#region treating physician: set main data
                let imeTreatingPhysician = new ReferralContactEntity();
                if (!this.state.treatingPhysician) {
                    imeTreatingPhysician.referralId = this.state.referralId;
                    imeTreatingPhysician.contactTypeId = ReferralContactTypesEnum.TreatingPhysician;
                }
                else {
                    imeTreatingPhysician = this.state.treatingPhysician;
                    if (!imeTreatingPhysician.referralId) {
                        imeTreatingPhysician.referralId = this.state.referralId;
                        imeTreatingPhysician.contactTypeId = ReferralContactTypesEnum.TreatingPhysician;
                    }
                }
                //#endregion

                //#region imeOptionalPhysician: set main data
                let imeOptionalPhysician: ReferralContactEntity = new ReferralContactEntity();
                if (!this.state.optionalIMEPhysician) {
                    imeOptionalPhysician.referralId = this.state.referralId;
                    imeOptionalPhysician.contactTypeId = ReferralContactTypesEnum.OptionalPhysician;
                }
                else {
                    imeOptionalPhysician = this.state.optionalIMEPhysician;
                    if (!imeOptionalPhysician.referralId) {
                        imeOptionalPhysician.referralId = this.state.referralId;
                        imeOptionalPhysician.contactTypeId = ReferralContactTypesEnum.OptionalPhysician;
                    }
                }
                //#endregion
                
                [ imeResult, treatingPhysicianResult, optionalPhysicianResult ] =
                    await Promise.all([
                        await sendReferralIme(
                                                {
                                                    ime: { ...this.state.ime, referralId: this.state.referralId },
                                                    specificBodyParts: this.state.currentSpecificBodyParts,
                                                    coverLetter: this.state.imeCoverLetter,
                                                } as ImeDataModel,
                                                this.abortController.signal,
                                                this.state.ime.imeId ),
                        await sendReferralContact(imeTreatingPhysician, this.abortController.signal, imeTreatingPhysician.contactId),
                        await sendReferralContact(imeOptionalPhysician, this.abortController.signal, imeOptionalPhysician.contactId)
                ]);
                selectedBodyParts = imeResult.specificBodyParts?.map((x: SpecificBodyPartEntity): number => x.specificBodyPartId);
            }
            else if (this.state.referral.serviceId == ServicesEnum.PT) {
                await sendReferralPT(this.state.referralId, this.state.medicalService, this.abortController.signal);
            }
            else if (this.state.referral.serviceId == ServicesEnum.Diagnostic) {
                await sendReferralDX(this.state.referralId, this.state.diagnosticService, this.abortController.signal);
                diagnosticResult = await getReferralDiagnostic(this.state.referralId, this.abortController.signal);
            }
            else if (this.state.referral.serviceId == ServicesEnum.UR) {
                [ utilizationReviewResult ] =
                    await Promise.all([
                        await sendReferralUR(this.state.referralId, this.state.utilizationReviewService, this.abortController.signal)
                    ]);
            }
            else if (this.state.referral.serviceId == ServicesEnum.TT) {
                    await Promise.all([
                        await 
                        sendReferralTt( this.state.referralId,
                            this.state.translationService.referralId ? this.state.translationService : null,
                            this.state.transportationService.referralId ? this.state.transportationService : null,
                            this.abortController.signal,
                        )
                    ]);
                    const STAbb = getServiceTypeAbb(this.state.services, this.state.referral?.serviceTypeIds);
                    [ translationResult, transportationResult ] =
                        await Promise.all([
                            isAbbreviationInArray(STAbb, translationTypes) ? await getReferralTranslation( this.state.referralId, this.abortController.signal ) : null,
                            isAbbreviationInArray(STAbb, ['TP']) ? await getReferralTransportation( this.state.referralId, this.abortController.signal ) : null
                        ]);
            }
            else if (this.state.referral.serviceId == ServicesEnum.DME) {
                [ dmeResult ] =
                    await Promise.all([
                        await sendReferralDme(this.state.referralId, this.state.dmeService, this.abortController.signal)
                    ]);
            }


            let referralDocumentsResult = await sendReferralDocuments( this.state.referralId, { documents: this.state.selectedDocuments } as ReferralDocumentDataModel, this.abortController.signal );
            let documentsResult = await getReferralDocument(this.state.referralId, this.abortController.signal);
            
            this.setState(() => {
                return {
                    transportationService: transportationResult, 
                    /**/translationService: translationResult,
                    dmeService: dmeResult,
                    diagnosticService: diagnosticResult,
                    ime: imeResult.ime,
                    currentSpecificBodyParts: imeResult.specificBodyParts,
                    specificBodyParts: selectedBodyParts,
                    treatingPhysician: treatingPhysicianResult,
                    optionalIMEPhysician: optionalPhysicianResult,
                    referralClaim: referralClaimResult,
                    referralClaimant: referralClaimantResult,
                    referralClaimantAttorney: referralClaimantAttorneyResult,
                    referralAdjuster: referralAdjusterResult,
                    referralEmployer: referralEmployerResult,
                    referralEmployerAttorney: referralEmployerAttorneyResult,
                    referralNurseCaseManager: referralNurseCaseManagerResult,
                    selectedDocuments: documentsResult,
                    loading: false,
                };
            });
        }
        catch(error){
            toast.warning('⚠️ ' + 'Error saving the Referral.', { autoClose: 5000 });
            this.setState({ loading: false });
            let errorLog = new ErrorLogEntity();
            if (error instanceof Response) {
                errorLog.ErrorMessage = `VendorPortal-exception-save all. url: ${error.url}, statusCode: ${error.status}, statusText: ${error.statusText}`;
              } else if (error instanceof TypeError) {
                errorLog.ErrorMessage = `Type error: ${error.message + ' - ' + error.stack}`; // Provide more context for type errors
              } else {
                errorLog.ErrorMessage = `Unexpected error: ${error}`; // Generic message for other errors
              }

              // Send the error log with appropriate error handling
                try {
                    await sendErrorLog(errorLog, this.abortController.signal);
                } catch (error) {
                    console.error('Failed to send error log:', error); // Handle logging error gracefully
                }

            return false;
        };

        return true;
    }

    isValidAll = () => {
        const msgError = () => {
            const msgBasic = "Some required fields are missing.";

            let isValidPT = this.validations["PT"]?.()?? true;
            if (!isValidPT)
                return msgBasic;
            
            let isValidPTProcedure = this.validations["PTProcedure"]?.()?? true;
            if (!isValidPTProcedure)
                return "At least one specific body part must be added.";

            let isValidTt = this.validations["Tt"]?.()?? true;
            if (!isValidTt)
                return msgBasic;

            let isValidTtDestination = this.validations["TtDestination"]?.()?? true;
            if (!isValidTtDestination)
                return "You need to add at least one destination.";
            
            let isValidDxProcedure = this.validations["DXProcedure"]?.()?? true;
            if (!isValidDxProcedure)
                return "In order to continue, one option of each service type must be added.";
            
            let isValidDmeProductCategory = this.validations["DmeProductCategory"]?.()?? true;
            if (!isValidDmeProductCategory)
                return "At least one product must be added.";
            
            let isValidIME = this.validations["IME"]?.()?? true; // if ["IME"] does not exist, set true
            let isValidDx = this.validations["DX"]?.()?? true;
            let isValidDme = this.validations["Dme"]?.()?? true;
            let isValidUr = this.validations["Ur"]?.()?? true;

            if (!isValidIME || !isValidDx || !isValidDme || !isValidUr /*|| !isValidClaim || !isValidAdjuster || !isValidClaimant || !isValidClaimantAttorney || !isValidEmployer*/)
                return msgBasic;


            return "";
        }

        const msgFinal = msgError();
        if (msgFinal) {
            this.setState({
                textErrorDialog: msgFinal,
                showErrorDialog: true
            });
            return false;
        }
        return true;
    }

    updateClaim = async () => {
        //this.setState({ loading: true });
        try {
    
            let referralClaimResult = await sendReferralClaim(
                this.state.referralClaim.referralClaimId,
                this.state.referralClaim,
                this.abortController.signal,
            );
            this.setState(() => {
                return {
                    referralClaim: referralClaimResult,
                    loading: false,
                };
        });

            toast.success('Claim saved', { autoClose: 3000 });
        }
        catch {
            toast.warning('⚠️ ' + 'Error saving Referral Claim');
            this.setState({ loading: false });
        }
    }
 
    updateContact = async (referralContact: any, referralContactTypeName: string) => {
        //this.setState({ loading: true });
        if (referralContact) {
            try {
                let referralContactResult = await sendReferralContact(referralContact, this.abortController.signal, referralContact.contactId);
                this.setState((): any => {
                    switch (ReferralContactTypesEnum[referralContactTypeName]) {
                        case ReferralContactTypesEnum.Adjuster:
                            return { referralAdjuster: referralContactResult, loading: false, };
                        case ReferralContactTypesEnum.Claimant:
                            return { referralClaimant: referralContactResult, loading: false, };
                        case ReferralContactTypesEnum.ClaimantAttorney:
                            return { referralClaimantAttorney: referralContactResult, loading: false, };
                        case ReferralContactTypesEnum.Client:
                            return { referralClient: referralContactResult, loading: false, };
                        case ReferralContactTypesEnum.Employer:
                            return { referralEmployer: referralContactResult, loading: false, };
                        case ReferralContactTypesEnum.EmployerAttorney:
                            return { referralEmployerAttorney: referralContactResult, loading: false, };
                        case ReferralContactTypesEnum.NurseCaseManager:
                            return { referralNurseCaseManager: referralContactResult, loading: false, };
                        case ReferralContactTypesEnum.TreatingPhysician:
                            return { referralTreatingPhysician: referralContactResult, loading: false, };
                        case ReferralContactTypesEnum.OptionalPhysician:
                            return { optionalIMEPhysician: referralContactResult, loading: false, };
                    }
                });
                toast.success('Referral ' + referralContactTypeName + ' saved', { autoClose: 3000 });
            }
            catch {
                toast.warning('⚠️ ' + 'Error saving Referral ' + referralContactTypeName);
                this.setState({ loading: false });
            };
        }
    }

    updateDocuments = async () => {
        //this.setState({ loading: true });
        try {               
            if (this.state.selectedDocuments && this.state.selectedDocuments.length > 0) {
                let referralDocumentsResult = await sendReferralDocuments(
                    this.state.referralId,
                    { documents: this.state.selectedDocuments } as ReferralDocumentDataModel,
                    this.abortController.signal,
                )
                toast.success('Selected document saved', { autoClose: 3000 });
            }
        }
        catch {
            toast.warning('⚠️ ' + 'Error saving Referral Documents');
            this.setState({ loading: false });
        }
    }

    updatePt = async () => {
        //this.setState({ loading: true });
        try {
            
            let physicalTherapyResult = await sendReferralPT(this.state.referralId, this.state.medicalService, this.abortController.signal)
            this.setState(() => {
                return {
                    medicalService: physicalTherapyResult,
                    loading: false,
                };
            });

        }
        catch {
            toast.warning('⚠️ ' + 'Error saving Referral PT');
            this.setState({ loading: false });
        }
    }

    updateIme = async () => {
        //this.setState({ loading: true });
        try {
                
            let referralImeResult = await sendReferralIme(
                {
                    ime: { ...this.state.ime, referralId: this.state.referralId },
                    specificBodyParts: this.state.currentSpecificBodyParts,
                    coverLetter: this.state.imeCoverLetter,
                } as ImeDataModel,
                this.abortController.signal,
                this.state.ime.imeId,
            );
            const selectedBodyParts = referralImeResult.specificBodyParts.map((x) => x.specificBodyPartId);
            this.setState(() => {
                return {
                    ime: referralImeResult.ime,
                    currentSpecificBodyParts: referralImeResult.specificBodyParts,
                    specificBodyParts: selectedBodyParts,
                };
            });
            toast.success('Referral IME saved', { autoClose: 3000 });
        }
        catch {
            toast.warning('⚠️ ' + 'Error saving Referral IME');
            this.setState({ loading: false });
        }
    }

    dismissHandler = () => {
        this.setState({
            showErrorDialog: false,
        });
        
        const timeout = setTimeout(() => {
            const firstErrorElement = document.getElementsByClassName('Mui-error')[0];
            firstErrorElement?.scrollIntoView({ block: "center", inline: "nearest"});
            const inputEl = firstErrorElement?.parentElement?.children[1]?.children[0];
            (inputEl as any)?.focus();
        }, 100);
      
        return () => {
            clearTimeout(timeout);
        };
    }

    public render() {
        const { classes } = this.props;
        return (
            <ThemeProvider theme={newTheme}>
                <Fragment>
                    {this.state.loading ? (
                        <Loader />
                    )
                    : (
                        <Fragment>
                            <div className={classes.content}>
                                <Fragment>
                                    <form autoComplete="off">
                                        <div className={classes.fixedHead + ' ' + classes.diffBrowser}>
                                            <BreadCrumb sections={this.state.addReferralBreadCrumb} />
                                            <div className={classes.wizardBar}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <NavigationBar>
                                                            <WizardBar
                                                                steps={[
                                                                    'Service',
                                                                    'Referral', /*'Claim','Claimant','Client','Employer','Referral','Documents',*/
                                                                    'Submit',
                                                                ]}
                                                                title="Add Referrals"
                                                                showsteps="1"
                                                                setStepIndex={this.setStepIndex}
                                                                activeIndex={this.state.stepIndex}
                                                            ></WizardBar>
                                                            {(
                                                                this.state.stepIndex==1 && 
                                                                <Button                   
                                                                    className={classes.refreshButton}                                         
                                                                    variant="outlined"                                                            
                                                                    onClick={this.handleRefresh}
                                                                    disabled={
                                                                        this.state.stepIndex<1
                                                                    }                                   
                                                                    >
                                                                    {'Refresh'}
                                                                </Button>
                                                            )}
                                                            
                                                        </NavigationBar>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <div className={classes.wizardBar}>
                                                <Grid container spacing={3}>
                                                    <Grid item xs={12}>
                                                        <ClaimSidebar
                                                            claimDataModel={this.state.claim}
                                                            referral={this.state.referral}
                                                            employerName={this.state.referralEmployer?.name}
                                                            vendorName={this.getVendorName()}
                                                            serviceTypeName={getServiceTypeName(
                                                                this.state.services,
                                                                this.state.referral?.serviceTypeIds,
                                                            )}
                                                            referralStatus={this.state.referralStatus}
                                                            side={false}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>
                                        <div className={classes.formContent}>
                                            {this.getStepContent(this.state.stepIndex)}
                                        </div>
                                        <div className={classes.formFooterContainer}>
                                            <div className={classes.formFooter}>
                                                <div className={classes.firstBottomButton}>
                                                    {this.state.stepIndex !== 0 ? (
                                                        <Button
                                                            disabled={
                                                                this.state.pendingSubmission ||
                                                                this.state.stepIndex === 0 ||
                                                                this.state.stepIndex === 1
                                                            }
                                                            onClick={this.handleBack}
                                                            className={classes.backButton}
                                                        >
                                                            {
                                                                this.state.referral?.referralStatusId ===
                                                                    ReferralStatusTypesEnum.ValidationFailed 
                                                                    ? 'Edit'
                                                                    : 'Back'
                                                            }
                                                        </Button>
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                    <>
                                                        <Button
                                                            onClick={this.startOver}
                                                            className={classes.startoverButton}
                                                            disabled={this.state.pendingSubmission}
                                                        >
                                                            {this.state.referral &&
                                                            (this.state.referral.referralStatusId ===
                                                                ReferralStatusTypesEnum.SubmissionFailed ||
                                                                this.state.referral.referralStatusId ===
                                                                    ReferralStatusTypesEnum.ValidationFailed)
                                                                ? 'Go to Referral List'
                                                                : 'Start Over'}
                                                        </Button>
                                                        {( [1].includes(this.state.stepIndex) ) ?
                                                        (
                                                        <>
                                                            <Button
                                                                onClick={()=>{this.handleOpenCloseStatusAll(true)}}
                                                                className={classes.startoverButton}
                                                            >
                                                                {this.state.referral &&
                                                                'Expand all'}
                                                            </Button>
                                                            <Button
                                                                onClick={()=>{this.handleOpenCloseStatusAll(false )}}
                                                                className={classes.startoverButton}
                                                            >
                                                                {this.state.referral &&
                                                                'Collapse all'}
                                                            </Button>
                                                        </>
                                                        ): ('')}
                                                    </>
                                                </div>
                                                {   ( [1].includes(this.state.stepIndex) ) ?
                                                    (
                                                                <Button
                                                                    className={classes.saveAllButton}
                                                                    variant="contained"
                                                                    color="primary"
                                                                    onClick={this.handleSaveAll}
                                                                    disabled={
                                                                        this.state.pendingSubmission ||
                                                                        this.state.referral?.referralStatusId ===
                                                                            ReferralStatusTypesEnum.SubmissionFailed
                                                                    }
                                                                    >
                                                                    {'Save all'}
                                                                </Button>
                                                    ) : (<div></div>)
                                                }
                                                {   ( ![2].includes(this.state.stepIndex) ) ?
                                                    (
                                                        <Button
                                                            className={classes.nextButton}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={this.handleNext}
                                                            disabled={
                                                                this.state.pendingSubmission ||
                                                                this.state.referral?.referralStatusId ===
                                                                    ReferralStatusTypesEnum.SubmissionFailed
                                                            }
                                                            >
                                                            {'Next'}
                                                        </Button>
                                                    )
                                                    :
                                                    (
                                                        <Button
                                                            className={classes.nextButton}
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={this.handleNext}
                                                            disabled={
                                                                this.state.pendingSubmission ||
                                                                this.state.referral?.referralStatusId ===
                                                                    ReferralStatusTypesEnum.SubmissionFailed
                                                            }
                                                            >
                                                            {this.state.stepIndex === 2
                                                                ? this.state.referral?.referralStatusId ===
                                                                    ReferralStatusTypesEnum.SubmissionFailed ||
                                                                this.state.referral?.referralStatusId ===
                                                                    ReferralStatusTypesEnum.ValidationFailed ||
                                                                this.state.referral?.referralStatusId ===
                                                                    ReferralStatusTypesEnum.ReadyForSubmission
                                                                    ? 'Re-submit'
                                                                    : 'Submit'
                                                                : 'Next'}
                                                        </Button>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </form>
                                </Fragment>
                            </div>
                        </Fragment>
                    )}
                    <DialogCustom
                        title={'Validation'}
                        acceptButtonText={'OK'}
                        acceptHandler={this.dismissHandler}
                        closeHandler={this.dismissHandler}
                        open={this.state.showErrorDialog}
                        children={
                            <Typography>
                                {this.state.textErrorDialog}
                            </Typography>
                        }
                    />
                </Fragment>
            </ThemeProvider>
        );
    }
}

export const AddReferral = withRouter(withStyles(styles)(AddReferralComponent));
