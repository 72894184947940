import React, { Fragment, FunctionComponent, useEffect } from 'react';
import styles from './ReferringPhysician.Styles';
import { Grid, MenuItem } from '@material-ui/core';
import InputMask from 'react-input-mask';
import { EmptyDataLabel } from '../../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import StateEntity from '../../../../../../entities/State.Entity';
import ReferralContactEntity from '../../../../../../entities/ReferralContact.Entity';
import { ReferralContactTypesEnum } from '../../../../../../support/enums/ReferralContactTypesEnum';
import { IMedicalServiceReferralFormErrors } from '../MedicalServiceReferralForm.Component';
import { TooltipTextField } from '../../../../../generic-components/tooltip-textfield/TooltipTextField.Component';
import CloseIcon from '@material-ui/icons/Close';
import SearchAddressAsync, { AddressValue } from '../../../../../../components/generic-components/search-autocomplete/SearchAddressAsync'
import AddressAutocompleteEntity,{MakeFullAddress} from '../../../../../../entities/ReferralAddress.Entity';

interface IReferringPhysicianProps {
    referralId: number;
    states: Array<StateEntity>;
    referringPhysician: ReferralContactEntity;
    handleLocalServiceEdit: any;
    errors: IMedicalServiceReferralFormErrors;
    validate: (isChange?: boolean, isSubmit?: boolean) => void;
    hasBeenSubmitted: boolean;
    serviceId: number;
}

export const ReferringPhysician: FunctionComponent<IReferringPhysicianProps> = (props: IReferringPhysicianProps) => {
    const classes = styles();
    const abortController = new AbortController();

    const [referralReferringPhysicianState, setReferralReferringPhysicianState] = React.useState(
        props.referringPhysician,
    );
    const [counterState, setCounterState] = React.useState(props.referringPhysician ? props.referringPhysician : null);
    const [referringPhysicianFullAddress, setReferringPhysicianFullAddress] = React.useState("");

    const passAddress = (address:AddressAutocompleteEntity) => {
        
        let newTreatingPhysician: ReferralContactEntity = referralReferringPhysicianState;
        newTreatingPhysician["cityName"] = address.city;
        newTreatingPhysician["addressLine1"] = `${address.streetNumber? address.streetNumber:''} ${address.streetName}`;
        newTreatingPhysician["addressLine2"] = address.address2 ?? "";
        newTreatingPhysician["zipCode"] = address.zipCode;

        const stateItem = props.states.find(element => element.stateName==address.stateName);
        newTreatingPhysician["stateId"] = stateItem?.stateId;
        newTreatingPhysician["referralId"] = props.referralId;
        newTreatingPhysician["contactTypeId"] = ReferralContactTypesEnum.TreatingPhysician;
        setReferralReferringPhysicianState(newTreatingPhysician);
        setCounterState(newTreatingPhysician);
        setReferringPhysicianFullAddress(address.fullAddress);        
      }

      const handleClear = ()=>{
        
        let newTreatingPhysician: ReferralContactEntity = referralReferringPhysicianState
        newTreatingPhysician["cityName"] = undefined
        newTreatingPhysician["addressLine1"] = undefined
        newTreatingPhysician["addressLine2"] = undefined
        newTreatingPhysician["zipCode"] = undefined        
        newTreatingPhysician["stateId"] = undefined;
        
        setReferringPhysicianFullAddress("");
    
        setReferralReferringPhysicianState(newTreatingPhysician);
        setCounterState(newTreatingPhysician);
    }
 
    //componentDidMount
    useEffect(() => {
        if (!props.referringPhysician) {
            let newRP = {
                referralId: props.referralId,
                contactTypeId: ReferralContactTypesEnum.TreatingPhysician,
            } as ReferralContactEntity;
            setReferralReferringPhysicianState(newRP);
            setCounterState(newRP);
        } else {
            setReferralReferringPhysicianState(props.referringPhysician);
            setCounterState(props.referringPhysician);
        }

        return function cleanup() {
            abortController.abort();
        };
    }, [props]);

    useEffect(() => {
        if(referralReferringPhysicianState?.stateId)
        {
            const stateItem = props.states?.find(element => element.stateId==referralReferringPhysicianState.stateId);            
            setReferringPhysicianFullAddress(MakeFullAddress(referralReferringPhysicianState,stateItem.stateName));
        }
    }, [referralReferringPhysicianState]);

    const handleTextFieldChangeReferringPhysician = (event) => {
        event.persist();
        let newReferringPhysician: ReferralContactEntity = referralReferringPhysicianState;
        if (!newReferringPhysician.referralId) {
            newReferringPhysician.referralId = props.referralId;
        }

        if (event.target.type == 'checkbox') {
            newReferringPhysician[event.target.id ? event.target.id : event.target.name] = event.target.checked;
        } else {
            setCounterState({
                ...counterState,
                [event.target.id ? event.target.id : event.target.name]:
                    event.target.value == 'true'
                        ? true
                        : event.target.value == 'false'
                        ? false
                        : event.target.value.toString() == 'true'
                        ? true
                        : event.target.value.toString() == 'false'
                        ? false
                        : event.target.value,
            });
            newReferringPhysician[event.target.id ? event.target.id : event.target.name] =
                event.target.value == 'true'
                    ? true
                    : event.target.value == 'false'
                    ? false
                    : event.target.value.toString() == 'true'
                    ? true
                    : event.target.value.toString() == 'false'
                    ? false
                    : event.target.value;
        }

        setReferralReferringPhysicianState(newReferringPhysician);
        props.handleLocalServiceEdit(referralReferringPhysicianState);
        if (props.hasBeenSubmitted) props.validate(true, false);
    };
    
    return (
        <Fragment>
            {referralReferringPhysicianState ? (
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={3}>
                        <TooltipTextField
                            key="TreatingFirstName"
                            label="First Name"
                            id="firstName"
                            onChange={(event) => handleTextFieldChangeReferringPhysician(event)}
                            value={referralReferringPhysicianState ? referralReferringPhysicianState.firstName : ''}                            
                            helperText={props.errors?.referringPhysicianFirstName}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SearchAddressAsync passAddress ={passAddress} states={props.states} />
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            key="TreatingMI"
                            label="Middle initial"
                            id="middleInitial"
                            onChange={(event) => handleTextFieldChangeReferringPhysician(event)}
                            value={referralReferringPhysicianState ? referralReferringPhysicianState.middleInitial : ''}
                            inputProps={{ maxlength: 1 }}
                        />
                    </Grid>
                    
                    <Grid item xs={3}>
                      <AddressValue 
                        key="TreatingSearchAddressResult" 
                        id="SearchAddressResult" 
                        address={referringPhysicianFullAddress} 
                        handleClear={handleClear} 
                      />
                    </Grid>
                    <Grid item xs={3}>
                        
                    </Grid>
                    <Grid item xs={3}>
                        
                    </Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            key="TreatingLastName"
                            label="Last Name"
                            id="lastName"
                            onChange={(event) => handleTextFieldChangeReferringPhysician(event)}
                            value={referralReferringPhysicianState ? referralReferringPhysicianState.lastName : ''}                            
                            helperText={props.errors?.referringPhysicianLastName}
                        />
                    </Grid>
                    <Grid item xs={3}>
                    <InputMask
                            mask="(999)-9999999"
                            value={
                                referralReferringPhysicianState
                                    ? referralReferringPhysicianState.primaryPhoneNumber
                                    : ''
                            }
                            onChange={(event) => handleTextFieldChangeReferringPhysician(event)}
                            disabled={false}
                            maskChar=" "
                        >
                            {() => (
                                <TooltipTextField
                                    key="TreatingPrimary"
                                    label="Primary Phone"
                                    id="primaryPhoneNumber"
                                    error={!!props.errors?.referringPhysicianPrimaryPhoneNumber}
                                    helperText={props.errors?.referringPhysicianPrimaryPhoneNumber}
                                />
                            )}
                        </InputMask>
                    </Grid>
                    <Grid item xs={3}>
                    <TooltipTextField
                            key="TreatingPhoneExt"
                            label="Ext."
                            id="primaryPhoneExtension"
                            onChange={(event) => handleTextFieldChangeReferringPhysician(event)}
                            value={
                                referralReferringPhysicianState
                                    ? referralReferringPhysicianState.primaryPhoneExtension
                                    : ''
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        
                    </Grid>
                    <Grid item xs={3}>
                        <InputMask
                            mask="9999999999"
                            value={
                                referralReferringPhysicianState
                                    ? referralReferringPhysicianState.treatingPhysicianNPI
                                    : ''
                            }
                            onChange={(event) => handleTextFieldChangeReferringPhysician(event)}
                            disabled={false}
                            maskChar=" "
                        >
                            {() => (
                                <TooltipTextField
                                    key="TreatingNPI"
                                    label="Physician NPI"
                                    id="treatingPhysicianNPI"
                                    error={!!props.errors?.referringPhysicianTreatingNPI}
                                    helperText={props.errors?.referringPhysicianTreatingNPI}
                                />
                            )}
                        </InputMask>
                    </Grid>
                    <Grid item xs={3} spacing={3}>
                    <InputMask
                            mask="(999)-9999999"
                            value={
                                referralReferringPhysicianState
                                    ? referralReferringPhysicianState.secondaryPhoneNumber
                                    : ''
                            }
                            onChange={(event) => handleTextFieldChangeReferringPhysician(event)}
                            disabled={false}
                            maskChar=" "
                        >
                            {() => (
                                <TooltipTextField
                                    key="TreatingSecondary"
                                    label="Secondary Phone"
                                    id="secondaryPhoneNumber"
                                />
                            )}
                        </InputMask>
                    </Grid>
                    <Grid item xs={3}>
                    <TooltipTextField
                            key="TreatingSecondaryExt"
                            label="Ext."
                            id="secondaryPhoneExtension"
                            onChange={(event) => handleTextFieldChangeReferringPhysician(event)}
                            value={
                                referralReferringPhysicianState
                                    ? referralReferringPhysicianState.secondaryPhoneExtension
                                    : ''
                            }
                        />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <TooltipTextField
                            key="TreatingEmail"
                            label="Email"
                            id="email"
                            onChange={(event) => handleTextFieldChangeReferringPhysician(event)}
                            value={referralReferringPhysicianState ? referralReferringPhysicianState.email : ''}
                            error={!!props.errors?.referringPhysicianEmail}
                            helperText={props.errors?.referringPhysicianEmail}
                        />
                    </Grid>
                    
                    <Grid item xs={3}>
                    <InputMask
                            mask="(999)-9999999"
                            value={referralReferringPhysicianState ? referralReferringPhysicianState.fax : ''}
                            onChange={(event) => handleTextFieldChangeReferringPhysician(event)}
                            disabled={false}
                            maskChar=" "
                        >
                            {() => (
                                <TooltipTextField
                                    key="TreatingFix"
                                    label="Fax"
                                    id="fax"
                                    error={!!props.errors?.referringPhysicianFax}
                                    helperText={props.errors?.referringPhysicianFax}
                                />
                            )}
                        </InputMask>
                        {/* Empty space */}
                    </Grid>
                    <Grid item xs={12}>
                        <TooltipTextField
                            key="TreatingAdditionalComments"
                            maxRows={4}
                            multiline={true}
                            label="Additional Comments"
                            id="comments"
                            onChange={(event) => handleTextFieldChangeReferringPhysician(event)}
                            value={referralReferringPhysicianState ? referralReferringPhysicianState.comments : ''}
                            inputProps={{ maxLength: 250 }}
                            helperText={`${counterState.comments ? counterState.comments.length : 0}/${250}`}
                        />
                    </Grid>
                </Grid>
            ) : (
                <EmptyDataLabel></EmptyDataLabel>
            )}
        </Fragment>
    );
};
