import { makeStyles } from "@material-ui/core";

const drawerWidth = 320;

export default makeStyles((theme) => ({
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#FFFFFF',
        color: '#004763',
    },
    drawerPermanentPaper: {
        width: drawerWidth,
        backgroundColor: '#FFFFFF',
        color: '#004763',
        [theme.breakpoints.up('sm')]: {
            // paddingTop: theme.mixins.toolbar[theme.breakpoints.up("sm")].minHeight
            marginTop: theme.mixins.toolbar.minHeight,
        },
    },
    drawerContainer: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column'
    }
}));