import { apiGet } from '../support/helpers/ApiConnection.Helper';
import ProductCategoryEntity from '../entities/dme/ProductCategory.Entity';
import ProductEntity from '../entities/dme/Product.Entity';

export default function getProductCategories(abortSignal: AbortSignal): Promise<Array<ProductCategoryEntity>> {
    return apiGet<Array<ProductCategoryEntity>>('/product-category', abortSignal);
}

export function getProductsByCategoryId(id: number, abortSignal: AbortSignal): Promise<ProductEntity[]> {
    return apiGet<ProductEntity[]>(`/product-category/${id}`, abortSignal);
}
