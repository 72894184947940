import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, Typography } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { FunctionComponent } from "react";
import styles from './Confirm.Styles';

type ConfirmProps = {
    children?: React.ReactNode,
    messageText?:string;
    title: string;
    onAction:() => void;
    openDialog:boolean;
    onToggleConfirm: () => void;
    okText:string;
    noText:string;
    hideCancel?:boolean;
    hideConfirm?:boolean;
    loading?:boolean;
}

export const Confirm: FunctionComponent<ConfirmProps> = ({ 
    children,messageText, title, onAction, openDialog, onToggleConfirm, okText, noText,
    hideCancel = false,
    hideConfirm = false,
    loading = false
}) => {
    const classes = styles();

    return (
        <Dialog open={openDialog} onClose={onToggleConfirm} PaperComponent={Paper}>
            <DialogTitle disableTypography>
            <Grid container alignItems="center" spacing={2} wrap="nowrap">
                <Grid item xs>
                <Typography className="tc-title">{title}</Typography>
                </Grid>
                <Grid item>
                <IconButton aria-label="close" size="small" onClick={onToggleConfirm}>
                    <Close />
                </IconButton>
                </Grid>
            </Grid>
            </DialogTitle>
            <DialogContent className={classes.content}>
            {messageText && <div className="tc-mb2">{messageText}</div>}
            {children}
            </DialogContent>
            <DialogActions className={classes.actions}>
            {!hideCancel && (
                <Button variant="outlined" autoFocus onClick={onToggleConfirm} className={classes.cancelButton} disabled={loading}>
                {noText}
                </Button>
            )}
            {!hideConfirm && (
                <Button variant="contained" onClick={onAction} className={classes.button} disabled={loading}>
                {okText}
                </Button>
            )}
            </DialogActions>
        </Dialog>
  );
}