import { createStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 320;

export default (theme: Theme) =>
    createStyles({
        content: {
            margin: '25px',
        },
        referralHeaderContainer: {
            display: 'flex',
            marginBottom: '15px',
        },
        referralHeader: {
            color: '#648C9B',
        },
    });
