import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    paper: {
        marginTop: '20px',
        //padding:'10px',
        //paddingTop: '15px',
        //paddingLeft: '35px',
        //paddingRight: '35px',
        //paddingBottom: '25px',
    },
    container: {
        marginTop: '20px',
    },
    paperTitle: {
        color: '#428302',
        marginBottom: '15px',
    },
    link: {
        color: '#107A98',
        cursor: 'pointer',
    },
    buttonsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginTop: '35px',
        paddingRight: '15px',
        paddingBottom: '15px',
    },
    button: {
        color: 'white',
        backgroundColor: '#107A98',
        marginLeft: '20px',
        '&:hover': {
            backgroundColor: '#004763!important',
        },
    },
}));
