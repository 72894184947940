import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary } from '@material-ui/core';
import styles from "./ClaimantAttorneySummary.Styles";
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import StateEntity from '../../../../entities/State.Entity';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';

interface IClaimantAttorneySummaryProps {
    referralClaimantAttorney: ReferralContactEntity;
    states: Array<StateEntity>;
    serviceId?: number;
}

export const ClaimantAttorneySummary: FunctionComponent<IClaimantAttorneySummaryProps> = (props: IClaimantAttorneySummaryProps) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>Claimant Attorney</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.referralClaimantAttorney ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid item xs={12}>
                                <Typography variant="body2" className={classes.dataLabel}>Attorney Represented</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney?.isRepresented ? 'Yes' : 'No'}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Firm</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney.firm}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Address Line 1</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney.addressLine1}</Typography>
                            </Grid>
                            {
                                props.serviceId === ServicesEnum.Diagnostic ?
                                <Grid container xs={3} spacing={1} style={{ marginTop:'9px', marginRight: '4px'}}>
                                    <Grid item xs={3} style={{ marginLeft: '12px', marginRight: '-15%' }}>
                                        <Typography variant="body2" className={classes.dataLabel}>Zip Code</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney.zipCode}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Grid item xs={3} style={{ marginTop:'12px',marginLeft: '15px', marginRight: '-8%' }}>
                                            _
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3} style={{marginRight: '15px'}}>
                                        <Typography variant="body2" className={classes.dataLabel}>Zip Code Ext.</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney.zipCodeExtension}</Typography>
                                    </Grid>
                                </Grid>
                                :
                                <Grid item xs={3}>
                                    <Typography variant="body2" className={classes.dataLabel}>Zip Code</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney.zipCode}</Typography>
                                </Grid>

                            }
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Attorney Full Name</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney.name}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Attorney First Name</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney.firstName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Address Line 2</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney.addressLine2}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Primary Phone</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney.primaryPhoneNumber}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Ext.</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney.primaryPhoneExtension}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Attorney Last Name</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney.lastName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>City</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney.cityName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Secondary Phone</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney.secondaryPhoneNumber}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Ext.</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney.secondaryPhoneExtension}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Email</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney.email}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>State</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.states?.filter(x => !!props.referralClaimantAttorney && !!props.referralClaimantAttorney.stateId && x.stateId == props.referralClaimantAttorney.stateId)[0]?.stateName}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" className={classes.dataLabel}>Fax</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaimantAttorney.fax}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" className={classes.dataLabel}>Additional Comments</Typography>
                                {props.referralClaimantAttorney?.comments?.split('\n').map((sentence, key) => (
                                    <Typography key={key} variant="subtitle2" className={classes.blackFont}>
                                        {sentence}
                                    </Typography>
                                ))}
                            </Grid>
                        </Grid>
                        : <EmptyDataLabel></EmptyDataLabel>
                }
            </AccordionDetails>
        </Accordion>
    );
}