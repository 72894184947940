import React, { FunctionComponent, useEffect, useState } from 'react';
import { Route, useParams, useRouteMatch } from 'react-router-dom';
import ServiceDataModel from '../../../data-models/Service.DataModel';
import VendorContactDataModel from '../../../data-models/VendorContact.DataModel';
import VendorProfileDataModel from '../../../data-models/VendorProfile.DataModel';
import StateEntity from '../../../entities/State.Entity';
import VendorContactEntity from '../../../entities/VendorContact.Entity';
import VendorAuthCredentialEntity from '../../../entities/VendorAuthCredential.Entity';
import { BreadCrumb } from '../../../support/custom-hooks/useBreadCrum';
import { VendorContacts } from '../vendor-contacts/VendorContacts.Component';
import { VendorAuthCredentials } from '../vendor-auth-credentials/VendorAuthCredentials.Component';
import { VendorProfile } from '../vendor-profile/VendorProfile.Component';
import styles from './VendorProfileController.Styles';
import VendorAuthCredentialDataModel from '../../../data-models/VendorAuthCredential.DataModel';
import VendorAuthenticationTypeEntity from '../../../entities/AuthenticationType.Entity';

interface VendorProfileControllerProps {
    profile: VendorProfileDataModel;
    currentTabIndex: string;
    states: Array<StateEntity>;
    authenticationTypes: Array<VendorAuthenticationTypeEntity>;
    handleVendorEdit: (newVendor: VendorProfileDataModel) => void;
    handleStartVendorProfileEdit: () => void;
    handleCancelVendorProfileEdit: () => void;
    handleSaveEditedVendorProfile: () => void;
    vendorContacts: Array<VendorContactDataModel>;
    vendorAuthCredentials: Array<VendorAuthCredentialDataModel>;
    newVendorContact: VendorContactEntity;
    handleVendorContactEdit: (newVendor: VendorContactEntity) => void;
    createVendorContact: () => void;
    newVendorAuthCredential: VendorAuthCredentialEntity;
    handleVendorAuthCredentialEdit: (newVendor: VendorAuthCredentialEntity) => void;
    createVendorAuthCredential: () => void;
    services: Array<ServiceDataModel>;
    handleNavigateToProfile: (id: number, section: string) => void;
}

export const VendorProfileController: FunctionComponent<VendorProfileControllerProps> = ({
    profile,
    currentTabIndex,
    states,
    authenticationTypes,
    handleVendorEdit,
    handleStartVendorProfileEdit,
    handleCancelVendorProfileEdit,
    handleSaveEditedVendorProfile,
    vendorContacts,
    vendorAuthCredentials,
    newVendorContact,
    handleVendorContactEdit,
    createVendorContact,
    newVendorAuthCredential,
    handleVendorAuthCredentialEdit,
    createVendorAuthCredential,
    services,
    handleNavigateToProfile,
}) => {
    const { id } = useParams<{ id: string }>();
    const classes = styles();
    const { path } = useRouteMatch();

    useEffect(() => {
        if (profile === null) {
            handleNavigateToProfile(parseInt(id), 'Vendor');
        }
    }, []);

    const vendorsBreadCrumb = {
        name: 'Vendors',
        goBack: false,
        link: '/vendors',
        path: path,
    };

    const vendorProfileBreadCrumb = {
        name: `${profile?.vendorName} Profile`,
        goBack: false,
        link: `/vendors/${id}/profile`,
        path: path,
    };

    const vendorContactsBreadCrumb = {
        name: `${profile?.vendorName} Contacts`,
        goBack: false,
        link: `/vendors/${id}/contacts`,
        path: path,
    };

    const vendorAuthCredentialsBreadCrumb = {
        name: `${profile?.vendorName} AuthCredentials`,
        goBack: false,
        link: `/vendors/${id}/authcredentials`,
        path: path,
    };
    return (
        <>
            <Route path="/vendors/:id/profile" exact>
                <BreadCrumb sections={[vendorsBreadCrumb, vendorProfileBreadCrumb]} />
                <div className={classes.container}>
                    <VendorProfile
                        handleVendorEdit={handleVendorEdit}
                        vendor={profile}
                        states={states}
                        handleStartVendorProfileEdit={handleStartVendorProfileEdit}
                        handleCancelVendorProfileEdit={handleCancelVendorProfileEdit}
                        handleSaveEditedVendorProfile={handleSaveEditedVendorProfile}
                        services={services}
                    />
                </div>
            </Route>
            <Route path="/vendors/:id/contacts" exact>
                <BreadCrumb sections={[vendorsBreadCrumb, vendorContactsBreadCrumb]} />
                <div className={classes.container}>
                    <VendorContacts
                        vendorContacts={vendorContacts}
                        newVendorContact={newVendorContact}
                        handleVendorContactEdit={handleVendorContactEdit}
                        createVendorContact={createVendorContact}
                        states={states}
                        vendorId={profile?.vendorId}
                    />
                </div>
            </Route>
            <Route path="/vendors/:id/authcredentials" exact>
                <BreadCrumb sections={[vendorsBreadCrumb, vendorAuthCredentialsBreadCrumb]} />
                <div className={classes.container}>
                    <VendorAuthCredentials
                        vendorAuthCredentials={vendorAuthCredentials}
                        newVendorAuthCredential={newVendorAuthCredential}
                        handleVendorAuthCredentialEdit={handleVendorAuthCredentialEdit}
                        createVendorAuthCredential={createVendorAuthCredential}
                        states={states}
                        authenticationTypes={authenticationTypes}
                        vendorId={profile?.vendorId}
                    />
                </div>
            </Route>
        </>
    );
};
