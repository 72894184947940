import { createTheme , makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    gridContainer: {
        width: '100%',
    },
    paper: {
        paddingBottom: '50px',
        paddingTop: '35px',
        paddingLeft: '50px',
        paddingRight: '50px'
    },
    dataLabel: {
        color: '#898F91',
        fontSize: '12px'
    },
    iconTextRow: {
        display: 'inline-flex',
        flexDirection: 'row'
    },
    blackFont: {
        color: '#000000'
    },
    greyFont: {
        color: '#898f91'
    },
    iconData: {
        color: '#000000',
        marginLeft: 10
    },
    paperHeader: {
        flexDirection: 'row-reverse'
    },
    paperTitle: {
        color: '#428302',
        marginBottom:'35px',
    },
    divider: {
        height: '1px',
        marginTop: 20,
        color: '#898F91'
    },
    paperHeaderIcon: {
        color: '#428302'
    },
    scoreRow: {
        display: 'flex',
        alignContent: 'center',
        alignItems: 'center'
    },
    bolderFont: {
        color: '#000000',
        fontWeight: 'bolder'
    },
    tableContainer: {
        maxHeight: 440
    },
    greenProgress: {
        color: '#FFF',
        backgroundColor: '#018749',
        padding: '5px',
        margin: '5px',
        fontWeight: 'bolder'
    },
    yellowProgress: {
        color: '#002244',
        backgroundColor: '#FFD700',
        padding: '5px',
        margin: '5px',
        fontWeight: 'bolder'
    },
    redProgress: {
        color: '#FFF',
        backgroundColor: '#960018',
        padding: '5px',
        margin: '5px',
        fontWeight: 'bolder'
    }
}));

export const imeScorecardTheme = createTheme ({
    overrides: {
        MuiRadio: {
            colorPrimary: {
                '&$disabled': {
                    color: '#898F91'
                },
                '&$checked': {
                    color: '#107A98!important'
                },
            },
        }
    },
});