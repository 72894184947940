import MSCoverLetterEntity from "../entities/pt/PTCoverLetter.Entity";
import ReferralVisitEntity from "../entities/pt/ReferralVisit.Entity";
import DiagnosticProcedureEntity from "../entities/DiagnosticProcedure.Entity";
import ServiceTypeResponseEntity from "../entities/pt/ServiceTypeResponse.Entity";
import ServiceTypeScorecardResponseEntity from "../entities/pt/ServiceTypeScorecardResponse.Entity";
import ReferralContactEntity from "../entities/ReferralContact.Entity";
import ReferralScheduleEntity from "../entities/ReferralSchedule.Entity";
import ScorecardResponsesChartContentDataModel from "./pt/ScorecardResponsesChartContentDataModel";
import DiagnosticProcedureTableDataModel from "./DiagnosticProcedureTable.DataModel";
import ServiceTypeRequestDxEntity from "../entities/ServiceTypeRequestDx.Entity";

export default class DiagnosticServiceDataModel {
    serviceTypeRequestDxId: number;
    referralId: number;
    serviceId: number;
    diagnosticProcedureDetail: number;
    serviceTypeRequestDx: ServiceTypeRequestDxEntity;
    procedures: Array<DiagnosticProcedureEntity>;
    lastVisit: ReferralVisitEntity;
    progressSummary: string;
    referringPhysician: ReferralContactEntity;
    response: ServiceTypeResponseEntity;
    visits: Array<ReferralVisitEntity>;
    notes: string;
    singleSchedule?: ReferralScheduleEntity;
    scorecardResponse: Array<ServiceTypeScorecardResponseEntity>;
    scorecardResponsesChartContent: Array<ScorecardResponsesChartContentDataModel>;
    proceduresTable: Array<DiagnosticProcedureTableDataModel>;
}