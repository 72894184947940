import React, { FunctionComponent, useEffect, useState } from 'react';
import { Paper, Button, Typography } from '@material-ui/core';
import styles from './ErrorManagementReferralList.Styles';
import moment from 'moment';
import { MaterialTableProps } from 'material-table';
import MaterialTable from '../../generic-components/material-table/MaterialTable.Component';
import ReferralStatusEntity from '../../../entities/ReferralStatus.Entity';
import ReferralErrorListDataModel from '../../../data-models/ReferralErrorList.DataModel';
import { Confirm } from '../../generic-components/confirm/Confirm.Component';
import { Loader } from '../../generic-components/loader/Loader.Component';
import { Section } from '../../../support/custom-hooks/useBreadCrum';
import AccessControl from '../../generic-components/access-control/AccessControl.Component';
import { referralSubmissionRules } from '../../../support/constants/SecurityRules.Constants';

interface IErrorManagementReferralListProps {
    referrals: Array<ReferralErrorListDataModel>;
    referralStatus: Array<ReferralStatusEntity>;
    setReferralReadyForSubmission: (referralId: number) => void;
    handleGetErrorManagement: () => Promise<void>;
}

export const ErrorManagementReferralList: FunctionComponent<IErrorManagementReferralListProps> = ({
    referralStatus,
    referrals,
    setReferralReadyForSubmission,
    handleGetErrorManagement,
}: // resetBreadcrum,
IErrorManagementReferralListProps) => {
    const [filter, setFilter] = useState(false);
    const [showVendorError, setShowVendorError] = useState(false);
    const [errorMessage, setErrorMessage] = useState<{ key: string; value: string }[]>(null);
    const [errorText, setErrorText] = useState<string>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const classes = styles();

    const referralStatusOptions = {};
    referralStatus?.forEach((referralStatus) => {
        const { referralStatusId, referralStatusLabel } = referralStatus;
        referralStatusOptions[referralStatusId] = referralStatusLabel;
    });

    useEffect(() => {
        setIsLoading(true);
        handleGetErrorManagement()
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    }, []);
    const tableProps: MaterialTableProps<ReferralErrorListDataModel> = {
        columns: [
            {
                title: 'Referral Number',
                render: (rowData) => rowData.referralId,
                customSort: (a, b) => a.referralId - b.referralId,
                field: 'referralId',
                headerStyle: {
                    maxWidth: '10%',
                    minWidth: '10%',
                },
                cellStyle: {
                    maxWidth: '10%',
                    minWidth: '10%',
                },
            },
            {
                title: 'Claim Id',
                render: (rowData) => rowData.claimId,
                customSort: (a, b) => a.claimId - b.claimId,
                field: 'claimId',
                headerStyle: {
                    maxWidth: '7%',
                    minWidth: '7%',
                },
                cellStyle: {
                    maxWidth: '7%',
                    minWidth: '7%',
                },
            },
            {
                title: 'Vendor',
                field: 'vendorName',
                headerStyle: {
                    maxWidth: '10%',
                    minWidth: '10%',
                },
                cellStyle: {
                    maxWidth: '10%',
                    minWidth: '10%',
                },
            },
            {
                title: 'Referral Status',
                field: 'referralStatusId',
                lookup: referralStatusOptions,
                headerStyle: {
                    maxWidth: '10%',
                    minWidth: '10%',
                },
                cellStyle: {
                    maxWidth: '10%',
                    minWidth: '10%',
                },
            },
            {
                title: 'Referral Status Date',
                render: (rowData) =>
                    rowData.referralStatusDate ? moment(rowData.referralStatusDate).format('M/D/YYYY') : '',
                customSort: (a, b) =>
                    new Date(a.referralStatusDate)?.getTime() - new Date(b.referralStatusDate)?.getTime(),
                headerStyle: {
                    maxWidth: '10%',
                    minWidth: '10%',
                },
                cellStyle: {
                    maxWidth: '10%',
                    minWidth: '10%',
                },
            },
            {
                title: 'Service',
                field: 'serviceName',
                headerStyle: {
                    maxWidth: '10%',
                    minWidth: '10%',
                },
                cellStyle: {
                    maxWidth: '10%',
                    minWidth: '10%',
                },
            },
            {
                title: 'Service Type',
                field: 'serviceTypeNames',
                render: (rowData) => rowData.serviceTypeNames.join(', '),
            },
            {
                title: 'Submitted Failed Date',
                field: 'submittedFailedDate',
                render: (rowData) =>
                    rowData.submittedFailedDate ? moment(rowData.submittedFailedDate).format('M/D/YYYY') : '-',
            },
        ],
        data: referrals ?? [],
        actions: [
            {
                icon: 'tune',
                tooltip: 'Filter',
                isFreeAction: true,
                onClick: () => setFilter(!filter),
            },
            (rowData) => ({
                icon: (p) => (
                    <Button {...p} variant="contained" color="primary" style={{ backgroundColor: '#107A98' }}>
                        View Error
                    </Button>
                ),
                position: 'row',
                onClick: (event, rowData) => {
                    if (Array.isArray(rowData)) return;

                    if (rowData.compressedFileRequestIdFailed !== null) {
                        const parsedJson = JSON.parse(rowData.compressedFileRequestIdFailed);
                        const newErrorMessage = [];
                        for (let [key, value] of Object.entries(parsedJson)) {
                            newErrorMessage.push({ key, value });
                        }
                        setErrorMessage(newErrorMessage);
                        setErrorText(null);
                    } else if (rowData.compressedFileErrorReason !== null) {
                        setErrorText(rowData.compressedFileErrorReason);
                        setErrorMessage(null);
                    } else if (rowData.vendorAnswer !== null) {
                        let jsonString = JSON.stringify(rowData.vendorAnswer, null, 2);
                        jsonString = jsonString.replaceAll('\\', '');
                        setErrorText(jsonString);
                        setErrorMessage(null);
                    }
                    setShowVendorError(true);
                },
            }),
            {
                icon: (p) => (
                    <AccessControl allowedPermissions={referralSubmissionRules.update} sendBack={false}>
                        <Button {...p} variant="contained" color="primary" style={{ backgroundColor: '#107A98' }}>
                            Ready
                        </Button>
                    </AccessControl>
                ),
                tooltip: 'Set Ready for Submission',
                position: 'row',
                onClick: (event, rowData) => {
                    if (Array.isArray(rowData)) return;
                    setReferralReadyForSubmission(rowData.referralId);
                },
            },
        ],
        options: {
            filtering: filter,
            showTitle: true,
            paging: referrals ? referrals.length >= 5 : false,
            toolbar: true,
            rowStyle: (rowData) => ({
                //    backgroundColor: rowData.referralStatusId == ReferralStatusTypesEnum.SubmissionFailed || rowData.referralStatusId == ReferralStatusTypesEnum.ValidationFailed ? "#ff000042" : "inherit"
            }),
            actionsColumnIndex: -1,
        },
        localization: {
            header: {
                actions: '',
            },
        },
        title: 'Referrals',
    };

    return (
        <Paper className={classes.clientListPaper}>
            {isLoading ? <Loader isSmall /> : <MaterialTable tableProps={tableProps} />}
            <Confirm
                onAction={() => setShowVendorError(false)}
                title="Vendor Error"
                onToggleConfirm={() => setShowVendorError(!showVendorError)}
                okText="Ok"
                openDialog={showVendorError}
                noText=""
                hideCancel
            >
                <>
                    {errorMessage != null &&
                        errorMessage?.map((em) => (
                            <div className={classes.errorMessageContainer} key={em.key}>
                                <Typography variant="body2">{em.key}:</Typography>

                                <Typography variant="body2">{JSON.stringify(em.value)}</Typography>
                            </div>
                        ))}
                    {errorText != null &&
                        errorText.split(',').map((error) => (
                            <>
                                <Typography variant="body2">{error},</Typography>
                                <br />
                            </>
                        ))}
                </>
            </Confirm>
        </Paper>
    );
};
