import React, { FunctionComponent, useEffect, useState } from 'react';
import { Paper, Button } from '@material-ui/core';
import styles from './AdjustersReferralList.Styles';
import MaterialTable from '../../../generic-components/material-table/MaterialTable.Component';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { MaterialTableProps } from 'material-table';
import { ReferralStatusTypesEnum } from '../../../../support/enums/ReferralStatusTypesEnum';
import ReferralStatusEntity from '../../../../entities/ReferralStatus.Entity';
import ReferralEntity from '../../../../entities/Referral.Entity';
import DeleteIcon from '@material-ui/icons/Delete';
import { Confirm } from '../../../generic-components/confirm/Confirm.Component';
import { useDateFilter } from '../../../../support/custom-hooks/useDateFilter';
import { SubscriptionsOutlined } from '@material-ui/icons';

interface IAdjustersReferralListProps {
    referrals: Array<IReferralEntityTable>;
    referralStatus: Array<ReferralStatusEntity>;
    handleNewReferralClick: any;
    handleDeleteReferral: (referralId: number) => void;
}
export interface IReferralEntityTable extends ReferralEntity {
    joinedServiceTypeNames: string;
}

export const AdjustersReferralList: FunctionComponent<IAdjustersReferralListProps> = ({
    handleDeleteReferral,
    referralStatus,
    referrals,
    handleNewReferralClick,
}: IAdjustersReferralListProps) => {
    const [filter, setFilter] = useState(false);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [referralToDelete, setReferralToDelete] = useState<number>(null);
    const [localIsStatusFilterActive, setLocalIsStatusFilterActive] = useState<boolean>(false);

    const classes = styles();

    const referralStatusOptions = {};
    referralStatus.forEach((referralStatus) => {
        const { referralStatusId, referralStatusLabel } = referralStatus;
        referralStatusOptions[referralStatusId] = referralStatusLabel;
    });

    const [localReferrals, setLocalReferrals] = useState<Array<ReferralEntity>>();
    const { InputFilters, isFilterActive } = useDateFilter(
        referrals,
        localReferrals,
        setLocalReferrals,
        'dateSubmitted',
        localIsStatusFilterActive,
    );
    const { InputFilters: StatusInputFilters, isFilterActive: isStatusFilterActive } = useDateFilter(
        referrals,
        localReferrals,
        setLocalReferrals,
        'referralStatusDate',
        isFilterActive,
    );

    useEffect(() => {
        setLocalIsStatusFilterActive(isStatusFilterActive);
    }, [isStatusFilterActive]);

    const tableProps: MaterialTableProps<ReferralEntity> = {
        columns: [
            {
                title: 'Referral Number',
                render: (rowData) => {
                    return rowData.referralStatusId === ReferralStatusTypesEnum.Open ||
                        rowData.referralStatusId === ReferralStatusTypesEnum.SubmissionFailed ||
                        rowData.referralStatusId === ReferralStatusTypesEnum.ValidationFailed ||
                        rowData.referralStatusId === ReferralStatusTypesEnum.ReadyForSubmission ? (
                        <Link to={`/claims/${rowData.claimId}/referrals/edit/${rowData.referralId}`}>
                            {rowData.referralId}
                        </Link>
                    ) : (
                        <Link to={`/claims/${rowData.claimId}/referrals/${rowData.referralId}`}>
                            {rowData.referralId}
                        </Link>
                    );
                },
                customSort: (a, b) => a.referralId - b.referralId,
                field: 'referralId',
            },
            {
                title: 'Client',
                field: 'referralClientName',
            },
            { title: 'Vendor', field: 'vendorName' },
            {
                title: 'Date Submitted',
                field: 'dateSubmitted',
                render: (rowData) => (rowData.dateSubmitted ? moment(rowData.dateSubmitted).format('MM/DD/YYYY') : ''),
                customSort: (a, b) => new Date(a.dateSubmitted)?.getTime() - new Date(b.dateSubmitted)?.getTime(),
                filterComponent: () => InputFilters,
            },
            {
                title: 'Referral Status',
                field: 'referralStatusId',
                lookup: referralStatusOptions,
            },
            {
                title: 'Referral Status Date',
                field: 'referralStatusDate',
                render: (rowData) =>
                    rowData.referralStatusDate ? moment(rowData.referralStatusDate).format('M/D/YYYY') : '',
                customSort: (a, b) =>
                    new Date(a.referralStatusDate)?.getTime() - new Date(b.referralStatusDate)?.getTime(),
                filterComponent: () => StatusInputFilters,
            },
            { title: 'Service', field: 'serviceName' },
            {
                title: 'Service Type',
                field: 'joinedServiceTypeNames',
            },
        ],
        data: localReferrals ?? [],
        actions: [
            {
                icon: 'tune',
                tooltip: 'Filter',
                isFreeAction: true,
                onClick: () => setFilter(!filter),
            },
            {
                icon: (p) => (
                    <Button
                        {...p}
                        variant="contained"
                        color="primary"
                        style={{ marginLeft: 10, backgroundColor: '#107A98' }}
                    >
                        New Referral
                    </Button>
                ),
                isFreeAction: true,
                onClick: () => handleNewReferralClick(),
            },
            (rowData) => ({
                icon: (rowData) => (rowData.disabled ? <></> : <DeleteIcon style={{ fill: 'rgba(0,0,0,0.54)' }} />),
                tooltip: rowData.referralStatusId !== ReferralStatusTypesEnum.Open ? '' : 'Delete referral',
                position: 'row',
                onClick: (event, rowData) => {
                    if (Array.isArray(rowData)) return;
                    if (rowData.referralStatusId !== ReferralStatusTypesEnum.Open) return;
                    setReferralToDelete(rowData.referralId);
                    setShowConfirmDelete(true);
                },
                disabled: rowData.referralStatusId !== ReferralStatusTypesEnum.Open,
            }),
        ],
        options: {
            filtering: filter,
            showTitle: true,
            paging: referrals ? referrals.length >= 10 : false,
            toolbar: true,
            exportButton: true,
            search: true,
            exportAllData: true,
            pageSize:10,
            exportFileName: 'TerraClaimConnect_Referral_List',
            rowStyle: (rowData) => ({
                backgroundColor:
                    rowData.referralStatusId === ReferralStatusTypesEnum.SubmissionFailed ||
                    rowData.referralStatusId === ReferralStatusTypesEnum.ValidationFailed ||
                    rowData.referralStatusId === ReferralStatusTypesEnum.ReadyForSubmission
                        ? '#ff000042'
                        : 'inherit',
            }),
            actionsColumnIndex: -1,
        },
        localization: {
            header: {
                actions: '',
            },
        },
        title: 'Referrals',
    };

    return (
        <>
            <Paper className={classes.clientListPaper}>
                <MaterialTable tableProps={tableProps} />
            </Paper>
            <Confirm
                onAction={() => {
                    handleDeleteReferral(referralToDelete);
                    setShowConfirmDelete(false);
                }}
                title="Delete Referral"
                messageText={`Are you sure you want to delete referral ${referralToDelete}?`}
                onToggleConfirm={() => {
                    setShowConfirmDelete(false);
                }}
                openDialog={showConfirmDelete}
                okText="Delete"
                noText="Cancel"
            />
        </>
    );
};
