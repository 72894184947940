import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import styles from "./MedicalServiceReferralForm.Styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ServiceTypeEntity from '../../../../../entities/ServiceType.Entity';
import { PtHeader } from './pt-header/PtHeader.Component';
import BodySideEntity from '../../../../../entities/BodySide.Entity';
import InjuryTypeEntity from '../../../../../entities/pt/InjuryType.Entity';
import SpecificBodyPartEntity from '../../../../../entities/SpecificBodyPart.Entity';
import { ReferringPhysician } from './referring-physician/ReferringPhysician.Component';
import StateEntity from '../../../../../entities/State.Entity';
import MedicalServiceDataModel from "../../../../../data-models/MedicalService.DataModel";
import { useValidationDialog } from "../../../../../support/custom-hooks/useValidationDialog";
import DiagnosticProcedureDetailDataModel from '../../../../../data-models/DiagnosticProcedureDetail.DataModel';
import DiagnosticServiceDataModel from '../../../../../data-models/DiagnosticService.DataModel';
import ReferralContactEntity from '../../../../../entities/ReferralContact.Entity';
import ServiceTypePTEntity from '../../../../../entities/pt/ServiceTypePT.Entity';
import LanguageEntity from '../../../../../entities/Language.Entity';
import ServiceTypeRequestPtDetailDataModel from "../../../../../data-models/pt/ServiceTypeRequestPtDetail.DataModel";
import AdditionalInformationDataModel from '../../../../../data-models/pt/AdditionalInformation.DataModel';
import { AdditionalInformation } from './additional-information/AdditionalInformation.Component';

interface IMedicalServiceReferralFormProps {
    referralId: number,
    medicalService: MedicalServiceDataModel,
    bodySides: Array<BodySideEntity>,
    injuryTypes: Array<InjuryTypeEntity>,
    specificBodyParts: Array<SpecificBodyPartEntity>,
    states: Array<StateEntity>,
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean ) => void,
    handleSetIsValidPtFunction: (currentPageIsValidPtFunction: () => boolean, serviceTypeString: string) => void;

    openCloseStatus: Object;
    handleOpenCloseStatus: (boxName: string, isOpened: boolean) => void;

    serviceId?: number,
    serviceTypes: Array<ServiceTypeEntity>,
    getMedicalService?: any,
    procedureDetails: Array<DiagnosticProcedureDetailDataModel>,
    diagnosticService: DiagnosticServiceDataModel,
    nextStep: boolean,
    handleMedicalServiceEdit: any,
    handleDiagnosticServiceEdit: any,
    handleSpecificBodyPart: (sbpId: number) => void,
    languages: Array<LanguageEntity>,
    deleteServiceTypeRequestPtDetail: (serviceTypeRequestPtDetailId: number) => void,
    handleClearInjuryTypes: () => void,
}

export interface IMedicalServiceReferralFormErrors {
    ptNumberOfApprovedVisits?: string,
    ptFrequencyAndDuration?: string,
    injuryType?: string,
    specificBodyPart?: string,
    bodySide?: string,
    referringPhysicianFirstName?: string,
    referringPhysicianLastName?: string,
    referringPhysicianTreatingNPI?: string,
    referringPhysicianAddressLine1?: string,
    referringPhysicianAddressLine2?: string,
    referringPhysicianZipCode?: string,
    referringPhysicianCityName?: string,
    referringPhysicianState?: string,
    referringPhysicianEmail?: string,
    referringPhysicianPrimaryPhoneNumber?: string,
    referringPhysicianFax?: string,
    scheduleSpecialInstructions?: string,
    prescriptionRx?: string;
    impressionResults?: string;
    proceduresError?:string;
    specificBodyPartId?:string;
}

export const MedicalServiceReferralForm: FunctionComponent<IMedicalServiceReferralFormProps> = (props: IMedicalServiceReferralFormProps) => {

    
    let isValidPtProcedureValidationFunction = null;
    const classes = styles();
    const [errors, setErrors] = React.useState<IMedicalServiceReferralFormErrors>(null);
    const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState<boolean>(false);
    const {Dialog, checkForErrors} = useValidationDialog("Validation","Some required fields are missing.","Ok");
    const {Dialog:DialogProcedures, checkForErrors:checkForErrorsProcedures} = useValidationDialog("Validation","At least one specific body part must be added","Ok");

    const [medicalServiceLocalState, setMedicalServiceLocalState] = useState<MedicalServiceDataModel>(props.medicalService);
    
    //#region additionalInformation
    const[additionalInformation,setAdditionalInformation] = useState<AdditionalInformationDataModel>(props.medicalService?.additionalInformation);
    const handleAdditionalInformationEdit = (value) => {
        setAdditionalInformation(value)
        let newMD : MedicalServiceDataModel = props.medicalService;
        newMD.additionalInformation = value;
        setMedicalServiceLocalState(newMD);
    }
    //#endregion

    //#region referringPhysician
    const[referringPhysician,setReferringPhysician] = useState<ReferralContactEntity>(props.medicalService?.referringPhysician);
    const handleReferringPhysicianEdit = (value) => {
        setReferringPhysician(value)
        let newMD : MedicalServiceDataModel = props.medicalService;
        newMD.referringPhysician = value;
        setMedicalServiceLocalState(newMD);
    }
    //#endregion
    
    //#region serviceTypePT
    const[serviceTypePT,setServiceTypePT] = useState<ServiceTypePTEntity>(props.medicalService?.serviceType);
    const handleServiceTypePTEdit = (value) => {
        setServiceTypePT(value);
        let newMD : MedicalServiceDataModel = props.medicalService;
        newMD.serviceType = value;
        setMedicalServiceLocalState(newMD);
    }
    //#endregion

    //#region serviceTypePT
    const [serviceTypeRequestPtDetail, setServiceTypeRequestPtDetail] = useState<Array<ServiceTypeRequestPtDetailDataModel>>(props.medicalService?.serviceType?.serviceTypeRequestPtDetail);
    const handleServiceTypeRequestPtDetailEdit = (value) => {
        setServiceTypeRequestPtDetail(value);
        let newMD : MedicalServiceDataModel = props.medicalService;
        newMD.serviceType.serviceTypeRequestPtDetail = value;
        setMedicalServiceLocalState(newMD);
    }
    //#endregion

    useEffect(() => {
        //props.handleSetValidationFunction(validatePT);
        props.handleSetIsValidPtFunction(isValidPT, "PT");
        props.handleSetIsValidPtFunction(isValidPtProcedureValidationFunction, "PTProcedure");
    }, [props]);
    
    useEffect(() => { 
        let preService;
        preService = medicalServiceLocalState;
        
        preService.referringPhysician = referringPhysician;

        let medicalService: MedicalServiceDataModel = props.medicalService;
        medicalService.serviceType = serviceTypePT;
        medicalService.serviceType.serviceTypeRequestPtDetail = serviceTypeRequestPtDetail;
        props.handleMedicalServiceEdit(medicalService);

    }, [props.nextStep]);

    useEffect(() => {
        if ( !props.openCloseStatus.hasOwnProperty( "Pm_box" ) )  props.handleOpenCloseStatus( "Pm_box", true );
        if ( !props.openCloseStatus.hasOwnProperty( "PmAdditionalInformation_box" ) )  props.handleOpenCloseStatus( "PmAdditionalInformation_box", true );
        if ( !props.openCloseStatus.hasOwnProperty( "PmReferringPhysician_box" ) ) props.handleOpenCloseStatus( "PmReferringPhysician_box", true );

        if (!props.medicalService) {
            let newMS = new MedicalServiceDataModel;
            setMedicalServiceLocalState(newMS);
        } else {
            let newMS : MedicalServiceDataModel = props.medicalService;
            setMedicalServiceLocalState(newMS);
        }
    }, []);

    const handleOpenCloseStatus = ( event, boxName ) => {
        event.persist();
        props.handleOpenCloseStatus( boxName, !(props.openCloseStatus?.[boxName]??true) );
    };
    
    const setIsValidPtProcedureFunction = (isValidFunction) => {
        isValidPtProcedureValidationFunction = isValidFunction;
    }

    const validatePT = (isChange?: boolean, isSubmit: boolean = true) => {
        let errors: IMedicalServiceReferralFormErrors = {};
        errors = getErrorsListPT()
        setErrors(errors);
        return checkForErrors(errors, isChange);
    };

    const isValidPT = () => { //returns true/false to know if the form has passed the validation OK (true) or not (false)
        let errors: IMedicalServiceReferralFormErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsListPT();
        setErrors(errors);
        isValidResult = (Object.keys(errors).length == 0);
        return isValidResult;
    }

    const getErrorsListPT = () => {//returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: IMedicalServiceReferralFormErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }

        if (!medicalServiceLocalState.serviceType?.numberOfApprovedVisits) errors.ptNumberOfApprovedVisits = 'Required';
        if (!medicalServiceLocalState.serviceType?.frequencyAndDuration) errors.ptFrequencyAndDuration = 'Required';
        
        //if (!medicalServiceLocalState.serviceType?.serviceTypeRequestPtDetail.length) /* && isSubmit */ {
        //    errors.specificBodyPartId = "Required";
        //}
        
        return errors;
    }

    return (
        <Fragment>
            <Grid item xs={12}>
                <Accordion className={classes.paper} expanded={props.openCloseStatus["Pm_box"]??true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                        aria-controls="panel1a-content"
                        id="cliam-accordion"
                        className={classes.paperHeader}
                        onClick={(event) => handleOpenCloseStatus(event, "Pm_box")}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.sidebarTitle}><p></p></Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.sidebarTitle}>Physical Medicine (PM)</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="caption"
                                    className={classes.actionSubtitle}
                                >
                                    At least one specific body part must be added
                                </Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <PtHeader
                            handleServiceTypePTEdit={handleServiceTypePTEdit}
                            serviceTypePT={serviceTypePT}
                            referralId={props.referralId}
                            bodySides={props.bodySides}
                            injuryTypes={props.injuryTypes}
                            specificBodyParts={props.specificBodyParts}
                            errors={errors}
                            validate={validatePT}
                            handleSetIsValidPtProcedureFunction={setIsValidPtProcedureFunction}
                            hasBeenSubmitted={hasBeenSubmitted}
                            handleSpecificBodyPart={props.handleSpecificBodyPart}
                            languages={props.languages}
                            handleServiceTypeRequestPtDetailEdit={handleServiceTypeRequestPtDetailEdit}
                            deleteServiceTypeRequestPtDetail={props.deleteServiceTypeRequestPtDetail}
                            handleClearInjuryTypes={props.handleClearInjuryTypes}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Accordion className={classes.paper} expanded={props.openCloseStatus["PmAdditionalInformation_box"]??true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                        aria-controls="panel1a-content"
                        id="adjuster-accordion"
                        className={classes.paperHeader}
                        onClick={(event) => handleOpenCloseStatus(event, "PmAdditionalInformation_box")}
                    >
                        <Typography variant="subtitle2" className={classes.sidebarTitle}>Additional Information</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AdditionalInformation
                            handleLocalServiceEdit={handleAdditionalInformationEdit}
                            referralId={props.referralId}
                            additionalInformation={additionalInformation}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Accordion className={classes.paper} expanded={props.openCloseStatus["PmReferringPhysician_box"]??true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                        aria-controls="panel1a-content"
                        id="adjuster-accordion"
                        className={classes.paperHeader}
                        onClick={(event) => handleOpenCloseStatus(event, "PmReferringPhysician_box")}
                    >
                        <Typography variant="subtitle2" className={classes.sidebarTitle}>Referring Physician</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ReferringPhysician
                            handleLocalServiceEdit={handleReferringPhysicianEdit}
                            referringPhysician={referringPhysician}
                            referralId={props.referralId}
                            states={props.states}
                            errors={errors}
                            validate={validatePT}
                            hasBeenSubmitted={hasBeenSubmitted}
                            serviceId={props.serviceId}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>
            {Dialog}
            {DialogProcedures}
        </Fragment>
    );
}