import React, { FunctionComponent } from 'react';
import styles from "./MsAdditionalInformation.Styles";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { EmptyDataLabel } from '../../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import Moment from 'moment';
import { TooltipTextField } from "../../../../../generic-components/tooltip-textfield/TooltipTextField.Component";
import MedicalServiceDataModel from "../../../../../../data-models/MedicalService.DataModel";

interface IMsAdditionalInformation {
    medicalService: MedicalServiceDataModel
}

export const MsAdditionalInformation: FunctionComponent<IMsAdditionalInformation> = (props: IMsAdditionalInformation) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.sidebarTitle}>Additional Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.medicalService ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid item xs={3}>
                                <TooltipTextField
                                    label='Date Of Hire' 
                                    disabled
                                    value={!!props.medicalService.additionalInformation?.dateOfHire ? Moment(props.medicalService.additionalInformation?.dateOfHire).format('MM/DD/YYYY') : ""}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                    label='Job Title' 
                                    disabled
                                    value={!!props.medicalService.additionalInformation?.jobTitle ? props.medicalService.additionalInformation?.jobTitle : ""}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                    label='Date The Employee Will Return To Work' 
                                    disabled
                                    value={!!props.medicalService.additionalInformation?.dateTheEmployeeWillReturnToWork ? Moment(props.medicalService.additionalInformation?.dateTheEmployeeWillReturnToWork).format('MM/DD/YYYY') : ""}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                {/* EMPTY SPACE */}
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                    label='ICD Medical Diagnosis Code 1' 
                                    disabled
                                    value={!!props.medicalService.additionalInformation?.icdMedicalDiagnosisCode1 ? props.medicalService.additionalInformation?.icdMedicalDiagnosisCode1 : ""}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                    label='ICD Medical Diagnosis Code 2' 
                                    disabled
                                    value={!!props.medicalService.additionalInformation?.icdMedicalDiagnosisCode2 ? props.medicalService.additionalInformation?.icdMedicalDiagnosisCode2 : ""}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                    label='ICD Medical Diagnosis Code 3' 
                                    disabled
                                    value={!!props.medicalService.additionalInformation?.icdMedicalDiagnosisCode3 ? props.medicalService.additionalInformation?.icdMedicalDiagnosisCode3 : ""}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                {/* EMPTY SPACE */}
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                    label='Did the Back Injury Cause the Leg Pain?' 
                                    disabled
                                    value={!!props.medicalService.additionalInformation?.didTheBackInjuryCauseTheLegPain ? props.medicalService.additionalInformation?.didTheBackInjuryCauseTheLegPain : ""}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                    label='Have You Had Surgeries To This Body Part In The Past?' 
                                    disabled
                                    value={!!props.medicalService.additionalInformation?.haveYouHadSurgeriesToThisBodyPartInThePast ? props.medicalService.additionalInformation?.haveYouHadSurgeriesToThisBodyPartInThePast : ""}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                {/* EMPTY SPACE */}
                            </Grid>
                            <Grid item xs={3}>
                                {/* EMPTY SPACE */}
                            </Grid>                            
                        </Grid>
                        : <EmptyDataLabel></EmptyDataLabel>
                }
            </AccordionDetails>
        </Accordion>

    );
}