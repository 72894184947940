import React, { FunctionComponent } from 'react';
import styles, { newTheme } from './ReferralTraker.Styles';
import {
    Typography,
    Step,
    StepLabel,
    StepContent,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    StepConnector,
    ThemeProvider,
    Grid,
} from '@material-ui/core';
import Moment from 'moment';
import ActivityEntity from '../../../../entities/Activity.Entity';
import ActivityTypeEntity from '../../../../entities/ActivityType.Entity';
import { Stepper } from '@material-ui/core';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';
import ReferralScheduleEntity from '../../../../entities/ReferralSchedule.Entity';
import { ReferralScheduleStatusTypesEnum } from '../../../../support/enums/ReferralScheduleStatusTypesEnum';
import ReferralNotificationTrackerDataModel from '../../../../data-models/ReferralNotificationTracker.DataModel';

type ReferralTrakerProps = {
    referralActivities: Array<ActivityEntity>;
    activityTypes: Array<ActivityTypeEntity>;
    referralSchedule: ReferralScheduleEntity;
    serviceId: number;
    referralNotificationTracker: ReferralNotificationTrackerDataModel;
};

function getSteps(serviceId: number) {
    if (serviceId === ServicesEnum.IME) {
        return [
            'Referral Submitted',
            'Referral Accepted',
            'IME Scheduled',
            'Claimant Verified They Will Attend',
            'Claimant Attended',
            'Doctor Summary',
            'Overview',
            'Close Date',
        ];
    } else if (serviceId === ServicesEnum.Diagnostic) {
        return [
            'Referral Submitted',
            'Referral Accepted',
            'Diagnostic Scheduled',
            'Attendance Verified',
            'Claimant Attendance',
            'Doctor Summary',
            'Scorecard',
            'Close Date',
        ];
    } else if (serviceId === ServicesEnum.UR) {
        return ['Referral Submitted', 'Referral Accepted', 'Information Received', 'Close Date'];
    } else if (serviceId === ServicesEnum.DME) {
        return ['Referral Submitted', 'Referral Accepted', 'Close Date'];
    } else if (serviceId === ServicesEnum.TT) {
        return ['Referral Submitted', 'Referral Accepted', 'Appointment Scheduled', 'Review Notes', 'Close Date'];
    } else {
        return [
            'Referral Submitted',
            'Referral Accepted',
            'PM Scheduled',
            'Claimant Attendance',
            'Discharge Notes',
            'Scorecards',
            'Close Date',
        ];
    }
}

function getStepContent(step) {
    return '';
}

function getStepDate(
    step,
    activities: Array<ActivityEntity>,
    serviceId: number,
    referralNotificationTracker: ReferralNotificationTrackerDataModel,
    referralSchedule?: ReferralScheduleEntity,
) {
    let ActivityTypeToShow = 0;
    if (serviceId == ServicesEnum.IME) {
        switch (step) {
            case 0: // text to show: Referral Submitted
                ActivityTypeToShow = 2; // on db: Referral Submitted
                break;
            case 1: // Referral Accepted
                ActivityTypeToShow = 5; // on db: Referral In Progress
                break;
            case 2: // IME Scheduled
                ActivityTypeToShow = 18; // on db: Schedule date updated
                break;
            case 3: // Attendance Verified
                ActivityTypeToShow = 9; // on db: Claimant Attendance Verified
                break;
            case 4: // Claimant Attendance
                ActivityTypeToShow = 11; // on db: Schedule Completed
                break;
            case 5: // Doctor Summary
                ActivityTypeToShow = 13; // on db: Referral Response Doctor summary & others
                break;
            case 6: // Overview
                ActivityTypeToShow = 15; // on db: Referral Response ScoreCard
                break;
            case 7: // Close Date
                ActivityTypeToShow = 16; // on db: Referral Completed
                break;
        }
    } else if (serviceId == ServicesEnum.Diagnostic) {
        switch (step) {
            case 0: // text to show: Referral Submitted
                ActivityTypeToShow = 2; // on db: Referral Submitted
                break;
            case 1: // Referral Accepted
                ActivityTypeToShow = 5; // on db: Referral In Progress
                break;
            case 2: // Diagnostic Scheduled
                ActivityTypeToShow = 18; // on db: Schedule date updated
                break;
            case 3: // Attendance Verified
                ActivityTypeToShow = 9; // on db: Claimant Attendance Verified
                break;
            case 4: // Claimant Attendance
                ActivityTypeToShow = 11; // on db: Schedule Completed
                break;
            case 5: // Doctor Summary
                ActivityTypeToShow = 13; // on db: Referral Response Doctor summary & others
                break;
            case 6: // Scorecard
                ActivityTypeToShow = 15; // on db: Referral Response ScoreCard
                break;
            case 7: // Close Date
                ActivityTypeToShow = 16; // on db: Referral Completed
                break;
        }
    } else if (serviceId === ServicesEnum.UR) {
        switch (step) {
            case 0: // Referral Submitted
                ActivityTypeToShow = 2; // Referral Submitted
                break;
            case 1: // Referral Accepted
                ActivityTypeToShow = 5; // Referral In Progress
                break;
            case 2: // Information Received
                ActivityTypeToShow = 23; // Referral Response
                break;
            case 3: // Close Date
                ActivityTypeToShow = 16; // Referral Completed
                break;
        }
    } else if (serviceId === ServicesEnum.TT) {
        switch (step) {
            case 0: // Referral Submitted
                ActivityTypeToShow = 2; // Referral Submitted
                break;
            case 1: // Referral Accepted
                ActivityTypeToShow = 5; // Referral In Progress
                break;
            case 2: // Appointment Scheduled
                ActivityTypeToShow = 6; // Referral Schedule
                break;
            case 3: // Review Notes
                ActivityTypeToShow = 23; // Referral Response
                break;
            case 4: // Close Date
                ActivityTypeToShow = 16; // Referral Completed
                break;
        }
    } else { // PM Service
        switch (step) {
            case 0: // Referral Submitted
                ActivityTypeToShow = 2; // Referral Submitted
                break;
            case 1: // Referral Accepted
                ActivityTypeToShow = 5; // Referral In Progress
                break;
            case 2: // PM Scheduled
                ActivityTypeToShow = 18; // on db: Schedule date updated
                break;
            case 3: // Claimant Attendance
                ActivityTypeToShow = 9; // on db: Claimant Attendance Verified
                break;
            case 4: // Discharge Notes
                ActivityTypeToShow = 32; // on bd: Discharge Note
                break;
            case 5: // Scorecard
                ActivityTypeToShow = 15; // on db: Referral Response ScoreCard
                break;
            case 6: // Close Date
                ActivityTypeToShow = 16; // Referral Completed
                break;
        }
    }
    let returned = false;
    let myDate;

    activities?.forEach((activity, index) => {
        if (ActivityTypeToShow == 9) {
            if (
                serviceId !== ServicesEnum.Diagnostic &&
                serviceId !== ServicesEnum.DME &&
                referralNotificationTracker.claimantAttendance
            ) {
                returned = true;
                myDate = referralNotificationTracker.visitDate;
            } else if (activity.activityTypeId === ActivityTypeToShow) {
                if (!returned) {
                    returned = true;
                    myDate = activity.date;
                }
            }
        } else if (ActivityTypeToShow === 16) {
            if (referralNotificationTracker.closedDate !== null) {
                returned = true;
                myDate = referralNotificationTracker.closedDate;
            }
        } else if (ActivityTypeToShow === 23 && serviceId === ServicesEnum.UR) {
            if (referralNotificationTracker.serviceLineUrDate !== null) {
                returned = true;
                myDate = referralNotificationTracker.serviceLineUrDate;
            }
        } else if (activity.activityTypeId == ActivityTypeToShow) {
            if (!returned) {
                returned = true;
                myDate = activity.date;
            }
        } else if (ActivityTypeToShow == 18 && (activity.activityTypeId == 6 || activity.activityTypeId == 27)) {
            if (!returned) {
                returned = true;
                myDate = activity.date;
            }
        } else if (ActivityTypeToShow === 15 && activity.activityTypeId == 30) {
            if (!returned) {
                returned = true;
                myDate = activity.date;
            }
        }
    });
    if (returned) {
        return Moment(myDate).format('MM/DD/YYYY');
    } else {
        return '-';
    }
}

function getStepActive(
    activities: Array<ActivityEntity>,
    referralNotificationTracker: ReferralNotificationTrackerDataModel,
    serviceId: number,
    referralSchedule?: ReferralScheduleEntity,
) {
    let activiteStep = -1;
    activities?.forEach((activity, index) => {
        switch (activity.activityTypeId) {
            case 2:
                if (activiteStep <= 0) activiteStep = 0;
                break;
            case 5:
                if (activiteStep <= 1) activiteStep = 1;
                break;
            case 18:
                if (activiteStep <= 2) activiteStep = 2;
                break;
            case 6:
                if (activiteStep <= 2) activiteStep = 2;
                break;
            case 9:
                if (
                    activiteStep <= 3 &&
                    referralSchedule?.referralScheduleStatusTypeId != ReferralScheduleStatusTypesEnum.Approved &&
                    referralNotificationTracker.claimantAttendance !== null
                )
                    activiteStep = 3;
                break;
            case 12:
                if (activiteStep <= 3) activiteStep = 2;
                break;
            case 27:
                if (activiteStep <= 3) activiteStep = 2;
                break;
            case 11:
                if (activiteStep <= 4) activiteStep = 4;
                break;
            case 32:
                if (activiteStep <= 4) activiteStep = 4;
                break;
            case 13:
                if (serviceId == ServicesEnum.Diagnostic && referralNotificationTracker.doctorSummary) {
                    activiteStep = 5;
                } else if (activiteStep <= 5) activiteStep = 5;
                break;
            case 15:
                if (activiteStep <= 6) activiteStep = 6;
                break;
            case 16:
                if (activiteStep <= 7) activiteStep = 7;
                break;
            case 28:
                if (referralNotificationTracker.claimantAttendance == null) activiteStep = 2;
                break;
        }
    });
    return activiteStep + 1;
}

export const ReferralTraker: FunctionComponent<ReferralTrakerProps> = ({
    referralActivities = null,
    activityTypes = null,
    serviceId = null,
    referralSchedule = null,
    referralNotificationTracker,
}: ReferralTrakerProps) => {
    const classes = styles();
    const steps = getSteps(serviceId);

    return (
        <ThemeProvider theme={newTheme}>
            <Accordion defaultExpanded={true} className={classes.paper} square>
                <AccordionSummary aria-controls="panel1a-content" id="cliam-accordion" className={classes.paperHeader}>
                    <Typography variant="subtitle2" className={classes.paperTitle}>
                        Referral Notification Tracker
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stepper
                        activeStep={getStepActive(
                            referralActivities,
                            referralNotificationTracker,
                            serviceId,
                            referralSchedule,
                        )}
                        orientation="vertical"
                        className={classes.stepper}
                        connector={<StepConnector className={classes.connector} />}
                    >
                        {steps.map((label, index) => (
                            <Step
                                key={label}
                                completed={
                                    getStepDate(
                                        index,
                                        referralActivities,
                                        serviceId,
                                        referralNotificationTracker,
                                        referralSchedule,
                                    ) != '-'
                                }
                            >
                                <StepLabel icon=" ">
                                    <Grid container spacing={0}>
                                        <Grid item xs={6}>
                                            {label}
                                        </Grid>
                                        <Grid item xs={6} className={classes.activityDates}>
                                            {getStepDate(
                                                index,
                                                referralActivities,
                                                serviceId,
                                                referralNotificationTracker,
                                                referralSchedule,
                                            )}
                                        </Grid>
                                    </Grid>
                                </StepLabel>
                                <StepContent>
                                    <Typography>{getStepContent(index)}</Typography>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </AccordionDetails>
            </Accordion>
        </ThemeProvider>
    );
};
