import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
    root: {
        flexGrow: 1,
        width: '100%',
        height: '100%',
        position: 'absolute',
        left: '0',
    },
});
