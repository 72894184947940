import React,{ useCallback, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, SvgIcon } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import lodash from "lodash";
import { GetSearchAddress } from '../../../services/Referrals.Service';
import AddressAutocompleteEntity from '../../../entities/ReferralAddress.Entity';
import { array } from '../../../support/common';
import LinkButton from '../linkButton/LinkButton.Component';
import { LocationModal } from './LocationModal.Component';
import { usePageModal } from '../../../hooks/usePageModal'; 
import { TooltipTextField } from '../tooltip-textfield/TooltipTextField.Component';
import './SearchAddressAsync.css';

export const AddressValue = ({ address, handleClear, ...other }) => {
  return (
    <Grid item container alignContent="center" alignItems="flex-end" xs={other?.xs || 12} className={((address || "").length > 0 ? "tcc-border-bottom" : "")}>
      <Grid item xs>
        <TooltipTextField
            key={other?.key}
            label=""
            id={other?.id}
            disabled
            value={address}
        />
      </Grid>
      {!other.disabled && (address || "").length > 0 && (
        <Grid item>
          <SvgIcon style={{ alignSelf: "flex-end", marginBottom: "-0.1rem" }} className="tcc-link tcc-pointer" onClick={handleClear}>
            <CloseIcon />
          </SvgIcon>
        </Grid>
      )}
    </Grid>
  );
};

const SearchAddressAsync = (props) => {
  //const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [valueState, setValueState] = React.useState("");
  const [dialogProps, onOpen] = usePageModal(false);

  const selectAddress = (v) => {
    props.passAddress(v);
    setValueState("");
    setOptions([]);
    setLoading(false);
  }
  const handleSelected =(event,value) => {
    event.persist();
    if(value == null)
        return false;    
    selectAddress(value);
  }

  const onchangeSearch = async (event) =>{
    event.persist();

    setValueState(event.target.value)
    
    if(event.target.value.length<3)
      return false;

    //if (event.target.value.length>0)
      //setOpen(true);

    if (event.target.value.length>3)
      setLoading(true);

    handler(event.target.value);
  }

  const fetchData = async (value) =>{
    let addressResult = null;
    const abortController = new AbortController();
    [addressResult] =
                  await Promise.all([
                      await GetSearchAddress(value,abortController.signal),
                  ]);

    const rows = [];    
    for (let i = 0; i < addressResult.length; i++) {
        let address : AddressAutocompleteEntity = new AddressAutocompleteEntity();                        
        address.id=addressResult[i].id;
        address.city = addressResult[i].address.municipality;
        address.stateCode = addressResult[i].address.countrySubdivision;
        address.stateName = addressResult[i].address.countrySubdivisionName;
        address.streetName = addressResult[i].address.streetName;
        address.streetNumber = addressResult[i].address.streetNumber;
        address.zipCode = addressResult[i].address.postalCode;
        address.fullAddress = `${address.streetNumber?address.streetNumber+" ":""}${address.streetName?address.streetName:""} ${address.city?address.city+", ":""} ${address.stateName? `${address.stateName} `:"" }${ address.zipCode? address.zipCode:""}`;
        address.name = address.fullAddress;
        address.title = address.fullAddress;
        
        rows.push(address);
    }
    setOptions(rows);
    setLoading(false);
  }

  const menuRef = useRef<HTMLInputElement>(null);
  const handler = useCallback(lodash.debounce(fetchData, 1000), []);

  React.useEffect(() => {
    let handler = (e)=>{
      if(!menuRef.current.contains(e.target)){
        setOptions([]);
        setLoading(false);
      }
    };

    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    }
  });

  const openCustomModal = () => { onOpen(); }

  const onAddNewLocation = (l: any) => { selectAddress(l); };
  
  const componentLoading = () => {
    return (
      <Grid container justifyContent='center' spacing={1} alignItems='center'>
        <Grid item><CircularProgress size={20} /></Grid>
        <Grid item>{" Loading..."}</Grid>
      </Grid>
    );
  }

  return (
    <React.Fragment>
    <div ref={menuRef} style={{position:'relative'}}>   
      <TextField
        value={valueState}
        style={{ width: "100%", height: "25px" }}
        label="Search Address"
        variant="standard" 
        onChange={(e) => onchangeSearch(e)}
        autoComplete='none'      
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          )
        }}
      />    
      {loading ? (
        <div className="results-list-loading">
          {componentLoading()}
        </div>
      ) : (
        <div className="results-list" >        
          {options
            .map((item,i) => (
              <div key={`result-item-${i}`} className="search-result" onClick={(e) => {handleSelected(e,item)}}>
                {item.fullAddress}
              </div>
            ))
          }
          {array(options).length > 0 && <LinkButton onClick={openCustomModal} blue className="tcc-ml2 tcc-ptb-1 tcc-plr-0">Add new address manually</LinkButton>}
        </div>
      )}
    </div>
    <LocationModal {...{ onAddNewLocation, states: props.states }} {...dialogProps} />
    </React.Fragment>
  );
}

export default SearchAddressAsync;