import { createStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 320;

export default (theme: Theme) => createStyles({
  content: {
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingTop: '15px'
  },
  referralHeaderContainer: {
    display: 'flex',
    marginBottom: '15px'
  },
  referralHeader: {
    color: '#648C9B'
  },
  referralNumber: {
    marginLeft: '5px',
    fontWeight: 'bold',
    color: '#004763'
  },
  container: {
    height: 'fit-content'
  }
});