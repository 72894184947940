import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { FunctionComponent, useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import UserWithRulesDataModel from '../../../data-models/UserWithRules.DataModel';
import VendorAdminTableDataModel from '../../../data-models/VendorAdminTable.DataModel';
import TenantEntity from '../../../entities/Tenant.Entity';
import UserEntity from '../../../entities/User.Entity';
import { getTenants } from '../../../services/Tenants.Services';
import { editUser, getUserProfile, userAssignSecurityProfile } from '../../../services/Users.Service';
import { getVendorAdminTable } from '../../../services/Vendors.Service';
import { AccessControlSecurityRuleAccordion } from '../access-control-security-rule-accordion/AccessControlSecurityRuleAccordion.Component';
import styles from './AccessControlUserProfile.Styles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Loader } from '../../generic-components/loader/Loader.Component';
import { Confirm } from '../../generic-components/confirm/Confirm.Component';
import { AccessControlSelectSecurityProfile } from '../access-control-select-security-profile/AccessControlSelectSecurityProfile.Component';
import { getSecurityProfileById } from '../../../services/SecurityProfiles.Service';
import { toast } from 'react-toastify';
import { Edit } from '@material-ui/icons';
import { ClearSelect } from '../../generic-components/clear-select/ClearSelect.Component';
import { BreadCrumb } from '../../../support/custom-hooks/useBreadCrum';

export const AccessControlUserProfile: FunctionComponent<any> = () => {
    const classes = styles();
    const { id } = useParams<{ id: string }>();
    const abortController = new AbortController();
    const { path } = useRouteMatch();

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [userProfile, setUserProfile] = React.useState<UserWithRulesDataModel>({ user: null, securityProfiles: [] });
    const { securityProfiles, user } = userProfile;
    const [vendors, setVendors] = React.useState<VendorAdminTableDataModel[]>([]);
    const [tenants, setTenants] = React.useState<TenantEntity[]>([]);
    const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
    const [originalUser, setOriginalUser] = React.useState<UserEntity>(null);

    const [showAddEditSecurityProfiles, setShowAddEditSecurityProfiles] = React.useState<boolean>(false);
    const [localAssignedSecurityProfiles, setLocalAssignedSecurityProfiles] = React.useState<
        {
            securityProfileId: number;
            securityProfileName: string;
        }[]
    >([]);

    useEffect(() => {
        handleInitialLoad();
    }, []);

    const handleInitialLoad = async () => {
        setIsLoading(true);
        await handleGetUserProfile();
        await handleGetVendors();
        await handleGetTenants();

        setIsLoading(false);
    };

    const handleGetUserProfile = async () => {
        const userProfile = await getUserProfile(id, abortController.signal);
        setUserProfile(userProfile);
    };

    const handleGetVendors = async () => {
        const vendors = await getVendorAdminTable(abortController.signal);
        setVendors(vendors);
    };

    const handleGetTenants = async () => {
        const tenants = await getTenants(abortController.signal);
        setTenants(tenants);
    };

    const handleTextFieldChange = (
        event:
            | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            | React.ChangeEvent<{ name?: string; value: unknown; id?: string }>,
    ) => {
        event.persist();
        const newUser: UserEntity = { ...user };
        newUser[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;

        setUserProfile({ ...userProfile, user: newUser });
    };

    if (isLoading) {
        return <Loader isSmall />;
    }

    const handleAddEditSecurityProfiles = () => {
        setShowAddEditSecurityProfiles(false);
        const newSecurityProfiles = [...securityProfiles];

        const newSecurityProfilesIds: number[] = [];
        const removedSecurityProfileIds: number[] = [];

        newSecurityProfiles.forEach((s, idx) => {
            const spIdx = localAssignedSecurityProfiles.findIndex((sp) => s.securityProfileId === sp.securityProfileId);

            if (spIdx === -1) {
                removedSecurityProfileIds.push(s.securityProfileId);
            }
        });

        localAssignedSecurityProfiles.forEach((sp) => {
            const idx = securityProfiles.findIndex((s) => s.securityProfileId === sp.securityProfileId);

            if (idx === -1) {
                newSecurityProfilesIds.push(sp.securityProfileId);
            }
        });

        handleLoadNewSecurityProfiles(newSecurityProfilesIds, removedSecurityProfileIds);
    };

    const handleLoadNewSecurityProfiles = async (
        newSecurityProfilesIds: number[],
        removedSecurityProfileIds: number[],
    ) => {
        const newSecurityProfiles = [...securityProfiles];

        for (const sp of newSecurityProfilesIds) {
            const spResult = await getSecurityProfileById(sp, abortController.signal);
            newSecurityProfiles.push(spResult);
        }

        const finalSecurityProfiles = newSecurityProfiles.filter(
            (s) => removedSecurityProfileIds.findIndex((sp) => sp === s.securityProfileId) === -1,
        );

        setUserProfile({ ...userProfile, securityProfiles: finalSecurityProfiles });

        userAssignSecurityProfile(
            user.userId,
            newSecurityProfilesIds,
            removedSecurityProfileIds,
            abortController.signal,
        ).then(() => {
            toast.success('User security profiles updated');
        });
    };

    const handleClearSelect = (selectId: string) => {
        const newUser = { ...user };

        newUser[selectId] = null;

        if (selectId === 'vendorId') {
            newUser['userKey'] = null;
        }

        setUserProfile({ ...userProfile, user: newUser });
    };

    const handleStartEdit = () => {
        setIsEditMode(true);
        setOriginalUser({ ...user });
    };

    const handleSave = async () => {
        setIsEditMode(false);

        await editUser(user, abortController.signal).then(() => {
            toast.success('User Information updated');
        });
    };

    const handleCancel = () => {
        setUserProfile({ ...userProfile, user: originalUser });
        setIsEditMode(false);
    };

    const adminBreadCrumb = {
        name: 'Admin',
        goBack: false,
        link: '/admin',
        path: path,
    };

    const accessControlBreadCrumb = {
        name: 'Access Control',
        goBack: false,
        link: '/admin/access-control/users',
        path: path,
    };

    const usersBreadCrumb = {
        name: 'Users',
        goBack: false,
        link: '/admin/access-control/users',
        path: path,
    };

    const userProfileBreadCrumb = {
        name: `${user.firstName} ${user.lastName} Profile`,
        goBack: false,
        path: path,
    };

    return (
        <>
            <div className={classes.breadCrumb}>
                <BreadCrumb
                    sections={[adminBreadCrumb, accessControlBreadCrumb, usersBreadCrumb, userProfileBreadCrumb]}
                />
            </div>
            <Paper className={classes.paper}>
                <Grid container justify="space-between" xs={12} className={classes.userInformationHeader}>
                    <Grid item>
                        <Typography variant="subtitle2">User Information</Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={handleStartEdit}>
                            <Edit />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={3}>
                        <TextField
                            label="First Name"
                            id="firstName"
                            value={user?.firstName}
                            InputLabelProps={{
                                shrink: !!user?.firstName,
                            }}
                            disabled={!isEditMode}
                            onChange={(event) => handleTextFieldChange(event)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Last Name"
                            id="lastName"
                            value={user?.lastName}
                            InputLabelProps={{
                                shrink: !!user?.lastName,
                            }}
                            disabled={!isEditMode}
                            onChange={(event) => handleTextFieldChange(event)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Username"
                            id="userName"
                            value={user?.userName}
                            InputLabelProps={{
                                shrink: !!user?.userName,
                            }}
                            disabled={!isEditMode}
                            onChange={(event) => handleTextFieldChange(event)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Guid"
                            id="guid"
                            value={user?.guid}
                            InputLabelProps={{
                                shrink: !!user?.guid,
                            }}
                            disabled={!isEditMode}
                            onChange={(event) => handleTextFieldChange(event)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.selectInput}>
                            <InputLabel id="demo-simple-select-label">Tenant</InputLabel>
                            {
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="tenantId"
                                    name="tenantId"
                                    key={user?.tenantId}
                                    value={user?.tenantId}
                                    onChange={(event) => handleTextFieldChange(event)}
                                    disabled={!isEditMode || user?.vendorId !== null}
                                    endAdornment={
                                        <ClearSelect
                                            shouldRender={!!user?.tenantId && isEditMode}
                                            onClick={() => handleClearSelect('tenantId')}
                                        />
                                    }
                                >
                                    {tenants
                                        .sort((a, b) => (a?.tenantName < b?.tenantName ? -1 : 1))
                                        .map((tenant, index) => {
                                            return (
                                                <MenuItem key={tenant.tenantId} value={tenant.tenantId}>
                                                    {tenant.tenantName}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.selectInput}>
                            <InputLabel id="vendor-label">Vendor</InputLabel>
                            <Select
                                labelId="vendor-label"
                                id="vendorId"
                                name="vendorId"
                                key={user?.vendorId}
                                value={user?.vendorId}
                                onChange={(event) => handleTextFieldChange(event)}
                                disabled={!isEditMode || user?.tenantId !== null}
                                endAdornment={
                                    <ClearSelect
                                        shouldRender={!!user?.vendorId && isEditMode}
                                        onClick={() => handleClearSelect('vendorId')}
                                    />
                                }
                            >
                                {vendors
                                    .sort((a, b) => (a?.vendorName < b?.vendorName ? -1 : 1))
                                    .map((vendor, index) => {
                                        return (
                                            <MenuItem key={vendor.vendorId} value={vendor.vendorId}>
                                                {vendor.vendorName}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="User Key"
                            id="userKey"
                            value={user?.userKey}
                            key={user?.vendorId + user?.tenantId}
                            InputLabelProps={{
                                shrink: !!user?.userKey,
                            }}
                            onChange={(event) => handleTextFieldChange(event)}
                            disabled={!isEditMode || user?.vendorId === null}
                        />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Email"
                            id="email"
                            value={user?.email}
                            InputLabelProps={{
                                shrink: !!user?.email,
                            }}
                            onChange={(event) => handleTextFieldChange(event)}
                            disabled={!isEditMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Contact Phone"
                            id="phone"
                            value={user?.phone}
                            InputLabelProps={{
                                shrink: !!user?.phone,
                            }}
                            onChange={(event) => handleTextFieldChange(event)}
                            disabled={!isEditMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Mobile Contact Phone"
                            id="mobilePhone"
                            value={user?.mobilePhone}
                            InputLabelProps={{
                                shrink: !!user?.mobilePhone,
                            }}
                            onChange={(event) => handleTextFieldChange(event)}
                            disabled={!isEditMode}
                        />
                    </Grid>
                </Grid>

                {isEditMode && (
                    <div className={classes.buttonsContainer}>
                        <Button className={classes.cancelButton} onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button className={classes.button} onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                )}
            </Paper>

            <Paper className={classes.securityProfilesPaper}>
                <Grid container className={classes.securityProfilesPaperTitleContainer} xs={12}>
                    <Grid item xs={10}>
                        <Typography variant="subtitle1">Security Profiles</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Button className={classes.button} onClick={() => setShowAddEditSecurityProfiles(true)}>
                            Add/Edit Security Profile
                        </Button>
                    </Grid>
                </Grid>

                {securityProfiles?.map((securityProfile) => (
                    <Accordion key={securityProfile.securityProfileId}>
                        <AccordionSummary>
                            <Grid item container xs={12} className={classes.sidebarTitle}>
                                <Grid item>
                                    <ExpandMore color="inherit" />
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle2">{securityProfile.name}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container xs={12}>
                                {securityProfile?.rules.map((rule) => (
                                    <AccessControlSecurityRuleAccordion
                                        key={rule.securityRuleId}
                                        rule={rule}
                                        handleParentChangeRulePermission={() => {}}
                                        readOnly={true}
                                    />
                                ))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Paper>
            <Confirm
                title="ADD/EDIT SECURITY PROFILE"
                openDialog={showAddEditSecurityProfiles}
                okText="Save"
                noText="Cancel"
                onToggleConfirm={() => setShowAddEditSecurityProfiles(false)}
                onAction={handleAddEditSecurityProfiles}
            >
                <AccessControlSelectSecurityProfile
                    assignedSecurityProfiles={securityProfiles}
                    localAssignedSecurityProfiles={localAssignedSecurityProfiles}
                    setLocalAssignedSecurityProfiles={setLocalAssignedSecurityProfiles}
                />
            </Confirm>
        </>
    );
};
