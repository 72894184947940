import React, { Fragment, FunctionComponent } from 'react';
import styles from "./ReferralImeClaimInfo.Styles";
import { Grid, Link, Typography, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReferralOverviewDataModel } from '../../../../../data-models/ReferralOverview.DataModel';
import ReferralContactEntity from '../../../../../entities/ReferralContact.Entity';
import { ClientSummary } from '../../client-summary/ClientSummary.Component';
import StateEntity from '../../../../../entities/State.Entity';
import ReferralClaimEntity from '../../../../../entities/ReferralClaim.Entity';
import CoverageTypeEntity from '../../../../../entities/CoverageType.Entity';
import { ClaimDetailSummary } from '../../claim-summary/ClaimDetailSummary.Component';
import { AdjusterSummary } from '../../adjuster-summary/AdjusterSummary.Component';
import { ClaimantSummary } from '../../claimant-summary/ClaimantSummary.Component';
import { ClaimantAttorneySummary } from '../../claimant-attorney-summary/ClaimantAttorneySummary.Component';
import { EmployerSummary } from '../../employer-summary/EmployerSummary.Component';
import { EmployerAttorneySummary } from '../../employer-attorney-summary/EmployerAttorneySummary.Component';
import { useSelector } from 'react-redux';
import { UsersState } from '../../../../../store/reducers/usersReducer';
import { UserRolesEnum } from '../../../../../support/enums/UserRoles.Enum';

type PathParamsType = {
    id: string;
    claimId: string;
}

type ReferralImeClaimInfoProps = {
    referralClient: ReferralContactEntity;
    referralOverviewDataModel: ReferralOverviewDataModel;
    states: Array<StateEntity>;
    referralClaim: ReferralClaimEntity;
    coverageTypes: Array<CoverageTypeEntity>;
    referralAdjuster: ReferralContactEntity;
    referralClaimant: ReferralContactEntity;
    referralClaimantAttorney: ReferralContactEntity;
    referralEmployer: ReferralContactEntity;
    referralEmployerAttorney: ReferralContactEntity;
} & WithStyles<typeof styles> & RouteComponentProps<PathParamsType>

const ReferralImeClaimInfoComponent: FunctionComponent<ReferralImeClaimInfoProps> = (props: ReferralImeClaimInfoProps) => {
    const { classes } = props;

    let userRoles = useSelector<UsersState, UsersState['roles']>(state => state.roles);
    let buttonBackLink: string;

    try {
        if (userRoles?.findIndex(x => x == UserRolesEnum.Vendor || x == UserRolesEnum.VendorApi) != -1) {
            buttonBackLink = '/referrals';
        }
        else {
            buttonBackLink = `/claims/${props.match.params.claimId}`;
        }
    }
    catch { }

    return (
        <Fragment>
            {
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ClaimDetailSummary referralClaim={props.referralClaim} states={props.states} coverageTypes={props.coverageTypes} />
                        </Grid>
                        <Grid item xs={12}>
                            <ClaimantAttorneySummary referralClaimantAttorney={props.referralClaimantAttorney} states={props.states} />
                        </Grid>
                        <Grid item xs={12}>
                            <EmployerAttorneySummary states={props.states} referralEmployerAttorney={props.referralEmployerAttorney} />
                        </Grid>
                        <Grid item xs={12}>
                            <AdjusterSummary referralAdjuster={props.referralAdjuster} states={props.states} />
                        </Grid>
                        <Grid item xs={12}>
                            <ClaimantSummary referralClaimant={props.referralClaimant} states={props.states} />
                        </Grid>                        
                        <Grid item xs={12}>
                            <ClientSummary referralClient={props.referralClient} states={props.states} />
                        </Grid>
                        <Grid item xs={12}>
                            <EmployerSummary states={props.states} referralEmployer={props.referralEmployer} />
                        </Grid>
                        
                    </Grid>
                </form>
            }
        </Fragment>
    );
}

export const ReferralImeClaimInfo = withRouter(withStyles(styles)(ReferralImeClaimInfoComponent))
