import React, { FunctionComponent, useEffect, useState } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary } from '@material-ui/core';
import styles from './PhysicianResponse.Styles';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import StateEntity from '../../../../entities/State.Entity';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';

interface IPhysicianResponseProps {
    physician: ReferralContactEntity;
    states: Array<StateEntity>;
    serviceId: number;
    prefix?: string;
}

export const PhysicianResponse: FunctionComponent<IPhysicianResponseProps> = (props: IPhysicianResponseProps) => {
    const classes = styles();
    const [title, setTitle] = useState<string>('');

    useEffect(() => {
        let finalTitle = '';

        if(props.serviceId !== ServicesEnum.UR)
        {
            finalTitle += 'Physician';
        }
        else 
        {
            finalTitle += 'Facility / Service Provider';
        }

        switch(props.serviceId)
        {
            case ServicesEnum.PT:
                finalTitle += ' / Service Provider';
                break;
            case ServicesEnum.Diagnostic:
                finalTitle += ' / Facility - ';
                break;
            default:
                break;
        }

        if(props.prefix)
        {
            finalTitle += props.prefix;
        }

        setTitle(finalTitle);

    }, [props.serviceId])


    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3} className={classes.gridContainer}>
                    {props.serviceId !== ServicesEnum.IME && (
                        <Grid item xs={12}>
                            <Typography variant="body2" className={classes.dataLabel}>
                                Facility Name
                            </Typography>
                            <Typography variant="subtitle2" className={classes.blackFont}>
                                {props.physician?.facilityName}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            First Name
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {props.physician?.firstName}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            Address Line 1
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {props.physician?.addressLine1}
                        </Typography>
                    </Grid>
                    {
                        props.serviceId === ServicesEnum.Diagnostic ?
                        <Grid container xs={3} spacing={1} style={{ marginTop:'9px', marginRight: '4px'}}>
                            <Grid item xs={3} style={{ marginLeft: '11px', marginRight: '-15%' }}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Zip Code
                                </Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>
                                    {props.physician?.zipCode}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Grid item xs={3} style={{ marginTop:'12px',marginLeft: '15px', marginRight: '-8%' }}>
                                    _
                                </Grid>
                            </Grid>
                            <Grid item xs={3} style={{marginRight: '15px'}}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Zip Code Ext.
                                </Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>
                                    {props.physician?.zipCodeExtension}
                                </Typography>
                            </Grid>
                        </Grid>
                        :
                        <Grid item xs={6} spacing={3} container>
                            <Grid item xs={6}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Zip Code
                                </Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>
                                    {props.physician?.zipCode}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            Last Name
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {props.physician?.lastName}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            Address Line 2
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {props.physician?.addressLine2}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            Primary Phone
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {props.physician?.primaryPhoneNumber}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            Ext.
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {props.physician?.primaryPhoneExtension}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                        Middle initial
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {props.physician?.middleInitial}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            City
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {props.physician?.cityName}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            Secondary Phone
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {props.physician?.secondaryPhoneNumber}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            Ext.
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {props.physician?.secondaryPhoneExtension}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            Physician NPI
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {props.physician?.treatingPhysicianNPI}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            State
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {
                                props.states?.filter(
                                    (x) =>
                                        props.physician &&
                                        props.physician?.stateId &&
                                        x.stateId == props.physician?.stateId,
                                )[0]?.stateName
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            Fax
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {props.physician?.fax}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        {/* EMPTY SPACE */}
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            Email
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>
                            {props.physician?.email}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" className={classes.dataLabel}>
                            Additional Comments
                        </Typography>
                        {props.physician?.comments?.split('\n').map((sentence, key) => (
                            <Typography key={key} variant="subtitle2" className={classes.blackFont}>
                                {sentence}
                            </Typography>
                        ))}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
