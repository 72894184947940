import React, { FunctionComponent } from 'react';
import { Typography, ThemeProvider, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import styles, {dialogTheme} from "./DialogCustom.Styles";

interface IDialogCustomProps {
    closeHandler: (val: boolean) => void;
    acceptHandler: (val: boolean) => void;
    open: boolean;
    title?: string;
    subtitle?: string;
    acceptButtonText?: string;
    cancelButtonText?: string;
    children?: any;
    isFullScreen?: boolean;
}

export const DialogCustom: FunctionComponent<IDialogCustomProps> = (props: IDialogCustomProps) => {
    const classes = styles();

    const handleClose = () => {
        props.closeHandler(false);
    };
    const handleAccept = () => {
        props.acceptHandler(true);
    };

    return (
        <ThemeProvider theme={dialogTheme(props.isFullScreen)}>
            <Dialog open={props.open} onClose={handleClose} className={classes.paper}>
                {
                    props.title && 
                        <DialogTitle className={classes.paperTitle}>
                            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                            {props.title}
                        </DialogTitle>
                }
                {
                    (props.subtitle ||
                    props.children) &&
                        <DialogContent>
                            {props.subtitle && <Typography variant="subtitle1" className={classes.subtitle}>{props.subtitle}</Typography>}
                            {props.children && <>{props.children}</>}
                        </DialogContent>
                }
                {
                    (props.acceptButtonText || 
                    props.cancelButtonText) &&
                        <DialogActions>
                            {props.acceptButtonText && <Button onClick={handleAccept} color="primary" variant="contained" className={classes.acceptButton}>{props.acceptButtonText}</Button>}
                            {props.cancelButtonText && <Button onClick={handleClose} color="primary" variant="outlined" className={classes.cancelButton}>{props.cancelButtonText}</Button>}
                        </DialogActions>
                }
            </Dialog>
        </ThemeProvider>
    );
}