import { createTheme , createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 320;

export default makeStyles((theme: Theme) => ({
    content: {
        [theme.breakpoints.up('sm')]: {
            paddingRight: drawerWidth,
        },
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
    sidebarTitle: {
        color: '#428302',
        marginLeft: '5px'
    },
    gridContainer: {
        width: '100%'
    },
    paper: {
        paddingLeft: '25px',
        paddingRight: '25px',
        paddingBottom: '20px'
    },
    paperInternal: {
        paddingLeft: '25px',
        paddingRight: '25px',
        paddingBottom: '20px',
        paddingTop: '20px',
        boxShadow: "-2px 0px 10px 0px rgba(0,0,0,0.40)",
    },
    paperHeader: {
        marginLeft: '10px',
        padding: '0',
        flexDirection: 'row-reverse'
    },
    paperHeaderIcon: {
        color: '#428302'
    },
    textAreaGrid: {
        paddingLeft: '35px!important',
    },
    fullWidthInput: {
        width: '100%'
    },
    selectInput: {
        width: '100%',
    },
    serviceTypeSubtitle: {
        fontSize: '12px',
        color: '#000000'
    },
    actionSubtitle: {
        fontSize: '12px',
        color: '#898F91'
    },
    nextButton: {
        float: "right",
        backgroundColor: '#107A98',
        '&:hover': {
            backgroundColor: '#004763!important',
        }
    },
    buttonContainer: {
        display: 'block',
        paddingBottom: '50px'
    },
    paperTitle: {
        color: '#428302',
    },
    listHeader: {
        padding: 0,
        lineHeight: 0,
        marginBottom: '16px',
        height: '30px'
    },
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        display: 'contents'
    },
    listHeaderSubTitle: {
        fontSize: '12px',
        color: '#898F91'
    },
    checkboxFormControl:{
        display:'flex',
        flexDirection:'row-reverse',
        justifyContent:'flex-end',
        alignItems:'center'
    },
    checkboxLabel:{
        position:"relative",
        transform:"none",
        cursor:"pointer",
    },
    saveButton: {
        float: "right",
        backgroundColor: '#107A98',
        '&:hover': {
            backgroundColor: '#004763!important',
        }
    }
}));