import { createTheme , makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 320;

export default makeStyles((theme: Theme) => ({
  sidebarTitle: {
    color: '#428302',
    // marginLeft: '5px'
  },
  gridContainer: {
    width: '100%',
  },
  paper: {
      paddingTop: '15px',
      paddingLeft: '35px',
      paddingRight: '35px',
      paddingBottom: '25px',
  },
  dataLabel: {
      color: '#898F91',
      fontSize: '12px'
  },
  iconTextRow: {
      display: 'inline-flex',
      flexDirection: 'row'
  },
  blackFont: {
      color: '#000000'
  },
  iconData: {
      color: '#000000',
      marginLeft: 10
  },
  paperHeader: {
      flexDirection: 'row-reverse',
      padding: "0px 0px"
  },
  paperTitle: {
      color: '#428302'
  },
  divider: {
      height: '1px',
      marginTop: 20,
      color: '#898F91'
  },
  paperHeaderIcon: {
      color: '#428302'
  },
  input:{
      width:'100%',
      '& label.Mui-disabled':{
          color: '#898F91',
      },
      '& textArea.Mui-disabled':{
          color:'black',
          fontWeight:500,
      }
  }
}));