import { makeStyles, Theme } from '@material-ui/core/styles';
const drawerWidth = 320;

export default makeStyles((theme: Theme) => ({
    content: {
        [theme.breakpoints.up('sm')]: {
            paddingRight: drawerWidth,
        },
    },
    contentWithoutSideBar: {
        [theme.breakpoints.up('sm')]: {
            paddingRight: 0,
        },
    },
    breadCrumb: {
        marginTop: '70px',
        marginBottom: '-55px',
    },
    tableContainer: {
        paddingRight: '40px',
        paddingLeft: '60px',
        // maxWidth: `calc(100vw - ${adminNavigationWidth}px)`,
    },
    tableContainerWithoutSideMenu: {
        paddingRight: '40px',
        // paddingLeft: '60px',
        // maxWidth: `calc(100vw - ${adminNavigationWidth}px)`,
    },
}));
