
import ImeDataModel from '../data-models/Ime.DataModel';
import ImeResponseEntity from '../entities/ImeResponse.Entity';
import { apiGet, apiPost, apiPut } from '../support/helpers/ApiConnection.Helper';

export function sendReferralIme(imeDataModel: ImeDataModel, abortSignal: AbortSignal, imeId?: number): Promise<ImeDataModel> {
    if (imeId) {
        return apiPut<ImeDataModel>('/imes', imeId, imeDataModel, abortSignal);
    }
    else {
        return apiPost<ImeDataModel>('/imes', imeDataModel, abortSignal);
    }
}

export function getReferralImeResponse(imeId: number, abortSignal: AbortSignal): Promise<ImeResponseEntity> {
    return apiGet<ImeResponseEntity>('/imes/' + imeId + '/response', abortSignal);
}
