import { Grid, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemIcon, Checkbox, ListItemText, TextField } from "@material-ui/core";
import React, { Fragment, FunctionComponent, useEffect } from 'react';
import FileEntity from "../../../../../../entities/File.Entity";
import IMECoverLetterEntity from "../../../../../../entities/IMECoverLetter.Entity";
import { FileTypesEnum } from "../../../../../../support/enums/FileTypes.Enum";
import { EmptyDataLabel } from "../../../../../generic-components/empty-data-label/EmptyDataLabel.Component";
import { FileUploader } from "../../../../../generic-components/file-uploader/FileUploader.Component";
import styles from "./ImeCoverLetter.Styles";

interface IImeCoverLetterProps{
    coverLetterState: IMECoverLetterEntity,
    handleTextFieldChangeIme : any,
    handleImeCoverLetterEdit: (edit: IMECoverLetterEntity) => void,
    handleUpdateImeCoverLetter: () => void
}

export const ImeCoverLetter: FunctionComponent<IImeCoverLetterProps> = (props) => {
    const classes = styles();
    const abortController = new AbortController();

    const [coverLetterState, setCoverLetterState] = React.useState(null);

    useEffect(() => {
        if (!props.coverLetterState) {
            let newCoverLetter: IMECoverLetterEntity = props.coverLetterState;
            newCoverLetter = { isCoverLetterRequired: true } as IMECoverLetterEntity;
            props.handleImeCoverLetterEdit(newCoverLetter);
        }
        setCoverLetterState(props.coverLetterState);

        return function cleanup() {
            abortController.abort();
        }
    }, [props]);

    const handleCheckboxChange = (id: string) => {

        let newImeCoverLetter: IMECoverLetterEntity = coverLetterState;
        
        newImeCoverLetter[id] = !props.coverLetterState[id];

        setCoverLetterState(newImeCoverLetter);

        let newCoverLetter: IMECoverLetterEntity = props.coverLetterState;
        newCoverLetter = newImeCoverLetter;
        props.handleImeCoverLetterEdit(newCoverLetter);
    }

    const handleTextFieldChangeImeCoverLetter = (event) => {
        event.persist();
        if(!event.target.id && !event.target.name) return;

        let newImeCoverLetter: IMECoverLetterEntity = coverLetterState;

        if (event.target.type == 'checkbox') {
            newImeCoverLetter[event.target.id ? event.target.id : event.target.name] = event.target.checked;
        }
        else {
            newImeCoverLetter[event.target.id ? event.target.id : event.target.name] = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value.toString() == "true" ? true : event.target.value.toString() == "false" ? false : event.target.value;
        }

        setCoverLetterState(newImeCoverLetter);
        
        if(!coverLetterState?.isCoverLetterRequired){
            // TO DO: ADD BACK WHEN CLIENT IS READY newImeCoverLetter.files = [];
            // TO DO: ADD BACK WHEN CLIENT IS READY newImeCoverLetter.isIWillAttachMyOwnCoverLetter = false;
            newImeCoverLetter.isCoverLetterProvidedSeparately = false;
            newImeCoverLetter.isMedicalSummaryNeeded = false;
            newImeCoverLetter.isDetailHistoryNeeded = false;
            newImeCoverLetter.isComplaintRelatedToDate = false;
            newImeCoverLetter.isTreatmentReasonable = false;
            newImeCoverLetter.isClaimantAbleToWork = false;
            newImeCoverLetter.isClaimantReachedMMI = false;
            newImeCoverLetter.isImpairmentRaitingNeeded = false;
            newImeCoverLetter.isFurtherMedicalTreatment = false;
            newImeCoverLetter.otherCommentsForCoverLetter = null;
        }

        let newCoverLetter: IMECoverLetterEntity = props.coverLetterState;
        newCoverLetter = newImeCoverLetter;
        props.handleImeCoverLetterEdit(newCoverLetter);
    }

    /* TO DO: ADD BACK WHEN CLIENT IS READY
    const handleOnFileChange = (files: Array<FileEntity>) => {
        let newIMECoverLetter: IMECoverLetterEntity = {...coverLetterState};
        // picks last document added
        if(files.length > 0){
            newIMECoverLetter.files = [files[files.length - 1]];
        } else {
            newIMECoverLetter.files = []
        }
        setCoverLetterState(newIMECoverLetter);

        let newIME: IMECoverLetterEntity = props.coverLetterState;
        newIME = newIMECoverLetter;
        props.handleImeCoverLetterEdit(newIME);
    }
    */

    return (
    <Fragment>
       {coverLetterState ? 
            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <div className={classes.singleRowField}>
                            <FormLabel component="legend">Cover Letter Required</FormLabel>
                            <RadioGroup row aria-label="position" name="isCoverLetterRequired" id="isCoverLetterRequired" onChange={(event) => handleTextFieldChangeImeCoverLetter(event)} value={(props.coverLetterState?.isCoverLetterRequired?.toString() == "false" ? false : true)} className={classes.singleRowRadioButtonGroup}>
                                <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
                                <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
                            </RadioGroup>
                        </div>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Select Questions</FormLabel>
                        <List>
                            {/* TO DO: REMOVE WHEN ADDING THE OTHER STUFF */}
                            <ListItem disabled={props.coverLetterState?.isCoverLetterRequired?.toString() == "false"} key="isCoverLetterProvidedSeparately" id="isCoverLetterProvidedSeparately" dense button onClick={(event) => handleCheckboxChange("isCoverLetterProvidedSeparately")}>
                                <ListItemIcon className={classes.disablePointerEvents} >
                                    <Checkbox
                                        edge="start"
                                        checked={!!props.coverLetterState.isCoverLetterProvidedSeparately}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': `checkbox-list-label-isCoverLetterProvidedSeparately` }}
                                    />
                                </ListItemIcon>
                                <ListItemText className={classes.disablePointerEvents} id="isCoverLetterProvidedSeparately" primary={`Cover letter included in Documents (Please attach it in documents section)`} />
                            </ListItem>
                            {/*  */}
                            {/* TO DO: ADD BACK WHEN CLIENT IS READY
                            <ListItem disabled={props.coverLetterState?.isCoverLetterRequired?.toString() == "false"} key="isIWillAttachMyOwnCoverLetter" id="isIWillAttachMyOwnCoverLetter" dense button onClick={(event) => handleCheckboxChange("isIWillAttachMyOwnCoverLetter")}>
                                <ListItemIcon className={classes.disablePointerEvents} >
                                    <Checkbox
                                        edge="start"
                                        checked={!!props.coverLetterState.isIWillAttachMyOwnCoverLetter}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': `checkbox-list-label-isIWillAttachMyOwnCoverLetter` }}
                                    />
                                </ListItemIcon>
                                <ListItemText className={classes.disablePointerEvents} id="isIWillAttachMyOwnCoverLetter" primary={`I will attach my own cover letter`} />
                            </ListItem> */}
                            <ListItem disabled={props.coverLetterState?.isCoverLetterRequired?.toString() == "false"} key="isMedicalSummaryNeeded" id="isMedicalSummaryNeeded" role={undefined} dense button onClick={(event) => handleCheckboxChange("isMedicalSummaryNeeded")}>
                                <ListItemIcon className={classes.disablePointerEvents}>
                                    <Checkbox
                                        edge="start"
                                        checked={!!props.coverLetterState.isMedicalSummaryNeeded}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': `checkbox-list-label-isMedicalSummaryNeeded` }}
                                    />
                                </ListItemIcon>
                                <ListItemText className={classes.disablePointerEvents} id="isMedicalSummaryNeeded" primary={`Please prepare a medical summary to be included for the IME physician's`} />
                            </ListItem>
                            <ListItem disabled={props.coverLetterState?.isCoverLetterRequired?.toString() == "false"} key="isDetailHistoryNeeded" id="isDetailHistoryNeeded" dense button onClick={(event) => handleCheckboxChange("isDetailHistoryNeeded")}>
                                <ListItemIcon className={classes.disablePointerEvents}>
                                    <Checkbox
                                        edge="start"
                                        checked={!!props.coverLetterState.isDetailHistoryNeeded}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': `checkbox-list-label-isDetailHistoryNeeded` }}
                                    />
                                </ListItemIcon>
                                <ListItemText className={classes.disablePointerEvents} id="isDetailHistoryNeeded" primary={`Please provide a detailed history, diagnosis and prognosis`} />
                            </ListItem>
                            <ListItem disabled={props.coverLetterState?.isCoverLetterRequired?.toString() == "false"} key="isComplaintRelatedToDate" id="isComplaintRelatedToDate" role={undefined} dense button onClick={(event) => handleCheckboxChange("isComplaintRelatedToDate")}>
                                <ListItemIcon className={classes.disablePointerEvents}>
                                    <Checkbox
                                        edge="start"
                                        checked={!!props.coverLetterState.isComplaintRelatedToDate}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': `checkbox-list-label-isComplaintRelatedToDate` }}
                                    />
                                </ListItemIcon>
                                <ListItemText className={classes.disablePointerEvents} id="isComplaintRelatedToDate" primary={`Please address casual relationship. Are the claimant's current complaints casually related to the date of accident?`} />
                            </ListItem>
                            <ListItem disabled={props.coverLetterState?.isCoverLetterRequired?.toString() == "false"} key="isTreatmentReasonable" id="isTreatmentReasonable" role={undefined} dense button onClick={(event) => handleCheckboxChange("isTreatmentReasonable")}>
                                <ListItemIcon className={classes.disablePointerEvents}>
                                    <Checkbox
                                        edge="start"
                                        checked={!!props.coverLetterState.isTreatmentReasonable}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': `checkbox-list-label-isTreatmentReasonable` }}
                                    />
                                </ListItemIcon>
                                <ListItemText className={classes.disablePointerEvents} id="isTreatmentReasonable" primary={`Has the claimant's medical treatment thus far been reasonable, necessary and casually related to the date of accident?`} />
                            </ListItem>
                            <ListItem disabled={props.coverLetterState?.isCoverLetterRequired?.toString() == "false"} key="isClaimantAbleToWork" id="isClaimantAbleToWork" role={undefined} dense button onClick={(event) => handleCheckboxChange("isClaimantAbleToWork")}>
                                <ListItemIcon className={classes.disablePointerEvents}>
                                    <Checkbox
                                        edge="start"
                                        checked={!!props.coverLetterState.isClaimantAbleToWork}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': `checkbox-list-label-isClaimantAbleToWork` }}
                                    />
                                </ListItemIcon>
                                <ListItemText className={classes.disablePointerEvents} id="isClaimantAbleToWork" primary={`Please address the claimant's work capabilities. Is the claimant able to work full or light duty at this time? Please specify any work restrictions you feel are necessary and indicate how long these restrictions should remain in place.`} />
                            </ListItem>
                            <ListItem disabled={props.coverLetterState?.isCoverLetterRequired?.toString() == "false"} key="isClaimantReachedMMI" id="isClaimantReachedMMI" role={undefined} dense button onClick={(event) => handleCheckboxChange("isClaimantReachedMMI")}>
                                <ListItemIcon className={classes.disablePointerEvents}>
                                    <Checkbox
                                        edge="start"
                                        checked={!!props.coverLetterState.isClaimantReachedMMI}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': `checkbox-list-label-isClaimantReachedMMI` }}
                                    />
                                </ListItemIcon>
                                <ListItemText className={classes.disablePointerEvents} id="isClaimantReachedMMI" primary={`Has the claimant reached Maximum Medical Improvement? If not, when do you anticipate the claimant will reach MMI`} />
                            </ListItem>
                            <ListItem disabled={props.coverLetterState?.isCoverLetterRequired?.toString() == "false"} key="isImpairmentRaitingNeeded" id="isImpairmentRaitingNeeded" role={undefined} dense button onClick={(event) => handleCheckboxChange("isImpairmentRaitingNeeded")}>
                                <ListItemIcon className={classes.disablePointerEvents}>
                                    <Checkbox
                                        edge="start"
                                        checked={!!props.coverLetterState.isImpairmentRaitingNeeded}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': `checkbox-list-label-isImpairmentRaitingNeeded` }}
                                    />
                                </ListItemIcon>
                                <ListItemText className={classes.disablePointerEvents} id="isImpairmentRaitingNeeded" primary={`If the claimant is at MMI, please provide a permanent impairment rating according to the AMA guidelines. Please apportion your rating to include any pre-existing and/or unrelated conditions`} />
                            </ListItem>
                            <ListItem disabled={props.coverLetterState?.isCoverLetterRequired?.toString() == "false"} key="isFurtherMedicalTreatment" id="isFurtherMedicalTreatment" role={undefined} dense button onClick={(event) => handleCheckboxChange("isFurtherMedicalTreatment")}>
                                <ListItemIcon className={classes.disablePointerEvents}>
                                    <Checkbox
                                        edge="start"
                                        checked={!!props.coverLetterState.isFurtherMedicalTreatment}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': `checkbox-list-label-isFurtherMedicalTreatment` }}
                                    />
                                </ListItemIcon>
                                <ListItemText className={classes.disablePointerEvents} id="isFurtherMedicalTreatment" primary={`Do you have any further medical treatment recommendations for this claimant? If so, please elaborate on the specific treatment(s) and duration of the treatment plan`} />
                            </ListItem>
                        </List>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.selectInput}>
                        <TextField disabled={props.coverLetterState?.isCoverLetterRequired?.toString() == "false"} maxRows={4} multiline={true} label="Additional Questions" id="otherCommentsForCoverLetter" onChange={(event) => handleTextFieldChangeImeCoverLetter(event)}
                        value={props.coverLetterState.otherCommentsForCoverLetter ? props.coverLetterState.otherCommentsForCoverLetter : ''} inputProps={{ maxLength: 500 }} helperText={`${props.coverLetterState.otherCommentsForCoverLetter ? props.coverLetterState.otherCommentsForCoverLetter.length : 0 }/${500}`} />
                    </FormControl>
                </Grid>
                        {/* TO DO: ADD BACK WHEN CLIENT IS READY
                <Grid item xs={12}>
                        <FormControl component="fieldset">
                            <FileUploader 
                                acceptedFileTypes={[FileTypesEnum.BMP, FileTypesEnum.DOC, FileTypesEnum.DOCX, FileTypesEnum.GIF, FileTypesEnum.JPEG, FileTypesEnum.JPG, FileTypesEnum.PDF, FileTypesEnum.PNG, FileTypesEnum.SX, FileTypesEnum.TIF, FileTypesEnum.TIFF, FileTypesEnum.XL, FileTypesEnum.XLS]} 
                                files={props.coverLetterState?.files}
                                onChange={handleOnFileChange} 
                                disabled={props.coverLetterState?.isCoverLetterRequired ? false : true} 
                            />
                        </FormControl>
                </Grid>
                */}
            </Grid>
            :
            <EmptyDataLabel />    
        }
    </Fragment>
    )
    
}