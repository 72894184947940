import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Typography,
} from '@material-ui/core';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import ProductEntity from '../../../../../../entities/dme/Product.Entity';
import ProductCategory from '../../../../../../entities/dme/ProductCategory.Entity';
import ProductInformationEntity from '../../../../../../entities/dme/ProductInformation.Entity';
import ServiceTypeRequestDmeEntity from '../../../../../../entities/dme/ServiceTypeRequestDme.Entity';
import { getProductsByCategoryId } from '../../../../../../services/ProductCategories.Service';
import { TooltipTextField } from '../../../../../generic-components/tooltip-textfield/TooltipTextField.Component';
import styles from './DmeHeader.Styles';
import { MaterialTableProps } from 'material-table';
import MaterialTable from '../../../../../generic-components/material-table/MaterialTable.Component';
import Moment from 'moment';
import { useValidationDialog } from '../../../../../../support/custom-hooks/useValidationDialog';
import { deleteProductInformationById } from '../../../../../../services/ProductInformation.Service';
import { toast } from 'react-toastify';
import SearchAddressAsync, { AddressValue } from '../../../../../generic-components/search-autocomplete/SearchAddressAsync';
import AddressAutocompleteEntity from '../../../../../../entities/ReferralAddress.Entity';
import StateEntity from '../../../../../../entities/State.Entity';
import { format } from 'date-fns';
import InputMask from 'react-input-mask';

interface IProductErrors {
    productId?: string;
    productCategoryId?: string;
    quantity?: string;
    rentalPurchase?: string;
    duration?: string;
}
interface IDmeHeaderProps {
    referralId: number;
    serviceTypeRequestDme: ServiceTypeRequestDmeEntity;
    productCategories: Array<ProductCategory>;
    states: Array<StateEntity>;
    validate: (isChange?: boolean) => void;
    handleSetIsValidDmeProductCategoryFunction: (currentPageIsValidDmeProductCategoryFunction: () => boolean) => void;
    handleServiceTypeRequestDmeEdit: (serviceTypeRequestDme: ServiceTypeRequestDmeEntity) => void;
    hasBeenSubmitted: boolean;
}

class AddressDme
{
  address : string;
  stateId: number;
  cityName: string;
  zipCode: string;
  addressLine2: string;
  fullAddress ; string;
}


export const DmeHeader: FunctionComponent<IDmeHeaderProps> = ({
    referralId,
    serviceTypeRequestDme,
    productCategories,
    handleServiceTypeRequestDmeEdit,
    hasBeenSubmitted,
    validate,
    handleSetIsValidDmeProductCategoryFunction,
    states
}) => {
    const classes = styles();
    const abortController = new AbortController();

    const [triggerUpdate, setTriggerUpdate] = useState<number>(0);
    const [localHasBeenSubmitted, setLocalHasBeenSubmitted] = useState<boolean>(false);
    const [errors, setErrors] = useState<IProductErrors>(null);
    const { Dialog, checkForErrors } = useValidationDialog('Validation', 'Some required fields are missing.', 'Ok');
    const [serviceTypeRequestDmeFullAddress, SetServiceTypeRequestDmeFullAddress] = React.useState("");
    const [newProductInformation, setNewProductInformation] = useState<ProductInformationEntity>(
        new ProductInformationEntity(),
    );

    const [products, setProducts] = useState<Array<ProductEntity>>([]);
    const [addressDmeItem, setAddressDmeItem] = React.useState<AddressDme>(
        new AddressDme(),
    );
    
    useEffect(() => {
        handleSetIsValidDmeProductCategoryFunction(isValidDmeProductCategory);
    }, [handleSetIsValidDmeProductCategoryFunction]);

    useEffect(() => {
        const newServiceTypeRequestDmeEntity = new ServiceTypeRequestDmeEntity();
        if (typeof(serviceTypeRequestDme)=="undefined") {
            newServiceTypeRequestDmeEntity.referralId = referralId;
            handleServiceTypeRequestDmeEdit(newServiceTypeRequestDmeEntity);
        }

    }, []);

    useEffect(() => {
        if (newProductInformation.productCategoryId) {
            handleProductCategoryChange(newProductInformation.productCategoryId);
        }
    }, [newProductInformation.productCategoryId]);

    const GetStateNameByStateId =(stateId:number) =>
    {
        return states?.find(element => element.stateId==stateId)?.stateName;
    }
    const DMERequestMakeFullAddress = (dmeState : ProductInformationEntity) =>
    {
        let addressString = dmeState.address;
        if (dmeState.cityName) {
            addressString += ", " + dmeState.cityName;
        }
        const stateName = GetStateNameByStateId(dmeState.stateId);
        if (stateName) {
            addressString += " " + stateName;
        }
        if (dmeState.zipCode) {
            addressString += " " + dmeState.zipCode;
        }

        return addressString;
}

    const passAddress = (address:AddressAutocompleteEntity) => {

        let newServiceTypeRequestDME: AddressDme = addressDmeItem;
        newServiceTypeRequestDME["cityName"] = address.city;
        newServiceTypeRequestDME["address"] = `${address.streetNumber? address.streetNumber:''} ${address.streetName}`.trim();
        newServiceTypeRequestDME["zipCode"] = address.zipCode;
        newServiceTypeRequestDME["addressLine2"] = address.address2;
        newServiceTypeRequestDME["fullAddress"] = address.fullAddress;

        const stateItem = states?.find(element => element.stateName==address.stateName);
        newServiceTypeRequestDME["stateId"] = stateItem?.stateId;
        
        SetServiceTypeRequestDmeFullAddress(address.fullAddress);  
        setAddressDmeItem(newServiceTypeRequestDME);
      }

      const handleClear = ()=>{
        
        let newServiceTypeRequestDME: AddressDme = addressDmeItem;
        newServiceTypeRequestDME["city"] = undefined
        newServiceTypeRequestDME["appointmentAddress"] = undefined
        newServiceTypeRequestDME["zipCode"] = undefined
        newServiceTypeRequestDME["stateId"] = undefined;

        SetServiceTypeRequestDmeFullAddress("");
        setAddressDmeItem(newServiceTypeRequestDME);
    
    }

    const handleProductCategoryChange = async (productCategoryId: number) => {
        const res = await getProductsByCategoryId(productCategoryId, abortController.signal);

        setProducts(res);
    };

    
    const handleChangeNewProductInformation = (event: any) => {
        event.persist();

        const newP: ProductInformationEntity = { ...newProductInformation };

        newP[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true'
                ? true
                : event.target.value == 'false'
                ? false
                : event.target.value?.toString() == 'true'
                ? true
                : event.target.value?.toString() == 'false'
                ? false
                : event.target.value;

        setNewProductInformation(newP);

        if (localHasBeenSubmitted) {
            localValidate(true);
        }
    };

    const handleServiceTypeRequestDme = (event) => {
        event.persist();

        const newSTRD: ServiceTypeRequestDmeEntity = { ...serviceTypeRequestDme };

        if (!newSTRD.referralId) {
            newSTRD.referralId = referralId;
        }

        if (event.target.type == 'checkbox') {
            newSTRD[event.target.id ? event.target.id : event.target.name] = event.target.checked;
        } else {
            newSTRD[event.target.id ? event.target.id : event.target.name] =
                event.target.value == 'true'
                    ? true
                    : event.target.value == 'false'
                    ? false
                    : event.target.value?.toString() == 'true'
                    ? true
                    : event.target.value?.toString() == 'false'
                    ? false
                    : event.target.value;
        }

        handleServiceTypeRequestDmeEdit(newSTRD);

        if (hasBeenSubmitted) validate(true);
    };

    const productTableProps: MaterialTableProps<ProductInformationEntity> = {
        columns: [
            {
                title: 'Product Category',
                render: (rowData) =>
                    productCategories.find((p) => p.productCategoryId === rowData.productCategoryId)
                        ?.productCategoryName,
            },
            {
                title: 'Product',
                field: 'productName',
            },
            {
                title: 'Quantity',
                field: 'quantity',
            },
            {
                title: 'Rental/Purchase',
                field: 'rentalPurchase',
            },
            {
                title: 'Durations (days)',
                field: 'duration',
            },
            {
                title: 'Delivery Address',
                render: (rowData) =>
                    DMERequestMakeFullAddress(rowData) ,
            },
            {
                title: 'Location',
                field: 'deliveryLocation',
            },
            {
                title: 'Requested Delivery Date',
                field: 'requestDeliveryDate',
                render: (rowData) => {
                    const formattedDate = rowData.requestDeliveryDate 
                        ? Moment(rowData?.requestDeliveryDate)?.format('YYYY-MM-DD')
                        : '';
                    return formattedDate;
                },
            },
        ],
        data: serviceTypeRequestDme?.products ?? [],
        options: {
            selection: false,
            paging: false,
            toolbar: false,
            actionsColumnIndex: -1,
        },
        actions: [
            {
                icon: 'delete',
                tooltip: 'delete detail',
                position: 'row',
                onClick: (event, rowData) => {
                    if (Array.isArray(rowData)) return;
                    handleDeleteRow(rowData);
                },
            },
        ],
    };

    const handleDeleteRow = (productInformation: ProductInformationEntity) => {
        const newSTRD: ServiceTypeRequestDmeEntity = { ...serviceTypeRequestDme };
        newSTRD.products.splice(newSTRD.products.indexOf(productInformation), 1);

        if (productInformation.productInformationId) {
            deleteProductInformationById(productInformation.productInformationId, abortController.signal)
                .then(() => {
                    toast.success('Product deleted successfully.');
                })
                .catch(() => {
                    toast.error('Error deleting product.');
                });
        }

        handleServiceTypeRequestDmeEdit(newSTRD);
    };

    const handleAddNewProductInformation = () => {
        if (!localValidate()) {
            return;
        }

        const newSTRD: ServiceTypeRequestDmeEntity = { ...serviceTypeRequestDme };

        const productName = products.find((p) => p.productId === newProductInformation.productId)?.productName;
        newProductInformation.productName = productName;
        newProductInformation.referralId = referralId;
        if (addressDmeItem.address?.length>0)
            {
                newProductInformation.address = addressDmeItem.address;
                newProductInformation.cityName = addressDmeItem.cityName;
                newProductInformation.zipCode = addressDmeItem.zipCode;
                newProductInformation.stateId = addressDmeItem.stateId;
                newProductInformation.addressLine2 = addressDmeItem.addressLine2;
                newProductInformation.fullAddress = addressDmeItem.fullAddress;
            }
            newProductInformation.deliveryLocation = serviceTypeRequestDme.deliveryAddress;
            newProductInformation.requestDeliveryDate = serviceTypeRequestDme.requestDeliveryDate;

        if (newSTRD.products) {
            newSTRD.products.push(newProductInformation);
        } else {
            newSTRD.products = [newProductInformation];
        }

        handleServiceTypeRequestDmeEdit({
            ...serviceTypeRequestDme,
            products: newSTRD.products,
        });

        setProducts([]);
        const newProductInformationEntity = new ProductInformationEntity();
        newProductInformationEntity.quantity = null;
        setNewProductInformation(newProductInformationEntity);
        setLocalHasBeenSubmitted(false);
        setTriggerUpdate(triggerUpdate + 1);
        handleClear();//clear address item
    };

    const isValidDmeProductCategory = () => { //returns true/false to know if the form has passed the validation OK (true) or not (false)
        let errors: IProductErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsListDmeProductCategory();
        setErrors(errors);
        isValidResult = (Object.keys(errors).length == 0);
        return isValidResult;
    }

    const getErrorsListDmeProductCategory = () => {//returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: IProductErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setLocalHasBeenSubmitted(true);
        }
        if (!(serviceTypeRequestDme?.products?.length > 0 )) {
            errors.productCategoryId = 'Required';
        }
        return errors;
    }

    const validateProductId = (newProductInformation) => {
        return newProductInformation && newProductInformation.productId !== null && newProductInformation.productId !== undefined
            ? ''
            : 'Required';
    };
    
    const validateProductCategoryId = (newProductInformation) => {
        return newProductInformation && newProductInformation.productCategoryId !== null && newProductInformation.productCategoryId !== undefined
            ? ''
            : 'Required';
    };
    
    const validateQuantity = (newProductInformation) => {
        return newProductInformation && newProductInformation.quantity !== null && newProductInformation.quantity !== undefined
            ? newProductInformation.quantity > 0
                ? ''
                : 'Quantity must be greater than 0'
            : 'Required';
    };
    
    const validateRentalPurchase = (newProductInformation) => {
        return newProductInformation && newProductInformation.rentalPurchase !== null && newProductInformation.rentalPurchase !== undefined
            ? ''
            : 'Required';
    };
    
    const validateDuration = (newProductInformation) => {
        if (!newProductInformation || newProductInformation.rentalPurchase !== 'Rental') {
            return '';
        }
    
        if (newProductInformation.duration === null || newProductInformation.duration === undefined) {
            return 'Required';
        }
    
        return newProductInformation.duration > 0 ? '' : 'Duration must be greater than 0';
    };
    
    const checkForDuplicateProduct = (newProductInformation, serviceTypeRequestDme) => {
        return serviceTypeRequestDme?.products?.length > 0 &&
               serviceTypeRequestDme.products.findIndex((p) => p.productId === newProductInformation.productId) !== -1
            ? 'You can only add each product once.'
            : '';
    };

    const localValidate = (isChange?: boolean) => {
        if (!localHasBeenSubmitted) {
            setLocalHasBeenSubmitted(true);
        }
    
        const errors: IProductErrors = {};
    
        errors.productId = validateProductId(newProductInformation);
        errors.productCategoryId = validateProductCategoryId(newProductInformation);
        errors.quantity = validateQuantity(newProductInformation);
        errors.rentalPurchase = validateRentalPurchase(newProductInformation);
        errors.duration = validateDuration(newProductInformation);
        errors.productId = checkForDuplicateProduct(newProductInformation, serviceTypeRequestDme) || errors.productId;
    
        setErrors(errors);
        return checkForErrors(errors, isChange);
    };

    return (
        <Fragment>
            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={3}>
                    <TooltipTextField
                        type="select"
                        formControlClass={classes.selectInput}
                        inputLabelId="product-category-label"
                        id="productCategoryId"
                        name="productCategoryId"
                        label="Product Category"
                        key={newProductInformation.productCategoryId}
                        value={newProductInformation.productCategoryId ?? ''}
                        onChange={handleChangeNewProductInformation}
                        required
                        error={!!errors?.productCategoryId}
                    >
                        {productCategories?.map((productCategory) => (
                            <MenuItem key={productCategory.productCategoryId} value={productCategory.productCategoryId}>
                                {productCategory.productCategoryName}
                            </MenuItem>
                        ))}
                    </TooltipTextField>
                    <FormHelperText className={classes.errorText}>
                        {errors?.productCategoryId && errors?.productCategoryId}
                    </FormHelperText>
                </Grid>
                <Grid item xs={3}>
                    <TooltipTextField
                        type="select"
                        formControlClass={classes.selectInput}
                        inputLabelId="product-label"
                        id="productId"
                        name="productId"
                        label="Product"
                        key={newProductInformation.productId}
                        value={newProductInformation.productId ?? ''}
                        disabled={products.length === 0}
                        onChange={handleChangeNewProductInformation}
                        required
                        notShrinkOnDisabled={false}
                        error={!!errors?.productId}
                    >
                        {products.map((product) => (
                            <MenuItem key={product.productId} value={product.productId}>
                                {product.productName}
                            </MenuItem>
                        ))}
                    </TooltipTextField>
                    <FormHelperText className={classes.errorText}>
                        {errors?.productId && errors?.productId}
                    </FormHelperText>
                </Grid>
                <Grid item xs={2}>
                    <TooltipTextField
                        type="number"
                        id="quantity"
                        label="Quantity"
                        key={triggerUpdate}
                        value={newProductInformation.quantity}
                        onChange={handleChangeNewProductInformation}
                        required
                        error={!!errors?.quantity}
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors?.quantity && errors?.quantity}
                    </FormHelperText>
                </Grid>
                <Grid item xs={2}>
                    <TooltipTextField
                        type="select"
                        inputLabelId="rental-purchase-label"
                        name="rentalPurchase"
                        id="rentalPurchase"
                        value={newProductInformation.rentalPurchase ?? ''}
                        onChange={handleChangeNewProductInformation}
                        label="Rental/Purchase"
                        required
                        error={!!errors?.rentalPurchase}
                    >
                        <MenuItem key={'Rental'} value={'Rental'}>
                            Rental
                        </MenuItem>
                        <MenuItem key={'Purchase'} value={'Purchase'}>
                            Purchase
                        </MenuItem>
                    </TooltipTextField>
                    <FormHelperText className={classes.errorText}>
                        {errors?.rentalPurchase && errors?.rentalPurchase}
                    </FormHelperText>
                </Grid>
                <Grid item xs={2}>
                    <TooltipTextField
                        type="number"
                        id="duration"
                        label="Duration (days)"
                        key={triggerUpdate}
                        value={newProductInformation.duration}
                        onChange={handleChangeNewProductInformation}
                        error={!!errors?.duration}
                    />
                    <FormHelperText className={classes.errorText}>
                        {errors?.duration && errors?.duration}
                    </FormHelperText>
                </Grid>
                
                {/*row 2*/}
                <Grid item xs={2}>
                <SearchAddressAsync passAddress={passAddress} states={states} />
                                      
                </Grid>
                <Grid item xs={2} spacing={3} container>
                        <Grid item xs={12}>
                            <AddressValue 
                                key="ServiceTypeRequestDmeSearchAddressResult" 
                                id="ServiceTypeRequestDmeSearchAddressResult" 
                                address={serviceTypeRequestDmeFullAddress} 
                                handleClear={handleClear} 
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={1} className={classes.verticallyCentered}>
                    <Typography variant="body2" >Location</Typography>
                </Grid>
                    <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            aria-label="position"
                            name="deliveryAddress"
                            onChange={(event) => handleServiceTypeRequestDme(event)}
                            value={serviceTypeRequestDme?.deliveryAddress}
                        >
                            <FormControlLabel value={'Home'} control={<Radio color="primary" />} label="Home" />
                            <FormControlLabel value={'Hospital'} control={<Radio color="primary" />} label="Hospital" />
                            <FormControlLabel value={'Other'} control={<Radio color="primary" />} label="Other" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <TooltipTextField
                        type="date"
                        id="requestDeliveryDate"
                        name="requestDeliveryDate"
                        label="Request Delivery Date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        //key={serviceTypeRequestDme?.requestDeliveryDate}
                        value={
                            serviceTypeRequestDme?.requestDeliveryDate
                                ? Moment(serviceTypeRequestDme?.requestDeliveryDate)?.format('YYYY-MM-DD')
                                : ''
                        }
                        onChange={handleServiceTypeRequestDme}
                    />
                </Grid>
                    <Grid item xs={2}>
                    <Button className={classes.button} onClick={handleAddNewProductInformation}>
                        Add
                    </Button>
                </Grid>
                {/*row 3*/}

                <Grid item xs={12}>
                    <MaterialTable tableProps={productTableProps} />
                </Grid>
                {/*row 4*/}

                <Grid item xs={3}>
                    <FormControlLabel
                        label={<Typography variant="body2">Rush Request</Typography>}
                        control={
                            <Checkbox
                                key={serviceTypeRequestDme?.rushRequest == null ? 'false' : 'true'}
                                id="rushRequest"
                                name="rushRequest"
                                disableRipple
                                checked={serviceTypeRequestDme?.rushRequest}
                                onClick={(event) => handleServiceTypeRequestDme(event)}
                            />
                        }
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl component="fieldset">
                        <FormLabel
                            component="legend" //error={!!props.errors?.translationType} required
                        >
                            Prescription
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-label="position"
                            id="prescription"
                            name="prescription"
                            key={serviceTypeRequestDme?.prescription ? 1 : 0}
                            onChange={(event) => handleServiceTypeRequestDme(event)}
                            value={serviceTypeRequestDme?.prescription}
                        >
                            <FormControlLabel value={true} control={<Radio color="primary" />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio color="primary" />} label="No" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    {/*Empty space */}
                </Grid>
                {/*row 5*/}
                <Grid item xs={3}>
                    <TooltipTextField
                        id="contactName"
                        name="contactName"
                        label="Contact Name"
                        value={serviceTypeRequestDme?.contactName}
                        onChange={handleServiceTypeRequestDme}
                    />
                </Grid>
                <Grid item xs={3}>
                <InputMask
                            mask="(999)-9999999"
                            value={
                                 serviceTypeRequestDme?.contactPhoneNumber
                            }
                            onChange= {handleServiceTypeRequestDme}
                            disabled={false}
                            maskChar=" "
                        >
                            {() => (
                                <TooltipTextField
                                    key="contactPhoneNumber"
                                    label="Contact Phone Number"
                                    id="contactPhoneNumber"
                                />
                            )}
                        </InputMask>
                </Grid>
                <Grid item xs={3}>
                    {/*Empty spcae */}
                </Grid>

                {/*row 6*/}

                <Grid item xs={12}>
                    <TooltipTextField
                        id="additionalComments"
                        name="additionalComments"
                        label="Additional Comments"
                        value={serviceTypeRequestDme?.additionalComments}
                        onChange={handleServiceTypeRequestDme}
                    />
                </Grid>
            </Grid>
            {Dialog}
        </Fragment>
    );
};
