import { createTheme , makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({    
    stepper:{
        width: "100%",
    },
    activityDates:{
        textAlign: "right"
    },
    connector: {
        padding: "0px",
    },
    root: {
        width: '100%',
    },
    button: {
    },
    actionsContainer: {
    },
    resetContainer: {
    },

    paperTitle: {
        color: '#428302'
    },
    paper: {
     
    },
    paperHeader: {
        flexDirection: 'row-reverse'
    },
    claimDataContainer: {
        padding: 25
    },
    sidebarTitle: {
        color: '#428302'
    },
    dataContainer: {
        paddingTop: 25
    },
    dataLabel: {
        color: '#898F91',
        fontSize: '12px'
    },
    dataRow: {
        paddingTop: 20
    },
    highlightedData: {
        color: '#428302', 
        fontWeight: 'bold'
    },
    iconTextRow: {
        display: 'inline-flex', 
        flexDirection: 'row'
    },
    blackFont: {
        color: '#000000'
    },
    claimData: {
        color: '#000000', 
        fontWeight: 'bold', 
        marginLeft: 10
    },
    iconData:{
        color: '#000000', 
        marginLeft: 10
    },
    divider:{
        height: '2px', 
        marginTop: 20, 
        color: '#898F91'
    },
    lastUpdateText:{
        color: '#898F91', 
        fontSize: '12px' 
    },
    bottomContainer:{
        bottom: '0', 
        marginTop: 'auto'
    },
    bottomTextContainer:{
        padding: 25, 
        paddingBottom: 80
    }
}));

export const newTheme = createTheme ({
    overrides:{
        MuiStepIcon:{
            active: {
                color: "rgba(0, 0, 0, 0.38) !important"
            },
            completed:{
                color:"#428302 !important"
            }
        },
        MuiStepConnector:{
            vertical: {
                marginLeft: "7px"
            },
            lineVertical: {
                minHeight: "10px",
                borderLeftStyle: "dotted",
                borderLeftWidth: "3px",
            }
        },
        MuiSvgIcon:{
            
            root:{
                width: "0.75em",
                marginRight: '16px!important'
            }
          },
    },
});