import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import styles from './DxResponseSummary.Styles';
import Moment from 'moment';
import ReferralStatusEntity from '../../../../entities/ReferralStatus.Entity';
import ReferralScheduleStatusTypeEntity from '../../../../entities/ReferralScheduleStatusType.Entity';
import { ReferralScheduleStatusTypesEnum } from '../../../../support/enums/ReferralScheduleStatusTypesEnum';
import DiagnosticProcedureResponseDataModel from '../../../../data-models/DiagnosticProcedureResponse.DataModel';

interface IDxResponseSummaryProps {
    referralStatus: ReferralStatusEntity;
    referralScheduleStatusTypes: Array<ReferralScheduleStatusTypeEntity>;
    diagnosticProcedureResponse: DiagnosticProcedureResponseDataModel;
    currentServiceType: string;
}

export const DxResponseSummary: FunctionComponent<IDxResponseSummaryProps> = (props: IDxResponseSummaryProps) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>
                    Diagnostic - {props.currentServiceType}: {props.diagnosticProcedureResponse?.specificBodyPartName}{' '}
                    {props.diagnosticProcedureResponse && props.diagnosticProcedureResponse?.bodySideName
                        ? ` - ${props.diagnosticProcedureResponse?.bodySideName}`
                        : ''}{' '}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {props.diagnosticProcedureResponse && (
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.dataLabel}>
                                Specific Body Parts
                            </Typography>
                            <Typography variant="subtitle2" className={classes.blackFont}>
                                {props.diagnosticProcedureResponse?.specificBodyPartName || '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.dataLabel}>
                                Body Side
                            </Typography>
                            <Typography variant="subtitle2" className={classes.blackFont}>
                                {props.diagnosticProcedureResponse?.bodySideName || '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.dataLabel}>
                                Diagnosis
                            </Typography>
                            <Typography variant="subtitle2" className={classes.blackFont}>
                                {props.diagnosticProcedureResponse?.diagnosis || '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.dataLabel}>
                                Procedure Details
                            </Typography>
                            <Typography variant="subtitle2" className={classes.blackFont}>
                                {props.diagnosticProcedureResponse?.procedureDetailName || '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.dataLabel}>
                                Age of Injury
                            </Typography>
                            <Typography variant="subtitle2" className={classes.blackFont}>
                                {props.diagnosticProcedureResponse?.ageOfInjuryReview == true
                                    ? 'Yes'
                                    : props.diagnosticProcedureResponse?.ageOfInjuryReview == false
                                    ? 'No'
                                    : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            {/* Empty space */}
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.dataLabel}>
                                Schedule Status
                            </Typography>
                            <Typography variant="subtitle2" className={classes.blackFont}>
                                {props.diagnosticProcedureResponse &&
                                props.diagnosticProcedureResponse?.referralScheduleStatusTypeId
                                    ? props.referralScheduleStatusTypes?.find(
                                          (rs) =>
                                              rs.referralScheduleStatusTypeId ===
                                              props.diagnosticProcedureResponse?.referralScheduleStatusTypeId,
                                      )?.referralScheduleStatusTypeLabel
                                    : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.dataLabel}>
                                Schedule date & time
                            </Typography>
                            <Typography variant="subtitle2" className={classes.blackFont}>
                                {props.diagnosticProcedureResponse && props.diagnosticProcedureResponse?.scheduledDate
                                    ? Moment(props.diagnosticProcedureResponse?.scheduledDate).format('MM/DD/YYYY') +
                                      ' at ' +
                                      Moment(props.diagnosticProcedureResponse?.scheduledDate).format('HH:mm')
                                    : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.dataLabel}>
                            Claimant Verified They Will Attend
                            </Typography>
                            <Typography variant="subtitle2" className={classes.blackFont}>
                                {props.diagnosticProcedureResponse &&
                                props.diagnosticProcedureResponse?.attendanceVerifiedDate
                                    ? 'Yes - ' +
                                      Moment(props.diagnosticProcedureResponse?.attendanceVerifiedDate).format(
                                          'MM/DD/YYYY',
                                      )
                                    : 'No'}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography variant="body2" className={classes.dataLabel}>
                            Claimant Attended
                            </Typography>
                            <Typography variant="subtitle2" className={classes.blackFont}>
                                {props.diagnosticProcedureResponse &&
                                props.diagnosticProcedureResponse?.referralScheduleStatusTypeId ==
                                    ReferralScheduleStatusTypesEnum.Completed
                                    ? 'Show'
                                    : props.diagnosticProcedureResponse &&
                                      props.diagnosticProcedureResponse?.referralScheduleStatusTypeId ==
                                          ReferralScheduleStatusTypesEnum.ClaimantNotAttended
                                    ? 'No-Show'
                                    : '-'}
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            {/* Empty space */}
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" className={classes.dataLabel}>
                                Doctor Summary
                            </Typography>
                            {props.diagnosticProcedureResponse?.doctorSummary?.split('\n').map((sentence, key) => {
                                if(sentence === '' || sentence === undefined || sentence === null || sentence.length === 0)
                                    return <br></br>
                                return <Typography key={key} variant="subtitle2" className={classes.blackFont}>
                                    {sentence}
                                </Typography>
                            })}
                            <Divider className={classes.divider}></Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" className={classes.dataLabel}>
                                Review Notes
                            </Typography>
                            {props.diagnosticProcedureResponse?.reviewNotes?.split('\n').map((sentence, key) => (
                                <Typography key={key} variant="subtitle2" className={classes.blackFont}>
                                    {sentence}
                                </Typography>
                            ))}
                            <Divider className={classes.divider}></Divider>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" className={classes.dataLabel}>
                                Other Comments
                            </Typography>
                            {props.diagnosticProcedureResponse?.otherComments?.split('\n').map((sentence, key) => (
                                <Typography key={key} variant="subtitle2" className={classes.blackFont}>
                                    {sentence}
                                </Typography>
                            ))}
                            <Divider className={classes.divider}></Divider>
                        </Grid>
                        {props.diagnosticProcedureResponse?.procedureCreatedByVendorOn && (
                            <Grid item xs={12}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Updated by Vendor - Reason
                                </Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>
                                    {props.diagnosticProcedureResponse?.updatedByVendorReason}
                                </Typography>
                                <Divider className={classes.divider}></Divider>
                            </Grid>
                        )}
                        {
                            props.diagnosticProcedureResponse?.referralScheduleStatusTypeId === ReferralScheduleStatusTypesEnum.VendorCancelled && (
                            <Grid item xs={12}>
                                <Typography variant="body2" className={classes.dataLabel}>
                                    Canceled by Vendor - Reason
                                </Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>
                                    {props.diagnosticProcedureResponse?.cancelationReason}
                                </Typography>
                                <Divider className={classes.divider}></Divider>
                            </Grid>
                        )}
                    </Grid>
                )}
            </AccordionDetails>
        </Accordion>
    );
};
