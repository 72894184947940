import React, { Fragment, FunctionComponent, useEffect } from 'react';
import styles from "./UrHeader.Styles";
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core';
import { EmptyDataLabel } from '../../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import { IMedicalServiceReferralFormErrors } from "../MedicalServiceReferralForm.Component";
import { TooltipTextField } from "../../../../../generic-components/tooltip-textfield/TooltipTextField.Component";
import ServiceTypeEntity from '../../../../../../entities/ServiceType.Entity';
import UtilizationReviewDetailEntity from "../../../../../../entities/UtilizationReviewDetail.Entity";
import ServiceTypeRequestUrEntity from "../../../../../../entities/UtilizationReviewDetail.Entity";

interface IUrHeaderProps {
    referralId: number,
    errors: IMedicalServiceReferralFormErrors,
    validate: (isChange?: boolean) => void,
    hasBeenSubmitted: boolean,
    serviceTypes: Array<ServiceTypeEntity>,
    handleSpecificBodyPart: (sbpId: number) => void;
    handleServiceTypeRequestUrEdit: (value) => void; 
    serviceTypeRequestUr: UtilizationReviewDetailEntity;
}

export const UrHeader: FunctionComponent<IUrHeaderProps> = (props: IUrHeaderProps) => {
    const classes = styles();
    const abortController = new AbortController();

    const [localServiceTypes, setLocalServiceTypes] = React.useState<Array<ServiceTypeEntity>>(null);

    const [localServiceTypeUr, setLocalServiceTypeUr] = React.useState<ServiceTypeRequestUrEntity>(props.serviceTypeRequestUr);

    //componentDidMount
    useEffect(() => {

        if(props.serviceTypeRequestUr)
        {
            let newUrd: UtilizationReviewDetailEntity = props.serviceTypeRequestUr;
            setLocalServiceTypeUr(newUrd);
        } else {
            let newUrd = new UtilizationReviewDetailEntity();
            setLocalServiceTypeUr(newUrd);
        }

        if(!localServiceTypes && props.serviceTypes){
            setLocalServiceTypes(props.serviceTypes);
        }

        return function cleanup() {
            abortController.abort();
        }
    }, [props]);

    const handleUtilizationReviewDetailChange = (event) => {
        event.persist();
        let newLocalServiceTypeUr :UtilizationReviewDetailEntity = {...localServiceTypeUr};
        if (event.target.type == 'checkbox') {
            newLocalServiceTypeUr[event.target.id ? event.target.id : event.target.name] = event.target.checked;
        }
        else {
            newLocalServiceTypeUr = {...localServiceTypeUr,[event.target.id ? event.target.id : event.target.name] : event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value.toString() == "true" ? true : event.target.value.toString() == "false" ? false : event.target.value};
        }

        setLocalServiceTypeUr(newLocalServiceTypeUr);
        props.handleServiceTypeRequestUrEdit(newLocalServiceTypeUr);

        if(props.hasBeenSubmitted)
            props.validate(true);
    }

    return (
        <Fragment>
            {
                localServiceTypes ?
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={3} >
                            <FormControlLabel
                                key={localServiceTypeUr?.rushRequest ? 'true' : 'false'}
                                control={
                                    <Checkbox
                                        id={'rushRequest'}
                                        name={'rushRequest'}
                                        disableRipple
                                        checked={localServiceTypeUr?.rushRequest}
                                        onClick={(event) => handleUtilizationReviewDetailChange(event)}
                                    />
                                }
                                label={<Typography variant="body2">Rush Request</Typography>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TooltipTextField 
                                label='Reason for referral'
                                id='reasonForReferral'
                                name='reasonForReferral'
                                InputLabelProps={{
                                    shrink: localServiceTypeUr?.reasonForReferral ? true : false,
                                }}
                                maxRows={4} 
                                multiline={true}
                                value={localServiceTypeUr?.reasonForReferral}
                                onChange={(event) => handleUtilizationReviewDetailChange(event)}
                                inputProps={{ maxLength: 500}}
                                helperText={`${localServiceTypeUr?.reasonForReferral ? localServiceTypeUr?.reasonForReferral.length : 0 }/${500}`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TooltipTextField 
                                label='Special Instructions'
                                id='specialInstructions'
                                name='specialInstructions'
                                InputLabelProps={{
                                    shrink: localServiceTypeUr?.specialInstructions ? true : false,
                                }}
                                //key={localServiceTypeUr?.specialInstructions}
                                maxRows={4} 
                                multiline={true}
                                value={localServiceTypeUr?.specialInstructions}
                                onChange={(event) => handleUtilizationReviewDetailChange(event)}
                                inputProps={{ maxLength: 500}}
                                helperText={`${localServiceTypeUr?.specialInstructions ? localServiceTypeUr?.specialInstructions.length : 0 }/${500}`}
                            />
                        </Grid>
                    </Grid>
                    : <EmptyDataLabel></EmptyDataLabel>
            }
        </Fragment>
    );
}