import ReferralNoteTtDataModel from './ReferralNoteTt.DataModel';

export default class TransportationTranslationResponse {
    serviceTypeId: number;
    appointmentDate: Date | string;
    reasonForCancellation: string;
    reviewNotes: Array<ReferralNoteTtDataModel>;
    scheduleStatus: string;
    serviceTypeName: string;
}
