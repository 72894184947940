import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    gridContainer: {
        width: '100%',
    },
    paper: {
        paddingTop: '15px',
        paddingLeft: '35px',
        paddingRight: '35px',
        paddingBottom: '25px',
    },
    dataLabel: {
        color: '#898F91',
        fontSize: '12px'
    },
    iconTextRow: {
        display: 'inline-flex',
        flexDirection: 'row'
    },
    blackFont: {
        color: '#000000'
    },
    redFont: {
        color: '#e74c3c'
    },
    iconData: {
        color: '#000000',
        marginLeft: 10
    },
    paperHeader: {
        flexDirection: 'row-reverse'
    },
    paperTitle: {
        color: '#428302'
    },
    button: {
        marginTop: '20px',
        float: "right",
        backgroundColor: '#107A98',
        '&:hover': {
            backgroundColor: '#004763!important',
        }
    },
    buttonContainer:{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center'
    }
}));