import React, { Fragment, FunctionComponent } from 'react';
import styles from './ReferralScorecard.Styles';
import { Grid, Typography, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReferralOverviewDataModel } from '../../../../data-models/ReferralOverview.DataModel';
import { ImeScorecard } from './ime-scorecard/ImeScorecard.Component';
import ImeScorecardResponseEntity from '../../../../entities/ImeScorecardResponse.Entity';
import ImeScorecardSectionDataModel from '../../../../data-models/ImeScorecardSection.DataModel';
import { PtScorecard } from './pt-scorecard/PtScorecard.Component';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';
import ScorecardSectionDataModel from '../../../../data-models/pt/ScorecardSection.DataModel';
import MedicalServiceDataModel from '../../../../data-models/MedicalService.DataModel';
import DiagnosticProcedureResponseScorecardTabDataModel from '../../../../data-models/DiagnosticProcedureResponseScorecardTabDataModel';
import { DxScorecard } from './dx-scorecard/DxScorecard.Component';

type PathParamsType = {
    id: string;
    claimId: string;
};

type ReferralScorecardProps = {
    referralOverviewDataModel: ReferralOverviewDataModel;
    imeScorecardResponses: Array<ImeScorecardResponseEntity>;
    imeScorecardSections: Array<ImeScorecardSectionDataModel>;
    medicalService: MedicalServiceDataModel;
    scorecardSections: Array<ScorecardSectionDataModel>;
    diagnosticProcedureScorecardResponse: Array<DiagnosticProcedureResponseScorecardTabDataModel>;
} & WithStyles<typeof styles> &
    RouteComponentProps<PathParamsType>;

const ReferralScorecardComponent: FunctionComponent<ReferralScorecardProps> = (props: ReferralScorecardProps) => {
    const { classes } = props;

    return (
        <Fragment>
            {
                <div className={classes.content}>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                {props.referralOverviewDataModel &&
                                    props.referralOverviewDataModel.referralServiceType[0].serviceId ==
                                        ServicesEnum.IME && (
                                        <ImeScorecard
                                            imeScorecardResponses={props.imeScorecardResponses}
                                            imeScorecardSections={props.imeScorecardSections}
                                        />
                                    )}
                                {props.referralOverviewDataModel &&
                                    props.referralOverviewDataModel.referralServiceType[0].serviceId ==
                                        ServicesEnum.PT && (
                                        <PtScorecard
                                            medicalService={props.medicalService}
                                            scorecardSections={props.scorecardSections}
                                        />
                                    )}
                                {props.referralOverviewDataModel &&
                                    props.referralOverviewDataModel.referralServiceType[0].serviceId ==
                                        ServicesEnum.Diagnostic &&
                                    props.diagnosticProcedureScorecardResponse && (
                                        <>
                                            <Grid item xs={12}>
                                                <Typography variant="subtitle2" className={classes.blackFont}>
                                                    The physician's examination of the claimant and the resulting report
                                                    reflects the following scorecard
                                                </Typography>
                                            </Grid>
                                            {props.diagnosticProcedureScorecardResponse?.map((response) => {
                                                return (
                                                    <DxScorecard
                                                        header={`${response.serviceTypeName} ${
                                                            response.specificBodyPartName != null
                                                                ? `: ${response.specificBodyPartName}`
                                                                : ''
                                                        } ${
                                                            response.bodySideName != null
                                                                ? `- ${response.bodySideName}`
                                                                : ''
                                                        }`}
                                                        scorecardSections={response.scorecards}
                                                    />
                                                );
                                            })}
                                        </>
                                    )}
                            </Grid>
                        </Grid>
                    </form>
                </div>
            }
        </Fragment>
    );
};

export const ReferralScorecard = withRouter(withStyles(styles)(ReferralScorecardComponent));
