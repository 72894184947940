import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import styles from './UrReferralForm.Styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ServiceTypeEntity from '../../../../../entities/ServiceType.Entity';
import StateEntity from '../../../../../entities/State.Entity';
import { useValidationDialog } from '../../../../../support/custom-hooks/useValidationDialog';
import {
    ITransportationHeaderErrors,
    TransportationHeader,
} from './transportation-header/TransportationHeader.Component';
import AppointmentTypeEntity from '../../../../../entities/AppointmentType.Entity';
import DestinationEntity from '../../../../../entities/Destination.Entity';
import OriginationEntity from '../../../../../entities/Origination.Entity';
import TransportationRequiredEntity from '../../../../../entities/TransportationRequired.Entity';
import ServiceTypeRequestTransportationEntity from '../../../../../entities/ServiceTypeRequestTransportation.Entity';
import TransportationDataModel from '../../../../../data-models/Transportation.DataModel';
import LanguageEntity from '../../../../../entities/Language.Entity';
import InterpretationTypeEntity from '../../../../../entities/InterpretationType.Entity';
import TranslationDataModel from '../../../../../data-models/Translation.DataModel';
import ServiceTypeRequestTranslationEntity from '../../../../../entities/ServiceTypeRequestTranslation.Entity';
import { ITranslationHeaderErrors, TranslationHeader } from './translation-header/TranslationHeader.Component';
import { WheelchairOrStrecherDetailEntity } from '../../../../../entities/WheelchairOrStrecherDetail.Entity';
import { translationTypes } from '../../../../../support/constants/TranslationTypes.Constants';

interface ITtReferralFormProps {
    referralId: number;
    states: Array<StateEntity>;
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean) => void;
    handleSetIsValidTtFunction: (currentPageIsValidTtFunction: () => boolean, serviceTypeString: string) => void;
    
    openCloseStatus: Object;
    handleOpenCloseStatus: (boxName: string, isOpened: boolean) => void;

    serviceId?: number;
    serviceTypes: Array<ServiceTypeEntity>;
    nextStep: boolean;
    handleTransportationServiceEdit?: (edit: TransportationDataModel) => void;
    transportationService: TransportationDataModel;
    appointmentTypes: Array<AppointmentTypeEntity>;
    transportationRequireds: Array<TransportationRequiredEntity>;
    originations: Array<OriginationEntity>;
    destinations: Array<DestinationEntity>;
    languages: Array<LanguageEntity>;
    handleTranslationServiceEdit?: (edit: TranslationDataModel) => void;
    translationService: TranslationDataModel;
    interpretationTypes: Array<InterpretationTypeEntity>;
    wheelchairOrStrecherDetail: Array<WheelchairOrStrecherDetailEntity>;
}

export interface ITtReferralFormErrors extends ITransportationHeaderErrors, ITranslationHeaderErrors {}

export const TtReferralForm: FunctionComponent<ITtReferralFormProps> = ({
    referralId,
    states,
    serviceId,
    serviceTypes,
    nextStep,
    handleTransportationServiceEdit,
    appointmentTypes,
    transportationRequireds,
    originations,
    destinations,
    handleSetValidationFunction,
    handleSetIsValidTtFunction,

    openCloseStatus,
    handleOpenCloseStatus,

    transportationService,
    languages,
    handleTranslationServiceEdit,
    translationService,
    interpretationTypes,
    wheelchairOrStrecherDetail,
}) => {
    const classes = styles();
    const [errors, setErrors] = React.useState<ITtReferralFormErrors>(null);
    const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState<boolean>(false);
    const { Dialog, checkForErrors } = useValidationDialog('Validation', 'Some required fields are missing.', 'Ok');
    const { Dialog: DestinationsDialog, checkForErrors: checkDestinationErrors } = useValidationDialog(
        'Destinations Validation',
        'You need to add at least one destination.',
        'Ok',
    );

    let isValidTtDestinationValidationFunction = null;

    const [transportationLocalState, setTransportationLocalState] = useState<TransportationDataModel>(
        transportationService,
    );

    const [translationLocalState, setTranslationLocalState] = useState<TranslationDataModel>(translationService);

    //#region transportation
    const [serviceTypeRequestTransportation, setServiceTypeRequestTransportation] = useState<
        ServiceTypeRequestTransportationEntity
    >({
        ...transportationService?.serviceTypeRequestTransportation,
        referralId: referralId,
    });
    const handleServiceTypeRequestTransportationEdit = (value) => {
        setServiceTypeRequestTransportation(value);
        const newT: TransportationDataModel = transportationService;
        newT.serviceTypeRequestTransportation = value;
        setTransportationLocalState(newT);
    };
    //#endregion

    //#region translation
    const [serviceTypeRequestTranslation, setServiceTypeRequestTranslation] = useState<
        ServiceTypeRequestTranslationEntity
    >({
        ...translationService?.serviceTypeRequestTranslation,
        referralId: referralId,
    });
    const handleServiceTypeRequestTranslationEdit = (value) => {
        setServiceTypeRequestTranslation(value);
        const newT: TranslationDataModel = translationService;
        newT.serviceTypeRequestTranslation = value;
        setTranslationLocalState(newT);
    };
    //#endregion

    useEffect(() => {
        const preService = transportationLocalState;

        let transportation: TransportationDataModel = transportationService;
        if (!transportationService) {
            transportation = new TransportationDataModel();
        }

        transportation.serviceTypeRequestTransportation = serviceTypeRequestTransportation;
        handleTransportationServiceEdit(transportation);

        let translation: TranslationDataModel = translationService;
        if (!translationService) {
            translation = new TranslationDataModel();
        }

        translation.serviceTypeRequestTranslation = serviceTypeRequestTranslation;
        handleTranslationServiceEdit(translation);
    }, [nextStep]);

    useEffect(() => {
        if ( !openCloseStatus.hasOwnProperty( "Translation_box" ) )  handleOpenCloseStatus( "Translation_box", true );
        if ( !openCloseStatus.hasOwnProperty( "Transportation_box" ) ) handleOpenCloseStatus( "Transportation_box", true );

        if (!transportationService) {
            const newT = new TransportationDataModel();
            setTransportationLocalState(newT);
        } else {
            const newT: TransportationDataModel = transportationService;
            setTransportationLocalState(newT);
        }

        if (!translationService) {
            const newTl = new TranslationDataModel();
            setTranslationLocalState(newTl);
        } else {
            const newTl: TranslationDataModel = translationService;
            setTranslationLocalState(newTl);
        }
    }, []);

    const handleOpenCloseStatus_local = ( event, boxName ) => {
        event.persist();
        handleOpenCloseStatus( boxName, !(openCloseStatus?.[boxName]??true) );
    };

    const setIsValidTtDestinationFunction = (isValidFunction) => {
        isValidTtDestinationValidationFunction = isValidFunction;
    }

    const validateTt = (isChange?: boolean) => {
        let errors: ITtReferralFormErrors = {};
        errors = getErrorsListTt()
        setErrors(errors);
        return checkForErrors(errors, isChange);
    };

    const isValidTt = () => { //returns true/false to know if the form has passed the validation OK (true) or not (false)
        let errors: ITtReferralFormErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsListTt();
        setErrors(errors);
        isValidResult = (Object.keys(errors).length == 0);
        return isValidResult;
    }

    const serviceType = serviceTypes.find((s) => translationTypes.includes(s.serviceTypeAbbreviation));

    const getErrorsListTt = () => {//returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: ITtReferralFormErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }

        if (serviceTypes.some((s) => translationTypes.includes(s.serviceTypeAbbreviation))) {
            if ( ( translationLocalState?.serviceTypeRequestTranslation?.appointmentTypeId ?? -1 ) < 0 ) errors.tlAppointmentType = 'Required';
            if ( !translationLocalState?.serviceTypeRequestTranslation?.appointmentDate ) errors.tlAppointmentDate = 'Required';        
            if ( ( translationLocalState?.serviceTypeRequestTranslation?.languageId ?? -1 ) < 0 ) errors.language = 'Required';            
            if ( !translationLocalState?.serviceTypeRequestTranslation?.translationType ) errors.translationType = 'Required';
        }

        
        if (serviceTypes.findIndex((s) => s.serviceTypeAbbreviation === 'TP') !== -1) {
            if ( !transportationLocalState?.serviceTypeRequestTransportation?.appointmentDate ) errors.appointmentDate = 'Required';
            if ( ( transportationLocalState?.serviceTypeRequestTransportation?.transportationRequiredId ?? -1 ) < 0 ) errors.transportationRequired = 'Required';
            if ( ( transportationLocalState?.serviceTypeRequestTransportation?.originationId ?? -1 ) < 0 ) errors.origination = 'Required';
            if ( !transportationLocalState?.serviceTypeRequestTransportation?.originationContactNumber ) errors.originationContactNumber = 'Required';
        }


        return errors;
    }

    const isValidTtDestination = () => { //returns true/false to know if the form has passed the validation OK (true) or not (false)
        let errors: ITtReferralFormErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsListTtDestination();
        setErrors(errors);
        isValidResult = (Object.keys(errors).length == 0);
        return isValidResult;
    }

    const getErrorsListTtDestination = () => {//returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: ITtReferralFormErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }

        if ( transportationLocalState?.serviceTypeRequestTransportation?.destinations === null ||
                transportationLocalState?.serviceTypeRequestTransportation?.destinations === undefined ||
                transportationLocalState?.serviceTypeRequestTransportation?.destinations?.length === 0 ) {
            errors.destinations = 'Required';
        }

        return errors;
    }

    useEffect(() => {
        //handleSetValidationFunction(validate);
        handleSetIsValidTtFunction( isValidTt, "Tt" );
        handleSetIsValidTtFunction( isValidTtDestinationValidationFunction /* isValidTtDestination  */, "TtDestination" );
    }, [handleSetIsValidTtFunction, isValidTt, isValidTtDestinationValidationFunction]);

    return (
        <Fragment>
            {serviceTypes.findIndex((s) => s.serviceTypeAbbreviation === 'TP') !== -1 && (
                <Grid item xs={12}>
                    <Accordion className={classes.paper} expanded={openCloseStatus["Transportation_box"]??true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                            aria-controls="panel1a-content"
                            id="Transportation-accordion"
                            className={classes.paperHeader}
                            onClick={(event) => handleOpenCloseStatus_local(event, "Transportation_box")}
                        >
                            <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                {'Transportation Service'}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <TransportationHeader
                                states={states}
                                referralId={referralId}
                                transportationRequireds={transportationRequireds}
                                originations={originations}
                                destinations={destinations}
                                errors={errors}
                                validate={validateTt}
                                handleSetIsValidTtDestinationFunction={setIsValidTtDestinationFunction}
                                hasBeenSubmitted={hasBeenSubmitted}
                                handleSetValidationFunction={handleSetValidationFunction}
                                serviceTypes={serviceTypes}
                                serviceTypeRequestTransportation={serviceTypeRequestTransportation}
                                handleServiceTypeRequestTransportationEdit={handleServiceTypeRequestTransportationEdit}
                                wheelchairOrStrecherDetail={wheelchairOrStrecherDetail}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            )}
            {serviceType && (
                <Grid item xs={12}>
                    <Accordion className={classes.paper} expanded={openCloseStatus["Translation_box"]??true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                            aria-controls="panel1a-content"
                            id="Translation-accordion"
                            className={classes.paperHeader}
                            onClick={(event) => handleOpenCloseStatus_local(event, "Translation_box")}                            
                        >
                            <Typography variant="subtitle2" className={classes.sidebarTitle} >
                                {'Translation Service'}
                            </Typography>                            
                        </AccordionSummary>
                        
                        <Typography variant="subtitle2" className={classes.subtitle}>
                                {serviceType.serviceTypeName}
                            </Typography>
                        
                        <AccordionDetails>
                            <TranslationHeader
                                referralId={referralId}
                                appointmentTypes={appointmentTypes}
                                interpretationTypes={interpretationTypes}
                                errors={errors}
                                validate={validateTt}
                                hasBeenSubmitted={hasBeenSubmitted}
                                handleSetValidationFunction={handleSetValidationFunction}
                                serviceTypes={serviceTypes}
                                serviceTypeRequestTranslation={serviceTypeRequestTranslation}
                                handleServiceTypeRequestTranslationEdit={handleServiceTypeRequestTranslationEdit}
                                languages={languages}
                                states={states}
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            )}
            {Dialog}
            {DestinationsDialog}
        </Fragment>
    );
};
