import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { ImeRequestSummary } from './ime-request-summary/ImeRequestSummary.Component';
import { PhysicianRequest } from './physician-request/PhysicianRequest.Component';
import { RecommendedPhysicianRequest } from './recommended-physician-request/RecommendedPhysicianRequest.Component';
import { CoverLetterSummary } from './cover-letter-summary/CoverLetterSummary.Component';
import ImeEntity from '../../../../../entities/Ime.Entity';
import { ReferralOverviewDataModel } from '../../../../../data-models/ReferralOverview.DataModel';
import LanguageEntity from '../../../../../entities/Language.Entity';
import StateEntity from '../../../../../entities/State.Entity';
import ReferralContactEntity from '../../../../../entities/ReferralContact.Entity';
import SpecialtyEntity from '../../../../../entities/Specialty.Entity';
import SpecificBodyPartEntity from '../../../../../entities/SpecificBodyPart.Entity';
import IMECoverLetterEntity from "../../../../../entities/IMECoverLetter.Entity";

type ImeReferralRequestProps = {
    ime: ImeEntity;
    referralOverviewDataModel: ReferralOverviewDataModel;
    specialties: Array<SpecialtyEntity>;
    languages: Array<LanguageEntity>;
    states: Array<StateEntity>;
    treatingPhysician: ReferralContactEntity;
    recommendedPhysician: ReferralContactEntity;
    specificBodyParts: Array<SpecificBodyPartEntity>;
    imeCoverLetter: IMECoverLetterEntity;
}

export const ImeReferralRequest: FunctionComponent<ImeReferralRequestProps> = (props: ImeReferralRequestProps) => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <ImeRequestSummary specificBodyParts={props.specificBodyParts} imeRequest={props.ime} specialties={props.specialties} languages={props.languages} />
            </Grid>
            <Grid item xs={12}>
                <PhysicianRequest physician={props.treatingPhysician} states={props.states} />
            </Grid>
            <Grid item xs={12}>
                <RecommendedPhysicianRequest physician={props.recommendedPhysician} states={props.states} />
            </Grid>
            <Grid item xs={12}>
                <CoverLetterSummary coverLetter={props.imeCoverLetter} />
            </Grid>
        </Grid>
    );
}
