//TODO: That's our entity reducer
import { Section } from '../../support/custom-hooks/useBreadCrum';
import { Action } from '../actions/usersActions';

export interface UsersState {
    roles: Array<number>;
    clientName: string;
    securityRules: Array<string>;
    userName: string;
    breadCrumb: Array<Section>;
}

const initialState: UsersState = {
    roles: [],
    clientName: '',
    securityRules: [],
    userName: '',
    breadCrumb: [],
};

export const usersReducer = (state: UsersState = initialState, action: Action) => {
    switch (action.type) {
        case 'MODIFY_USER_ROLES': {
            return { ...state, roles: action.payload };
        }
        case 'MODIFY_CLIENT_NAME': {
            return { ...state, clientName: action.payload };
        }
        case 'MODIFY_USER_SECURITY_RULES': {
            return { ...state, securityRules: action.payload };
        }
        case 'MODIFY_USER_NAME': {
            return { ...state, userName: action.payload };
        }
        case 'MODIFY_BREADCRUMB': {
            return { ...state, breadCrumb: action.payload };
        }
        default: {
            return state;
        }
    }
};
