import { apiGet } from '../support/helpers/ApiConnection.Helper';
import ServiceDataModel from '../data-models/Service.DataModel';
import ScorecardSectionDataModel from '../data-models/pt/ScorecardSection.DataModel';

export function getServices(abortSignal: AbortSignal, onlyAdjusterServices = false): Promise<Array<ServiceDataModel>> {
    //here is set DummyResponse
    /*var arrayServices: ServiceDataModel[] = new Array();
    var arrayServiceTypesIME: ServiceTypeDataModel[] = new Array();
    arrayServiceTypesIME = [
        new ServiceTypeDataModel(1,1,"IME","IME"),
        new ServiceTypeDataModel(2,1,"EME","EME"),
    ];
    var arrayServiceTypesMCM: ServiceTypeDataModel[] = new Array();
    arrayServiceTypesMCM = [
        new ServiceTypeDataModel(3,2,"FCM","FCM"),
        new ServiceTypeDataModel(4,2,"TCM","TCM"),
    ];
    arrayServices = [
        new ServiceDataModel(1,"IME","IME",arrayServiceTypesIME),
        new ServiceDataModel(2,"MCM","MCM",arrayServiceTypesMCM),
    ];
    var promise = new Promise<Array<ServiceDataModel>>((resolve, reject) => {
        resolve();
    }).then(
        (value) => {
            return arrayServices;
        },
        (error) => {
            console.error(error);
            return arrayServices;
        }
    );
    return promise;*/
    return apiGet<Array<ServiceDataModel>>(`/services?onlyAdjusterServices=${onlyAdjusterServices}`, abortSignal);
}

export function getScorecardSections(
    serviceId: number,
    abortSignal: AbortSignal,
): Promise<Array<ScorecardSectionDataModel>> {
    return apiGet<Array<ScorecardSectionDataModel>>('/services/' + serviceId + '/scorecard-sections', abortSignal);
}

export function getAllServices(abortSignal: AbortSignal): Promise<Array<ServiceDataModel>> {
    return apiGet<Array<ServiceDataModel>>('/services/all', abortSignal);
}
