import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary, RadioGroup, FormControlLabel, Radio, ThemeProvider } from '@material-ui/core';
import styles, { imeScorecardTheme } from "./ImeScorecard.Styles";
import { EmptyDataLabel } from '../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import { ImeScorecardSectionsEnum } from '../../../../../support/enums/ImeScorecardSections.Enum';
import ImeScorecardResponseEntity from '../../../../../entities/ImeScorecardResponse.Entity';
import ImeScorecardSectionDataModel from '../../../../../data-models/ImeScorecardSection.DataModel';

interface IImeScorecardProps {
    imeScorecardResponses: Array<ImeScorecardResponseEntity>;
    imeScorecardSections: Array<ImeScorecardSectionDataModel>;
}

export const ImeScorecard: FunctionComponent<IImeScorecardProps> = (props: IImeScorecardProps) => {
    const classes = styles();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Accordion defaultExpanded={true} className={classes.paper} square>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="cliam-accordion"
                        className={classes.paperHeader}
                    >
                        <Typography variant="subtitle2" className={classes.paperTitle}>Causality Score</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            props.imeScorecardResponses && props.imeScorecardResponses.length > 0 && props.imeScorecardSections && props.imeScorecardSections.length > 0 && props.imeScorecardSections?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.CasualtyScore)[0]?.options && props.imeScorecardResponses?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.CasualtyScore)[0]?.imeScorecardOptionId ?
                                <Grid container spacing={3} className={classes.gridContainer}>
                                    <Grid item xs={12}>
                                        <ThemeProvider theme={imeScorecardTheme}>
                                            <RadioGroup row aria-label="position" name="casualtyScore" value={+props.imeScorecardResponses?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.CasualtyScore)[0]?.imeScorecardOptionId}>
                                                <Grid container spacing={1}>
                                                    {
                                                        props.imeScorecardSections?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.CasualtyScore)[0]?.options?.map(option =>
                                                            <Grid item xs={12} className={classes.scoreRow}>
                                                                <FormControlLabel disabled value={option.imeScorecardOptionId} control={<Radio color="primary" />} label={<Typography variant="subtitle2" className={option.optionNumber == 1 ? classes.bolderFont : classes.blackFont}>{option.value}</Typography>} />
                                                                <Typography variant="subtitle2" className={+props.imeScorecardResponses?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.CasualtyScore)[0]?.imeScorecardOptionId == option.imeScorecardOptionId ? classes.bolderFont : classes.blackFont }>{option.description}</Typography>
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>
                                            </RadioGroup>
                                        </ThemeProvider>
                                    </Grid>
                                </Grid>
                                :
                                <EmptyDataLabel></EmptyDataLabel>
                        }
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Accordion defaultExpanded={true} className={classes.paper} square>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="cliam-accordion"
                        className={classes.paperHeader}
                    >
                        <Typography variant="subtitle2" className={classes.paperTitle}>Treatment Score</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            props.imeScorecardResponses && props.imeScorecardResponses.length > 0 && props.imeScorecardSections && props.imeScorecardSections.length > 0 && props.imeScorecardResponses?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.TreatmentScore)[0]?.imeScorecardOptionId && props.imeScorecardSections?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.TreatmentScore)[0]?.options ?
                                <Grid container spacing={3} className={classes.gridContainer}>
                                    <Grid item xs={12}>
                                        <ThemeProvider theme={imeScorecardTheme}>
                                            <RadioGroup row aria-label="position" name="treatmentScore" value={+props.imeScorecardResponses?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.TreatmentScore)[0]?.imeScorecardOptionId}>
                                                <Grid container spacing={1}>
                                                    {
                                                        props.imeScorecardSections?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.TreatmentScore)[0]?.options.map(option =>
                                                            <Grid item xs={12} className={classes.scoreRow}>
                                                                <FormControlLabel disabled value={option.imeScorecardOptionId} control={<Radio color="primary" />} label={<Typography variant="subtitle2" className={option.optionNumber == 1 ? classes.bolderFont : classes.blackFont}>{option.value}</Typography>} />
                                                                <Typography variant="subtitle2" className={+props.imeScorecardResponses?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.TreatmentScore)[0]?.imeScorecardOptionId == option.imeScorecardOptionId ? classes.bolderFont : classes.blackFont}>{option.description}</Typography>
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>
                                            </RadioGroup>
                                        </ThemeProvider>
                                    </Grid>
                                </Grid>
                                :
                                <EmptyDataLabel></EmptyDataLabel>
                        }
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Accordion defaultExpanded={true} className={classes.paper} square>
                    <AccordionSummary
                        aria-controls="panel1a-content"
                        id="cliam-accordion"
                        className={classes.paperHeader}
                    >
                        <Typography variant="subtitle2" className={classes.paperTitle}>Work Score</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {
                            props.imeScorecardResponses && props.imeScorecardResponses.length > 0 && props.imeScorecardSections && props.imeScorecardSections.length > 0 && props.imeScorecardResponses?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.WorkScore)[0]?.imeScorecardOptionId && props.imeScorecardSections?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.WorkScore)[0]?.options ?
                                <Grid container spacing={3} className={classes.gridContainer}>
                                    <Grid item xs={12}>
                                        <ThemeProvider theme={imeScorecardTheme}>
                                            <RadioGroup row aria-label="position" name="workScore" value={+props.imeScorecardResponses?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.WorkScore)[0]?.imeScorecardOptionId}>
                                                <Grid container spacing={1}>
                                                    {
                                                        props.imeScorecardSections?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.WorkScore)[0].options?.map(option =>
                                                            <Grid item xs={12} className={classes.scoreRow}>
                                                                <FormControlLabel disabled value={option.imeScorecardOptionId} control={<Radio color="primary" />} label={<Typography variant="subtitle2" className={option.optionNumber == 1 ? classes.bolderFont : classes.blackFont}>{option.value}</Typography>} />
                                                                <Typography variant="subtitle2" className={+props.imeScorecardResponses?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.WorkScore)[0]?.imeScorecardOptionId == option.imeScorecardOptionId ? classes.bolderFont : classes.blackFont}>{option.description}</Typography>
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>
                                            </RadioGroup>
                                        </ThemeProvider>
                                    </Grid>
                                </Grid>
                                :
                                <EmptyDataLabel></EmptyDataLabel>
                        }
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    );
}