import React, { FunctionComponent, useEffect, useState } from 'react';
import { Route, useParams, useRouteMatch } from 'react-router-dom';
import ClientPreferredVendorDataModel from '../../../data-models/ClientPreferredVendor.DataModel';
import ServiceDataModel from '../../../data-models/Service.DataModel';
import VendorAdminTableDataModel from '../../../data-models/VendorAdminTable.DataModel';
import ClientEntity from '../../../entities/Client.Entity';
import StateEntity from '../../../entities/State.Entity';
import TenantEntity from '../../../entities/Tenant.Entity';
import UserEntity from '../../../entities/User.Entity';
import { clientsRules, preferredVendorRules, profileRules } from '../../../support/constants/SecurityRules.Constants';
import { BreadCrumb } from '../../../support/custom-hooks/useBreadCrum';
import AccessControl from '../../generic-components/access-control/AccessControl.Component';
import { ClientPreferredVendorSetup } from '../client-preferred-vendor-set-up/ClientPreferredVendorSetup.Component';
import { ClientProfile } from '../client-profile/ClientProfile.Component';
import styles from './ClientProfileController.Styles';

interface ClientProfileControllerProps {
    profile: ClientEntity;
    states: Array<StateEntity>;
    handleClientEdit: (newClient: ClientEntity) => void;
    handleStartClientProfileEdit: () => void;
    handleCancelClientProfileEdit: () => void;
    handleSaveEditedClientProfile: () => void;
    tenants: Array<TenantEntity>;
    vendors: Array<VendorAdminTableDataModel>;
    services: Array<ServiceDataModel>;
    currentTabIndex: string;
    handleNewPreferredVendorEdit: (edited: ClientPreferredVendorDataModel) => void;
    newPreferredVendor: ClientPreferredVendorDataModel;
    handleNewPreferredVendorMultipleStateEdit: (edited: Array<number>, states: Array<StateEntity>) => void;
    preferredVendors: Array<ClientPreferredVendorDataModel>;
    handleCreateNewPreferredVendor: () => Promise<void>;
    handleChangeClientPreferredVendorStatus: (id: Array<number>) => void;
    handleStartEditPreferredVendor: (
        tenantId: number,
        vendorId: number,
        serviceId: number,
        states: number[],
        clientPreferredVendorIds: number[],
    ) => void;
    handleConfirmEditPreferredVendor: (newStateIds: number[], removedStateIds: number[]) => void;
    handlConfirmUpdatePreferredVendor: (removedClientIds: number[]) => void;
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean) => void;
    userProfile: UserEntity;
    handleClearNewPreferredVendor: () => void;
    handleGetClientPreferredVendors: (clientId: string) => void;
    handleNavigateToProfile: (id: number, section: string) => void;
}

export const ClientProfileController: FunctionComponent<ClientProfileControllerProps> = ({
    profile,
    states,
    handleClientEdit,
    handleStartClientProfileEdit,
    handleCancelClientProfileEdit,
    handleSaveEditedClientProfile,
    tenants,
    currentTabIndex,
    vendors,
    services,
    handleNewPreferredVendorEdit,
    newPreferredVendor,
    handleNewPreferredVendorMultipleStateEdit,
    preferredVendors,
    handleCreateNewPreferredVendor,
    handleChangeClientPreferredVendorStatus,
    handleStartEditPreferredVendor,
    handleConfirmEditPreferredVendor,
    handlConfirmUpdatePreferredVendor,
    handleSetValidationFunction,
    userProfile, // only profile
    handleClearNewPreferredVendor,
    handleGetClientPreferredVendors,
    handleNavigateToProfile,
}) => {
    const [currentSection, setCurrentSection] = useState<string>('');
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        const tenantName = tenants?.find((t) => t.tenantId === profile?.tenantId)?.tenantName;
        if (currentTabIndex === 'Profile') {
            if (!profile) return;
            setCurrentSection(`${profile?.clientName} (${tenantName} Tenant) Profile`);
        } else if (currentTabIndex === 'Preferred Vendor') {
            setCurrentSection(`${profile?.clientName} (${tenantName} Tenant) Preferred Vendors`);
        } else {
            setCurrentSection(`${profile?.clientName} (${tenantName} Tenant) Profile`);
        }
    }, [currentTabIndex, profile]);

    useEffect(() => {
        if (profile === null) {
            handleNavigateToProfile(parseInt(id), 'Clients');
        }
    }, []);

    const classes = styles();
    const { path } = useRouteMatch();

    const clientProfilePermissions = [...clientsRules.read, ...profileRules.read];
    const clientPreferredVendorSetupPermissions = [...clientsRules.read, ...preferredVendorRules.read];

    const clientsBreadCrumb = {
        name: 'Clients',
        goBack: false,
        link: '/clients',
        path: path,
    };

    const profileBreadCrumb = {
        name: `${profile?.clientName} (${
            tenants?.find((t) => t.tenantId === profile?.tenantId)?.tenantName
        } Tenant) Profile`,
        goBack: false,
        link: `/clients/${id}/profile`,
        path: path,
    };

    const preferredVendorBreadCrumb = {
        name: `${profile?.clientName} (${
            tenants?.find((t) => t.tenantId === profile?.tenantId)?.tenantName
        } Tenant) Preferred Vendors`,
        goBack: false,
        link: `/clients/${id}/preferred-vendor`,
        path: path,
    };

    return (
        <>
            <Route path="/clients/:id/profile" exact>
                <AccessControl allowedPermissions={clientProfilePermissions} sendBack={false}>
                    <BreadCrumb sections={[clientsBreadCrumb, profileBreadCrumb]} />
                    <div className={classes.container}>
                        <ClientProfile
                            handleClientEdit={handleClientEdit}
                            client={profile}
                            states={states}
                            handleStartClientProfileEdit={handleStartClientProfileEdit}
                            handleCancelClientProfileEdit={handleCancelClientProfileEdit}
                            handleSaveEditedClientProfile={handleSaveEditedClientProfile}
                            tenants={tenants}
                            userProfile={userProfile}
                        />
                    </div>
                </AccessControl>
            </Route>
            <Route path="/clients/:id/preferred-vendor">
                <AccessControl allowedPermissions={clientPreferredVendorSetupPermissions} sendBack={false}>
                    <BreadCrumb sections={[clientsBreadCrumb, preferredVendorBreadCrumb]} />
                    <div className={classes.container}>
                        <ClientPreferredVendorSetup
                            tenants={tenants}
                            vendors={vendors}
                            services={services}
                            handleNewPreferredVendorEdit={handleNewPreferredVendorEdit}
                            newPreferredVendor={newPreferredVendor}
                            states={states}
                            handleNewPreferredVendorMultipleStateEdit={handleNewPreferredVendorMultipleStateEdit}
                            preferredVendors={preferredVendors}
                            handleCreateNewPreferredVendor={handleCreateNewPreferredVendor}
                            tenantId={profile?.tenantId}
                            handleChangeClientPreferredVendorStatus={handleChangeClientPreferredVendorStatus}
                            handleStartEditPreferredVendor={handleStartEditPreferredVendor}
                            handleConfirmEditPreferredVendor={handleConfirmEditPreferredVendor}
                            handlConfirmUpdatePreferredVendor={handlConfirmUpdatePreferredVendor}
                            handleSetValidationFunction={handleSetValidationFunction}
                            handleClearNewPreferredVendor={handleClearNewPreferredVendor}
                            handleGetClientPreferredVendors={handleGetClientPreferredVendors}
                        />
                    </div>
                </AccessControl>
            </Route>
        </>
    );
};
