import React, { FunctionComponent, useEffect } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@material-ui/core';
import TextfieldMask from '../../../generic-components/texfield-mask/TextfieldMask.Component';
import styles from './ReferralEmployer.Styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StateEntity from '../../../../entities/State.Entity';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';
import { useValidationDialog } from '../../../../support/custom-hooks/useValidationDialog';
import { ClearSelect } from '../../../generic-components/clear-select/ClearSelect.Component';

interface IReferralEmployerProps {
    referralId: number;
    states: Array<StateEntity>;
    referralEmployer: ReferralContactEntity;    
    handleEmployerEdit: any;    
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean) => void;    
    handleSetIsValidEmployerFunction: (currentFormIsValidEmployerFunction: () => boolean, referralContactType: string) => void;
    serviceId: number;
    handleUpdateEmployer: (currentPageValidationFunction: () => boolean) => void;    
    openCloseStatus: Object;
    handleOpenCloseStatus: (boxName: string, isOpened: boolean) => void;
}

interface IErrors {
    name?: string;
    addressLine1?: string;
    addressLine2?: string;
    zipCode?: string;
    cityName?: string;
    state?: string;
    email?: string;
    primaryPhoneNumber?: string;
    fax?: string;
    attorneyFax?: string;
}

export const ReferralEmployer: FunctionComponent<IReferralEmployerProps> = (props: IReferralEmployerProps) => {
    const classes = styles();
    const abortController = new AbortController();

    const [referralEmployerState, setReferralEmployerState] = React.useState(props.referralEmployer);
    
    const [errors, setErrors] = React.useState<IErrors>(null);
    const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState<boolean>(false);    
    const { Dialog, checkForErrors } = useValidationDialog('Validation', 'Some required fields are missing.', 'Ok');
    //functional component states

    //componentDidMount
    useEffect(() => {
        if ( !props.openCloseStatus.hasOwnProperty("Employer_box" ) )  props.handleOpenCloseStatus("Employer_box", true);
        if ( !props.openCloseStatus.hasOwnProperty("EmployerAdjuster_box" ) ) props.handleOpenCloseStatus("EmployerAdjuster_box", true);
    },[]);
    
    useEffect(() => {
        if (props.referralEmployer?.primaryPhoneNumber?.length == 0) {
            setReferralEmployerState({ ...props.referralEmployer, primaryPhoneNumber: null });
        } else {
            setReferralEmployerState(props.referralEmployer);
        }

        props.handleSetIsValidEmployerFunction(isValidEmployer, "Employer");
        return function cleanup() {
            abortController.abort();
        };
    }, [props]);

    const handleOpenCloseStatus = ( event, boxName ) => {
        event.persist();
        props.handleOpenCloseStatus( boxName, !(props.openCloseStatus?.[boxName]??true) );
    };
    
    const handleTextFieldChangeEmployer = (event) => {
        event.persist();
        let newEmployer: ReferralContactEntity = referralEmployerState;
        newEmployer[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;
        setReferralEmployerState(newEmployer);
        props.handleEmployerEdit(newEmployer);
        if (hasBeenSubmitted) validate(true);
    };

    const validate = (isChange?: boolean) => {
        let errors: IErrors = {};
        errors = getErrorsListEmployer()
        setErrors(errors);
        return checkForErrors(errors, isChange);
    };

    const isValidEmployer = () => { //returns true/false to know if the form has passed the validation OK (true) or not (false)
        let errors: IErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsListEmployer();
        setErrors(errors);
        isValidResult = (Object.keys(errors).length == 0);
        return isValidResult;
    }

    const getErrorsListEmployer = () => {//returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: IErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }

        if ([
            ServicesEnum.PT,
            ServicesEnum.UR,
            ServicesEnum.TT,
            ServicesEnum.DME]
        .includes( props.serviceId ))
        {                
            if (!props.referralEmployer?.name) errors.name = 'Required';
            if (!props.referralEmployer?.addressLine1) errors.addressLine1 = 'Required';
            if (!props.referralEmployer?.zipCode) errors.zipCode = 'Required';
            if (!props.referralEmployer?.cityName) errors.cityName = 'Required';
            if (!props.referralEmployer?.stateId) errors.state = 'Required';
        }

        return errors;
    }
        
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Accordion className={classes.paper} expanded={props.openCloseStatus["Employer_box"]??true} onClick={(event) => handleOpenCloseStatus(event, "Employer_box")}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                            aria-controls="panel1a-content"
                            id="referralEmployerFormHeader-accordion"
                            className={classes.paperHeader}
                        >
                            <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                Employer
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {referralEmployerState ? (
                                <Grid container spacing={3} className={classes.gridContainer}>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Employer Name"
                                            id="name"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployer(event)}
                                            value={referralEmployerState?.name}                                            
                                            error={!!errors?.name}
                                            helperText={errors?.name}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Address Line 1"
                                            id="addressLine1"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployer(event)}
                                            value={referralEmployerState?.addressLine1}                                            
                                            error={!!errors?.addressLine1}
                                            helperText={errors?.addressLine1}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask
                                            mask="(999)-9999999"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployer(event)}
                                            value={referralEmployerState?.primaryPhoneNumber}
                                            label="Primary Phone"
                                            id="primaryPhoneNumber"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Ext."
                                            id="primaryPhoneExtension"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployer(event)}
                                            value={referralEmployerState?.primaryPhoneExtension}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Contact First Name"
                                            id="firstName"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployer(event)}
                                            value={referralEmployerState?.firstName}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Address Line 2"
                                            id="addressLine2"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployer(event)}
                                            value={referralEmployerState?.addressLine2}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask
                                            mask="(999)-9999999"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployer(event)}
                                            value={referralEmployerState?.secondaryPhoneNumber}
                                            label="Secondary Phone"
                                            id="secondaryPhoneNumber"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Ext."
                                            id="secondaryPhoneExtension"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployer(event)}
                                            value={referralEmployerState?.secondaryPhoneExtension}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Contact Last Name"
                                            id="lastName"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployer(event)}
                                            value={referralEmployerState?.lastName}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Group #"
                                            id="groupNumber"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployer(event)}
                                            value={referralEmployerState?.groupNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="City"
                                            id="cityName"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployer(event)}
                                            value={referralEmployerState?.cityName}
                                            required={
                                                props.serviceId === ServicesEnum.PT ||
                                                props.serviceId === ServicesEnum.DME ||
                                                props.serviceId === ServicesEnum.UR ||
                                                props.serviceId === ServicesEnum.TT
                                            }
                                            error={!!errors?.cityName}
                                            helperText={errors?.cityName}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask
                                            mask="(999)-9999999"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployer(event)}
                                            value={referralEmployerState?.fax}
                                            label="Fax"
                                            id="fax"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Location ID"
                                            id="Location"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployer(event)}
                                            value={referralEmployerState?.location}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl className={classes.selectInput}>
                                            <InputLabel
                                                id="employer-state-id-label"
                                            >
                                                State
                                            </InputLabel>
                                            <Select
                                                labelId="employer-state-id-label"
                                                id="stateId"
                                                name="stateId"
                                                key={referralEmployerState?.stateId}
                                                value={referralEmployerState?.stateId}
                                                disabled={true}
                                                onChange={(event) => handleTextFieldChangeEmployer(event)}                                                
                                                error={!!errors?.state}
                                            >
                                                {props.states.map((state, index) => {
                                                    return (
                                                        <MenuItem key={state.stateId} value={state.stateId}>
                                                            {state.stateName}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Email"
                                            id="email"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployer(event)}
                                            value={referralEmployerState?.email}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        {/* Empty space */}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask
                                            mask="99-9999999"
                                            label="FEIN"
                                            id="fein"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeEmployer(event)}
                                            value={referralEmployerState?.fein}
                                        />
                                    </Grid>
                                    {props.serviceId === ServicesEnum.Diagnostic ? (
                                        <Grid
                                            container
                                            xs={3}
                                            spacing={1}
                                            style={{ marginTop: '9px', marginRight: '4px' }}
                                        >
                                            <Grid item xs={3} style={{ marginLeft: '15px', marginRight: '-8px' }}>
                                                <TextField
                                                    label="Zip Code"
                                                    id="ZipCode"
                                                    disabled={true}
                                                    onChange={(event) => handleTextFieldChangeEmployer(event)}
                                                    value={referralEmployerState?.zipCode}
                                                    style={{ width: '110px' }}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                        marginTop: '17px',
                                                        marginLeft: '15px',
                                                        marginRight: '-8%',
                                                    }}
                                                >
                                                    _
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3} style={{ marginRight: '15px' }}>
                                                <TextField
                                                    disabled={true}
                                                    label="Zip Code Ext."
                                                    id="zipCodeExtension"
                                                    onChange={(event) => handleTextFieldChangeEmployer(event)}
                                                    value={referralEmployerState.zipCodeExtension}
                                                    style={{ width: '100px' }}
                                                    inputProps={{ maxLength: 4 }}
                                                />
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={9} container spacing={3}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Zip Code"
                                                    id="zipCode"
                                                    disabled={true}
                                                    onChange={(event) => handleTextFieldChangeEmployer(event)}
                                                    value={referralEmployerState?.zipCode}
                                                    
                                                    error={!!errors?.zipCode}
                                                    helperText={errors?.zipCode}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            ) : (
                                <EmptyDataLabel></EmptyDataLabel>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            {Dialog}
        </>
    );
};
