import ClaimDataModel from '../data-models/Claim.DataModel';
import ClaimTableDataModel from '../data-models/ClaimTable.DataModel';
import ReferralDocumentEntity from '../entities/ReferralDocument.Entity';
import { apiGet } from '../support/helpers/ApiConnection.Helper';

export function getClaims(abortSignal: AbortSignal): Promise<Array<ClaimDataModel>> {
    return apiGet<Array<ClaimDataModel>>('/claims',abortSignal);
}

export function getClaim(claimId: number, abortSignal: AbortSignal): Promise<ClaimDataModel> {
    return apiGet<ClaimDataModel>('/claims/'+claimId,abortSignal);
}

export function getClaimDocuments(claimId: number, documentTypeIds: Array<string>, initialDate: Date, endDate: Date, referralId: number, abortSignal: AbortSignal): Promise<Array<ReferralDocumentEntity>> {    
    let querystring = '';
    if (documentTypeIds && documentTypeIds.length > 0){
        querystring += 'DocumentTypeNumbers=';
        documentTypeIds.forEach(documentTypeNumber => querystring += documentTypeNumber + ',');
        querystring = querystring.substr(0, querystring.length-1);
        if (!!initialDate){
            querystring += '&&DateInitial=' + initialDate;
        }
        if (!!endDate){
            querystring += '&&DateEnd=' + endDate;
        }
        if (!!referralId){
            querystring += '&&ReferralId=' + referralId;
        }
    }
    return apiGet<Array<ReferralDocumentEntity>>('/claims/'+claimId+"/documents",abortSignal,querystring);
}

export function getClaimTable(tenantId: number, abortSignal: AbortSignal): Promise<Array<ClaimTableDataModel>> {
    return apiGet<Array<ClaimTableDataModel>>('/claims/table/'+tenantId,abortSignal);
}


