import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import SpecificBodyPartEntity from '../../../../../entities/SpecificBodyPart.Entity';
import { PtHeaderSummary } from './pt-header-summary/PtHeaderSummary.Component';
import { MsReferringPhysician } from './ms-referring-physician/MsReferringPhysician.Component';
import InjuryTypeEntity from '../../../../../entities/pt/InjuryType.Entity';
import BodySideEntity from '../../../../../entities/BodySide.Entity';
import StateEntity from '../../../../../entities/State.Entity';
import MSCoverLetterEntity from "../../../../../entities/pt/PTCoverLetter.Entity";
import { MSCoverLetterDetails } from "./ms-cover-letter/MsCoverLetter.Component";
import MedicalServiceDataModel from "../../../../../data-models/MedicalService.DataModel";
import ServiceTypePTEntity from '../../../../../entities/pt/ServiceTypePT.Entity';
import LanguageEntity from "../../../../../entities/Language.Entity";
import { MsAdditionalInformation } from './ms-additional-information/MsAdditionalInformation.Component';

type MsReferralRequestProps = {
    injuryTypes: Array<InjuryTypeEntity>;
    bodySides: Array<BodySideEntity>;
    medicalService: MedicalServiceDataModel;
    states: Array<StateEntity>;
    referralId: number;
    specificBodyParts: Array<SpecificBodyPartEntity>;
    serviceId: number;
    serviceType: ServiceTypePTEntity;
    languages : Array<LanguageEntity>;
}

export const MsReferralRequest: FunctionComponent<MsReferralRequestProps> = (props: MsReferralRequestProps) => {
    return (
        <form noValidate autoComplete="off">
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <PtHeaderSummary languages={props.languages} specificBodyParts={props.specificBodyParts} injuryTypes={props.injuryTypes} bodySides={props.bodySides} serviceType={props.serviceType} referralId={props.referralId} serviceId={props.serviceId}/>
                </Grid>
                <Grid item xs={12}>
                    <MsAdditionalInformation medicalService={props.medicalService}/>
                </Grid>
                <Grid item xs={12}>
                    <MsReferringPhysician medicalService={props.medicalService} states={props.states}/>
                </Grid>
            </Grid>
        </form>
    );
}