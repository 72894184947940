import { AuthError, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useAccount } from "@azure/msal-react";
import React, { FunctionComponent } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { scopes } from "../../../b2cConfiguration";
import getProfile from "../../../services/Users.Service";
import { modifyClientName, modifyUserRoles, modifyUserSecurityRules, modifyUserName } from "../../../store/actions/usersActions";


export const AuthenticationVerifier: FunctionComponent<any> = ({ accounts, instance, children }) => {
    const account = useAccount(accounts[0]);
    const abortController = new AbortController();
    const dispatch = useDispatch();
    const [isTokenSavedState, setIsTokenSavedState] = React.useState(false);

    const onModifyUserRoles = (roles: Array<number>) => {
        dispatch(modifyUserRoles(roles));
    }

    const onModifyClientName = (clientName: string) => {
        dispatch(modifyClientName(clientName));
    }

    const onModifyUserSecurityRules = (securityRules: string[]) => {
        dispatch(modifyUserSecurityRules(securityRules));
    }

    const onModifyUserName = (userName: string) => {
        dispatch(modifyUserName(userName));
    }

    useEffect(() => {
        if (account) {
            instance.acquireTokenSilent({
                scopes: scopes,
                account: account
            }).then((response) => {
                if (response) {
                    window.sessionStorage.setItem("Authorization", `Bearer ${response.accessToken}`);
                    window.sessionStorage.setItem("X-Tenant-Authorization", `${response.idToken}`);
                    getProfile(abortController.signal).then(userProfile => {
                        onModifyUserRoles(userProfile.userRoles);
                        onModifyClientName(userProfile.clientName);
                        onModifyUserSecurityRules(userProfile.securityRules);
                        onModifyUserName(userProfile.firstName + ' ' + userProfile.lastName);
                    }).catch(error => {

                    });
                    setIsTokenSavedState(true);
                }
            }).catch(async (error) => {
                if (error instanceof AuthError) {
                    window.sessionStorage.setItem("Authorization", '');
                    window.sessionStorage.setItem("X-Tenant-Authorization", '');
                    instance.logout();
                    window.location.reload();
                }
                else if (error instanceof InteractionRequiredAuthError) {
                    return instance.acquireTokenPopup({
                        scopes: scopes,
                        account: account
                    });
                }
            }).catch(error => {

            });
        }
        return function cleanup() {
            abortController.abort();
        }
    }, [account, instance]);

    if (isTokenSavedState){
        return children;   
    }
    return '';
}