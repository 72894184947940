import React, { FunctionComponent, useEffect, useState } from 'react';
import { Link, Redirect, Route, useRouteMatch } from 'react-router-dom';
import styles from './AdminTab.Styles';
import AdminReferralVendorDocumentsTableDataModel from '../../../data-models/AdminReferralVendorDocumentsTable.DataModel';
import BillingReportCountDataModel from '../../../data-models/BillingReportCount.DataModel';
import BillingReportSummaryDataModel from '../../../data-models/BillingReportSummary.DataModel';
import EditedVendorByServiceDataModel from '../../../data-models/EditedVendorByService.DataModel';
import NewVendorByServiceDataModel from '../../../data-models/NewVendorByService.DataModel';
import ReferralErrorListDataModel from '../../../data-models/ReferralErrorList.DataModel';
import ReportDataModel from '../../../data-models/Report.DataModel';
import ServiceDataModel from '../../../data-models/Service.DataModel';
import UserAdminTableDataModel from '../../../data-models/UserAdminTableDataModel';
import VendorAdminTableDataModel from '../../../data-models/VendorAdminTable.DataModel';
import VendorServiceSetUpTableDataModel from '../../../data-models/VendorServiceSetUpTable.DataModel';
import ProfileEntity from '../../../entities/Profile.Entity';
import ReferralStatusEntity from '../../../entities/ReferralStatus.Entity';
import StateEntity from '../../../entities/State.Entity';
import TenantEntity from '../../../entities/Tenant.Entity';
import UserEntity from '../../../entities/User.Entity';
import VendorEntity from '../../../entities/Vendor.Entity';
import getProfiles from '../../../services/Profiles.Service';
import {
    getFailedReferrals,
    updateReferralStatus,
    getReferralsVendorDocumentsTable,
} from '../../../services/Referrals.Service';
import getReferralStatuses from '../../../services/ReferralStatuses.Service';
import { getBillingReport, getReport } from '../../../services/Report.Service';
import { getServices } from '../../../services/Services.Service';
import getStates from '../../../services/States.Service';
import { getTenants } from '../../../services/Tenants.Services';
import getProfile, { createUser, getUsers, editUser, deleteUser } from '../../../services/Users.Service';
import {
    getVendorAdminTable,
    deleteServiceByVendor,
    getTenantPartnerVendor,
    createVendorPartnerTenant,
    editVendorPartnerTenant,
} from '../../../services/Vendors.Service';
import { ReferralStatusTypesEnum } from '../../../support/enums/ReferralStatusTypesEnum';
import { Loader } from '../../generic-components/loader/Loader.Component';
import { adminNavigationCards, ISubRoute } from '../admin-navigation/NavigationCards';
import { BillingReportFilters } from '../billing-report/BillingReports.Component';
import { ReportFilters } from '../reports/Reports.Component';
import { VendorDocumentsFilter } from '../vendor-documents/VendorDocuments.Component';
import { ErrorManagementMenu } from '../error-management-menu/ErrorManagementMenu.Component';
import { ServiceSetUp } from '../service-set-up/ServiceSetUp.Component';
import { Grid, CardContent, Typography } from '@material-ui/core';
import { AccessControlController } from '../access-control-controller/AccessControlController.Component';
import AccessControl, { checkPermissions } from '../../generic-components/access-control/AccessControl.Component';
import { useSelector } from 'react-redux';
import { UsersState } from '../../../store/reducers/usersReducer';
import { BreadCrumb } from '../../../support/custom-hooks/useBreadCrum';
import {
    accessControlRules,
    adminRules,
    errorManagementRules,
    serviceSetUpRules,
} from '../../../support/constants/SecurityRules.Constants';

interface AdminTabState {
    loading: boolean;
    currentRoute: string;
    vendors: Array<VendorAdminTableDataModel>;
    states: Array<StateEntity>;
    newVendor: VendorEntity;
    users: Array<UserAdminTableDataModel>;
    newOrEditedUser: UserEntity;
    profiles: Array<ProfileEntity>;
    vendorServiceSetUpTable: Array<VendorServiceSetUpTableDataModel>;
    newServiceByVendor: NewVendorByServiceDataModel;
    reports: Array<ReportDataModel>;
    tenants: Array<TenantEntity>;
    services: Array<ServiceDataModel>;
    userProfile: UserEntity;
    referrals: Array<ReferralErrorListDataModel>;
    referralStatus: Array<ReferralStatusEntity>;
    initialTabIndex: number;
    referralsVendorDocumentsTable: Array<AdminReferralVendorDocumentsTableDataModel>;
    isReferralsVendorDocumentsTableLoading: boolean;

    //billing report
    billingReportResults: Array<BillingReportSummaryDataModel>;
    totalBillingReportCount: BillingReportCountDataModel;
}

type PathParamsType = {
    subRoutes: ISubRoute[];
    initialTabIndex: number;
    profileId?: number;
};

export const NewAdminTab: FunctionComponent<PathParamsType> = (props) => {
    const [state, setState] = useState<AdminTabState>({
        loading: false,
        currentRoute: null,
        vendors: null,
        states: null,
        newVendor: null,
        users: null,
        newOrEditedUser: null,
        profiles: null,
        vendorServiceSetUpTable: null,
        newServiceByVendor: null,
        reports: null,
        tenants: null,
        services: null,
        userProfile: null,
        referrals: null,
        referralStatus: null,
        initialTabIndex: null,
        referralsVendorDocumentsTable: null,
        isReferralsVendorDocumentsTableLoading: null,
        billingReportResults: null,
        totalBillingReportCount: null,
    });

    const classes = styles();
    const abortController = new AbortController();
    const { path } = useRouteMatch();

    var validationFunction: () => boolean = null;

    const setValidationFunction = (currentPageValidationFunction: () => boolean) => {
        validationFunction = currentPageValidationFunction;
    };

    useEffect(() => {
        const getUserProfile =async () => {
            setState({
                ...state,
                loading: true,
            });
            try{
            const result =await Promise.all([
                getProfiles(abortController.signal),
                getUsers(abortController.signal),
                getVendorAdminTable(abortController.signal),
                getTenants(abortController.signal),
    
                getTenantPartnerVendor(abortController.signal),
                getStates(abortController.signal),
                getServices(abortController.signal),
                getProfile(abortController.signal),
            ]);
                    setState(() => {
                        return {
                            ...state,
                            currentRoute: 'admin',
                            profiles: result[0],
                            users: result[1],
                            vendors: result[2],
                            tenants: result[3],
    
                            vendorServiceSetUpTable: result[4],
                            states: result[5],
                            services: result[6],
                            userProfile: result[7],
                            loading: false,
                        };
                    });
            }catch(e) 
            {
                setState({ ...state, loading: false });
            }
        };
        
        getUserProfile();
    }, []);


    const handleCreateUser = () => {
        setState({ ...state, loading: true });
        createUser(state.newOrEditedUser, abortController.signal)
            .then(() => {
                const selectedCard = adminNavigationCards.find((c) => c.link === '/admin');
                getUsers(abortController.signal).then((users) => {
                    setState(() => {
                        return {
                            ...state,
                            states: state.states,
                            users,
                            currentRoute: 'admin',
                            newOrEditedUser: null,
                            newVendor: null,
                            loading: false,
                        };
                    });
                });
            })
            .catch(() => {
                setState({ ...state, loading: false });
            });
    };

    const handleUserEdit = (editedUser: UserEntity) => {
        setState({ ...state, newOrEditedUser: editedUser });
    };

    const handleTabIndex = (newTabIndex: number) => {
        setState({ ...state, initialTabIndex: newTabIndex });
    };

    const handleDeleteUser = (userId: number) => {
        setState({ ...state, loading: true });
        deleteUser(userId, abortController.signal)
            .then(() => {
                const selectedCard = adminNavigationCards.find((c) => c.link === '/admin');
                getUsers(abortController.signal).then((users) => {
                    setState(() => {
                        return {
                            ...state,
                            currentRoute: 'admin',
                            users,
                            loading: false,
                        };
                    });
                });
            })
            .catch(() => {
                setState({ ...state, loading: false });
            });
    };

    const handleMarkAsInactiveServiceByVendor = (rowIds: number[]) => {
        var dataModel = new VendorServiceSetUpTableDataModel();
        setState({ ...state, loading: true });
        deleteServiceByVendor(rowIds, abortController.signal)
            .then(() => {
                const selectedCard = adminNavigationCards.find((c) => c.link === '/admin');
                getTenantPartnerVendor(abortController.signal).then((vendorServiceSetUpTable) => {
                    setState(() => {
                        return {
                            ...state,
                            currentRoute: 'admin',
                            vendorServiceSetUpTable,
                            loading: false,
                        };
                    });
                });
            })
            .catch(() => {
                setState({ ...state, loading: false });
            });
    };

    //#region Billing Report
    const handleGetBillingReport = async (filters: BillingReportFilters) => {
        return await Promise.all([getBillingReport(filters, abortController.signal)]).then((res) => {
            setState({ ...state, billingReportResults: res[0] });
        });
    };
    //#endregion

    const handleGetReport = (filters: ReportFilters) => {
        getReport(filters, abortController.signal).then((reports) => {
            setState({ ...state, reports: reports });
        });
    };

    const handleGetServiceSetUp = async () => {
        return await Promise.all([
            getTenantPartnerVendor(abortController.signal),
            getStates(abortController.signal),
            getServices(abortController.signal),
        ]).then((res) => {
            setState(() => {
                return {
                    ...state,
                    vendorServiceSetUpTable: res[0],
                    states: res[1], //
                    services: res[2],
                    loading: false,
                };
            });
        });
    };

    const handleGetErrorManagement = async () => {
        return await Promise.all([
            getFailedReferrals(abortController.signal),
            getReferralStatuses(abortController.signal),
        ])
            .then((referralResults) => {
                setState({
                    ...state,
                    referrals: referralResults[0],
                    referralStatus: referralResults[1],
                    loading: false,
                });
            })
            .catch(() => {
                setState({ ...state, loading: false });
            });
    };

    const handleNewServiceByVendorEdit = (newServiceByVendorEdit: NewVendorByServiceDataModel) => {
        setState({
            ...state,
            newServiceByVendor: newServiceByVendorEdit,
        });
    };

    const handleCreateNewServiceByVendor = () => {
        if (!(!validationFunction || validationFunction())) return;

        setState({ ...state, loading: true });
        createVendorPartnerTenant(state.newServiceByVendor, abortController.signal).then(() => {
            getTenantPartnerVendor(abortController.signal)
                .then((vendorServiceSetUpTable) =>
                    setState({
                        ...state,
                        loading: false,
                        vendorServiceSetUpTable: vendorServiceSetUpTable,
                        newServiceByVendor: null,
                    }),
                )
                .catch(() => {
                    setState({ ...state, loading: false });
                });
        });
    };

    const handleStartEditServiceByVendor = (
        tenantId: number,
        vendorId: number,
        serviceId: number,
        states: number[],
        serviceTypeIdsExcluded: number[],
    ) => {
        setState({
            ...state,
            newServiceByVendor: {
                tenantId: tenantId,
                vendorId: vendorId,
                serviceId: serviceId,
                stateIds: states,
                serviceTypeIdsExcluded: serviceTypeIdsExcluded
            },
        });
    };

    const handleConfirmEditServiceByVendor = (
        newStateIds: number[],
        removedStateIds: number[],
        originalServiceId: number,
        serviceTypeIdsExcluded: number[],
    ) => {
        if (!(!validationFunction || validationFunction())) return;

        setState({ ...state, loading: true });

        const editedVendorPartnerTenant: EditedVendorByServiceDataModel = {
            tenantId: state.newServiceByVendor.tenantId,
            vendorId: state.newServiceByVendor.vendorId,
            serviceId: state.newServiceByVendor.serviceId,
            originalServiceId: originalServiceId,
            newStateIds,
            removedStateIds,
            serviceTypeIdsExcluded:serviceTypeIdsExcluded
        };

        editVendorPartnerTenant(editedVendorPartnerTenant, abortController.signal).then(() => {
            getTenantPartnerVendor(abortController.signal)
                .then((vendorServiceSetUpTable) =>
                    setState({
                        ...state,
                        loading: false,
                        vendorServiceSetUpTable: vendorServiceSetUpTable,
                        newServiceByVendor: null,
                    }),
                )
                .catch(() => {
                    setState({ ...state, loading: false });
                });
        });
    };

    const handleVendorByServiceMultipleStateEdit = (edited: Array<number>, states: StateEntity[]) => {
        let selectedStates = edited.map((x) => states.find((y) => x === y.stateId).stateId);
        const currentServiceByVendor = { ...state.newServiceByVendor };
        currentServiceByVendor.stateIds = selectedStates;
        setState({ ...state, newServiceByVendor: currentServiceByVendor });
    };

    const setReferralReadyForSubmission = (referralId: number) => {
        setState({ ...state, loading: true });

        updateReferralStatus(referralId, ReferralStatusTypesEnum.ReadyForSubmission, abortController.signal)
            .then((res) => {
                //remove referral from list
                let referrals = state.referrals.filter((referral) => referral.referralId !== referralId);
                setState({ ...state, referrals, loading: false });
            })
            .catch(() => {
                setState({ ...state, loading: false });
            });
    };

    const handleGetReferralsVendorDocumentsTable = (filters: VendorDocumentsFilter) => {
        setState({ ...state, isReferralsVendorDocumentsTableLoading: true });

        getReferralsVendorDocumentsTable(filters, abortController.signal)
            .then((res) => {
                setState({
                    ...state,
                    referralsVendorDocumentsTable: res,
                    isReferralsVendorDocumentsTableLoading: false,
                });
            })
            .catch(() => {
                setState({ ...state, isReferralsVendorDocumentsTableLoading: false });
            });
    };

    const [subRoutes, setSubroutes] = useState<ISubRoute[]>([]);
    const userDecurityRules = useSelector<UsersState, UsersState['securityRules']>((state) => state.securityRules);

    useEffect(() => {
        const newSubRoutes = [];
        for (let i = 0; i < props.subRoutes.length; i++) {
            if (checkPermissions(userDecurityRules, props.subRoutes[i].allowedPermissions, false)) {
                newSubRoutes.push(props.subRoutes[i]);
            }
        }

        setSubroutes(newSubRoutes);
    }, []);

    const serviceSetUpPermissions = [...adminRules.read, ...serviceSetUpRules.read];
    const accessControlControllerPermissions = [...adminRules.read, ...accessControlRules.read];
    const errorManagementMenuPermissions = [...adminRules.read, ...errorManagementRules.read];

    const adminBreadCrumb = {
        name: 'Admin',
        goBack: false,
        link: '/admin',
        path: path,
    };

    const serviceSetUpBreadCrumb = {
        name: 'Service Set Up',
        goBack: false,
        link: '/admin/service-set-up',
        path: path,
    };

    return state?.loading ? (
        <Loader isSmall />
    ) : (
        <>
            <Route path="/admin" exact>
                <BreadCrumb sections={[adminBreadCrumb]} />
                <div className={classes.container}>
                    <Grid container className="tc-full-height tc-p2">
                        <Grid item container className={classes.gridContainer}>
                            {subRoutes?.map((sr, idx) => {
                                return (
                                    <Grid
                                        key={`${sr.label}_grid`}
                                        item
                                        xs={12}
                                        sm={3}
                                        className={idx == 0 ? classes.tcAdminFirstCard : classes.tcAdminCard}
                                    >
                                        <Link to={sr.link} style={{ textDecoration: 'none' }}>
                                            <CardContent>
                                                <Typography color="textSecondary" gutterBottom>
                                                    {sr.icon}
                                                </Typography>
                                                <Typography variant="h5" component="h2" gutterBottom>
                                                    {sr.label}
                                                </Typography>
                                                <Typography variant="body2" component="p">
                                                    {sr.description}
                                                </Typography>
                                            </CardContent>
                                        </Link>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </div>
            </Route>
            <Route path="/admin/service-set-up" exact>
                <AccessControl
                    allowedPermissions={serviceSetUpPermissions}
                    sendBack={false}
                    renderNoAccess={<Redirect to="/not-found" />}
                >
                    <BreadCrumb sections={[adminBreadCrumb, serviceSetUpBreadCrumb]} />
                    <div className={classes.container}>
                        <ServiceSetUp
                            users={state.users}
                            newUser={state.newOrEditedUser}
                            profiles={state.profiles}
                            createUser={handleCreateUser}
                            handleUserEdit={handleUserEdit}
                            vendors={state.vendors}
                            vendorServiceSetUpTable={state.vendorServiceSetUpTable}
                            states={state.states}
                            newServiceByVendor={state.newServiceByVendor}
                            handleServiceByVendorEdit={handleNewServiceByVendorEdit}
                            createServiceByVendor={handleCreateNewServiceByVendor}
                            services={state.services}
                            tenants={state.tenants}
                            handleVendorByServiceMultipleStateEdit={handleVendorByServiceMultipleStateEdit}
                            handleMarkAsInactiveServiceByVendor={handleMarkAsInactiveServiceByVendor}
                            handleStartEditServiceByVendor={handleStartEditServiceByVendor}
                            handleSetValidationFunction={setValidationFunction}
                            handleConfirmEditServiceByVendor={handleConfirmEditServiceByVendor}
                            handleGetServiceSetUp={handleGetServiceSetUp}
                        />
                    </div>
                </AccessControl>
            </Route>
            <Route path="/admin/access-control">
                <AccessControl
                    allowedPermissions={accessControlControllerPermissions}
                    sendBack={false}
                    renderNoAccess={<Redirect to="/not-found" />}
                >
                    <div className={classes.container}>
                        <AccessControlController profileId={props.profileId} initialTabIndex={props.initialTabIndex} />
                    </div>
                </AccessControl>
            </Route>
            <Route path="/admin/error-management">
                <AccessControl
                    allowedPermissions={errorManagementMenuPermissions}
                    sendBack={false}
                    renderNoAccess={<Redirect to="/not-found" />}
                >
                    <div className={classes.container}>
                        <ErrorManagementMenu
                            referrals={state.referrals}
                            referralStatus={state.referralStatus}
                            setReferralReadyForSubmission={setReferralReadyForSubmission}
                            handleGetErrorManagement={handleGetErrorManagement}
                            handleTabIndex={handleTabIndex}
                            tenants={state.tenants}
                            vendors={state.vendors}
                            referralsVendorDocuments={state.referralsVendorDocumentsTable}
                            isReferralsVendorDocumentsTableLoading={state.isReferralsVendorDocumentsTableLoading}
                            handleGetReferralsVendorDocumentsTable={handleGetReferralsVendorDocumentsTable}
                        />
                    </div>
                </AccessControl>
            </Route>
        </>
    );
};
