import React from 'react';
import styles, { newTheme } from './TcAdmin.Styles';
import { ThemeProvider, withStyles, WithStyles } from '@material-ui/core';
import { AdminNavigation } from '../../components/tc-admin/admin-navigation/AdminNavigation.Component';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { Loader } from '../../components/generic-components/loader/Loader.Component';
import { TopNavigation } from '../../components/tc-admin/top-navigation/TopNavigation.Component';
import getProfile from '../../services/Users.Service';
import UserEntity from '../../entities/User.Entity';
import { IAdminNavigationCard } from '../../components/tc-admin/admin-navigation/NavigationCards';
import { UserRolesEnum } from '../../support/enums/UserRoles.Enum';
import { SideBarMenu } from '../side-bar-menu/SideBarMenu.Container';

type MainContainerState = {
    loading: boolean;
    currentRoute: string;
    selectedRouteInfo: {
        section: string;
        subRoutes: { label: string; link: string; allowedPermissions?: string[] }[];
        subRoutesRoles: UserRolesEnum[][];
        showTabs: boolean;
    };
    currentSubroutIndex: string;
    userProfile: UserEntity;
    initialTabIndex: number;
    profileId: number;
    clickAdminNavigationItem: boolean;
};

type PathParamsType = {
    id: string;
    claimId: string;
};

type MainContainerProps = WithStyles<typeof styles> & RouteComponentProps<PathParamsType>;

class MainContainerComponent extends React.Component<MainContainerProps, MainContainerState> {
    constructor(props: MainContainerProps) {
        super(props);

        this.state = {
            loading: false,
            currentRoute: null,
            selectedRouteInfo: null,
            currentSubroutIndex: null,
            userProfile: null,
            initialTabIndex: null,
            profileId: null,
            clickAdminNavigationItem: false,
        };
    }
    abortController = new AbortController();

    validationFunction: () => boolean = null;

    setValidationFunction(currentPageValidationFunction: () => boolean) {
        this.validationFunction = currentPageValidationFunction;
    }

    async componentDidMount() {
        this.getUserProfile();
        this.setState({
            loading: true,
        });
    }

    getUserProfile = () => {
        getProfile(this.abortController.signal)
            .then((profile) => {
                this.setState({
                    ...this.state,
                    userProfile: profile,
                    loading: false,
                });
            })
            .catch(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    handleTabChange = (newTab: string) => {
        this.setState({
            currentSubroutIndex: newTab,
        });
    };

    handleTabIndex = (newTabIndex: number) => {
        this.setState({
            initialTabIndex: newTabIndex,
        });
    };

    handleChangeRoute = (route: string, selectedCard: IAdminNavigationCard) => {
        if (!route || !selectedCard) return;

        if (selectedCard.getProfile) {
            const pathArr = this.props.location.pathname.split('/');
            const id = parseInt(pathArr[pathArr.length - 1]);
            this.handleNavigateToProfile(id, selectedCard.title);
        }

        this.getUserProfile();

        this.setState(() => {
            return {
                currentRoute: route,
                selectedRouteInfo: {
                    section: selectedCard.section,
                    subRoutes: selectedCard.subRoutes,
                    subRoutesRoles: selectedCard.subRoutesRoles ?? null,
                    showTabs: selectedCard.showTabs,
                },
                loading: false,
            };
        });
    };

    handleNavigateToProfile = (id: number, section: string) => {
        if (isNaN(id)) {
            return;
        }

        this.setState({
            loading: true,
        });

        this.setState({
            profileId: id,
            loading: false,
        });
    };

    handleId = (id: number) => {
        this.setState({
            profileId: id,
        });
    };

    resetBreadcrumb = () => {
        this.setState({
            clickAdminNavigationItem: this.state.clickAdminNavigationItem ? false : true,
        });
    };

    public render() {
        const { classes } = this.props;

        return (
            <ThemeProvider theme={newTheme}>
                <>
                    <div className={classes.mainContainer}>
                        <AdminNavigation
                            handleChangeRoute={this.handleChangeRoute}
                            userProfile={this.state.userProfile}
                            resetBreadcrumb={this.resetBreadcrumb}
                        />
                        {this.state.loading ? (
                            <Loader />
                        ) : (
                            <>
                                <div
                                    className={
                                        this.state.selectedRouteInfo?.subRoutes?.length > 0 &&
                                        this.state.selectedRouteInfo.showTabs
                                            ? classes.selectedComponentContainerWithTabs
                                            : classes.selectedComponentContainer
                                    }
                                >
                                    {this.state.currentRoute && this.state.selectedRouteInfo !== null && (
                                        <>
                                            <Route path={`/${this.state.currentRoute}`} key={this.state.currentRoute}>
                                                {this.state.selectedRouteInfo.showTabs && (
                                                    <TopNavigation
                                                        {...this.state.selectedRouteInfo}
                                                        tabIndex={this.state.currentSubroutIndex}
                                                        handleTabChange={this.handleTabChange}
                                                        initialTabIndex={this.state.initialTabIndex}
                                                        profileId={this.state.profileId}
                                                    />
                                                )}
                                                <Switch>
                                                    <SideBarMenu
                                                        currentTab={this.state.currentSubroutIndex}
                                                        handleId={this.handleId}
                                                        selectedRouteInfo={this.state.selectedRouteInfo}
                                                        handleTabChange={this.handleTabChange}
                                                        initialTabIndex={this.state.initialTabIndex}
                                                        profileId={this.state.profileId}
                                                        clickAdminNavigationItem={this.state.clickAdminNavigationItem}
                                                    />
                                                </Switch>
                                            </Route>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </>
            </ThemeProvider>
        );
    }
}

export const MainContainer = withRouter(withStyles(styles)(MainContainerComponent));
