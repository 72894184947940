import { Button, Checkbox, FormControl, FormHelperText, Grid, Input, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";

import React, { FunctionComponent } from "react";
import styles from './AddServiceByVendor.Styles';
import VendorAdminTableDataModel from "../../../data-models/VendorAdminTable.DataModel";
import TenantEntity from "../../../entities/Tenant.Entity";
import StateEntity from "../../../entities/State.Entity";
import ServiceDataModel from "../../../data-models/Service.DataModel";
import NewVendorByServiceDataModel from "../../../data-models/NewVendorByService.DataModel";
import useArrayChanges from "../../../support/custom-hooks/useArrayChanges";
import ServiceTypeEntity from "../../../entities/ServiceType.Entity";
import { getAllServiceTypesByVendor } from "../../../services/ServiceType.Service";

interface AddServiceByVendorProps{
    newServiceByVendor: NewVendorByServiceDataModel,
    handleServiceByVendorEdit: (newServiceByVendor: NewVendorByServiceDataModel) => void;
    createServiceByVendor : () => void;
    services: Array<ServiceDataModel>;
    cancel: () => void;
    vendors: Array<VendorAdminTableDataModel>;
    tenants: Array<TenantEntity>;
    states: Array<StateEntity>;
    handleVendorByServiceMultipleStateEdit: (edited: Array<number>, states: StateEntity[]) => void;
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean ) => void;
    editMode?: boolean;
    handleConfirmEditServiceByVendor: (newStateIds: number[], removedStateIds: number[], originalServiceId: number,serviceTypeIdsExcluded:Array<number>) => void;
}

interface IErrors{
    tenantId?: string,
    serviceId?: string,
    serviceTypeId?: string,
    vendorId?: string,
    stateId?: string,
}

export const AddServiceByVendor: FunctionComponent<AddServiceByVendorProps> = (props) => {
    const classes = styles();
    const abortController = new AbortController();
    const [serviceByVendorState, setServiceByVendorState] = React.useState(props.newServiceByVendor);
    const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState<boolean>(false);
    const [errors, setErrors] = React.useState<IErrors>(null);
    const [states, setStates] = React.useState<StateEntity[]>([]);
    const [serviceTypeIds, setServiceTypeIds] = React.useState<ServiceTypeEntity[]>([]);
    const { updateItems} = useArrayChanges(props.newServiceByVendor?.stateIds);
    const [originalServiceByVendor, setOriginalServiceByVendor] = React.useState<NewVendorByServiceDataModel>(null);

    React.useEffect(() => {
        let newServiceByVendor = props.newServiceByVendor;

        if(states.length === 0){
            const newStates = [...props.states];
            newStates.unshift({ stateId: 0, stateCode:'All', stateName:'All'})
            setStates(newStates);
        }

        if(!newServiceByVendor){
            newServiceByVendor = { serviceId: null, stateIds: [0] } as NewVendorByServiceDataModel;
            props.handleServiceByVendorEdit(newServiceByVendor);
        }

        setServiceByVendorState(newServiceByVendor);
        props.handleSetValidationFunction(validate);

        return function cleanup() {
            abortController.abort();
        }
    }, [props]);

    React.useEffect(()=>{
        checkSelected();
    },[]);

    React.useEffect(()=>{
        checkSelected();
        
    },[serviceByVendorState?.serviceId, serviceByVendorState?.vendorId]);

    const checkSelected = () => {
        if(serviceByVendorState?.vendorId >0 && serviceByVendorState?.serviceId >0 )
        {
            Promise.all([
                getAllServiceTypesByVendor(serviceByVendorState.serviceId,serviceByVendorState.vendorId, abortController.signal)
            ]).then((res) => {
                setServiceTypeIds(res[0])
                });
        }
    }

    React.useEffect(() => {
        if(props.editMode)
        {
            setOriginalServiceByVendor({...props.newServiceByVendor});
        }
    }, [props.editMode]);

    const handleTextFieldChange = (event: 
        React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        |
        React.ChangeEvent<{name?: string; value: unknown; id?:string;}>
    ) => {
        event.persist();
        let newServiceByVendor : NewVendorByServiceDataModel = serviceByVendorState;
        newServiceByVendor[event.target.id ? event.target.id : event.target.name] = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value;
        setServiceByVendorState(newServiceByVendor);
        props.handleServiceByVendorEdit(newServiceByVendor);
        if(hasBeenSubmitted)
            validate();
    }

    const handleServiceTypeFilterChange = (event) => {
            event.persist();

        let newServiceTypeFilter = [...serviceTypeIds]; // Crea una copia del array
        const item = newServiceTypeFilter.find(x => x.serviceTypeId === parseInt(event.target.id)); 
        if (event.target.type === 'checkbox') {
            if (event.target.checked) {
                item.vendorEnabled = true;
            } else {
                item.vendorEnabled = false;
            }

            setServiceTypeIds(newServiceTypeFilter);
            if(!originalServiceByVendor?.serviceId)
                {
                    let newServiceByVendor : NewVendorByServiceDataModel = serviceByVendorState;
                    const unselectedItems = serviceTypeIds.filter(item => !item.vendorEnabled)
                                .map(item => item.serviceTypeId);

                    newServiceByVendor.serviceTypeIdsExcluded=unselectedItems;
                    setServiceByVendorState(newServiceByVendor);
                    props.handleServiceByVendorEdit(newServiceByVendor);
                }
        }
    };

    const handleStateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        event.persist();
        const currentState = {...serviceByVendorState};
        currentState.stateIds = event.target.value as number[];
        setServiceByVendorState(currentState);
        props.handleVendorByServiceMultipleStateEdit(event.target.value as number[], states);
    };

    const handleCancel = () => {
        let newServiceByVendor = { serviceId: null, stateIds: [] } as NewVendorByServiceDataModel;
        props.handleServiceByVendorEdit(newServiceByVendor);
        props.cancel();
    }

    const validate = () => {
        let errors: IErrors = {};
        
        if(!hasBeenSubmitted){
            setHasBeenSubmitted(true);
        }

        errors.tenantId = props.newServiceByVendor && props.newServiceByVendor.tenantId ? "" : "Required";
        errors.serviceId = props.newServiceByVendor && props.newServiceByVendor.serviceId ? "" : "Required";
        errors.vendorId = props.newServiceByVendor && props.newServiceByVendor.vendorId ? "" : "Required";

        if(props.newServiceByVendor && props.newServiceByVendor.stateIds.length > 0){

            if(props.newServiceByVendor.stateIds.includes(0) && props.newServiceByVendor.stateIds.length > 1)
            {
                errors.stateId = "You can't select 'All' and another option."
            }
        } else {
            errors.stateId = 'Required';
        }

        setErrors(errors);

        return Object.values(errors).every(x => x == "");
    }

    const handleConfirmEdit = async () => {
        const {addedItems, removedItems} = updateItems(props.newServiceByVendor.stateIds);
        const unselectedItems = serviceTypeIds.filter(item => !item.vendorEnabled)
                              .map(item => item.serviceTypeId);
                              
        if( props.newServiceByVendor.serviceId !== originalServiceByVendor.serviceId )
        {
            props.handleConfirmEditServiceByVendor(originalServiceByVendor.stateIds ,originalServiceByVendor.stateIds, originalServiceByVendor.serviceId, unselectedItems);
        }
        else 
        {
            props.handleConfirmEditServiceByVendor(addedItems, removedItems, props.newServiceByVendor.serviceId, unselectedItems);
        }
    }

    if(!serviceByVendorState) return null;

    return <form noValidate autoComplete="off" className={classes.formContainer} >
        
        <Typography variant="subtitle2" className={classes.paperTitle}>Basic information...</Typography>
        <Grid container spacing={3} className={classes.gridContainer} >
            <Grid item xs={2}>
                <FormControl className={classes.selectInput}>
                    <InputLabel id="demo-simple-select-label">Tenant</InputLabel>
                    <Select
                        labelId="demo-simple-select-label0"
                        id="tenantId"
                        name="tenantId"
                        value={props.newServiceByVendor?.tenantId}
                        onChange={(event) => handleTextFieldChange(event)}
                        required
                        error={!!errors?.tenantId}
                        disabled={props.editMode}
                    >
                        {
                            props.tenants?.map((tenant, index) => {
                                return (
                                    <MenuItem key={tenant.tenantId} value={tenant.tenantId}>{tenant.tenantName}</MenuItem>
                                )
                            })
                        }
                    </Select>
                    <FormHelperText style={{color:'red'}}>
                        {errors?.tenantId}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl className={classes.selectInput}>
                    <InputLabel id="demo-simple-select-label">Vendor</InputLabel>
                    <Select
                        labelId="demo-simple-select-label1"
                        id="vendorId"
                        name="vendorId"
                        value={props.newServiceByVendor?.vendorId}
                        onChange={(event) => handleTextFieldChange(event)}
                        required
                        error={!!errors?.vendorId}
                        disabled={props.editMode}
                    >
                        {
                            props.vendors?.map((vendor, index) => {
                                return (
                                    <MenuItem key={vendor.vendorId} value={vendor.vendorId}>{vendor.vendorName}</MenuItem>
                                )
                            })
                        }
                    </Select>
                    <FormHelperText style={{color:'red'}}>
                        {errors?.vendorId}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <FormControl className={classes.selectInput}>
                    <InputLabel id="demo-simple-select-label">Service</InputLabel>
                    <Select
                        labelId="demo-simple-select-label2"
                        id="serviceId"
                        name="serviceId"
                        value={serviceByVendorState.serviceId}
                        onChange={(event) => handleTextFieldChange(event)}
                        required
                        error={!!errors?.serviceId}
                    >
                        {
                            props.services?.map((service, index) => {
                                return (
                                    <MenuItem key={service.service.serviceId} value={service.service.serviceId}>{service.service.serviceName}</MenuItem>
                                )
                            })
                        }
                    </Select>
                    <FormHelperText style={{color:'red'}}>
                        {errors?.serviceId}
                    </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
            <Grid item xs={12} className={classes.marginBottom20}>
                                                <Typography variant="caption" className={classes.serviceTypeSubtitle}>Enabled Service Type</Typography>
            </Grid>
            {
                serviceTypeIds.map(documentType =>
                    <Grid item xs={12} container spacing={2} key={'grLevel_01_' + documentType.serviceTypeId}>
                        <Grid item xs={12} key={'grLevel_02_' + documentType.serviceTypeId}>
                            <FormControl 
                                key={'fc_' + documentType.serviceTypeId}                                                            
                                className={classes.checkboxFormControl}
                            >
                                <InputLabel
                                    key={'il_' + documentType.serviceTypeId}
                                    shrink={false}
                                    disableAnimation 
                                    className={classes.checkboxLabel}
                                    htmlFor={documentType.serviceTypeId.toString()}
                                >
                                    {documentType.serviceTypeName}
                                </InputLabel>
                                <Checkbox
                                    key={'chBox_' + documentType.serviceTypeId}
                                    id={documentType.serviceTypeId.toString()}
                                    name={documentType.serviceTypeName}                                                                                                                                
                                    disableRipple
                                    checked={documentType.vendorEnabled}
                                    onClick={(event) => handleServiceTypeFilterChange(event)}
                                    className={classes.checkboxFormControl}
                                />
                            </FormControl>
                        </Grid>
                        
                    </Grid>
                )
            }
            </Grid>
            <Grid item xs={2}>
                <FormControl className={classes.selectInput}>
                    <InputLabel id="demo-simple-select-label">States</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="stateId"
                        name="stateId"
                        multiple
                        value={props.newServiceByVendor?.stateIds}
                        input={<Input/>}
                        renderValue={(selected) => (selected as number[]).map(x => states.filter(y => y.stateId == x)[0]?.stateName).join(', ')}
                        onChange={handleStateChange}
                        required
                        error={!!errors?.stateId}
                    >
                        {
                            states?.map((state, index) => {
                                return (
                                    <MenuItem key={state.stateId} value={state.stateId}>{state.stateName}</MenuItem>
                                )
                            })
                        }
                    </Select>
                    <FormHelperText style={{color:'red'}}>
                        {errors?.stateId}
                    </FormHelperText>
                </FormControl>
            </Grid>
        </Grid>
        <div className={classes.buttonsContainer}>
            <Button 
                className={classes.cancelButton}
                onClick={handleCancel}
            >
                Cancel
            </Button>
            {props.editMode ?
                <Button className={classes.button} onClick={handleConfirmEdit}>
                    Save
                </Button>
                :
                <Button className={classes.button} onClick={props.createServiceByVendor}>
                    Add
                </Button>
            }
        </div>
    </form>
}