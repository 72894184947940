import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary, Divider, FormControl, FormLabel, List, ListItem, ListItemIcon, Checkbox, ListItemText, ThemeProvider } from '@material-ui/core';
import styles from "./ClaimDetailSummary.Styles";
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StateEntity from '../../../../entities/State.Entity';
import ReferralClaimEntity from '../../../../entities/ReferralClaim.Entity';
import CoverageTypeEntity from '../../../../entities/CoverageType.Entity';
import Moment from 'moment';

interface IClaimDetailSummaryProps {
    referralClaim: ReferralClaimEntity;
    states: Array<StateEntity>;
    coverageTypes: Array<CoverageTypeEntity>;
}

export const ClaimDetailSummary: FunctionComponent<IClaimDetailSummaryProps> = (props: IClaimDetailSummaryProps) => {
    const classes = styles();

    var claimCoverageType = props.coverageTypes?.filter(x => !!props.referralClaim && !!props.referralClaim.coverageTypeId && x.coverageTypeId == props.referralClaim.coverageTypeId);

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>Claim</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.referralClaim ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Claim Number</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralClaim.claimNumber}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Date Of Loss</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{Moment(props.referralClaim.dateOfLoss).format('MM/DD/YYYY')}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Coverage Type</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{claimCoverageType[0]?.coverageTypeCode + ' - ' +claimCoverageType[0]?.coverageTypeName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>State Of Jurisdiction</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.states?.filter(x => !!props.referralClaim && !!props.referralClaim.stateOfJurisdictionId && x.stateId == props.referralClaim.stateOfJurisdictionId)[0]?.stateName}</Typography>
                            </Grid>
                        </Grid>
                        :
                        <EmptyDataLabel></EmptyDataLabel>
                }
            </AccordionDetails>
        </Accordion>
    );
}