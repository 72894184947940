import { createTheme , makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  overrides: {
    "MuiStepLabel-alternativeLabel": {
      marginTop: 0,
    },
  },
  appNavBarHeader: {
    boxShadow: "-2px 0px 10px 0px rgba(0,0,0,0.12)",
    backgroundColor: "#F4F8F9",
    width: "100%",
    paddingLeft: '20px',
    [theme.breakpoints.up("sm")]: {
      minWidth: "initial !important"
    },
    "& h6": {
      color: "#004763"
    }
  },
}));

export const navigationBarTheme = createTheme ({
  overrides: {
    MuiStepIcon: {
      active: {
        color: '#004763!important'
      },
      completed: {
        color: '#428302!important'
      }
    },
    MuiStepLabel: {
      label: {
        marginTop: '0px!important',
        fontSize: '14px',
        fontWeight: 'normal!important' as any
      },
      active: {
        color: '#004763!important',
        fontWeight: 'normal!important' as any
      },
      completed: {
        color: '#428302!important'
      }
    },
    MuiTab: {
      root: {
        minHeight: "64px!important"
      }
    },
  },
});