import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import React from 'react';

interface RadioButtonGroupProps {
    radioButtons: {
        value: string;
        label: string;
        disabled: boolean;
    }[];
    value: unknown;
    onChange: (e?: React.ChangeEvent<HTMLInputElement>) => void;
    labelPlacement: 'start' | 'top' | 'bottom' | 'end';
    readOnly?: boolean;
}

const RadioButtonGroup = ({ labelPlacement, radioButtons, onChange, value, readOnly }: RadioButtonGroupProps) => {
    return (
        <FormControl component="fieldset">
            <RadioGroup row onChange={(e) => onChange(e)} value={value}>
                {radioButtons.map(({ value, label, disabled }) => (
                    <FormControlLabel
                        key={label}
                        value={value}
                        control={<Radio color="primary" />}
                        label={label}
                        labelPlacement={labelPlacement}
                        disabled={disabled || readOnly}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};
export default RadioButtonGroup;
