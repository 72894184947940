import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import styles from "./MedicalServiceReferralForm.Styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ServiceTypeEntity from '../../../../../entities/ServiceType.Entity';
import { MedicalServiceCoverLetter } from './medical-service-cover-letter/MedicalServiceCoverLetter.Component';
import BodySideEntity from '../../../../../entities/BodySide.Entity';
import InjuryTypeEntity from '../../../../../entities/pt/InjuryType.Entity';
import SpecificBodyPartEntity from '../../../../../entities/SpecificBodyPart.Entity';
import { ReferringPhysician } from './referring-physician/ReferringPhysician.Component';
import StateEntity from '../../../../../entities/State.Entity';
import MedicalServiceDataModel from "../../../../../data-models/MedicalService.DataModel";
import { DxHeader } from "./dx-header/DxHeader.Component";
import { useValidationDialog } from "../../../../../support/custom-hooks/useValidationDialog";
import DiagnosticProcedureEntity from '../../../../../entities/DiagnosticProcedure.Entity';
import DiagnosticProcedureDetailDataModel from '../../../../../data-models/DiagnosticProcedureDetail.DataModel';
import DiagnosticServiceDataModel from '../../../../../data-models/DiagnosticService.DataModel';
import ReferralContactEntity from '../../../../../entities/ReferralContact.Entity';
import MSCoverLetterEntity from '../../../../../entities/pt/PTCoverLetter.Entity';
import DiagnosisDataModel from '../../../../../data-models/Diagnosis.DataModel';
import { ReferralContactTypesEnum } from '../../../../../support/enums/ReferralContactTypesEnum';
import LanguageEntity from '../../../../../entities/Language.Entity';
import ServiceTypeRequestDxEntity from '../../../../../entities/ServiceTypeRequestDx.Entity';

interface IDiagnosticServiceReferralFormProps {
    referralId: number,
    medicalService: MedicalServiceDataModel,
    bodySides: Array<BodySideEntity>,
    injuryTypes: Array<InjuryTypeEntity>,
    specificBodyParts: Array<SpecificBodyPartEntity>,
    states: Array<StateEntity>,
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean ) => void,
    handleSetIsValidDxFunction: (currentPageIsValidDxFunction: () => boolean, serviceTypeString: string) => void;
    
    openCloseStatus: Object;
    handleOpenCloseStatus: (boxName: string, isOpened: boolean) => void;

    serviceId?: number,
    serviceTypes: Array<ServiceTypeEntity>,
    getMedicalService?: any,
    procedureDetails: Array<DiagnosticProcedureDetailDataModel>,
    diagnosticService: DiagnosticServiceDataModel,
    nextStep: boolean,
    handleMedicalServiceEdit: any,
    handleDiagnosticServiceEdit: any,
    searchDiagnosisByCodeDescription: (description: string) => void,
    diagnosisFindedArray: Array<DiagnosisDataModel>,
    getDxSpecificBodyParts: (serviceTypeId: number) => void,
    getDxProcedureDetailsByServiceTypeId: (serviceTypeId: number) => void,
    languages: Array<LanguageEntity>,
    loadingDiagnosis: boolean,
}

export interface IDiagnosticServiceReferralFormErrors {
    injuryType?: string,
    specificBodyPart?: string,
    bodySide?: string,
    referringPhysicianFirstName?: string,
    referringPhysicianLastName?: string,
    referringPhysicianTreatingNPI?: string,
    referringPhysicianAddressLine1?: string,
    referringPhysicianAddressLine2?: string,
    referringPhysicianZipCode?: string,
    referringPhysicianCityName?: string,
    referringPhysicianState?: string,
    referringPhysicianEmail?: string,
    referringPhysicianPrimaryPhoneNumber?: string,
    referringPhysicianFax?: string,
    scheduleSpecialInstructions?: string,
    howTallAreYou?:string;
    howMuchDoYouWeight?:string;
    weightLbs?: string;
    heightFt?: string;
    heightInches?: string;
    serviceTypesInProceduresError?:string;
}

export interface IExtraFields {
    weightLbs?: number;
    heightFt?: number;
    heightInches?: number;
}

export const DiagnosticServiceReferralForm: FunctionComponent<IDiagnosticServiceReferralFormProps> = (props: IDiagnosticServiceReferralFormProps) => {

    let isValidDxProcedureValidationFunction = null;
    const classes = styles();
    const [errors, setErrors] = React.useState<IDiagnosticServiceReferralFormErrors>(null);
    const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState<boolean>(false);
    const {Dialog, checkForErrors} = useValidationDialog("Validation","Some required fields are missing.","Ok");
    
    const [diagnosticLocalState, setDiagnosticLocalState] = useState<DiagnosticServiceDataModel>(props.diagnosticService);
    
    //#region serviceTypeRequestDx
    const[serviceTypeRequestDx,setServiceTypeRequestDx] = useState<ServiceTypeRequestDxEntity>(props.diagnosticService?.serviceTypeRequestDx);
    const handleServiceTypeRequestDxEdit = (value) => {
        setServiceTypeRequestDx(value)
        let newD : DiagnosticServiceDataModel = props.diagnosticService;
        newD.serviceTypeRequestDx = value;
        setDiagnosticLocalState(newD);
        handleExtraFieldsEdit(value)
    }
    //#endregion

    //#region serviceTypeRequestDx
    const[extraFields,setExtraFields] = useState<ServiceTypeRequestDxEntity>(props.diagnosticService?.serviceTypeRequestDx);
    const handleExtraFieldsEdit = (value) => {
        setExtraFields(value)
    }
    //#endregion

    //#region referringPhysician
    const[referringPhysician,setReferringPhysician] = useState<ReferralContactEntity>(props.diagnosticService?.referringPhysician ? props.diagnosticService?.referringPhysician : new ReferralContactEntity());
    const handleReferringPhysicianEdit = (value) => {
        setReferringPhysician(value)
        let newD : DiagnosticServiceDataModel = props.diagnosticService;
        newD.referringPhysician = value;
        newD.referringPhysician.contactTypeId = ReferralContactTypesEnum.TreatingPhysician;
        setDiagnosticLocalState(newD);
    }
    //#endregion

    //#region diagnosticProcedure
    const[diagnosticProcedure,setDiagnosticProcedure] = useState<Array<DiagnosticProcedureEntity>>(props.diagnosticService?.procedures);
    const handleDiagnosticProcedureEdit = (value, proceduresTable) => {
        setDiagnosticProcedure(value);
        let newD : DiagnosticServiceDataModel = props.diagnosticService;
        newD.procedures = value;
        newD.proceduresTable = proceduresTable;
        setDiagnosticLocalState(newD);
    }
    //#endregion

    useEffect(() => {
        //props.handleSetValidationFunction(validate);
        props.handleSetIsValidDxFunction(isValidDX, "DX");
        props.handleSetIsValidDxFunction(isValidDxProcedureValidationFunction, "DXProcedure");
    }, [props]);
    
    useEffect(() => {
        let preService;
        preService = diagnosticLocalState;

        preService.referringPhysician = referringPhysician;

        let diagnosticService: DiagnosticServiceDataModel = preService;
        diagnosticService.procedures = diagnosticProcedure;
        diagnosticService.serviceTypeRequestDx = serviceTypeRequestDx
        props.handleDiagnosticServiceEdit(diagnosticService);

    }, [props.nextStep]);

    useEffect(() => {
        if ( !props.openCloseStatus.hasOwnProperty( "Dx_box" ) )  props.handleOpenCloseStatus( "Dx_box", true );
        if ( !props.openCloseStatus.hasOwnProperty( "DxReferringPhysician_box" ) ) props.handleOpenCloseStatus( "DxReferringPhysician_box", true );

        if (!props.diagnosticService) {
            let newD = new DiagnosticServiceDataModel;
            newD.referralId = props.referralId;
            setDiagnosticLocalState(newD);
        } else {
            let newD : DiagnosticServiceDataModel = props.diagnosticService;
            newD.referralId = props.referralId;
            setDiagnosticLocalState(newD);
        }
    }, []);

    const handleOpenCloseStatus = ( event, boxName ) => {
        event.persist();
        props.handleOpenCloseStatus( boxName, !(props.openCloseStatus?.[boxName]??true) );
    };

    const checkProcedures = (procedures:Array<DiagnosticProcedureEntity>) => {
        let returnValue = true;
        props.serviceTypes?.forEach((st)=>{
            var coincidence=false;
            for(var i=0;i<=procedures.length-1;i++){
                if(procedures[i].serviceTypeNumber == st.serviceTypeId){
                    coincidence = true;
                    break;
                }
            }
            if(!coincidence){
                returnValue = false;
            }
        })
        return returnValue;
    }

    const setIsValidDxProcedureFunction = (isValidFunction) => {
        isValidDxProcedureValidationFunction = isValidFunction;
    }

    const validateDX = (isChange?: boolean, isSubmit: boolean = true) => {
        let errors: IDiagnosticServiceReferralFormErrors = {};
        errors = getErrorsListDX()
        setErrors(errors);
        return checkForErrors(errors, isChange);
    };

    const isValidDX = () => { //returns true/false to know if the form has passed the validation OK (true) or not (false)
        let errors: IDiagnosticServiceReferralFormErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsListDX();
        setErrors(errors);
        //alert ('len errors:' + Object.keys(errors).length)
        isValidResult = (Object.keys(errors).length == 0);
        return isValidResult;
    }

    const getErrorsListDX = () => {//returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: IDiagnosticServiceReferralFormErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }

        if(!(diagnosticLocalState.referringPhysician?.firstName || 
            diagnosticLocalState.referringPhysician?.lastName ||
            diagnosticLocalState.referringPhysician?.addressLine1 ||
            diagnosticLocalState.referringPhysician?.zipCode || 
            diagnosticLocalState.referringPhysician?.cityName || 
            diagnosticLocalState.referringPhysician?.stateId ||
            diagnosticLocalState.referringPhysician?.primaryPhoneNumber
            )) {
                let newD : DiagnosticServiceDataModel = props.diagnosticService;
                newD.referringPhysician = null;
                setDiagnosticLocalState(newD);
            }
        

        //if ( !checkProcedures(props.diagnosticService?.procedures ) /*&& isSubmit*/){errors.serviceTypesInProceduresError = "Required";}

        return errors;
    }

    return (
        <Fragment>
            <Grid item xs={12}>
                <Accordion className={classes.paper} expanded={props.openCloseStatus["Dx_box"]??true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                        aria-controls="panel1a-content"
                        id="cliam-accordion"
                        className={classes.paperHeader}
                        onClick={(event) => handleOpenCloseStatus(event, "Dx_box")}
                    >
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" className={classes.sidebarTitle}>Diagnostic</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails>
                        <>
                            <Grid container>               
                                <Grid item xs={12}>
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="caption"
                                            className={classes.actionSubtitle}
                                        >
                                            One option of each service type must be added
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2" className={classes.sidebarTitle}><p></p></Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <DxHeader
                                        procedures={props.diagnosticService?.procedures}
                                        proceduresTable={props.diagnosticService?.proceduresTable}
                                        handleServiceTypesEdit={handleDiagnosticProcedureEdit}
                                        referralId={props.referralId}
                                        bodySides={props.bodySides}
                                        injuryTypes={props.injuryTypes}
                                        specificBodyParts={props.specificBodyParts}
                                        procedureDetails={props.procedureDetails}
                                        errors={errors}
                                        validate={validateDX}
                                        handleSetIsValidDxProcedureFunction={setIsValidDxProcedureFunction}
                                        hasBeenSubmitted={hasBeenSubmitted}
                                        serviceTypes={props.serviceTypes}
                                        searchDiagnosisByCodeDescription={props.searchDiagnosisByCodeDescription}
                                        diagnosisFindedArray={props.diagnosisFindedArray}
                                        getDxSpecificBodyParts={props.getDxSpecificBodyParts}
                                        getDxProcedureDetailsByServiceTypeId={props.getDxProcedureDetailsByServiceTypeId}
                                        handleServiceTypeRequestDxEdit={handleServiceTypeRequestDxEdit}
                                        languages={props.languages}
                                        serviceTypeRequestDx={props.diagnosticService?.serviceTypeRequestDx}
                                        loadingDiagnosis={props.loadingDiagnosis}
                                    />
                                </Grid>
                            </Grid>       
                        </>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Accordion className={classes.paper}  expanded={props.openCloseStatus["DxReferringPhysician_box"]??true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                        aria-controls="panel1a-content"
                        id="adjuster-accordion"
                        className={classes.paperHeader}
                        onClick={(event) => handleOpenCloseStatus(event, "DxReferringPhysician_box")}
                    >
                        <Typography variant="subtitle2" className={classes.sidebarTitle}>Referring Physician</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ReferringPhysician
                            handleLocalServiceEdit={handleReferringPhysicianEdit}
                            referringPhysician={referringPhysician}
                            referralId={props.referralId}
                            states={props.states}
                            errors={errors}
                            validate={validateDX}
                            hasBeenSubmitted={hasBeenSubmitted}
                            serviceId={props.serviceId}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>
            {Dialog}
        </Fragment>
    );
}