import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary, Divider, FormControl, FormLabel, List, ListItem, ListItemIcon, Checkbox, ListItemText, ThemeProvider } from '@material-ui/core';
import styles from "./AdjusterSummary.Styles";
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import StateEntity from '../../../../entities/State.Entity';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';

interface IAdjusterSummaryProps {
    referralAdjuster: ReferralContactEntity;
    states: Array<StateEntity>;
    serviceId?: number;
}

export const AdjusterSummary: FunctionComponent<IAdjusterSummaryProps> = (props: IAdjusterSummaryProps) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>Adjuster</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.referralAdjuster ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>First Name</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralAdjuster.firstName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>State</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.states?.filter(x => !!props.referralAdjuster && !!props.referralAdjuster.stateId && x.stateId == props.referralAdjuster.stateId)[0]?.stateName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Primary Phone</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralAdjuster.primaryPhoneNumber}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Ext.</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralAdjuster.primaryPhoneExtension}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Last Name</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralAdjuster.lastName}</Typography>
                            </Grid>
                            {
                                props.serviceId === ServicesEnum.Diagnostic ?
                                <Grid container xs={3} spacing={1} style={{ marginTop:'9px', marginRight: '4px'}}>
                                    <Grid item xs={3} style={{ marginLeft: '12px', marginRight: '-15%' }}>
                                        <Typography variant="body2" className={classes.dataLabel}>Zip Code</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralAdjuster.zipCode}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Grid item xs={3} style={{ marginTop:'12px',marginLeft: '15px', marginRight: '-8%' }}>
                                            _
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3} style={{marginRight: '15px'}}>
                                        <Typography variant="body2" className={classes.dataLabel}>Zip Code Ext.</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralAdjuster.zipCodeExtension}</Typography>
                                    </Grid>
                                </Grid>
                                :
                                <Grid item xs={3}>
                                    <Typography variant="body2" className={classes.dataLabel}>Zip Code</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.referralAdjuster.zipCode}</Typography>
                                </Grid>
                            }
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Secondary Phone</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralAdjuster.secondaryPhoneNumber}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Ext.</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralAdjuster.secondaryPhoneExtension}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Address Line 1</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralAdjuster.addressLine1}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Email</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralAdjuster.email}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" className={classes.dataLabel}>Fax</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralAdjuster.fax}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Grid xs={12} container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" className={classes.dataLabel}>Address Line 2</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralAdjuster.addressLine2}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" className={classes.dataLabel}>City</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralAdjuster.cityName}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography variant="body2" className={classes.dataLabel}>Additional Comments</Typography>
                                    {props.referralAdjuster?.comments?.split('\n').map((sentence, key) => (
                                        <Typography key={key} variant="subtitle2" className={classes.blackFont}>
                                            {sentence}
                                        </Typography>
                                    ))}
                                </Grid>
                        </Grid>
                        :
                        <EmptyDataLabel></EmptyDataLabel>
                }
            </AccordionDetails>
        </Accordion>
    );
}