import React, { Fragment, FunctionComponent } from 'react';
import styles from './ReferralClaimInfo.Styles';
import { Link, Typography, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReferralOverviewDataModel } from '../../../../data-models/ReferralOverview.DataModel';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import StateEntity from '../../../../entities/State.Entity';
import ReferralClaimEntity from '../../../../entities/ReferralClaim.Entity';
import CoverageTypeEntity from '../../../../entities/CoverageType.Entity';
import { ReferralImeClaimInfo } from './referral-ime-claim-info/ReferralImeClaimInfo.Component';
import { ReferralMsClaimInfo } from './referral-ms-claim-info/ReferralMsClaimInfo.Component';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';
import { ReferralTTClaimInfo } from './referral-TT-claim-info/ReferralTTClaimInfo.Component';

type PathParamsType = {
    id: string;
    claimId: string;
};

type ReferralClaimInfoProps = {
    referralClient: ReferralContactEntity;
    referralOverviewDataModel: ReferralOverviewDataModel;
    states: Array<StateEntity>;
    referralClaim: ReferralClaimEntity;
    coverageTypes: Array<CoverageTypeEntity>;
    referralAdjuster: ReferralContactEntity;
    referralClaimant: ReferralContactEntity;
    referralClaimantAttorney: ReferralContactEntity;
    referralEmployer: ReferralContactEntity;
    referralEmployerAttorney: ReferralContactEntity;
    referralNurseCaseManager: ReferralContactEntity;
} & WithStyles<typeof styles> &
    RouteComponentProps<PathParamsType>;

const ReferralClaimInfoComponent: FunctionComponent<ReferralClaimInfoProps> = (props: ReferralClaimInfoProps) => {
    const { classes } = props;

    return (
        <Fragment>
            {
                <div className={classes.content}>
                    {props.referralOverviewDataModel?.referralServiceType[0]?.serviceId == ServicesEnum.IME ? (
                        <ReferralImeClaimInfo
                            referralOverviewDataModel={props.referralOverviewDataModel}
                            states={props.states}
                            referralClient={props.referralClient}
                            coverageTypes={props.coverageTypes}
                            referralClaim={props.referralClaim}
                            referralAdjuster={props.referralAdjuster}
                            referralClaimant={props.referralClaimant}
                            referralClaimantAttorney={props.referralClaimantAttorney}
                            referralEmployer={props.referralEmployer}
                            referralEmployerAttorney={props.referralEmployerAttorney}
                        />
                    ) : props.referralOverviewDataModel?.referralServiceType[0]?.serviceId == ServicesEnum.TT ? (
                        <ReferralTTClaimInfo
                            referralOverviewDataModel={props.referralOverviewDataModel}
                            states={props.states}
                            referralClient={props.referralClient}
                            coverageTypes={props.coverageTypes}
                            referralClaim={props.referralClaim}
                            referralAdjuster={props.referralAdjuster}
                            referralClaimant={props.referralClaimant}
                            referralClaimantAttorney={props.referralClaimantAttorney}
                            referralEmployer={props.referralEmployer}
                            referralEmployerAttorney={props.referralEmployerAttorney}
                        />
                    ) : (
                        <ReferralMsClaimInfo
                            referralOverviewDataModel={props.referralOverviewDataModel}
                            states={props.states}
                            referralClient={props.referralClient}
                            coverageTypes={props.coverageTypes}
                            referralClaim={props.referralClaim}
                            referralAdjuster={props.referralAdjuster}
                            referralClaimant={props.referralClaimant}
                            referralClaimantAttorney={props.referralClaimantAttorney}
                            referralEmployer={props.referralEmployer}
                            referralEmployerAttorney={props.referralEmployerAttorney}
                            referralNurseCaseManager={props.referralNurseCaseManager}
                        />
                    )}
                </div>
            }
        </Fragment>
    );
};

export const ReferralClaimInfo = withRouter(withStyles(styles)(ReferralClaimInfoComponent));
