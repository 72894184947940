import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary, List } from '@material-ui/core';
import styles from "./ResponseDocuments.Styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MaterialTable from "../../../generic-components/material-table/MaterialTable.Component";
import { MaterialTableProps } from 'material-table';
import ReferralDocumentEntity from '../../../../entities/ReferralDocument.Entity';
import Moment from 'moment';

interface IResponseDocumentsProps {
    vendorDocuments: Array<ReferralDocumentEntity>
}

export const ResponseDocuments: FunctionComponent<IResponseDocumentsProps> = (props: IResponseDocumentsProps) => {
    const classes = styles();

    const filesTableProps: MaterialTableProps<ReferralDocumentEntity> = {
        columns: [
            {
                title: "Description",
                field: "fileDescription"
            },
            {
                title: "File Name",
                field: "fileName"
            },            
            {
                title: "Document Type",
                field: "documentType"
            },            
            {
                title: "Date",
                render: rowData => (rowData.dueDate ? Moment(rowData.dueDate).format("M/D/YYYY") : "")
            },
            {
                title: "Uploaded by",
                field: "uploadedBy"
            }
        ],
        data: props.vendorDocuments ? props.vendorDocuments : [],
        options: {
            paging: false,
            toolbar: false
        }
    };


    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>Documents</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12}>
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            className={classes.root}
                        >
                            <MaterialTable tableProps={filesTableProps} />
                        </List>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}