import React, { FunctionComponent, Fragment } from 'react';
import styles from "./Sidebar.Styles";
import { Hidden, Drawer, SwipeableDrawer } from '@material-ui/core';


export const Sidebar: FunctionComponent<{}> = ({ children, ...other }) => {
    const classes = styles();

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
            <div className={classes.drawerContainer} {...other}>{children}</div>
    );

    return (
        <Fragment>
            <Hidden smUp implementation="css">
                <SwipeableDrawer
                    variant="temporary"
                    open={mobileOpen}
                    anchor='right'
                    onClose={handleDrawerToggle}
                    onOpen={handleDrawerToggle}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                    ModalProps={{
                        keepMounted: true // Better open performance on mobile.
                    }}
                >
                    {drawer}
                </SwipeableDrawer>
            </Hidden>
            <Hidden xsDown implementation="css">
                <Drawer
                    anchor='right'
                    classes={{
                        paper: classes.drawerPermanentPaper,
                    }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
            </Hidden>
        </Fragment>
    );
}