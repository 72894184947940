
import { Button, FormControl, Grid, IconButton, Input, InputAdornment, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { MaterialTableProps, MTableCell } from "material-table";
import React, { FunctionComponent } from "react";
import VendorProfileDataModel from "../../../data-models/VendorProfile.DataModel";
import StateEntity from "../../../entities/State.Entity";
import styles from './VendorProfile.Styles';
import TenantPartnerVendorDataModel from "../../../data-models/TenantPartnerVendorDataModel";
import ServiceTypeEntity from "../../../entities/ServiceType.Entity";
import ServiceDataModel from "../../../data-models/Service.DataModel";

interface VendorProfileProps{
    vendor: VendorProfileDataModel,
    states: Array<StateEntity>;
    handleVendorEdit: (newVendor: VendorProfileDataModel) => void;
    handleStartVendorProfileEdit: () => void;
    handleCancelVendorProfileEdit: () => void;
    handleSaveEditedVendorProfile: () => void;
    services: Array<ServiceDataModel>;
}

export const VendorProfile: FunctionComponent<VendorProfileProps> = (props) => {
    const classes = styles();
    const abortController = new AbortController();

    const [vendorState, setVendorState] = React.useState(props.vendor);
    const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
    const [serviceTypes, setServiceTypes] = React.useState<Array<ServiceTypeEntity>>([]);
    const [newServiceAndFee, setNewServiceAndFee] = React.useState<{serviceTypeId: number, price: number}>(null);

    React.useEffect(() => {
        let newVendor = props.vendor;
        if(!newVendor){
            newVendor = {} as VendorProfileDataModel;
            props.handleVendorEdit(newVendor);
        }

        const newServiceTypes = [];

        props.services?.forEach(service => {
            service.serviceTypes.forEach(serviceType => {
                newServiceTypes.push(serviceType);
            });
        });

        setServiceTypes(newServiceTypes);

        setVendorState(newVendor);

        return function cleanup() {
            abortController.abort();
        }
    }, [props]);

    const handleTextFieldChange = (event: 
        React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        |
        React.ChangeEvent<{name?: string; value: unknown; id?:string;}>
    ) => {
        event.persist();
        let newVendor : VendorProfileDataModel = vendorState;
        newVendor[event.target.id ? event.target.id : event.target.name] = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value;
        setVendorState(newVendor);
        props.handleVendorEdit(newVendor);
    }

    const handleServiceFeeChange = (event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            event.persist();
            let newVendor : VendorProfileDataModel = vendorState;
            const editedService = newVendor.services.find(s => s.serviceTypeId.toString() === event.target.id);
            editedService.price = parseFloat(event.target.value) ? parseFloat(event.target.value) : 0;
            editedService.edited = true;
            props.handleVendorEdit(newVendor);
        }

    if(!props.vendor || !vendorState ) return null;

    return (
        <Paper className={classes.paper} >
            <div className={classes.header} >
                <Typography variant='subtitle2' className={classes.greenText} >
                    Vendor Information
                </Typography>
                <IconButton 
                    onClick={
                        () => {
                            props.handleStartVendorProfileEdit();
                            setIsEditMode(true)
                        }
                    }
                >
                    <Edit />
                </IconButton>
            </div>
            <Grid container spacing={3} className={classes.formContainer} >
                <Grid item xs={3}>
                    <TextField 
                        label='Name' 
                        id='vendorName'
                        InputLabelProps={{
                            shrink: !!vendorState.vendorName
                        }}
                        value={vendorState.vendorName}
                        onChange={(event) => handleTextFieldChange(event)}
                        disabled={!isEditMode} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        label='Vendor Number' 
                        id='vendorNumber'
                        InputLabelProps={{
                            shrink: !!vendorState.vendorNumber || vendorState.vendorNumber === 0
                        }}
                        value={vendorState.vendorNumber}
                        onChange={(event) => handleTextFieldChange(event)}
                        disabled={!isEditMode}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        label='Email' 
                        id='email'
                        InputLabelProps={{
                            shrink: !!vendorState.email
                        }}
                        value={vendorState.email}
                        onChange={(event) => handleTextFieldChange(event)}
                        disabled={!isEditMode} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        label='FEIN' 
                        id='fein'
                        InputLabelProps={{
                            shrink: !!vendorState.fein
                        }}
                        value={vendorState.fein}
                        onChange={(event) => handleTextFieldChange(event)}
                        disabled={!isEditMode} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        label='Phone Number' 
                        id='phone1'
                        InputLabelProps={{
                            shrink: !!vendorState.phone1
                        }}
                        value={vendorState.phone1}
                        onChange={(event) => handleTextFieldChange(event)}
                        disabled={!isEditMode} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        label='Website' 
                        id='api'
                        InputLabelProps={{
                            shrink: !!vendorState.api
                        }}
                        value={vendorState.api}
                        onChange={(event) => handleTextFieldChange(event)}
                        disabled={!isEditMode} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        label='Address 1' 
                        id='address1'
                        InputLabelProps={{
                            shrink: !!vendorState.address1
                        }}
                        value={vendorState.address1}
                        onChange={(event) => handleTextFieldChange(event)}
                        disabled={!isEditMode} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        label='Address 2' 
                        id='address2'
                        InputLabelProps={{
                            shrink: !!vendorState.address2
                        }}
                        value={vendorState.address2}
                        onChange={(event) => handleTextFieldChange(event)}
                        disabled={!isEditMode} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        label='City' 
                        id='city'
                        InputLabelProps={{
                            shrink: !!vendorState.city
                        }}
                        value={vendorState.city}
                        onChange={(event) => handleTextFieldChange(event)}
                        disabled={!isEditMode} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl 
                        className={classes.selectInput}
                        disabled={!isEditMode} 
                    >
                        <InputLabel id="demo-simple-select-label">State</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="stateId"
                            name="stateId"
                            value={vendorState.stateId}
                            onChange={(event) => handleTextFieldChange(event)}
                        >
                            {
                                props.states?.map((state, index) => {
                                    return (
                                        <MenuItem key={state.stateId} value={state.stateId}>{state.stateName}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField 
                        label='Zip' 
                        id='zip'
                        InputLabelProps={{
                            shrink: !!vendorState.zip
                        }}
                        value={vendorState.zip}
                        onChange={(event) => handleTextFieldChange(event)}
                        disabled={!isEditMode} 
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormControl 
                        className={classes.selectInput} 
                        disabled={!isEditMode} 
                    >
                        <InputLabel id="demo-simple-select-label">Status</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="isActive"
                            name="isActive"
                            value={vendorState.isActive ? 1 : 0}
                            onChange={(event) => handleTextFieldChange(event)}
                        >
                            <MenuItem value={1}>Active</MenuItem>
                            <MenuItem value={0}>Inactive</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                
                <Grid item xs={3}>
                    <FormControl 
                        className={classes.selectInput} 
                        disabled={!isEditMode} 
                    >
                        <InputLabel id="demo-simple-select-label">Has Authentication</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="hasAuthentication"
                            name="hasAuthentication"
                            value={vendorState.hasAuthentication ? 1 : 0}
                            onChange={(event) => handleTextFieldChange(event)}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl 
                        className={classes.selectInput} 
                        disabled={!isEditMode} 
                    >
                        <InputLabel id="demo-simple-select-label">Has File Authentication</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="hasFileAuthentication"
                            name="hasFileAuthentication"
                            value={vendorState.hasFileAuthentication ? 1 : 0}
                            onChange={(event) => handleTextFieldChange(event)}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <TextField 
                        label='Tenants'
                        id='tenantName'
                        //defaultValue={vendorState.tenantName}
                        value={vendorState.tenantNames && vendorState.tenantNames.length !== 0 ? vendorState.tenantNames.join(", ") : 'No tenants assigned' }
                        //onChange={(event) => handleTextFieldChange(event)}
                        disabled
                    />
                </Grid>
                
                <Grid item xs={4}>
                    <Typography variant='subtitle2' style={{
                        color:'rgba(0, 0, 0, 0.38)',
                        fontWeight:400,
                        fontSize:'12px',
                    }} >
                        Services & Fees
                    </Typography>
                    <div className={classes.servicesContainer}>
                        {props.vendor?.services?.map( service => {
                            return (
                            <div className={classes.serviceContainer} >
                                <div className={classes.serviceName} > {service.serviceTypeName} </div>
                                <TextField
                                    type='number'
                                    className={classes.servicePrice}
                                    id={service?.serviceTypeId.toString()}
                                    value={vendorState?.services?.find( s => s.serviceTypeId === service.serviceTypeId)?.price}
                                    onChange={(event) => handleServiceFeeChange(event)}
                                    disabled={!isEditMode}
                                    InputProps={{
                                        startAdornment: <InputAdornment position='start'>$</InputAdornment> 
                                    }}
                                />
                            </div>
                        )} )  }
                    </div>
                </Grid>
            </Grid>
            {isEditMode && 
                <div className={classes.buttonsContainer}>
                    <Button 
                        className={classes.cancelButton} 
                        onClick={
                            () => {
                                props.handleCancelVendorProfileEdit();
                                setIsEditMode(false);
                            }
                        }
                    >
                        Cancel
                    </Button>
                    <Button className={classes.button} onClick={props.handleSaveEditedVendorProfile} >
                        Save
                    </Button>
                </div>
            }
        </Paper>
    )
}