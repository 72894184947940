import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import styles from './ClaimList.Styles';
import MaterialTable from '../../generic-components/material-table/MaterialTable.Component';
import { Link, useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import { MaterialTableProps } from 'material-table';
import getProfile from '../../../services/Users.Service';
import ClaimTableDataModel from '../../../data-models/ClaimTable.DataModel';
import { getClaimTable } from '../../../services/Claim.Service';
import { BreadCrumb } from '../../../support/custom-hooks/useBreadCrum';
import { Loader } from '../../generic-components/loader/Loader.Component';

interface NewCLientState {
    loading: boolean;
    claims: Array<ClaimTableDataModel>;
    profileId: number;
}

type PathParamsType = {
    handleId?: (id: number) => void;
    // claimId: string;
};

export const ClaimList: FunctionComponent<PathParamsType> = (props) => {
    const [state, setState] = useState<NewCLientState>({
        loading: false,
        profileId: null,
        claims: null,
    });

    const { path } = useRouteMatch();
    const classes = styles();
    const abortController = new AbortController();

    const [filter, setFilter] = useState(false);

    useEffect(() => {
        getUserProfile();
        setState({
            ...state,
            loading: true,
        });
    }, []);

    const getUserProfile = () => {
        setState({
            ...state,
            loading: true,
        });
        getProfile(abortController.signal).then((profile) => {
            getClaimTable(profile?.tenantId, abortController.signal).then((result) => {
                setState({
                    ...state,
                    claims: result,
                    loading: false,
                });
            });
        });
    };

    const handleNavigateToProfile = (id: number, section: string) => {
        if (isNaN(id)) {
            return;
        }

        props.handleId(id);

        setState({ ...state, loading: false });
    };

    const setColumns = () => {
        return [
            {
                title: 'Claim Number',
                field: 'claimNumber',
                render: (rowData) => (
                    <Link
                        to={`${path}/${rowData.claimId}`}
                        onClick={() => handleNavigateToProfile(rowData.claimId, 'Claims')}
                    >
                        {rowData.claimNumber}
                    </Link>
                ),
            },
            {
                title: 'Client Name',
                field: 'clientName',
            },
            {
                title: 'Claimant',
                field: 'claimantName',
            },
            {
                title: 'DOL',
                field: 'dateOfLoss',
                render: (rowData) =>
                    rowData.dateOfLoss
                        ? moment(rowData.dateOfLoss).format('M/D/YYYY') +
                          ' at ' +
                          moment(rowData.dateOfLoss).format('HH:mm')
                        : '',
                customSort: (a, b) => new Date(a.dateOfLoss)?.getTime() - new Date(b.dateOfLoss)?.getTime(),
            },
            {
                title: 'Coverage',
                field: 'coverageTypeName',
            },
            {
                title: 'State of jurisdiction',
                field: 'stateOfJurisdictionName',
            },
            {
                title: 'Last Referral submited date',
                field: 'lastReferralSubmittedDate',
                render: (rowData) =>
                    rowData.lastReferralSubmittedDate
                        ? moment(rowData.lastReferralSubmittedDate).format('M/D/YYYY')
                        : '',
            },
            {
                title: 'Number of Referrals',
                field: 'numberOfReferrals',
            },
        ];
    };

    const tableProps: MaterialTableProps<ClaimTableDataModel> = {
        columns: setColumns(),
        data: state.claims ?? [],
        actions: [
            {
                icon: 'tune',
                tooltip: 'Filter',
                isFreeAction: true,
                onClick: () => setFilter(!filter),
            },
        ],
        options: {
            filtering: filter,
            showTitle: true,
            paging: state.claims ? state.claims.length >= 5 : false,
            toolbar: true,
            exportButton: true,
            search: true,
            exportAllData: true,
            exportFileName: 'TerraClaimConnect_Claim_List',
            rowStyle: (rowData) => ({
                backgroundColor: 'inherit',
            }),
            actionsColumnIndex: -1,
        },
        localization: {
            header: {
                actions: '',
            },
        },
        title: 'Claims',
    };

    const claimBreadCrumb = {
        name: 'Claims',
        goBack: false,
        link: '/claims',
        path: path,
    };

    return (
        <Fragment>
            {state.loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <BreadCrumb sections={[claimBreadCrumb]} />
                    <Paper className={classes.clientListPaper}>
                        <MaterialTable tableProps={tableProps} />
                    </Paper>
                </Fragment>
            )}
        </Fragment>
    );
};
