import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid } from '@material-ui/core';
import styles from "./ClaimSummary.Styles";
import PersonIcon from '@material-ui/icons/Person';
import CardIcon from '@material-ui/icons/ContactMail';
import LocationIcon from '@material-ui/icons/LocationOn';
import Moment from 'moment';
import { EmptyDataLabel } from '../../generic-components/empty-data-label/EmptyDataLabel.Component';
import ClaimEntity from '../../../entities/Claim.Entity';
import CoverageTypeEntity from '../../../entities/CoverageType.Entity';
import StateEntity from '../../../entities/State.Entity';
import ReferralContactEntity from '../../../entities/ReferralContact.Entity';

interface IClaimSummaryProps {
    claim: ClaimEntity,
    claimant: ReferralContactEntity,
    coverageType: CoverageTypeEntity,
    stateOfJurisdiction: StateEntity,
    client: ReferralContactEntity
}

export const ClaimSummary: FunctionComponent<IClaimSummaryProps> = (props: IClaimSummaryProps) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionDetails>
                {
                    props.claim ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid item xs={2}>
                                <Typography variant="subtitle2" className={classes.claimTitle}>Claim</Typography>
                                <Typography variant="subtitle1" className={classes.claimData}> {props.claim?.claimNumber ? props.claim?.claimNumber : 'No claim data'}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" className={classes.dataLabel}>Claimant</Typography>
                                <div className={classes.iconTextRow}>
                                    <PersonIcon className={classes.blackFont}></PersonIcon> <Typography variant="subtitle1" className={classes.claimData}>{props.claimant?.firstName && props.claimant?.lastName ? props.claimant?.firstName + ' ' + props.claimant?.lastName : 'No claimant data'}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" className={classes.dataLabel}>DOL</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{Moment(props.claim?.dateOfLoss).format('MM/DD/YYYY')}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" className={classes.dataLabel}>Coverage</Typography>
                                <div className={classes.iconTextRow}>
                                    <CardIcon className={classes.blackFont}></CardIcon> <Typography variant="subtitle2" className={classes.iconData}>{props.coverageType?.coverageTypeCode + ' - ' + props.coverageType?.coverageTypeName}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" className={classes.dataLabel}>State of Jurisdiction</Typography>
                                <div className={classes.iconTextRow}>
                                    <LocationIcon className={classes.blackFont}></LocationIcon> <Typography variant="subtitle2" className={classes.iconData}>{props.stateOfJurisdiction?.stateName}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="body2" className={classes.dataLabel}>Client</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.client?.number + ' - ' + props.client?.name}</Typography>
                            </Grid>
                        </Grid>
                        :
                        <EmptyDataLabel></EmptyDataLabel>
                }
            </AccordionDetails>
        </Accordion>
    );
}