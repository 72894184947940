import React, { FunctionComponent } from "react";
import { IconButton, InputAdornment } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import styles from './ClearSelect.Styles';

type ClearSelectProps = {
    shouldRender: boolean;
    onClick: () => void;
}
export const ClearSelect: FunctionComponent<ClearSelectProps> = ({
    shouldRender,
    onClick
}) => {
    const classes = styles();

    if(!shouldRender)
    {
        return <></>
    }

    return (
        <InputAdornment className={classes.selectAdornment} position="end">
            <IconButton 
                className={classes.selectAdornmentButton}
                onClick={onClick}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </InputAdornment>
    )
}