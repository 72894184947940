import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { modifyBreadCrumb } from '../../store/actions/usersActions';
import { UsersState } from '../../store/reducers/usersReducer';
import getProfile from '../../services/Users.Service';

const useStyles = makeStyles(() => ({
    referralHeader: {
        color: '#648C9B',
        cursor: 'pointer',
        marginLeft: '5px',
    },
    referralNumber: {
        marginLeft: '5px',
        fontWeight: 'bold',
        color: '#004763',
    },
    referralHeaderContainer: {
        display: 'flex',
        margin: '10px',
    },
}));

export interface Section {
    name: string;
    goBack: boolean;
    onClick?: (index?: number) => void;
    link?: string;
    path?: string;
}

interface IBreadCrumbProps {
    sections: Section[];
}

export const BreadCrumb: FunctionComponent<IBreadCrumbProps> = ({ sections }: IBreadCrumbProps) => {
    const breadCrumbStore = useSelector<UsersState, UsersState['breadCrumb']>((state) => state.breadCrumb);
    const [modifiedBreadCrumb, setModifiedBreadCrumb] = useState<Section[]>(breadCrumbStore);
    const dispatch = useDispatch();
    const abortController = new AbortController();
    const [adjusterProfileId, setAdjusterProfileId] = React.useState(0);
    const [vendorProfileId, setVendorProfileId] = React.useState(0);

    const classes = useStyles();

    const onModifyBreadCrumb = (breadCrumb: Array<Section>) => {
        dispatch(modifyBreadCrumb(breadCrumb));
    };

    useEffect(() => {
        onModifyBreadCrumb(sections);
        setModifiedBreadCrumb(sections);
        getProfile(abortController.signal).then(userProfile => {
            setAdjusterProfileId(userProfile.userRoles.find(userRole => {return userRole == 2 }));
            setVendorProfileId(userProfile.userRoles.find(userRole => {return userRole == 1 }));
        }).catch(error => {

        });
    }, []);

    return (
        <div className={classes.referralHeaderContainer}>
            <>
                {modifiedBreadCrumb !== undefined &&
                    modifiedBreadCrumb !== null &&
                    modifiedBreadCrumb.length > 0 &&
                    modifiedBreadCrumb.map((bc, idx) =>
                    idx !== modifiedBreadCrumb.length - 1 && bc.link && (bc.link != "/claims" && adjusterProfileId==2 || bc.link != "/claims" && vendorProfileId==1) ? (
                            bc.link ? (
                                <Link
                                    key={'lnk_' + idx}
                                    style={{ textDecoration: 'none' }}
                                    to={bc.link}
                                    className={
                                        bc.goBack || bc.onClick !== null
                                            ? classes.referralHeader
                                            : classes.referralNumber
                                    }
                                >
                                    <Typography variant="subtitle2" className={classes.referralHeader}>
                                        {bc.name} {idx < modifiedBreadCrumb.length - 1 ? '>' : ''}
                                    </Typography>
                                </Link>
                            ) : (
                                <Typography variant="subtitle2" className={classes.referralHeader}>
                                    {bc.name} {idx < modifiedBreadCrumb.length - 1 ? '>' : ''}
                                </Typography>
                            )
                        ) : (
                            <Typography variant="subtitle2" className={classes.referralNumber} key={'tgp_' + idx}>
                                {bc.name}
                            </Typography>
                        ),
                    )}
            </>
        </div>
    );
};
