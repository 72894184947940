import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    claimTitle: {
        color: '#428302',
        marginLeft: '10px',
    },
    gridContainer: {
        width: '100%',
        display: 'contents',
    },
    paper: {
        paddingTop: '15px',
        paddingLeft: '15px',
        paddingRight: '15px',
        marginRight: '24px!important',
    },
    dataLabel: {
        color: '#898F91',
        fontSize: '12px',
    },
    iconTextRow: {
        display: 'inline-flex',
        flexDirection: 'row',
    },
    blackFont: {
        color: '#000000',
    },
    claimData: {
        color: '#000000',
        fontWeight: 'bold',
        marginLeft: 10,
    },
    iconData: {
        color: '#000000',
        marginLeft: 10,
    },
}));
