import React, { Fragment, Component } from 'react';
import styles from './VendorReferrals.Styles';
import { Typography, WithStyles, withStyles } from '@material-ui/core';
import { Loader } from '../../components/generic-components/loader/Loader.Component';
import getReferrals from '../../services/Referrals.Service';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import ReferralStatusEntity from '../../entities/ReferralStatus.Entity';
import getReferralStatuses from '../../services/ReferralStatuses.Service';
import { VendorsReferralList } from '../../components/referral-components/referral-list/vendors-referral-list/VendorsReferralList.Component';
import ReferralEntity from '../../entities/Referral.Entity';
import { IReferralEntityTable } from '../../components/referral-components/referral-list/adjusters-referral-list/AdjustersReferralList.Component';
import { BreadCrumb, Section } from '../../support/custom-hooks/useBreadCrum';

type VendorReferralsState = {
    loading: boolean;
    referrals: Array<IReferralEntityTable>;
    referralStatus: Array<ReferralStatusEntity>;
    vendorsReferralListBreadCrumb: Section;
};

type PathParamsType = {
    id: string;
};

type VendorReferralsProps = WithStyles<typeof styles> & RouteComponentProps<PathParamsType>;

const getTableReferrals = (referrals: ReferralEntity[]) => {
    return referrals.map((referral) => {
        const tableReferral: IReferralEntityTable = {
            ...referral,
            joinedServiceTypeNames: referral.serviceTypeNames.join(', '),
        };

        return tableReferral;
    });
};

class VendorReferralsComponent extends Component<VendorReferralsProps, VendorReferralsState> {
    constructor(props: VendorReferralsProps) {
        super(props);

        this.state = {
            loading: false,
            referrals: [],
            referralStatus: [],
            vendorsReferralListBreadCrumb: {
                name: 'Referral list',
                goBack: false,
                link: '/referrals',
                path: this.props?.location?.pathname,
            },
        };
    }

    abortController = new AbortController();
    componentDidMount() {
        this.setState({
            loading: true,
        });

        getReferrals(this.abortController.signal, null)
            .then((referralsResult) => {
                getReferralStatuses(this.abortController.signal)
                    .then((referralStatusResult) => {
                        const referrals = getTableReferrals(referralsResult);
                        this.setState({
                            referralStatus: referralStatusResult,
                            referrals: referrals,
                            loading: false,
                        });
                    })
                    .catch((err) => {
                        this.setState({
                            loading: false,
                        });
                    });
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                });
            });
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                {this.state.loading ? (
                    <Loader />
                ) : (
                    <Fragment>
                        <div className={classes.content}>
                            {/* <div className={classes.referralHeaderContainer}>
                                <Typography variant="subtitle2" className={classes.referralHeader}>
                                    Claims {'>'} Referral list {'>'}{' '}
                                </Typography>
                            </div> */}
                            <Fragment>
                                <BreadCrumb sections={[this.state.vendorsReferralListBreadCrumb]} />
                                <VendorsReferralList
                                    referralStatus={this.state.referralStatus}
                                    referrals={this.state.referrals}
                                />
                            </Fragment>
                        </div>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export const VendorReferrals = withRouter(withStyles(styles)(VendorReferralsComponent));
