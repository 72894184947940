import VendorAuthCredentialDataModel from "../data-models/VendorAuthCredential.DataModel";
import VendorAuthCredentialEntity from "../entities/VendorAuthCredential.Entity";
import { apiGet, apiPost } from '../support/helpers/ApiConnection.Helper';

export function createVendorAuthCredential(newVendorAuthCredential: VendorAuthCredentialEntity, abortSignal: AbortSignal): Promise<VendorAuthCredentialEntity> {
    return apiPost<VendorAuthCredentialEntity>('/vendor-auth-credentials', newVendorAuthCredential, abortSignal);
}

export function getVendorAuthCredentials(vendorId: number, abortSignal: AbortSignal): Promise<Array<VendorAuthCredentialDataModel>> {
    return apiGet<Array<VendorAuthCredentialDataModel>>('/vendor-auth-credentials/authcredentials-by-vendor/' + vendorId, abortSignal);
}