import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    paper: {
        marginTop: '20px',
        //padding:'10px',
        paddingTop: '15px',
        paddingLeft: '35px',
        paddingRight: '35px',
        paddingBottom: '15px',
        marginBottom: '100px',
    },
    buttonsContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        position: 'fixed',
        bottom: '0',
        left: '0',
        right: '0',
        padding: '10px',
        backgroundColor: '#fff',
    },
    button: {
        color: 'white',
        backgroundColor: '#107A98',
        marginLeft: '20px',
        '&:hover': {
            backgroundColor: '#004763!important',
        },
    },
    cancelButton: {
        color: 'black',
        backgroundColor: '#e5e5e5',
        marginLeft: '40px',
        '&:hover': {
            backgroundColor: '#f0f0f0 !important',
        },
    },
    container: {
        marginTop: '20px',
    },
    paperTitle: {
        color: '#428302',
        marginBottom: '15px',
    },
    permissionTitle: {
        marginRight: '10px',
        marginLeft: '10px',
    },
    accessGridTitle: {
        paddingLeft: '15px',
    },
    gridContainer: {
        paddingLeft: '15px',
    },
    textfieldsContainer: {
        paddingTop: '15px',
        paddingBottom: '15px',
    },
}));
