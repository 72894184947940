import { Button, Paper } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { MaterialTableProps } from 'material-table';
import React, { FunctionComponent } from 'react';
import styles from './ServiceSetUp.Styles';
import MaterialTable from '../../generic-components/material-table/MaterialTable.Component';
import UserAdminTableDataModel from '../../../data-models/UserAdminTableDataModel';
import { Confirm } from '../../generic-components/confirm/Confirm.Component';
import UserEntity from '../../../entities/User.Entity';
import ProfileEntity from '../../../entities/Profile.Entity';
import VendorAdminTableDataModel from '../../../data-models/VendorAdminTable.DataModel';
import VendorServiceSetUpTableDataModel from '../../../data-models/VendorServiceSetUpTable.DataModel';
import StateEntity from '../../../entities/State.Entity';
import { AddServiceByVendor } from '../add-service-by-vendor/AddServiceByVendor.Component';
import TenantEntity from '../../../entities/Tenant.Entity';
import ServiceDataModel from '../../../data-models/Service.DataModel';
import NewVendorByServiceDataModel from '../../../data-models/NewVendorByService.DataModel';
import { Section } from '../../../support/custom-hooks/useBreadCrum';
import AccessControl from '../../generic-components/access-control/AccessControl.Component';
import { adminRules, serviceSetUpRules } from '../../../support/constants/SecurityRules.Constants';

interface ServiceSetUpProps {
    users: Array<UserAdminTableDataModel>;
    states: Array<StateEntity>;
    newUser: UserEntity;
    profiles: Array<ProfileEntity>;
    createUser: () => void;
    handleUserEdit: (newUser: UserEntity) => void;
    vendors: Array<VendorAdminTableDataModel>;
    vendorServiceSetUpTable: Array<VendorServiceSetUpTableDataModel>;
    newServiceByVendor: NewVendorByServiceDataModel;
    handleServiceByVendorEdit: (newServiceByVendor: NewVendorByServiceDataModel) => void;
    createServiceByVendor: () => void;
    services: Array<ServiceDataModel>;
    tenants: Array<TenantEntity>;
    handleVendorByServiceMultipleStateEdit: (edited: Array<number>, states: StateEntity[]) => void;
    handleMarkAsInactiveServiceByVendor: (rowIds: number[]) => void;
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean) => void;
    handleStartEditServiceByVendor: (tenantId: number, vendorId: number, serviceId: number, states: number[], serviceTypeIdsExcluded:number[]) => void;
    handleConfirmEditServiceByVendor: (
        newStateIds: number[],
        removedStateIds: number[],
        originalServiceId: number,
        serviceTypeIdsExcluded: number[],
    ) => void;
    handleGetServiceSetUp: () => Promise<void>;
}

export const ServiceSetUp: FunctionComponent<ServiceSetUpProps> = (props) => {
    const classes = styles();

    const [filter, setFilter] = React.useState<boolean>(false);
    const [showConfirmDelete, setShowConfirmDelete] = React.useState<boolean>(false);
    const [showAddService, setShowAddService] = React.useState<boolean>(false);
    const [selectedRow, setSelectedRow] = React.useState<number[]>(null);
    const [editMode, setEditMode] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        props.handleGetServiceSetUp();
    }, []);

    const clientsTableProps: MaterialTableProps<VendorServiceSetUpTableDataModel> = {
        columns: [
            {
                title: 'Tenants',
                field: 'tenantName',
                render: (rowData) => rowData?.tenantName,
            },
            {
                title: 'Vendors',
                field: 'vendorName',
                render: (rowData) => rowData?.vendorName,
            },
            {
                title: 'Service',
                field: 'serviceName',
                render: (rowData) => rowData?.serviceName,
            },
            {
                title: 'State',
                field: 'stateName',
                render: (rowData) => (rowData.stateId[0] == 0 ? 'All' : rowData?.stateName.join(', ')),
            },
        ],
        data: props.vendorServiceSetUpTable ? props.vendorServiceSetUpTable : [],
        options: {
            filtering: filter,
            showTitle: true,
            paging: props.vendorServiceSetUpTable ? props.vendorServiceSetUpTable.length > 10 : false,
            toolbar: true,
            actionsColumnIndex: -1,
            exportButton: true,
            exportFileName: 'TerraClaimConnect_Admin_ServiceByVendor',
            pageSizeOptions: [10, 25, 50],
            pageSize: 50,
            paginationPosition: 'top',
            emptyRowsWhenPaging: false
        },
        style: {
            paddingLeft: '35px',
            paddingRight: '35px',
        },
        actions: [
            {
                icon: 'tune',
                tooltip: 'Filter',
                isFreeAction: true,
                onClick: () => setFilter(!filter),
            },
            {
                icon: (props) => (
                    <Button {...props} className={classes.button}>
                        Add Service By Vendor
                    </Button>
                ),
                tooltip: 'Add Service By Vendor',
                position: 'toolbar',
                onClick: (event, rowData) => {
                    setShowAddService(true);
                    setEditMode(false);
                },
            },
            {
                icon: () => <EditIcon style={{ fill: 'rgba(0,0,0,0.54)' }} />,
                tooltip: 'Edit service',
                position: 'row',
                onClick: (event, rowData) => {
                    if (Array.isArray(rowData)) return;
                    props.handleStartEditServiceByVendor(
                        rowData.tenantId,
                        rowData.vendorId,
                        rowData.serviceId,
                        rowData.stateId,
                        rowData.serviceTypeIdsExcluded
                    );
                    setShowAddService(true);
                    setEditMode(true);
                },
            },
            {
                icon: () => <DeleteIcon style={{ fill: 'rgba(0,0,0,0.54)' }} />,
                tooltip: 'Delete service',
                position: 'row',
                onClick: (event, rowData) => {
                    if (Array.isArray(rowData)) return;
                    setSelectedRow(rowData.tenantPartnerVendorByStateId);
                    setShowConfirmDelete(true);
                    // props.handleMarkAsInactiveServiceByVendor(rowData.tenantPartnerVendorByStateId);
                },
            },
        ],
        title: 'Service by Vendor',
    };

    const addServiceByVendorPermissions = [...adminRules.create, ...serviceSetUpRules.create];

    return (
        <>
            <Paper className={classes.paper}>
                {showAddService ? (
                    <AccessControl allowedPermissions={addServiceByVendorPermissions} sendBack={false}>
                        <AddServiceByVendor
                            newServiceByVendor={props.newServiceByVendor}
                            handleServiceByVendorEdit={props.handleServiceByVendorEdit}
                            createServiceByVendor={props.createServiceByVendor}
                            services={props.services}
                            vendors={props.vendors}
                            tenants={props.tenants}
                            states={props.states}
                            cancel={() => setShowAddService(false)}
                            handleVendorByServiceMultipleStateEdit={props.handleVendorByServiceMultipleStateEdit}
                            handleSetValidationFunction={props.handleSetValidationFunction}
                            editMode={editMode}
                            handleConfirmEditServiceByVendor={props.handleConfirmEditServiceByVendor}
                        />
                    </AccessControl>
                ) : (
                    <MaterialTable tableProps={clientsTableProps} />
                )}
            </Paper>
            <Confirm
                onAction={() => {
                    props.handleMarkAsInactiveServiceByVendor(selectedRow);
                    setShowConfirmDelete(false);
                }}
                title="Delete Service"
                onToggleConfirm={() => {
                    setShowConfirmDelete(false);
                }}
                openDialog={showConfirmDelete}
                okText="Delete"
                noText="Cancel"
                messageText="Are you sure you want to delete this service?"
            />
        </>
    );
};
