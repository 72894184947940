import React, { forwardRef } from "react";
import clsx from "clsx";
import { styled } from '@material-ui/core/styles';

interface IProps {
  bold?: boolean;
  // eslint-disable-next-line no-unused-vars
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  blue?: boolean;
  grey?: boolean;
  disabled?: boolean;
  underline?: boolean;
  upper?: boolean;
  nowrap?: boolean;
  children?: React.ReactNode;
}

const PREFIX = "linkbutton";

const classes = {
  disabled: `${PREFIX}-disabled`,
  underline: `${PREFIX}-underline`
};

const ButtonStyled = styled("button")(() => ({
  background: "transparent",
  border: "none",
  padding: 0,
  font: "inherit",
  cursor: "pointer",
  outline: "inherit",
  textAlign: "inherit",
  fontWeight: "inherit",
  textDecoration: "inherit",
  textTransform: "inherit",
  "white-space": "wrap",
  color: "inherit",
  [`&.${classes.disabled}`]: { color: "rgba(0,0,0,0.36) !important", cursor: "initial" },
  [`&.${classes.underline}`]: { textDecoration: "underline" }
}));

const LinkButton = forwardRef<HTMLButtonElement, IProps>(
  ({ onClick, className, blue, grey, underline, bold, disabled, nowrap, upper, ...props }, ref) => {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      if (typeof onClick === "function") onClick(event);
    };
    return (
      <ButtonStyled
        {...{
          type: "button",
          onClick: handleClick,
          disabled,
          ref,
          ...props
        }}
        className={clsx(className, {
          "tcc-color-blue": blue,
          "tcc-color-gray": grey,
          [classes.disabled]: disabled,
          [classes.underline]: underline,
          "tcc-bold": bold,
          "tcc-nowrap": nowrap,
          "tcc-text-uppercase": upper
        })}
      />
    );
  }
);

export default LinkButton;
