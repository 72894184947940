export default class ProductInformationEntity {
  productInformationId: number;
  referralId: number;
  productCategoryId: number;
  productCategoryName: string;
  productId: number;
  productName: string;
  quantity: number;
  rentalPurchase: string;
  status: string;
  sentDate: Date;
  deliver: boolean;
  deliverySchedule: Date;
  deliveredDate: Date;
  trackingNumber: string;
  duration: number;
  returnedDate: Date;
  note: string;
  address : string;
  stateId: number;
  cityName: string;
  zipCode: string;
  addressLine2: string;
  fullAddress: string;
  deliveryLocation: string;/*Home,Hospital,Other*/
  requestDeliveryDate: Date;
}
