import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 320;

export default makeStyles((theme: Theme) =>
    createStyles({
        content: {
            [theme.breakpoints.up('sm')]: {
                paddingRight: drawerWidth,
            },
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        sidebarTitle: {
            color: '#428302',
            marginLeft: '5px',
        },
        gridContainer: {
            width: '100%',
        },
        paper: {
            paddingLeft: '25px',
            paddingRight: '25px',
            paddingBottom: '20px',
        },
        paperHeader: {
            padding: '0',
            flexDirection: 'row-reverse',
        },
        paperHeaderIcon: {
            color: '#428302',
        },
        textAreaGrid: {
            paddingLeft: '35px!important',
        },
        fullWidthInput: {
            width: '100%',
        },
        selectInput: {
            width: '100%',
        },
        singleRowField: {
            display: 'flex',
            alignItems: 'center',
        },
        singleRowRadioButtonGroup: {
            paddingLeft: '35px',
        },
        divider: {
            height: '1px',
            marginTop: 20,
            backgroundColor: '#707070',
        },
        button: {
            color: 'white',
            backgroundColor: '#107A98',
            marginLeft: '40px',
            paddingLeft: '40px',
            paddingRight: '40px',
            '&:hover': {
                backgroundColor: '#004763!important',
            },
        },
        errorText: {
            color: 'red',
        },
        verticallyCentered: {
            display: 'flex',
            alignItems: 'center',
          }
    }),
);
