import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import TransportationDataModel from '../../../../../data-models/Transportation.DataModel';
import { TransportationHeaderSummary } from './transportation-header-summary/TransportationHeaderSummary.Component';
import AppointmentTypeEntity from '../../../../../entities/AppointmentType.Entity';
import DestinationEntity from '../../../../../entities/Destination.Entity';
import OriginationEntity from '../../../../../entities/Origination.Entity';
import TransportationRequiredEntity from '../../../../../entities/TransportationRequired.Entity';
import TranslationDataModel from '../../../../../data-models/Translation.DataModel';
import LanguageEntity from '../../../../../entities/Language.Entity';
import { TranslationHeaderSummary } from './translation-header-summary/TranslationHeaderSummary.Component';
import InterpretationTypeEntity from '../../../../../entities/InterpretationType.Entity';
import { WheelchairOrStrecherDetailEntity } from '../../../../../entities/WheelchairOrStrecherDetail.Entity';
import ServiceTypeEntity from '../../../../../entities/ServiceType.Entity';
import StateEntity from '../../../../../entities/State.Entity';

type TtReferralRequestProps = {
    transportationServiceType: TransportationDataModel;
    appointmentTypes: Array<AppointmentTypeEntity>;
    transportationRequireds: Array<TransportationRequiredEntity>;
    originations: Array<OriginationEntity>;
    destinations: Array<DestinationEntity>;
    translationServiceType: TranslationDataModel;
    interpretationTypes: Array<InterpretationTypeEntity>;
    languages: Array<LanguageEntity>;
    wheelchairOrStretcherDetails: Array<WheelchairOrStrecherDetailEntity>;
    states: Array<StateEntity>;
};

export const TtReferralRequest: FunctionComponent<TtReferralRequestProps> = ({
    transportationServiceType,
    appointmentTypes,
    transportationRequireds,
    originations,
    destinations,
    translationServiceType,
    languages,
    interpretationTypes,
    wheelchairOrStretcherDetails,
    states,
}) => {
    return (
        <>
            {transportationServiceType?.serviceTypeRequestTransportation.serviceTypeRequestTransportationId && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TransportationHeaderSummary
                            serviceTypeRequestTransportation={
                                transportationServiceType?.serviceTypeRequestTransportation
                            }
                            appointmentTypes={appointmentTypes}
                            transportationRequireds={transportationRequireds}
                            originations={originations}
                            destinations={destinations}
                            wheelchairOrStretcherDetails={wheelchairOrStretcherDetails}
                            states={states}
                        />
                    </Grid>
                </Grid>
            )}
            {translationServiceType?.serviceTypeRequestTranslation.serviceTypeRequestTranslationId && (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TranslationHeaderSummary
                            serviceTypeRequestTranslation={translationServiceType?.serviceTypeRequestTranslation}
                            appointmentTypes={appointmentTypes}
                            interpretationTypes={interpretationTypes}
                            languages={languages}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    );
};
