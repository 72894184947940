import { FunctionComponent, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export interface PortalProps {
    elementId: string;
    children: React.ReactNode;
}

const Portal = ({ elementId, children }) => {
    const [domReady, setDomReady] = useState(false);
    useEffect(() => {
        setDomReady(true);
    }, []);

    if (!domReady) return null;
    if (!elementId) return children || null;

    const el = document.getElementById(elementId);

    return (el && createPortal(children, el)) || null;
};

export default Portal;
