import React, { FunctionComponent } from "react";
import styles from "./EmptyDataLabel.Styles";
import { Typography } from "@material-ui/core";

type EmptyDataLabelProps = {
    label?: string;
}

export const EmptyDataLabel: FunctionComponent<EmptyDataLabelProps> = ({label = 'No data to display'}: EmptyDataLabelProps) => {
    const classes = styles();

    return (
        <div className={classes.root}>
            <Typography variant='body2' className={classes.label}>{label}</Typography>
        </div>
    );
}
