import { Checkbox, Collapse, Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent, useEffect } from 'react';
import styles from './AccessControlSecurityRuleAccordion.Styles';
import RadioButtonGroup from '../../generic-components/radio-button-group/RadioButtonGroup.Component';
import { permissions } from '../access-control-security-profiles-add-edit/AccessControlSecurityProfilesAddEdit.Component';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SecurityRuleDataModel from '../../../data-models/admin/access-control/SecurityRule.DataModel';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

interface AccessControlSecurityRuleAccordionProps {
    rule: SecurityRuleDataModel;
    handleParentChangeRulePermission: (securityProfie: SecurityRuleDataModel, parentId: number) => void;
    changeParentRadioButton?: () => void;
    readOnly?: boolean;
}

export const AccessControlSecurityRuleAccordion: FunctionComponent<AccessControlSecurityRuleAccordionProps> = ({
    rule,
    handleParentChangeRulePermission,
    changeParentRadioButton,
    readOnly = false,
}) => {
    const classes = styles();
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const [radioButtonGroupValue, setRadioButtonGroupValue] = React.useState<'none' | 'full' | 'partial'>(null);

    const accessOptions = [
        { value: 'none', label: 'None', disabled: false },
        { value: 'full', label: 'Full', disabled: false },
        { value: 'partial', label: 'Partial', disabled: true },
    ];

    useEffect(() => {
        autoControlAccessRadioButtons();
    }, [rule, rule.children, rule.create, rule.update, rule.read, rule.delete]);

    const toggleOpen = () => {
        setExpanded(!expanded);
    };

    const handleChangeRulePermission = (ruleName: string, isParent: boolean, newValue: boolean = null) => {
        const newRule = { ...rule };
        newRule[ruleName] = newValue === null ? !newRule[ruleName] : newValue;

        if (isParent) {
            newRule.children.forEach((rule) => {
                rule[ruleName] = newValue === null ? !!newRule[ruleName] : newValue;
            });
        }

        handleParentChangeRulePermission(newRule, newRule.parentId);

        if (changeParentRadioButton !== undefined) {
            changeParentRadioButton();
        }
    };

    const massUpdateChildren = (rules: SecurityRuleDataModel[], value: boolean) => {
        for (let rule of rules) {
            rule.create = value;
            rule.update = value;
            rule.delete = value;
            rule.read = value;
            if (rule.children?.length > 0) {
                rule.children.forEach((childRule) => {
                    childRule.create = value;
                    childRule.update = value;
                    childRule.delete = value;
                    childRule.read = value;
                    if (childRule.children?.length > 0) {
                        massUpdateChildren(childRule.children, value);
                    }
                });
            }
        }
        return false;
    };

    const massUpadteRulePermissions = (newValue: boolean) => {
        const newRule = { ...rule };
        newRule.create = newValue;
        newRule.update = newValue;
        newRule.delete = newValue;
        newRule.read = newValue;

        massUpdateChildren(newRule.children, newValue);

        handleParentChangeRulePermission(newRule, newRule.parentId);

        if (changeParentRadioButton !== undefined) {
            changeParentRadioButton();
        }
    };

    const autoControlAccessRadioButtons = () => {
        let isFullAccess: boolean = false;

        if (rule.children.length > 0) {
            if (rule.children.every((r) => r.read && r.create && r.update && r.delete)) {
                isFullAccess = true;
            }
        } else {
            if (rule.read && rule.create && rule.update && rule.delete) {
                isFullAccess = true;
            }
        }

        if (isFullAccess) {
            return setRadioButtonGroupValue('full');
        }

        let isNoneAccess: boolean = false;
        if (rule.children.length > 0) {
            if (
                rule.children.every(
                    (r) => r.read === false && r.create === false && r.update === false && r.delete === false,
                )
            ) {
                isNoneAccess = true;
            }
        } else {
            if (rule.read === false && rule.create === false && rule.update === false && rule.delete === false) {
                isNoneAccess = true;
            }
        }

        if (isNoneAccess) {
            return setRadioButtonGroupValue('none');
        }

        return setRadioButtonGroupValue('partial');
    };

    const handleChangeRadioButtonGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (readOnly) return;
        if (event.target.value === 'none') {
            setRadioButtonGroupValue('none');
            permissions.forEach((p) => {
                massUpadteRulePermissions(false);
            });
        } else if (event.target.value === 'full') {
            setRadioButtonGroupValue('full');
            permissions.forEach((p) => {
                massUpadteRulePermissions(true);
            });
        }
    };

    return (
        <>
            <Grid container item xs={12}>
                <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    className={classes.gridContainer}
                    style={{ backgroundColor: rule.children.length > 0 ? '#eee' : '#fff' }}
                >
                    {rule.children.length > 0 ? (
                        <Grid item container xs={3} className={classes.sidebarTitle} onClick={toggleOpen}>
                            <Grid item className={classes.parentTitleIcon}>
                                <ExpandMore color="inherit" />
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle2">{rule.name}</Typography>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item xs={3}>
                            <Typography>{rule.name}</Typography>
                        </Grid>
                    )}

                    <Grid item xs={3}>
                        <RadioButtonGroup
                            value={radioButtonGroupValue}
                            labelPlacement="end"
                            onChange={(e) => {
                                handleChangeRadioButtonGroup(e);
                            }}
                            radioButtons={accessOptions}
                            readOnly={readOnly}
                        />
                    </Grid>
                    {permissions.map((p) => {
                        const value = rule[p.toLowerCase()];

                        return (
                            <Grid key={p + rule.securityRuleId + radioButtonGroupValue} item xs={1} justify="center">
                                {readOnly ? (
                                    <div> {value ? <CheckIcon /> : <CloseIcon />} </div>
                                ) : (
                                    <Checkbox
                                        color="primary"
                                        indeterminate={value === null}
                                        checked={value}
                                        onClick={() =>
                                            handleChangeRulePermission(p.toLowerCase(), rule.children.length > 0)
                                        }
                                    />
                                )}
                            </Grid>
                        );
                    })}
                </Grid>
                {rule.children.length > 0 && (
                    <Collapse
                        style={{
                            flexGrow: 1,
                        }}
                        in={expanded}
                    >
                        {rule.children.map((child) => {
                            return (
                                <AccessControlSecurityRuleAccordion
                                    key={child.securityRuleId}
                                    rule={child}
                                    handleParentChangeRulePermission={handleParentChangeRulePermission}
                                    changeParentRadioButton={autoControlAccessRadioButtons}
                                    readOnly={readOnly}
                                />
                            );
                        })}
                    </Collapse>
                )}
            </Grid>
        </>
    );
};
