import DiagnosticProcedureTableDataModel from "../data-models/DiagnosticProcedureTable.DataModel";
import ServiceTypeRequestDxDetailEntity from "./pt/ServiceTypeRequestDxDetailEntity.Entity";

export default class DiagnosticProcedureEntity{
    referralId: number;
    serviceId: number;
    serviceTypeName?: string;
    serviceTypeNumber: number;
    specificBodyPartId: number;
    bodySideId: number;
    diagnosisId: number;
    diagnosticProcedureDetailId: number;
    ageOfInjuryReview: boolean;

    // public constructor (serviceTypeNumber: number,serviceTypeName: string){
    //     this.serviceTypeNumber = serviceTypeNumber;
    //     this.serviceTypeName = serviceTypeName;
    // }
}