import { apiDelete, apiGet, apiPost, apiPut } from '../support/helpers/ApiConnection.Helper';
import ReferralContactEntity from '../entities/ReferralContact.Entity';
import ReferralsClaimsInfoEntity from '../entities/ReferralsClaimsInfo.Entity';
import ReferralEntity from '../entities/Referral.Entity';
import ReferralClaimEntity from '../entities/ReferralClaim.Entity';
import ImeDataModel from '../data-models/Ime.DataModel';
import ReferralDocumentDataModel from '../data-models/ReferralDocument.DataModel';
import ReferralDocumentEntity from '../entities/ReferralDocument.Entity';
import { ReferralOverviewDataModel } from '../data-models/ReferralOverview.DataModel';
import ReferralScheduleEntity from '../entities/ReferralSchedule.Entity';
import ReferralResponseDataModel from '../data-models/ReferralResponse.DataModel';
import { ServicesEnum } from '../support/enums/Services.Enum';
import { ReferralOverviewImeDataModel } from '../data-models/ReferralOverviewIme.DataModel';
import ImeScorecardResponseEntity from '../entities/ImeScorecardResponse.Entity';
import SubmissionEntity from '../entities/Submission.Entity';
import ReferralSubmitDataModel from '../data-models/ReferralSubmit.DataModel';
import IMECoverLetterEntity from '../entities/IMECoverLetter.Entity';
import MedicalServiceDataModel from '../data-models/MedicalService.DataModel';
import DiagnosticServiceDataModel from '../data-models/DiagnosticService.DataModel';
import DiagnosticProcedureResponseScorecardTabDataModel from '../data-models/DiagnosticProcedureResponseScorecardTabDataModel';
import ReferralErrorListDataModel from '../data-models/ReferralErrorList.DataModel';
import UtilizationReviewServiceDataModel from '../data-models/UtilizationReviewService.DataModel';
import TransportationDataModel from '../data-models/Transportation.DataModel';
import TranslationDataModel from '../data-models/Translation.DataModel';
import AdminReferralTableDataModel from '../data-models/AdminReferralTableDataModel';
import { ReferralSearchFilter } from '../components/tc-admin/referrals/Referrals.Component';
import AdminReferralSentCountDataModel from '../data-models/AdminReferralSentCountDataModel';
import AdminReferralSentCountByDateDataModel from '../data-models/AdminReferralSentCountByDate.DataModel';
import AdminReferralByDateAndServiceDataModel from '../data-models/AdminReferralByDateAndService.DataModel';
import AdminReferralVendorDocumentsTableDataModel from '../data-models/AdminReferralVendorDocumentsTable.DataModel';
import ReferralVendorDocumentDataModel from '../data-models/ReferralVendorDocument.DataModel';
import { TtDataModel } from '../data-models/Tt.DataModel';
import DmeServiceDataModel from '../data-models/dme/DmeService.DataModel';
import DmeResponseDataModel from '../data-models/dme/DmeResponse.DataModel';

export default function getReferrals(
    abortSignal: AbortSignal,
    claimId?: number,
    dateFrom?: string,
    dateTo?: string,
): Promise<Array<ReferralEntity>> {
    const url = '/referrals';

    let queryString = '?';

    if (claimId) {
        queryString += 'claimId=' + claimId;
    }

    if (dateFrom !== null && dateFrom !== undefined) {
        if (claimId) {
            queryString += '&';
        }
        queryString += `dateFrom=${dateFrom}`;
    }

    if (dateTo !== null && dateTo !== undefined) {
        if ((dateFrom !== null && dateFrom !== undefined) || claimId) {
            queryString += '&';
        }
        queryString += `dateTo=${dateTo}`;
    }

    return apiGet<Array<ReferralEntity>>(url + queryString, abortSignal);
}

export function getReferral(referralId: number, abortSignal: AbortSignal): Promise<ReferralEntity> {
    return apiGet<ReferralEntity>('/referrals/' + referralId, abortSignal);
}

export function sendReferralInitialData(
    claimId: number,
    serviceTypeIds: Array<number>,
    vendorId: number,
    abortSignal: AbortSignal,
): Promise<ReferralEntity> {
    const referral: ReferralEntity = { claimId: claimId, serviceTypeIds: serviceTypeIds, vendorId: vendorId };
    return apiPost<ReferralEntity>('/referrals', referral, abortSignal);
}

export function getReferralClaim(referralId: number, abortSignal: AbortSignal): Promise<ReferralClaimEntity> {
    return apiGet<ReferralClaimEntity>('/referrals/' + referralId + '/claims', abortSignal);
}

export function getReferralContact(
    referralId: number,
    referralContactTypeId: number,
    abortSignal: AbortSignal,
): Promise<ReferralContactEntity> {
    return apiGet<ReferralContactEntity>(
        '/referrals/' + referralId + '/referral-contacts',
        abortSignal,
        'referralContactTypeId=' + referralContactTypeId,
    );
}

export function GetRefreshedReferralContacts(
    referralId: number,
    referralContactTypeId: number,
    abortSignal: AbortSignal,
): Promise<ReferralsClaimsInfoEntity> {
    return apiGet<ReferralsClaimsInfoEntity>(
        '/referrals/' + referralId + '/claims-info',
        abortSignal,
        'referralContactTypeId=' + referralContactTypeId,
    );
}


export function getReferralSchedule(referralId: number, abortSignal: AbortSignal): Promise<ReferralScheduleEntity> {
    return apiGet<ReferralScheduleEntity>('/referrals/' + referralId + '/schedules', abortSignal);
}

export function getReferralDocument(
    referralId: number,
    abortSignal: AbortSignal,
): Promise<Array<ReferralDocumentEntity>> {
    return apiGet<Array<ReferralDocumentEntity>>('/referrals/' + referralId + '/documents', abortSignal);
}

export function getReferralIme(referralId: number, abortSignal: AbortSignal): Promise<ImeDataModel> {
    return apiGet<ImeDataModel>('/referrals/' + referralId + '/imes', abortSignal);
}

export function sendReferralDocuments(
    referralId: number,
    documentsBody: ReferralDocumentDataModel,
    abortSignal: AbortSignal,
): Promise<Array<ReferralDocumentEntity>> {
    return apiPost('/referrals/' + referralId + '/documents', documentsBody, abortSignal);
}

export function sendReferralSubmit(
    referralId: number,
    abortSignal: AbortSignal,
    isTt = false,
): Promise<ReferralSubmitDataModel> {
    let url = '/referrals/' + referralId + '/submit';
    if (isTt) {
        url += '/tt';
    }

    return apiPost(url, null, abortSignal, false);
}

export function getPermission(
    referralId: number,
    claimId: number,
    abortSignal: AbortSignal,
): Promise<ReferralOverviewDataModel> {
    return apiGet('/referrals/' + referralId + '/permission/' + claimId, abortSignal);
}

export function getOverview(referralId: number, abortSignal: AbortSignal): Promise<ReferralOverviewDataModel> {
    return apiGet('/referrals/' + referralId + '/info', abortSignal);
}

export function getOverviewIme(referralId: number, abortSignal: AbortSignal): Promise<ReferralOverviewImeDataModel> {
    return apiGet('/referrals/' + referralId + '/overview-ime', abortSignal);
}

export function getReferralResponse(
    referralId: number,
    abortSignal: AbortSignal,
    service: ServicesEnum | number,
): Promise<ReferralResponseDataModel> {
    return apiGet('/referrals/' + referralId + '/response', abortSignal, 'service=' + service);
}

export function getServiceTypeResponse<T>(
    referralId: number,
    abortSignal: AbortSignal,
    service: ServicesEnum | number,
): Promise<T> {
    return apiGet<T>('/referrals/' + referralId + '/response', abortSignal, 'service=' + service);
}

export function getImeScorecardResponse(
    referralId: number,
    abortSignal: AbortSignal,
): Promise<Array<ImeScorecardResponseEntity>> {
    return apiGet('/referrals/' + referralId + '/ime/scorecard-response', abortSignal);
}

export function getReferralSubmission(referralId: number, abortSignal: AbortSignal): Promise<any> {
    return apiGet('/referrals/' + referralId + '/referral-submission', abortSignal);
}

export function getReferralPhysicalTherapy(
    referralId: number,
    abortSignal: AbortSignal,
): Promise<MedicalServiceDataModel> {
    return apiGet('/referrals/' + referralId + '/physical-therapy', abortSignal);
}

export function getReferralDiagnostic(
    referralId: number,
    abortSignal: AbortSignal,
): Promise<DiagnosticServiceDataModel> {
    return apiGet('/referrals/' + referralId + '/diagnostic', abortSignal);
}

export function getReferralUr(
    referralId: number,
    abortSignal: AbortSignal,
): Promise<UtilizationReviewServiceDataModel> {
    return apiGet('/referrals/' + referralId + '/utilization-review', abortSignal);
}

export function getReferralTransportation(
    referralId: number,
    abortSignal: AbortSignal,
): Promise<TransportationDataModel> {
    return apiGet('/referrals/' + referralId + '/transportation', abortSignal);
}

export function getReferralTranslation(referralId: number, abortSignal: AbortSignal): Promise<TranslationDataModel> {
    return apiGet('/referrals/' + referralId + '/translation', abortSignal);
}

export function getReferralDme(referralId: number, abortSignal: AbortSignal): Promise<DmeServiceDataModel> {
    return apiGet('/referrals/' + referralId + '/dme', abortSignal);
}

export function getReferralDmeResponse(referralId: number, abortSignal: AbortSignal): Promise<DmeResponseDataModel> {
    return apiGet('/referrals/' + referralId + '/dme/response', abortSignal);
}

export function getReferralUrResponse(
    referralId: number,
    abortSignal: AbortSignal,
): Promise<UtilizationReviewServiceDataModel> {
    return apiGet('/referrals/' + referralId + '/utilization-review', abortSignal);
}

export function getImeCoverLetter(referralId: number, abortSignal: AbortSignal): Promise<IMECoverLetterEntity> {
    return apiGet('/referrals/' + referralId + '/cover-letter-ime', abortSignal);
}

export function getCoverLetter(referralId: number, abortSignal: AbortSignal): Promise<IMECoverLetterEntity> {
    return apiGet('/referrals/' + referralId + '/cover-letter', abortSignal);
}

export function sendSubmissionRequest(
    referralId: number,
    submission: SubmissionEntity,
    abortSignal: AbortSignal,
): Promise<SubmissionEntity> {
    return apiPost<SubmissionEntity>('/referrals/' + referralId + '/submission', submission, abortSignal);
}

export function sendReferralPT(
    referralId: number,
    physicalTherapyDataModel: MedicalServiceDataModel,
    abortSignal: AbortSignal,
): Promise<MedicalServiceDataModel> {
    return apiPost<MedicalServiceDataModel>(
        '/referrals/' + referralId + '/physical-therapy',
        physicalTherapyDataModel,
        abortSignal,
    );
}

export function sendReferralDX(
    referralId: number,
    diagnosticDataModel: DiagnosticServiceDataModel,
    abortSignal: AbortSignal,
): Promise<DiagnosticServiceDataModel> {
    return apiPost<DiagnosticServiceDataModel>(
        '/referrals/' + referralId + '/diagnostic',
        diagnosticDataModel,
        abortSignal,
    );
}

export function sendReferralUR(
    referralId: number,
    utilizationReviewDataModel: UtilizationReviewServiceDataModel,
    abortSignal: AbortSignal,
): Promise<UtilizationReviewServiceDataModel> {
    return apiPost<UtilizationReviewServiceDataModel>(
        '/referrals/' + referralId + '/utilization-review',
        utilizationReviewDataModel,
        abortSignal,
    );
}

export function sendReferralTt(
    referralId: number,
    translationDataModel: TranslationDataModel,
    transportationDataModel: TransportationDataModel,
    abortSignal: AbortSignal,
): Promise<TtDataModel> {
    return apiPost<TtDataModel>(
        '/referrals/' + referralId + '/tt',
        {
            translation: translationDataModel,
            transportation: transportationDataModel,
        },
        abortSignal,
    );
}

export function sendReferralDme(
    referralId: number,
    dmeDataModel: DmeServiceDataModel,
    abortSignal: AbortSignal,
): Promise<DmeServiceDataModel> {
    return apiPost<DmeServiceDataModel>('/referrals/' + referralId + '/dme', dmeDataModel, abortSignal);
}

export function deleteReferral(referralId: number, abortSignal: AbortSignal): Promise<ReferralEntity> {
    return apiDelete<ReferralEntity>('/referrals', referralId, abortSignal);
}

export function getDiagnosticProcedureResponseScorecard(
    referralId: number,
    abortSignal: AbortSignal,
): Promise<Array<DiagnosticProcedureResponseScorecardTabDataModel>> {
    return apiGet<Array<DiagnosticProcedureResponseScorecardTabDataModel>>(
        '/referrals/' + referralId + '/dxs/scorecard-response',
        abortSignal,
    );
}

export function getFailedReferrals(abortSignal: AbortSignal): Promise<Array<ReferralErrorListDataModel>> {
    return apiGet<Array<ReferralErrorListDataModel>>('/referrals/admin/failed', abortSignal);
}

export function getReferralsSentCount(
    referralId: number,
    abortSignal: AbortSignal,
): Promise<Array<AdminReferralSentCountDataModel>> {
    return apiGet<Array<AdminReferralSentCountDataModel>>(
        `/referrals/admin/table/${referralId}/sent-count`,
        abortSignal,
    );
}
export function getReferralsByDateAndService(
    serviceId: number,
    dateRangeFrom: string,
    dateRangeTo: string,
    abortSignal: AbortSignal,
): Promise<Array<AdminReferralByDateAndServiceDataModel>> {
    return apiGet<Array<AdminReferralByDateAndServiceDataModel>>(
        `/referrals/admin/count-by-date/${serviceId}?dateRangeFrom=${dateRangeFrom}&dateRangeTo=${dateRangeTo}`,
        abortSignal,
    );
}

export function updateReferralStatus(
    referralId: number,
    referralStatusId: number,
    abortSignal: AbortSignal,
): Promise<number> {
    return apiPut<number>('/referrals/status', referralId, referralStatusId, abortSignal);
}

const getQueryString = ({ tenantId, vendorId, dateRangeFrom, dateRangeTo }: ReferralSearchFilter) => {
    let queryString = '';

    if (tenantId) {
        queryString += 'tenantId=' + tenantId;
    }

    if (vendorId) {
        queryString += '&vendorId=' + vendorId;
    }

    if (dateRangeFrom) {
        queryString += '&dateRangeFrom=' + dateRangeFrom;
    }

    if (dateRangeTo) {
        queryString += '&dateRangeTo=' + dateRangeTo;
    }

    return queryString;
};

export function getReferralsTable(
    { tenantId, vendorId, dateRangeFrom, dateRangeTo }: ReferralSearchFilter,
    abortSignal: AbortSignal,
): Promise<Array<AdminReferralTableDataModel>> {
    const queryString = getQueryString({ tenantId, vendorId, dateRangeFrom, dateRangeTo });
    return apiGet<Array<AdminReferralTableDataModel>>(`/referrals/admin/table?${queryString}`, abortSignal);
}

export function getReferralsCountByDateTable(
    { tenantId, vendorId, dateRangeFrom, dateRangeTo }: ReferralSearchFilter,
    abortSignal: AbortSignal,
): Promise<Array<AdminReferralSentCountByDateDataModel>> {
    const queryString = getQueryString({ tenantId, vendorId, dateRangeFrom, dateRangeTo });
    return apiGet<Array<AdminReferralSentCountByDateDataModel>>(
        `/referrals/admin/count-by-date?${queryString}`,
        abortSignal,
    );
}

export function getReferralsVendorDocumentsTable(
    { tenantId, vendorId, dateRangeFrom, dateRangeTo }: ReferralSearchFilter,
    abortSignal: AbortSignal,
): Promise<Array<AdminReferralVendorDocumentsTableDataModel>> {
    const queryString = getQueryString({ tenantId, vendorId, dateRangeFrom, dateRangeTo });
    return apiGet<Array<AdminReferralVendorDocumentsTableDataModel>>(
        `/referrals/admin/vendor-documents-table?${queryString}`,
        abortSignal,
    );
}

export function getReferralsVendorDocumentsList(
    referralId: number,
    abortSignal: AbortSignal,
): Promise<Array<ReferralVendorDocumentDataModel>> {
    return apiGet<Array<ReferralVendorDocumentDataModel>>(
        '/referrals/admin/' + referralId + '/vendor-documents-list',
        abortSignal,
    );
}

export function GetSearchAddress(
    partialAddress: string,
    abortSignal: AbortSignal,
): Promise<string> {
    return apiGet<string>(
        '/search-address?query=' + partialAddress,
        abortSignal,
    );
}