import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import { MaterialTableProps } from 'material-table';
import React, { FunctionComponent, useEffect } from 'react';
import styles from './Referrals.Styles';
import MaterialTable from '../../generic-components/material-table/MaterialTable.Component';
import AdminReferralTableDataModel from '../../../data-models/AdminReferralTableDataModel';
import TenantEntity from '../../../entities/Tenant.Entity';
import Moment from 'moment';
import VendorAdminTableDataModel from '../../../data-models/VendorAdminTable.DataModel';
import { DialogCustom } from '../../generic-components/dialog-custom/DialogCustom.Component';
import { ReferralsSentCount } from '../referrals-sent-count/ReferralsSentCount.Component';
import { Loader } from '../../generic-components/loader/Loader.Component';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import FileDownload from '@material-ui/icons/GetApp';

interface ReferralsProps {
    referrals: Array<AdminReferralTableDataModel>;
    tenants: Array<TenantEntity>;
    vendors: Array<VendorAdminTableDataModel>;
    handleGetReferralsTable: (filters: ReferralSearchFilter) => void;
    isLoading: boolean;
}

export interface ReferralSearchFilter {
    tenantId: number;
    vendorId: number;
    dateRangeFrom?: string;
    dateRangeTo?: string;
}

export const Referrals: FunctionComponent<ReferralsProps> = (props) => {
    const classes = styles();
    const [filter, setFilter] = React.useState<boolean>(false);
    const [referralSearchFilter, setReferralSearchFilter] = React.useState<ReferralSearchFilter>({
        tenantId: null,
        vendorId: null,
        dateRangeFrom: null,
        dateRangeTo: Moment(new Date()).format('YYYY-MM-DD'),
    });
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [referralId, setReferralId] = React.useState<number>(null);

    useEffect(() => {
        let dateRangeFrom = new Date();
        dateRangeFrom.setDate(dateRangeFrom.getDate() - 7);

        setReferralSearchFilter({ ...referralSearchFilter, dateRangeFrom: Moment(dateRangeFrom).format('YYYY-MM-DD') });
    }, []);

    const clientsTableProps: MaterialTableProps<AdminReferralTableDataModel> = {
        columns: [
            {
                title: 'Created Date',
                field: 'createdDate',
            },
            {
                title: 'Referral Id',
                field: 'referralId',
            },
            {
                title: 'Claim Number',
                field: 'claimNumber',
            },
            {
                title: 'Claim Id',
                field: 'claimId',
            },
            {
                title: 'Adjuster',
                field: 'adjuster',
            },
            {
                title: 'Submitted Date',
                field: 'submittedDate',
            },
            {
                title: 'Sent Count',
                field: 'sentCount',
                render: (rowData) => (
                    <div
                        className={classes.link}
                        onClick={() => {
                            setOpenDialog(true);
                            setReferralId(rowData.referralId);
                        }}
                    >
                        {rowData.sentCount} attempts
                    </div>
                ),
            },
            {
                title: 'Tenant',
                field: 'tenantName',
            },
            {
                title: 'Vendor',
                field: 'vendorName',
            },
            {
                title: 'Referral Status Date',
                field: 'referralStatusDate',
            },
            {
                title: 'Referral Status',
                field: 'referralStatus',
            },
            {
                title: 'IME Schedule Status',
                field: 'scheduleStatus',
            },
            {
                title: 'Vendor Docs',
                field: 'vendorDocs',
            },
        ],
        data: props.referrals ?? [],
        options: {
            filtering: filter,
            showTitle: true,
            paging: props.referrals ? props.referrals.length > 10 : false,
            toolbar: true,
            exportButton: false,
            exportFileName: 'TerraClaimConnect_Admin_ReferralsList',
            pageSizeOptions: [10, 25, 50],
            pageSize: 50,
            paginationPosition: 'top',
            emptyRowsWhenPaging: false
        },
        actions: [
            {
                icon: () => <FileDownload />,
                tooltip: 'Export as CSV',
                isFreeAction: true,
                onClick: (event, rowData) => exportToCSV()
              },
              {
                icon: 'picture_as_pdf',
                tooltip: 'Export as PDF',
                isFreeAction: true,
                onClick: (event, rowData) => exportToPDF()
              },
              {
                icon: 'tune',
                tooltip: 'Filter',
                isFreeAction: true,
                onClick: () => setFilter(!filter)
            }
        ],
        title: 'Referral List'
    };

    const exportToCSV = () => {
        const fileName = 'TerraClaimConnect_Admin_ReferralsList.csv';
        let csvContent = "data:text/csv;charset=utf-8,";
    
        const customColumns = clientsTableProps.columns;
        customColumns.push({
            title: 'Referral Status Details',
            field: 'referralStatusDetails',
        })
        // Add the column titles
        const headers = customColumns.map(column => column.title).join(',');
        csvContent += headers + "\r\n";

        // Add the data rows
        props.referrals?.forEach(referral => {
            const row = customColumns.map(column => {
                const value = referral[column.field];
                return `"${value}"`; // Wrap the values in quotes to handle commas and line breaks.
            }).join(',');
            csvContent += row + "\r\n";
        });
    
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportToPDF = () => {
        const doc = new jsPDF('l', 'mm', 'a4'); //to make pdf landscape 
        const customColumns = clientsTableProps.columns;
        customColumns.push({
            title: 'Referral Status Details',
            field: 'referralStatusDetails',
        })
        const tableColumn = customColumns.map(column => column.title);
        const tableRows = props.referrals?.map(referral => 
            customColumns.map(column => referral[column.field]?.toString() || '')
        );
      
        doc.autoTable({
          head: [tableColumn],
          body: tableRows,
        });
        
        doc.save("TerraClaimConnect_Admin_ReferralsList.pdf");
    };
    

    const handleTextFieldChange = (
        event:
            | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            | React.ChangeEvent<{ name?: string; value: unknown; id?: string }>,
    ) => {
        event.persist();
        let newFilter: ReferralSearchFilter = { ...referralSearchFilter };
        newFilter[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;
        setReferralSearchFilter(newFilter);
    };
    

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={3}>
                    <FormControl className={classes.selectInput}>
                        <InputLabel id="demo-simple-select-label">Tenant</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="tenantId"
                            name="tenantId"
                            value={referralSearchFilter.tenantId}
                            onChange={(event) => handleTextFieldChange(event)}
                        >
                            {props.tenants?.map((tenant, index) => {
                                return (
                                    <MenuItem key={tenant.tenantId} value={tenant.tenantId}>
                                        {tenant.tenantName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className={classes.selectInput}>
                        <InputLabel id="demo-simple-select-label">Vendor</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="vendorId"
                            name="vendorId"
                            value={referralSearchFilter.vendorId}
                            onChange={(event) => handleTextFieldChange(event)}
                        >
                            {props.vendors?.map((vendor, index) => {
                                return (
                                    <MenuItem key={vendor.vendorId} value={vendor.vendorId}>
                                        {vendor.vendorName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Submitted Date Range From"
                        id="dateRangeFrom"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => handleTextFieldChange(event)}
                        value={Moment(referralSearchFilter.dateRangeFrom).format('YYYY-MM-DD')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Submitted Date Range To"
                        id="dateRangeTo"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => handleTextFieldChange(event)}
                        value={Moment(referralSearchFilter.dateRangeTo).format('YYYY-MM-DD')}
                    />
                </Grid>
                <Button className={classes.button} variant="contained" onClick={() => props.handleGetReferralsTable(referralSearchFilter)}>
                    Get Referrals
                </Button>
            </Grid>
            {props.isLoading ? <Loader isSmall /> : <MaterialTable tableProps={clientsTableProps} />}
            <DialogCustom
                open={openDialog}
                closeHandler={() => setOpenDialog(false)}
                acceptHandler={() => setOpenDialog(false)}
                acceptButtonText="Ok"
                isFullScreen
            >
                <ReferralsSentCount referralId={referralId} />
            </DialogCustom>
        </Paper>
    );
};
