import PostSecurityProfileDataModel from '../data-models/admin/access-control/PostSecurityProfile.DataModel';
import SecurityProfileDataModel from '../data-models/admin/access-control/SecurityProfile.DataModel';
import SecurityProfileList from '../data-models/admin/access-control/SecurityProfileList.DataModel';
import { apiDelete, apiGet, apiPost, apiPut } from '../support/helpers/ApiConnection.Helper';

export function getAllSecurityProfiles(abortSignal: AbortSignal): Promise<Array<SecurityProfileList>> {
    return apiGet<Array<SecurityProfileList>>('/security-profile', abortSignal);
}

export function getSecurityProfileById(id: number, abortSignal: AbortSignal): Promise<SecurityProfileDataModel> {
    return apiGet<SecurityProfileDataModel>(`/security-profile/profile/${id}`, abortSignal);
}

export function getNewSecurityProfile(abortSignal: AbortSignal): Promise<SecurityProfileDataModel> {
    return apiGet<SecurityProfileDataModel>(`/security-profile/new-security-profile`, abortSignal);
}

export function editSecurityProfile(
    id: number,
    securityProfile: PostSecurityProfileDataModel,
    abortSignal: AbortSignal,
): Promise<PostSecurityProfileDataModel> {
    return apiPut<PostSecurityProfileDataModel>(`/security-profile/update`, id, securityProfile, abortSignal);
}

export function addSecurityProfile(
    securityProfile: PostSecurityProfileDataModel,
    abortSignal: AbortSignal,
): Promise<PostSecurityProfileDataModel> {
    return apiPost<PostSecurityProfileDataModel>(
        `/security-profile/new-security-profile`,
        securityProfile,
        abortSignal,
    );
}

export function deleteSecurityProfile(
    securityProfileId: number,
    abortSignal: AbortSignal,
): Promise<PostSecurityProfileDataModel> {
    return apiDelete<PostSecurityProfileDataModel>(`/security-profile`, securityProfileId, abortSignal);
}
