import { createTheme } from '@material-ui/core/styles';

export default createTheme({
    palette: {
        background: { default: '#E7EEF1' },
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#107A98',
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            // light: will be calculated from palette.primary.main,
            main: '#004763',
            // dark: will be calculated from palette.secondary.main,
            contrastText: '#ffcc00',
        },
    },
    overrides: {
        MuiIcon: {
            root: {
                color: '#898F91'
            }
        },
    },
});
