import { createTheme , makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    gridContainer: {
        width: '100%',
    },
    paper: {
        paddingTop: '15px',
        paddingLeft: '35px',
        paddingRight: '35px',
        paddingBottom: '25px',
    },
    dataLabel: {
        color: '#898F91',
        fontSize: '12px'
    },
    iconTextRow: {
        display: 'inline-flex',
        flexDirection: 'row'
    },
    blackFont: {
        color: '#000000'
    },
    iconData: {
        color: '#000000',
        marginLeft: 10
    },
    paperHeader: {
        flexDirection: 'row-reverse'
    },
    paperTitle: {
        color: '#428302',
    },
    summaryTextArea: {
        width: '100%'
    },
    stoppedVisitsReason: {
        width: '90%'
    },
    gridInfoText: {
        paddingTop: '1rem',
    },
}));

export const referralTranslationSummaryTheme = createTheme ({
    overrides: {
        MuiInputBase: {
            root: {
                '&$disabled': {
                    color: '#000000',
                    fontSize: '0.875rem',
                    fontWeight: '500'
                },
            },
        },
        MuiFormLabel: {
            root: {
                '&$disabled': {
                    color: '#898F91',
                },
            }
        }
    },
});