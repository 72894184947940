import React, { FunctionComponent, useState, useEffect } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary } from '@material-ui/core';
import styles from "./MsScorecardSummary.Styles";
import Moment from 'moment';
import { EmptyDataLabel } from '../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import ScorecardSectionDataModel from '../../../../../data-models/pt/ScorecardSection.DataModel';
import { ServicesEnum } from '../../../../../support/enums/Services.Enum';
import ServiceTypeScorecardResponseEntity from '../../../../../entities/pt/ServiceTypeScorecardResponse.Entity';
import ScorecardResponsesChartContentDataModel from '../../../../../data-models/pt/ScorecardResponsesChartContentDataModel';

interface IMsScorecardSummaryProps {
    // medicalService: MedicalServiceDataModel,
    scorecardResponsesChartContent: Array<ScorecardResponsesChartContentDataModel>;
    scorecardResponse: Array<ServiceTypeScorecardResponseEntity>;
    scorecardSections: Array<ScorecardSectionDataModel>,
    serviceId: number,
}

export const MsScorecardSummary: FunctionComponent<IMsScorecardSummaryProps> = (props: IMsScorecardSummaryProps) => {
    const classes = styles();

    const[lastScorecard,setLastScorecard] = useState(null);

    useEffect(()=>{
        setLastScorecard(props.scorecardResponsesChartContent ? props.scorecardResponsesChartContent[0] : null);
    },[props]);

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                {(props.serviceId == ServicesEnum.PT) && <Typography variant="subtitle2" className={classes.paperTitle}>Latest Scorecard Summary</Typography>}
                {props.serviceId == ServicesEnum.Diagnostic && <Typography variant="subtitle2" className={classes.paperTitle}>Scorecard Summary</Typography>}
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.scorecardResponse && props.scorecardSections ? 
                        <>
                            {
                                props.serviceId == ServicesEnum.PT &&
                                    <Grid container spacing={3} className={classes.gridContainer}>
                                        <Grid item xs={3}>
                                            <Typography variant="body2" className={classes.dataLabel}>Scorecard Submitted</Typography>
                                            <Typography variant="subtitle2" className={classes.blackFont}>{props.scorecardResponse?.length > 0 ? 'Yes - ' + Moment(props.scorecardResponse?.sort((a, b) => +new Date(a.createdDate) - +new Date(b.createdDate))[0]?.createdDate).format('MM/DD/YYYY') : 'No'}</Typography>
                                        </Grid>
                                        {
                                            props.scorecardSections?.map(scorecardSection => (
                                                <Grid item xs={3}>
                                                    <Typography variant="body2" className={classes.dataLabel}>{scorecardSection.description}</Typography>
                                                    <Typography variant="subtitle2" className={classes.blackFont}>{lastScorecard?.scorecardResponses?.filter(x => x.scorecardSectionId == scorecardSection.scorecardSectionId)[0]?.value ? lastScorecard?.scorecardResponses?.filter(x => x.scorecardSectionId == scorecardSection.scorecardSectionId)[0]?.value : scorecardSection.options?.filter(option => option.scorecardOptionId == lastScorecard?.scorecardResponses?.filter(x => x.scorecardSectionId == scorecardSection.scorecardSectionId)[0]?.scorecardOptionId)[0]?.value ? scorecardSection.options?.filter(option => option.scorecardOptionId == lastScorecard?.scorecardResponses?.filter(x => x.scorecardSectionId == scorecardSection.scorecardSectionId)[0]?.scorecardOptionId)[0]?.value : '-'}</Typography>
                                                </Grid>
                                            )) 
                                        }
                                    </Grid>
                            }
                            {
                                props.serviceId == ServicesEnum.Diagnostic &&
                                    <Grid container spacing={3} className={classes.gridContainer}>
                                        <Grid item xs={3}>
                                            <Typography variant="body2" className={classes.dataLabel}>Scorecard Submitted</Typography>
                                            <Typography variant="subtitle2" className={classes.blackFont}>{props.scorecardResponse?.length > 0 ? 'Yes - ' + Moment(props.scorecardResponse?.sort((a, b) => +new Date(a.createdDate) - +new Date(b.createdDate))[0]?.createdDate).format('MM/DD/YYYY') : 'No'}</Typography>
                                        </Grid>
                                        {
                                            props.scorecardSections?.map(scorecardSection => (
                                                <Grid item xs={3}>
                                                    <Typography variant="body2" className={classes.dataLabel}>{scorecardSection.description}</Typography>
                                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.scorecardResponse?.filter(x => x.scorecardSectionId == scorecardSection.scorecardSectionId)[0]?.value ? props.scorecardResponse?.filter(x => x.scorecardSectionId == scorecardSection.scorecardSectionId)[0]?.value : scorecardSection.options?.filter(option => option.scorecardOptionId == props.scorecardResponse?.filter(x => x.scorecardSectionId == scorecardSection.scorecardSectionId)[0]?.scorecardOptionId)[0]?.value ? scorecardSection.options?.filter(option => option.scorecardOptionId == props.scorecardResponse?.filter(x => x.scorecardSectionId == scorecardSection.scorecardSectionId)[0]?.scorecardOptionId)[0]?.value : '-'}</Typography>
                                                </Grid>
                                            )) 
                                        }
                                    </Grid>
                            }
                        </>
                        :
                        <EmptyDataLabel/> 
                }
                
            </AccordionDetails>
        </Accordion>
    );
}