import { createStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 320;
const contentHorizontalPadding = 25;
export default (theme: Theme) =>
    createStyles({
        content: {
            paddingLeft: `${contentHorizontalPadding}px`,
            paddingRight: `${contentHorizontalPadding}px`,
            paddingTop: '15px',
            // width: `calc(100vw - ${drawerWidth}px - ${contentHorizontalPadding}px)`,
        },
        referralHeaderContainer: {
            display: 'flex',
            marginBottom: '15px',
        },
        referralHeader: {
            color: '#648C9B',
        },
        referralNumber: {
            marginLeft: '5px',
            fontWeight: 'bold',
            color: '#004763',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        sidebarTitle: {
            color: '#428302',
            marginLeft: '5px',
        },
        gridContainer: {
            width: '100%',
        },
        paper: {
            paddingLeft: '25px',
            paddingRight: '25px',
            paddingBottom: '20px',
        },
        paperHeader: {
            padding: '0',
            flexDirection: 'row-reverse',
        },
        paperHeaderIcon: {
            color: '#428302',
        },
        textAreaGrid: {
            paddingLeft: '35px!important',
        },
        fullWidthInput: {
            width: '100%',
        },
        selectInput: {
            width: '100%',
        },
    });
