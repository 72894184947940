import React, { Fragment, Component } from 'react';
import {
    AdjustersReferralList,
    IReferralEntityTable,
} from '../../components/referral-components/referral-list/adjusters-referral-list/AdjustersReferralList.Component';
import styles from './ClaimReferrals.Styles';
import { ClaimSidebar } from '../../components/claim-components/claim-sidebar/ClaimSidebar.Component';
import ClaimDataModel from '../../data-models/Claim.DataModel';
import { getClaim } from '../../services/Claim.Service';
import { Grid, WithStyles, withStyles } from '@material-ui/core';
import { Loader } from '../../components/generic-components/loader/Loader.Component';
import getReferrals, { deleteReferral } from '../../services/Referrals.Service';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import ReferralStatusEntity from '../../entities/ReferralStatus.Entity';
import getReferralStatuses from '../../services/ReferralStatuses.Service';
import ReferralEntity from '../../entities/Referral.Entity';
import { toast } from 'react-toastify';
import { BreadCrumb, Section } from '../../support/custom-hooks/useBreadCrum';

type ClaimReferralsState = {
    claim: ClaimDataModel;
    loading: boolean;
    referrals: Array<IReferralEntityTable>;
    referralStatus: Array<ReferralStatusEntity>;
    claimId: number;
    claimNumber: string;
    referralListBreadCrumb: Section[];
};

type PathParamsType = {
    id: string;
};

type ComponentProps = {
    handleId?: (id: number) => void;
};

type ClaimReferralsProps = WithStyles<typeof styles> & RouteComponentProps<PathParamsType> & ComponentProps;

const getTableReferrals = (referrals: ReferralEntity[]) => {
    return referrals.map((referral) => {
        const tableReferral: IReferralEntityTable = {
            ...referral,
            joinedServiceTypeNames: referral.serviceTypeNames.join(', '),
        };

        return tableReferral;
    });
};

class ClaimReferralsComponent extends Component<ClaimReferralsProps, ClaimReferralsState, ComponentProps> {
    constructor(props: ClaimReferralsProps) {
        super(props);

        this.state = {
            claim: null,
            loading: false,
            referrals: [],
            referralStatus: [],
            claimId: null,
            claimNumber: null,
            referralListBreadCrumb: [],
        };
    }
    handleNewReferralClick = () => {
        this.props.history.push('/claims/' + this.props.match.params.id + '/referrals/add');
    };
    abortController = new AbortController();

    componentDidMount() {
        this.setState({
            loading: true,
        });
        getClaim(+this.props.match.params.id, this.abortController.signal)
            .then((claimResult) => {
                if (this.props.handleId !== null) {
                    this.props.handleId(claimResult?.claim?.claimId);
                }
                getReferrals(this.abortController.signal, claimResult.claim.claimId)
                    .then((referralsResult) => {
                        getReferralStatuses(this.abortController.signal)
                            .then((referralStatusResult) => {
                                const referrals = getTableReferrals(referralsResult);

                                this.setState({
                                    referralStatus: referralStatusResult,
                                    referrals: referrals,
                                    claim: claimResult,
                                    claimNumber: claimResult.claim?.claimNumber,
                                    referralListBreadCrumb: [
                                        {
                                            name: 'Claims',
                                            goBack: false,
                                            link: '/claims',
                                            path: this.props.location.pathname,
                                        },
                                        {
                                            name: `${claimResult.claim?.claimNumber}: Referral List`,
                                            goBack: false,
                                            link: `/claim/${claimResult?.claim?.claimId}`,
                                            path: this.props.location.pathname,
                                        },
                                    ],
                                    loading: false,
                                });
                            })
                            .catch((err) => {
                                this.setState({
                                    loading: false,
                                });
                            });
                    })
                    .catch((err) => {
                        this.setState({
                            loading: false,
                        });
                    });
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                });
            });
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    handleDeleteReferral = (referralId: number) => {
        deleteReferral(referralId, this.abortController.signal)
            .then(() => {
                toast.success(`Referral ${referralId} deleted successfully.`);
                this.setState({
                    referrals: this.state.referrals.filter((referral) => referral.referralId !== referralId),
                });
            })
            .catch((err) => {});
    };

    render() {
        const { classes } = this.props;

        return (
            <Route path="/claims/:id" exact>
                <Fragment>
                    {this.state.loading ? (
                        <Loader />
                    ) : (
                        <Fragment>
                            <BreadCrumb sections={this.state.referralListBreadCrumb} />
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <ClaimSidebar
                                        claimDataModel={this.state.claim}
                                        referral={null}
                                        employerName={null}
                                        vendorName={null}
                                        serviceTypeName={null}
                                        referralStatus={null}
                                        side={false}
                                    ></ClaimSidebar>
                                </Grid>
                            </Grid>
                            <div className={classes.content}>
                                <Fragment>
                                    <AdjustersReferralList
                                        handleDeleteReferral={this.handleDeleteReferral}
                                        referralStatus={this.state.referralStatus}
                                        referrals={this.state.referrals}
                                        handleNewReferralClick={this.handleNewReferralClick}
                                    />
                                </Fragment>
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            </Route>
        );
    }
}

export const ClaimReferrals = withRouter(withStyles(styles)(ClaimReferralsComponent));
