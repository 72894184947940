import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 100;
const tablePadding = 35;

export default makeStyles((theme: Theme) => ({
    paper:{
        marginTop:'20px',
        marginBottom:'20px',
        width: `calc(100vw - ${drawerWidth + (tablePadding * 2)}px) !important`
    },
    button:{
        color:'white',
        backgroundColor:'#107A98',
        marginLeft:'40px',
        '&:hover': {
            backgroundColor: '#004763!important',
        },
        position:'absolute',
        bottom:'0px',
        right:'40px',
    },
    gridContainer:{
        position:'relative',
        marginBottom:'20px',
        paddingBottom:'60px',
        paddingTop:'20px',
        paddingLeft: '30px',
        paddingRight: '30px',
    },
    selectInput: {
      width:'100%',
    },
    link:{
        color:'#107A98',
        cursor:'pointer',
    }
}));