import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";

import React, { FunctionComponent } from "react";
import styles from './AddClient.Styles';
import ClientEntity from "../../../entities/Client.Entity";
import StateEntity from "../../../entities/State.Entity";
import TenantEntity from "../../../entities/Tenant.Entity";
import UserEntity from "../../../entities/User.Entity";
import { UserRolesEnum } from "../../../support/enums/UserRoles.Enum";

interface AddClientProps{
    newClient: ClientEntity,
    handleClientEdit: (newClient: ClientEntity) => void;
    states: Array<StateEntity>;
    createClient : () => void;
    cancel: () => void;
    tenants: Array<TenantEntity>;
    userProfile: UserEntity;
}

export const AddClient: FunctionComponent<AddClientProps> = (props) => {
    const classes = styles();
    const abortController = new AbortController();

    const [vendorState, setVendorState] = React.useState(props.newClient);

    const [isTenantAdmin, setIsTenantAdmin] = React.useState<boolean>(false);

    React.useEffect(() => {
        let newClient = props.newClient;
        if(!newClient){
            newClient = {} as ClientEntity;
            if(isTenantAdmin){
                newClient.tenantId = props.userProfile?.tenantId;
            }
            props.handleClientEdit(newClient);
        }

        setVendorState(newClient);

        if (props.userProfile?.userRoles?.findIndex(x => x == UserRolesEnum.TenantAdmin) != -1){
            setIsTenantAdmin(true);
        }

        return function cleanup() {
            abortController.abort();
        }
    }, [props]);

    const handleTextFieldChange = (event: 
        React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        |
        React.ChangeEvent<{name?: string; value: unknown; id?:string;}>
    ) => {
        event.persist();
        let newClient : ClientEntity = vendorState;
        if(isTenantAdmin){
            newClient.tenantId = props.userProfile?.tenantId;
        }
        newClient[event.target.id ? event.target.id : event.target.name] = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value;
        setVendorState(newClient);
        props.handleClientEdit(newClient);
    }
    
    const handleCancel = () => {
        let newClient = {} as ClientEntity;
        props.handleClientEdit(newClient);
        props.cancel();
    }

    if(!vendorState) return null;

    return <form noValidate autoComplete="off" className={classes.formContainer} >
        
        <Typography variant="subtitle2" className={classes.paperTitle}>Basic information</Typography>
        <Grid container spacing={3} className={classes.gridContainer} >
            <Grid item xs={3}>
                <TextField 
                    label='Name' 
                    id='clientName'
                    value={props.newClient.clientName}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                {
                    !isTenantAdmin ? 
                        <FormControl className={classes.selectInput}>
                            <InputLabel id="demo-simple-select-label">Tenant</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="tenantId"
                                name="tenantId"
                                value={props.newClient.tenantId ?? ''}
                                onChange={(event) => handleTextFieldChange(event)}
                            >
                                {
                                    props.tenants?.map((tenant, index) => {
                                        return (
                                            <MenuItem key={tenant.tenantId} value={tenant.tenantId}>{tenant.tenantName}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                        : <FormControl className={classes.selectInput}/>
                }
                
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Contact First Name' 
                    id='contactFirstName'
                    value={props.newClient.contactFirstName}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <FormControl className={classes.selectInput}>
                    <InputLabel id="demo-simple-select-label">Status</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="isActive"
                        name="isActive"
                        value={props.newClient.isActive}
                        onChange={(event) => handleTextFieldChange(event)}
                    >
                        <MenuItem value={1}>Active</MenuItem>
                        <MenuItem value={0}>Inactive</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Client Number/ID' 
                    id='clientNumber'
                    type='number'
                    value={props.newClient.clientNumber}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Phone Number' 
                    id='primaryPhoneNumber'
                    value={props.newClient.primaryPhoneNumber}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Contact Last Name' 
                    id='contactLastName'
                    value={props.newClient.contactLastName}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3}>
                <TextField 
                    label='Email' 
                    id='email'
                    value={props.newClient.email}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='FEIN' 
                    id='fein'
                    value={props.newClient.fein}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField 
                    label='Client Contact Phone Number' 
                    id='clientContactPhoneNumber'
                    value={props.newClient.clientContactPhoneNumber}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={6} />
            <Grid item xs={3}>
                <TextField 
                    label='Client Contact Email' 
                    id='clientContactEmail'
                    value={props.newClient.clientContactEmail}
                    onChange={(event) => handleTextFieldChange(event)}
                />
            </Grid>
        </Grid>
        <div className={classes.buttonsContainer}>
            <Button 
                className={classes.cancelButton}
                onClick={handleCancel}
            >
                Cancel
            </Button>
            <Button className={classes.button} onClick={props.createClient}>
                Add
            </Button>
        </div>
    </form>
}