import React, { Fragment, FunctionComponent } from 'react';
import { Typography, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Paper } from '@material-ui/core';
import styles from "./DxScorecard.Styles";
import ScorecardSectionDataModel from '../../../../../data-models/pt/ScorecardSection.DataModel';
import Moment from 'moment';
import MedicalServiceDataModel from "../../../../../data-models/MedicalService.DataModel";
import DiagnosticProcedureResponseScorecardDataModel from "../../../../../data-models/DiagnosticProcedureResponseScorecard.DataModel";

interface IColumnConfiguration {
    id: string,
    label: string,
    minWidth: number,
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify',
    format?: (value) => string,
    color?: (value: number) => string
}

interface IPtScorecardProps {
    scorecardSections: Array<DiagnosticProcedureResponseScorecardDataModel>,
    header: string
}

export const DxScorecard: FunctionComponent<IPtScorecardProps> = (props: IPtScorecardProps) => {
    const classes = styles();

    const columns: Array<IColumnConfiguration> = [
        {
            id: 'scorecardSectionName',
            label: 'Score Reported',
            minWidth: 170,
            align: 'left'
        },
        {
            id: 'dateReported',
            label: 'Date Reported',
            minWidth: 170,
            align: 'left',
            format: (value) => Moment(value).format('MM/DD/YYYY'),
        },
        {
            id: 'value',
            label: 'Score',
            minWidth: 170,
            align: 'left',
            format: (value) => value.toFixed(1),
            //color: (value) => value >= 1.4 ? 'green' : value >= 1 ? 'yellow' : 'red'
        },
        {
            id: 'scorecardOptionName',
            label: 'Reference',
            minWidth: 170,
            align: 'left',
        }
    ];

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Grid container spacing={3} className={classes.gridContainer}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle2" className={classes.paperTitle}>Scorecards: {props.header}</Typography>
                            <TableContainer className={classes.tableContainer}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead>
                                        <TableRow>
                                            {columns.map((column) => (
                                                <TableCell
                                                    key={column.id}
                                                    align={column.align}
                                                    style={{ minWidth: column.minWidth }}
                                                >
                                                    {column.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {props.scorecardSections.map(row => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={row.value}>
                                                    {columns.map((column) => {
                                                        const value = row[column.id];
                                                        // const tooltipValue: string = props.scorecardSections.map(scorecardSection => {
                                                        //     return scorecardSection.description + ": " + (row.scorecardResponses.filter(x => x.scorecardSectionId == scorecardSection.scorecardSectionId)[0]?.value ? row.scorecardResponses.filter(x => x.scorecardSectionId == scorecardSection.scorecardSectionId)[0]?.value : scorecardSection.options?.filter(option => option.scorecardOptionId == row.scorecardResponses.filter(x => x.scorecardSectionId == scorecardSection.scorecardSectionId)[0]?.scorecardOptionId)[0]?.value ? scorecardSection.options?.filter(option => option.scorecardOptionId == row.scorecardResponses.filter(x => x.scorecardSectionId == scorecardSection.scorecardSectionId)[0]?.scorecardOptionId)[0]?.value : 'NA')
                                                        // }).join(' - ');
                                                        return (
                                                            <TableCell >
                                                                {column.format ? column.format(value) : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}