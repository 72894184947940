import { createTheme , createStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 320;

export default (theme: Theme) => createStyles({
  paperHeader: {
    flexDirection: 'row-reverse'
  },
  paperTitle: {
    color: '#428302'
  },
  paperCaption: {
    color: '#898F91'
  },
  listItem: {
    paddingLeft: "0px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },

  content: {
    paddingRight: '25px',
    paddingTop: '15px'
  },
  referralHeaderContainer: {
    display: 'flex',
    marginBottom: '15px'
  },
  referralHeader: {
    color: '#648C9B'
  },
  referralNumber: {
    marginLeft: '5px',
    fontWeight: 'bold',
    color: '#004763'
  },
  claimTitle: {
    color: '#428302',
    marginLeft: '10px'
  },
  gridContainer: {
    width: '100%',
    display: 'block',
    
  },
  paper: {
    padding: '15px'
  },
  dataLabel: {
    color: '#898F91',
    fontSize: '12px'
  },
  iconTextRow: {
    display: 'inline-flex',
    flexDirection: 'row'
  },
  blackFont: {
    color: '#000000'
  },
  claimData: {
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: 10
  },
  iconData: {
    color: '#000000',
    marginLeft: 10
  },
  clientListPaper: {
    padding: "10px 25px 100px",
    margin: "25px",
    "& th": {
      background: "#F0F0F0"
    },
    "& a": {
      color: "#1599BC",
      textDecoration: "none"
    }
  },
});

export const newTheme = createTheme ({
  overrides:{
      MuiStepIcon:{
          completed:{
              color:"#428302 !important"
          }
      },
      MuiList:{
        padding:{
            paddingBottom:"0px",
            paddingTop:"0px"
        }
      },
      MuiStepConnector:{
          vertical: {
              marginLeft: "7px"
          },
          lineVertical: {
              minHeight: "10px",
              borderLeftStyle: "dotted",
              borderLeftWidth: "3px",
          }
      },      
      MuiSvgIcon:{
          root:{
              width: "0.75em",
              marginRight: '16px!important'
          }
        },
  },
});