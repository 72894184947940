import { createTheme , makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    gridContainer: {
        width: '100%',
    },
    paper: {
        paddingTop: '15px',
        paddingLeft: '35px',
        paddingRight: '35px',
        paddingBottom: '25px',
        maxWidth:'none'
    },
    dataLabel: {
        color: '#898F91',
        fontSize: '12px'
    },
    iconTextRow: {
        display: 'inline-flex',
        flexDirection: 'row'
    },
    subtitle: {
        color: '#000000',
        whiteSpace: 'pre-line'
    },
    blackFont: {
        color: '#000000',
    },
    iconData: {
        color: '#000000',
        marginLeft: 10
    },
    paperHeader: {
        flexDirection: 'row-reverse'
    },
    paperTitle: {
        color: '#428302',
        fontSize: '12px'
    },
    summaryTextArea: {
        width: '100%'
    },
    acceptButton: {
        marginLeft: 15, 
        backgroundColor: '#134F5C',
        '&:hover': {
            backgroundColor: '#004763!important',
        }
    },
    cancelButton: {
        padding: "6px 24px",
        marginLeft: 15, 
        color: '#134F5C',
        borderColor: '#134F5C' ,
        '&:hover': {
            color: '#134F5C',
            borderColor: '#134F5C' ,
        }
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(3.5),
        top: theme.spacing(3.5),
        color: '#000000',
    },
}));

export const dialogTheme = (isFullScreen: boolean = false) => createTheme ({
        overrides: {
            MuiPaper: {
                root: {
                    paddingTop: '25px',
                    paddingLeft: '35px',
                    paddingRight: '35px',
                    paddingBottom: '25px',
                    maxWidth: isFullScreen ? 'none !important' : '600px',
                    minWidth: isFullScreen ? '95vw' : 'none !important'
                },
            },
            MuiFormLabel: {
                root: {
                    '&$disabled': {
                        color: '#898F91',
                    },
                }
            }
        },
    });
