import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import { MaterialTableProps } from 'material-table';
import React, { FunctionComponent, useEffect } from 'react';
import styles from './ReferralsCountByDate.Styles';
import MaterialTable from '../../generic-components/material-table/MaterialTable.Component';
import TenantEntity from '../../../entities/Tenant.Entity';
import Moment from 'moment';
import VendorAdminTableDataModel from '../../../data-models/VendorAdminTable.DataModel';
import { DialogCustom } from '../../generic-components/dialog-custom/DialogCustom.Component';
import { ReferralsSentCount } from '../referrals-sent-count/ReferralsSentCount.Component';
import { Loader } from '../../generic-components/loader/Loader.Component';
import AdminReferralSentCountByDateDataModel from '../../../data-models/AdminReferralSentCountByDate.DataModel';
import { ReferralsByDateAndService } from '../referrals-by-date-and-service/ReferralsByDateAndService.Component';

interface ReferralsCountByDateProps {
    referrals: Array<AdminReferralSentCountByDateDataModel>;
    tenants: Array<TenantEntity>;
    vendors: Array<VendorAdminTableDataModel>;
    handleGetReferralsTable: (filters: ReferralSearchFilter) => void;
    isLoading: boolean;
}

export interface ReferralSearchFilter {
    tenantId: number;
    vendorId: number;
    dateRangeFrom?: string;
    dateRangeTo?: string;
}

export const ReferralsCountByDate: FunctionComponent<ReferralsCountByDateProps> = (props) => {
    const classes = styles();
    const [filter, setFilter] = React.useState<boolean>(false);
    const [referralSearchFilter, setReferralSearchFilter] = React.useState<ReferralSearchFilter>({
        tenantId: null,
        vendorId: null,
        dateRangeFrom: null,
        dateRangeTo: Moment(new Date()).format('YYYY-MM-DD'),
    });
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [serviceId, setServiceId] = React.useState<number>(null);

    useEffect(() => {
        let dateRangeFrom = new Date();
        dateRangeFrom.setDate(dateRangeFrom.getDate() - 7);

        setReferralSearchFilter({ ...referralSearchFilter, dateRangeFrom: Moment(dateRangeFrom).format('YYYY-MM-DD') });
    }, []);

    const referralsTableProps: MaterialTableProps<AdminReferralSentCountByDateDataModel> = {
        columns: [
            {
                title: 'Submitted Date',
                render: (rowData) => (
                    <span>
                        {rowData.minSubmittedDate} - {rowData.maxSubmittedDate}
                    </span>
                ),
            },
            {
                title: 'Tenant',
                field: 'tenantName',
            },
            {
                title: 'Referral Total Count',
                field: 'referralTotalCount',
            },
            {
                title: 'Total IME',
                render: (rowData) => (
                    <div
                        className={classes.link}
                        onClick={() => {
                            setOpenDialog(true);
                            setServiceId(1);
                        }}
                    >
                        {rowData.totalIme}
                    </div>
                ),
            },
            {
                title: 'Total Physical Medicine',
                render: (rowData) => (
                    <div
                        className={classes.link}
                        onClick={() => {
                            setOpenDialog(true);
                            setServiceId(3);
                        }}
                    >
                        {rowData.totalPm}
                    </div>
                ),
            },
            {
                title: 'Total Diagnostic',
                render: (rowData) => (
                    <div
                        className={classes.link}
                        onClick={() => {
                            setOpenDialog(true);
                            setServiceId(4);
                        }}
                    >
                        {rowData.totalDx}
                    </div>
                ),
            },
            {
                title: 'Total Utilization Review',
                render: (rowData) => (
                    <div
                        className={classes.link}
                        onClick={() => {
                            setOpenDialog(true);
                            setServiceId(5);
                        }}
                    >
                        {rowData.totalUr}
                    </div>
                ),
            },
            {
                title: 'Total Transportation/Translation',
                render: (rowData) => (
                    <div
                        className={classes.link}
                        onClick={() => {
                            setOpenDialog(true);
                            setServiceId(6);
                        }}
                    >
                        {rowData.totalTt}
                    </div>
                ),
            },
        ],
        data: props.referrals ?? [],
        options: {
            filtering: filter,
            showTitle: true,
            paging: props.referrals ? props.referrals.length > 10 : false,
            toolbar: true,
            exportButton: true,
            exportFileName: 'TerraClaimConnect_Admin_ReferralsList',
            pageSizeOptions: [10, 25, 50],
            pageSize: 50,
            paginationPosition: 'top',
            emptyRowsWhenPaging: false,
        },
        actions: [
            {
                icon: 'tune',
                tooltip: 'Filter',
                isFreeAction: true,
                onClick: () => setFilter(!filter),
            },
        ],
        title: 'Referral List',
    };

    const handleTextFieldChange = (
        event:
            | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            | React.ChangeEvent<{ name?: string; value: unknown; id?: string }>,
    ) => {
        event.persist();
        let newFilter: ReferralSearchFilter = { ...referralSearchFilter };
        newFilter[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;
        setReferralSearchFilter(newFilter);
    };

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={3}>
                    <FormControl className={classes.selectInput}>
                        <InputLabel id="demo-simple-select-label">Tenant</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="tenantId"
                            name="tenantId"
                            value={referralSearchFilter.tenantId}
                            onChange={(event) => handleTextFieldChange(event)}
                        >
                            {props.tenants?.map((tenant, index) => {
                                return (
                                    <MenuItem key={tenant.tenantId} value={tenant.tenantId}>
                                        {tenant.tenantName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className={classes.selectInput}>
                        <InputLabel id="demo-simple-select-label">Vendor</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="vendorId"
                            name="vendorId"
                            value={referralSearchFilter.vendorId}
                            onChange={(event) => handleTextFieldChange(event)}
                        >
                            {props.vendors.map((vendor, index) => {
                                return (
                                    <MenuItem key={vendor.vendorId} value={vendor.vendorId}>
                                        {vendor.vendorName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Submitted Date Range From"
                        id="dateRangeFrom"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => handleTextFieldChange(event)}
                        value={Moment(referralSearchFilter.dateRangeFrom).format('YYYY-MM-DD')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Submitted Date Range To"
                        id="dateRangeTo"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => handleTextFieldChange(event)}
                        value={Moment(referralSearchFilter.dateRangeTo).format('YYYY-MM-DD')}
                    />
                </Grid>
                <Button className={classes.button} onClick={() => props.handleGetReferralsTable(referralSearchFilter)}>
                    Get Referrals
                </Button>
            </Grid>
            {props.isLoading ? <Loader isSmall /> : <MaterialTable tableProps={referralsTableProps} />}
            <DialogCustom
                open={openDialog}
                closeHandler={() => setOpenDialog(false)}
                acceptHandler={() => setOpenDialog(false)}
                acceptButtonText="Ok"
                isFullScreen
            >
                <ReferralsByDateAndService
                    serviceId={serviceId}
                    dateRangeFrom={referralSearchFilter.dateRangeFrom}
                    dateRangeTo={referralSearchFilter.dateRangeTo}
                />
            </DialogCustom>
        </Paper>
    );
};
