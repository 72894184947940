import InjuryTypeEntity from '../entities/pt/InjuryType.Entity';
import SpecificBodyPartEntity from '../entities/SpecificBodyPart.Entity';
import { apiGet } from '../support/helpers/ApiConnection.Helper';

export default function getSpecificBodyParts(serviceId: number, serviceTypeId: number, abortSignal: AbortSignal): Promise<Array<SpecificBodyPartEntity>> {    
    return apiGet<Array<SpecificBodyPartEntity>>(`/services/${serviceId}/${serviceTypeId}/body-parts`,abortSignal);
}

export function getSpecificBodyPartsInjuryTypes(specificBodyPartId: number, abortSignal: AbortSignal): Promise<Array<InjuryTypeEntity>> {
    return apiGet<Array<InjuryTypeEntity>>('/specific-body-parts/'+specificBodyPartId+'/injury-types',abortSignal);
}