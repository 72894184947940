import {
    Button,
    FormControl,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { MaterialTableProps } from 'material-table';
import React, { FunctionComponent } from 'react';
import styles from './BillingReports.Styles';
import MaterialTable from '../../generic-components/material-table/MaterialTable.Component';
import VendorAdminTableDataModel from '../../../data-models/VendorAdminTable.DataModel';
import TenantEntity from '../../../entities/Tenant.Entity';
import Moment from 'moment';
import BillingReportDataModel from '../../../data-models/BillingReport.DataModel';
import BillingReportCountDataModel from '../../../data-models/BillingReportCount.DataModel';
import { Loader } from '../../generic-components/loader/Loader.Component';
import BillingReportSummaryDataModel from '../../../data-models/BillingReportSummary.DataModel';
import { DialogCustom } from '../../generic-components/dialog-custom/DialogCustom.Component';
import { getBillingReportByVendorId } from '../../../services/Report.Service';

export interface BillingReportFilters {
    vendorIds: number[];
    tenantIds: number[];
    dateRangeFrom: Date;
    dateRangeTo: Date;
}

interface BillingReportsProps {
    vendors: Array<VendorAdminTableDataModel>;
    tenants: Array<TenantEntity>;
    reportResults: Array<BillingReportSummaryDataModel>;
    totalReportCount: BillingReportCountDataModel;
    handleGetReport: (filters: BillingReportFilters) => Promise<void>;
    isLoading: boolean;
}

export const BillingReports: FunctionComponent<BillingReportsProps> = (props) => {
    const abortController = new AbortController();
    const classes = styles();
    const [filter, setFilter] = React.useState<boolean>(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [reportFilters, setReportFilters] = React.useState<BillingReportFilters>({
        vendorIds: [],
        tenantIds: [],
        dateRangeFrom: null,
        dateRangeTo: null,
    });
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [vendorBillingReport, setVendorBillingReport] = React.useState<BillingReportDataModel[]>(null);
    const [isVendorBillingReportLoading, setIsVendorBillingReportLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        setReportFilters({
            ...reportFilters,
            dateRangeFrom: Moment(firstDay).format('YYYY-MM-DD') as any,
            dateRangeTo: Moment(lastDay).format('YYYY-MM-DD') as any,
        });
    }, []);

    const handleGetBillingReportByVendor = async (vendorId: number) => {
        setIsVendorBillingReportLoading(true);
        setOpenDialog(true);
        const billingReports = await getBillingReportByVendorId(vendorId, reportFilters, abortController.signal);

        setVendorBillingReport(billingReports);
        setIsVendorBillingReportLoading(false);
    };

    const clientsTableProps: MaterialTableProps<BillingReportSummaryDataModel> = {
        columns: [
            {
                title: 'Vendor',
                field: 'vendorName',
            },
            {
                title: 'Accepted',
                field: 'accepted',
            },
            {
                title: 'Rejected',
                field: 'rejected',
            },
            {
                title: 'Credits',
                field: 'totalQuantity',
                headerStyle: {
                    maxWidth: '10%',
                    minWidth: '10%',
                },
                cellStyle: {
                    maxWidth: '10%',
                    minWidth: '10%',
                },
            },
            {
                title: 'Total Price',
                field: 'totalPrice',
                render: (rowData) => `$${rowData.totalPrice}`,
            },
        ],
        data: props.reportResults ? props.reportResults : [],
        actions: [
            (rowData) => ({
                icon: (props) => (
                    <div
                        {...props}
                        className={classes.link}
                        onClick={() => {
                            handleGetBillingReportByVendor(rowData.vendorId);
                        }}
                    >
                        View Details
                    </div>
                ),
                tooltip: '',
                isFreeAction: true,
                onClick: () => {},
            }),
        ],
        options: {
            filtering: filter,
            showTitle: true,
            paging: props.vendors ? props.vendors.length > 10 : false,
            toolbar: true,
            actionsColumnIndex: -1,
        },
        style: {
            paddingLeft: '35px',
            paddingRight: '35px',
        },
        title: 'Results',
    };

    const vendorBillingReportTableProps: MaterialTableProps<BillingReportDataModel> = {
        columns: [
            {
                title: 'Adjuster',
                field: 'adjusterName',
            },
            {
                title: 'Claim Number',
                field: 'claimNumber',
            },
            {
                title: 'Referral Number',
                field: 'referralId',
            },
            {
                title: 'Accepted',
                field: 'acceptedCount',
            },
            {
                title: 'Rejected',
                field: 'rejectedCount',
            },
            {
                title: 'Credits',
                render: (rowData) => `${rowData.acceptedCount - rowData.rejectedCount}`,
            },
            {
                title: 'Referral Price',
                render: (rowData) =>
                    `$${rowData.acceptedCount * rowData.referralPrice - rowData.rejectedCount * rowData.referralPrice}`,
            },
            {
                title: 'Service Name',
                field: 'service',
            },
            {
                title: 'Service Types',
                field: 'serviceTypes',
                render: (rowData) => rowData.serviceTypes.join(', '),
            },
        ],
        data: vendorBillingReport ?? [],
        options: {
            filtering: filter,
            showTitle: true,
            paging: props.vendors ? props.vendors.length > 10 : false,
            toolbar: true,
        },
        style: {
            paddingLeft: '35px',
            paddingRight: '35px',
        },
        title: 'Vendor Billing Report Results',
    };

    const handleTextFieldChange = (
        event:
            | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            | React.ChangeEvent<{ name?: string; value: unknown; id?: string }>,
    ) => {
        event.persist();
        let newFilter: BillingReportFilters = { ...reportFilters };
        newFilter[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;
        setReportFilters(newFilter);
    };

    const handleGetReports = async () => {
        setIsLoading(true);
        props.handleGetReport(reportFilters).then(() => setIsLoading(false));
    };

    const handleVendorChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        event.persist();
        const currentState = { ...reportFilters };
        currentState.vendorIds = event.target.value as number[];
        setReportFilters(currentState);
    };

    const handleTenantChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        event.persist();
        const currentState = { ...reportFilters };
        currentState.tenantIds = event.target.value as number[];
        setReportFilters(currentState);
    };

    return (
        <Paper className={classes.paper}>
            <Typography variant="subtitle2" className={classes.paperTitle}>
                Billing Report
            </Typography>
            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={3}>
                    <FormControl className={classes.selectInput}>
                        <InputLabel id="demo-simple-select-label">Vendor</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="vendorId"
                            name="vendorId"
                            multiple
                            input={<Input />}
                            value={reportFilters?.vendorIds}
                            onChange={handleVendorChange}
                        >
                            {props.vendors?.map((vendor, index) => {
                                return (
                                    <MenuItem key={vendor.vendorId} value={vendor.vendorId}>
                                        {vendor.vendorName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className={classes.selectInput}>
                        <InputLabel id="demo-simple-select-label">Tenant</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="tenantIds"
                            name="tenantIds"
                            multiple
                            input={<Input />}
                            value={reportFilters?.tenantIds}
                            onChange={handleTenantChange}
                        >
                            {props.tenants?.map((tenant) => {
                                return (
                                    <MenuItem key={tenant.tenantId} value={tenant.tenantId}>
                                        {tenant.tenantName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Date Range From"
                        id="dateRangeFrom"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => handleTextFieldChange(event)}
                        value={Moment(reportFilters.dateRangeFrom).format('YYYY-MM-DD')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Date Range To"
                        id="dateRangeTo"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => handleTextFieldChange(event)}
                        value={Moment(reportFilters.dateRangeTo).format('YYYY-MM-DD')}
                    />
                </Grid>
                <Button className={classes.button} onClick={handleGetReports}>
                    Get Report
                </Button>
            </Grid>
            <div className={classes.tableContainer}>
                {isLoading || props.isLoading ? (
                    <Loader isSmall />
                ) : (
                    <>
                        <MaterialTable tableProps={clientsTableProps} />
                        {props.totalReportCount && (
                            <div className={classes.totalReportContainer}>
                                <Typography variant="subtitle2">
                                    Total Count: {props.totalReportCount?.accepted} Accepted -{' '}
                                    {props.totalReportCount?.rejected} Rejected ={' '}
                                    {props.totalReportCount?.accepted - props.totalReportCount?.rejected}
                                </Typography>
                                <Typography variant="subtitle2">
                                    Total Price: ${props.totalReportCount?.acceptedPrice.toFixed(2)} - $
                                    {props.totalReportCount?.rejectedPrice.toFixed(2)} = $
                                    {(
                                        props.totalReportCount?.acceptedPrice - props.totalReportCount?.rejectedPrice
                                    ).toFixed(2)}
                                </Typography>
                            </div>
                        )}
                    </>
                )}
            </div>
            <DialogCustom
                open={openDialog}
                closeHandler={() => setOpenDialog(false)}
                acceptHandler={() => setOpenDialog(false)}
                acceptButtonText="Ok"
                isFullScreen
            >
                {isVendorBillingReportLoading ? (
                    <Loader isSmall />
                ) : (
                    <MaterialTable tableProps={vendorBillingReportTableProps} />
                )}
            </DialogCustom>
        </Paper>
    );
};
