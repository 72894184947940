import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary } from '@material-ui/core';
import styles from "./MsResponseSummary.Styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReferralStatusEntity from '../../../../entities/ReferralStatus.Entity';
import MedicalServiceDataModel from "../../../../data-models/MedicalService.DataModel";
import ReferralVisitStatusEntity from '../../../../entities/pt/ReferralVisitStatus.Entity';

interface IMsResponseSummaryProps {
    referralStatus: ReferralStatusEntity,
    medicalServiceDataModel: MedicalServiceDataModel
}

export const MsResponseSummary: FunctionComponent<IMsResponseSummaryProps> = (props: IMsResponseSummaryProps) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>PM</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>Number of approved visits</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>{props.medicalServiceDataModel?.serviceType.numberOfApprovedVisits}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>PM - Status</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralStatus?.referralStatusLabel}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>Discharge completed</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>{props.medicalServiceDataModel?.response?.dischargeCompleted ? 'Yes' : 'No'}</Typography>
                    </Grid>
                </Grid>                    
            </AccordionDetails>
        </Accordion>
    );
}