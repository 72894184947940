import React, { FunctionComponent } from 'react';
import { Typography, Grid, TextField, ThemeProvider, Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import styles, {referralDxSummaryTheme} from "./ReferralDxSummary.Styles";
import { EmptyDataLabel } from '../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import SpecificBodyPartEntity from '../../../../../entities/SpecificBodyPart.Entity';
import MedicalServiceDataModel from "../../../../../data-models/MedicalService.DataModel";
import Moment from 'moment';
import { ReferralScheduleStatusTypesEnum } from "../../../../../support/enums/ReferralScheduleStatusTypesEnum";
import DiagnosticProcedureResponseDataModel from "../../../../../data-models/DiagnosticProcedureResponse.DataModel";
import ServiceDataModel from "../../../../../data-models/Service.DataModel";

interface IReferralDxSummaryProps {
    specificBodyParts: Array<SpecificBodyPartEntity>;
    diagnosticProcedureResponse: DiagnosticProcedureResponseDataModel;
    services: Array<ServiceDataModel>;
    title?: string;
}

export const ReferralDxSummary: FunctionComponent<IReferralDxSummaryProps> = (props: IReferralDxSummaryProps) => {
    const classes = styles();
    const [currentServiceType, setCurrentServiceType] = React.useState<string>("");

    React.useEffect(() => {
        const serviceTypes = props.services?.find( s => s.service.serviceId == props.diagnosticProcedureResponse?.serviceId);
        const st = serviceTypes.serviceTypes.find(st => st.serviceTypeId == props.diagnosticProcedureResponse?.serviceTypeId);
        setCurrentServiceType(st.serviceTypeName);

    }, [props.diagnosticProcedureResponse]);

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>{props.title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.diagnosticProcedureResponse ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid xs={12} item container >
                                <Grid item xs={3}>
                                    <Typography variant="body2" className={classes.dataLabel}>Service Type</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{currentServiceType}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2" className={classes.dataLabel}>Specific Body Part</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.diagnosticProcedureResponse?.specificBodyPartName}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body2" className={classes.dataLabel}>Body Side</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.diagnosticProcedureResponse?.bodySideName}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body2" className={classes.dataLabel}>Schedule date & time</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.diagnosticProcedureResponse && props.diagnosticProcedureResponse?.scheduledDate ? Moment(props.diagnosticProcedureResponse?.scheduledDate).format('MM/DD/YYYY') + ' at ' + Moment(props.diagnosticProcedureResponse?.scheduledDate).format('HH:mm'): '' }</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body2" className={classes.dataLabel}>Claimant attendance</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.diagnosticProcedureResponse && props.diagnosticProcedureResponse?.referralScheduleStatusTypeId == ReferralScheduleStatusTypesEnum.Completed ? 'Show' : props.diagnosticProcedureResponse && props.diagnosticProcedureResponse?.referralScheduleStatusTypeId == ReferralScheduleStatusTypesEnum.ClaimantNotAttended ? 'No-Show' : ''}</Typography>
                                </Grid>
                            </Grid>
                            <Grid xs={12} item container >
                                <ThemeProvider theme={referralDxSummaryTheme}>
                                    <TextField className={classes.summaryTextArea} multiline disabled label="Doctor Summary" id="doctorSummary" defaultValue={props.diagnosticProcedureResponse?.doctorSummary ? props.diagnosticProcedureResponse?.doctorSummary : ''} />
                                </ThemeProvider>
                            </Grid>
                            {
                                props.diagnosticProcedureResponse?.procedureCreatedByVendorOn &&
                                    <Grid xs={12} item container >
                                        <ThemeProvider theme={referralDxSummaryTheme}>
                                            <TextField className={classes.summaryTextArea} multiline disabled label="Updated by Vendor - Reason" id="updatedByVendorReason" defaultValue={props.diagnosticProcedureResponse?.updatedByVendorReason ? props.diagnosticProcedureResponse?.updatedByVendorReason : ''} />
                                        </ThemeProvider>
                                    </Grid>
                            }
                        </Grid>
                    :
                        <EmptyDataLabel />
                }
            </AccordionDetails>
        </Accordion>

    );
}