import React, { FunctionComponent } from 'react';
import { Route, useRouteMatch } from 'react-router-dom';
import { UserRolesEnum } from '../../../support/enums/UserRoles.Enum';
import { AccessControlSecurityProfiles } from '../access-control-security-profiles/AccessControlSecurityProfiles.Component';
import { TopNavigation } from '../top-navigation/TopNavigation.Component';
import styles from './AccessControlController.Styles';
import { ReactComponent as AccessControlIcon } from '../../../assets/svgs/lastAccessControl.svg';
import { BreadCrumb } from '../../../support/custom-hooks/useBreadCrum';
import AccessControl, { checkPermissions } from '../../generic-components/access-control/AccessControl.Component';
import { IAdminNavigationCard } from '../admin-navigation/NavigationCards';
import { useSelector } from 'react-redux';
import { UsersState } from '../../../store/reducers/usersReducer';
import {
    accessControlRules,
    adminRules,
    securityProfilesRules,
    usersRules,
} from '../../../support/constants/SecurityRules.Constants';
import { AccessControlUsers } from '../access-control-users/AccessControlUsers.Component';

interface AccessControlControllerProps {
    initialTabIndex: number;
    profileId?: number;
}

export const AccessControlController: FunctionComponent<AccessControlControllerProps> = ({
    initialTabIndex,
    profileId,
}) => {
    const classes = styles();
    const [currentSubroutIndex, setCurrentSubroutIndex] = React.useState('');
    const { path } = useRouteMatch();
    const userSecurityRules = useSelector<UsersState, UsersState['securityRules']>((state) => state.securityRules);

    const handleTabChange = (newTab: string) => {
        setCurrentSubroutIndex(newTab);
    };

    const usersPermissions = [...adminRules.read, ...accessControlRules.read, ...usersRules.read];
    const accessControlSecurityProfilesPermissions = [
        ...adminRules.read,
        ...accessControlRules.read,
        ...securityProfilesRules.read,
    ];

    const card: IAdminNavigationCard = {
        order: 1,
        section: null,
        subRoutes: [
            { label: 'Users', link: 'users', allowedPermissions: usersPermissions },
            {
                label: 'Security Profiles',
                link: 'security-profiles',
                allowedPermissions: accessControlSecurityProfilesPermissions,
            },
        ],
        subRoutesRoles: [
            //Users
            [UserRolesEnum.Admin, UserRolesEnum.TcMainAdmin],
            //Security Profiles
            [UserRolesEnum.Admin, UserRolesEnum.TcMainAdmin],
        ],
        icon: <AccessControlIcon />,
        title: 'Access Control',
        link: '/access-control/users',
        allowedPermissions: [],
        hidden: false,
        showTabs: true,
    };

    const adminBreadCrumb = {
        name: 'Admin',
        goBack: false,
        link: '/admin',
        path: path,
    };

    const accessControlBreadCrumb = {
        name: 'Access Control',
        goBack: false,
        link: checkPermissions(userSecurityRules, usersPermissions, false)
            ? '/admin/access-control/users'
            : checkPermissions(userSecurityRules, accessControlSecurityProfilesPermissions, false)
            ? '/admin/access-control/security-profiles'
            : '/admin',
        path: path,
    };

    const usersBreadCrumb = {
        name: 'Users',
        goBack: false,
        link: '/admin/access-control/users',
        path: path,
    };

    const securityProfilesBreadCrumb = {
        name: 'Security Profiles',
        goBack: false,
        link: '/admin/access-control/security-profiles',
        path: path,
    };

    return (
        <div className={classes.selectedComponentContainerWithTabs}>
            <TopNavigation
                {...card}
                tabIndex={currentSubroutIndex}
                handleTabChange={handleTabChange}
                initialTabIndex={initialTabIndex}
                profileId={profileId}
            />
            <Route path="/admin/access-control/users">
                <AccessControl allowedPermissions={usersPermissions} sendBack={false}>
                    <Route path="/admin/access-control/users" exact>
                        <div className={classes.breadCrumb}>
                            <BreadCrumb sections={[adminBreadCrumb, accessControlBreadCrumb, usersBreadCrumb]} />
                        </div>
                    </Route>
                    <AccessControlUsers />
                </AccessControl>
            </Route>
            <Route path="/admin/access-control/security-profiles" exact>
                <AccessControl allowedPermissions={accessControlSecurityProfilesPermissions} sendBack={false}>
                    <div className={classes.breadCrumb}>
                        <BreadCrumb sections={[adminBreadCrumb, accessControlBreadCrumb, securityProfilesBreadCrumb]} />
                    </div>
                    <AccessControlSecurityProfiles />
                </AccessControl>
            </Route>
        </div>
    );
};
