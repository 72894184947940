import { createStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 320;

export default (theme: Theme) => createStyles({
  content: {
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingTop: '15px'
  },
  referralHeaderContainer: {
    display: 'flex',
    marginBottom: '15px'
  },
  referralHeader: {
    color: '#648C9B'
  },
  referralNumber: {
    marginLeft: '5px',
    fontWeight: 'bold',
    color: '#004763'
  },
  claimTitle: {
    color: '#428302',
    marginLeft: '10px'
  },
  gridContainer: {
    width: '100%',
    display: 'block',
    
  },
  paper: {
    padding: '15px'
  },
  dataLabel: {
    color: '#898F91',
    fontSize: '12px'
  },
  iconTextRow: {
    display: 'inline-flex',
    flexDirection: 'row'
  },
  blackFont: {
    color: '#000000'
  },
  claimData: {
    color: '#000000',
    fontWeight: 'bold',
    marginLeft: 10
  },
  iconData: {
    color: '#000000',
    marginLeft: 10
  },
  clientListPaper: {
    padding: "10px 25px 100px",
    margin: "25px",
    "& th": {
      background: "#F0F0F0"
    },
    "& a": {
      color: "#1599BC",
      textDecoration: "none"
    }
  },
});