import {Box, Grid, Paper, Typography} from "@material-ui/core";
import { MaterialTableProps } from "material-table";
import React, { FunctionComponent, useEffect, useState } from "react";
import AdminReferralSentCountDataModel from "../../../data-models/AdminReferralSentCountDataModel";
import { getReferralsSentCount } from "../../../services/Referrals.Service";
import MaterialTable from "../../generic-components/material-table/MaterialTable.Component";
import styles from './ReferralsSentCount.Styles';
import VendorErrorEntity from "../../../entities/VendorError.Entity";
import VendorResponseDataModel from "../../../data-models/VendorResponse.DataModel";

interface ReferralsSentCountProps{
    referralId: number;
}

export const ReferralsSentCount: FunctionComponent<ReferralsSentCountProps> = (props) => {

    const classes = styles();
    const abortController = new AbortController();
    const [referralSubmission, setReferralSubmission] = useState<AdminReferralSentCountDataModel[]>(null);
    const [vendorResponse, setVendorResponse] = useState<VendorResponseDataModel>(null);
    const [vendorError, setVendorError] = useState<string>(null);

    useEffect(() => {
        setVendorResponse(null);

        getReferralsSentCount(props.referralId, abortController.signal).then(res => {
            setReferralSubmission(res);

            setJSON(res[0]);
        });
    }, [props.referralId]);

    const handleSelectReferralSubmission = (date: Date) => {
        const selectedReferralSubmission = referralSubmission.find(r => r.date === date);

        setJSON(selectedReferralSubmission);
    }

    const setJSON = (rs: AdminReferralSentCountDataModel) => {
        const vendorResponseErrorList = [];
        
        if(rs.vendorError != null)
        {
            setVendorError(rs.vendorError);
        }
        else 
        {
            setVendorError(null);
            let result = JSON.parse(rs?.vendorAnswerData);
            let errorObject = null;
            if(result !== null)
            {
                try {
                    errorObject = JSON.parse(result?.Message);
                } catch{
                    setVendorError(result?.Message);
                }

                if(errorObject !== null)
                {
                    errorObject?.validationErrors?.map((vendorError) => {
                        var vendorErrorEntity = new VendorErrorEntity(vendorError['fieldName'], vendorError['errorMessage']);
                        vendorResponseErrorList.push(vendorErrorEntity);
                    })
                    
                    var vendorResponseDataModel = new VendorResponseDataModel(vendorResponseErrorList);
                    setVendorResponse(vendorResponseDataModel);
                } 
                else 
                {
                    setVendorResponse(null);
                }
            }
            else 
            {
                setVendorResponse(null);
            }
        }
    }

    const referralSubmissionTableProps: MaterialTableProps<AdminReferralSentCountDataModel> = {
        columns:[
            {
                title: 'Attempt Number',
                render: rowData => 
                    <div className={classes.link} onClick={() => handleSelectReferralSubmission(rowData.date)}>
                        {referralSubmission.findIndex(r => r.date === rowData.date) + 1}
                    </div>
            },
            {
                title: 'Answer Date',
                field: 'date',
            },
            {
                title: 'Submitted Date',
                field: 'submittedDate',
            },
            {
                title: 'Referral Id',
                field: 'referralId',
            },
            {
                title: 'Claim Number',
                field: 'claimNumber',
            }
        ],
        options:{
            showTitle:true,
            toolbar: true,
            search: false,
            paging: false,
        },
        data: referralSubmission ?? [],
        title: 'Sent Count',
    }

    const validationErrorsTableProps: MaterialTableProps<VendorErrorEntity> = {
        columns:[
            {
                title: 'Field Name',
                field: 'fieldName',
            },
            {
                title: 'Error Message',
                render: rowData => <span className={classes.redFont}>{rowData.errorDescription}</span>
            },
        ],
        options:{
            showTitle:true,
            toolbar: true,
            search: false,
            paging: false,
        },
        data: vendorResponse?.vendorResponse ?? [],
        title: 'Vendor Validation Errors',
    };

    return (
        <Box className={classes.paper}>
            <Paper className={classes.tableContainer}>
                <MaterialTable tableProps={referralSubmissionTableProps}/>
            </Paper>
            <Paper className={classes.jsonContainer}>
                {vendorError !== null &&
                    <>
                        <Typography variant="subtitle2" className={classes.greenTitle}>Vendor Validation Errors</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>Status set by the vendor: Validation Failed</Typography>
                        <Typography variant="subtitle2" className={classes.redFont}>Message: {vendorError}</Typography> 
                    </>
                }  
                {vendorResponse !== null &&
                    <MaterialTable tableProps={validationErrorsTableProps}/>
                }
                {
                    vendorError === null && vendorResponse === null &&
                        <Typography variant="subtitle2" className={classes.blackFont}>No errors.</Typography>
                }
            </Paper>
        </Box>
    )        
}