import { createTheme , makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 320;

export default makeStyles((theme: Theme) => ({
  content: {
    [theme.breakpoints.up('sm')]: {
      paddingRight: drawerWidth,
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  sidebarTitle: {
    color: '#428302',
    marginLeft: '5px'
  },
  gridContainer: {
    width: '100%',
    "& th": {
      background: "#F0F0F0"
    },
    "& a": {
      color: "#1599BC",
      textDecoration: "none"
    }
  },
  paper: {
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingBottom: '20px'
  },
  paperHeader: {
    padding: '0',
    flexDirection: 'row-reverse'
  },
  paperHeaderIcon: {
    color: '#428302'
  },
  textAreaGrid: {
    paddingLeft: '35px!important',
  },
  fullWidthInput: {
    width: '100%'
  },
  selectInput: {
    width: '100%',
  },
  singleRowField: {
    display: 'flex',
    alignItems: 'center'
  },
  singleRowRadioButtonGroup: {
    paddingLeft: '35px'
  },
  divider: {
    height: '1px',
    marginTop: 20,
    backgroundColor: '#707070'
  },
  dataLabel: {
    color: '#898F91',
    fontSize: '12px'
  },
  iconTextRow: {
    display: 'inline-flex',
    flexDirection: 'row'
  },
  blackFont: {
    color: '#000000'
  },
  iconData: {
    color: '#000000',
    marginLeft: 10
  },
  paperTitle: {
    color: '#428302'
  },
}));