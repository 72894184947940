import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => {
    return {
        content: { color: "#000000", fontSize: 12 },
        close: { marginRight: -10 },
        actions: { padding: theme.spacing(1, 3, 2.5, 3), "& .MuiButton-root": { lineHeight: 1.5 } },
        dialogContent: { padding: theme.spacing(0, 3, 1, 3) },
        button:{
            color:'white',
            backgroundColor:'#107A98',
            marginLeft:'40px',
            '&:hover': {
                backgroundColor: '#004763!important',
            },
        },
        cancelButton:{
            color:'black',
            backgroundColor:'#e5e5e5',
            marginLeft:'40px',
            '&:hover': {
                backgroundColor: '#f0f0f0 !important',
            },
        },
    };
});
