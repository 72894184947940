import { Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField } from '@material-ui/core';
import { MaterialTableProps } from 'material-table';
import React, { FunctionComponent, useEffect } from 'react';
import styles from './VendorDocuments.Styles';
import MaterialTable from '../../generic-components/material-table/MaterialTable.Component';
import TenantEntity from '../../../entities/Tenant.Entity';
import Moment from 'moment';
import VendorAdminTableDataModel from '../../../data-models/VendorAdminTable.DataModel';
import { DialogCustom } from '../../generic-components/dialog-custom/DialogCustom.Component';
import { Loader } from '../../generic-components/loader/Loader.Component';
import AdminReferralVendorDocumentsTableDataModel from '../../../data-models/AdminReferralVendorDocumentsTable.DataModel';
import ReferralVendorDocumentDataModel from '../../../data-models/ReferralVendorDocument.DataModel';
import moment from 'moment';
import { getReferralsVendorDocumentsList } from '../../../services/Referrals.Service';
import retryOneVendorDocumentDownload from '../../../services/ReferralDocuments.Service';
import { Section } from '../../../support/custom-hooks/useBreadCrum';
import AccessControl from '../../generic-components/access-control/AccessControl.Component';
import { vendorDocumentsRules } from '../../../support/constants/SecurityRules.Constants';

interface VendorDocumentsProps {
    referrals: Array<AdminReferralVendorDocumentsTableDataModel>;
    tenants: Array<TenantEntity>;
    vendors: Array<VendorAdminTableDataModel>;
    isLoading: boolean;
    handleGetReferralsVendorDocumentsTable: (filters: VendorDocumentsFilter) => void;
}

export interface VendorDocumentsFilter {
    tenantId: number;
    vendorId: number;
    dateRangeFrom?: string;
    dateRangeTo?: string;
}

export const VendorDocuments: FunctionComponent<VendorDocumentsProps> = (props) => {
    const classes = styles();
    const abortController = new AbortController();
    const [filter, setFilter] = React.useState<boolean>(false);
    const [vendorDocumentsSearchFilter, setVendorDocumentsSearchFilter] = React.useState<VendorDocumentsFilter>({
        tenantId: null,
        vendorId: null,
        dateRangeFrom: null,
        dateRangeTo: Moment(new Date()).format('YYYY-MM-DD'),
    });
    const [openDialog, setOpenDialog] = React.useState<boolean>(false);
    const [documentsList, setDocumentsList] = React.useState<Array<ReferralVendorDocumentDataModel>>(null);
    const [documentError, setDocumentError] = React.useState<Array<ReferralVendorDocumentDataModel>>(null);

    useEffect(() => {
        let dateRangeFrom = new Date();
        dateRangeFrom.setDate(dateRangeFrom.getDate() - 7);

        setVendorDocumentsSearchFilter({
            ...vendorDocumentsSearchFilter,
            dateRangeFrom: Moment(dateRangeFrom).format('YYYY-MM-DD'),
        });
    }, []);

    const handleGetDocumentError = (documentId: number) => {
        var error: Array<ReferralVendorDocumentDataModel> = new Array<ReferralVendorDocumentDataModel>();
        error.push(documentsList.find((d) => d.documentId === documentId));
        setDocumentError(error);
    };

    const clientsTableProps: MaterialTableProps<AdminReferralVendorDocumentsTableDataModel> = {
        columns: [
            {
                title: 'Referral Id',
                field: 'referralId',
            },
            {
                title: 'Claim Number',
                field: 'claimNumber',
            },
            {
                title: 'Claim Id',
                field: 'claimId',
            },
            {
                title: 'Total documents',
                field: 'countDocuments',
            },
            {
                title: 'Tenant',
                field: 'tenantName',
            },
            {
                title: 'Vendor',
                field: 'vendorName',
            },
            {
                title: 'Total documents failed',
                field: 'countDocumentsFailed',
                render: (rowData) => (
                    <div
                        className={classes.link}
                        onClick={() => {
                            setOpenDialog(true);
                            getReferralsVendorDocumentsList(rowData.referralId, abortController.signal)
                                .then((res) => {
                                    setDocumentsList(res);
                                    // setJSON(res[0]);
                                })
                                .catch(() => {});
                        }}
                    >
                        {rowData.countDocumentsFailed} documents
                    </div>
                ),
            },
        ],
        data: props.referrals ?? [],
        options: {
            filtering: filter,
            showTitle: true,
            paging: props.referrals ? props.referrals.length > 10 : false,
            toolbar: true,
            // exportButton: true,
            // exportFileName:'TerraClaimConnect_Admin_ReferralsList',
            pageSizeOptions: [10, 25, 50],
            pageSize: 50,
            paginationPosition: 'top',
            emptyRowsWhenPaging: false,
        },
        actions: [
            {
                icon: 'tune',
                tooltip: 'Filter',
                isFreeAction: true,
                onClick: () => setFilter(!filter),
            },
        ],
        title: 'Referral List',
    };

    const vendorDocumentsTableProps: MaterialTableProps<ReferralVendorDocumentDataModel> = {
        columns: [
            {
                title: 'Referral Number',
                render: (rowData) => rowData.referralId,
                customSort: (a, b) => a.referralId - b.referralId,
                field: 'referralId',
                headerStyle: {
                    maxWidth: '8%',
                    minWidth: '8%',
                },
                cellStyle: {
                    maxWidth: '8%',
                    minWidth: '8%',
                },
            },
            {
                title: 'Document Id',
                field: 'documentId',
                headerStyle: {
                    maxWidth: '7%',
                    minWidth: '7%',
                },
                cellStyle: {
                    maxWidth: '7%',
                    minWidth: '7%',
                },
            },
            {
                title: 'Created Date',
                render: (rowData) =>
                    rowData.createdDate
                        ? moment(rowData.createdDate).format('M/D/YYYY') +
                          ' at ' +
                          moment(rowData.createdDate).format('HH:mm')
                        : '',
                customSort: (a, b) => new Date(a.createdDate)?.getTime() - new Date(b.createdDate)?.getTime(),
                headerStyle: {
                    maxWidth: '7%',
                    minWidth: '7%',
                },
                cellStyle: {
                    maxWidth: '7%',
                    minWidth: '7%',
                },
            },
            {
                title: 'Vendor Link',
                field: 'link',
                render: (rowData) => {
                    return (
                        <div>
                            {rowData.link?.slice(0, 20)}
                            {rowData.link && (
                                <Button
                                    onClick={() => {
                                        navigator.clipboard.writeText(rowData.link);
                                    }}
                                >
                                    Copy
                                </Button>
                            )}
                        </div>
                    );
                },
                headerStyle: {
                    maxWidth: '18%',
                    minWidth: '18%',
                },
                cellStyle: {
                    maxWidth: '18%',
                    minWidth: '18%',
                },
            },
            {
                title: 'Vendor Due Date',
                render: (rowData) =>
                    rowData.dueDate
                        ? moment(rowData.dueDate).format('M/D/YYYY') +
                          ' at ' +
                          moment(rowData.dueDate).format('HH:mm')
                        : '',
                customSort: (a, b) => new Date(a.dueDate)?.getTime() - new Date(b.dueDate)?.getTime(),
                headerStyle: {
                    maxWidth: '8%',
                    minWidth: '8%',
                },
                cellStyle: {
                    maxWidth: '8%',
                    minWidth: '8%',
                },
            },
            {
                title: 'TCVC Link',
                field: 'localLink',
                render: (rowData) => {
                    return (
                        <div>
                            {rowData.localLink?.slice(0, 20)}
                            {rowData.localLink && (
                                <Button
                                    onClick={() => {
                                        navigator.clipboard.writeText(rowData.localLink);
                                    }}
                                >
                                    Copy
                                </Button>
                            )}
                        </div>
                    );
                },
                headerStyle: {
                    maxWidth: '18%',
                    minWidth: '18%',
                },
                cellStyle: {
                    maxWidth: '18%',
                    minWidth: '18%',
                },
            },
            {
                title: 'TCVC Due Date',
                render: (rowData) =>
                    rowData.localFileDueDate
                        ? moment(rowData.localFileDueDate).format('M/D/YYYY') +
                          ' at ' +
                          moment(rowData.localFileDueDate).format('HH:mm')
                        : '',
                customSort: (a, b) => new Date(a.localFileDueDate)?.getTime() - new Date(b.localFileDueDate)?.getTime(),
                headerStyle: {
                    maxWidth: '8%',
                    minWidth: '8%',
                },
                cellStyle: {
                    maxWidth: '8%',
                    minWidth: '8%',
                },
            },
            {
                title: 'Downloaded Date',
                render: (rowData) =>
                    rowData.downloadedDate
                        ? moment(rowData.downloadedDate).format('M/D/YYYY') +
                          ' at ' +
                          moment(rowData.downloadedDate).format('HH:mm')
                        : '',
                customSort: (a, b) => new Date(a.downloadedDate)?.getTime() - new Date(b.downloadedDate)?.getTime(),
                headerStyle: {
                    maxWidth: '10%',
                    minWidth: '10%',
                },
                cellStyle: {
                    maxWidth: '10%',
                    minWidth: '10%',
                },
            },
            {
                title: 'Download Failed',
                render: (rowData) => {
                    return (
                        <Button
                            onClick={(e) => {
                                handleGetDocumentError(rowData.documentId);
                            }}
                            variant="contained"
                            color="primary"
                            style={{ backgroundColor: '#107A98' }}
                        >
                            View error
                        </Button>
                    );
                },
                headerStyle: {
                    maxWidth: '10%',
                    minWidth: '10%',
                },
                cellStyle: {
                    maxWidth: '10%',
                    minWidth: '10%',
                },
            },
        ],
        data: documentsList ?? [],
        actions: [
            {
                icon: 'tune',
                tooltip: 'Filter',
                isFreeAction: true,
                onClick: () => setFilter(!filter),
            },
            {
                icon: (p) => (
                    <AccessControl allowedPermissions={vendorDocumentsRules.update} sendBack={false}>
                        <Button {...p} variant="contained" color="primary" style={{ backgroundColor: '#107A98' }}>
                            Retry download
                        </Button>
                    </AccessControl>
                ),
                // tooltip: '',
                position: 'row',
                onClick: (event, rowData) => {
                    if (Array.isArray(rowData)) return;
                    if (
                        rowData.localLink == null ||
                        (rowData.downloadFailedDate && rowData.downloadFailedReason) !== null
                    ) {
                        retryOneVendorDocumentDownload(rowData.referralId, rowData.documentId, abortController.signal);
                    }
                },
            },
        ],
        options: {
            filtering: filter,
            showTitle: true,
            // paging: referrals ? referrals.length >= 5 : false,
            toolbar: true,
            rowStyle: (rowData) => ({
                //    backgroundColor: rowData.referralStatusId == ReferralStatusTypesEnum.SubmissionFailed || rowData.referralStatusId == ReferralStatusTypesEnum.ValidationFailed ? "#ff000042" : "inherit"
            }),
            actionsColumnIndex: -1,
        },
        localization: {
            header: {
                actions: '',
            },
        },
        title: 'Documents',
    };

    const vendorDocumentsErrorTableProps: MaterialTableProps<ReferralVendorDocumentDataModel> = {
        columns: [
            {
                title: 'Document Id',
                field: 'documentId',
                headerStyle: {
                    maxWidth: '5%',
                    minWidth: '5%',
                },
                cellStyle: {
                    maxWidth: '5%',
                    minWidth: '5%',
                },
            },
            {
                title: 'Download Failed Date',
                render: (rowData) =>
                    rowData.downloadFailedDate
                        ? moment(rowData.downloadFailedDate).format('M/D/YYYY') +
                          ' at ' +
                          moment(rowData.downloadFailedDate).format('HH:mm')
                        : '',
                customSort: (a, b) =>
                    new Date(a.downloadFailedDate)?.getTime() - new Date(b.downloadFailedDate)?.getTime(),
                headerStyle: {
                    maxWidth: '5%',
                    minWidth: '5%',
                },
                cellStyle: {
                    maxWidth: '5%',
                    minWidth: '5%',
                },
            },
            {
                title: 'Download Failed Reason',
                field: 'downloadFailedReason',
                render: (rowData) => {
                    return (
                        <div>
                            {rowData.downloadFailedReason}
                            {
                                <Button
                                    onClick={() => {
                                        navigator.clipboard.writeText(rowData.downloadFailedReason);
                                    }}
                                >
                                    Copy
                                </Button>
                            }
                        </div>
                    );
                },
                headerStyle: {
                    maxWidth: '50%',
                    minWidth: '50%',
                },
                cellStyle: {
                    maxWidth: '50%',
                    minWidth: '50%',
                },
            },
        ],
        data: documentError ?? [],
        actions: [
            {
                icon: 'tune',
                tooltip: 'Filter',
                isFreeAction: true,
                onClick: () => setFilter(!filter),
            },
        ],
        options: {
            filtering: filter,
            showTitle: true,
            // paging: referrals ? referrals.length >= 5 : false,
            toolbar: true,
            rowStyle: (rowData) => ({
                //    backgroundColor: rowData.referralStatusId == ReferralStatusTypesEnum.SubmissionFailed || rowData.referralStatusId == ReferralStatusTypesEnum.ValidationFailed ? "#ff000042" : "inherit"
            }),
            actionsColumnIndex: -1,
        },
        localization: {
            header: {
                actions: '',
            },
        },
        title: 'Document error',
    };

    const handleTextFieldChange = (
        event:
            | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            | React.ChangeEvent<{ name?: string; value: unknown; id?: string }>,
    ) => {
        event.persist();
        let newFilter: VendorDocumentsFilter = { ...vendorDocumentsSearchFilter };
        newFilter[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;
        setVendorDocumentsSearchFilter(newFilter);
    };

    return (
        <Paper className={classes.paper}>
            <Grid container spacing={3} className={classes.gridContainer}>
                <Grid item xs={3}>
                    <FormControl className={classes.selectInput}>
                        <InputLabel id="demo-simple-select-label">Tenant</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="tenantId"
                            name="tenantId"
                            value={vendorDocumentsSearchFilter.tenantId}
                            onChange={(event) => handleTextFieldChange(event)}
                        >
                            {props.tenants?.map((tenant, index) => {
                                return (
                                    <MenuItem key={tenant.tenantId} value={tenant.tenantId}>
                                        {tenant.tenantName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className={classes.selectInput}>
                        <InputLabel id="demo-simple-select-label">Vendor</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="vendorId"
                            name="vendorId"
                            value={vendorDocumentsSearchFilter.vendorId}
                            onChange={(event) => handleTextFieldChange(event)}
                        >
                            {props.vendors.map((vendor, index) => {
                                return (
                                    <MenuItem key={vendor.vendorId} value={vendor.vendorId}>
                                        {vendor.vendorName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Documents Created Date Range From"
                        id="dateRangeFrom"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => handleTextFieldChange(event)}
                        value={Moment(vendorDocumentsSearchFilter.dateRangeFrom).format('YYYY-MM-DD')}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Documents Created Date Range To"
                        id="dateRangeTo"
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => handleTextFieldChange(event)}
                        value={Moment(vendorDocumentsSearchFilter.dateRangeTo).format('YYYY-MM-DD')}
                    />
                </Grid>
                <Button
                    className={classes.button}
                    onClick={() => props.handleGetReferralsVendorDocumentsTable(vendorDocumentsSearchFilter)}
                >
                    Get Referrals
                </Button>
            </Grid>
            {props.isLoading ? <Loader isSmall /> : <MaterialTable tableProps={clientsTableProps} />}
            <DialogCustom
                open={openDialog}
                closeHandler={() => setOpenDialog(false)}
                acceptHandler={() => setOpenDialog(false)}
                acceptButtonText="Ok"
                isFullScreen
            >
                <MaterialTable tableProps={vendorDocumentsTableProps} />
                <MaterialTable tableProps={vendorDocumentsErrorTableProps} />
            </DialogCustom>
        </Paper>
    );
};
