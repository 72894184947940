import { createTheme , makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
  overrides: {
    "MuiStepLabel-alternativeLabel": {
      marginTop: 0,
    },
    "MuiTabs-root":{
        minHeight: "64px",
    }
  },
  navBarTitle: {
    minWidth: 15,
  },
  rootHeader: {
    width: '87%',
  },
  stepper: {
    backgroundColor: "inherit !important",
    padding: 5,
    paddingLeft: '30px',
    paddingRight: '30px'
  },
  instructions: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  stepperLabel: {
    marginTop: 0,
  },
  titleWidth:{
     minWidth:"125px",
  }
}));