import { Tab, Tabs, Typography } from '@material-ui/core';
import React, { Fragment, FunctionComponent } from 'react';
// import styles from "./WizardBar.Styles";

type TabsBarProps = {
    tabChangeHandler: (newValue: number) => void;
    tabs: Array<string>;
    default?: number;
    title?: string;
}

export const TabsBar: FunctionComponent<TabsBarProps> = (props: TabsBarProps) => {
    // const classes = styles();
    const [tabValue, setTabValue] = React.useState(props.default);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
        props.tabChangeHandler(newValue);
    };

    return (
        <Fragment>
            { props.title && 
                <Typography style={{
                    fontWeight:500
                }} >
                    {props.title}
                </Typography>
            }
            <Tabs value={tabValue} onChange={handleChange} aria-label="simple tabs example" TabIndicatorProps={{
                        style: {
                          height:"3px",
                          backgroundColor: "#004763",
                        }
                      }}>
                {props.tabs.map(tab => (
                    <Tab label={tab} key={tab} disableRipple/>
                ))}
            </Tabs>
        </Fragment>
    );
}