import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary } from '@material-ui/core';
import styles from "./ReferralImeSummary.Styles";
import Moment from 'moment';
import { EmptyDataLabel } from '../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import { ReferralScheduleStatusTypesEnum } from '../../../../../support/enums/ReferralScheduleStatusTypesEnum';
import { ReferralOverviewImeDataModel } from '../../../../../data-models/ReferralOverviewIme.DataModel';
import ReferralScheduleStatusTypeEntity from '../../../../../entities/ReferralScheduleStatusType.Entity';
import IMECoverLetterEntity from "../../../../../entities/IMECoverLetter.Entity";

interface IReferralImeSummaryProps {
    referralOverviewImeDataModel: ReferralOverviewImeDataModel;
    scheduleStatusTypes: Array<ReferralScheduleStatusTypeEntity>;
    coverLetter: IMECoverLetterEntity;
}

export const ReferralImeSummary: FunctionComponent<IReferralImeSummaryProps> = (props: IReferralImeSummaryProps) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>IME</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.referralOverviewImeDataModel ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid xs={12} item container >
                                <Grid item xs={3}>
                                    <Typography variant="body2" className={classes.dataLabel}>Schedule Status</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.referralOverviewImeDataModel.schedule && props.referralOverviewImeDataModel.schedule.referralScheduleStatusTypeId && props.scheduleStatusTypes && props.scheduleStatusTypes.length > 0 ? props.scheduleStatusTypes?.filter(x => x.referralScheduleStatusTypeId == props.referralOverviewImeDataModel.schedule.referralScheduleStatusTypeId)[0]?.referralScheduleStatusTypeLabel : ''}</Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body2" className={classes.dataLabel}>Schedule Date & Time</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.referralOverviewImeDataModel.schedule && props.referralOverviewImeDataModel.schedule.scheduledDate ? Moment(props.referralOverviewImeDataModel.schedule.scheduledDate).format('MM/DD/YYYY') + ' at ' + Moment(props.referralOverviewImeDataModel.schedule.scheduledDate).format('HH:mm'): ''}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body2" className={classes.dataLabel}>Claimant Verified They Will Attend</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.referralOverviewImeDataModel.schedule && props.referralOverviewImeDataModel.schedule.attendanceVerifiedDate ? 'Yes - ' + Moment(props.referralOverviewImeDataModel.schedule.attendanceVerifiedDate).format('MM/DD/YYYY') : 'No'}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body2" className={classes.dataLabel}>Claimant Attended</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.referralOverviewImeDataModel.schedule && props.referralOverviewImeDataModel.schedule.referralScheduleStatusTypeId == ReferralScheduleStatusTypesEnum.Completed ? 'Yes' : props.referralOverviewImeDataModel.schedule && props.referralOverviewImeDataModel.schedule.referralScheduleStatusTypeId == ReferralScheduleStatusTypesEnum.ClaimantNotAttended ? 'No' : ''}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography variant="body2" className={classes.dataLabel}>Rush Request</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.referralOverviewImeDataModel.ime?.rushRequest ? 'Yes' : 'No' }</Typography>
                                </Grid>
                            </Grid>
                            <Grid xs={12} item container >
                                <Grid item xs={3}>
                                    <Typography variant="body2" className={classes.dataLabel}>Cover Letter</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.coverLetter.isCoverLetterRequired ? 'Yes' : 'No'}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" className={classes.dataLabel}>Doctor Summary</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralOverviewImeDataModel.imeResponse?.doctorSummary}</Typography>
                            </Grid>
                        </Grid>
                        :
                        <EmptyDataLabel></EmptyDataLabel>
                }
            </AccordionDetails>
        </Accordion>
    );
}