import React, { FunctionComponent, useEffect } from 'react';
import styles from './TooltipTextField.Styles';
import { 
    Box,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    Select,
    StandardTextFieldProps,
    Tooltip,
    Typography,
    SelectProps
} from "@material-ui/core";
import { isArray, array } from '../../../support/common';

interface ITooltipTextField extends StandardTextFieldProps {
    children?: React.ReactNode[],
    inputLabelId?: string,
    formControlClass?: string,
    typographyLabelClass?: string,
    typographyValueClass?: string,
    input?: React.ReactNode,
    selectProps?: SelectProps,
    notShrinkOnDisabled?: boolean;
    zeroIsValid?: boolean;
}

//When shrinked, input label size decreases by 25%
const SHRINK_FACTOR = 0.75;

const CHAR_SIZE = 7.5;
const SMALL_LABEL_CHAR_SIZE = 6;

export const TooltipTextField: FunctionComponent<ITooltipTextField> = (props) => {
    const [openLabelTooltip, setOpenLabelTooltip] = React.useState<boolean>(false);
    const [showLabelTooltipOnHover, setShowLabelTooltipOnHover] = React.useState<boolean>(false);
    const [shouldShrink, setShouldShrink] = React.useState<boolean>( props.value || (props.zeroIsValid && props.value === 0) || props.defaultValue ? true : false);
    const labelRef = React.useRef<HTMLLabelElement | null>(null);
    const formControlRef = React.useRef<HTMLDivElement | null>(null);
    const inputRef = React.useRef<HTMLInputElement | HTMLSelectElement | null>(null);
    const [selectTooltipText, setSelectTooltipText] = React.useState<string>('');
    const [showInputTooltip, setShowInputTooltip] = React.useState<boolean>(false);

    const compareLabel = () => {
        const multiplier = shouldShrink ? SHRINK_FACTOR : 1;
        if(labelRef !=null && formControlRef != null ){
            if(props.type === 'typography'){
                const length = props.label ? props.label.toString().length : 0;
                setShowLabelTooltipOnHover( length * SMALL_LABEL_CHAR_SIZE > formControlRef.current.offsetWidth );
            } else {
                setShowLabelTooltipOnHover(labelRef.current.scrollWidth * multiplier > formControlRef.current.offsetWidth);
            }
        }
    };

    const compareInput = () => {
        if(formControlRef.current == null) return;
        let length: number;
        if(props.type === 'select'){
            const selectValue = props.value;
            if(!selectValue) return;
            const selectedChildrenArray: string[] = [];

            if(Array.isArray(selectValue)){
                selectValue.forEach( value => {
                    const child = props.children?.find((c:any) => c.key === value.toString());
                    selectedChildrenArray.push((child as any).props.children[1].props.primary);
                });
            } else {
                const child = props.children?.find((c:any) => c.key === selectValue.toString());
                selectedChildrenArray.push((child as any)?.props.children);
            }

            const selectLabel = selectedChildrenArray.join(', ');

            setSelectTooltipText(selectLabel);

            //add 7 to length to compensate for arrow
            length = selectLabel.length + 7;
        }
        else {
            length = props.value ? props.value.toString().length : props.defaultValue ? props.defaultValue.toString().length : 0;
        }

        if(!length) return;
        
        //only show input tooltip if length of input value is wider than the container
        setShowInputTooltip(length * CHAR_SIZE > formControlRef.current.offsetWidth)
    }

    useEffect(() => {
        compareInput();
        setOpenLabelTooltip(false);
        compareLabel();

        window.addEventListener('resize', compareLabel);
        window.addEventListener('resize', compareInput);
        return () => {
            window.removeEventListener('resize', compareLabel);
            window.removeEventListener('resize', compareInput);
        }
    }, [shouldShrink, props]);

    
    const classes = styles({ ellipsis: showLabelTooltipOnHover, shrink: shouldShrink });

    const getInputElement = () => {
        switch(props.type){
            case 'select':
                if(!props.inputLabelId) throw Error("You must specify input label ID to render a select.");
                return <Select
                            {...props as any}
                            {...props.selectProps}
                            ref={inputRef}
                            classes={{
                                icon: classes.icon,
                            }}
                            onChange={(e) => {
                                if(props.onChange) (props.onChange as any)(e);
                                if(e.target.value != 0) setShouldShrink(true);
                            }}
                            onFocus={ (e) => {
                                setShouldShrink(true);
                                if(props.onFocus) props.onFocus(e);
                            }}
                            onBlur={ (e) => {
                                if(!e.target.value && parseInt(e.target.value) != 0 ) setShouldShrink(false);
                                if(props.onBlur) props.onBlur(e);
                            }}
                            onMouseEnter={ (e) => {
                                if(!shouldShrink && showLabelTooltipOnHover && !props.disabled) setOpenLabelTooltip(true);
                                if(props.onMouseEnter) props.onMouseEnter(e);
                            }}
                            onMouseLeave={(e) => {
                                if(!shouldShrink && showLabelTooltipOnHover && !props.disabled) setOpenLabelTooltip(false);
                                if(props.onMouseLeave) props.onMouseLeave(e);
                            }}
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                            endAdornment={
                                props?.inputProps?.endAdornment
                            }
                        >
                            {props.children}
                        </Select>
            case 'typography':
                return <Typography 
                    {...props as any}
                    ref={inputRef}
                    onFocus={ (e) => {
                        setShouldShrink(true);
                        if(props.onFocus) props.onFocus(e);
                    }}
                    onMouseEnter={ (e) => {
                        if(showLabelTooltipOnHover) setOpenLabelTooltip(true);
                        if(props.onMouseEnter) props.onMouseEnter(e);
                    }}
                    onMouseLeave={(e) => {
                        if(showLabelTooltipOnHover) setOpenLabelTooltip(false);
                        if(props.onMouseLeave) props.onMouseLeave(e);
                    }}
                    className={`${classes.typography} ${props.typographyValueClass}`}
                    style={{
                        display:'block',
                    }}
                >
                    {props.value}
                </Typography>
            default: 
                return <Input
                            {...props as any}
                            ref={inputRef}
                            onChange={(e) => {
                                if(props.onChange) props.onChange(e);
                                if(e.target.value) setShouldShrink(true);
                            }}
                            onFocus={ (e) => {
                                setShouldShrink(true);
                                if(props.onFocus) props.onFocus(e);
                            }}
                            onBlur={ (e) => {
                                if(!e.target.value) setShouldShrink(false);
                                if(props.onBlur) props.onBlur(e);
                            }}
                            onMouseEnter={ (e) => {
                                if(!shouldShrink && showLabelTooltipOnHover && !props.disabled) setOpenLabelTooltip(true);
                                if(props.onMouseEnter) props.onMouseEnter(e);
                            }}
                            onMouseLeave={(e) => {
                                if(!shouldShrink && showLabelTooltipOnHover && !props.disabled) setOpenLabelTooltip(false);
                                if(props.onMouseLeave) props.onMouseLeave(e);
                            }}
                            classes={{
                                input: `${classes.input} ${props.disabled && props.type === 'date' ? classes.disableCalendarIcon : ''}`,
                                disabled: classes.disabledInput,
                            }}
                            disableUnderline={props.disabled}
                            renderSuffix={false}
                            autoComplete= 'none'
                        />
        }
    }

    const getLabelElement = () => {
        switch(props.type){
            case 'typography':
                return <Typography
                            ref={labelRef}
                            onMouseEnter={ () => {
                                if( showLabelTooltipOnHover ) setOpenLabelTooltip(true);
                            }}
                            onMouseLeave={() => setOpenLabelTooltip(false)}
                            variant="body2"
                            className={`${classes.labelTypography} ${props.typographyLabelClass}`}
                            style={{
                                display:'block'
                            }}
                        >
                            {props.label}
                        </Typography>
            default:
                return <InputLabel
                    className={classes.label}
                    htmlFor={props.id}
                    ref={labelRef}
                    //shrink={ props.notShrinkOnDisabled && props.disabled ? false : props.disabled ? true : shouldShrink}
                    shrink={!isArray(props.value) ? (props.notShrinkOnDisabled && props.disabled ? false : props.disabled ? true : shouldShrink) : array(props.value).length > 0}
                    onMouseEnter={ () => {
                        if( showLabelTooltipOnHover ) setOpenLabelTooltip(true);
                    }}
                    onMouseLeave={() => setOpenLabelTooltip(false)}
                    required={props.required}
                    {...props.InputLabelProps}
                >
                    {props.label} 
                </InputLabel>

        }
    }

    return(
            <FormControl
                className={`${classes.container} ${props.formControlClass}`}
                ref={formControlRef}
            >
                <Tooltip
                    className={classes.tooltip}
                    title={props.label}
                    arrow
                    open={openLabelTooltip}
                    placement={props.disabled || props.type === 'date' || props.type === 'select' || props.type === 'typography' || shouldShrink ? 'top-start' : 'bottom-start'}
                    classes={{arrow: classes.arrow}}
                > 
                    {getLabelElement()}
                </Tooltip>
                <Tooltip
                    title={!showInputTooltip ? '' : props.type === 'select' ? selectTooltipText : props.value ? props.value : props.defaultValue}
                    disableHoverListener={ props.disabled || props.type === 'typography' || props.type === 'select' ? !showInputTooltip : true}
                    disableFocusListener={props.type === 'typography' ? !showInputTooltip : true}
                    arrow
                    placement='bottom-start'
                    classes={{arrow: classes.arrow}}
                >   
                    {getInputElement()}
                </Tooltip>
                {props.helperText && 
                    <FormHelperText 
                        style={{ color: props.error ? 'red' : 'auto' }} 
                        {...props.FormHelperTextProps} 
                    >
                        {props.helperText}
                    </FormHelperText>
                }
            </FormControl>
    );
}