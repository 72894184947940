import React, { Fragment, FunctionComponent } from "react";
import styles from "./ReferralResponse.Styles";
import { Link, Typography, WithStyles, withStyles } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ImeResponseEntity from "../../../../entities/ImeResponse.Entity";
import { ReferralOverviewDataModel } from "../../../../data-models/ReferralOverview.DataModel";
import ReferralScheduleStatusTypeEntity from "../../../../entities/ReferralScheduleStatusType.Entity";
import ReferralContactEntity from "../../../../entities/ReferralContact.Entity";
import StateEntity from "../../../../entities/State.Entity";
import ReferralDocumentEntity from "../../../../entities/ReferralDocument.Entity";
import { ReferralOverviewImeDataModel } from "../../../../data-models/ReferralOverviewIme.DataModel";
import { ServicesEnum } from "../../../../support/enums/Services.Enum";
import { ReferralImeResponse } from "./referral-ime-response/ReferralImeResponse.Component";
import { ReferralMsResponse } from "./referral-ms-response/ReferralMsResponse.Component";
import MedicalServiceDataModel from "../../../../data-models/MedicalService.DataModel";
import DiagnosticProcedureResponseDataModel from "../../../../data-models/DiagnosticProcedureResponse.DataModel";
import ServiceDataModel from "../../../../data-models/Service.DataModel";
import DiagnosticProcedureTableDataModel from "../../../../data-models/DiagnosticProcedureTable.DataModel";
import TransportationTranslationResponse from "../../../../data-models/TransportationTranslationResponse.DataModel";
import ServiceTypeEntity from "../../../../entities/ServiceType.Entity";
import UtilizationReviewResponseDataModel from "../../../../data-models/UtilizationReviewResponse.DataModel";
import DmeResponseDataModel from "../../../../data-models/dme/DmeResponse.DataModel";
import ReferralVisitStatusEntity from "../../../../entities/pt/ReferralVisitStatus.Entity";

type PathParamsType = {
  id: string;
  claimId: string;
};

type ReferralResponseProps = {
  imeResponse: ImeResponseEntity;
  referralOverviewDataModel: ReferralOverviewDataModel;
  referralScheduleStatusTypes: Array<ReferralScheduleStatusTypeEntity>;
  physician: ReferralContactEntity;
  states: Array<StateEntity>;
  responseDocuments: Array<ReferralDocumentEntity>;
  referralOverviewImeDataModel: ReferralOverviewImeDataModel;
  physicalTherapyDataModel: MedicalServiceDataModel;
  serviceId: number;
  diagnosticProcedureResponse: Array<DiagnosticProcedureResponseDataModel>;
  services: Array<ServiceDataModel>;
  serviceTypeResponseUr: UtilizationReviewResponseDataModel;
  proceduresTable: Array<DiagnosticProcedureTableDataModel>;
  transportationTranslationResponses: Array<TransportationTranslationResponse>;
  serviceType: ServiceTypeEntity;
  dmeResponse: DmeResponseDataModel;
  ReferralVisitStatuses : Array<ReferralVisitStatusEntity>;
} & WithStyles<typeof styles> &
  RouteComponentProps<PathParamsType>;

const ReferralResponseComponent: FunctionComponent<ReferralResponseProps> = (
  props: ReferralResponseProps,
) => {
  const { classes } = props;

  return (
    <Fragment>
      {
        <div className={classes.content}>
          {props.referralOverviewDataModel?.referralServiceType[0]?.serviceId ==
          ServicesEnum.IME ? (
            <ReferralImeResponse
              serviceId={props.serviceId}
              states={props.states}
              imeResponse={props.imeResponse}
              physician={props.physician}
              referralOverviewDataModel={props.referralOverviewDataModel}
              referralOverviewImeDataModel={props.referralOverviewImeDataModel}
              referralScheduleStatusTypes={props.referralScheduleStatusTypes}
              responseDocuments={props.responseDocuments}
            />
          ) : (
            <ReferralMsResponse
              serviceId={
                props.referralOverviewDataModel?.referralServiceType[0]
                  ?.serviceId
              }
              states={props.states}
              physicalTherapyDataModel={props.physicalTherapyDataModel}
              physician={props.physician}
              referralOverviewDataModel={props.referralOverviewDataModel}
              responseDocuments={props.responseDocuments}
              referralScheduleStatusTypes={props.referralScheduleStatusTypes}
              diagnosticProcedureResponse={props.diagnosticProcedureResponse}
              services={props.services}
              serviceTypeResponseUr={props.serviceTypeResponseUr}
              proceduresTable={props.proceduresTable}
              ReferralVisitStatuses ={props.ReferralVisitStatuses}
              transportationTranslationResponses={
                props.transportationTranslationResponses
              }
              serviceType={props.serviceType}
              dmeResponse={props.dmeResponse}
            />
          )}
        </div>
      }
    </Fragment>
  );
};

export const ReferralResponse = withRouter(
  withStyles(styles)(ReferralResponseComponent),
);
