export default class ClientEntity {
    clientId: number;
    clientNumber: string;
    clientName: string;
    tenantId: number;
    isActive: boolean;
    createdDate: Date;
    createdUserId: number;
    modifiedDate: Date;
    modifiedUserId: boolean;
    contactFirstName: string;
    contactLastName: string;
    email: string;
    addressLine1: string;
    addressLine2: string;
    cityName: string;
    stateId: number;
    zipCode: string;
    primaryPhoneNumber: string;
    primaryPhoneExtensionNumber: string;
    clientContactPhoneNumber: string;
    secondaryPhoneExtensionNumber: string;
    fax: string;
    fein: string;
    clientContactEmail: string;
}