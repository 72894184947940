export default class DiagnosticProcedureTableDataModel {
    diagnosticProcedureId: number;
    serviceTypeName: string;
    serviceTypeId: number;
    procedureDetailsName: string;
    procedureDetailsId: number;
    specificBodyPartName:string;
    specificBodyPartId: number;
    bodySideName:string;
    bodySideId:number;
    diagnosisName: string;
    diagnosisId: number;
    ageOfInjuryReview: boolean;
}