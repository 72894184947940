
import { Button, FormControl, Grid, IconButton, InputLabel, Link, MenuItem, Paper, Select, TextField, Typography } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React, { FunctionComponent } from "react";
import StateEntity from "../../../entities/State.Entity";
import styles from './ClientProfile.Styles';
import ClientEntity from "../../../entities/Client.Entity";
import TenantEntity from "../../../entities/Tenant.Entity";
import { useHistory } from "react-router";
import UserEntity from "../../../entities/User.Entity";
import { UserRolesEnum } from "../../../support/enums/UserRoles.Enum";

interface ClientProfileProps{
    client: ClientEntity,
    states: Array<StateEntity>;
    handleClientEdit: (newClient: ClientEntity) => void;
    handleStartClientProfileEdit: () => void;
    handleCancelClientProfileEdit: () => void;
    handleSaveEditedClientProfile: () => void;
    tenants: Array<TenantEntity>;
    userProfile: UserEntity;
}

export const ClientProfile: FunctionComponent<ClientProfileProps> = (props) => {
    const classes = styles();
    const abortController = new AbortController();

    const [clientState, setClientState] = React.useState(props.client);
    const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
    const [isTenantAdmin, setIsTenantAdmin] = React.useState<boolean>(false);

    React.useEffect(() => {
        let newClient = props.client;
        if(!newClient){
            newClient = {} as ClientEntity;
            if(isTenantAdmin){
                newClient.tenantId = props.userProfile?.tenantId;
            }
            props.handleClientEdit(newClient);
        }

        setClientState(newClient);

        if (props.userProfile?.userRoles?.findIndex(x => x == UserRolesEnum.TenantAdmin) != -1){
            setIsTenantAdmin(true);
        }

        return function cleanup() {
            abortController.abort();
        }
    }, [props]);

    const handleTextFieldChange = (event: 
        React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
        |
        React.ChangeEvent<{name?: string; value: unknown; id?:string;}>
    ) => {
        event.persist();
        let newClient : ClientEntity = clientState;
        if(isTenantAdmin){
            newClient.tenantId = props.userProfile?.tenantId;
        }
        newClient[event.target.id ? event.target.id : event.target.name] = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value;
        setClientState(newClient);
        props.handleClientEdit(newClient);
    }
    
    if(!props.client || !clientState ) return null;

    return (
        <Paper className={classes.paper} >
            <div className={classes.header} >
                <Typography variant='subtitle2' className={classes.greenText} >
                    Client Information
                </Typography>
                <IconButton 
                    onClick={
                        () => {
                            props.handleStartClientProfileEdit();
                            setIsEditMode(true)
                        }
                    }
                >
                    <Edit />
                </IconButton>
            </div>
            <Grid container spacing={3} className={classes.formContainer} >
                <Grid container item xs={3} spacing={3} direction='column'>
                    <Grid item >
                        <TextField 
                            label='Name' 
                            id='clientName'
                            InputLabelProps={{
                                shrink: !!clientState.clientName
                            }}
                            value={clientState.clientName ?? ''}
                            onChange={(event) => handleTextFieldChange(event)}
                            disabled={!isEditMode} 
                        />
                    </Grid>
                    <Grid item>
                        <TextField 
                            label='Client Number/ID' 
                            id='clientNumber'
                            InputLabelProps={{
                                shrink: !!clientState.clientNumber
                            }}
                            value={clientState.clientNumber}
                            onChange={(event) => handleTextFieldChange(event)}
                            disabled={!isEditMode} 
                        />
                    </Grid>
                    <Grid item >
                        <FormControl 
                            className={classes.selectInput} 
                            disabled={!isEditMode} 
                        >
                            <InputLabel id="demo-simple-select-label">Status</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="isActive"
                                name="isActive"
                                value={clientState.isActive ? 1 : 0}
                                onChange={(event) => handleTextFieldChange(event)}
                                disabled={!isEditMode} 
                            >
                                <MenuItem value={1}>Active</MenuItem>
                                <MenuItem value={0}>Inactive</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container item xs={3} spacing={3} direction='column'>
                    <Grid item >
                        {
                            !isTenantAdmin ?
                                <FormControl className={classes.selectInput}>
                                    <InputLabel id="demo-simple-select-label">Tenant</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="tenantId"
                                        name="tenantId"
                                        value={clientState.tenantId ?? ''}
                                        onChange={(event) => handleTextFieldChange(event)}
                                        disabled={!isEditMode} 
                                    >
                                        {
                                            props.tenants?.map((tenant, index) => {
                                                return (
                                                    <MenuItem key={tenant.tenantId} value={tenant.tenantId}>{tenant.tenantName}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            :
                            <FormControl className={classes.selectInput}>
                                <InputLabel id="demo-simple-select-label"></InputLabel>
                                <Select
                                    labelId=""
                                    id=""
                                    name=""
                                    value={''}
                                    disabled
                                >
                                </Select>
                            </FormControl>
                        }
                    </Grid>
                    <Grid item >
                        <TextField 
                            label='FEIN' 
                            id='fein'
                            InputLabelProps={{
                                shrink: !!clientState.fein
                            }}
                            value={clientState.fein ?? ''}
                            onChange={(event) => handleTextFieldChange(event)}
                            disabled={!isEditMode} 
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={3} spacing={3} direction='column'>
                    <Grid item>
                        <TextField 
                            label='Client Contact First Name' 
                            id='contactFirstName'
                            InputLabelProps={{
                                shrink: !!clientState.contactFirstName
                            }}
                            value={clientState.contactFirstName ?? ''}
                            onChange={(event) => handleTextFieldChange(event)}
                            disabled={!isEditMode}
                        />
                    </Grid>
                    <Grid item>
                        <TextField 
                            label='Client Contact Last Name'
                            id='contactLastName'
                            InputLabelProps={{
                                shrink: !!clientState.contactLastName
                            }}
                            value={clientState.contactLastName ?? ''}
                            onChange={(event) => handleTextFieldChange(event)}
                            disabled={!isEditMode} 
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={3} spacing={3} direction='column'>
                    <Grid item >
                        <TextField 
                            label='Client Contact Email' 
                            id='clientContactEmail'
                            InputLabelProps={{
                                shrink: !!clientState.clientContactEmail
                            }}
                            value={clientState.clientContactEmail ?? ''}
                            onChange={(event) => handleTextFieldChange(event)}
                            disabled={!isEditMode}
                        />
                    </Grid>
                    <Grid item >
                        <TextField 
                            label='Phone Number' 
                            id='primaryPhoneNumber'
                            InputLabelProps={{
                                shrink: !!clientState.primaryPhoneNumber
                            }}
                            value={clientState.primaryPhoneNumber ?? ''}
                            onChange={(event) => handleTextFieldChange(event)}
                            disabled={!isEditMode}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {isEditMode && 
                <div className={classes.buttonsContainer}>
                    <Button 
                        className={classes.cancelButton} 
                        onClick={
                            () => {
                                props.handleCancelClientProfileEdit();
                                setIsEditMode(false);
                            }
                        }
                    >
                        Cancel
                    </Button>
                    <Button className={classes.button} onClick={props.handleSaveEditedClientProfile} >
                        Save
                    </Button>
                </div>
            }
        </Paper>
    )
}