import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(() => ({
    claimDataContainer: {
        padding: 25,
    },
    sidebarTitle: {
        color: '#428302',
    },
    dataContainer: {
        // paddingTop: 25,
    },
    dataLabel: {
        color: '#898F91',
        fontSize: '12px',
    },
    dataRow: {
        paddingTop: 20,
    },
    referralHighlightedData: {
        color: '#428302',
        fontWeight: 'bold',
        display: 'inline',
    },
    highlightedData: {
        color: '#428302',
        fontWeight: 'bold',
    },
    iconTextRow: {
        display: 'inline-flex',
        flexDirection: 'row',
    },
    blackFont: {
        color: '#000000',
    },
    moreHorizIcon: {
        color: '#FFFFFF',
        background: '#004763',
        borderRadius: '50%',
        marginLeft: '7px',
    },
    referralData: {
        color: '#000000',
        fontWeight: 'bold',
        marginLeft: 10,
        display: 'inline',
    },
    claimData: {
        color: '#000000',
        fontWeight: 'bold',
        marginLeft: 10,
    },
    iconData: {
        color: '#000000',
        marginLeft: 10,
    },
    divider: {
        height: '2px',
        marginTop: 20,
        color: '#898F91',
    },
    lastUpdateText: {
        color: '#898F91',
        fontSize: '12px',
    },
    bottomContainer: {},
    bottomTextContainer: {
        padding: 25,
        paddingBottom: 80,
    },
    claimTitle: {
        color: '#428302',
        marginLeft: '10px',
    },
    gridContainer: {
        width: '100%',
        display: 'contents',
    },
    paper: {
        // paddingTop: '15px',
        // paddingLeft: '15px',
        // paddingRight: '15px'
    },
}));
