import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary, Divider, TextField } from '@material-ui/core';
import styles from "./RecommendedPhysicianRequest.Styles";
import { EmptyDataLabel } from '../../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StateEntity from '../../../../../../entities/State.Entity';
import ReferralContactEntity from '../../../../../../entities/ReferralContact.Entity';
import { TooltipTextField } from "../../../../../generic-components/tooltip-textfield/TooltipTextField.Component";

interface IRecommendedPhysicianRequestProps {
    physician: ReferralContactEntity,
    states: Array<StateEntity>
}

export const RecommendedPhysicianRequest: FunctionComponent<IRecommendedPhysicianRequestProps> = (props: IRecommendedPhysicianRequestProps) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>Optional Requested IME Physician</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.physician && props.states ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid item xs={3}>
                                <TooltipTextField
                                        label=' First Name'
                                        disabled={true} 
                                        value={props.physician.firstName}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                        label=' Address Line 1'
                                        disabled={true} 
                                        value={props.physician.addressLine1}
                                />
                            </Grid>
                            <Grid item xs={6} spacing={3} container>
                                <Grid item xs={6}>
                                <TooltipTextField
                                        label=' Zip Code'
                                        disabled={true} 
                                        value={props.physician.zipCode}
                                />
                                </Grid>
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                        label=' Last Name'
                                        disabled={true} 
                                        value={props.physician.lastName}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                        label=' Address Line 2'
                                        disabled={true} 
                                        value={props.physician.addressLine2}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                        label=' Primary Phone'
                                        disabled={true} 
                                        value={props.physician.primaryPhoneNumber}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                        label=' Ext.'
                                        disabled={true} 
                                        value={props.physician.primaryPhoneExtension}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                        label=' Middle initial'
                                        disabled={true} 
                                        value={props.physician.middleInitial}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                        label=' City'
                                        disabled={true} 
                                        value={props.physician.cityName}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                        label=' Secondary Phone'
                                        disabled={true} 
                                        value={props.physician.secondaryPhoneNumber}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                        label=' Ext.'
                                        disabled={true} 
                                        value={props.physician.secondaryPhoneExtension}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                        label=' Physician NPI'
                                        disabled={true} 
                                        value={props.physician.optionalPhysicianNPI}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField
                                        label=' State'
                                        disabled={true} 
                                        value={props.states?.filter(x => x.stateId == props.physician.stateId)[0]?.stateName}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TooltipTextField
                                        label=' Fax'
                                        disabled={true} 
                                        value={props.physician.fax}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField 
                                        label=" Email"
                                        disabled={true} 
                                        value={props.physician.email}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                        label=' Additional Comments'
                                        maxRows={6}
                                        multiline
                                        className={classes.input}
                                        disabled
                                        InputProps={{
                                                disableUnderline:true,
                                        }}
                                        value={props.physician.comments}
                                />
                            </Grid>
                        </Grid>
                        :
                        <EmptyDataLabel></EmptyDataLabel>
                }
            </AccordionDetails>
        </Accordion>
    );
}