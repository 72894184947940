import VendorContactDataModel from "../data-models/VendorContact.DataModel";
import VendorContactEntity from "../entities/VendorContact.Entity";
import { apiGet, apiPost } from '../support/helpers/ApiConnection.Helper';

export function createVendorContact(newVendorContact: VendorContactEntity, abortSignal: AbortSignal): Promise<VendorContactEntity> {
    return apiPost<VendorContactEntity>('/vendor-contacts', newVendorContact, abortSignal);
}

export function getVendorContacts(vendorId: number, abortSignal: AbortSignal): Promise<Array<VendorContactDataModel>> {
    return apiGet<Array<VendorContactDataModel>>('/vendor-contacts/contacts-by-vendor/' + vendorId, abortSignal);
}