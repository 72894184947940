//Rule interface
interface Rule {
    read?: string[];
    create?: string[];
    update?: string[];
    delete?: string[];
    allFamilyRules?: string[];
}

//Claims
export const claimsRules: Rule = {
    read: ['read:CLAIMS'],
    create: ['create:CLAIMS'],
    update: ['update:CLAIMS'],
};
export const claimsListRules: Rule = { read: ['read:CLAIMS.CLAIMS_LIST'] };
export const referralListRules: Rule = {
    read: ['read:CLAIMS.REFERRAL_LIST'],
    create: ['create:CLAIMS.REFERRAL_LIST'],
    update: ['update:CLAIMS.REFERRAL_LIST'],
};

//Reports
export const referralStatusRules: Rule = { read: ['read:REPORTS.REFERRAL_STATUS'] };
export const referralCountByDateRules: Rule = { read: ['read:REPORTS.REFERRAL_COUNT_BY_DATE'] };
export const generalReportRules: Rule = { read: ['read:REPORTS.GENERAL_REPORT'] };
export const billingReportRules: Rule = { read: ['read:REPORTS.BILLING_REPORT'] };
export const reportRules: Rule = {
    read: ['read:REPORTS'],
    allFamilyRules: [
        'read:REPORTS',
        ...referralStatusRules.read,
        ...referralCountByDateRules.read,
        ...generalReportRules.read,
        ...billingReportRules.read,
    ],
};

//Clients
export const clientsListRules: Rule = { read: ['read:CLIENTS.CLIENTS_LIST'] };
export const profileRules: Rule = { read: ['read:CLIENTS.PROFILE'] };
export const preferredVendorRules: Rule = { read: ['read:CLIENTS.PREFERRED_VENDOR'] };
export const clientsRules: Rule = {
    read: ['read:CLIENTS'],
    allFamilyRules: ['read:CLIENTS', ...clientsListRules.read, ...profileRules.read, ...preferredVendorRules.read],
};

//Vendor
export const vendorsListRules: Rule = { read: ['read:VENDOR.VENDOR_LIST'] };
export const vendorsRules: Rule = { read: ['read:VENDOR'], allFamilyRules: vendorsListRules.read };

//Admin
export const serviceSetUpRules: Rule = { read: ['read:ADMIN.SERVICE_SET_UP'], create: ['create:ADMIN.SERVICE_SET_UP'] };
export const errorManagementRules: Rule = {
    read: ['read:ADMIN.ERROR_MANAGEMENT'],
    update: ['update:ADMIN.ERROR_MANAGEMENT'],
};
export const referralSubmissionRules: Rule = {
    read: ['read:ADMIN.ERROR_MANAGEMENT.REFERRAL_SUBMISSION'],
    update: ['update:ADMIN.ERROR_MANAGEMENT.REFERRAL_SUBMISSION'],
};
export const vendorDocumentsRules: Rule = {
    read: ['read:ADMIN.ERROR_MANAGEMENT.VENDOR_DOCUMENTS'],
    update: ['update:ADMIN.ERROR_MANAGEMENT.VENDOR_DOCUMENTS'],
};
export const accessControlRules: Rule = {
    read: ['read:ADMIN.ACCESS_CONTROL'],
    create: ['create:ADMIN.ACCESS_CONTROL'],
    update: ['update:ADMIN.ACCESS_CONTROL'],
    delete: ['delete:ADMIN.ACCESS_CONTROL'],
};
export const usersRules: Rule = { read: ['read:ADMIN.ACCESS_CONTROL.USERS'] };
export const securityProfilesRules: Rule = {
    read: ['read:ADMIN.ACCESS_CONTROL.SECURITY_PROFILES'],
    create: ['create:ADMIN.ACCESS_CONTROL.SECURITY_PROFILES'],
    update: ['update:ADMIN.ACCESS_CONTROL.SECURITY_PROFILES'],
    delete: ['delete:ADMIN.ACCESS_CONTROL.SECURITY_PROFILES'],
};
export const adminRules: Rule = {
    read: ['read:ADMIN'],
    create: ['create:ADMIN'],
    update: ['update:ADMIN'],
    delete: ['delete:ADMIN'],
    allFamilyRules: [
        'read:ADMIN',
        ...serviceSetUpRules.read,
        ...serviceSetUpRules.create,
        ...errorManagementRules.read,
        ...errorManagementRules.update,
        ...referralSubmissionRules.read,
        ...referralSubmissionRules.update,
        ...vendorDocumentsRules.read,
        ...vendorDocumentsRules.update,
        ...accessControlRules.read,
        ...accessControlRules.create,
        ...accessControlRules.update,
        ...accessControlRules.delete,
        ...usersRules.read,
        ...securityProfilesRules.read,
        ...securityProfilesRules.create,
        ...securityProfilesRules.update,
        ...securityProfilesRules.delete,
    ],
};

//Vendor portal
export const vendorReferralList: Rule = { read: ['read:VENDOR_PORTAL.REFERRAL_LIST'] };
export const vendorPortalRules: Rule = {
    read: ['read:VENDOR_PORTAL'],
    allFamilyRules: ['read:VENDOR_PORTAL', ...vendorReferralList.read],
};
