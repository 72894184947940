import { Box, Paper } from '@material-ui/core';
import React, { FunctionComponent, useEffect } from 'react';
import styles from './ErrorManagementMenu.Styles';
import { Link, Redirect, Route, useLocation, useRouteMatch } from 'react-router-dom';
import { Section, BreadCrumb } from '../../../support/custom-hooks/useBreadCrum';
import ReferralErrorListDataModel from '../../../data-models/ReferralErrorList.DataModel';
import ReferralStatusEntity from '../../../entities/ReferralStatus.Entity';
import { ErrorManagementReferralList } from '../error-management-referral-list/ErrorManagementReferralList.Component';
import { VendorDocuments, VendorDocumentsFilter } from '../vendor-documents/VendorDocuments.Component';
import AdminReferralVendorDocumentsTableDataModel from '../../../data-models/AdminReferralVendorDocumentsTable.DataModel';
import VendorAdminTableDataModel from '../../../data-models/VendorAdminTable.DataModel';
import TenantEntity from '../../../entities/Tenant.Entity';
import AccessControl from '../../generic-components/access-control/AccessControl.Component';
import NotFound from '../../generic-components/not-found/NotFound.Component';
import {
    adminRules,
    errorManagementRules,
    referralSubmissionRules,
    vendorDocumentsRules,
} from '../../../support/constants/SecurityRules.Constants';

interface IErrorManagementMenuProps {
    referrals: Array<ReferralErrorListDataModel>;
    referralsVendorDocuments: Array<AdminReferralVendorDocumentsTableDataModel>;
    referralStatus: Array<ReferralStatusEntity>;
    setReferralReadyForSubmission: (referralId: number) => void;
    handleGetErrorManagement: () => Promise<void>;
    handleTabIndex: (tabIndex: number) => void;
    vendors: Array<VendorAdminTableDataModel>;
    tenants: Array<TenantEntity>;
    isReferralsVendorDocumentsTableLoading: boolean;
    handleGetReferralsVendorDocumentsTable: (filters: VendorDocumentsFilter) => void;
}

export const ErrorManagementMenu: FunctionComponent<IErrorManagementMenuProps> = ({
    referralStatus,
    referrals,
    setReferralReadyForSubmission,
    handleGetErrorManagement,
    handleTabIndex,
    referralsVendorDocuments,
    vendors,
    tenants,
    isReferralsVendorDocumentsTableLoading,
    handleGetReferralsVendorDocumentsTable,
}: IErrorManagementMenuProps) => {
    const classes = styles();
    const { path } = useRouteMatch();

    const errorManagementReferralListPermissions = [
        ...adminRules.read,
        ...adminRules.update,
        ...errorManagementRules.read,
        ...errorManagementRules.update,
        ...referralSubmissionRules.read,
        ...referralSubmissionRules.update,
    ];
    const vendorDocumentsPermissions = [
        ...adminRules.read,
        ...adminRules.update,
        ...errorManagementRules.read,
        ...errorManagementRules.update,
        ...vendorDocumentsRules.read,
        ...vendorDocumentsRules.update,
    ];

    const adminBreadCrumb = {
        name: 'Admin',
        goBack: false,
        link: '/admin',
        path: path,
    };

    const errorManagementBreadCrumb = {
        name: 'Error Management',
        goBack: false,
        link: '/admin/error-management',
        path: path,
    };

    const submissionBreadCrumb = {
        name: 'Submission',
        goBack: false,
        link: '/admin/error-management/submission',
        path: path,
    };

    const vendorDocumentsBreadCrumb = {
        name: 'Vendor Documents',
        goBack: false,
        link: '/admin/error-management/vendor-documents',
        path: path,
    };

    return (
        <>
            <Route path="/admin/error-management/submission" exact>
                <AccessControl
                    allowedPermissions={errorManagementReferralListPermissions}
                    sendBack={false}
                    renderNoAccess={<Redirect to="/not-found" />}
                >
                    <BreadCrumb sections={[adminBreadCrumb, errorManagementBreadCrumb, submissionBreadCrumb]} />
                    <ErrorManagementReferralList
                        referrals={referrals}
                        referralStatus={referralStatus}
                        setReferralReadyForSubmission={setReferralReadyForSubmission}
                        handleGetErrorManagement={handleGetErrorManagement}
                    />
                </AccessControl>
            </Route>
            <Route path="/admin/error-management/vendor-documents" exact>
                <AccessControl
                    allowedPermissions={vendorDocumentsPermissions}
                    sendBack={false}
                    renderNoAccess={<Redirect to="/not-found" />}
                >
                    <BreadCrumb sections={[adminBreadCrumb, errorManagementBreadCrumb, vendorDocumentsBreadCrumb]} />
                    <VendorDocuments
                        referrals={referralsVendorDocuments}
                        vendors={vendors}
                        tenants={tenants}
                        isLoading={isReferralsVendorDocumentsTableLoading}
                        handleGetReferralsVendorDocumentsTable={handleGetReferralsVendorDocumentsTable}
                    />
                </AccessControl>
            </Route>
            <Route path="/admin/error-management" exact>
                <BreadCrumb sections={[adminBreadCrumb, errorManagementBreadCrumb]} />
                <Paper className={classes.paper}>
                    <Box className={classes.linksContainer}>
                        <AccessControl allowedPermissions={errorManagementReferralListPermissions} sendBack={false}>
                            <Link
                                to={`${path}/submission`}
                                onClick={(e) => {
                                    handleTabIndex(3);
                                }}
                                className={classes.link}
                            >
                                Referral Submission
                            </Link>
                        </AccessControl>
                        <AccessControl allowedPermissions={vendorDocumentsPermissions} sendBack={false}>
                            <Link
                                to={`${path}/vendor-documents`}
                                onClick={(e) => {
                                    handleTabIndex(3);
                                }}
                                className={classes.link}
                            >
                                Vendor Documents
                            </Link>
                        </AccessControl>
                    </Box>
                </Paper>
            </Route>
        </>
    );
};
