import React, { Fragment, FunctionComponent, useEffect } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
} from '@material-ui/core';
import TextfieldMask from '../../../generic-components/texfield-mask/TextfieldMask.Component';
import styles from './ReferralClaim.Styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import Moment from 'moment';
import CoverageTypeEntity from '../../../../entities/CoverageType.Entity';
import StateEntity from '../../../../entities/State.Entity';
import ReferralClaimEntity from '../../../../entities/ReferralClaim.Entity';
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';
import { ReferralContactTypesEnum } from '../../../../support/enums/ReferralContactTypesEnum';
import { useValidationDialog } from '../../../../support/custom-hooks/useValidationDialog';
import { ClearSelect } from '../../../generic-components/clear-select/ClearSelect.Component';

interface IReferralClaimProps {
    referralId: number;
    states: Array<StateEntity>;
    coverageTypes: Array<CoverageTypeEntity>;
    referralClaim: ReferralClaimEntity;
    referralAdjuster: ReferralContactEntity;
    handleClaimEdit: any;
    handleAdjusterEdit: any;
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean, referralContactType: string) => void;
    handleSetIsValidFunction: (currentFormIsValidFunction: () => boolean, referralContactType: string) => void;
    handleSetIsValidClaimFunction: (currentFormIsValidFunction: () => boolean) => void;
    openCloseStatus: Object;
    handleOpenCloseStatus: (boxName: string, isOpened: boolean) => void;
    serviceId: number;
    referralNurseCaseManager: ReferralContactEntity;
    handleNurseCaseManagerEdit: any;
    handleUpdateAdjuster: (currentPageValidationFunction: () => boolean) => void;
    handleUpdateClaim: (currentPageValidationFunction: () => boolean) => void;
}

interface IErrors {
    claimNumber?: string;
    claimCoverageType?: string;
    claimStateOfJurisdiction?: string;
    adjusterFirstName?: string;
    adjusterLastName?: string;
    adjusterPrimaryPhone?: string;
    adjusterAddressLine1?: string;
    adjusterAddressLine2?: string;
    adjusterZipCode?: string;
    adjusterCityName?: string;
    adjusterStateId?: string;
    adjusterEmail?: string;
    adjusterFax?: string;
    nurseCompany?: string;
    nurseFirstName?: string;
    nurseLastName?: string;
    nurseEmail?: string;
    nursePrimaryPhone?: string;
    nurseFax?: string;
    dateOfLoss?: string;
}

export const ReferralClaim: FunctionComponent<IReferralClaimProps> =  (props: IReferralClaimProps) => {

    const classes = styles();
    const abortController = new AbortController();

    const [referralClaimState, setreferralClaimState] = React.useState(props.referralClaim);
    const [referralAdjusterState, setreferralAdjusterState] = React.useState(props.referralAdjuster);
    const [referralNurseCaseManagerState, setReferralNurseCaseManagerState] = React.useState(
        props.referralNurseCaseManager,
    );
    const [errors, setErrors] = React.useState<IErrors>(null);
    const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState<boolean>(false);
    const { Dialog, checkForErrors } = useValidationDialog('Validation', 'Some required fields are missing.', 'Ok');

    //componentDidMount
    useEffect(() => {
        window.scrollTo(0,0);
        if ( !props.openCloseStatus.hasOwnProperty("ClaimAdjuster_box" ) ) {
            props.handleOpenCloseStatus("ClaimAdjuster_box", true);}
        if ( !props.openCloseStatus.hasOwnProperty("NurseCaseManager_box" ) ) props.handleOpenCloseStatus("NurseCaseManager_box", true);
    },[]);
    
    useEffect(() => {
        setreferralAdjusterState(props.referralAdjuster);
        setreferralClaimState(props.referralClaim);

        let newNurseCaseManager = props.referralNurseCaseManager;
        if (!newNurseCaseManager) {
            newNurseCaseManager = {
                referralId: props.referralId,
                contactTypeId: ReferralContactTypesEnum.NurseCaseManager,
            } as ReferralContactEntity;
            props.handleNurseCaseManagerEdit(newNurseCaseManager);
        }
        setReferralNurseCaseManagerState(newNurseCaseManager);
        //props.handleSetValidationFunction(validate, "Adjuster");
        props.handleSetIsValidFunction(isValid, "Adjuster");
        props.handleSetIsValidFunction(isValidClaim, "Claim");
        return function cleanup() {
            abortController.abort();
        };
    }, [props]);

    const handleOpenCloseStatus = ( event, boxName ) => {
        event.persist();
        props.handleOpenCloseStatus( boxName, !(props.openCloseStatus?.[boxName]??true) );
    };

    const handleTextFieldChangeAdjuster = (event) => {
        event.persist();
        let newReferralAdjusterState: ReferralContactEntity = referralAdjusterState;
        newReferralAdjusterState[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;
        props.handleAdjusterEdit(newReferralAdjusterState);
        setreferralAdjusterState(newReferralAdjusterState);
        if (hasBeenSubmitted) {
            validate(true);
        }
    };

    const handleClearSelectFieldAdjuster = (selectId: string) => {
        let newReferralAdjusterState: ReferralContactEntity = referralAdjusterState;
        newReferralAdjusterState[selectId] = null;
        props.handleAdjusterEdit(newReferralAdjusterState);
        setreferralAdjusterState(newReferralAdjusterState);
        if (hasBeenSubmitted) {
            validate(true);
        }
    };

    const handleTextFieldChangeNurseCaseManager = (event) => {
        event.persist();
        let newReferralNurseCaseManager: ReferralContactEntity = referralNurseCaseManagerState;
        newReferralNurseCaseManager[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;
        props.handleNurseCaseManagerEdit(newReferralNurseCaseManager);
        setReferralNurseCaseManagerState(newReferralNurseCaseManager);
        if (hasBeenSubmitted) {
            validate(true);
        }
    };

    const handleTextFieldChangeClaim = (event) => {
        event.persist();
        let newReferralClaimEntitye: ReferralClaimEntity = referralClaimState;
        newReferralClaimEntitye[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;
        setreferralClaimState(newReferralClaimEntitye);
        props.handleClaimEdit(newReferralClaimEntitye);
        if (hasBeenSubmitted) {
            validateClaim(true);
        }
    };

    const validate = (isChange?: boolean) => {
        let errors: IErrors = {};
        errors = getErrorsList()
        setErrors(errors);
        return checkForErrors(errors, isChange);
    };

    const validateClaim = (isChange?: boolean) => {
        let errors: IErrors = {};
        errors = getErrorsListClaim()
        setErrors(errors);
        return checkForErrors(errors, isChange);
    };

    const isValid = () => {
        let errors: IErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsList();
        setErrors(errors);
        isValidResult = (Object.keys(errors).length == 0);
        return isValidResult;
    }
    const isValidClaim = () => {
        let errors: IErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsListClaim();
        setErrors(errors);
        isValidResult = (Object.keys(errors).length == 0);
        return isValidResult;
    }

    const getErrorsList = () => { //returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: IErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }
        
        if ([
            ServicesEnum.IME]
            .includes( props.serviceId )){
                if (!props.referralClaim?.dateOfLoss)  errors.adjusterPrimaryPhone = 'Required';
        }

        if (!props.referralAdjuster?.firstName) errors.adjusterFirstName = 'Required';
        if (!props.referralAdjuster?.lastName)  errors.adjusterLastName = 'Required';
        
        if ([
            ServicesEnum.IME]
            .includes( props.serviceId )){
                if (!props.referralAdjuster?.primaryPhoneNumber)  errors.adjusterPrimaryPhone = 'Required';
                if (!props.referralAdjuster?.email)  errors.adjusterEmail = 'Required';

                if (
                    props.referralAdjuster?.addressLine1
                    && props.referralAdjuster?.cityName
                    && props.referralAdjuster?.stateId
                    && props.referralAdjuster?.zipCode ){
                        if (!props.referralAdjuster?.addressLine1)  errors.adjusterAddressLine1 = 'Required';
                        if (!props.referralAdjuster?.cityName)  errors.adjusterCityName = 'Required';
                        if (!props.referralAdjuster?.stateId)  errors.adjusterStateId = 'Required';
                        if (!props.referralAdjuster?.zipCode)  errors.adjusterZipCode = 'Required';
                    }
        }

        return errors;
    }

    const getErrorsListClaim = () => { //returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: IErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }
        if (!props.referralClaim?.claimNumber) errors.claimNumber = 'Required';
        if (!props.referralClaim?.stateOfJurisdictionId) errors.claimStateOfJurisdiction = 'Required';

        if ([
            ServicesEnum.IME,
            ServicesEnum.PT,
            ServicesEnum.UR,
            ServicesEnum.DME]
            .includes( props.serviceId )){
                if (!props.referralClaim?.coverageTypeId)  errors.claimCoverageType = 'Required';
        }

        return errors;
    }

    return (
        <>
        {/*<form noValidate autoComplete="off">*/}
            <Grid container spacing={3}>
                <div className={classes.hideComponent}>{/** hide component requested by DiegoH: it was hiden just in case the client could request it again */}
                <Grid item xs={12}>
                    <Accordion defaultExpanded={true} className={classes.paper}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                            aria-controls="panel1a-content"
                            id="cliam-accordion"
                            className={classes.paperHeader}
                        >
                            <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                Claim
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {referralClaimState ? (
                                <Grid container spacing={3} className={classes.gridContainer}>
                                    <Grid item xs={3}>
                                        <TextField
                                            disabled={!!referralClaimState.claimNumber}
                                            label="Claim Number"
                                            id="claimNumber"
                                            onChange={(event) => handleTextFieldChangeClaim(event)}
                                            key={referralClaimState.claimNumber}
                                            value={referralClaimState.claimNumber}
                                            required
                                            error={!!errors?.claimNumber}
                                            helperText={errors?.claimNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Date Of Loss"
                                            id="dateOfLoss"
                                            type={referralClaimState?.dateOfLoss ? 'text' : 'date'}
                                            InputLabelProps={{ shrink: true }}
                                            onChange={(event) => handleTextFieldChangeClaim(event)}
                                            key={referralClaimState.dateOfLoss.toString()}
                                            value={Moment(referralClaimState?.dateOfLoss).format('MM/DD/YYYY')}
                                            disabled={!!referralClaimState.dateOfLoss}
                                            required={
                                                props.serviceId === ServicesEnum.Diagnostic ||
                                                props.serviceId === ServicesEnum.IME
                                            }
                                            error={!!errors?.dateOfLoss}
                                            helperText={errors?.dateOfLoss}
                                        />
                                    </Grid>
                                    <Grid item xs={3} container>
                                        <FormControl
                                            className={classes.selectInput}
                                            error={!!errors?.claimCoverageType}
                                            required={props.serviceId !== ServicesEnum.Diagnostic}
                                        >
                                            <InputLabel id="demo-simple-select-label">Coverage</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="coverageTypeId"
                                                key={referralClaimState.coverageTypeId}
                                                value={referralClaimState.coverageTypeId}
                                                disabled={!!referralClaimState.coverageTypeId}
                                                onChange={(event) => handleTextFieldChangeClaim(event)}
                                            >
                                                {props.coverageTypes.map((coverageType, index) => {
                                                    return (
                                                        <MenuItem
                                                            key={coverageType.coverageTypeId}
                                                            value={coverageType.coverageTypeId}
                                                        >
                                                            {coverageType.coverageTypeName}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {props.serviceId === ServicesEnum.Diagnostic &&
                                                errors?.claimCoverageType && <FormHelperText>Required</FormHelperText>}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl
                                            className={classes.selectInput}
                                            error={!!errors?.claimStateOfJurisdiction}
                                            required
                                        >
                                            <InputLabel id="demo-simple-select-label">State Of Jurisdiction</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="stateOfJurisdictionId"
                                                key={referralClaimState.stateOfJurisdictionId}
                                                value={referralClaimState.stateOfJurisdictionId}
                                                disabled={!!referralClaimState.stateOfJurisdictionId}
                                                onChange={(event) => handleTextFieldChangeClaim(event)}
                                                style={{ width: '100%' }}
                                            >
                                                {props.states.map((state, index) => {
                                                    return (
                                                        <MenuItem key={state.stateId} value={state.stateId}>
                                                            {state.stateName}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {errors?.claimStateOfJurisdiction && (
                                                <FormHelperText>Required</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    {props.serviceId === ServicesEnum.PT ||
                                    props.serviceId === ServicesEnum.UR ||
                                    props.serviceId === ServicesEnum.Diagnostic ||
                                    props.serviceId === ServicesEnum.TT ||
                                    props.serviceId === ServicesEnum.DME ? (
                                        <Fragment>
                                            <Grid item xs={3}>
                                                <TextField
                                                    disabled={true}
                                                    label="Claim Type"
                                                    id="claimType"
                                                    onChange={(event) => handleTextFieldChangeClaim(event)}
                                                    key={referralClaimState.claimType}
                                                    value={referralClaimState.claimType}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    disabled={true}
                                                    label="Claim Status"
                                                    id="claimStatus"
                                                    onChange={(event) => handleTextFieldChangeClaim(event)}
                                                    key={referralClaimState.claimStatus}
                                                    value={referralClaimState.claimStatus}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    disabled={referralClaimState.lockedClosedDate}
                                                    label="Date the claim was closed"
                                                    id="closedDate"
                                                    type="date"
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(event) => handleTextFieldChangeClaim(event)}
                                                    value={
                                                        (referralClaimState.closedDate as string)?.split('T')[0]
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    disabled={referralClaimState.lockedMedicalDate}
                                                    label="Date the claim became medical only"
                                                    type="date"
                                                    InputLabelProps={{ shrink: true }}
                                                    id="medicalDate"
                                                    onChange={(event) => handleTextFieldChangeClaim(event)}
                                                    value={
                                                        (referralClaimState.medicalDate as string)?.split('T')[0]
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    disabled={referralClaimState.lockedLostTimeDate}
                                                    label="Date the claim became lost time"
                                                    type="date"
                                                    InputLabelProps={{ shrink: true }}
                                                    id="lostTimeDate"
                                                    onChange={(event) => handleTextFieldChangeClaim(event)}
                                                    value={
                                                        (referralClaimState.lostTimeDate as string)?.split('T')[0]
                                                    }
                                                />
                                            </Grid>
                                        </Fragment>
                                    ) : (
                                        ''
                                    )}
                                </Grid>
                            ) : (
                                <EmptyDataLabel></EmptyDataLabel>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                </div>
                {props.serviceId === ServicesEnum.PT ||
                props.serviceId === ServicesEnum.Diagnostic ||
                props.serviceId === ServicesEnum.DME ? (
                    <Grid item xs={12}>
                        <Accordion className={classes.paper} expanded={props.openCloseStatus["NurseCaseManager_box"]??true}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                                aria-controls="panel1a-content"
                                id="NurseCaseManager-accordion"
                                className={classes.paperHeader}
                                onClick={(event) => handleOpenCloseStatus(event, "NurseCaseManager_box")}
                            >
                                <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                    Nurse Case Manager
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                {referralNurseCaseManagerState ? (                                    
                                    <Grid container spacing={3} className={classes.gridContainer}>
                                        <Grid item xs={3}>
                                            <TextField
                                                disabled={referralNurseCaseManagerState.lockedName}
                                                label="Case Management Company"
                                                id="name"
                                                value={referralNurseCaseManagerState.name}
                                                onChange={(event) => handleTextFieldChangeNurseCaseManager(event)}
                                                error={!!errors?.nurseCompany}
                                                helperText={errors?.nurseCompany}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                disabled={referralNurseCaseManagerState.lockedAddressLine1}
                                                label="Address Line 1"
                                                id="addressLine1"
                                                onChange={(event) => handleTextFieldChangeNurseCaseManager(event)}
                                                defaultValue={referralNurseCaseManagerState.addressLine1}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl className={classes.selectInput}>
                                                <InputLabel id="demo-simple-select-label">State</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="stateId"
                                                    name="stateId"
                                                    value={referralNurseCaseManagerState.stateId}
                                                    disabled={referralNurseCaseManagerState.lockedStateId}
                                                    onChange={(event) => handleTextFieldChangeNurseCaseManager(event)}
                                                >
                                                    {props.states?.map((state, index) => {
                                                        return (
                                                            <MenuItem key={state.stateId} value={state.stateId}>
                                                                {state.stateName}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextfieldMask
                                                mask="(999)-9999999"
                                                disabled={referralNurseCaseManagerState.lockedPrimaryPhoneNumber}
                                                onChange={(event) => handleTextFieldChangeNurseCaseManager(event)}
                                                value={referralNurseCaseManagerState.primaryPhoneNumber}
                                                label="Primary Phone"
                                                id="primaryPhoneNumber"
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <TextField
                                                disabled={referralNurseCaseManagerState.lockedFirstName}
                                                label="Nurse First Name"
                                                id="firstName"
                                                onChange={(event) => handleTextFieldChangeNurseCaseManager(event)}
                                                value={referralNurseCaseManagerState.firstName}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                disabled={referralNurseCaseManagerState.lockedAddressLine2}
                                                label="Address Line 2"
                                                id="addressLine2"
                                                onChange={(event) => handleTextFieldChangeNurseCaseManager(event)}
                                                defaultValue={referralNurseCaseManagerState.addressLine2}
                                            />
                                        </Grid>
                                        {props.serviceId === ServicesEnum.Diagnostic ? (
                                            <Grid
                                                container
                                                xs={3}
                                                spacing={1}
                                                style={{ marginTop: '9px', marginRight: '4px' }}
                                            >
                                                <Grid item xs={3} style={{ marginLeft: '15px', marginRight: '-8px' }}>
                                                    <TextField
                                                        disabled={referralNurseCaseManagerState.lockedZipCode}
                                                        label="Zip Code"
                                                        id="ZipCode"
                                                        onChange={(event) =>
                                                            handleTextFieldChangeNurseCaseManager(event)
                                                        }
                                                        defaultValue={referralNurseCaseManagerState.zipCode}
                                                        style={{ width: '110px' }}
                                                    />
                                                </Grid>
                                                <Grid item xs={1}>
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        style={{
                                                            marginTop: '17px',
                                                            marginLeft: '15px',
                                                            marginRight: '-8%',
                                                        }}
                                                    >
                                                        _
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={3} style={{ marginRight: '15px' }}>
                                                    <TextField
                                                        disabled={referralNurseCaseManagerState.lockedCodeExtension}
                                                        label="Zip Code Ext."
                                                        id="zipCodeExtension"
                                                        onChange={(event) =>
                                                            handleTextFieldChangeNurseCaseManager(event)
                                                        }
                                                        value={referralNurseCaseManagerState.zipCodeExtension}
                                                        style={{ width: '100px' }}
                                                        inputProps={{ maxLength: 4 }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid item xs={3}>
                                                <TextField
                                                    disabled={referralNurseCaseManagerState.lockedZipCode}
                                                    label="Zip Code"
                                                    id="ZipCode"
                                                    onChange={(event) => handleTextFieldChangeNurseCaseManager(event)}
                                                    defaultValue={referralNurseCaseManagerState.zipCode}
                                                />
                                            </Grid>
                                        )}
                                        <Grid item xs={3}>
                                            <TextfieldMask
                                                mask="(999)-9999999"
                                                disabled={referralNurseCaseManagerState.lockedFax}
                                                onChange={(event) => handleTextFieldChangeNurseCaseManager(event)}
                                                value={referralNurseCaseManagerState.fax}
                                                label="Fax"
                                                id="fax"
                                            />
                                        </Grid>

                                        <Grid item xs={3}>
                                            <TextField
                                                disabled={referralNurseCaseManagerState.lockedLastName}
                                                label="Nurse Last Name"
                                                id="lastName"
                                                value={referralNurseCaseManagerState.lastName}
                                                onChange={(event) => handleTextFieldChangeNurseCaseManager(event)}
                                                defaultValue={referralNurseCaseManagerState.lastName}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                disabled={referralNurseCaseManagerState.lockedCityName}
                                                label="City"
                                                id="cityName"
                                                onChange={(event) => handleTextFieldChangeNurseCaseManager(event)}
                                                defaultValue={referralNurseCaseManagerState.cityName}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <TextField
                                                disabled={referralNurseCaseManagerState.lockedEmail}
                                                label="Email"
                                                id="email"
                                                onChange={(event) => handleTextFieldChangeNurseCaseManager(event)}
                                                defaultValue={referralNurseCaseManagerState.email}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            {/* Empty space */}
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <EmptyDataLabel></EmptyDataLabel>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                ) : (
                    ''
                )}
                <Grid item xs={12}>
                    <Accordion className={classes.paper} expanded={props.openCloseStatus["ClaimAdjuster_box"]??true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                            aria-controls="panel1a-content"
                            id="adjuster-accordion"
                            className={classes.paperHeader}
                            onClick={(event) => handleOpenCloseStatus(event, "ClaimAdjuster_box")}
                        >
                            <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                Adjuster
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {referralAdjusterState ? (
                                <Grid container spacing={3} className={classes.gridContainer}>
                                    <Grid item xs={3}>
                                        <TextField
                                            disabled={true}
                                            label="First Name"
                                            id="firstName"
                                            onChange={(event) => handleTextFieldChangeAdjuster(event)}
                                            value={referralAdjusterState.firstName}
                                            required
                                            error={!!errors?.adjusterFirstName}
                                            helperText={errors?.adjusterFirstName}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl className={classes.selectInput}>
                                            <InputLabel id="demo-simple-select-label">State</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="stateId"
                                                name="stateId"
                                                value={referralAdjusterState.stateId}
                                                disabled={true}
                                                onChange={(event) => handleTextFieldChangeAdjuster(event)}
                                                endAdornment={
                                                    <ClearSelect
                                                        shouldRender={
                                                            referralAdjusterState.stateId &&
                                                            !referralAdjusterState.lockedStateId
                                                        }
                                                        onClick={() => handleClearSelectFieldAdjuster('stateId')}
                                                    />
                                                }
                                                error={!!errors?.adjusterStateId}
                                            >
                                                {props.states.map((state, index) => {
                                                    return (
                                                        <MenuItem key={state.stateId} value={state.stateId}>
                                                            {state.stateName}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            {errors?.adjusterStateId && (
                                                <FormHelperText style={{ color: 'red' }}>Required</FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask
                                            mask="(999)-9999999"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeAdjuster(event)}
                                            value={referralAdjusterState.primaryPhoneNumber?? ''}
                                            required={props.serviceId === ServicesEnum.IME}
                                            error={!!errors?.adjusterPrimaryPhone}
                                            helperText={errors?.adjusterPrimaryPhone}
                                            label="Primary Phone"
                                            id="primaryPhoneNumber"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            disabled={true}
                                            label="Ext."
                                            id="primaryPhoneExtension"
                                            onChange={(event) => handleTextFieldChangeAdjuster(event)}
                                            value={referralAdjusterState.primaryPhoneExtension?? ''}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            disabled={true}
                                            label="Last Name"
                                            id="lastName"
                                            onChange={(event) => handleTextFieldChangeAdjuster(event)}
                                            value={referralAdjusterState.lastName?? ''}
                                            required
                                            error={!!errors?.adjusterLastName}
                                            helperText={errors?.adjusterLastName}
                                        />
                                    </Grid>
                                    {props.serviceId === ServicesEnum.Diagnostic ? (
                                        <Grid
                                            container
                                            xs={3}
                                            spacing={1}
                                            style={{ marginTop: '9px', marginRight: '4px' }}
                                        >
                                            <Grid item xs={3} style={{ marginLeft: '15px', marginRight: '-8px' }}>
                                                <TextField
                                                    disabled={true}
                                                    label="Zip Code"
                                                    id="zipCode"
                                                    onChange={(event) => handleTextFieldChangeAdjuster(event)}
                                                    value={referralAdjusterState.zipCode?? ''}
                                                    style={{ width: '110px' }}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                        marginTop: '17px',
                                                        marginLeft: '15px',
                                                        marginRight: '-8%',
                                                    }}
                                                >
                                                    _
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3} style={{ marginRight: '15px' }}>
                                                <TextField
                                                    disabled={true}
                                                    label="Zip Code Ext."
                                                    id="zipCodeExtension"
                                                    onChange={(event) => handleTextFieldChangeAdjuster(event)}
                                                    value={referralAdjusterState.zipCodeExtension?? ''}
                                                    style={{ width: '100px' }}
                                                    inputProps={{ maxLength: 4 }}
                                                />
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={3}>
                                            <TextField
                                                disabled={true}
                                                label="Zip Code"
                                                id="zipCode"
                                                onChange={(event) => handleTextFieldChangeAdjuster(event)}
                                                value={referralAdjusterState.zipCode?? ''}
                                                error={!!errors?.adjusterZipCode}
                                                helperText={errors?.adjusterZipCode}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={3}>
                                        <TextfieldMask
                                            mask="(999)-9999999"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeAdjuster(event)}
                                            value={referralAdjusterState.secondaryPhoneNumber?? ''}
                                            label="Secondary Phone"
                                            id="secondaryPhoneNumber"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            disabled={true}
                                            label="Ext."
                                            id="secondaryPhoneExtension"
                                            onChange={(event) => handleTextFieldChangeAdjuster(event)}
                                            value={referralAdjusterState.secondaryPhoneExtension?? ''}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            disabled={true}
                                            label="Address Line 1"
                                            id="addressLine1"
                                            onChange={(event) => handleTextFieldChangeAdjuster(event)}
                                            value={referralAdjusterState.addressLine1?? ''}
                                            error={!!errors?.adjusterAddressLine1}
                                            helperText={errors?.adjusterAddressLine1}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            disabled={true}
                                            label="Email"
                                            id="email"
                                            onChange={(event) => handleTextFieldChangeAdjuster(event)}
                                            value={referralAdjusterState.email?? ''}
                                            required={props.serviceId === ServicesEnum.IME}
                                            error={!!errors?.adjusterEmail}
                                            helperText={errors?.adjusterEmail}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask
                                            mask="(999)-9999999"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeAdjuster(event)}
                                            value={referralAdjusterState.fax?? ''}
                                            label="Fax"
                                            id="fax"
                                            error={!!errors?.adjusterFax}
                                            helperText={errors?.adjusterFax}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        {/* Empty space */}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled={true}
                                                    label="Address Line 2"
                                                    id="addressLine2"
                                                    onChange={(event) => handleTextFieldChangeAdjuster(event)}
                                                    value={referralAdjusterState.addressLine2?? ''}
                                                    error={!!errors?.adjusterAddressLine2}
                                                    helperText={errors?.adjusterAddressLine2}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    disabled={true}
                                                    label="City"
                                                    id="cityName"
                                                    onChange={(event) => handleTextFieldChangeAdjuster(event)}
                                                    value={referralAdjusterState.cityName?? ''}
                                                    error={!!errors?.adjusterCityName}
                                                    helperText={errors?.adjusterCityName}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <FormControl className={classes.selectInput}>
                                            <TextField
                                                multiline={true}
                                                label="Additional Comments"
                                                id="comments"
                                                onChange={(event) => handleTextFieldChangeAdjuster(event)}
                                                value={referralAdjusterState.comments?? ''}
                                                inputProps={{ maxLength: 250 }}
                                                helperText={`${
                                                    referralAdjusterState?.comments
                                                        ? referralAdjusterState?.comments.length
                                                        : 0
                                                }/${250}`}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            ) : (
                                <EmptyDataLabel></EmptyDataLabel>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                
            </Grid>
            {Dialog}
        {/*</form>*/}
        </>
    );
};
