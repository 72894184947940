import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    paper: {
        marginTop: '20px',
        //padding:'10px',
        //paddingTop: '15px',
        //paddingLeft: '35px',
        //paddingRight: '35px',
        //paddingBottom: '25px',
    },
    button: {
        color: 'white',
        backgroundColor: '#107A98',
        marginLeft: '40px',
        '&:hover': {
            backgroundColor: '#004763!important',
        },
    },
    container: {
        marginTop: '20px',
    },
    paperTitle: {
        color: '#428302',
        marginBottom: '15px',
    },
    paperHeader: {
        padding: '0',
        marginLeft: '15px',
        flexDirection: 'row-reverse',
    },
    paperHeaderIcon: {
        color: '#428302',
    },
    sidebarTitle: {
        color: '#107A98',
        cursor: 'pointer',
    },
    parentTitleIcon: {},
    childContainer: {
        backgroundColor: '#eee',
        paddingLeft: '15px',
        paddingTop: '15px',
    },
    gridContainer: {
        paddingLeft: '15px',
    },
    checkbox: {
        paddingLeft: '10px',
    },
}));
