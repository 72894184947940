import React, { Fragment, FunctionComponent } from 'react';
import { List, Collapse, ListItem, ListItemText, FormControlLabel, Radio, Checkbox } from '@material-ui/core';
import styles from './ReferralService.Styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ServiceDataModel from '../../../../data-models/Service.DataModel';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';
import { toast } from 'react-toastify';

interface IReferralServiceListProps {
    service: ServiceDataModel;
    handleServiceTypesSelected: (serviceTypeSelected: number[]) => void;
    expanded?: boolean;
    serviceSelected: number;
    setServiceSelected: (serviceSelected: number) => void;
    serviceTypesSelected: Array<number>;
}

export const ReferralServiceList: FunctionComponent<IReferralServiceListProps> = ({
    serviceSelected,
    setServiceSelected,
    service,
    handleServiceTypesSelected,
    expanded,
    serviceTypesSelected,
}: IReferralServiceListProps) => {
    const classes = styles();
    const [openServiceE, setOpenService] = React.useState(expanded ? expanded : false);

    const handleClick = () => {
        setOpenService(!openServiceE);
    };

    const myHandleServiceTypeSelected = (serviceTypeSelected: number, serviceId: number) => {
        const newArrayOfServiceTypesNotDiagnostic = [];        
        if (!isNaN(serviceTypeSelected)) {
            setServiceSelected(serviceId);
            newArrayOfServiceTypesNotDiagnostic.push(serviceTypeSelected);
            handleServiceTypesSelected(newArrayOfServiceTypesNotDiagnostic);
        }
    };

    const handleMultipleServiceCheckbox = (serviceTypeSelected: number, serviceId: number) => {                
        let newArrayOfServiceTypes: Array<number> = [...serviceTypesSelected];
        const indexOfSelected = newArrayOfServiceTypes.indexOf(serviceTypeSelected);

        if (serviceId !== serviceSelected) {
            newArrayOfServiceTypes = [];
            setServiceSelected(serviceId);
        }

        if (indexOfSelected !== -1 && newArrayOfServiceTypes.length > 0) {
            newArrayOfServiceTypes.splice(indexOfSelected, 1);
            handleServiceTypesSelected(newArrayOfServiceTypes);
        } else if (indexOfSelected == -1 && !isNaN(serviceTypeSelected)) {
            if(serviceId==6 && newArrayOfServiceTypes.length ==2)
                {
                    toast.warning('⚠️ ' + 'you only can select up to 2 service types');
                }
            else if(newArrayOfServiceTypes.length<=2)
            {
                newArrayOfServiceTypes.push(serviceTypeSelected);
                handleServiceTypesSelected(newArrayOfServiceTypes);
            }
            else
            {
                toast.warning('⚠️ ' + 'you only can select up to 3 services');
            }
        }
    };

    const getTitle = () => (<><span className={classes.listItemTitle}>{service.service.serviceName}</span><span className={classes.subtitleDiagnostic}>{openServiceE && service.service.serviceId === ServicesEnum.Diagnostic && 'Select up to 3'}</span></>);

    return (
        <Fragment>
            {service.serviceTypes.length > 0 ? (
                <Fragment>
                    <ListItem className={classes.serviceTitle} button onClick={handleClick}>
                        {openServiceE ? <ExpandLess /> : <ExpandMore />}
                        <ListItemText
                            primary={getTitle()}
                        />
                    </ListItem>
                    <Collapse in={openServiceE} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {service.service.serviceId === ServicesEnum.Diagnostic ||
                            service.service.serviceId === ServicesEnum.TT
                                ? service.serviceTypes.map((servicetype, sindex) => {
                                      return (
                                          <ListItem key={servicetype.serviceTypeId} className={classes.nested}>
                                              <ListItemText>
                                                  <FormControlLabel
                                                      onClick={(event) => {
                                                        handleMultipleServiceCheckbox(
                                                            +(event.target as HTMLInputElement).value,
                                                            servicetype.serviceId,
                                                        )
                                                      }}
                                                      value={servicetype.serviceTypeId}
                                                      control={
                                                          <Checkbox
                                                              className={classes.radioButton}
                                                              checked={
                                                                  serviceTypesSelected.findIndex(
                                                                      (sts) => sts == servicetype.serviceTypeId,
                                                                  ) !== -1
                                                              }
                                                          />
                                                      }
                                                      label={
                                                          <span className={classes.radioButtonLabel}>
                                                              {servicetype.serviceTypeName}
                                                          </span>
                                                      }
                                                  />
                                              </ListItemText>
                                          </ListItem>
                                      );
                                  })
                                : service.serviceTypes.map((servicetype, sindex) => {
                                      return (
                                          <ListItem key={servicetype.serviceTypeId} className={classes.nested}>
                                              <ListItemText>
                                                  <FormControlLabel
                                                      onClick={(event) =>
                                                          myHandleServiceTypeSelected(
                                                              +(event.target as HTMLInputElement).value,
                                                              service.service.serviceId,
                                                          )
                                                      }
                                                      value={servicetype.serviceTypeId}
                                                      control={
                                                          <Radio
                                                              className={classes.radioButton}
                                                              checked={
                                                                  serviceTypesSelected.findIndex(
                                                                      (sts) => sts == servicetype.serviceTypeId,
                                                                  ) !== -1
                                                              }
                                                          />
                                                      }
                                                      label={
                                                          <span className={classes.radioButtonLabel}>
                                                              {servicetype.serviceTypeName}
                                                          </span>
                                                      }
                                                  />
                                              </ListItemText>
                                          </ListItem>
                                      );
                                  })}
                        </List>
                    </Collapse>
                </Fragment>
            ) : (
                ''
            )}
        </Fragment>
    );
};
