import React, { Fragment, FunctionComponent } from 'react';
import styles from './ReferralDocuments.Styles';
import { Grid, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReferralOverviewDataModel } from '../../../../data-models/ReferralOverview.DataModel';
import ReferralDocumentEntity from '../../../../entities/ReferralDocument.Entity';
import { ResponseDocumentList } from '../response-document-list/ResponseDocumentList.Component';

type PathParamsType = {
    id: string;
    claimId: string;
};

type ReferralDocumentsProps = {
    referralDocuments: Array<ReferralDocumentEntity>;
    referralOverviewDataModel: ReferralOverviewDataModel;
} & WithStyles<typeof styles> &
    RouteComponentProps<PathParamsType>;

const ReferralDocumentsComponent: FunctionComponent<ReferralDocumentsProps> = (props: ReferralDocumentsProps) => {
    const { classes } = props;

    return (
        <Fragment>
            {
                <div className={classes.content}>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <ResponseDocumentList
                                    referralDocuments={props.referralDocuments}
                                ></ResponseDocumentList>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            }
        </Fragment>
    );
};

export const ReferralDocuments = withRouter(withStyles(styles)(ReferralDocumentsComponent));
