import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary, Button } from '@material-ui/core';
import styles from "./ReferralSummary.Styles";
import Moment from 'moment';
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import ServiceTypeEntity from '../../../../entities/ServiceType.Entity';
import VendorEntity from '../../../../entities/Vendor.Entity';
import ReferralStatusEntity from '../../../../entities/ReferralStatus.Entity';
import ActivityEntity from '../../../../entities/Activity.Entity';
import { ReferralStatusTypesEnum } from '../../../../support/enums/ReferralStatusTypesEnum';
import { RouteComponentProps } from 'react-router-dom';
import { ServicesEnum } from "../../../../support/enums/Services.Enum";
import UtilizationReviewServiceDataModel from "../../../../data-models/UtilizationReviewService.DataModel";
import DiagnosticServiceDataModel from "../../../../data-models/DiagnosticService.DataModel";
import MedicalServiceDataModel from "../../../../data-models/MedicalService.DataModel";
import ReferralEntity from '../../../../entities/Referral.Entity';
import TranslationDataModel from '../../../../data-models/Translation.DataModel';
import TransportationDataModel from '../../../../data-models/Transportation.DataModel';
import { translationTypes } from '../../../../support/constants/TranslationTypes.Constants';

interface IReferralSummaryProps {
    serviceType: Array<ServiceTypeEntity>,
    vendor: VendorEntity,
    referralStatus: ReferralStatusEntity,
    referralActivity: ActivityEntity,
    referralId: number,
    claimId: number,
    utilizationReviewService: UtilizationReviewServiceDataModel,
    diagnosticService: DiagnosticServiceDataModel,
    medicalService: MedicalServiceDataModel,
    transportationService: TransportationDataModel,
    translationService: TranslationDataModel,
    referral: ReferralEntity,
}

type ReferralSummaryProps = RouteComponentProps & IReferralSummaryProps

export const ReferralSummary: FunctionComponent<ReferralSummaryProps> = (props: ReferralSummaryProps) => {
    const [shouldShrink, setShouldShrink] = React.useState<boolean>(false);
    const classes = styles();
    React.useEffect(() => {
        if(props.serviceType && (props.serviceType[0]?.serviceId === ServicesEnum.UR || props.serviceType[0]?.serviceId === ServicesEnum.Diagnostic || props.serviceType[0]?.serviceId === ServicesEnum.PT || props.serviceType[0]?.serviceId === ServicesEnum.TT)){
            setShouldShrink(true);
        } else {
            setShouldShrink(false);
        }
    }, [props.serviceType]);

    const handleGoEdit = () => {
        props.history.push("/claims/" + props.claimId + "/referrals/edit/" + props.referralId);
    }

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>Referral</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.serviceType && props.vendor && props.referralStatus ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Service Type</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.serviceType.map( (st, idx) => `${st.serviceTypeName}${idx != props.serviceType.length -1 ? ', ' : ''}`  )}</Typography>
                            </Grid>
                            <Grid item xs={shouldShrink ? 2 : 3}>
                                <Typography variant="body2" className={classes.dataLabel}>Vendor</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.vendor.vendorName}</Typography>
                            </Grid>
                            <Grid item xs={shouldShrink ? 2 : 3}>
                                <Typography variant="body2" className={classes.dataLabel}>Status</Typography>
                                <Grid item xs={12}>
                                    {
                                        props.referralStatus.referralStatusId == ReferralStatusTypesEnum.SubmissionFailed || props.referralStatus.referralStatusId == ReferralStatusTypesEnum.ValidationFailed ?
                                            <Typography variant="subtitle2" className={classes.redFont}>{props.referralStatus.referralStatusLabel}</Typography>
                                            : <Typography variant="subtitle2" className={classes.blackFont}>{props.referralStatus.referralStatusLabel}</Typography>
                                    }
                                </Grid>
                                {
                                    props.referralStatus.referralStatusId == ReferralStatusTypesEnum.SubmissionFailed || props.referralStatus.referralStatusId == ReferralStatusTypesEnum.ValidationFailed ?
                                        <Grid item xs={12} className={classes.buttonContainer}>
                                            <Button className={classes.button} onClick={handleGoEdit} variant="contained" color="primary">
                                                Retry
                                            </Button>
                                        </Grid>
                                        : ''
                                }
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Last Activity</Typography>
                                {
                                    props.referralActivity?.description &&
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.referralActivity?.description + ' - ' + Moment(props.referralActivity?.date).format('MM/DD/YYYY')}</Typography>
                                }
                            </Grid>
                            {
                                props.serviceType[0].serviceId === ServicesEnum.UR &&
                                <Grid item xs={2}>
                                    <Typography variant="body2" className={classes.dataLabel}>Rush Request</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{ props.utilizationReviewService?.serviceTypeRequestUr?.rushRequest ? 'Yes' : 'No' }</Typography>
                                </Grid>
                            }
                            {
                                props.serviceType[0].serviceId === ServicesEnum.Diagnostic &&
                                <Grid item xs={2}>
                                    <Typography variant="body2" className={classes.dataLabel}>Rush Request</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{ props.diagnosticService?.serviceTypeRequestDx?.rushRequest ? 'Yes' : 'No' }</Typography>
                                </Grid>
                            }
                            {
                                props.serviceType[0].serviceId === ServicesEnum.PT &&
                                <Grid item xs={2}>
                                    <Typography variant="body2" className={classes.dataLabel}>Rush Request</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{ props.medicalService?.serviceType?.rushRequest ? 'Yes' : 'No' }</Typography>
                                </Grid>
                            }
                            {
                                props.serviceType[0].serviceId === ServicesEnum.TT && props.serviceType[0].serviceTypeAbbreviation == "TP" &&
                                <Grid item xs={2}>
                                    <Typography variant="body2" className={classes.dataLabel}>Rush Request</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{ props.transportationService?.serviceTypeRequestTransportation?.rushRequest ? 'Yes' : 'No' }</Typography>
                                </Grid>
                            }
                            {
                                props.serviceType[0].serviceId === ServicesEnum.TT && translationTypes.includes(props.serviceType[0].serviceTypeAbbreviation) &&
                                <Grid item xs={2}>
                                    <Typography variant="body2" className={classes.dataLabel}>Rush Request</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{ props.translationService?.serviceTypeRequestTranslation?.rushRequest ? 'Yes' : 'No' }</Typography>
                                </Grid>
                            }
                            {
                                props.referralStatus.referralStatusId == ReferralStatusTypesEnum.Cancelled && props.serviceType[0].serviceId !== ServicesEnum.IME &&
                                <Grid item xs={12}>
                                    <Typography variant="body2" className={classes.dataLabel}>Reason for Cancellation</Typography>
                                    <Typography variant="subtitle2" className={classes.blackFont}>{props.referral?.referralStatusDetails}</Typography>
                                </Grid>
                            }
                        </Grid>
                        :
                        <EmptyDataLabel></EmptyDataLabel>
                }
            </AccordionDetails>
        </Accordion>
    );
}