import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary, Divider } from '@material-ui/core';
import styles from "./ImeResponseSummary.Styles";
import Moment from 'moment';
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import ReferralScheduleEntity from '../../../../entities/ReferralSchedule.Entity';
import { ReferralScheduleStatusTypesEnum } from '../../../../support/enums/ReferralScheduleStatusTypesEnum';
import ImeResponseEntity from '../../../../entities/ImeResponse.Entity';
import ReferralScheduleStatusTypeEntity from '../../../../entities/ReferralScheduleStatusType.Entity';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReferralStatusEntity from '../../../../entities/ReferralStatus.Entity';

interface IImeResponseSummaryProps {
    referralSchedule: ReferralScheduleEntity,
    imeResponse: ImeResponseEntity,
    referralScheduleStatusTypes: Array<ReferralScheduleStatusTypeEntity>,
    referralStatus: ReferralStatusEntity,
}

export const ImeResponseSummary: FunctionComponent<IImeResponseSummaryProps> = (props: IImeResponseSummaryProps) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>IME</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>Referral Status</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralStatus?.referralStatusLabel}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>Claimant Verified They Will Attend</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralSchedule?.attendanceVerifiedDate ? 'Yes - ' + Moment(props.referralSchedule?.attendanceVerifiedDate).format('MM/DD/YYYY') : 'No'}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>Claimant Attended</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralSchedule && props.referralSchedule.referralScheduleStatusTypeId == ReferralScheduleStatusTypesEnum.Completed ? 'YES' : props.referralSchedule && props.referralSchedule.referralScheduleStatusTypeId == ReferralScheduleStatusTypesEnum.ClaimantNotAttended ? 'No' : ''}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>Schedule Date & Time</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralSchedule ? Moment(props.referralSchedule?.scheduledDate).format('MM/DD/YYYY') + ' at ' + Moment(props.referralSchedule.scheduledDate).format('HH:mm'): '' }</Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography variant="body2" className={classes.dataLabel}>Schedule Status</Typography>
                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralScheduleStatusTypes?.filter(x => x.referralScheduleStatusTypeId == props.referralSchedule?.referralScheduleStatusTypeId)[0]?.referralScheduleStatusTypeLabel}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        {/* Empty space */}
                        </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" className={classes.dataLabel}>Doctor Summary</Typography>
                        {props.imeResponse?.doctorSummary?.split('\n').map((sentence, key) => (
                            <Typography key={key} variant="subtitle2" className={classes.blackFont}>
                                {sentence}
                            </Typography>
                        ))}
                        <Divider className={classes.divider}></Divider>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" className={classes.dataLabel}>IME Paraprofessional Summary</Typography>
                        {props.imeResponse?.paraprofessionalSummary?.split('\n').map((sentence, key) => (
                            <Typography key={key} variant="subtitle2" className={classes.blackFont}>
                                {sentence}
                            </Typography>
                        ))}
                        <Divider className={classes.divider}></Divider>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" className={classes.dataLabel}>IME Paraprofessional Other Comments</Typography>
                        {props.imeResponse?.otherComments?.split('\n').map((sentence, key) => (
                            <Typography key={key} variant="subtitle2" className={classes.blackFont}>
                                {sentence}
                            </Typography>
                        ))}
                        <Divider className={classes.divider}></Divider>
                    </Grid>
                </Grid>                    
            </AccordionDetails>
        </Accordion>
    );
}