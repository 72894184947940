import React, { FunctionComponent, useState, useEffect } from 'react';
import styles from './TopNavigation.Styles';
import { NavigationBar } from '../../generic-components/navigation-bar/NavigationBar.Component';
import { TabsBar } from '../../generic-components/tabs-bar/TabsBar.Component';
import { UserRolesEnum } from '../../../support/enums/UserRoles.Enum';
import { useSelector } from 'react-redux';
import { UsersState } from '../../../store/reducers/usersReducer';
import { TabsBarLinked } from '../../generic-components/tabs-bar-linked/TabsBarLinked.Component';
import { checkPermissions } from '../../generic-components/access-control/AccessControl.Component';

interface TopNavigationProps {
    section: string;
    subRoutes: { label: string; link: string; allowedPermissions?: string[] }[];
    subRoutesRoles?: UserRolesEnum[][];
    handleTabChange: (newTabIndex: string) => void;
    tabIndex: string;
    initialTabIndex: number;
    profileId?: number;
}

export const TopNavigation: FunctionComponent<TopNavigationProps> = (props) => {
    const classes = styles();
    const [subRoutes, setSubroutes] = useState<{ label: string; link: string; allowedPermissions?: string[] }[]>([]);
    const userDecurityRules = useSelector<UsersState, UsersState['securityRules']>((state) => state.securityRules);

    useEffect(() => {
        const newSubRoutes = [];
        for (let i = 0; i < props.subRoutes.length; i++) {
            if (checkPermissions(userDecurityRules, props.subRoutes[i].allowedPermissions, false)) {
                newSubRoutes.push(props.subRoutes[i]);
            }
        }

        setSubroutes(newSubRoutes);
    }, [props]);

    return (
        subRoutes?.length > 0 && (
            <div className={classes.container}>
                <NavigationBar>
                    <TabsBarLinked
                        linkedTabs={subRoutes ?? []}
                        handleTabChange={props.handleTabChange}
                        title={props.section}
                        initialTabIndex={props.initialTabIndex}
                        profileId={props.profileId}
                    />
                </NavigationBar>
            </div>
        )
    );
};
