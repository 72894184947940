import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useState } from "react"
import { DialogCustom } from "../../components/generic-components/dialog-custom/DialogCustom.Component";

export const useValidationDialog = (title: string, bodyText: string, acceptButtonText: string) => {
    const [showDialog, setShowDialog] = useState<boolean>(false);

    const dismissHandler = () => {
        setShowDialog(false);
        
        const timeout = setTimeout(() => {
            const firstErrorElement = document.getElementsByClassName('Mui-error')[0];
            firstErrorElement?.scrollIntoView({ block: "center", inline: "nearest"});
            const inputEl = firstErrorElement?.parentElement?.children[1]?.children[0];
            (inputEl as any)?.focus();
        }, 100);
      
        return () => {
            clearTimeout(timeout);
        };
    }

    const checkForErrors = (errors: any, isChange: boolean) => {
        const noErrors = Object.values(errors).every(x => x == "");

        if(!noErrors && !isChange)
        {
            setShowDialog(true);
        }

        return noErrors;
    }

    const Dialog = <DialogCustom
                        title={title}
                        acceptButtonText={acceptButtonText}
                        acceptHandler={dismissHandler}
                        closeHandler={dismissHandler}
                        open={showDialog}
                        children={
                            <Typography>
                                {bodyText}
                            </Typography>
                        }
                    />

    return {Dialog, setShowDialog, checkForErrors};

}