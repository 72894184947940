import React, { Fragment, FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary, Divider, FormControl, FormLabel, List, ListItem, ListItemIcon, Checkbox, ListItemText, ThemeProvider, TextField } from '@material-ui/core';
import styles, { coverLetterSummaryTheme } from "./CoverLetterSummary.Styles";
import { EmptyDataLabel } from '../../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import IMECoverLetterEntity from "../../../../../../entities/IMECoverLetter.Entity";
import ImeEntity from '../../../../../../entities/Ime.Entity';
import { TooltipTextField } from "../../../../../generic-components/tooltip-textfield/TooltipTextField.Component";

interface ICoverLetterSummaryProps {
    coverLetter: IMECoverLetterEntity;
}

export const CoverLetterSummary: FunctionComponent<ICoverLetterSummaryProps> = (props: ICoverLetterSummaryProps) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>Cover Letter</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.coverLetter ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid item xs={12}>
                                <Typography variant="body2" className={classes.dataLabel}>Cover Letter Required</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.coverLetter.isCoverLetterRequired ? 'Yes' : 'No'}</Typography>
                            </Grid>
                            {
                                props.coverLetter.isCoverLetterRequired ?
                                    <Fragment>
                                        <Grid item xs={12}>
                                            <FormControl component="fieldset">
                                                <Typography variant="body2" className={classes.dataLabel}>Select Questions</Typography>
                                                {   
                                                    !props.coverLetter.isCoverLetterProvidedSeparately && //TO DO: REMOVE WHEN ADDING BACK THE OTHER STUFF
                                                    // TO DO: ADD BACK WHEN CLIENT IS READY  !props.coverLetter.isIWillAttachMyOwnCoverLetter && 
                                                    !props.coverLetter.isMedicalSummaryNeeded && !props.coverLetter.isDetailHistoryNeeded
                                                        && !props.coverLetter.isComplaintRelatedToDate && !props.coverLetter.isTreatmentReasonable && !props.coverLetter.isClaimantAbleToWork
                                                        && !props.coverLetter.isClaimantReachedMMI && !props.coverLetter.isImpairmentRaitingNeeded && !props.coverLetter.isFurtherMedicalTreatment ?
                                                        <Typography variant="subtitle2" className={classes.blackFont}>{'No options selected'}</Typography> :
                                                        <ThemeProvider theme={coverLetterSummaryTheme}>
                                                            <List>
                                                                {
                                                                    //TO DO: REMOVE WHEN ADDING BACK THE OTHER STUFF
                                                                    props.coverLetter.isCoverLetterProvidedSeparately ?
                                                                    <div className={classes.coverLetterLine} >
                                                                        <ListItemIcon>
                                                                            <Checkbox
                                                                                edge="start"
                                                                                checked={true}
                                                                                disableRipple
                                                                                disabled
                                                                                key="isCoverLetterProvidedSeparately"
                                                                                id="isCoverLetterProvidedSeparately"
                                                                                inputProps={{ 'aria-labelledby': `checkbox-list-label-isCoverLetterProvidedSeparately` }}
                                                                            />
                                                                        </ListItemIcon>
                                                                        <Typography variant="subtitle2" className={classes.blackFont}>{'Cover letter included in Documents (Please attach it in documents section)'}</Typography>
                                                                    </div>
                                                                    : ''
                                                                }
                                                                {/* TO DO: ADD BACK WHEN CLIENT IS READY 
                                                                    props.coverLetter.isIWillAttachMyOwnCoverLetter ?
                                                                        <div className={classes.coverLetterLine} >
                                                                            <ListItemIcon>
                                                                                <Checkbox
                                                                                    edge="start"
                                                                                    checked={true}
                                                                                    disableRipple
                                                                                    disabled
                                                                                    key="isCoverLetterProvidedSeparately"
                                                                                    id="isCoverLetterProvidedSeparately"
                                                                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-isCoverLetterProvidedSeparately` }}
                                                                                />
                                                                            </ListItemIcon>
                                                                            <Typography variant="subtitle2" className={classes.blackFont}>{'I will attach my own cover letter'}</Typography>
                                                                        </div>
                                                                        : ''
                                                                */}
                                                                {
                                                                    props.coverLetter.isMedicalSummaryNeeded ?
                                                                        <div className={classes.coverLetterLine} >
                                                                            <ListItemIcon>
                                                                                <Checkbox
                                                                                    edge="start"
                                                                                    checked={true}
                                                                                    key="isMedicalSummaryNeeded" id="isMedicalSummaryNeeded"
                                                                                    disableRipple
                                                                                    disabled
                                                                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-isMedicalSummaryNeeded` }}
                                                                                />
                                                                            </ListItemIcon>
                                                                            <Typography variant="subtitle2" className={classes.blackFont}>{`Please prepare a medical summary to be included for the IME physician's review`}</Typography>
                                                                        </div>
                                                                        : ''
                                                                }
                                                                {
                                                                    props.coverLetter.isDetailHistoryNeeded ?
                                                                        <div className={classes.coverLetterLine} >
                                                                            <ListItemIcon>
                                                                                <Checkbox
                                                                                    edge="start"
                                                                                    checked={true}
                                                                                    key="isDetailHistoryNeeded" id="isDetailHistoryNeeded"
                                                                                    disableRipple
                                                                                    disabled
                                                                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-isDetailHistoryNeeded` }}
                                                                                />
                                                                            </ListItemIcon>
                                                                            <Typography variant="subtitle2" className={classes.blackFont}>{`Please provide a detailed history, diagnosis and prognosis`}</Typography>
                                                                        </div>
                                                                        : ''
                                                                }
                                                                {
                                                                    props.coverLetter.isComplaintRelatedToDate ?
                                                                        <div className={classes.coverLetterLine} >
                                                                            <ListItemIcon>
                                                                                <Checkbox
                                                                                    edge="start"
                                                                                    checked={true}
                                                                                    key="isComplaintRelatedToDate" id="isComplaintRelatedToDate"
                                                                                    disableRipple
                                                                                    disabled
                                                                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-isComplaintRelatedToDate` }}
                                                                                />
                                                                            </ListItemIcon>
                                                                            <Typography variant="subtitle2" className={classes.blackFont}>{`Please address casual relationship. Are the claimant's current complaints casually related to the date of accident?`}</Typography>
                                                                        </div>
                                                                        : ''
                                                                }
                                                                {
                                                                    props.coverLetter.isTreatmentReasonable ?
                                                                        <div className={classes.coverLetterLine} >
                                                                            <ListItemIcon>
                                                                                <Checkbox
                                                                                    edge="start"
                                                                                    checked={true}
                                                                                    key="isTreatmentReasonable" id="isTreatmentReasonable"
                                                                                    disableRipple
                                                                                    disabled
                                                                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-isTreatmentReasonable` }}
                                                                                />
                                                                            </ListItemIcon>
                                                                            <Typography variant="subtitle2" className={classes.blackFont}>{`Has the claimant's medical treatment thus far been reasonable, necessary and casually related to the date of accident?`}</Typography>
                                                                        </div>
                                                                        : ''
                                                                }
                                                                {
                                                                    props.coverLetter.isClaimantAbleToWork ?
                                                                        <div className={classes.coverLetterLine} >
                                                                            <ListItemIcon>
                                                                                <Checkbox
                                                                                    edge="start"
                                                                                    checked={true}
                                                                                    key="isClaimantAbleToWork" id="isClaimantAbleToWork"
                                                                                    disableRipple
                                                                                    disabled
                                                                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-isClaimantAbleToWork` }}
                                                                                />
                                                                            </ListItemIcon>
                                                                            <Typography variant="subtitle2" className={classes.blackFont}>{`Please address the claimant's work capabilities. Is the claimant able to work full or light duty at this tcoverLetter? Please specify any work restrictions you feel are necessary and indicate how long these restrictions should remain in place.`}</Typography>
                                                                        </div>
                                                                        : ''
                                                                }
                                                                {
                                                                    props.coverLetter.isClaimantReachedMMI ?
                                                                        <div className={classes.coverLetterLine} >
                                                                            <ListItemIcon>
                                                                                <Checkbox
                                                                                    edge="start"
                                                                                    checked={true}
                                                                                    key="isClaimantReachedMMI" id="isClaimantReachedMMI"
                                                                                    disableRipple
                                                                                    disabled
                                                                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-isClaimantReachedMMI` }}
                                                                                />
                                                                            </ListItemIcon>
                                                                            <Typography variant="subtitle2" className={classes.blackFont}>{`Has the claimant reached Maximum Medical Improvement? If not, when do you anticipate the claimant will reach MMI`}</Typography>
                                                                        </div>
                                                                        : ''
                                                                }
                                                                {
                                                                    props.coverLetter.isImpairmentRaitingNeeded ?
                                                                        <div className={classes.coverLetterLine} >
                                                                            <ListItemIcon>
                                                                                <Checkbox
                                                                                    edge="start"
                                                                                    checked={true}
                                                                                    key="isImpairmentRaitingNeeded" id="isImpairmentRaitingNeeded"
                                                                                    disableRipple
                                                                                    disabled
                                                                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-isImpairmentRaitingNeeded` }}
                                                                                />
                                                                            </ListItemIcon>
                                                                            <Typography variant="subtitle2" className={classes.blackFont}>{`If the claimant is at MMI, please provide a permanent impairment rating according to the AMA guidelines. Please apportion your rating to include any pre-existing and/or unrelated conditions`}</Typography>
                                                                        </div>
                                                                        : ''
                                                                }
                                                                {
                                                                    props.coverLetter.isFurtherMedicalTreatment ?
                                                                        <div className={classes.coverLetterLine} >
                                                                            <ListItemIcon>
                                                                                <Checkbox
                                                                                    edge="start"
                                                                                    checked={true}
                                                                                    key="isFurtherMedicalTreatment" id="isFurtherMedicalTreatment"
                                                                                    disableRipple
                                                                                    disabled
                                                                                    inputProps={{ 'aria-labelledby': `checkbox-list-label-isFurtherMedicalTreatment` }}
                                                                                />
                                                                            </ListItemIcon>
                                                                            <Typography variant="subtitle2" className={classes.blackFont}>{`Do you have any further medical treatment recommendations for this claimant? If so, please elaborate on the specific treatment(s) and duration of the treatment plan`}</Typography>
                                                                        </div>
                                                                        : ''
                                                                }
                                                            </List>
                                                        </ThemeProvider>
                                                }
                                            </FormControl>
                                        </Grid>
                                            {/* TO DO: ADD BACK WHEN CLIENT IS READY 
                                        <Grid item xs={12}>
                                            <Typography variant="body2" className={classes.dataLabel}>Files Attached</Typography>
                                                props.coverLetter.files && props.coverLetter.files.length > 0 ? 
                                                <Fragment>
                                                    { props.coverLetter.files.map(file => 
                                                        <a download={file.name} href={file.base64} className={classes.coverLetterLine}>
                                                             {file.name}
                                                        </a> 
                                                    )}
                                                </Fragment>
                                            : <Typography variant="subtitle2" className={classes.blackFont}> No files attached </Typography>
                                        </Grid>
                                            */}
                                        <Grid item xs={12}>
                                            <TextField
                                                type='typography'
                                                label=' Additional Questions'
                                                maxRows={6}
                                                multiline
                                                className={classes.input}
                                                disabled
                                                InputProps={{
                                                        disableUnderline:true,
                                                }}
                                                value={!!props.coverLetter.otherCommentsForCoverLetter ? props.coverLetter.otherCommentsForCoverLetter : 'No additional questions'}

                                            />
                                        </Grid>
                                        
                                    </Fragment>
                                    :
                                    ''
                            }
                        </Grid>
                        :
                        <EmptyDataLabel></EmptyDataLabel>
                }
            </AccordionDetails>
        </Accordion>
    );
}