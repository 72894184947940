import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import React, { FunctionComponent, useEffect } from 'react';
import { useParams, useRouteMatch, useHistory } from 'react-router-dom';
import UserWithRulesDataModel from '../../../data-models/UserWithRules.DataModel';
import VendorAdminTableDataModel from '../../../data-models/VendorAdminTable.DataModel';
import TenantEntity from '../../../entities/Tenant.Entity';
import UserEntity from '../../../entities/User.Entity';
import { getTenants } from '../../../services/Tenants.Services';
import { createUser, getUserProfile, userAssignSecurityProfile } from '../../../services/Users.Service';
import { getVendorAdminTable } from '../../../services/Vendors.Service';
import styles from '../access-control-user-profile/AccessControlUserProfile.Styles';
import { Loader } from '../../generic-components/loader/Loader.Component';
import { toast } from 'react-toastify';
import { ClearSelect } from '../../generic-components/clear-select/ClearSelect.Component';
import { BreadCrumb } from '../../../support/custom-hooks/useBreadCrum';

export const AccessControlUserNew: FunctionComponent<any> = () => {
    const classes = styles();
    const abortController = new AbortController();
    const { path } = useRouteMatch();

    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [userProfile, setUserProfile] = React.useState<UserWithRulesDataModel>({ user: null, securityProfiles: [] });
    const { securityProfiles, user } = userProfile;
    const [vendors, setVendors] = React.useState<VendorAdminTableDataModel[]>([]);
    const [tenants, setTenants] = React.useState<TenantEntity[]>([]);
    const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
    const [originalUser, setOriginalUser] = React.useState<UserEntity>(null);

    useEffect(() => {
        handleInitialLoad();
    }, []);
    
    const history = useHistory();

    const handleInitialLoad = async () => {
        setIsLoading(true);
        setIsEditMode(true);
        //await handleGetUserProfile();
        await handleGetVendors();
        await handleGetTenants();

        setIsLoading(false);
    };

    const handleGetVendors = async () => {
        const vendors = await getVendorAdminTable(abortController.signal);
        setVendors(vendors);
    };

    const handleGetTenants = async () => {
        const tenants = await getTenants(abortController.signal);
        setTenants(tenants);
    };

    const handleTextFieldChange = (
        event:
            | React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            | React.ChangeEvent<{ name?: string; value: unknown; id?: string }>,
    ) => {
        event.persist();
        const newUser: UserEntity = { ...user };
        newUser[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;

        setUserProfile({ ...userProfile, user: newUser });
    };

    if (isLoading) {
        return <Loader isSmall />;
    }

    const handleClearSelect = (selectId: string) => {
        const newUser = { ...user };

        newUser[selectId] = null;

        if (selectId === 'vendorId') {
            newUser['userKey'] = null;
        }

        setUserProfile({ ...userProfile, user: newUser });
    };

    const handleStartEdit = () => {
        setIsEditMode(true);
        setOriginalUser({ ...user });
    };

    const handleSave = async () => {
        setIsEditMode(false);

        await createUser(user, abortController.signal).then(() => {
            toast.success('User created');
        });
    };

    const handleCancel = () => {
        history.push('/admin/access-control/users');
        //setUserProfile({ ...userProfile, user: originalUser });
        //setIsEditMode(false);
    };

    const adminBreadCrumb = {
        name: 'Admin',
        goBack: false,
        link: '/admin',
        path: path,
    };

    const accessControlBreadCrumb = {
        name: 'Access Control',
        goBack: false,
        link: '/admin/access-control/users',
        path: path,
    };

    const usersBreadCrumb = {
        name: 'Users',
        goBack: false,
        link: '/admin/access-control/users',
        path: path,
    };

    const userProfileBreadCrumb = {
        name: `New Profile`,
        goBack: false,
        path: path,
    };

    return (
        <>
            <div className={classes.breadCrumb}>
                <BreadCrumb
                    sections={[adminBreadCrumb, accessControlBreadCrumb, usersBreadCrumb, userProfileBreadCrumb]}
                />
            </div>
            <Paper className={classes.paper}>
                <Grid container justify="space-between" xs={12} className={classes.userInformationHeader}>
                    <Grid item>
                        <Typography variant="subtitle2">User Information</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={3}>
                        <TextField
                            label="First Name"
                            id="firstName"
                            value={user?.firstName}
                            InputLabelProps={{
                                shrink: !!user?.firstName,
                            }}
                            disabled={!isEditMode}
                            onChange={(event) => handleTextFieldChange(event)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Last Name"
                            id="lastName"
                            value={user?.lastName}
                            InputLabelProps={{
                                shrink: !!user?.lastName,
                            }}
                            disabled={!isEditMode}
                            onChange={(event) => handleTextFieldChange(event)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Username"
                            id="userName"
                            value={user?.userName}
                            InputLabelProps={{
                                shrink: !!user?.userName,
                            }}
                            disabled={!isEditMode}
                            onChange={(event) => handleTextFieldChange(event)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Guid"
                            id="guid"
                            value={user?.guid}
                            InputLabelProps={{
                                shrink: !!user?.guid,
                            }}
                            disabled={!isEditMode}
                            onChange={(event) => handleTextFieldChange(event)}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.selectInput}>
                            <InputLabel id="demo-simple-select-label">Tenant</InputLabel>
                            {
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="tenantId"
                                    name="tenantId"
                                    key={user?.tenantId}
                                    value={user?.tenantId}
                                    onChange={(event) => handleTextFieldChange(event)}
                                    disabled={!isEditMode}
                                    endAdornment={
                                        <ClearSelect
                                            shouldRender={!!user?.tenantId && isEditMode}
                                            onClick={() => handleClearSelect('tenantId')}
                                        />
                                    }
                                >
                                    {tenants
                                        .sort((a, b) => (a?.tenantName < b?.tenantName ? -1 : 1))
                                        .map((tenant, index) => {
                                            return (
                                                <MenuItem key={tenant.tenantId} value={tenant.tenantId}>
                                                    {tenant.tenantName}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            }
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl className={classes.selectInput}>
                            <InputLabel id="vendor-label">Vendor</InputLabel>
                            <Select
                                labelId="vendor-label"
                                id="vendorId"
                                name="vendorId"
                                key={user?.vendorId}
                                value={user?.vendorId}
                                onChange={(event) => handleTextFieldChange(event)}
                                disabled={!isEditMode}
                                endAdornment={
                                    <ClearSelect
                                        shouldRender={!!user?.vendorId && isEditMode}
                                        onClick={() => handleClearSelect('vendorId')}
                                    />
                                }
                            >
                                {vendors
                                    .sort((a, b) => (a?.vendorName < b?.vendorName ? -1 : 1))
                                    .map((vendor, index) => {
                                        return (
                                            <MenuItem key={vendor.vendorId} value={vendor.vendorId}>
                                                {vendor.vendorName}
                                            </MenuItem>
                                        );
                                    })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="User Key"
                            id="userKey"
                            value={user?.userKey}
                            key={user?.vendorId + user?.tenantId}
                            InputLabelProps={{
                                shrink: !!user?.userKey,
                            }}
                            onChange={(event) => handleTextFieldChange(event)}
                            disabled={!isEditMode || user?.vendorId === null}
                        />
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Email"
                            id="email"
                            value={user?.email}
                            InputLabelProps={{
                                shrink: !!user?.email,
                            }}
                            onChange={(event) => handleTextFieldChange(event)}
                            disabled={!isEditMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Contact Phone"
                            id="phone"
                            value={user?.phone}
                            InputLabelProps={{
                                shrink: !!user?.phone,
                            }}
                            onChange={(event) => handleTextFieldChange(event)}
                            disabled={!isEditMode}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Mobile Contact Phone"
                            id="mobilePhone"
                            value={user?.mobilePhone}
                            InputLabelProps={{
                                shrink: !!user?.mobilePhone,
                            }}
                            onChange={(event) => handleTextFieldChange(event)}
                            disabled={!isEditMode}
                        />
                    </Grid>
                </Grid>

                {isEditMode && (
                    <div className={classes.buttonsContainer}>
                        <Button className={classes.cancelButton} onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button className={classes.button} onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                )}
            </Paper>

        </>
    );
};
