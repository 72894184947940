import React, { FunctionComponent } from 'react';
import styles from './UrHeaderSummary.Styles';
import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TooltipTextField } from '../../../../../generic-components/tooltip-textfield/TooltipTextField.Component';
import ServiceTypeRequestUrEntity from '../../../../../../entities/UtilizationReviewDetail.Entity';

interface IUrHeaderSummaryProps {
    serviceTypeRequestUr: ServiceTypeRequestUrEntity;
}

export const UrHeaderSummary: FunctionComponent<IUrHeaderSummaryProps> = (props: IUrHeaderSummaryProps) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                {
                    <Typography variant="subtitle2" className={classes.sidebarTitle}>
                        UR/ Precertification/ Preauthorization
                    </Typography>
                }
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={3}>
                        <TooltipTextField
                            type="typography"
                            label="Rush Request"
                            typographyLabelClass={classes.dataLabel}
                            typographyValueClass={classes.blackFont}
                            value={props.serviceTypeRequestUr?.rushRequest ? 'Yes' : 'No'}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            className={classes.input}
                            disabled
                            label="Reason for referral"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={
                                props.serviceTypeRequestUr?.reasonForReferral
                                    ? props.serviceTypeRequestUr.reasonForReferral
                                    : ''
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            className={classes.input}
                            disabled
                            label="Special Instructions"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={
                                props.serviceTypeRequestUr?.specialInstructions
                                    ? props.serviceTypeRequestUr.specialInstructions
                                    : ''
                            }
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
