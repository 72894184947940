import React, { Fragment, FunctionComponent } from 'react';
import styles from "./ReferralImeResponse.Styles";
import { Grid, Link, Typography, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ImeResponseEntity from '../../../../../entities/ImeResponse.Entity';
import { ReferralOverviewDataModel } from '../../../../../data-models/ReferralOverview.DataModel';
import ReferralScheduleStatusTypeEntity from '../../../../../entities/ReferralScheduleStatusType.Entity';
import ReferralContactEntity from '../../../../../entities/ReferralContact.Entity';
import StateEntity from '../../../../../entities/State.Entity';
import ReferralDocumentEntity from '../../../../../entities/ReferralDocument.Entity';
import { ReferralOverviewImeDataModel } from '../../../../../data-models/ReferralOverviewIme.DataModel';
import { UsersState } from '../../../../../store/reducers/usersReducer';
import { UserRolesEnum } from '../../../../../support/enums/UserRoles.Enum';
import { ImeResponseSummary } from '../../ime-response-summary/ImeResponseSummary.Component';
import { PhysicianResponse } from '../../physician-response/PhysicianResponse.Component';
import { ResponseDocuments } from '../../response-documents/ResponseDocuments.Component';

type PathParamsType = {
    id: string;
    claimId: string;
}

type ReferralImeResponseProps = {
    imeResponse: ImeResponseEntity;
    referralOverviewDataModel: ReferralOverviewDataModel;
    referralScheduleStatusTypes: Array<ReferralScheduleStatusTypeEntity>;
    physician: ReferralContactEntity;
    states: Array<StateEntity>;
    responseDocuments: Array<ReferralDocumentEntity>;
    referralOverviewImeDataModel: ReferralOverviewImeDataModel;
    serviceId: number;
} & WithStyles<typeof styles> & RouteComponentProps<PathParamsType>

const ReferralImeResponseComponent: FunctionComponent<ReferralImeResponseProps> = (props: ReferralImeResponseProps) => {
    let userRoles = useSelector<UsersState, UsersState['roles']>(state => state.roles);
    let buttonBackLink: string;

    try {
        if (userRoles?.findIndex(x => x == UserRolesEnum.Vendor || x == UserRolesEnum.VendorApi) != -1) {
            buttonBackLink = '/referrals';
        }
        else {
            buttonBackLink = `/claims/${props.match.params.claimId}`;
        }
    }
    catch { }

    const { classes } = props;

    return (
        <Fragment>
            {
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <ImeResponseSummary referralStatus={props.referralOverviewDataModel?.referralStatus} referralSchedule={props.referralOverviewImeDataModel?.schedule} imeResponse={props.referralOverviewImeDataModel?.imeResponse} referralScheduleStatusTypes={props.referralScheduleStatusTypes} />
                        </Grid>
                        <Grid item xs={12}>
                            <PhysicianResponse serviceId={props.serviceId} physician={props.physician} states={props.states} />
                        </Grid>
                        <Grid item xs={12}>
                            <ResponseDocuments vendorDocuments={props.responseDocuments} />
                        </Grid>
                    </Grid>
                </form>
            }
        </Fragment>
    );
}

export const ReferralImeResponse = withRouter(withStyles(styles)(ReferralImeResponseComponent))
