import { apiGet, apiPost, apiPut, apiDelete } from '../support/helpers/ApiConnection.Helper';
import VendorEntity from '../entities/Vendor.Entity';
import VendorAdminTableDataModel from "../data-models/VendorAdminTable.DataModel";
import VendorProfileDataModel from "../data-models/VendorProfile.DataModel";
import VendorServiceSetUpTableDataModel from '../data-models/VendorServiceSetUpTable.DataModel';
import NewVendorByServiceDataModel from "../data-models/NewVendorByService.DataModel";
import EditedVendorByServiceDataModel from "../data-models/EditedVendorByService.DataModel";

export default function getVendors(clientID:number,serviceTypeId:number, abortSignal: AbortSignal): Promise<Array<VendorEntity>> {
    //here is set DummyResponse
    /*var arrayVendors: VendorDataModel[] = new Array();
    arrayVendors = [
        new VendorDataModel(new VendorEntity(1,"Novare",true)),
        new VendorDataModel(new VendorEntity(2,"Examworks",false)),
    ];
    var promise = new Promise<Array<VendorDataModel>>((resolve, reject) => {
        resolve();
    }).then(
        (value) => {
            return arrayVendors;
        },
        (error) => {
            console.error(error);
            return arrayVendors;
        }
    );
    return promise;*/
    const res = apiGet<Array<VendorEntity>>('/claims/'+clientID+'/service-types/'+serviceTypeId+'/vendors', abortSignal);

    return res;
}

export function getVendorAdminTable(abortSignal: AbortSignal): Promise<Array<VendorAdminTableDataModel>> {   
    return apiGet<Array<VendorAdminTableDataModel>>('/vendors',abortSignal);
}

export function createVendor(newVendor: VendorEntity, abortSignal: AbortSignal): Promise<VendorEntity> {
    return apiPost<VendorEntity>('/vendors', newVendor, abortSignal);
}

export function getVendorProfile(id:number, abortSignal: AbortSignal): Promise<VendorProfileDataModel> {   
    return apiGet<VendorProfileDataModel>('/vendors/' + id + '/profile',abortSignal);
}

export function editVendor(editedVendor: VendorProfileDataModel,id: number, abortSignal: AbortSignal): Promise<VendorProfileDataModel> {
    return apiPut<VendorProfileDataModel>('/vendors',id, editedVendor, abortSignal);
}

export function getTenantPartnerVendor(abortSignal: AbortSignal): Promise<Array<VendorServiceSetUpTableDataModel>> {   
    return apiGet<Array<VendorServiceSetUpTableDataModel>>('/vendors?table=tenant-partner',abortSignal);
}

export function createVendorPartnerTenant( newTenantPartnerVendor: NewVendorByServiceDataModel, abortSignal: AbortSignal) {
    return apiPost<NewVendorByServiceDataModel>('/vendors/' + newTenantPartnerVendor.vendorId +'/add-tenant-partner', newTenantPartnerVendor, abortSignal);
}

export function deleteServiceByVendor(ids: number[], abortSignal: AbortSignal): Promise<VendorServiceSetUpTableDataModel>{
    return apiPost<VendorServiceSetUpTableDataModel>('/vendors/delete-tenant-partner-vendor', ids, abortSignal);
}

export function editVendorPartnerTenant( newTenantPartnerVendor: EditedVendorByServiceDataModel, abortSignal: AbortSignal) {
    return apiPut<EditedVendorByServiceDataModel>('/vendors/edit-tennant-partner',newTenantPartnerVendor.vendorId, newTenantPartnerVendor, abortSignal);
}
