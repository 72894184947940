import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 100;

export default makeStyles((theme: Theme) => ({
    paper: {
        marginTop: '20px',
        //padding:'10px',
        //paddingTop: '15px',
        //paddingLeft: '35px',
        //paddingRight: '35px',
        //paddingBottom: '25px',
    },
    button: {
        color: 'white',
        backgroundColor: '#107A98',
        marginLeft: '40px',
        '&:hover': {
            backgroundColor: '#004763!important',
        },
    },
    container: {
        marginTop: '20px',
    },
    tcAdminCard: {
        '&, &:before': {
            content: '',
            borderLeft: 'solid 1px #648c9b',
        },
        '& p': {
            color: '#000',
        },
        '& h2': {
            fontSize: '1.5rem',
            color: '#107a98',
        },
        '&:hover': {
            backgroundColor: '#648c9b',
        },
        padding: '4.5rem 1.5rem',
        position: 'relative',
        textAlign: 'center',
    },
    tcAdminFirstCard: {
        '& p': {
            color: '#000',
        },
        '& h2': {
            fontSize: '1.5rem',
            color: '#107a98',
        },
        '&:hover': {
            backgroundColor: '#648c9b',
        },
        padding: '4.5rem 1.5rem',
        position: 'relative',
        textAlign: 'center',
    },
    gridContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
}));
