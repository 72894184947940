import React, { Fragment, FunctionComponent, useEffect } from 'react';
import styles from "./PtHeaderSummary.Styles";
import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PhysicalTherapyDataModel from '../../../../../../data-models/PhysicalTherapy.DataModel';
import MedicalServiceDataModel from '../../../../../../data-models/MedicalService.DataModel';
import { EmptyDataLabel } from '../../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import SpecificBodyPartEntity from '../../../../../../entities/SpecificBodyPart.Entity';
import InjuryTypeEntity from '../../../../../../entities/pt/InjuryType.Entity';
import BodySideEntity from '../../../../../../entities/BodySide.Entity';
import { TooltipTextField } from "../../../../../generic-components/tooltip-textfield/TooltipTextField.Component";
import { ServicesEnum } from '../../../../../../support/enums/Services.Enum';
import ServiceTypePTEntity from '../../../../../../entities/pt/ServiceTypePT.Entity';
import LanguageEntity from "../../../../../../entities/Language.Entity";
import { MaterialTableProps } from "material-table";
import ServiceTypeRequestPtDetailDataModel from "../../../../../../data-models/pt/ServiceTypeRequestPtDetail.DataModel";
import MaterialTable from "../../../../../generic-components/material-table/MaterialTable.Component";

interface IPtHeaderSummaryProps {
    referralId: number,
    specificBodyParts: Array<SpecificBodyPartEntity>,
    injuryTypes: Array<InjuryTypeEntity>,
    bodySides: Array<BodySideEntity>,
    serviceType: ServiceTypePTEntity,
    serviceId: number,
    languages: Array<LanguageEntity>
}

export const PtHeaderSummary: FunctionComponent<IPtHeaderSummaryProps> = (props: IPtHeaderSummaryProps) => {
    const classes = styles();

    const serviceTypeRequestPtTableProps: MaterialTableProps<ServiceTypeRequestPtDetailDataModel> = {
        columns: [
            {
                title:'Specific Body Parts',
                field:'specificBodyPartName'
            },
            {
                title:'Injury Type',
                field:'injuryTypeName'
            },
            {
                title:'Body Side',
                field:'bodySideName'
            }
        ],
        data: props.serviceType?.serviceTypeRequestPtDetail ?? [],
        options: {
            filtering: false,
            showTitle: true,
            paging: false,
            toolbar: false,
            actionsColumnIndex: -1,
        },
        style:{
            paddingLeft: '35px',
            paddingRight: '35px',
        }
    }

    return (
        <Accordion defaultExpanded={true} className={classes.paper}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                {props.serviceId == ServicesEnum.PT && <Typography variant="subtitle2" className={classes.sidebarTitle}>Physical Medicine (PM)</Typography>}
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.serviceType ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid item xs={3}>
                                <TooltipTextField 
                                    type='typography'
                                    label='Number of approved visits'
                                    typographyLabelClass={classes.dataLabel}
                                    typographyValueClass={classes.blackFont}
                                    value={props.serviceType.numberOfApprovedVisits ? props.serviceType.numberOfApprovedVisits : ''} />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField 
                                    type='typography'
                                    label='Frequency & Duration'
                                    typographyLabelClass={classes.dataLabel}
                                    typographyValueClass={classes.blackFont}
                                    value={props.serviceType.frequencyAndDuration ? props.serviceType.frequencyAndDuration : ''}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField 
                                    type='typography'
                                    label='Schedule special instructions'
                                    typographyLabelClass={classes.dataLabel}
                                    typographyValueClass={classes.blackFont}
                                    value={props.serviceType.scheduleSpecialInstructions ? props.serviceType.scheduleSpecialInstructions : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <MaterialTable 
                                    tableProps={serviceTypeRequestPtTableProps}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField 
                                    type='typography'
                                    label='Rush Request'
                                    typographyLabelClass={classes.dataLabel}
                                    typographyValueClass={classes.blackFont}
                                    value={props.serviceType.rushRequest ? 'Yes' : 'No' }
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField 
                                    type='typography'
                                    label='Transportation'
                                    typographyLabelClass={classes.dataLabel}
                                    typographyValueClass={classes.blackFont}
                                    value={props.serviceType.transportation ? 'Yes' : 'No' }
                                />
                            </Grid>
                            <Grid item xs={6} />
                            
                            <Grid item xs={3}>
                                <TooltipTextField 
                                    type='typography'
                                    label='Patient Language'
                                    typographyLabelClass={classes.dataLabel}
                                    typographyValueClass={classes.blackFont}
                                    value={props.serviceType.patientLanguage ? props.serviceType.patientLanguage : ''}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField 
                                    type='typography'
                                    label='Translation'
                                    typographyLabelClass={classes.dataLabel}
                                    typographyValueClass={classes.blackFont}
                                    value={props.serviceType.translation ? 'Yes' : 'No' }
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField 
                                    type='typography'
                                    label='Language'
                                    typographyLabelClass={classes.dataLabel}
                                    typographyValueClass={classes.blackFont}
                                    value={ props.serviceType?.languageId === 0 ? 'Other' : props.languages?.find(x => x.languageId == props.serviceType.languageId)?.languageName}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TooltipTextField 
                                    type='typography'
                                    label='Other Language'
                                    typographyLabelClass={classes.dataLabel}
                                    typographyValueClass={classes.blackFont}
                                    value={props.serviceType.otherLanguage ? props.serviceType.otherLanguage : ''}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    type='typography'
                                    label='Additional Comments'
                                    maxRows={6}
                                    multiline
                                    className={classes.input}
                                    disabled
                                    InputProps={{
                                        disableUnderline:true,
                                    }}
                                    value={props.serviceType.otherComments ? props.serviceType.otherComments : ''}
                                />
                                <TooltipTextField 
                                    type='typography'
                                    typographyLabelClass={classes.dataLabel}
                                    typographyValueClass={classes.blackFont}
                                />
                            </Grid>
                        </Grid>
                        : <EmptyDataLabel></EmptyDataLabel>
                }
            </AccordionDetails>
        </Accordion>

    );
}