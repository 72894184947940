import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
    clientListPaper: {
        padding: '10px 25px 100px',
        marginTop: '15px',
        marginBottom: '25px',
        // margin: "25px",
        '& th': {
            background: '#F0F0F0',
        },
        '& a': {
            color: '#1599BC',
            textDecoration: 'none',
        },
    },
    referralHeaderContainer: {
        display: 'flex',
        marginBottom: '15px',
    },
    referralHeader: {
        color: '#648C9B',
    },
}));
