import React, { Fragment, FunctionComponent } from 'react';
import styles from './ReferralOverview.Styles';
import { Grid, Link, Typography, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ClaimSummary } from '../../../claim-components/claim-summary/ClaimSummary.Component';
import { ReferralSummary } from '../referral-summary/ReferralSummary.Component';
import { ReferralImeSummary } from './referral-ime-summary/ReferralImeSummary.Component';
import { ReferralOverviewDataModel } from '../../../../data-models/ReferralOverview.DataModel';
import { ImeScorecardSummary } from '../referral-scorecard-summary/ime-scorecard-summary/ImeScorecardSummary.Component';
import { ReferralOverviewImeDataModel } from '../../../../data-models/ReferralOverviewIme.DataModel';
import { ReferralTraker } from '../referral-traker/ReferralTraker.Component';
import { ReferralActivityList } from '../referral-activity-list/ReferralActivityList.Component';
import ReferralScheduleStatusTypeEntity from '../../../../entities/ReferralScheduleStatusType.Entity';
import { UserRolesEnum } from '../../../../support/enums/UserRoles.Enum';
import { UsersState } from '../../../../store/reducers/usersReducer';
import { useSelector } from 'react-redux';
import ImeScorecardSectionDataModel from '../../../../data-models/ImeScorecardSection.DataModel';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';
import SpecificBodyPartEntity from '../../../../entities/SpecificBodyPart.Entity';
import { MsScorecardSummary } from '../referral-scorecard-summary/ms-scorecard-summary/MsScorecardSummary.Component';
import ScorecardSectionDataModel from '../../../../data-models/pt/ScorecardSection.DataModel';
import ReferralNotificationTrackerDataModel from '../../../../data-models/ReferralNotificationTracker.DataModel';
import SubmissionEntity from '../../../../entities/Submission.Entity';
import MedicalServiceDataModel from '../../../../data-models/MedicalService.DataModel';
import IMECoverLetterEntity from '../../../../entities/IMECoverLetter.Entity';
import DiagnosticProcedureResponseDataModel from '../../../../data-models/DiagnosticProcedureResponse.DataModel';
import ServiceDataModel from '../../../../data-models/Service.DataModel';
import { ReferralPtSummary } from './referral-pt-summary/ReferralPtSummary.Component';
import { ReferralDxSummary } from './referral-dx-summary/ReferralDxSummary.Component';
import UtilizationReviewServiceDataModel from '../../../../data-models/UtilizationReviewService.DataModel';
import DiagnosticServiceDataModel from '../../../../data-models/DiagnosticService.DataModel';
import TranslationDataModel from '../../../../data-models/Translation.DataModel';
import TransportationDataModel from '../../../../data-models/Transportation.DataModel';
import AppointmentTypeEntity from '../../../../entities/AppointmentType.Entity';
import DestinationEntity from '../../../../entities/Destination.Entity';
import InterpretationTypeEntity from '../../../../entities/InterpretationType.Entity';
import OriginationEntity from '../../../../entities/Origination.Entity';
import TransportationRequiredEntity from '../../../../entities/TransportationRequired.Entity';
import LanguageEntity from '../../../../entities/Language.Entity';
import { ReferralTransportationSummary } from './referral-transportation-summary/ReferralTransportationSummary.Component';
import { ReferralTranslationSummary } from './referral-translation-summary/ReferralTranslationSummary.Component';
import { ReferralUrSummary } from './referral-ur-summary/ReferralUrSummary.Component';
import ActivityDataModel from '../../../../data-models/Activity.DataModel';
import DmeResponseDataModel from '../../../../data-models/dme/DmeResponse.DataModel';
import { ReferralDmeSummary } from './referral-dme-summary/ReferralDmeSummary.Component';
import { translationTypes } from '../../../../support/constants/TranslationTypes.Constants';

type PathParamsType = {
    id: string;
    claimId: string;
};

type ReferralOverviewProps = {
    referralOverviewDataModel: ReferralOverviewDataModel;
    referralOverviewImeDataModel: ReferralOverviewImeDataModel;
    referralActivities: Array<ActivityDataModel>;
    scheduleStatusTypes: Array<ReferralScheduleStatusTypeEntity>;
    imeScorecardSections: Array<ImeScorecardSectionDataModel>;
    medicalService: MedicalServiceDataModel;
    specificBodyParts: Array<SpecificBodyPartEntity>;
    scorecardSections: Array<ScorecardSectionDataModel>;
    referralNotificationTracker: ReferralNotificationTrackerDataModel;
    sendSubmission: (referralId: number, add: SubmissionEntity) => void;
    imeCoverLetter: IMECoverLetterEntity;
    diagnosticProcedureResponse: Array<DiagnosticProcedureResponseDataModel>;
    services: Array<ServiceDataModel>;
    utilizationReviewService: UtilizationReviewServiceDataModel;
    diagnosticService: DiagnosticServiceDataModel;
    transportationService: TransportationDataModel;
    translationService: TranslationDataModel;
    appointmentTypes: Array<AppointmentTypeEntity>;
    destinations: Array<DestinationEntity>;
    interpretationTypes: Array<InterpretationTypeEntity>;
    originations: Array<OriginationEntity>;
    transportationRequireds: Array<TransportationRequiredEntity>;
    languages: Array<LanguageEntity>;
    dmeResponse: DmeResponseDataModel;
} & WithStyles<typeof styles> &
    RouteComponentProps<PathParamsType>;

const ReferralOverviewComponent: FunctionComponent<ReferralOverviewProps> = (props: ReferralOverviewProps) => {
    const { classes } = props;

    const getScorecard = () => {
        switch (props.referralOverviewDataModel?.referralServiceType[0]?.serviceId) {
            case ServicesEnum.IME:
                return (
                    <ImeScorecardSummary
                        referralOverviewDataModel={props.referralOverviewDataModel}
                        referralOverviewImeDataModel={props.referralOverviewImeDataModel}
                        imeScorecardSections={props.imeScorecardSections}
                    />
                );
            case ServicesEnum.Diagnostic:
            case ServicesEnum.PT:
                return (
                    <MsScorecardSummary
                        scorecardResponse={props.medicalService?.scorecardResponse}
                        scorecardResponsesChartContent={props.medicalService?.scorecardResponsesChartContent}
                        scorecardSections={props.scorecardSections}
                        serviceId={props?.referralOverviewDataModel?.referralServiceType[0]?.serviceId}
                    />
                );
            case ServicesEnum.UR:
            default:
                return '';
        }
    };

    const getServiceSummary = () => {
        switch (props.referralOverviewDataModel?.referralServiceType[0]?.serviceId) {
            case ServicesEnum.IME:
                return (
                    <ReferralImeSummary
                        coverLetter={props.imeCoverLetter}
                        scheduleStatusTypes={props.scheduleStatusTypes}
                        referralOverviewImeDataModel={props.referralOverviewImeDataModel}
                    />
                );
            case ServicesEnum.Diagnostic:
                return props.diagnosticProcedureResponse?.map(
                    (dx) =>
                        !dx.replacedByVendorOn && (
                            <ReferralDxSummary
                                diagnosticProcedureResponse={dx}
                                specificBodyParts={props.specificBodyParts}
                                services={props.services}
                                title={`Diagnostic ${
                                    dx.specificBodyPartName != null ? `: ${dx.specificBodyPartName}` : ''
                                } ${dx.bodySideName != null ? `- ${dx.bodySideName}` : ''}`}
                            />
                        ),
                );
            case ServicesEnum.PT:
                return (
                    <ReferralPtSummary
                        medicalService={props.medicalService}
                        specificBodyParts={props.specificBodyParts}
                        sendSubmission={props.sendSubmission}
                    />
                );
            case ServicesEnum.UR:
                return (
                    <ReferralUrSummary serviceTypeRequestUr={props.utilizationReviewService?.serviceTypeRequestUr} />
                );
            case ServicesEnum.DME:
                return <ReferralDmeSummary dmeResponse={props.dmeResponse} />;
            default:
                return '';
        }
    };

    return (
        <Fragment>
            {
                <div className={classes.content}>
                    <form noValidate autoComplete="off">
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <ClaimSummary
                                    claim={props.referralOverviewDataModel?.claim}
                                    claimant={props.referralOverviewDataModel?.claimant}
                                    client={props.referralOverviewDataModel?.client}
                                    coverageType={props.referralOverviewDataModel?.claimCoverageType}
                                    stateOfJurisdiction={props.referralOverviewDataModel?.claimState}
                                />
                            </Grid>
                            <Grid item container xs={8} spacing={3} className={classes.container}>
                                <Grid item xs={12}>
                                    <ReferralSummary
                                        medicalService={props.medicalService}
                                        diagnosticService={props.diagnosticService}
                                        utilizationReviewService={props.utilizationReviewService}
                                        transportationService={props.transportationService}
                                        translationService={props.translationService}
                                        claimId={props.referralOverviewDataModel?.referral?.claimId}
                                        referralId={props.referralOverviewDataModel?.referral?.referralId}
                                        history={props.history}
                                        location={props.location}
                                        match={props.match}
                                        referralActivity={
                                            props.referralActivities?.sort((x, y) => {
                                                if (x.activityId < y.activityId) {
                                                    return 1;
                                                } else if (x.activityId > y.activityId) {
                                                    return -1;
                                                } else {
                                                    return 0;
                                                }
                                            })[0]
                                        }
                                        referral={props.referralOverviewDataModel?.referral}
                                        referralStatus={props.referralOverviewDataModel?.referralStatus}
                                        serviceType={props.referralOverviewDataModel?.referralServiceType}
                                        vendor={props.referralOverviewDataModel?.referralVendor}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {getServiceSummary()}
                                    {props.referralOverviewDataModel?.referralServiceType[0]?.serviceId ==
                                        ServicesEnum.TT &&
                                        props.referralOverviewDataModel?.referralServiceType[0]
                                            ?.serviceTypeAbbreviation == 'TP' && (
                                            <ReferralTransportationSummary
                                                transportationService={props.transportationService}
                                                appointmentTypes={props.appointmentTypes}
                                                destinations={props.destinations}
                                                originations={props.originations}
                                                transportationRequireds={props.transportationRequireds}
                                            />
                                        )}
                                    {props.referralOverviewDataModel?.referralServiceType[0]?.serviceId ==
                                        ServicesEnum.TT &&
                                        translationTypes.includes(props.referralOverviewDataModel?.referralServiceType[0]
                                            ?.serviceTypeAbbreviation) && (
                                            <ReferralTranslationSummary
                                                translationService={props.translationService}
                                                appointmentTypes={props.appointmentTypes}
                                                interpretationTypes={props.interpretationTypes}
                                                languages={props.languages}
                                            />
                                        )}
                                </Grid>
                                <Grid item xs={12}>
                                    {getScorecard()}
                                </Grid>
                            </Grid>
                            <Grid item xs={4}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <ReferralTraker
                                            referralNotificationTracker={props.referralNotificationTracker}
                                            referralSchedule={props.referralOverviewImeDataModel?.schedule}
                                            referralActivities={props.referralActivities}
                                            serviceId={
                                                props?.referralOverviewDataModel?.referralServiceType[0]?.serviceId
                                            }
                                            activityTypes={null}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ReferralActivityList referralActivities={props.referralActivities} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            }
        </Fragment>
    );
};

export const ReferralOverview = withRouter(withStyles(styles)(ReferralOverviewComponent));
