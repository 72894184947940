import React from "react";
import { Button, Container } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Link as RouterLink } from "react-router-dom";
import styles from "./NotFound.styles";
import backgroundImage from '../../../assets/svgs/terra_background_not_found.svg';
import terraLogo from '../../../assets/svgs/terra_logo.svg';
const NotFound = () => {
    
  const classes = styles();
  return (
    <Container maxWidth={false} className={classes.root} 
      style={{
        backgroundImage:`url(${backgroundImage})`
      }} 
    >
      <Grid container direction="row" justify="center" alignItems="center" spacing={3} className={classes.container}>
        <Grid item xs={1} />
        <Grid item xs={6}>
          <Grid container spacing={2} justify="center">
            <Grid item xs={12} className={classes.title}>
              Oops, something broke
            </Grid>
            <Grid item xs={12} className={classes.subtitle}>
              Error 404: We are not sure what went wrong, we couldn&apos;t find the page.
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" className={classes.btnHomePage} component={RouterLink} to="/">
                BACK TO MAIN SECTION
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          <div 
            className={classes.logo} 
            style={{
              backgroundImage:`url(${terraLogo})`
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default NotFound;
