import React, { FunctionComponent, useEffect } from 'react';
import styles from './ExtraDxFields.Styles';
import { FormControl, FormHelperText, Grid, Input, InputAdornment, InputLabel, Typography } from '@material-ui/core';
import ServiceTypeRequestDxEntity from '../../../../../../entities/ServiceTypeRequestDx.Entity';
import { IDiagnosticServiceReferralFormErrors } from '../DiagnosticServiceReferralForm.Component';

interface IExtraDxFieldsProps {
    serviceTypeRequestDx: ServiceTypeRequestDxEntity;
    errors: IDiagnosticServiceReferralFormErrors;
    validate: (isChange?: boolean, isSubmit?: boolean) => void;
    handleServiceTypeRequestDxEdit: any;
    hasBeenSubmitted: boolean;
    referralId: number;
}

export interface IExtraDxFieldsErrors {}

export const ExtraDxFields: FunctionComponent<IExtraDxFieldsProps> = (props: IExtraDxFieldsProps) => {
    const classes = styles();
    const abortController = new AbortController();

    const [serviceTypeRequestDx, setServiceTypeRequestDx] = React.useState<ServiceTypeRequestDxEntity>(
        props.serviceTypeRequestDx ? props.serviceTypeRequestDx : new ServiceTypeRequestDxEntity(),
    );
    const [counter, setCounter] = React.useState<ServiceTypeRequestDxEntity>(
        props.serviceTypeRequestDx ? props.serviceTypeRequestDx : new ServiceTypeRequestDxEntity(),
    );

    //componentDidMount
    useEffect(() => {
        if (props.serviceTypeRequestDx) {
            let newDPAD: ServiceTypeRequestDxEntity = props.serviceTypeRequestDx;
            setServiceTypeRequestDx(newDPAD);
            setCounter(newDPAD);
        } else {
            let newDPAD: ServiceTypeRequestDxEntity = new ServiceTypeRequestDxEntity();
            newDPAD.referralId = props.referralId;
            setServiceTypeRequestDx(newDPAD);
            setCounter(newDPAD);
        }

        return function cleanup() {
            abortController.abort();
        };
    }, [props]);

    const handleServiceTypeRequestDx = (event) => {
        event.persist();

        let newDPAD: ServiceTypeRequestDxEntity = serviceTypeRequestDx;

        if (!newDPAD.referralId) {
            newDPAD.referralId = props.referralId;
        }

        newDPAD[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true'
                ? true
                : event.target.value == 'false'
                ? false
                : event.target.value?.toString() == 'true'
                ? true
                : event.target.value?.toString() == 'false'
                ? false
                : parseInt(event.target.value);
        setCounter({ ...counter, [event.target.id ? event.target.id : event.target.name]: event.target.value });
        setServiceTypeRequestDx(newDPAD);

        props.handleServiceTypeRequestDxEdit(serviceTypeRequestDx);

        if (props.hasBeenSubmitted) props.validate(true, false);
    };

    return (
        <Grid container spacing={2}>
            <Grid item style={{ width: '300px' }}>
                <Typography variant="body2" className={classes.typo}>
                    Claimant Relevant Information
                </Typography>
            </Grid>
            <Grid item xs={1} style={{ marginLeft: '20px', marginRight: '20px' }}>
                <FormControl>
                    <InputLabel htmlFor="filled-adornment-amount" shrink={true}>
                        Height
                    </InputLabel>
                    <Input
                        id="heightFt"
                        name="heightFt"
                        value={props.serviceTypeRequestDx?.heightFt}
                        onChange={(event) => handleServiceTypeRequestDx(event)}
                        type="number"
                        endAdornment={<InputAdornment position="end">Ft</InputAdornment>}
                        onInput={(e) => {
                            (e.target as HTMLInputElement).value = Math.max(
                                0,
                                parseInt((e.target as HTMLInputElement).value),
                            )
                                .toString()
                                .slice(0, 2);
                        }}                                            
                        style={{ width: '100px' }}
                    />
                    <FormHelperText id="standard-weight-helper-text">{props?.errors?.heightFt} </FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={1} style={{ marginLeft: '20px', marginRight: '20px' }}>
                <FormControl>
                    <InputLabel htmlFor="filled-adornment-amount" shrink={true}></InputLabel>
                    <Input
                        id="heightInches"
                        name="heightInches"
                        value={props.serviceTypeRequestDx?.heightInches}
                        onChange={(event) => handleServiceTypeRequestDx(event)}
                        type="number"
                        endAdornment={<InputAdornment position="end">Inches</InputAdornment>}
                        onInput={(e) => {
                            (e.target as HTMLInputElement).value = Math.max(
                                0,
                                parseInt((e.target as HTMLInputElement).value),
                            )
                                .toString()
                                .slice(0, 2);
                        }}                        
                        style={{ width: '100px' }}
                    />
                    <FormHelperText id="standard-weight-helper-text">{props?.errors?.heightInches}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={1} style={{ marginLeft: '20px', marginRight: '20px' }}>
                <FormControl>
                    <InputLabel htmlFor="filled-adornment-amount" shrink={true}>
                        Weight
                    </InputLabel>
                    <Input
                        id="weightLbs"
                        name="weightLbs"
                        value={props.serviceTypeRequestDx?.weightLbs}
                        onChange={(event) => handleServiceTypeRequestDx(event)}
                        type="number"
                        endAdornment={<InputAdornment position="end">Lbs</InputAdornment>}
                        onInput={(e) => {
                            (e.target as HTMLInputElement).value = Math.max(
                                0,
                                parseInt((e.target as HTMLInputElement).value),
                            )
                                .toString()
                                .slice(0, 4);
                        }}                        
                        style={{ width: '100px' }}
                    />
                    <FormHelperText id="standard-weight-helper-text">{props?.errors?.weightLbs}</FormHelperText>
                </FormControl>
            </Grid>
        </Grid>
    );
};
