import { makeStyles } from "@material-ui/core";
import { Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    selectInput: {
      width:'100%',
      paddingRight: '20px!important'
    },
    selectAdornment: {
      marginRight: theme.spacing(3),
    },
    selectAdornmentButton:{
      padding:'5px',
    }
}));