import { makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) => ({
    container: {
        //width: '100%',
        backgroundColor: 'white !important',
        position: 'fixed',
        left: '77px',
        right: 0,
        top: '64px',
        zIndex: 1000,
    },
}));
