import MSCoverLetterEntity from "../entities/pt/PTCoverLetter.Entity";
import ReferralVisitEntity from "../entities/pt/ReferralVisit.Entity";
import ReferralVisitStatusEntity from "../entities/pt/ReferralVisitStatus.Entity";
import ServiceTypePTEntity from "../entities/pt/ServiceTypePT.Entity";
import ServiceTypeResponseEntity from "../entities/pt/ServiceTypeResponse.Entity";
import ServiceTypeScorecardResponseEntity from "../entities/pt/ServiceTypeScorecardResponse.Entity";
import ReferralContactEntity from "../entities/ReferralContact.Entity";
import ReferralScheduleEntity from "../entities/ReferralSchedule.Entity";
import AdditionalInformationDataModel from "./pt/AdditionalInformation.DataModel";
import ScorecardResponsesChartContentDataModel from "./pt/ScorecardResponsesChartContentDataModel";

export default class MedicalServiceDataModel {
    serviceType: ServiceTypePTEntity;
    lastVisit: ReferralVisitEntity;
    progressSummary: string;
    notes: string;
    referringPhysician: ReferralContactEntity;
    response: ServiceTypeResponseEntity;
    visits: Array<ReferralVisitEntity>;
    singleSchedule?: ReferralScheduleEntity;
    scorecardResponse: Array<ServiceTypeScorecardResponseEntity>;
    scorecardResponsesChartContent: Array<ScorecardResponsesChartContentDataModel>;
    additionalInformation: AdditionalInformationDataModel;
}