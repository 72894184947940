import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary } from '@material-ui/core';
import styles from "./EmployerSummary.Styles";
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import StateEntity from '../../../../entities/State.Entity';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';

interface IEmployerSummaryProps {
    referralEmployer: ReferralContactEntity;
    states: Array<StateEntity>;
    serviceId?: number;
}

export const EmployerSummary: FunctionComponent<IEmployerSummaryProps> = (props: IEmployerSummaryProps) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>Employer</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.referralEmployer ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Employer Name</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.name}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Address Line 1</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.addressLine1}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Primary Phone</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.primaryPhoneNumber}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Ext.</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.primaryPhoneExtension}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Contact First Name</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.firstName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Address Line 2</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.addressLine2}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Secondary Phone</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.secondaryPhoneNumber}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Ext.</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.secondaryPhoneExtension}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Contact Last Name</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.lastName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Group #</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.groupNumber}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>City</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.cityName}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Fax</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.fax}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Location ID</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.location}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>State</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.states?.filter(x => !!props.referralEmployer && !!props.referralEmployer.stateId && x.stateId == props.referralEmployer.stateId)[0]?.stateName}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" className={classes.dataLabel}>Email</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.email}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>FEIN</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.fein}</Typography>
                            </Grid>
                            {
                                props.serviceId === ServicesEnum.Diagnostic ?
                                <Grid container xs={3} spacing={1} style={{ marginTop:'9px', marginRight: '4px'}}>
                                    <Grid item xs={3} style={{ marginLeft: '15px', marginRight: '-15%' }}>
                                        <Typography variant="body2" className={classes.dataLabel}>Zip Code</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.zipCode}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Grid item xs={3} style={{ marginTop:'12px',marginLeft: '15px', marginRight: '-8%' }}>
                                            _
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={3} style={{marginRight: '15px'}}>
                                        <Typography variant="body2" className={classes.dataLabel}>Zip Code Ext.</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.zipCodeExtension}</Typography>
                                    </Grid>
                                </Grid>
                                :
                                <Grid item xs={9} container spacing={3}>
                                    <Grid item xs={4}>
                                        <Typography variant="body2" className={classes.dataLabel}>Zip Code</Typography>
                                        <Typography variant="subtitle2" className={classes.blackFont}>{props.referralEmployer.zipCode}</Typography>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        : <EmptyDataLabel></EmptyDataLabel>
                }
            </AccordionDetails>
        </Accordion>
    );
}