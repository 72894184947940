import React, { Fragment, FunctionComponent } from 'react';
import styles from './ReferralMsClaimInfo.Styles';
import { Grid, WithStyles, withStyles } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ReferralOverviewDataModel } from '../../../../../data-models/ReferralOverview.DataModel';
import ReferralContactEntity from '../../../../../entities/ReferralContact.Entity';
import { ClientSummary } from '../../client-summary/ClientSummary.Component';
import StateEntity from '../../../../../entities/State.Entity';
import ReferralClaimEntity from '../../../../../entities/ReferralClaim.Entity';
import CoverageTypeEntity from '../../../../../entities/CoverageType.Entity';
import { MsClaimDetailSummary } from '../../ms-claim-summary/MsClaimDetailSummary.Component';
import { AdjusterSummary } from '../../adjuster-summary/AdjusterSummary.Component';
import { ClaimantSummary } from '../../claimant-summary/ClaimantSummary.Component';
import { ClaimantAttorneySummary } from '../../claimant-attorney-summary/ClaimantAttorneySummary.Component';
import { EmployerSummary } from '../../employer-summary/EmployerSummary.Component';
import { EmployerAttorneySummary } from '../../employer-attorney-summary/EmployerAttorneySummary.Component';
import { NurseCaseManagerSummary } from '../../nurse-case-manager-summary/NurseCaseManagerSummary.Component';
import { ServicesEnum } from '../../../../../support/enums/Services.Enum';

type PathParamsType = {
    id: string;
    claimId: string;
};

type ReferralMsClaimInfoProps = {
    referralClient: ReferralContactEntity;
    referralOverviewDataModel: ReferralOverviewDataModel;
    states: Array<StateEntity>;
    referralClaim: ReferralClaimEntity;
    coverageTypes: Array<CoverageTypeEntity>;
    referralAdjuster: ReferralContactEntity;
    referralClaimant: ReferralContactEntity;
    referralClaimantAttorney: ReferralContactEntity;
    referralEmployer: ReferralContactEntity;
    referralEmployerAttorney: ReferralContactEntity;
    referralNurseCaseManager: ReferralContactEntity;
} & WithStyles<typeof styles> &
    RouteComponentProps<PathParamsType>;

const ReferralMsClaimInfoComponent: FunctionComponent<ReferralMsClaimInfoProps> = (props: ReferralMsClaimInfoProps) => {
    return (
        <Fragment>
            {
                <form noValidate autoComplete="off">
                    <Grid container spacing={3}>                    
                        <Grid item xs={12}>
                            <MsClaimDetailSummary
                                referralClaim={props.referralClaim}
                                states={props.states}
                                coverageTypes={props.coverageTypes}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ClaimantAttorneySummary
                                referralClaimantAttorney={props.referralClaimantAttorney}
                                states={props.states}
                                serviceId={props.referralOverviewDataModel?.referralServiceType[0]?.serviceId}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EmployerAttorneySummary
                                states={props.states}
                                referralEmployerAttorney={props.referralEmployerAttorney}
                                serviceId={props.referralOverviewDataModel?.referralServiceType[0]?.serviceId}
                            />
                        </Grid>
                        {props.referralOverviewDataModel?.referralServiceType[0]?.serviceId !== ServicesEnum.UR &&
                            props.referralOverviewDataModel?.referralServiceType[0]?.serviceId !== ServicesEnum.DME && (
                                <Grid item xs={12}>
                                    <NurseCaseManagerSummary
                                        states={props.states}
                                        referralNurseCaseManager={props.referralNurseCaseManager}
                                        serviceId={props.referralOverviewDataModel?.referralServiceType[0]?.serviceId}
                                    />
                                </Grid>
                            )}
                        <Grid item xs={12}>
                            <AdjusterSummary
                                referralAdjuster={props.referralAdjuster}
                                states={props.states}
                                serviceId={props.referralOverviewDataModel?.referralServiceType[0]?.serviceId}
                            />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <ClaimantSummary
                                referralClaimant={props.referralClaimant}
                                states={props.states}
                                serviceId={props.referralOverviewDataModel?.referralServiceType[0]?.serviceId}
                            />
                        </Grid>
                        
                        <Grid item xs={12}>
                            <ClientSummary
                                referralClient={props.referralClient}
                                states={props.states}
                                serviceId={props.referralOverviewDataModel?.referralServiceType[0]?.serviceId}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <EmployerSummary
                                states={props.states}
                                referralEmployer={props.referralEmployer}
                                serviceId={props.referralOverviewDataModel?.referralServiceType[0]?.serviceId}
                            />
                        </Grid>
                        
                    </Grid>
                </form>
            }
        </Fragment>
    );
};

export const ReferralMsClaimInfo = withRouter(withStyles(styles)(ReferralMsClaimInfoComponent));
