import { FunctionComponent, Fragment, ReactNode } from 'react';
import React from 'react';
import { UserRolesEnum } from '../../../support/enums/UserRoles.Enum';
import { useSelector } from 'react-redux';
import { UsersState } from '../../../store/reducers/usersReducer';

type PrivateComponentProps = {
    authorizedRoles?: Array<UserRolesEnum>;
    children: ReactNode;
    sendBack: boolean;
}

function isAuthorized(userRoles: Array<number>, authorizedRoles: Array<UserRolesEnum>, sendBack: boolean): boolean {
    try {
        if (userRoles && userRoles.length > 0) {
            var index = 0;
            while (index < userRoles.length) {
                if (authorizedRoles.findIndex((role) => userRoles[index] === role) !== -1) {
                    return true;
                }
                index++;
            }
            if (sendBack) {
                window.history.back();
            }
        }
    }
    catch { }

    return false;
}

const PrivateComponent: FunctionComponent<PrivateComponentProps> = ({ authorizedRoles, sendBack, children }: PrivateComponentProps) => {
    const userRoles = useSelector<UsersState, UsersState['roles']>(state => state.roles);
    if (isAuthorized(userRoles, authorizedRoles, sendBack)) {
        return <Fragment>{children}</Fragment>;
    }
    return null;
};

export default PrivateComponent;
