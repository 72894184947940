import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary } from '@material-ui/core';
import styles from "./ImeScorecardSummary.Styles";
import Moment from 'moment';
import { EmptyDataLabel } from '../../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import { ReferralOverviewDataModel } from '../../../../../data-models/ReferralOverview.DataModel';
import { ReferralOverviewImeDataModel } from '../../../../../data-models/ReferralOverviewIme.DataModel';
import { ImeScorecardSectionsEnum } from '../../../../../support/enums/ImeScorecardSections.Enum';
import ImeScorecardSectionDataModel from '../../../../../data-models/ImeScorecardSection.DataModel';

interface IImeScorecardSummaryProps {
    referralOverviewDataModel: ReferralOverviewDataModel,
    referralOverviewImeDataModel: ReferralOverviewImeDataModel,
    imeScorecardSections: Array<ImeScorecardSectionDataModel>;
}

export const ImeScorecardSummary: FunctionComponent<IImeScorecardSummaryProps> = (props: IImeScorecardSummaryProps) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>Overview Summary By Section</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {
                    props.referralOverviewDataModel && props.referralOverviewImeDataModel ?
                        <Grid container spacing={3} className={classes.gridContainer}>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Overview Submitted</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralOverviewImeDataModel?.scorecardResponse?.length > 0 ? 'Yes' : 'No'}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Casualty Score</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralOverviewImeDataModel?.scorecardResponse?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.CasualtyScore)[0]?.value ? props.referralOverviewImeDataModel.scorecardResponse.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.CasualtyScore)[0]?.value : props.imeScorecardSections?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.CasualtyScore)[0]?.options?.filter(option => option.imeScorecardOptionId == props.referralOverviewImeDataModel.scorecardResponse.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.CasualtyScore)[0]?.imeScorecardOptionId)[0]?.value ? props.imeScorecardSections?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.CasualtyScore)[0]?.options?.filter(option => option.imeScorecardOptionId == props.referralOverviewImeDataModel.scorecardResponse.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.CasualtyScore)[0]?.imeScorecardOptionId)[0]?.value : '-'}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Treatment Score</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralOverviewImeDataModel?.scorecardResponse?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.TreatmentScore)[0]?.value ? props.referralOverviewImeDataModel.scorecardResponse.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.TreatmentScore)[0]?.value : props.imeScorecardSections?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.TreatmentScore)[0]?.options?.filter(option => option.imeScorecardOptionId == props.referralOverviewImeDataModel.scorecardResponse.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.TreatmentScore)[0]?.imeScorecardOptionId)[0]?.value ? props.imeScorecardSections?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.TreatmentScore)[0]?.options?.filter(option => option.imeScorecardOptionId == props.referralOverviewImeDataModel.scorecardResponse.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.TreatmentScore)[0]?.imeScorecardOptionId)[0]?.value : '-'}</Typography>
                            </Grid>
                            <Grid item xs={3}>
                                <Typography variant="body2" className={classes.dataLabel}>Work Score</Typography>
                                <Typography variant="subtitle2" className={classes.blackFont}>{props.referralOverviewImeDataModel?.scorecardResponse?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.WorkScore)[0]?.value ? props.referralOverviewImeDataModel.scorecardResponse.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.WorkScore)[0]?.value : props.imeScorecardSections?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.WorkScore)[0]?.options?.filter(option => option.imeScorecardOptionId == props.referralOverviewImeDataModel.scorecardResponse.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.WorkScore)[0]?.imeScorecardOptionId)[0]?.value ? props.imeScorecardSections?.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.WorkScore)[0]?.options?.filter(option => option.imeScorecardOptionId == props.referralOverviewImeDataModel.scorecardResponse.filter(x => x.imeScorecardSectionId == ImeScorecardSectionsEnum.WorkScore)[0]?.imeScorecardOptionId)[0]?.value : '-'}</Typography>
                            </Grid>
                        </Grid>
                        :
                        <EmptyDataLabel></EmptyDataLabel>
                }
            </AccordionDetails>
        </Accordion>
    );
}