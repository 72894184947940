import { makeStyles } from "@material-ui/core";
import { Theme } from '@material-ui/core/styles';


export default makeStyles((theme: Theme) => ({  
    selectInput: {
      width:'100%',
      paddingRight: '20px!important'
    },
    mb2: { marginBottom: `${theme.spacing(2)}px !important` }
}));