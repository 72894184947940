import { useCallback, useState } from "react";

const usePageModal = (openDialog) => {
    const [state, setState] = useState(openDialog);
    const onToggleModal = useCallback(() => setState((ps) => ({ ...ps, openDialog: false })), []);
    const onOpen = useCallback((d) => setState((ps) => ({ ...ps, data: { ...d }, openDialog: true })), []);
    return [{ ...state, onToggleModal }, onOpen, onToggleModal, setState];
  };

export {
    usePageModal
}