import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import SpecialtyEntity from '../../../../entities/Specialty.Entity';
import LanguageEntity from '../../../../entities/Language.Entity';
import SpecificBodyPartEntity from '../../../../entities/SpecificBodyPart.Entity';
import StateEntity from '../../../../entities/State.Entity';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import ImeEntity from '../../../../entities/Ime.Entity';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';
import { ImeReferralForm } from './ime-referral-form/ImeReferralForm.Component';
import { MedicalServiceReferralForm } from './ms-referral-form/MedicalServiceReferralForm.Component';
import InjuryTypeEntity from '../../../../entities/pt/InjuryType.Entity';
import BodySideEntity from '../../../../entities/BodySide.Entity';
import IMECoverLetterEntity from '../../../../entities/IMECoverLetter.Entity';
import MedicalServiceDataModel from '../../../../data-models/MedicalService.DataModel';
import ServiceTypeEntity from '../../../../entities/ServiceType.Entity';
import DiagnosticProcedureDetailDataModel from '../../../../data-models/DiagnosticProcedureDetail.DataModel';
import DiagnosticServiceDataModel from '../../../../data-models/DiagnosticService.DataModel';
import { DiagnosticServiceReferralForm } from './ms-referral-form/DiagnosticServiceReferralForm.Component';
import DiagnosisDataModel from '../../../../data-models/Diagnosis.DataModel';
import { UrReferralForm } from './ms-referral-form/UrReferralForm.Component';
import UtilizationReviewServiceDataModel from '../../../../data-models/UtilizationReviewService.DataModel';
import AppointmentTypeEntity from '../../../../entities/AppointmentType.Entity';
import DestinationEntity from '../../../../entities/Destination.Entity';
import InterpretationTypeEntity from '../../../../entities/InterpretationType.Entity';
import OriginationEntity from '../../../../entities/Origination.Entity';
import TransportationRequiredEntity from '../../../../entities/TransportationRequired.Entity';
import TransportationDataModel from '../../../../data-models/Transportation.DataModel';
import TranslationDataModel from '../../../../data-models/Translation.DataModel';
import { TtReferralForm } from './ms-referral-form/TtReferralForm.Component';
import { WheelchairOrStrecherDetailEntity } from '../../../../entities/WheelchairOrStrecherDetail.Entity';
import { DmeReferralForm } from './ms-referral-form/DmeReferralForm.Component';
import DmeServiceDataModel from '../../../../data-models/dme/DmeService.DataModel';
import ProductCategory from '../../../../entities/dme/ProductCategory.Entity';
import ProductCategoryEntity from '../../../../entities/dme/ProductCategory.Entity';

interface IReferralFormProps {
    //Required for IME
    referralId: number;
    specialties?: Array<SpecialtyEntity>;
    languages?: Array<LanguageEntity>;
    bodyParts?: Array<SpecificBodyPartEntity>;
    states?: Array<StateEntity>;
    ime?: ImeEntity;
    specificBodyParts?: Array<number>;
    handleImeEdit?: (edit: ImeEntity) => void;
    treatingPhysician?: ReferralContactEntity;
    handleTreatingPhysicianEdit?: any;
    getMedicalService?: any;
    optionalImePhysician?: ReferralContactEntity;
    handleOptionalImePhysicianEdit?: any;
    handleImeBodyPartsEdit?: any;
    serviceId?: number;
    //Required for PT
    medicalService: MedicalServiceDataModel;
    handleMedicalServiceEdit: any;
    injuryTypes: Array<InjuryTypeEntity>;
    bodySides: Array<BodySideEntity>;
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean) => void;
    handleImeCoverLetterEdit: (edit: IMECoverLetterEntity) => void;
    coverLetterState: IMECoverLetterEntity;
    serviceTypes: Array<ServiceTypeEntity>;
    procedureDetails: Array<DiagnosticProcedureDetailDataModel>;
    handleSpecificBodyPart: (sbpId: number) => void;

    diagnosticService: DiagnosticServiceDataModel;
    handleDiagnosticServiceEdit: any;
    nextStep: boolean;
    searchDiagnosisByCodeDescription: (description: string) => void;
    diagnosisFindedArray: Array<DiagnosisDataModel>;
    getDxSpecificBodyParts: (serviceTypeId: number) => void;
    loadingDiagnosis: boolean;

    handleUtilizationReviewServiceEdit: any;
    utilizationReviewService: UtilizationReviewServiceDataModel;
    getDxProcedureDetailsByServiceTypeId: (serviceTypeId: number) => void;

    translationService: TranslationDataModel;
    transportationService: TransportationDataModel;
    interpretationTypes: Array<InterpretationTypeEntity>;
    appointmentTypes: Array<AppointmentTypeEntity>;
    transportationRequireds: Array<TransportationRequiredEntity>;
    originations: Array<OriginationEntity>;
    destinations: Array<DestinationEntity>;
    handleTransportationServiceEdit?: (edit: TransportationDataModel) => void;
    handleTranslationServiceEdit?: (edit: TranslationDataModel) => void;
    deleteServiceTypeRequestPtDetail: (procedureId: number) => void;
    handleClearInjuryTypes: () => void;
    wheelchairOrStrecherDetail: Array<WheelchairOrStrecherDetailEntity>;

    //DME
    dmeService: DmeServiceDataModel;
    handleDmeServiceEdit: (edit: DmeServiceDataModel) => void;
    productCategories: Array<ProductCategoryEntity>;
    
    handleSetIsValidImeFunction: (currentPageIsValidImeFunction: () => boolean, serviceTypeString: string) => void;
    handleSetIsValidPtFunction: (currentPageIsValidPtFunction: () => boolean, serviceTypeString: string) => void;
    handleSetIsValidDxFunction: (currentPageIsValidDxFunction: () => boolean, serviceTypeString: string) => void;
    handleSetIsValidDmeFunction: (currentPageIsValidDmeFunction: () => boolean, serviceTypeString: string) => void;
    handleSetIsValidUrFunction: (currentPageIsValidUrFunction: () => boolean, serviceTypeString: string) => void;
    handleSetIsValidTtFunction: (currentPageIsValidTtFunction: () => boolean, serviceTypeString: string) => void;
    
    openCloseStatus: Object;
    handleOpenCloseStatus: (boxName: string, isOpened: boolean) => void;
    
    handleUpdateIme: (currentPageIsValidImeFunction: () => boolean) => void;
    handleUpdateTreatingPhysician: (currentPageIsValidTreatingPhysicianFunction: () => boolean) => void;
    handleUpdateOptionalImePhysician: (currentPageIsValidOptionalImePhysicianFunction: () => boolean) => void;
    handleUpdateImeCoverLetter: () => void;
}

export const ReferralForm: FunctionComponent<IReferralFormProps> = (props: IReferralFormProps) => {
    const getForm = () => {
        switch (props.serviceId) {
            case ServicesEnum.IME:
                return (
                    <ImeReferralForm
                        bodyParts={props.bodyParts}
                        ime={props.ime}
                        languages={props.languages}
                        specialties={props.specialties}
                        specificBodyParts={props.specificBodyParts}
                        states={props.states}
                        optionalImePhysician={props.optionalImePhysician}
                        treatingPhysician={props.treatingPhysician}
                        referralId={props.referralId}
                        handleImeBodyPartsEdit={props.handleImeBodyPartsEdit}
                        handleImeEdit={props.handleImeEdit}
                        handleOptionalImePhysicianEdit={props.handleOptionalImePhysicianEdit}
                        handleTreatingPhysicianEdit={props.handleTreatingPhysicianEdit}
                        coverLetterState={props.coverLetterState}
                        handleImeCoverLetterEdit={props.handleImeCoverLetterEdit}
                        handleSetValidationFunction={props.handleSetValidationFunction}
                        handleSetIsValidImeFunction={props.handleSetIsValidImeFunction}
                        
                        openCloseStatus={props.openCloseStatus}
                        handleOpenCloseStatus={props.handleOpenCloseStatus}
                        
                        handleUpdateIme={props.handleUpdateIme}
                        handleUpdateTreatingPhysician={props.handleUpdateTreatingPhysician}
                        handleUpdateOptionalImePhysician={props.handleUpdateOptionalImePhysician}
                        handleUpdateImeCoverLetter={props.handleUpdateImeCoverLetter}
                    />
                );
            case ServicesEnum.PT:
                return (
                    <MedicalServiceReferralForm
                        handleMedicalServiceEdit={props.handleMedicalServiceEdit}
                        referralId={props.referralId}
                        medicalService={props.medicalService}
                        specificBodyParts={props.bodyParts}
                        bodySides={props.bodySides}
                        injuryTypes={props.injuryTypes}
                        states={props.states}
                        handleSetValidationFunction={props.handleSetValidationFunction}
                        handleSetIsValidPtFunction={props.handleSetIsValidPtFunction}

                        openCloseStatus={props.openCloseStatus}
                        handleOpenCloseStatus={props.handleOpenCloseStatus}

                        serviceId={props.serviceId}
                        serviceTypes={props.serviceTypes}
                        getMedicalService={props.getMedicalService}
                        procedureDetails={props.procedureDetails}
                        diagnosticService={props.diagnosticService}
                        nextStep={props.nextStep}
                        handleDiagnosticServiceEdit={props.handleDiagnosticServiceEdit}
                        handleSpecificBodyPart={props.handleSpecificBodyPart}
                        languages={props.languages}
                        deleteServiceTypeRequestPtDetail={props.deleteServiceTypeRequestPtDetail}
                        handleClearInjuryTypes={props.handleClearInjuryTypes}
                    />
                );
            case ServicesEnum.Diagnostic:
                return (
                    <DiagnosticServiceReferralForm
                        handleMedicalServiceEdit={props.handleMedicalServiceEdit}
                        referralId={props.referralId}
                        medicalService={props.medicalService}
                        specificBodyParts={props.bodyParts}
                        bodySides={props.bodySides}
                        injuryTypes={props.injuryTypes}
                        states={props.states}
                        handleSetValidationFunction={props.handleSetValidationFunction}
                        handleSetIsValidDxFunction={props.handleSetIsValidDxFunction}
                        
                        openCloseStatus={props.openCloseStatus}
                        handleOpenCloseStatus={props.handleOpenCloseStatus}

                        serviceId={props.serviceId}
                        serviceTypes={props.serviceTypes}
                        getMedicalService={props.getMedicalService}
                        procedureDetails={props.procedureDetails}
                        diagnosticService={props.diagnosticService}
                        nextStep={props.nextStep}
                        handleDiagnosticServiceEdit={props.handleDiagnosticServiceEdit}
                        searchDiagnosisByCodeDescription={props.searchDiagnosisByCodeDescription}
                        diagnosisFindedArray={props.diagnosisFindedArray}
                        getDxSpecificBodyParts={props.getDxSpecificBodyParts}
                        getDxProcedureDetailsByServiceTypeId={props.getDxProcedureDetailsByServiceTypeId}
                        languages={props.languages}
                        loadingDiagnosis={props.loadingDiagnosis}
                    />
                );
            case ServicesEnum.UR:
                return (
                    <UrReferralForm
                        referralId={props.referralId}
                        specificBodyParts={props.bodyParts}
                        bodySides={props.bodySides}
                        injuryTypes={props.injuryTypes}
                        states={props.states}
                        handleSetValidationFunction={props.handleSetValidationFunction}
                        handleSetIsValidUrFunction={props.handleSetIsValidUrFunction}
                        
                        openCloseStatus={props.openCloseStatus}
                        handleOpenCloseStatus={props.handleOpenCloseStatus}

                        serviceId={props.serviceId}
                        serviceTypes={props.serviceTypes}
                        getMedicalService={props.getMedicalService}
                        utilizationReviewService={props.utilizationReviewService}
                        nextStep={props.nextStep}
                        handleUtilizationReviewServiceEdit={props.handleUtilizationReviewServiceEdit}
                        handleSpecificBodyPart={props.handleSpecificBodyPart}
                    />
                );
            case ServicesEnum.TT:
                return (
                    <TtReferralForm
                        referralId={props.referralId}
                        states={props.states}
                        handleSetValidationFunction={props.handleSetValidationFunction}
                        handleSetIsValidTtFunction={props.handleSetIsValidTtFunction}
                        
                        openCloseStatus={props.openCloseStatus}
                        handleOpenCloseStatus={props.handleOpenCloseStatus}

                        serviceId={props.serviceId}
                        serviceTypes={props.serviceTypes}
                        nextStep={props.nextStep}
                        handleTranslationServiceEdit={props.handleTranslationServiceEdit}
                        translationService={props.translationService}
                        appointmentTypes={props.appointmentTypes}
                        interpretationTypes={props.interpretationTypes}
                        languages={props.languages}
                        handleTransportationServiceEdit={props.handleTransportationServiceEdit}
                        transportationService={props.transportationService}
                        transportationRequireds={props.transportationRequireds}
                        originations={props.originations}
                        destinations={props.destinations}
                        wheelchairOrStrecherDetail={props.wheelchairOrStrecherDetail}
                    />
                );
            case ServicesEnum.DME:
                return (
                    <DmeReferralForm
                        referralId={props.referralId}
                        serviceTypes={props.serviceTypes}
                        handleSetValidationFunction={props.handleSetValidationFunction}
                        handleSetIsValidDmeFunction={props.handleSetIsValidDmeFunction}

                        openCloseStatus={props.openCloseStatus}
                        handleOpenCloseStatus={props.handleOpenCloseStatus}

                        dmeService={props.dmeService}
                        productCategories={props.productCategories}
                        handleDmeServiceEdit={props.handleDmeServiceEdit}
                        serviceId={props.serviceId}
                        states={props.states}
                    />
                );
            default:
                return '';
        }
    };

    return (
        <Grid container spacing={3}>
            {getForm()}
        </Grid>
    );
};
