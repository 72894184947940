import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { UsersState } from '../../store/reducers/usersReducer';
import { useSelector } from 'react-redux';
import NotFound from '../../components/generic-components/not-found/NotFound.Component';
import { MainContainer } from '../../containers/tc-admin/TcAdmin.Component';
import { VendorReferrals } from '../../containers/vendor-referrals/VendorReferrals.Container';
import AccessControl, {
    checkPermissions,
} from '../../components/generic-components/access-control/AccessControl.Component';
import { vendorPortalRules, vendorReferralList } from '../constants/SecurityRules.Constants';
import { adminNavigationCards } from '../../components/tc-admin/admin-navigation/NavigationCards';

//Add here new routes and related pages

function Router() {
    const location = useLocation();
    return (
        <Switch>
            <Route path="/referrals" exact>
                <AccessControl
                    allowedPermissions={[...vendorPortalRules.read, ...vendorReferralList.read]}
                    sendBack={false}
                    renderNoAccess={<Redirect to="/not-found" />}
                >
                    <VendorReferrals />
                </AccessControl>
            </Route>
            <Route path="/not-found">
                <NotFound />
            </Route>
            <Route path="/">
                <Home path={location.pathname} />
            </Route>
        </Switch>
    );
}
function Home({ path }: { path: string }) {
    try {
        const userSecurityRules = useSelector<UsersState, UsersState['securityRules']>((state) => state.securityRules);
        const foundCard = adminNavigationCards.find((c) =>
            checkPermissions(userSecurityRules, c.allowedPermissions, false),
        );
        if (
            checkPermissions(userSecurityRules, [...vendorPortalRules.read, ...vendorReferralList.read], false) &&
            path === '/'
        ) {
            return <Redirect to="/referrals" />;
        } else if (path === '/') {
            return <Redirect to={foundCard.link} />;
        } else {
            return <MainContainer />;
        }
    } catch {}
    return null;
}

export default Router;
