import internal from "events";

export default class VendorServiceSetUpTableDataModel {
    tenantPartnerVendorByStateId: Array<number>;
    tenantName: string;
    tenantId: number;
    vendorName:string;
    vendorId: number;
    serviceName:string;
    serviceId:number;
    stateName: Array<string>;
    stateId: Array<number>;
    isActive: boolean;
    serviceTypeIdsExcluded:Array<number>;
}