import DestinationInformationEntity from './DestinationInformation.Entity';

export default class ServiceTypeRequestTransportationEntity {
    serviceTypeRequestTransportationId: number;
    referralId: number;
    appointmentDate: Date | string;
    transportationRequiredId: number;
    otherTransportationRequired: string;
    originationId: number;
    originationContactNumber: string;
    originationAddress: string;
    originationFacilityName: string;
    originationCity: string;
    originationStateId: number;
    originationZipCode: string;
    destinations: DestinationInformationEntity[];
    rushRequest: boolean;
    specialInstructions: string;
    appointmentTime: Date | string;
    appointmentLength: string;
    wheelchairOrStretcherDetails: number;
    additionalPassengers: string;
    numberOfSteps: string;
    heightFt: number;
    heightInches: number;
    weightLbs: number;
    rental: boolean;
    oxygen: boolean;
    oxygenLevel: string;
    medicalMonitoring: boolean;
    originationAddressLine2: string;

    // public constructor (serviceTypeNumber: number,serviceTypeName: string){
    //     this.serviceTypeNumber = serviceTypeNumber;
    //     this.serviceTypeName = serviceTypeName;
    // }
}
