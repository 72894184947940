import React from 'react';
import MaterialTable, { MTableAction, MaterialTableProps } from 'material-table';
import styles from './MaterialTable.Styles';
import { ThemeProvider } from '@material-ui/core';

type DataTableProps = {
    tableProps: MaterialTableProps<any>;
    onActionSave?: Function;
    onDelete?: Function;
};

const DataTable = ({ tableProps, onActionSave, onDelete }: DataTableProps) => {
    return (
        <ThemeProvider theme={styles}>
            <MaterialTable
                components={{
                    Container: ({ children }) => children,
                    Action: (props) => {
                        const {
                            action: { custom, icon: Icon, onClick },
                        } = props;
                        if (custom)
                            return <Icon onClick={(event) => typeof onClick === 'function' && onClick(event)} />;
                        return <MTableAction {...props} />;
                    },
                }}
                isLoading={tableProps.isLoading}
                editable={{
                    isEditable: (r) => onActionSave != null && r.editable !== false,
                    isDeletable: (r) => onDelete != null && r.editable !== false,
                    onRowAdd:
                        onActionSave != null
                            ? (newData) => new Promise((resolve) => resolve(onActionSave(newData)))
                            : null,
                    onRowUpdate:
                        onActionSave != null
                            ? (newData) => new Promise((resolve) => resolve(onActionSave(newData)))
                            : null,
                    onRowDelete:
                        onDelete != null ? (oldData) => new Promise((resolve) => resolve(onDelete(oldData))) : null,
                }}
                {...tableProps}
            />
        </ThemeProvider>
    );
};

export default DataTable;
