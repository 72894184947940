import React from 'react';
import { ReactComponent as SuitcaseIcon } from '../../../assets/svgs/suitcase.svg';
import { ReactComponent as AdminIcon } from '../../../assets/svgs/admin_icon.svg';
import { ReactComponent as ClientsIcon } from '../../../assets/svgs/client_menu.svg';
import { ReactComponent as ClaimsIcon } from '../../../assets/svgs/claims_menu.svg';
import { ReactComponent as DashboardIcon } from '../../../assets/svgs/dashboard_menu.svg';
import { ReactComponent as AccessControlIcon } from '../../../assets/svgs/lastAccessControl.svg';
import { ReactComponent as ErrorManagementIcon } from '../../../assets/svgs/errorManagement.svg';
import { ReactComponent as ServiceSetUpIcon } from '../../../assets/svgs/serviceSetUp.svg';
import { ReactComponent as ReportsIcon } from '../../../assets/svgs/document_icon.svg';
import { UserRolesEnum } from '../../../support/enums/UserRoles.Enum';
import {
    accessControlRules,
    adminRules,
    claimsRules,
    clientsRules,
    errorManagementRules,
    referralSubmissionRules,
    reportRules,
    securityProfilesRules,
    serviceSetUpRules,
    vendorDocumentsRules,
    vendorsRules,
} from '../../../support/constants/SecurityRules.Constants';

export interface ISubRoute {
    order?: number;
    icon?: React.ReactNode;
    description?: string;
    nowShow?: boolean;
    subRoutes?: IAdminNavigationCard;
    allowedPermissions?: string[];
    label: string;
    link: string;
}
export interface IAdminNavigationCard {
    order: number;
    section: string;
    subRoutes: ISubRoute[];
    icon: React.ReactNode;
    title: string;
    link: string;
    profile?: {
        subRoutes: { label: string; link: string; allowedPermissions: string[] }[];
    };
    subRoutesRoles?: UserRolesEnum[][];
    getProfile?: boolean;
    hidden: boolean;
    allowedPermissions: string[];
    showTabs: boolean;
}

export const adminNavigationCards: IAdminNavigationCard[] = [
    {
        order: 1,
        section: 'Dashboard',
        subRoutes: [],
        icon: <DashboardIcon />,
        title: 'Dashboard',
        link: '/',
        allowedPermissions: [],
        hidden: false,
        showTabs: true,
    },
    {
        order: 2,
        section: 'Claims',
        subRoutes: [],
        icon: <ClaimsIcon />,
        title: 'Claims',
        link: '/claims',
        allowedPermissions: [...claimsRules.read, ...claimsRules.create, ...claimsRules.update],
        hidden: false,
        showTabs: true,
    },
    {
        order: 3,
        section: 'Reports',
        subRoutes: [],
        icon: <ReportsIcon />,
        title: 'Reports',
        link: '/reports',
        allowedPermissions: reportRules.allFamilyRules,
        hidden: false,
        showTabs: true,
    },
    {
        order: 4,
        section: 'Clients',
        subRoutes: [],
        icon: <ClientsIcon />,
        title: 'Clients',
        link: '/clients',
        allowedPermissions: clientsRules.allFamilyRules,
        profile: {
            subRoutes: [
                {
                    label: 'Profile',
                    link: '/clients/:id/profile',
                    allowedPermissions: ['read:CLIENTS', 'read:CLIENTS.PROFILE'],
                },
                {
                    label: 'Preferred Vendor',
                    link: '/clients/:id/preferred-vendor',
                    allowedPermissions: ['read:CLIENTS', 'read:CLIENTS.PREFERRED_VENDOR'],
                },
            ],
        },
        hidden: false,
        showTabs: true,
    },
    {
        order: 5,
        section: 'Vendor',
        subRoutes: [],
        icon: <SuitcaseIcon />,
        title: 'Vendor',
        link: '/vendors',
        allowedPermissions: vendorsRules.allFamilyRules,
        profile: {
            subRoutes: [
                {
                    label: 'Profile',
                    link: '/vendors/:id/profile',
                    allowedPermissions: vendorsRules.allFamilyRules,
                },
                {
                    label: 'Contacts',
                    link: '/vendors/:id/contacts',
                    allowedPermissions: vendorsRules.allFamilyRules,
                },
                {
                    label: 'Auth Credentials',
                    link: '/vendors/:id/authcredentials',
                    allowedPermissions: vendorsRules.allFamilyRules,
                },
                {
                    label: 'Files',
                    link: '/vendors/:id/files',
                    allowedPermissions: vendorsRules.allFamilyRules,
                },
                {
                    label: 'Billing',
                    link: '/vendors/:id/billing',
                    allowedPermissions: vendorsRules.allFamilyRules,
                },
            ],
        },
        hidden: false,
        showTabs: true,
    },
    {
        order: 6,
        section: 'Admin',
        subRoutes: [
            {
                label: 'Service set up',
                link: '/admin/service-set-up',
                icon: <ServiceSetUpIcon />,
                description: 'The Access to Add Service by Vendor',
                nowShow: true,
                allowedPermissions: [
                    ...adminRules.read,
                    ...adminRules.create,
                    ...serviceSetUpRules.read,
                    ...serviceSetUpRules.create,
                ],
            },
            {
                label: 'Access Control',
                link: '/admin/access-control/users',
                icon: <AccessControlIcon />,
                description: 'The Access to Create users and assign the corresponding permissions',
                nowShow: true,
                allowedPermissions: [
                    ...adminRules.read,
                    ...adminRules.create,
                    ...adminRules.update,
                    ...adminRules.delete,
                    ...accessControlRules.read,
                    ...accessControlRules.create,
                    ...accessControlRules.update,
                    ...accessControlRules.delete,
                    ...securityProfilesRules.read,
                    ...securityProfilesRules.create,
                    ...securityProfilesRules.update,
                    ...securityProfilesRules.delete,
                ],
            },
            {
                label: 'Error Management',
                link: '/admin/error-management',
                icon: <ErrorManagementIcon />,
                description: 'The Access to Manage the Documents and Referral Submissions errors',
                nowShow: true,
                allowedPermissions: [
                    ...adminRules.read,
                    ...adminRules.update,
                    ...errorManagementRules.read,
                    ...errorManagementRules.update,
                    ...referralSubmissionRules.read,
                    ...referralSubmissionRules.update,
                    ...vendorDocumentsRules.read,
                    ...vendorDocumentsRules.update,
                ],
            },
        ],
        subRoutesRoles: [
            //Service set up
            [UserRolesEnum.Admin, UserRolesEnum.TcMainAdmin],
            //users
            [UserRolesEnum.Admin, UserRolesEnum.TcMainAdmin],
            //error management
            [UserRolesEnum.TcMainAdmin],
        ],
        icon: <AdminIcon />,
        title: 'Admin',
        link: '/admin',
        allowedPermissions: adminRules.allFamilyRules,
        hidden: false,
        showTabs: false,
    },
];
