import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import styles from "./UrReferralForm.Styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography, Grid, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ServiceTypeEntity from '../../../../../entities/ServiceType.Entity';
import BodySideEntity from '../../../../../entities/BodySide.Entity';
import InjuryTypeEntity from '../../../../../entities/pt/InjuryType.Entity';
import SpecificBodyPartEntity from '../../../../../entities/SpecificBodyPart.Entity';
import StateEntity from '../../../../../entities/State.Entity';
import { useValidationDialog } from "../../../../../support/custom-hooks/useValidationDialog";
import ReferralContactEntity from '../../../../../entities/ReferralContact.Entity';
import UtilizationReviewServiceDataModel from '../../../../../data-models/UtilizationReviewService.DataModel';
import { ReferringPhysician } from './referring-physician/ReferringPhysician.Component';
import { UrHeader } from "./ur-header/UrHeader.Component";
import UtilizationReviewDetailEntity from "../../../../../entities/UtilizationReviewDetail.Entity";

interface IUrReferralFormProps {
    referralId: number,
    bodySides: Array<BodySideEntity>,
    injuryTypes: Array<InjuryTypeEntity>,
    specificBodyParts: Array<SpecificBodyPartEntity>,
    states: Array<StateEntity>,
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean ) => void,
    handleSetIsValidUrFunction: (currentPageIsValidUrFunction: () => boolean, serviceTypeString: string) => void;
    
    openCloseStatus: Object;
    handleOpenCloseStatus: (boxName: string, isOpened: boolean) => void;

    serviceId?: number,
    serviceTypes: Array<ServiceTypeEntity>,
    getMedicalService?: any,
    nextStep: boolean,
    handleSpecificBodyPart: (sbpId: number) => void,
    handleUtilizationReviewServiceEdit: any,
    utilizationReviewService: UtilizationReviewServiceDataModel,
}

export interface IUrReferralFormErrors {
    referringPhysicianFirstName?: string,
    referringPhysicianLastName?: string,
    referringPhysicianTreatingNPI?: string,
    referringPhysicianAddressLine1?: string,
    referringPhysicianAddressLine2?: string,
    referringPhysicianZipCode?: string,
    referringPhysicianCityName?: string,
    referringPhysicianState?: string,
    referringPhysicianEmail?: string,
    referringPhysicianPrimaryPhoneNumber?: string,
    referringPhysicianFax?: string,
    scheduleSpecialInstructions?: string,
    prescriptionRx?: string;
    impressionResults?: string;
}

export const UrReferralForm: FunctionComponent<IUrReferralFormProps> = (props: IUrReferralFormProps) => {
    const classes = styles();
    const [errors, setErrors] = React.useState<IUrReferralFormErrors>(null);
    const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState<boolean>(false);
    const {Dialog, checkForErrors} = useValidationDialog("Validation","Some required fields are missing.","Ok");

    const [utilizationReviewServiceLocalState, setUtilizationReviewServiceLocalState] = useState<UtilizationReviewServiceDataModel>(props.utilizationReviewService);

    //#region referringPhysician
    const[referringPhysician,setReferringPhysician] = useState<ReferralContactEntity>(props.utilizationReviewService?.referringPhysician);
    const handleReferringPhysicianEdit = (value) => {
        setReferringPhysician(value)
        let newUR : UtilizationReviewServiceDataModel = props.utilizationReviewService;
        newUR.referringPhysician = value;
        setUtilizationReviewServiceLocalState(newUR);
    }
    //#endregion

    //#region serviceTypeRequestUr
    const [serviceTypeRequestUr, setserviceTypeRequestUr] = useState<UtilizationReviewDetailEntity>({
        ...props.utilizationReviewService?.serviceTypeRequestUr,
        referralId: props.referralId,
    });
    const handleServiceTypeRequestUrEdit = (value) => {
        setserviceTypeRequestUr(value);
        let newUr : UtilizationReviewServiceDataModel = props.utilizationReviewService;
        newUr.serviceTypeRequestUr = value;
        setUtilizationReviewServiceLocalState(newUr);
    }
    //#endregion

    useEffect(() => {
        //props.handleSetValidationFunction(validate);
        props.handleSetIsValidUrFunction(isValidUr, "Ur");
    }, [props]);
    
    useEffect(() => { 
        let preService;
        preService = utilizationReviewServiceLocalState;

        preService.referringPhysician = referringPhysician;

        let utilizationReviewService: UtilizationReviewServiceDataModel = props.utilizationReviewService;
        utilizationReviewService.serviceTypeRequestUr = serviceTypeRequestUr;
        props.handleUtilizationReviewServiceEdit(utilizationReviewService);

    }, [props.nextStep]);

    useEffect(() => {
        if ( !props.openCloseStatus.hasOwnProperty( "Ur_box" ) )  props.handleOpenCloseStatus( "Ur_box", true );
        if ( !props.openCloseStatus.hasOwnProperty( "UrReferringPhysician_box" ) ) props.handleOpenCloseStatus( "UrReferringPhysician_box", true );

        if (!props.utilizationReviewService) {
            let newMS = new UtilizationReviewServiceDataModel;
            setUtilizationReviewServiceLocalState(newMS);
        } else {
            let newMS : UtilizationReviewServiceDataModel = props.utilizationReviewService;
            setUtilizationReviewServiceLocalState(newMS);
        }
    }, []);

    const handleOpenCloseStatus = ( event, boxName ) => {
        event.persist();
        props.handleOpenCloseStatus( boxName, !(props.openCloseStatus?.[boxName]??true) );
    };
    
    const validateUr = (isChange?: boolean) => {
        let errors: IUrReferralFormErrors = {};
        errors = getErrorsListUr()
        setErrors(errors);
        return checkForErrors(errors, isChange);
    };

    const isValidUr = () => { //returns true/false to know if the form has passed the validation OK (true) or not (false)
        let errors: IUrReferralFormErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsListUr();
        setErrors(errors);
        isValidResult = (Object.keys(errors).length == 0);
        return isValidResult;
    }

    const getErrorsListUr = () => {//returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: IUrReferralFormErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }
        
        return errors;
    }

    return (
        <Fragment>
            <Grid item xs={12}>
                <Accordion className={classes.paper} expanded={props.openCloseStatus["Ur_box"]??true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                        aria-controls="panel1a-content"
                        id="cliam-accordion"
                        className={classes.paperHeader}
                        onClick={(event) => handleOpenCloseStatus(event, "Ur_box")}
                    >
                        <Typography variant="subtitle2" className={classes.sidebarTitle}>UR/ Precertification/ Preauthorization</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <UrHeader 
                            referralId={props.referralId}
                            errors={errors}
                            validate={validateUr}
                            handleServiceTypeRequestUrEdit={handleServiceTypeRequestUrEdit}
                            hasBeenSubmitted={hasBeenSubmitted}
                            serviceTypes={props.serviceTypes}
                            handleSpecificBodyPart={props.handleSpecificBodyPart}
                            serviceTypeRequestUr={props.utilizationReviewService.serviceTypeRequestUr}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item xs={12}>
                <Accordion className={classes.paper} expanded={props.openCloseStatus["UrReferringPhysician_box"]??true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                        aria-controls="panel1a-content"
                        id="adjuster-accordion"
                        className={classes.paperHeader}
                        onClick={(event) => handleOpenCloseStatus(event, "UrReferringPhysician_box")}
                    >
                        <Typography variant="subtitle2" className={classes.sidebarTitle}>Referring Physician</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ReferringPhysician
                            handleLocalServiceEdit={handleReferringPhysicianEdit}
                            referringPhysician={referringPhysician}
                            referralId={props.referralId}
                            states={props.states}
                            errors={errors}
                            validate={validateUr}
                            hasBeenSubmitted={hasBeenSubmitted}
                            serviceId={props.serviceId}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>
            {Dialog}
        </Fragment>
    );
}