import React, { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { MsReferringPhysician } from './ms-referring-physician/MsReferringPhysician.Component';
import InjuryTypeEntity from '../../../../../entities/pt/InjuryType.Entity';
import BodySideEntity from '../../../../../entities/BodySide.Entity';
import StateEntity from '../../../../../entities/State.Entity';
import UtilizationReviewServiceDataModel from "../../../../../data-models/UtilizationReviewService.DataModel";
import { UrHeaderSummary } from "./ur-header-summary/UrHeaderSummary.Component";

type UrReferralRequestProps = {
    injuryTypes: Array<InjuryTypeEntity>;
    bodySides: Array<BodySideEntity>;
    utilizationReviewService: UtilizationReviewServiceDataModel;
    states: Array<StateEntity>;
    referralId: number;
    serviceId: number;
}

export const UrReferralRequest: FunctionComponent<UrReferralRequestProps> = (props: UrReferralRequestProps) => {
    return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <UrHeaderSummary 
                        serviceTypeRequestUr={props.utilizationReviewService?.serviceTypeRequestUr}
                    />
                </Grid>
                <Grid item xs={12}>
                    <MsReferringPhysician medicalService={props.utilizationReviewService} states={props.states}/>
                </Grid>
            </Grid>
    );
}