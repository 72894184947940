import React, { FunctionComponent } from 'react';
import styles from './ReferralDmeSummary.Styles';
import { Accordion, AccordionDetails, AccordionSummary, Grid, TextField, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DmeResponseDataModel from '../../../../../data-models/dme/DmeResponse.DataModel';
import Moment from 'moment';

interface IReferralDmeSummaryProps {
    dmeResponse: DmeResponseDataModel;
}

export const ReferralDmeSummary: FunctionComponent<IReferralDmeSummaryProps> = (props: IReferralDmeSummaryProps) => {
    const classes = styles();

    return (
        <Accordion defaultExpanded={true} className={classes.paper}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                {
                    <Typography variant="subtitle2" className={classes.sidebarTitle}>
                        Durable Medical Equipment
                    </Typography>
                }
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={3}>
                        <TextField
                            multiline
                            className={classes.input}
                            disabled
                            label="Sent Date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={
                                props.dmeResponse?.products[0]?.sentDate
                                    ? Moment(props.dmeResponse?.products[0]?.sentDate).format('MM/DD/YYYY')
                                    : ''
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            multiline
                            className={classes.input}
                            disabled
                            label="Deliver"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={
                                props.dmeResponse?.products[0]?.deliver ? props.dmeResponse?.products[0]?.deliver : ''
                            }
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};
