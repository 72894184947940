import React, { Fragment, FunctionComponent, useEffect, useRef } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, TextField, Checkbox, Button, Paper, List, ListSubheader, IconButton, FormControl, InputLabel } from '@material-ui/core';
import styles from "./ReferralDocument.Styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Moment from 'moment';
import { MaterialTableProps } from 'material-table';
import MaterialTable from "../../../generic-components/material-table/MaterialTable.Component";
import { IDocumentsFilter } from '../../../../support/interfaces/IDocumentsFilter';
import ReferralDocumentEntity from '../../../../entities/ReferralDocument.Entity';
import DeleteIcon from '@material-ui/icons/Delete';
import DocumentTypeEntity from '../../../../entities/DocumentType.Entity';
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import { ServicesEnum } from "../../../../support/enums/Services.Enum";
import { useValidationDialog } from '../../../../support/custom-hooks/useValidationDialog';

interface IReferralDocumentProps {
    handleGetDocuments: any;
    handleSelectedDocumentsEdit: any;
    documents: Array<ReferralDocumentEntity>;
    selectedDocuments: Array<ReferralDocumentEntity>;
    documentTypes: Array<DocumentTypeEntity>;
    serviceId?: number;
    referralId: number;
    handleSetValidationFunction: (currentPageValidationFunction: () => boolean ) => void;
    handleSetIsValidDocumentsFunction: (currentFormIsValidFunction: () => boolean, referralComponentType: string) => void;
    handleUpdateDocuments: (currentPageValidationFunction: () => boolean) => void;
    handleSetFocusDocuments: (currentPageSetFocusFunction: () => boolean) => void;
    openCloseStatus: Object;
    handleOpenCloseStatus: any;
}

export interface IReferralDocumentErrors {
    atLeastOneDocumentIsRequired?: string;
    noDocumentTypeSelected?: string;
    dxRequiresRXDocumentType?: string;
}

export const ReferralDocument: FunctionComponent<IReferralDocumentProps> = (props: IReferralDocumentProps) => {
    const classes = styles();
    const abortController = new AbortController();
    const [errors, setErrors] = React.useState<IReferralDocumentErrors>(null);
    const [hasBeenSubmitted, setHasBeenSubmitted] = React.useState<boolean>(false);
    const {Dialog, checkForErrors} = useValidationDialog("Validation","Please select a Document Type. At least one document is required to attach.","Ok");
    const {Dialog:DialogForDx, checkForErrors:checkForErrorsForDx} = useValidationDialog("Validation","Please attach a RX/Prescription Request Document to continue.","Ok");
    const {Dialog:DialogForTT, checkForErrors:checkForErrorsForTT} = useValidationDialog("Validation","Please select a Document Type.","Ok");
    
    const [documentsFilterState, setdocumentsFilterState] = React.useState(null as IDocumentsFilter);
    const [dateFiltersDisabled, setDateFiltersDisabled] = React.useState(true);
    const [incomingDocumentsState, setIncomingDocumentsState] = React.useState(props.documents);
    const [selectedDocumentsState, setSelectedDocumentsState] = React.useState(props.selectedDocuments);

    const refRequestDocuments = useRef(null);
    const refGetDocuments = useRef(null);

    //componentDidMount
    useEffect(() => {
        if ( !props.openCloseStatus.hasOwnProperty("Documents_box" ) ) {
            //alert( "set: Documents_box" );
            props.handleOpenCloseStatus("Documents_box", true);}
    },[]);

    useEffect(() => {
        //props.handleSetValidationFunction(validate);
        props.handleSetIsValidDocumentsFunction(isValid, "Documents");
        props.handleSetFocusDocuments(handleSetFocusDocuments);
        
        handleIncomingDocuments(props.documents);
        setSelectedDocumentsState(props.selectedDocuments);

        return function cleanup() {
            abortController.abort();
        }
    }, [props]);

    const handleOpenCloseStatus = ( event, boxName ) => {
        event.persist();
        props.handleOpenCloseStatus( boxName, !(props.openCloseStatus?.[boxName]??true) );
    };
    
    const incomingFilesTableProps: MaterialTableProps<ReferralDocumentEntity> = {
        columns: [
            {
                title: "Description",
                field: "fileDescription"
            },
            {
                title: "File Name",
                field: "fileName"
            },            
            {
                title: "Document Type",
                field: "documentType"
            },            
            {
                title: "Date",
                render: rowData => { return (rowData.dueDate ? Moment(rowData.dueDate).format("M/D/YYYY") : "") }
            }
        ],
        data: incomingDocumentsState?? [],
        options: {
            selection: true,
            paging: false,
            toolbar: false,
            selectionProps: rowData => ({
                disabled: !rowData.fileName,
                color: 'primary'
              })
        },
        onSelectionChange: (rows) => addSelectedDocuments(rows),
    };

    const selectedFilesTableProps: MaterialTableProps<ReferralDocumentEntity> = {
        columns: [
            {
                title: "Description",
                field: "fileDescription"
            },
            {
                title: "File Name",
                field: "fileName"
            },
            {
                title: "Document Type",
                field: "documentType"
            },
            {
                title: "Date",
                render: rowData => (rowData.dueDate ? Moment(rowData.dueDate).format("M/D/YYYY") : "")
            },
            {
                title: "",
                render: rowData => <IconButton
                    color="inherit"
                    edge="start"
                    onClick={() => handleRemoveRowSelection(rowData.documentNumber)}
                >
                    <DeleteIcon />
                </IconButton>
            }
        ],
        data: selectedDocumentsState?? [],
        options: {
            paging: false,
            toolbar: false
        }
    };

    const handleRemoveRowSelection = (documentNumber: string) => {
        if (incomingFilesTableProps.data && incomingFilesTableProps.data.length > 0) {
            let documentIndex = (incomingFilesTableProps.data as Array<any>).findIndex(x => x.documentNumber == documentNumber);
            if (documentIndex !== null && documentIndex !== undefined && documentIndex !== -1) {
                let newIncomingFileTableProps = (incomingFilesTableProps.data as Array<any>);
                newIncomingFileTableProps[documentIndex].tableData.checked = false;
                setIncomingDocumentsState(newIncomingFileTableProps);
            }
        }
        let newSelectedDocuments = selectedDocumentsState.filter(x => x.documentNumber !== documentNumber);
        setSelectedDocumentsState(newSelectedDocuments);
        props.handleSelectedDocumentsEdit(newSelectedDocuments);
    }

    const addSelectedDocuments = (rows: Array<ReferralDocumentEntity>) => {
        let newSelectedDocuments = selectedDocumentsState?? [];

        rows.forEach(row => {
            let documentIndex = newSelectedDocuments.findIndex(x => x.documentNumber === row.documentNumber);
            if (documentIndex === -1) {
                let newDoc = { 
                    documentId : row.documentId, 
                    action : row.action, 
                    documentName : row.documentName,
                    documentNumber: row.documentNumber,
                    documentPageCount: row.documentPageCount,
                    documentType: row.documentType,
                    documentTypeId: row.documentTypeId,
                    dueDate: row.dueDate,
                    fileDescription: row.fileDescription ? row.fileDescription : 'No description',
                    fileName: row.fileName,
                    fileSize: row.fileSize,
                    link: row.link,
                    referralId: row.referralId,
                    uploadedBy: row.uploadedBy  
                } as ReferralDocumentEntity;
                newSelectedDocuments.push(newDoc);
            }
        });
        
        setSelectedDocumentsState(newSelectedDocuments);
        props.handleSelectedDocumentsEdit(newSelectedDocuments);

        let newIncomingFileTableProps = (incomingFilesTableProps.data as Array<any>)
        newSelectedDocuments.forEach(document => {
            let selectedDocumentIndex = rows.findIndex(x => x.documentNumber === document.documentNumber);
            if (selectedDocumentIndex === -1) {
                if (incomingFilesTableProps.data && incomingFilesTableProps.data.length > 0) {
                    let incomingDocumentIndex = (incomingFilesTableProps.data as Array<any>).findIndex(x => x.documentNumber == document.documentNumber);
                    if (incomingDocumentIndex !== null && incomingDocumentIndex !== undefined && incomingDocumentIndex !== -1) {
                        newIncomingFileTableProps[incomingDocumentIndex].tableData.checked = false;
                        newSelectedDocuments = newSelectedDocuments.filter(x => x.documentNumber !== document.documentNumber);
                    }
                }
            }
        });

        setIncomingDocumentsState(newIncomingFileTableProps);
        setSelectedDocumentsState(newSelectedDocuments);
        props.handleSelectedDocumentsEdit(newSelectedDocuments);
    }

    const handleIncomingDocuments = (incomingDocuments: Array<ReferralDocumentEntity>) => {
        setIncomingDocumentsState(incomingDocuments);
        (incomingFilesTableProps.data as any[]).forEach(incomingTableDocument => {
            let documentIndex = selectedDocumentsState?.findIndex(x => x.documentNumber === incomingTableDocument.documentNumber);
            if (documentIndex !== -1) {
                incomingTableDocument.tableData.checked = true;
            }
        });
        setIncomingDocumentsState(incomingDocuments);
    }

    const handleDocumentsFilterChange = (event) => {
        event.persist();

        let newDocumentsFilter: IDocumentsFilter = documentsFilterState ? documentsFilterState : {} as IDocumentsFilter;
        if (event.target.type == 'checkbox') {
            if (event.target.checked) {
                newDocumentsFilter['documentTypes'] ? newDocumentsFilter['documentTypes'].push(event.target.id) : newDocumentsFilter['documentTypes'] = [event.target.id];
            }
            else {
                newDocumentsFilter['documentTypes'] = newDocumentsFilter['documentTypes'].filter(x => x != event.target.id);
            }
        }
        else {
            newDocumentsFilter[event.target.id ? event.target.id : event.target.name] = event.target.value == "true" ? true : event.target.value == "false" ? false : event.target.value;
        }

        let dateFilteredDocumentTypes = props.documentTypes.filter(x => x.isDateFilterRequired);
        if (!newDocumentsFilter['documentTypes'].some(x => dateFilteredDocumentTypes.filter(y => y.documentTypeNumber == x).length > 0)) {
            newDocumentsFilter.dateInitial = null;
            newDocumentsFilter.dateEnd = null;
        }

        setdocumentsFilterState(newDocumentsFilter);
        setDateFiltersDisabled(!newDocumentsFilter['documentTypes'].some(x => dateFilteredDocumentTypes.filter(y => y.documentTypeNumber == x).length > 0));
    };

    const handleGetDocuments = () => {
        if(documentsFilterState?.documentTypes?.length > 0){
            props.handleGetDocuments(documentsFilterState?.documentTypes, documentsFilterState?.dateInitial ? documentsFilterState.dateInitial : null, documentsFilterState?.dateEnd ? documentsFilterState.dateEnd : null, props.referralId)
        } else {
            let errors: IReferralDocumentErrors = {};
            /*if (documentsFilterState?.documentTypes?.length == 0){
                errors.noDocumentTypeSelected = "Required";
                if ( props.serviceId === ServicesEnum.TT ){
                    checkForErrorsForTT(errors,false);
                } else {
                    checkForErrors(errors, false);
                }
            }*/
                /**/
            errors.noDocumentTypeSelected = documentsFilterState && documentsFilterState?.documentTypes?.length > 0 ? "" : "Required";
            if(props.serviceId !== ServicesEnum.TT){
                checkForErrors(errors,false);
            } else {
                checkForErrorsForTT(errors,false);
            }
        }
    }

    const validate = (isChange?: boolean) => {
        let errors: IReferralDocumentErrors = {};
        errors = getErrorsList();
        setErrors(errors);
        return checkForErrors(errors, isChange);
    }

    const isValid = () => { //returns true/false to know if the form has passed the validation OK (true) or not (false)
        let errors: IReferralDocumentErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsList();
        setErrors(errors);
        isValidResult = (Object.keys(errors).length == 0);
        return isValidResult;
    }
    
    const getErrorsList = () => {//returns an object with properties (just the failed fields). These properties are strings with the validation message        
        let errors: IReferralDocumentErrors = {};
        if (!hasBeenSubmitted) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmitted(true);
        }

        if ( [ServicesEnum.IME,ServicesEnum.UR].includes(props.serviceId))
        {
            if (selectedDocumentsState?.length === 0) {
                errors.atLeastOneDocumentIsRequired = "Required";
            }
        }

        return errors;
    }

    const handleSetFocusDocuments = (component: string = 'title') => {
        if (component == 'title')
            refRequestDocuments.current.focus();
        else if (component == 'searchButton')
            refGetDocuments.current.focus();
        return true;
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                    <Accordion className={classes.paper} expanded={props.openCloseStatus["Documents_box"]??true} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                            aria-controls="panel1a-content"
                            id="documents-accordion"
                            className={classes.paperHeader}
                            ref={refRequestDocuments}
                            onClick={(event) => handleOpenCloseStatus(event, "Documents_box")}
                        >
                            <Typography variant="subtitle2" className={classes.sidebarTitle}>
                            Documents
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <Grid container spacing={1} className={classes.gridContainer}>
                            {
                                props.documentTypes && props.documentTypes.length > 0 ?
                                    <Fragment>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <Typography variant="caption" className={classes.serviceTypeSubtitle}>{props.serviceId === ServicesEnum.IME ? 'Independent Medical Exam (IME)' : props.serviceId === ServicesEnum.PT ? 'Physical Medicine' : props.serviceId === ServicesEnum.Diagnostic ?  'Diagnostic' : props.serviceId === ServicesEnum.UR ? 'Utilization Review' : '' }</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography
                                                    variant="caption"
                                                    className={classes.actionSubtitle}
                                                >
                                                    Select document type. {props.serviceId === ServicesEnum.IME ? 'At least one document is required to attach.' : ''}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        {
                                            props.documentTypes.map(documentType =>
                                                <Grid item xs={12} container spacing={3} key={'grLevel_01_' + documentType.documentTypeNumber}>
                                                    <Grid item xs={documentType.orderToDisplay == 1 ? 3 : 12} key={'grLevel_02_' + documentType.documentTypeNumber}>
                                                        <FormControl 
                                                            key={'fc_' + documentType.documentTypeNumber}                                                            
                                                            className={classes.checkboxFormControl}
                                                        >
                                                            <InputLabel
                                                                key={'il_' + documentType.documentTypeNumber}
                                                                shrink={false}
                                                                disableAnimation 
                                                                className={classes.checkboxLabel}
                                                                htmlFor={documentType.documentTypeNumber}
                                                            >
                                                                {documentType.documentTypeName}
                                                            </InputLabel>
                                                            <Checkbox
                                                                key={'chBox_' + documentType.documentTypeNumber}
                                                                id={documentType.documentTypeNumber}
                                                                name={documentType.documentTypeNumber}
                                                                disableRipple
                                                                onClick={(event) => handleDocumentsFilterChange(event)}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    {
                                                        documentType.orderToDisplay == 1 && props.documentTypes.filter(x => x.isDateFilterRequired).length > 0 && documentsFilterState?.documentTypes?.some(x => props.documentTypes.filter(x => x.isDateFilterRequired).filter(y => y.documentTypeNumber == x).length > 0) ?
                                                            <Fragment>
                                                                <Grid item xs={3}>
                                                                    <TextField label="Date Range From" id="dateInitial" type="date" InputLabelProps={{ shrink: true }} value={documentsFilterState?.dateInitial} onChange={(event) => handleDocumentsFilterChange(event)} disabled={dateFiltersDisabled} />
                                                                </Grid>
                                                                <Grid item xs={3}>
                                                                    <TextField label="Date Range To" id="dateEnd" type="date" InputLabelProps={{ shrink: true }} value={documentsFilterState?.dateEnd} onChange={(event) => handleDocumentsFilterChange(event)} disabled={dateFiltersDisabled} />
                                                                </Grid>
                                                            </Fragment>
                                                            : ''
                                                    }
                                                </Grid>
                                            )
                                        }
                                        <Grid item xs={12} className={classes.buttonContainer}>
                                            <Button className={classes.nextButton} onClick={handleGetDocuments} variant="contained" color="primary" ref={refGetDocuments}>
                                                Search Documents
                                            </Button>
                                        </Grid>
                                        {( ( incomingFilesTableProps.data as any[] ).length > 0 || selectedDocumentsState.length > 0 ) ? 
                                            <>
                                                <Grid item xs={6}>
                                                    <Paper className={classes.paperInternal}>
                                                        <List
                                                            component="nav"
                                                            aria-labelledby="nested-list-subheader"
                                                            subheader={
                                                                <ListSubheader component="div" id="nested-list-subheader" className={classes.listHeader}>
                                                                    <Typography variant="subtitle2" className={classes.paperTitle}>Available Documents Grid</Typography>
                                                                    <Typography 
                                                                        variant="caption"
                                                                        className={classes.listHeaderSubTitle}
                                                                    > 
                                                                        {props.serviceId === ServicesEnum.IME ? 'Select from list the Documents to Attach':''}
                                                                    </Typography>
                                                                </ListSubheader>
                                                            }
                                                            className={classes.root}
                                                        >
                                                            <MaterialTable tableProps={incomingFilesTableProps} />
                                                        </List>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Paper className={classes.paperInternal}>
                                                        <List
                                                            component="nav"
                                                                aria-labelledby="nested-list-subheader"
                                                            subheader={
                                                                <ListSubheader component="div" id="nested-list-subheader" className={classes.listHeader}>
                                                                    <Typography variant="subtitle2" className={classes.paperTitle}>Selected Documents Grid</Typography>
                                                                </ListSubheader>
                                                            }
                                                            className={classes.root}
                                                        >
                                                            <MaterialTable tableProps={selectedFilesTableProps} />
                                                        </List>
                                                    </Paper>
                                                </Grid>
                                            </>
                                        :
                                            ''
                                        }
                                    </Fragment>
                                    :
                                    <EmptyDataLabel />
                            }
                        </Grid>
                        </AccordionDetails>
                    </Accordion>
            </Grid>


            {Dialog}
            {DialogForTT}
        </Grid>
    );
}