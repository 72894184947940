import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { BorderBottom } from "@material-ui/icons";

const drawerWidth = 100;

export default makeStyles((theme: Theme) => ({
    paper:{
        paddingBottom:'20px',
    },
    formContainer:{
        paddingRight:'25px',
        paddingLeft:'25px',
        paddingTop:'20px',
        paddingBottom:'20px',
    },
    greenText:{
        color:'#428302',
    },
    header:{
        display:'flex',
        width:'100%',
        alignItems:'center',
        justifyContent:'space-between',
        paddingLeft:'25px',
        paddingRight:'20px',
    },
    button:{
        color:'white',
        backgroundColor:'#107A98',
        marginLeft:'20px',
        '&:hover': {
            backgroundColor: '#004763!important',
        },
    },
    cancelButton:{
        color:'black',
        backgroundColor:'#e5e5e5',
        marginLeft:'40px',
        '&:hover': {
            backgroundColor: '#f0f0f0 !important',
        },
    },
    buttonsContainer:{
        display:'flex',
        alignItems:'center',
        marginTop:'40px',
        justifyContent:'flex-end',
        marginRight:'20px',
    },
    selectInput: {
      width:'100%',
    },
    smallSelectInput: {
      width:'50%',
    },
    gridContainer:{
        paddingRight:'20px',
        paddingLeft:'40px'
    },
    paperTitle: {
        color: '#428302',
        marginBottom:'15px',
    },
    servicesContainer:{
        width:'100%',
        maxHeight:'96px',
        overflowY:'scroll',
    },
    serviceContainer:{
        display:'flex',
        alignItems:'center',
        width: '100%',
        justifyContent:'space-between',
        paddingBottom:'6px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    newServiceContainer:{
        display:'flex',
        alignItems:'flex-end',
        width: '100%',
        justifyContent:'space-between',
        paddingBottom:'6px',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
    },
    serviceName:{
        width:'60%',
        marginRight:'25px'
    },
    servicePrice:{
        width:'40%'
    },
}));