import { apiDelete, apiGet, apiPost, apiPut } from '../support/helpers/ApiConnection.Helper';
import ClientEntity from "../entities/Client.Entity";
import ClientPreferredVendorDataModel from "../data-models/ClientPreferredVendor.DataModel";
import EditedClientPreferredVendorDataModel from "../data-models/EditedPreferredVendor.DataModel";

export default function getClients(abortSignal: AbortSignal): Promise<Array<ClientEntity>> {   
    return apiGet<Array<ClientEntity>>('/clients',abortSignal);
}

export function getClient(clientId: number,  abortSignal: AbortSignal): Promise<ClientEntity> {   
    return apiGet<ClientEntity>(`/clients/admin-client/${clientId}`,abortSignal);
}

export function createClient(newClient: ClientEntity, abortSignal: AbortSignal): Promise<ClientEntity> {
    return apiPost<ClientEntity>('/clients', newClient, abortSignal);
}

export function editClient(clientId: number, client: ClientEntity, abortSignal: AbortSignal): Promise<ClientEntity> {   
    return apiPut<ClientEntity>(`/clients`,clientId, client ,abortSignal);
}

export function getClientPreferredVendors(clientId: number, abortSignal: AbortSignal): Promise<Array<ClientPreferredVendorDataModel>> {
    return apiGet<Array<ClientPreferredVendorDataModel>>(`/clients/preferred-vendors/${clientId}`, abortSignal);
}

export function createClientPreferredVendor(clientId: number, clientPreferredVendor: ClientPreferredVendorDataModel, abortSignal:AbortSignal): Promise<ClientPreferredVendorDataModel> {
    return apiPost<ClientPreferredVendorDataModel>(`/clients/preferred-vendors/${clientId}`, clientPreferredVendor, abortSignal, false, false);
}

export function changeClientPreferredVendorStatus(ids: Array<number>, abortSignal: AbortSignal): Promise<Array<ClientPreferredVendorDataModel>> {
    const body = {
        ids
    };
    return apiPost<any>('/clients/preferred-vendors-change-status', body, abortSignal);
}

export function editClientPreferredVendor(clientId: number, clientPreferredVendor: EditedClientPreferredVendorDataModel, abortSignal:AbortSignal): Promise<EditedClientPreferredVendorDataModel> {
    return apiPut<EditedClientPreferredVendorDataModel>(`/clients/preferred-vendors`,clientId, clientPreferredVendor, abortSignal);
}

export function createNewClientPreferredVneodrAndDeleteOldOne(clientId: number, clientPreferredVendor: EditedClientPreferredVendorDataModel, abortSignal:AbortSignal): Promise<EditedClientPreferredVendorDataModel> {
    return apiPut<EditedClientPreferredVendorDataModel>(`/clients/update-preferred-vendors`,clientId, clientPreferredVendor, abortSignal);
}

export function getClientsByTenantId(tenantId: number, abortSignal: AbortSignal): Promise<Array<ClientEntity>> {   
    return apiGet<Array<ClientEntity>>(`/clients/tenant-admin-clients/${tenantId}`,abortSignal);
}