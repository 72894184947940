import React, { FunctionComponent } from 'react';
import { Accordion, AccordionDetails, Typography, Grid, AccordionSummary } from '@material-ui/core';
import styles from "./UrResponseSummary.Styles";
import UtilizationReviewResponseDataModel from "../../../../data-models/UtilizationReviewResponse.DataModel";
import Moment from 'moment';
import { MaterialTableProps } from "material-table";
import DescriptionCodeUrDataModel from "../../../../data-models/DescriptionCodeUr.DataModel";
import MaterialTable from "../../../generic-components/material-table/MaterialTable.Component";

interface IUrResponseSummaryProps {
    utilizationReviewResponse: UtilizationReviewResponseDataModel,
}

export const UrResponseSummary: FunctionComponent<IUrResponseSummaryProps> = (props: IUrResponseSummaryProps) => {
    const classes = styles();

    const descriptionCodeTableProps: MaterialTableProps<DescriptionCodeUrDataModel> = {
        columns: [
            {
                title: "Code",
                field: "code"
            },
            {
                title: "Description",
                field: "description"
            },
            {
                title: "Status",
                field: "status"
            },
            {
                title: "Received Date",
                field: "createdDate",
                render: rowData => <span> {Moment(rowData.createdDate).format('MM/DD/YYYY')} </span>
            },
        ],
        data: props.utilizationReviewResponse?.descriptionCodes ?? [],
        options: {
            selection: false,
            paging: false,
            toolbar: false
        },
    }

    return (
        <Accordion defaultExpanded={true} className={classes.paper} square>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="cliam-accordion"
                className={classes.paperHeader}
            >
                <Typography variant="subtitle2" className={classes.paperTitle}>UR/ Precertification/ Preauthorization</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3} className={classes.gridContainer}>
                    <Grid item xs={12}>
                        <MaterialTable
                            tableProps={descriptionCodeTableProps}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body2" className={classes.dataLabel}>Additional Notes</Typography>
                        {
                            props.utilizationReviewResponse?.additionalNotes.map( additionalNote => 
                                <Typography key={additionalNote.note} variant="subtitle2" className={classes.blackFont}>{additionalNote.note}</Typography>
                            )
                        }
                        
                    </Grid>
                </Grid>                    
            </AccordionDetails>
        </Accordion>
    );
}