import { apiGet } from '../support/helpers/ApiConnection.Helper';
import DiagnosisDataModel from '../data-models/Diagnosis.DataModel';

export function getDiagnosis(abortSignal: AbortSignal): Promise<Array<DiagnosisDataModel>> {   
    return apiGet<Array<DiagnosisDataModel>>('/diagnosis',abortSignal);
}

export function getDiagnosisById(diagnosisId: number,abortSignal: AbortSignal): Promise<DiagnosisDataModel> {   
    return apiGet<DiagnosisDataModel>('/diagnosis/' + diagnosisId, abortSignal);
}

export function getDiagnosisByDescription(diagnosisDescription: string, abortSignal: AbortSignal): Promise<Array<DiagnosisDataModel>> {   
    let querystring = '';
    querystring += 'CodeDescription=' + diagnosisDescription;
    return apiGet<Array<DiagnosisDataModel>>('/diagnosis/code-description/',abortSignal,querystring);
}