import React, { FunctionComponent, useEffect } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    InputLabel,
    Select,
    MenuItem,
    Button,
} from '@material-ui/core';
import TextfieldMask from '../../../generic-components/texfield-mask/TextfieldMask.Component';
import styles from './ReferralClaimantAttorney.Styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StateEntity from '../../../../entities/State.Entity';
import ReferralContactEntity from '../../../../entities/ReferralContact.Entity';
import { EmptyDataLabel } from '../../../generic-components/empty-data-label/EmptyDataLabel.Component';
import { ServicesEnum } from '../../../../support/enums/Services.Enum';
import { useValidationDialog } from '../../../../support/custom-hooks/useValidationDialog';
import { ClearSelect } from '../../../generic-components/clear-select/ClearSelect.Component';


interface IReferralClaimantAttorneyProps {
    referralId: number;
    states: Array<StateEntity>;    
    referralClaimantAttorney: ReferralContactEntity;    
    handleClaimantAttorneyEdit: any;    
    handleSetIsValidClaimantAttorneyFunction: (currentFormIsValidClaimantAttorneyFunction: () => boolean, referralContactType: string) => void;
    openCloseStatus: Object;
    handleOpenCloseStatus: any;
    serviceId: number;    
    handleUpdateClaimantAttorney: (currentPageValidationFunction: () => boolean) => void;
}

interface IErrors {    
    attorneyFirstName?: string;
    attorneyLastName?: string;
    attorneyAddressLine1?: string;
    attorneyAddressLine2?: string;
    attorneyZipCode?: string;
    attorneyCityName?: string;
    attorneyState?: string;
    attorneyEmail?: string;
    attorneyPrimaryPhoneNumber?: string;
    attorneyFax?: string;
}

export const ReferralClaimantAttorney: FunctionComponent<IReferralClaimantAttorneyProps> = (props: IReferralClaimantAttorneyProps) => {

    const classes = styles();
    const abortController = new AbortController();
   
    const [referralClaimantAttorneyState, setreferralClaimantAttorneyState] = React.useState(null);    
    const [errorsClaimantAttorney, setErrorsClaimantAttorney] = React.useState<IErrors>(null);
    const [hasBeenSubmittedClaimantAttorney, setHasBeenSubmittedClaimantAttorney] = React.useState<boolean>(false);
    const { Dialog, checkForErrors } = useValidationDialog('Validation', 'Some required fields are missing.', 'Ok');

    //componentDidMount
    useEffect(() => {
        window.scrollTo(0,0);        
        if ( !props.openCloseStatus.hasOwnProperty("ClaimantAttorney_box" ) ) props.handleOpenCloseStatus("ClaimantAttorney_box", true);
    },[]);

    useEffect(() => {
        
        if (props.referralClaimantAttorney?.primaryPhoneNumber?.length == 0) {
            setreferralClaimantAttorneyState({ ...props.referralClaimantAttorney, primaryPhoneNumber: null });
        } else {
            setreferralClaimantAttorneyState(props.referralClaimantAttorney);
        }
        props.handleSetIsValidClaimantAttorneyFunction(isValidClaimantAttorney, "ClaimantAttorney");
        
        return function cleanup() {
            abortController.abort();
        };
    }, [props]);

    const handleOpenCloseStatus = ( event, boxName ) => {
        event.persist();
        props.handleOpenCloseStatus( boxName, !(props.openCloseStatus?.[boxName]??true) );
    };
        
    const handleTextFieldChangeClaimantAttorney = (event) => {
        event.persist();
        let newClaimantAttorney: ReferralContactEntity = referralClaimantAttorneyState;
        newClaimantAttorney[event.target.id ? event.target.id : event.target.name] =
            event.target.value == 'true' ? true : event.target.value == 'false' ? false : event.target.value;
        setreferralClaimantAttorneyState(newClaimantAttorney);
        props.handleClaimantAttorneyEdit(newClaimantAttorney);
        
    };

    const handleClearSelectFieldChangeClaimantAttorney = (selectId: string) => {
        let newClaimantAttorney: ReferralContactEntity = referralClaimantAttorneyState;
        newClaimantAttorney[selectId] = null;
        setreferralClaimantAttorneyState(newClaimantAttorney);
        props.handleClaimantAttorneyEdit(newClaimantAttorney);
        
    };
        
    const validateClaimantAttorney = (isChange?: boolean) => {

        let errors: IErrors = {};
        errors = getErrorsListClaimantAttorney()
        setErrorsClaimantAttorney(errors);
        return checkForErrors(errors, isChange);
    };

      
    const isValidClaimantAttorney = () => {
        let errors: IErrors = {};
        let isValidResult: boolean = false;
        errors = getErrorsListClaimantAttorney();
        isValidResult = (Object.keys(errors).length == 0);
        setErrorsClaimantAttorney(errors);
        return isValidResult;
    }

    const getErrorsListClaimantAttorney = () => {
        
        let errors: IErrors = {};
        if (!hasBeenSubmittedClaimantAttorney) { //to know if the form has been tried to be saved (so it will continue showing the fields with validation errors in red)
            setHasBeenSubmittedClaimantAttorney(true);
        }

        if ([
            ServicesEnum.PT,
            ServicesEnum.Diagnostic,
            ServicesEnum.UR,
            ServicesEnum.TT]
            .includes( props.serviceId )){
                if (!props.referralClaimantAttorney?.stateId) errors.attorneyState = 'Required';
            }
        return errors;
    }
    
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Accordion className={classes.paper} expanded={props.openCloseStatus["ClaimantAttorney_box"]??true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className={classes.paperHeaderIcon} />}
                            aria-controls="panel1a-content"
                            id="claimantadjuster-accordion"
                            className={classes.paperHeader}
                            onClick={(event) => handleOpenCloseStatus(event, "ClaimantAttorney_box")}
                        >
                            <Typography variant="subtitle2" className={classes.sidebarTitle}>
                                Claimant Attorney
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {referralClaimantAttorneyState ? (
                                <Grid container spacing={3} className={classes.gridContainer}>
                                    <Grid item xs={12}>
                                        <FormControl
                                            component="fieldset"
                                            disabled={true}
                                        >
                                            <FormLabel component="legend">Attorney Represented</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-label="position"
                                                name="isRepresented"
                                                onChange={(event) => {
                                                    handleTextFieldChangeClaimantAttorney(event);
                                                    validateClaimantAttorney();
                                                }}
                                                value={referralClaimantAttorneyState?.isRepresented?.toString()}
                                            >
                                                <FormControlLabel
                                                    value="true"
                                                    control={<Radio color="primary" />}
                                                    label="Yes"
                                                />
                                                <FormControlLabel
                                                    value="false"
                                                    control={<Radio color="primary" />}
                                                    label="No"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Firm"
                                            id="firm"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimantAttorney(event)}
                                            value={referralClaimantAttorneyState?.firm}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Address Line 1"
                                            id="addressLine1"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimantAttorney(event)}
                                            value={referralClaimantAttorneyState?.addressLine1}
                                            error={!!errorsClaimantAttorney?.attorneyAddressLine1}
                                            helperText={errorsClaimantAttorney?.attorneyAddressLine1}
                                        />
                                    </Grid>
                                    {props.serviceId === ServicesEnum.Diagnostic ? (
                                        <Grid
                                            container
                                            xs={3}
                                            spacing={1}
                                            style={{ marginTop: '9px', marginRight: '4px' }}
                                        >
                                            <Grid item xs={3} style={{ marginLeft: '15px', marginRight: '-8px' }}>
                                                <TextField
                                                    label="Zip Code"
                                                    id="zipCode"
                                                    disabled={true}
                                                    onChange={(event) =>
                                                        handleTextFieldChangeClaimantAttorney(event)
                                                    }
                                                    value={referralClaimantAttorneyState?.zipCode}
                                                    error={!!errorsClaimantAttorney?.attorneyZipCode}
                                                    helperText={errorsClaimantAttorney?.attorneyZipCode}
                                                    style={{ width: '110px' }}
                                                />
                                            </Grid>
                                            <Grid item xs={1}>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    style={{
                                                        marginTop: '17px',
                                                        marginLeft: '15px',
                                                        marginRight: '-8%',
                                                    }}
                                                >
                                                    _
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3} style={{ marginRight: '15px' }}>
                                                <TextField
                                                    disabled={true}
                                                    label="Zip Code Ext."
                                                    id="zipCodeExtension"
                                                    onChange={(event) =>
                                                        handleTextFieldChangeClaimantAttorney(event)
                                                    }
                                                    value={referralClaimantAttorneyState.zipCodeExtension}
                                                    style={{ width: '100px' }}
                                                    inputProps={{ maxLength: 4 }}
                                                />
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={3}>
                                            <TextField
                                                label="Zip Code"
                                                id="zipCode"
                                                disabled={true}
                                                onChange={(event) => handleTextFieldChangeClaimantAttorney(event)}
                                                value={referralClaimantAttorneyState?.zipCode}
                                                error={!!errorsClaimantAttorney?.attorneyZipCode}
                                                helperText={errorsClaimantAttorney?.attorneyZipCode}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Attorney Full Name"
                                            id="name"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimantAttorney(event)}
                                            value={referralClaimantAttorneyState?.name}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Attorney First Name"
                                            id="firstName"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimantAttorney(event)}
                                            value={referralClaimantAttorneyState?.firstName}
                                            error={!!errorsClaimantAttorney?.attorneyFirstName}
                                            helperText={errorsClaimantAttorney?.attorneyFirstName}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Address Line 2"
                                            id="addressLine2"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimantAttorney(event)}
                                            value={referralClaimantAttorneyState?.addressLine2}
                                            error={!!errorsClaimantAttorney?.attorneyAddressLine2}
                                            helperText={errorsClaimantAttorney?.attorneyAddressLine2}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask
                                            mask="(999)-9999999"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimantAttorney(event)}
                                            value={referralClaimantAttorneyState?.primaryPhoneNumber}
                                            label="Primary Phone"
                                            id="primaryPhoneNumber"
                                            error={!!errorsClaimantAttorney?.attorneyPrimaryPhoneNumber}
                                            helperText={errorsClaimantAttorney?.attorneyPrimaryPhoneNumber}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Ext."
                                            id="primaryPhoneExtension"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimantAttorney(event)}
                                            value={referralClaimantAttorneyState?.primaryPhoneExtension}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Attorney Last Name"
                                            id="lastName"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimantAttorney(event)}
                                            value={referralClaimantAttorneyState?.lastName}
                                            error={!!errorsClaimantAttorney?.attorneyLastName}
                                            helperText={errorsClaimantAttorney?.attorneyLastName}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="City"
                                            id="cityName"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimantAttorney(event)}
                                            value={referralClaimantAttorneyState?.cityName}
                                            error={!!errorsClaimantAttorney?.attorneyCityName}
                                            helperText={errorsClaimantAttorney?.attorneyCityName}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask
                                            mask="(999)-9999999"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimantAttorney(event)}
                                            value={referralClaimantAttorneyState?.secondaryPhoneNumber}
                                            label="Secondary Phone"
                                            id="secondaryPhoneNumber"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Ext."
                                            id="secondaryPhoneExtension"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimantAttorney(event)}
                                            value={referralClaimantAttorneyState?.secondaryPhoneExtension}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextField
                                            label="Email"
                                            id="email"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimantAttorney(event)}
                                            value={referralClaimantAttorneyState?.email}
                                            error={!!errorsClaimantAttorney?.attorneyEmail}
                                            helperText={errorsClaimantAttorney?.attorneyEmail}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <FormControl className={classes.selectInput}>
                                            <InputLabel id="demo-simple-select-label">State</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="stateId"
                                                name="stateId"
                                                key={referralClaimantAttorneyState?.stateId}
                                                value={referralClaimantAttorneyState?.stateId}
                                                disabled={true}
                                                onChange={(event) => handleTextFieldChangeClaimantAttorney(event)}                                                
                                                error={!!errorsClaimantAttorney?.attorneyState}
                                                endAdornment={
                                                    <ClearSelect
                                                        shouldRender={false}
                                                        onClick={() =>
                                                            handleClearSelectFieldChangeClaimantAttorney('stateId')
                                                        }
                                                    />
                                                }
                                            >
                                                {props.states.map((state, index) => {
                                                    return (
                                                        <MenuItem key={state.stateId} value={state.stateId}>
                                                            {state.stateName}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <TextfieldMask
                                            mask="(999)-9999999"
                                            disabled={true}
                                            onChange={(event) => handleTextFieldChangeClaimantAttorney(event)}
                                            value={referralClaimantAttorneyState?.fax}
                                            label="Fax"
                                            id="fax"
                                            error={!!errorsClaimantAttorney?.attorneyFax}
                                            helperText={errorsClaimantAttorney?.attorneyFax}
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        {/* Empty space */}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl className={classes.selectInput}>
                                            <TextField
                                                maxRows={4}
                                                multiline={true}
                                                label="Additional Comments"
                                                id="comments"                                                
                                                onChange={(event) => handleTextFieldChangeClaimantAttorney(event)}
                                                value={referralClaimantAttorneyState?.comments}
                                                inputProps={{ maxLength: 250 }}
                                                helperText={`${
                                                    referralClaimantAttorneyState?.comments
                                                        ? referralClaimantAttorneyState?.comments.length
                                                        : 0
                                                }/${250}`}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            ) : (
                                <EmptyDataLabel></EmptyDataLabel>
                            )}
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            {Dialog}
        </>
    );
};
